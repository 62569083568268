/* Markets.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, März 2019
 */
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";
import {withStyles} from "@material-ui/core";
import * as React from "react";

class MarketsIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <style type="text/css">{`
      .st0markets{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;fill-opacity:0.5;}
      .st1markets{fill:#FFFFFF;}
      .st2markets{fill:#FFFFFF;stroke:#E21532;stroke-width:0.2274;stroke-miterlimit:10;}
     `}</style>
      <path className="st0markets" d="M72.33,30.6L51.21,46.07l-10.72-8.2l-7.3,5.75l0,0l1.65,0.95c0.39,0.34,0.96,0.58,1.19,1.03
	c0.28,0.55,0.18,1.33,0.18,1.96v13.97l36.12,0V30.6z"/>
      <path id="Combined-Shape_2_" className="st0markets" d="M35.03,45.89v15.64H5.92V45.89C14.8,40.75,26.15,40.75,35.03,45.89z M27.7,30.55
	c0,3.99-3.23,7.22-7.22,7.22s-7.22-3.23-7.22-7.22c0-3.99,3.23-7.22,7.22-7.22S27.7,26.56,27.7,30.55z"/>
      <polygon className="st1markets"
               points="74.95,30.41 74.95,21.63 66.18,21.63 69.4,24.85 51.27,38.53 40.53,30.07 40.37,30.27 26.89,40.91 30.74,42.41 40.39,34.85 51.21,43.17 51.22,43.18 51.22,43.18 51.23,43.18 51.25,43.16 72.02,27.48 "/>
      <path className="st2markets" d="M26.14,39.18"/>
      <path className="st2markets" d="M35.7,45.21"/>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(MarketsIcon);
