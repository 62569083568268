import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class FormulaAttributeIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <path d="M5.1587,16 L3.7817,16 L7.4017,10.896 L3.9377,6 L5.3137,6 L8.0227,10.025 L10.6877,6 L12.0637,6 L8.5997,10.896 L12.2197,16 L10.8427,16 L8.0227,11.789 L5.1587,16 Z M14,10 L14,9 L20,9 L20,10 L14,10 Z M3,19 L3,18 L21,18 L21,19 L3,19 Z M14,13 L14,12 L20,12 L20,13 L14,13 Z"/> ;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(FormulaAttributeIcon);