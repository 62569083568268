/* DnDInsertAttribute.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Januar 2019
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";
import theme from "../../theme/Theme";

class DnDInsertAttributeIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    const highlightColor = theme.metus.dialog.buttonFill
    const highlightColorLight = theme.metus.dialog.buttonFillOnHover
    return <React.Fragment>
      <g id="layer1" transform="translate(-250,-642)">
        <g transform="translate(-75.706414,-192.69716)" id="layer1-5"/>
        <path
            style={{
              fill: "#f2f2f2",
              fillRule: "evenodd",
              stroke: "#000000",
              strokeWidth: 0,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeOpacity: 1
            }}
            d="m 109.49365,662.20857 8.21429,-11.25 7.32142,11.25 z"
            id="path12476"
        />
        <rect
            style={{fill: "#000000", fillOpacity: 0}}
            id="rect12488"
            width="65.178574"
            height="25.892857"
            x="88.928574"
            y="667.54077"/>
        <rect
            style={{
              fill: "#f2f2f2",
              fillOpacity: 1,
              stroke: "#000000",
              strokeWidth: 0,
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeOpacity: 1
            }}
            id="rect12490"
            width="210"
            height="100.55454"
            x="76.457657"
            y="662.04315"/>
        <g
            transform="translate(-225.71879,601.74199)"
            id="g44">
          <g
              id="g46">
            <g
                id="g48">
              <path
                  style={{fill: "#ededed"}}
                  id="path50"
                  d="M 476.48,91.91 C 484.9,91.54 486,86 486,79.65 c 0.01,-7.26 1.55,-13.37 -7.19,-13.69 -8.1,-0.3 -73.5,-0.63 -93.39,-0.66 -17.98,0.02 -37.96,-0.2 -45.33,-0.03 -9.44,0.22 -9.88,6.35 -9.85,12.79 0.03,6.55 0.68,13.39 10.28,13.17 5.68,-0.12 131.68,0.87 135.96,0.68 z"
                  className="st4"/>
              <path
                  style={{fill: "#a8a8a8"}}
                  id="path52"
                  d="m 476.45,91.23 c 1.74,-0.13 3.47,-0.51 4.87,-1.34 1.42,-0.81 2.4,-2.13 2.96,-3.7 1.13,-3.17 0.71,-6.91 0.91,-10.54 0.03,-1.79 0.06,-3.59 -0.37,-5.16 -0.22,-0.77 -0.56,-1.46 -1.07,-1.99 -0.51,-0.53 -1.18,-0.91 -1.94,-1.16 -1.53,-0.52 -3.31,-0.48 -5.13,-0.53 l -5.45,-0.08 C 456.69,66.58 442.15,66.51 427.6,66.45 L 383.97,66.28 C 369.43,66.25 354.86,66 340.36,66.19 c -1.75,0.04 -3.48,0.24 -4.96,0.9 -1.49,0.65 -2.62,1.79 -3.24,3.29 -0.64,1.5 -0.84,3.24 -0.92,5 -0.06,1.76 -0.05,3.6 0.04,5.35 0.1,1.76 0.34,3.5 0.94,5.04 0.59,1.54 1.63,2.78 3.05,3.49 1.42,0.72 3.13,0.97 4.84,0.95 l 5.46,-0.01 43.64,0.21 c 14.55,0.08 29.09,0.32 43.63,0.47 l 21.82,0.25 10.91,0.12 5.45,0.05 2.73,0.02 c 0.91,0.01 1.8,-0.04 2.7,-0.09 z m 0.06,1.37 c -14.61,-0.05 -29.11,0.28 -43.67,0.12 l -43.64,-0.33 -43.63,-0.27 -5.45,0 c -1.89,0 -3.88,-0.25 -5.7,-1.17 -0.9,-0.47 -1.73,-1.11 -2.4,-1.88 -0.66,-0.78 -1.16,-1.67 -1.52,-2.59 -0.71,-1.85 -0.94,-3.75 -1.05,-5.61 -0.09,-1.87 -0.1,-3.67 -0.03,-5.54 0.09,-1.85 0.29,-3.78 1.07,-5.63 0.38,-0.92 0.95,-1.79 1.67,-2.53 0.72,-0.73 1.59,-1.3 2.51,-1.7 1.84,-0.81 3.79,-1.01 5.65,-1.04 29.13,0.01 58.19,-0.11 87.29,0.13 14.55,0.1 29.09,0.19 43.64,0.37 l 5.46,0.09 c 0.93,0.03 1.8,0.03 2.77,0.09 0.96,0.07 1.93,0.22 2.89,0.54 0.96,0.31 1.92,0.84 2.67,1.63 0.76,0.79 1.23,1.78 1.5,2.75 0.52,1.96 0.45,3.85 0.42,5.68 l -0.07,2.72 c -0.02,0.89 -0.01,1.8 -0.04,2.74 -0.07,1.86 -0.25,3.77 -0.92,5.63 -0.33,0.93 -0.81,1.83 -1.45,2.63 -0.65,0.79 -1.46,1.44 -2.35,1.92 -1.78,0.97 -3.76,1.2 -5.62,1.25 z"
                  className="st3"/>
            </g>
          </g>
        </g>
        <path
            style={{opacity: 0.25, fill: "#a8a8a8"}}
            id="path232"
            d="m 202.97121,693.06199 c 3.01,-4.47 5.78,-10.1 5.59,-15.8 -0.15,-4.41 -2.82,-7.56 -5,-9.64 0.97,10.28 2.84,17.39 -0.59,25.44 z"
            className="st7"/>
        <g
            transform="translate(-225.71879,601.74199)"
            id="g234">
          <path
              style={{fill: "#a8a8a8"}}
              id="path236"
              d="m 428.38,91.17 c 0.13,-0.6 0.48,-1.08 0.72,-1.61 L 429.91,88 c 0.53,-1.04 1.06,-2.08 1.55,-3.13 0.98,-2.09 1.89,-4.23 2.18,-6.5 0.31,-2.26 0.2,-4.57 -0.72,-6.63 -0.91,-2.04 -2.19,-3.99 -3.88,-5.6 l 0,0 c -0.14,-0.13 -0.14,-0.35 -0.01,-0.48 0.13,-0.14 0.35,-0.14 0.48,-0.01 0,0 0.01,0.01 0.01,0.01 0.82,0.85 1.81,1.56 2.49,2.57 0.73,0.96 1.32,2.03 1.8,3.14 0.98,2.25 1.08,4.78 0.72,7.14 -0.39,2.37 -1.16,4.66 -2.19,6.79 -0.51,1.07 -1.06,2.11 -1.61,3.15 L 429.9,90 c -0.3,0.5 -0.48,1.07 -0.9,1.51 l 0,0 c -0.14,0.14 -0.36,0.15 -0.5,0.01 -0.11,-0.11 -0.14,-0.24 -0.12,-0.35 z"
              className="st3"/>
        </g>
        <g
            transform="translate(-225.71879,601.74199)"
            id="g238">
          <path
              style={{fill: "none", stroke: "#a8a8a8", strokeWidth: 3, strokeMiterlimit: 10}}
              id="path240"
              d="m 354.65,83.18 c -2.97,-0.05 -5.93,-0.09 -8.9,-0.14"
              className="st16"/>
          <path
              style={{fill: "none", stroke: "#a8a8a8", strokeWidth: 3, strokeMiterlimit: 10}}
              id="path242"
              d="m 354.42,78.36 c -2.89,0.08 -5.79,0.15 -8.68,0.23"
              className="st16"/>
          <path
              style={{fill: "none", stroke: "#a8a8a8", strokeWidth: 3, strokeMiterlimit: 10}}
              id="path244"
              d="m 354.45,73.36 c -3.14,0.28 -6.12,0.11 -8.45,-0.14"
              className="st16"/>
          <line
              style={{fill: "none", stroke: "#a8a8a8", strokeWidth: 3, strokeMiterlimit: 10}}
              id="line246"
              y2="83.040001"
              x2="342.64001"
              y1="83.07"
              x1="339.66"
              className="st16"/>
          <line
              style={{fill: "none", stroke: "#a8a8a8", strokeWidth: 3, strokeMiterlimit: 10}}
              id="line248"
              y2="78.07"
              x2="342.44"
              y1="78.099998"
              x1="339.31"
              className="st16"/>
          <line
              style={{fill: "none", stroke: "#a8a8a8", strokeWidth: 3, strokeMiterlimit: 10}}
              id="line250"
              y2="73.160004"
              x2="342.69"
              y1="73.190002"
              x1="339.26001"
              className="st16"/>
        </g>
        <g
            transform="translate(-225.71879,601.74199)"
            id="g253">
          <g
              id="g255">
            <g
                id="g257">
              <g
                  id="g259">
                <g
                    id="g261">
                  <path
                      style={{fill: highlightColorLight}}
                      id="path263"
                      d="m 436.09,106.81 c -14.41,-0.97 -42.62,-1.21 -51.53,-0.53 -6.93,0.52 -9.66,4.76 -9.06,13.06 0.45,6.21 1.67,12.38 8.87,12.69 15.47,0.66 36.31,-0.06 50.4,-1.28 7.92,-0.69 8.76,-5.98 8.78,-11.95 0.01,-5.5 -0.16,-11.5 -7.46,-11.99 z"
                      className="st11"/>
                  <path
                      style={{fill: highlightColor}}
                      id="path265"
                      d="m 436.04,107.49 c -1.78,0.08 -3.55,-0.01 -5.32,-0.1 l -5.32,-0.19 c -3.55,-0.11 -7.1,-0.18 -10.65,-0.23 -7.1,-0.11 -14.2,-0.09 -21.29,0 -1.77,0.02 -3.54,0.05 -5.31,0.11 -0.88,0.03 -1.77,0.06 -2.64,0.11 -0.89,0.04 -1.71,0.1 -2.53,0.26 -1.62,0.3 -3.13,0.94 -4.23,2.03 -1.1,1.09 -1.77,2.6 -2.1,4.22 -0.34,1.63 -0.36,3.35 -0.27,5.07 0.11,1.74 0.28,3.49 0.63,5.15 0.35,1.66 0.9,3.27 1.85,4.52 0.93,1.27 2.29,2.09 3.86,2.42 0.79,0.17 1.57,0.21 2.49,0.23 l 2.65,0.09 c 1.77,0.05 3.54,0.08 5.31,0.09 3.54,0.03 7.09,0.01 10.64,-0.05 7.09,-0.13 14.19,-0.4 21.27,-0.83 3.52,-0.19 7.14,-0.38 10.56,-0.69 1.65,-0.22 3.24,-0.74 4.43,-1.76 1.19,-1.01 1.91,-2.51 2.27,-4.14 0.37,-1.64 0.44,-3.38 0.47,-5.14 0.02,-1.77 0.03,-3.52 -0.24,-5.2 -0.26,-1.67 -0.84,-3.29 -2.01,-4.37 -1.15,-1.07 -2.82,-1.65 -4.52,-1.6 z m 0.09,-1.37 c 0.93,0.14 1.85,0.33 2.76,0.6 0.91,0.29 1.8,0.76 2.54,1.42 1.52,1.33 2.23,3.27 2.57,5.08 0.35,1.84 0.38,3.67 0.41,5.45 0.02,1.8 0.01,3.64 -0.36,5.51 -0.38,1.84 -1.23,3.79 -2.79,5.14 -1.55,1.36 -3.5,1.98 -5.35,2.26 -0.95,0.14 -1.83,0.19 -2.72,0.26 l -2.67,0.19 -5.34,0.32 c -7.11,0.41 -14.23,0.65 -21.35,0.75 -3.56,0.04 -7.12,0.05 -10.69,0.01 -1.78,-0.02 -3.56,-0.06 -5.35,-0.12 l -2.67,-0.1 c -0.86,-0.02 -1.86,-0.07 -2.79,-0.28 -0.95,-0.21 -1.88,-0.57 -2.73,-1.09 -0.84,-0.53 -1.59,-1.22 -2.17,-2.01 -1.18,-1.59 -1.77,-3.43 -2.15,-5.22 -0.38,-1.81 -0.54,-3.61 -0.65,-5.41 -0.1,-1.82 -0.06,-3.67 0.3,-5.52 0.36,-1.83 1.13,-3.72 2.55,-5.14 1.41,-1.42 3.31,-2.21 5.15,-2.56 0.93,-0.18 1.87,-0.26 2.76,-0.31 0.9,-0.07 1.79,-0.1 2.68,-0.15 1.79,-0.08 3.57,-0.13 5.35,-0.17 7.13,-0.13 14.24,-0.05 21.36,0.08 3.56,0.07 7.12,0.16 10.67,0.3 l 5.34,0.22 c 0.89,0.05 1.78,0.08 2.67,0.15 0.9,0.08 1.79,0.21 2.67,0.34 z"
                      className="st12"/>
                </g>
              </g>
            </g>
            <path
                style={{fill: "white"}}
                id="path267"
                d="m 414.22,120.89 c -2.9,1.74 -6.67,2.03 -9.81,0.74 0.02,2.46 2.45,4.65 4.98,4.48 2.53,-0.17 5.17,-2.78 4.83,-5.22 z"
                className="st12"/>
            <g
                id="g269">
              <path
                  style={{fill: "white"}}
                  id="path271"
                  d="m 394.64,115.61 c -0.13,-0.17 -0.07,-0.23 -0.04,-0.29 l 0.12,-0.19 c 0.08,-0.12 0.17,-0.24 0.27,-0.36 0.19,-0.23 0.44,-0.39 0.69,-0.54 0.24,-0.17 0.53,-0.24 0.81,-0.26 0.28,-0.04 0.53,-0.13 0.81,-0.18 0.53,-0.08 1.08,-0.13 1.58,-0.04 0.26,0.03 0.5,0.11 0.7,0.3 0.1,0.09 0.2,0.17 0.3,0.27 l 0.14,0.14 c 0.02,0.02 0.05,0.04 0.07,0.06 0.03,0 0.03,0.1 0.11,-0.05 0.36,0.13 0.54,0.52 0.41,0.88 -0.13,0.36 -0.52,0.54 -0.88,0.41 0.04,-0.17 -0.03,-0.07 -0.05,-0.08 -0.03,0.01 -0.06,0.02 -0.09,0.04 l -0.2,0.12 c -0.13,0.08 -0.21,0.04 -0.31,0.02 -0.17,-0.08 -0.34,-0.17 -0.52,-0.21 -0.36,-0.09 -0.71,-0.13 -1.05,-0.1 -0.16,0.02 -0.34,0.03 -0.48,0.12 -0.14,0.1 -0.26,0.21 -0.4,0.27 -0.14,0.07 -0.24,0.18 -0.37,0.25 -0.06,0.04 -0.12,0.08 -0.17,0.13 l -0.08,0.07 c -0.02,0.03 -0.03,0.06 -0.22,-0.02 l -0.39,-0.17 c -0.32,-0.14 -0.57,-0.35 -0.76,-0.59 z"
                  className="st12"/>
            </g>
            <g
                id="g273">
              <path
                  style={{fill: "white"}}
                  id="path275"
                  d="m 422.15,115.55 c -0.14,0.15 -0.18,0.12 -0.23,0.11 l -0.15,-0.04 -0.3,-0.06 c -0.21,-0.02 -0.39,-0.08 -0.59,-0.1 -0.2,0 -0.39,-0.07 -0.6,-0.13 -0.21,-0.05 -0.41,0 -0.62,0.03 -0.41,0.07 -0.78,0.25 -1.15,0.41 -0.18,0.1 -0.36,0.17 -0.61,0.17 l -0.36,0.05 -0.18,0.04 c -0.03,0.01 -0.05,0.02 -0.08,0.03 -0.01,0.03 -0.1,-0.03 -0.01,0.11 -0.28,0.25 -0.71,0.23 -0.97,-0.05 -0.25,-0.28 -0.23,-0.71 0.05,-0.97 0.15,0.11 0.09,-0.01 0.12,-0.03 0.01,-0.04 0.03,-0.08 0.04,-0.12 0.01,-0.1 0.03,-0.19 0.06,-0.28 0.05,-0.18 0.19,-0.26 0.31,-0.35 0.28,-0.14 0.56,-0.23 0.84,-0.36 0.56,-0.22 1.14,-0.35 1.73,-0.42 0.29,-0.03 0.59,-0.02 0.88,-0.06 0.3,-0.04 0.6,-0.06 0.9,0 0.3,0.06 0.6,0.11 0.88,0.24 l 0.41,0.2 0.2,0.11 c 0.07,0.04 0.14,0.06 0.11,0.27 -0.06,0.41 -0.25,0.77 -0.51,1.05 l -0.17,0.15 z"
                  className="st12"/>
            </g>
            <g
                id="g277">
              <g
                  id="g279">
                <path
                    style={{fill: highlightColor}}
                    id="path281"
                    d="m 378.11,136.99 c 1.36,-0.05 2.69,-0.01 4.03,0.03 1.34,0.04 2.67,0.07 4,0.15 0.24,0.01 0.43,0.22 0.41,0.46 -0.01,0.24 -0.22,0.43 -0.46,0.41 -2.66,-0.12 -5.33,-0.36 -7.96,-0.36 l -0.01,0 c -0.19,0 -0.34,-0.15 -0.34,-0.34 0,-0.2 0.15,-0.35 0.33,-0.35 z"
                    className="st12"/>
                <path
                    style={{fill: highlightColor}}
                    id="path283"
                    d="m 394.13,137.54 7.99,0.26 0.01,0 c 0.25,0.01 0.44,0.22 0.43,0.46 -0.01,0.25 -0.22,0.44 -0.46,0.43 l 0,0 -8.01,-0.27 c -0.24,-0.01 -0.44,-0.21 -0.43,-0.46 0.01,-0.25 0.22,-0.43 0.47,-0.42 l 0,0 z"
                    className="st12"/>
                <path
                    style={{fill: highlightColor}}
                    id="path285"
                    d="m 410.09,137.76 c 1.32,-0.08 2.64,-0.16 3.97,-0.28 l 3.99,-0.34 c 0.26,-0.02 0.48,0.17 0.5,0.42 0.02,0.25 -0.17,0.48 -0.42,0.5 l -0.01,0 c -2.66,0.21 -5.3,0.46 -7.99,0.6 -0.25,0.01 -0.46,-0.18 -0.48,-0.43 -0.01,-0.25 0.18,-0.46 0.42,-0.48 l 0.02,0.01 z"
                    className="st12"/>
                <path
                    style={{fill: highlightColor}}
                    id="path287"
                    d="m 426.04,136.6 c 2.67,-0.13 5.37,-0.17 8.03,-0.06 l 0.01,0 c 0.27,0.01 0.48,0.24 0.47,0.51 -0.01,0.27 -0.23,0.47 -0.5,0.47 -1.34,-0.03 -2.66,-0.07 -3.98,-0.06 -1.33,0.01 -2.65,0.05 -3.98,0.11 -0.26,0.01 -0.49,-0.19 -0.5,-0.46 -0.01,-0.27 0.19,-0.5 0.45,-0.51 z"
                    className="st12"/>
                <path
                    style={{fill: highlightColor}}
                    id="path289"
                    d="m 441.9,136.18 c 2.44,-0.69 4.38,-2.61 5.7,-4.82 0.13,-0.21 0.41,-0.28 0.62,-0.15 0.21,0.13 0.28,0.41 0.15,0.62 l 0,0 c -0.71,1.17 -1.56,2.27 -2.6,3.19 -1.03,0.92 -2.27,1.65 -3.62,2.04 -0.24,0.07 -0.5,-0.07 -0.57,-0.31 -0.07,-0.24 0.07,-0.49 0.32,-0.57 l 0,0 z"
                    className="st12"/>
                <path
                    style={{fill: highlightColor}}
                    id="path291"
                    d="m 450.32,124.05 c 0.53,-2.57 0.75,-5.22 0.66,-7.84 -0.01,-0.24 0.18,-0.45 0.42,-0.46 0.24,-0.01 0.45,0.18 0.46,0.42 l 0,0.01 c 0.08,2.7 -0.13,5.4 -0.67,8.05 -0.05,0.24 -0.28,0.39 -0.52,0.34 -0.24,-0.04 -0.4,-0.27 -0.35,-0.52 l 0,0 z"
                    className="st12"/>
                <path
                    style={{fill: highlightColor}}
                    id="path293"
                    d="m 449.34,108.62 c -1.06,-2.36 -2.82,-4.39 -5.09,-5.59 -0.22,-0.11 -0.3,-0.38 -0.19,-0.6 0.11,-0.22 0.38,-0.3 0.6,-0.19 l 0.01,0 c 2.46,1.29 4.36,3.51 5.47,6 l 0,0.01 c 0.1,0.22 0,0.48 -0.22,0.58 -0.22,0.11 -0.48,0.01 -0.58,-0.21 z"
                    className="st12"/>
                <path
                    style={{fill: highlightColor}}
                    id="path295"
                    d="m 436.7,101.31 c -2.65,-0.23 -5.3,-0.36 -7.96,-0.46 -0.25,-0.01 -0.45,-0.22 -0.44,-0.47 0.01,-0.25 0.22,-0.45 0.47,-0.44 l 0,0 c 2.67,0.1 5.34,0.24 8.01,0.47 0.25,0.02 0.43,0.24 0.41,0.49 -0.03,0.25 -0.25,0.44 -0.49,0.41 z"
                    className="st12"/>
                <path
                    style={{fill: highlightColor}}
                    id="path297"
                    d="m 420.73,100.7 -7.99,0.02 -0.01,0 c -0.26,0 -0.47,-0.21 -0.47,-0.47 0,-0.26 0.21,-0.47 0.47,-0.47 l 0,0 8.01,-0.01 c 0.25,0 0.46,0.21 0.46,0.46 0,0.26 -0.21,0.47 -0.47,0.47 l 0,0 z"
                    className="st12"/>
                <path
                    style={{fill: highlightColor}}
                    id="path299"
                    d="m 404.75,100.86 -7.99,0.22 c -0.26,0.01 -0.49,-0.2 -0.5,-0.46 -0.01,-0.27 0.2,-0.49 0.46,-0.5 l 0,0 8,-0.21 c 0.26,-0.01 0.48,0.2 0.49,0.46 0.01,0.26 -0.2,0.49 -0.46,0.49 z"
                    className="st12"/>
                <path
                    style={{fill: highlightColor}}
                    id="path301"
                    d="m 388.76,101.36 -8,0.25 c -0.26,0.01 -0.48,-0.19 -0.49,-0.46 -0.01,-0.27 0.2,-0.48 0.46,-0.49 l 8,-0.28 c 0.27,-0.01 0.49,0.2 0.51,0.47 0,0.27 -0.21,0.5 -0.48,0.51 l 0,0 z"
                    className="st12"/>
                <path
                    style={{fill: highlightColor}}
                    id="path303"
                    d="m 373.3,103.06 c -2.1,1.45 -3.44,3.82 -4.29,6.27 -0.08,0.23 -0.33,0.36 -0.57,0.28 -0.23,-0.08 -0.36,-0.34 -0.28,-0.57 0.44,-1.29 1.01,-2.55 1.76,-3.71 0.75,-1.16 1.69,-2.22 2.86,-3.03 0.21,-0.14 0.49,-0.09 0.64,0.12 0.14,0.21 0.08,0.5 -0.12,0.64 l 0,0 z"
                    className="st12"/>
                <path
                    style={{fill: highlightColor}}
                    id="path305"
                    d="m 367.59,117.07 c -0.03,1.32 -0.11,2.64 -0.04,3.96 0.05,1.32 0.17,2.64 0.37,3.94 0.03,0.21 -0.11,0.41 -0.33,0.44 -0.21,0.03 -0.41,-0.11 -0.44,-0.32 -0.22,-1.33 -0.35,-2.68 -0.42,-4.03 -0.08,-1.35 -0.02,-2.7 0,-4.04 0,-0.24 0.2,-0.43 0.44,-0.43 0.24,0 0.43,0.2 0.43,0.44 l 0,0 -0.01,0.04 z"
                    className="st12"/>
                <path
                    style={{fill: highlightColor}}
                    id="path307"
                    d="m 370.32,132.42 c 0.66,1.12 1.48,2.15 2.5,2.94 1.01,0.8 2.21,1.34 3.48,1.55 0.16,0.03 0.27,0.18 0.25,0.34 -0.03,0.16 -0.18,0.27 -0.34,0.25 l 0,0 c -1.36,-0.23 -2.68,-0.79 -3.77,-1.64 -1.1,-0.84 -1.99,-1.93 -2.69,-3.1 -0.1,-0.16 -0.05,-0.37 0.12,-0.47 0.14,-0.09 0.35,-0.04 0.45,0.13 0,-0.01 0,0 0,0 z"
                    className="st12"/>
              </g>
            </g>
          </g>
          <g
              id="g309">
            <path
                style={{fill: highlightColor}}
                id="path311"
                d="m 434.33,106.14 c -0.06,-0.33 0.06,-0.57 0.16,-0.82 l 0.31,-0.74 c 0.22,-0.49 0.4,-1 0.57,-1.5 0.16,-0.51 0.37,-1 0.52,-1.51 l 0.45,-1.53 c 0.53,-2.05 1.03,-4.14 0.96,-6.27 -0.01,-2.12 -0.32,-4.22 -0.88,-6.26 -0.29,-1.02 -0.61,-2.03 -0.88,-3.08 -0.15,-0.52 -0.3,-1.04 -0.47,-1.56 -0.08,-0.26 -0.17,-0.52 -0.26,-0.78 l -0.14,-0.39 c -0.07,-0.12 -0.04,-0.29 -0.24,-0.34 l -0.04,-0.01 c -0.59,-0.16 -0.95,-0.76 -0.79,-1.36 0.16,-0.59 0.76,-0.95 1.36,-0.79 0.59,0.16 0.95,0.76 0.79,1.36 -0.02,0.07 -0.05,0.15 -0.08,0.22 -0.1,0.2 0.07,0.28 0.12,0.4 0.08,0.12 0.15,0.23 0.24,0.35 0.18,0.22 0.34,0.46 0.51,0.69 0.32,0.47 0.54,0.99 0.75,1.52 0.39,1.07 0.7,2.16 0.99,3.26 0.57,2.21 0.86,4.5 0.81,6.78 -0.09,2.27 -0.28,4.56 -0.86,6.77 -0.33,1.09 -0.63,2.19 -1.1,3.22 -0.21,0.53 -0.43,1.05 -0.69,1.55 l -0.37,0.76 c -0.12,0.25 -0.23,0.52 -0.53,0.68 -0.4,0.22 -0.91,0.07 -1.13,-0.33 -0.04,-0.08 -0.07,-0.16 -0.09,-0.24 l 0.01,-0.05 z"
                className="st12"/>
          </g>
          <g
              id="g313">
            <path
                style={{fill: highlightColor}}
                id="path315"
                d="m 390.38,105.24 c 0.42,-1.73 1.44,-3.17 2.29,-4.68 0.45,-0.75 0.97,-1.45 1.45,-2.18 0.49,-0.72 1.06,-1.38 1.59,-2.08 2.22,-2.7 4.94,-4.91 7.66,-7.05 2.76,-2.1 5.73,-3.9 8.79,-5.48 1.52,-0.81 3.1,-1.5 4.72,-2.1 l 2.43,-0.86 1.22,-0.41 0.61,-0.21 c 0.19,-0.09 0.43,-0.08 0.57,-0.31 l 0.01,-0.02 c 0.25,-0.42 0.8,-0.56 1.22,-0.31 0.42,0.25 0.56,0.8 0.31,1.22 -0.22,0.36 -0.65,0.52 -1.04,0.39 -0.26,-0.09 -0.41,0.11 -0.61,0.18 l -0.56,0.3 -1.11,0.64 c -0.74,0.42 -1.51,0.76 -2.28,1.1 -1.55,0.66 -3.1,1.32 -4.59,2.09 -3,1.52 -5.9,3.23 -8.6,5.21 -2.74,1.94 -5.1,4.33 -7.25,6.86 -0.52,0.65 -1.07,1.27 -1.56,1.95 -0.47,0.68 -0.98,1.34 -1.42,2.04 -0.43,0.71 -0.88,1.41 -1.28,2.13 l -0.57,1.11 c -0.19,0.37 -0.37,0.74 -0.74,1.03 l -0.16,0.13 c -0.3,0.23 -0.73,0.18 -0.96,-0.12 -0.15,-0.16 -0.18,-0.37 -0.14,-0.57 z"
                className="st12"/>
          </g>
          <path
              style={{fill: highlightColorLight, stroke: highlightColor, strokeLinecap: "round", strokeMiterlimit: 10}}
              id="path317"
              d="m 422.06,77.95 c -0.31,0.38 -0.6,1.22 -0.5,1.61 0.16,0.65 0.46,1.47 1.01,1.94 0.6,0.5 1.79,0.32 2.49,-0.29 0.72,-0.63 0.91,-1.54 0.66,-2.24 -0.26,-0.71 -0.93,-1.26 -1.79,-1.46 -0.62,-0.15 -1.47,-0.06 -1.87,0.44 z"
              className="st22"/>
          <path
              style={{fill: highlightColorLight, stroke: highlightColor, strokeLinecap: "round", strokeMiterlimit: 10}}
              id="path319"
              d="m 436.1,77.52 c -0.31,-0.38 -1.06,-0.84 -1.47,-0.83 -0.67,0.02 -1.53,0.14 -2.1,0.58 -0.62,0.48 -0.69,1.68 -0.25,2.5 0.46,0.84 1.31,1.21 2.05,1.11 0.75,-0.1 1.42,-0.64 1.81,-1.45 0.27,-0.56 0.37,-1.41 -0.04,-1.91 z"
              className="st22"/>
        </g>
        <g
            id="g116-9"
            transform="translate(40,635.73416)">
          <text
              style={{fontSize: "18px", fontFamily: "Roboto-LightItalic", fill: highlightColor}}
              id="text118-5"
              className="st12 st19 st20"
              transform="translate(68.5648,123.2395)">Insert an attribute here.
          </text>
        </g>
      </g>
      <g
          transform="translate(-49.992128,208.73141)"
          id="layer1-6"/>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 744.09448819 1052.3622047";
  }
}

export default withStyles(svgIconStyles)(DnDInsertAttributeIcon);
