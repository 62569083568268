import {createMuiTheme, Theme} from "@material-ui/core";
import {BackgroundColorProperty, ColorProperty, CursorProperty, FontFamilyProperty, FontSizeProperty} from "csstype";

const palette = {
  primary: {
    white: "#fff",
    main: "#dedede",
    mainDark: "#2d2d2d",
    mainSelected: "#d5d5d5",
    mainDarkerTint: "#b0b0b0",
    notYetSet: "#ff13db",
    errorColor: "#c52a1a"
  },
  secondary: {
    main: "#E88D14",
    light: "#eda443",
    highlight: "#AD1B02"
  },

  action: {
    disabled: "#b9b9b9",
    disabledBackground: "#e7e7e7",
  }
};

interface ElementSelectionColors {
  primary: ColorProperty;
  secondary: ColorProperty;
}

interface FontSizes {
  sidebarPrimaryText: FontSizeProperty<string>;
  sidebarSecondaryText: FontSizeProperty<string>;
  dialogTitle: FontSizeProperty<string>;
  dialogSubTitle: FontSizeProperty<string>;
  dialogText: FontSizeProperty<string>;
}


export const metusElementSelectionColors: ElementSelectionColors = {
  primary: "#FFC266",
  secondary: "#B0B0B0"
};

export const metusFontSizes: FontSizes = {
  sidebarPrimaryText: "20px",
  sidebarSecondaryText: "14px",
  dialogTitle: "21px",
  dialogSubTitle: "17px",
  dialogText: "16px",
}

const typography: any = {
  useNextVariants: true,
  h6: { // accordion header
    fontSize: metusFontSizes.sidebarPrimaryText,
    fontWeight: 500, // medium
    color: palette.primary[500],
  },
  subtitle1: {
    fontSize: "16px",
    fontWeight: 500, // medium
    color: palette.primary[500],
  },
  body2: { // right menu text
    fontSize: metusFontSizes.sidebarSecondaryText,
    fontWeight: 400, // regular
    color: "inherit",
  },
  body1: { // left menu entries
    fontSize: metusFontSizes.sidebarSecondaryText,
    fontWeight: 400, // regular
    color: palette.secondary[800],
  },
  button: { // accordion header
    fontSize: "16px",
    fontWeight: 500, // medium
    color: palette.primary[500],
    textTransform: "none",
  },
  caption: { // properties tab
    fontSize: "8px",
    fontWeight: 700, // bold
    textTransform: "uppercase",
  },

};

interface SidebarProperties {
  fill: BackgroundColorProperty;
  primaryText: ColorProperty;
  secondaryText: ColorProperty;
  filterText: ColorProperty;
  icon: ColorProperty;
  iconText: ColorProperty;
  iconTextActive: ColorProperty;
  iconDivider: ColorProperty;
  active: {
    highlight: ColorProperty
    fill: BackgroundColorProperty
    secondaryFill: BackgroundColorProperty
    icon: ColorProperty
  };
  disabled: {
    fill: BackgroundColorProperty
    cursor: CursorProperty
  }
  width: number;
}

interface NavigationBarProperties {
  secondLevelFill: ColorProperty;
  levelIndent: number;
  headerBarIconButton: ColorProperty;
  headerBarColor: ColorProperty;
  headerBarColorNoScenario: ColorProperty;
  editorScrollBar: ColorProperty;
  editorScrollBarBackground: ColorProperty;
}

interface DialogProperties {
  buttonFill: ColorProperty;
  buttonFillOnHover: ColorProperty;
  fill: ColorProperty;
  primaryText: ColorProperty;
  secondaryText: ColorProperty;
  headerText: ColorProperty;
  dialogBackgroundColor: ColorProperty;
  errorFooterColor: ColorProperty;
  errorTypography: ColorProperty;
}

interface MetusThemeExtension {
  typography: {
    fontFamily: "Helvetica Neue, Arial"
  };
  main: {
    fill: BackgroundColorProperty;
    fontFamily: FontFamilyProperty;
    primaryText: ColorProperty;
    cloudIcon: ColorProperty;
    projectIcon: ColorProperty;
    progressColor: ColorProperty;
    progressBarColor: ColorProperty;
  };
  // color in navigation tree and main bar
  navigation: SidebarProperties & NavigationBarProperties;
  properties: SidebarProperties;
  dialog: DialogProperties;
  selection: {
    fill: BackgroundColorProperty
    primaryText: ColorProperty
  };
  elementSelection: ElementSelectionColors;
}


declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    metus: MetusThemeExtension;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    metus?: MetusThemeExtension;
  }
}

const metus: MetusThemeExtension = {
  typography: {
    fontFamily: "Helvetica Neue, Arial"
  },
  main: {
    fill: palette.primary.mainDark,
    fontFamily: "Helvetica Neue, Arial",
    primaryText: palette.primary.white,
    cloudIcon: "#00b8d9", // cloud icon in metus server selection
    projectIcon: "#36b37e", // project icon in metus server selection
    progressColor: "rgb(255, 153, 0)",
    progressBarColor: "rgb(157, 177, 207)",
  },
  navigation: {
    fill: palette.primary.mainSelected,
    primaryText: palette.primary.mainDark,
    secondaryText: palette.primary.mainDark,
    icon: palette.primary.mainDark,
    headerBarIconButton: palette.primary.white,
    headerBarColor: palette.primary.mainDark,
    headerBarColorNoScenario: palette.primary.mainDark,  //palette.secondary.main, TODO: check if we can use a color in the header bar (not allowed by pwc brand guidlines...)
    iconDivider: palette.primary.mainDark,
    editorScrollBar: palette.primary.mainDarkerTint,
    editorScrollBarBackground: palette.primary.mainSelected,
    filterText: palette.primary.mainDark,
    iconTextActive: palette.primary.main,
    iconText: palette.primary.mainDark,
    active: {
      highlight: palette.secondary.highlight,
      fill: palette.secondary.main,
      secondaryFill: palette.primary.main,
      icon: palette.primary.main
    },
    disabled: {
      fill: palette.primary.notYetSet, // todo set to appropriate colors
      cursor: "not-allowed"
    },
    width: 64,
    secondLevelFill: palette.primary.mainSelected,
    levelIndent: 24,
  },
  properties: {
    fill: palette.primary.main,
    primaryText: palette.primary.mainDark,
    secondaryText: palette.primary.notYetSet,
    icon: palette.primary.mainDark,
    iconDivider: palette.primary.mainDark,
    iconText: palette.primary.notYetSet,
    iconTextActive: palette.primary.notYetSet,
    filterText: palette.primary.notYetSet,
    active: {
      highlight: palette.secondary.highlight,
      fill: palette.primary.mainSelected,
      secondaryFill: palette.primary.notYetSet, // todo set to appropriate colors
      icon: palette.primary.mainDark
    },
    disabled: { // necessary here? currently not used
      fill: palette.primary.notYetSet, // todo set to appropriate colors
      cursor: "not-allowed"
    },
    width: 56,
  },
  selection: {
    fill: palette.secondary.main, // active border of editor
    primaryText: "#FFFFFF"
  },
  dialog: {
    buttonFill:  palette.secondary.main,
    buttonFillOnHover: palette.secondary.light,
    fill: "#ffffff",
    headerText: palette.primary.mainDark,
    primaryText: palette.primary.mainDark,
    secondaryText: palette.primary.mainDark,
    dialogBackgroundColor: palette.primary.white,
    errorFooterColor: palette.primary.errorColor,
    errorTypography: palette.primary.errorColor,
  },
  elementSelection: metusElementSelectionColors
};

// spacing
const theme: Theme = createMuiTheme({
  metus,
  palette,
  typography,
  overrides: {
    MuiListItemIcon: {
      root: {
        color: "inherit",
      }
    },
    MuiTypography: {
      body1: {
        color: "inherit",
        fontSize: "14px", // list item in nav bar
      },
      subtitle1: {
        color: "inherit",
      }
    },
    MuiIconButton: {
      root: {
        borderRadius: 0,
        padding: 0,
      },
    },
    MuiButton: {
      root: {
        fontWeight: 400, // normal
      },
      contained: {
        maxWidth: "240px",
        width: "100%",
        height: 8 * 4, // 8 is theme.spacing.unit
        borderRadius: 8 / 2,
        fontSize: 8 * 2,
        "&:hover": {
          opacity: 0.9,
          // backgroundColor: theme.metus.properties.active.fill,
        },
        color: "white",
        fontWeight: 400, // normal
        boxShadow: "none",
      }
    } as any, // TODO MB
    MuiPaper: {},
    MuiInput: {
      root: {
        "&$error": {
          "&:after": {
            backgroundColor: "#de323c",
            transform: "" // delete error transform
          }
        }
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
      },
      label: {
        marginLeft: 4,
      }
    }
  }
});

export const startScreenTheme = createMuiTheme({
  metus,
  palette,
  typography,
  overrides: {
    MuiTypography: {
      subtitle1: {
        color: "inherit",
      }
    }
  }
});


export default theme;
