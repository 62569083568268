/* ChartIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2018
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";


class ChartIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <g id="co-/-chart" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
          d="M47,23.237 L47,28.764 C47,29.999 45.999,31 44.764,31 L33.236,31 C32.001,31 31,29.999 31,28.764 L31,27 L29.003,27 C28.45,27 28,27.451 28,28.003 L28,51.997 C28,52.55 28.45,53 29.003,53 L31,53 L31,51.237 C31,50.001 32.001,49 33.236,49 L44.764,49 C45.999,49 47,50.001 47,51.237 L47,56.764 C47,57.999 45.999,59 44.764,59 L33.236,59 C32.001,59 31,57.999 31,56.764 L31,55 L29.003,55 C27.347,55 26,53.654 26,51.997 L26,28.003 C26,26.347 27.347,25 29.003,25 L31,25 L31,23.237 C31,22.001 32.001,21 33.236,21 L44.764,21 C45.999,21 47,22.001 47,23.237 Z M33,56.764 C33,56.895 33.106,57 33.236,57 L44.764,57 C44.895,57 45,56.895 45,56.764 L45,51.237 C45,51.106 44.895,51 44.764,51 L33.236,51 C33.106,51 33,51.106 33,51.237 L33,56.764 Z"
          id="Combined-Shape" fillOpacity="0.5" fill="#FFFFFF"></path>
      <path d="M71,44.237 L71,49.764 C71,50.999 69.999,52 68.764,52 L57.236,52 C56.001,52 55,50.999 55,49.764 L55,48 L53.003,48 C52.45,48 52,48.451 52,49.003 L52,58.997 C52,59.55 52.45,60 53.003,60 L55,60 L55,58.237 C55,57.001 56.001,56 57.236,56 L68.764,56 C69.999,56 71,57.001 71,58.237 L71,63.764 C71,64.999 69.999,66 68.764,66 L57.236,66 C56.001,66 55,64.999 55,63.764 L55,62 L53.003,62 C51.347,62 50,60.654 50,58.997 L50,49.003 C50,47.347 51.347,46 53.003,46 L55,46 L55,44.237 C55,43.001 56.001,42 57.236,42 L68.764,42 C69.999,42 71,43.001 71,44.237 Z M69,44.237 C69,44.106 68.895,44 68.764,44 L57.236,44 C57.105,44 57,44.106 57,44.237 L57,49.764 C57,49.895 57.105,50 57.236,50 L68.764,50 C68.895,50 69,49.895 69,49.764 L69,44.237 Z M57,63.764 C57,63.895 57.105,64 57.236,64 L68.764,64 C68.895,64 69,63.895 69,63.764 L69,58.237 C69,58.106 68.895,58 68.764,58 L57.236,58 C57.105,58 57,58.106 57,58.237 L57,63.764 Z" id="Combined-Shape" fill="#FFFFFF"></path>
      <path
          d="M57.236,24 L68.764,24 C69.999,24 71,22.999 71,21.764 L71,16.237 C71,15.001 69.999,14 68.764,14 L57.236,14 C56.001,14 55,15.001 55,16.237 L55,18 L53.003,18 C51.347,18 50,19.347 50,21.003 L50,30.997 C50,32.654 51.347,34 53.003,34 L55,34 L55,35.764 C55,36.999 56.001,38 57.236,38 L68.764,38 C69.999,38 71,36.999 71,35.764 L71,30.237 C71,29.001 69.999,28 68.764,28 L57.236,28 C56.001,28 55,29.001 55,30.237 L55,32 L53.003,32 C52.45,32 52,31.55 52,30.997 L52,21.003 C52,20.451 52.45,20 53.003,20 L55,20 L55,21.764 C55,22.999 56.001,24 57.236,24 Z"
          id="Path" fillOpacity="0.5" fill="#FFFFFF"></path>
      <path
          d="M23,37.237 L23,42.764 C23,43.999 21.999,45 20.764,45 L9.236,45 C8.001,45 7,43.999 7,42.764 L7,37.237 C7,36.001 8.001,35 9.236,35 L20.764,35 C21.999,35 23,36.001 23,37.237 Z"
          id="Path" fillOpacity="0.5" fill="#FFFFFF"></path>
    </g>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(ChartIcon);
