/* Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by MeegenM, Juli 2017
 */
import {VisualElementId, VisualElementIdString} from "../../../core/utils/Core";
import * as dagre from "dagre";
import {LayoutAlgorithm} from "../../../api/api";
import {VisualChartColumn} from "../../models/chart/VisualChartColumn";
import {VisualChartElement} from "../../models/chart/VisualChartElement";
import {VisualConnection} from "../../models/chart/VisualConnection";
import {AutoLayoutOptions} from "./AutoLayoutOptions";

import layout from "./DagreRankedLayout";
import {Validate} from "../../../common/utils/Validate";

export interface NodeProperties {
  rank: number;
  order: number;
};

function getNode(tables: VisualChartColumn[], nodeId: VisualElementId): VisualChartElement {
  let result: VisualChartElement = null;
  if (nodeId) {
    tables.forEach(table => {
      if (!result) {
        result = table.visualElements.get(nodeId.toKey());
        return;
      }
    });
  }
  return result;
}

export function layoutHierarchy(tables: VisualChartColumn[], edges: VisualConnection[], yOffset: number, options: AutoLayoutOptions): void {
  switch (options.algorithm) {
    case LayoutAlgorithm.DAGRE:
      layoutDagreHierarchy(tables, edges, yOffset, options);
      break;
    default:
      Validate.isTrue(false, "Layout Algorithm not supported: " + LayoutAlgorithm[options.algorithm]);
  }
}

function layoutDagreHierarchy(tables: VisualChartColumn[], edges: VisualConnection[], yOffset: number, options: AutoLayoutOptions): void {
  const dagreOptions = {
        rankdir: "RL",
        nodesep: "10", /** vertical space */
        ranksep: "50", /** horizontal space */
        marginx: 50,
        marginy: yOffset,
        ranker: "none",
        preserveorder: options.preserveOrdering ? 1 : 0
      }
  ;

  if (tables.length === 0) {
    return;
  }
  const g = createDagreGraph(tables, edges, dagreOptions);
  layout(g);

// write back new y positions
  g.nodes().forEach((key: VisualElementIdString) => {
    const n = getNode(tables, VisualElementId.fromKey(key));
    const dnode = g.node(key);
    // if node was part of layout subgraph, set y
    if (!Number.isNaN(dnode.y)) {
      n.y = dnode.y;
    } else {
      console.error("This should not happen", dnode);
    }
    // for debugging rank assignment
    // n._x = g.node(key).x;
  });
}

export function createDagreGraph(tables: VisualChartColumn[], edges: VisualConnection[], dagreOptions: any = {
  rankdir: "RL",
  nodesep: "10", /** horizontal space */
  ranksep: "50", /** horizontal space */
  marginx: 50,
  marginy: 150,
  ranker: "none",
  preserveorder: 1
}): dagre.graphlib.Graph<NodeProperties> {
// build layout graph for dagre
  const g = new dagre.graphlib.Graph<NodeProperties>();
  g.setGraph(dagreOptions);
  g.setDefaultEdgeLabel(function (): any {
    return {};
  });
  let rank: number = 1;
  tables.forEach(t => {
    t.filteredNodes.forEach(n => {
      g.setNode(n.id.toKey(), {label: n.title, width: n.width, height: n.height, rank: rank});
    });
    rank++;
  });
  edges.forEach((edge: VisualConnection) => {
    const source = g.node(edge.source.id.toKey());
    const target = g.node(edge.target.id.toKey());
    if (source !== undefined && target !== undefined) {
      // make paths directed
      if (source.rank < target.rank) {
        g.setEdge(edge.source.id.toKey(), edge.target.id.toKey());
      } else {
        g.setEdge(edge.target.id.toKey(), edge.source.id.toKey());
      }
    }
  });

  if (preserveOrder) {
    // pre assign order by rank and y-position
    /** map of all nodes in this graph */
    const yCoords: Map<VisualElementIdString, number> = new Map();
    tables.forEach(t => t.filteredNodes.forEach(n => yCoords.set(n.id.toKey(), n.y)));
    preserveOrder(g, yCoords);
  }
  return g;
}

export function preserveOrder(g: dagre.graphlib.Graph<NodeProperties>, y: Map<VisualElementIdString, number>): void {
  let maxRank = 0;
  g.nodes().forEach(k => {
    maxRank = Math.max(maxRank, g.node(k).rank);
  });
  for (let i = 0; i <= maxRank; i++) {
    let o = 0;
    const sorted = g.nodes().filter(k => g.node(k).rank === i).sort((k1, k2) => y.get(k1) - y.get(k2));
    sorted.forEach(k => {
      g.node(k).order = o++;
    });
  }
}


