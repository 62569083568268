/* ProductionIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, März 2019
 */
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";
import {withStyles} from "@material-ui/core";
import * as React from "react";

class ProductionIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <style type="text/css">{`
      .st0production{fill:#FFFFFF;}
      .st1production{fill:#FFFFFF;fill-opacity:0.5;}
     `}</style>
      <path className="st0production" d="M66.29,41.05l-1.69-4.33l0.11,0l0.11-0.01l0.15-0.02l0.17-0.04l0.19-0.06l0.15-0.06l0.15-0.08l0.09-0.05
	l0.11-0.07l0.1-0.09l0.09-0.09l0.08-0.09l0.1-0.12l0.05-0.07l0.06-0.09l1.79,4.57c0.19,0.5-0.05,1.06-0.55,1.26v0
	C67.05,41.8,66.48,41.55,66.29,41.05z"/>
      <path className="st1production" d="M46.85,45.93H33.4c0,2.88-2.33,5.21-5.21,5.21s-5.21-2.33-5.21-5.21h-7.57c-1.3,0-2.35,1.05-2.35,2.35v9.42
	c0,1.3,1.05,2.35,2.35,2.35h31.44c1.3,0,2.35-1.05,2.35-2.35v-9.42C49.2,46.98,48.15,45.93,46.85,45.93z"/>
      <g>
        <path className="st1production" d="M40.54,21.55l-8.22,25.46c-0.74,2.29-3.2,3.55-5.5,2.81l0,0c-2.29-0.74-3.55-3.2-2.81-5.5l8.22-25.46
		c0.74-2.29,3.2-3.55,5.5-2.81l0,0C40.02,16.8,41.28,19.26,40.54,21.55z"/>
        <circle className="st0production" cx="36.28" cy="20.5" r="3.62"/>
        <circle className="st0production" cx="28.2" cy="45.6" r="3.62"/>
      </g>
      <g>
        <path className="st1production" d="M62.99,35.89l-6.98-9.92c-0.63-0.89-0.41-2.13,0.48-2.76l0,0c0.89-0.63,2.13-0.41,2.76,0.48l6.98,9.92
		c0.63,0.89,0.41,2.13-0.48,2.76v0C64.86,37,63.62,36.78,62.99,35.89z"/>
        <circle className="st0production" cx="64.54" cy="34.64" r="1.64"/>
        <circle className="st0production" cx="57.65" cy="24.86" r="1.64"/>
      </g>
      <path className="st1production" d="M40.67,23.94l15.19,2.88c-0.47-0.49-0.75-1.15-0.75-1.88c0-1.21,0.79-2.23,1.88-2.59l-16.5-5.56
	c0.46,0.69,1.02,1.59,1.04,2.47c0.01,0.25,0.03,0.62-0.02,0.99C41.28,21.77,40.67,23.94,40.67,23.94z"/>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(ProductionIcon);
