import rootlog, {Classifier, ClassifierLogger, LogLevel} from "./ClassifierLogger";
import {Logger} from "loglevel";
// 2. Log Formatter
// logEvent formatter, https://www.npmjs.com/package/loglevel-prefix


// There are two loglevel plugins, which are preconfigured here.

// Note that there is one disadvantage of using loglevel plugins:
// if logging is done via a plugin, the line number in the browser console is not the original logging
// location but the location of a wrapper function within the plugin.

// 1. Remote Logging
// switch on remote logging, logs per default by posting to <current-url>/logger
// see webpack.config.js

//  import remote from "loglevel-plugin-remote";
// remote.apply(logEvent);
//noinspection JSUnusedGlobalSymbols
const prefix_options = {
  template: "[%t] %l - %n:",
  timestampFormatter: (date: Date): string => date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1"),
  levelFormatter: (level: string): string => level.toUpperCase(),
  nameFormatter: (name: string): string => name || "root"
};
// careful: when calling prefix.apply, it also sets the level of rootlog to "WARN" - and persists the value
// prefix.apply(rootlog, prefix_options); localStorage.removeItem("loglevel");

// Note: if setting levels does not work as expected, the local storage might contain
// persisted settings from previous sessions. Please remove all local storage entries of loglevel
// (in chrome open developer tools --> Application, left tree Storage - Local Storage - http://localhost:8088 - right click - clear

// the logEvent level for any component unless it is explicitly overwritten (see below)
rootlog.setDefaultLevel(LogLevel.INFO);


//noinspection JSUnusedLocalSymbols
const persist = false; /* do not write to local storge */

rootlog.getLogger("RestCallUtil").setLevel(LogLevel.DEBUG);
rootlog.getLogger("InteractionStore").setLevel(LogLevel.DEBUG);
// ClassifierLogger.setClassifierLevelForComponent("properties", Classifier.render, LogLevel.DEBUG);
// set graph to level debug, which also includes its classifiers event and dnd
// rootlog.getLogger("MatrixModel").setLevel(LogLevel.DEBUG, persist);
// rootlog.getLogger("MatrixComponent").setLevel(LogLevel.DEBUG, persist);
// rootlog.getLogger("InteractionStore").setLevel(LogLevel.DEBUG, persist);
// rootlog.getLogger("MainNavigationComponent").setLevel(LogLevel.DEBUG, persist);

// set all event loggers to INFO and thereby overwriting the level of the parent component
// ClassifierLogger.setLevelForClassifier(Classifier.dnd, LogLevel.DEBUG);

// set event logging of component "diagram" to DEBUG
// ClassifierLogger.setClassifierLevelForComponent("diagram", Classifier.event, LogLevel.DEBUG);

// ClassifierLogger.setClassifierLevelForComponent("PanZoomScrollableSVGComponent", Classifier.update, LogLevel.DEBUG);

class MetusLog  {
  static logger(component: string, classifier?: Classifier): Logger {
    return new ClassifierLogger(component, classifier).getLog();
  }
}
export default MetusLog;
