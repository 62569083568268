export enum VisitState {
  /** continue visiting, standard behavior to visit all visuals */
  CONTINUE,
  /** do not visit subtree of current visual, a subtree can be pruned */
  SKIP_SUBTREE,
  /** terminate visiting e.g. if only one matching visual is searched */
  TERMINATE
}

/**
 * will be called for eacht visual in the tree to visit
 * @param {T} visual currently visited item
 * @returns {VisitState} CONTINUE if visit should be continued in this subtree, SKIP_SUBTREE if not, TERMINATE if no more visual should be visited
 */
export type IVisitCallback<T = any> = (visual: T) => VisitState;

export interface IVisitable {
  /**
   * internal visitor implementation, must be implemented for each IVisitable to traverse the tree left-to-right depth first (but inner nodes are returned before their subtree nodes) according to the VisitState result. The implementor must make sure all childrens accept will be called too.
   * @param {IVisitCallback} v
   * @returns {boolean} true if result was accepted, visiting will be continued; false will stop the visit traversal process
   */
  accept(v: IVisitCallback): boolean;
}

export function DefaultAcceptImpl<T = any, C extends IVisitable = IVisitable>(current: T, visitCallback: IVisitCallback, childIterator: Iterator<C>): boolean {
  let result: boolean = true;
  const parentVisitState: VisitState = visitCallback(current);
  let childResult = true;
  switch (parentVisitState) {
    case VisitState.CONTINUE:
      // traverse children
      let next = childIterator.next();
      // abort traversal if child wants to abort;
      while (childResult && !next.done) {
        childResult = next.value.accept(visitCallback);
        next = childIterator.next();
      }
      result = childResult;
      break;
    case VisitState.SKIP_SUBTREE:
      // do not visit children
      result = true;
      break;
    case VisitState.TERMINATE:
      // terminate
      result = false;
      break;
  }
  return result;
}