import {VisualChartElement} from "./VisualChartElement";
import {observable} from "mobx";
import {Rect} from "../../../common/utils/Geometry";

export class VisualConnection implements Rect {
  @observable source: VisualChartElement;
  @observable target: VisualChartElement;

  constructor(source: VisualChartElement, target: VisualChartElement) {
    this.source = source;
    this.target = target;
  }

  get id(): string {
    return this.source.id.toKey() + "->" + this.target.id.toKey();
  }

  get visible(): boolean {
    return this.source.visible && this.target.visible;
  }

  get x(): number {
    return this.source.x;
  }

  get y(): number {
    return this.source.y;
  }

  get width(): number {
    return this.target.x - this.source.x;
  }

  get height(): number {
    return this.target.y - this.source.y;
  }
}