/**
 * configuration wraps create-react-app conventions
 */
import {version} from "../../../package.json";

export class Configuration {
  /** REST server BASE URL
   * development mode: use metusrestserver as host name, developers must create an entry in /etc/hosts file
   */
  static readonly API_SERVER_URL: string = "";

  static version(): string {
    const buildnr = typeof process.env.REACT_APP_BUILD_NUMBER === "undefined" || process.env.REACT_APP_BUILD_NUMBER == "" ?  "0" : process.env.REACT_APP_BUILD_NUMBER;
    return version + "." + buildnr;
  }

  static isDevelopment(): boolean {
    return (typeof process.env.NODE_ENV === "undefined") || process.env.NODE_ENV !== "production";
  }

}
