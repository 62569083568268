import {SelectionKind} from "../../core/utils/Core";
import {CSSProperties} from "react";
import {StyleUtil} from "../../core/utils/StyleUtil";
import {DefaultStyles} from "../../api/api";
import theme, {metusElementSelectionColors} from "../../common/theme/Theme";
import {VisualBaseElement} from "../../diagram/models/common/CommonDiagramTypes";

export const METUS_DEFAULT_STYLES: DefaultStyles = {
  "tableHeader": {
    "fill": "#C3C3C3",
    "fontSize": "14px",
    "fontWeight": 400,
    "fontStyle": "normal",
    "fontFamily": theme.metus.main.fontFamily,
    "color": "#000000",
    "textAlign": "left",
        "verticalAlign": "middle",
        "strokeWidth": 0,
        "stroke": "rgb(0,0,0)"
      },
      "visualElement": {
        "fill": "#F3F3F3",
        "fontSize": "8pt",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontFamily": theme.metus.main.fontFamily,
        "color": "rgb(0,0,0)",
        "textAlign": "left",
        "verticalAlign": "middle",
        "strokeWidth": 1,
        "stroke": "rgb(0,0,0)"
      },
      "attributeValue": {
        "fontSize": "8pt",
        "fontWeight": "normal",
        "fontStyle": "normal",
        "fontFamily": theme.metus.main.fontFamily,
        "color": "rgb(0,0,0)",
        "textAlign": "left",
        "verticalAlign": "middle",
        "strokeWidth": 0,
        "stroke": "rgb(0,0,0)"
      },
      "attributeHeader": {
        "fontSize": "12px",
        "fontWeight": 400,
        "fontStyle": "normal",
        "fontFamily": theme.metus.main.fontFamily,
        "color": "#000000",
        "textAlign": "left",
        "verticalAlign": "middle",
        "strokeWidth": 0,
        "stroke": "rgb(0,0,0)"
      }
    }
;
export class ElementStyleUtil {
// handles color/fill according to selection state
  public static updateNodeTextStyle(vNode: VisualBaseElement, defaultStyles: any): CSSProperties {
    // the filter for node text style excludes fill
    const stylesToConvert = vNode.styles;
    const result = StyleUtil.updateStyles(stylesToConvert, defaultStyles, StyleUtil.toNodeTextStyle);

    let color = null;

    switch (vNode.selection) {
      case SelectionKind.Primary:
        color = "white";
        break;
      default:
        color = (stylesToConvert && stylesToConvert["color"]) || defaultStyles && defaultStyles["color"];
        break;
    }
    if (color) {
      result.fill = color;
    }

    return result;
  }

  public static updateNodeRectStyle(vNode: VisualBaseElement, defaultStyles: any): CSSProperties {
    const stylesToConvert: CSSProperties = vNode.styles;
// the filter for node rect style excludes fill
    const result: CSSProperties = StyleUtil.updateStyles(stylesToConvert, defaultStyles, StyleUtil.toNodeRectStyle);

// ... which must now be handled according to selection state
    let fill = null;
    switch (vNode.selection) {
      case SelectionKind.None:
        fill = (stylesToConvert && stylesToConvert["fill"]) || defaultStyles && defaultStyles["fill"];
        break;
      case SelectionKind.Primary:
        fill = metusElementSelectionColors.primary;
        break;
      case SelectionKind.Secondary:
        fill = metusElementSelectionColors.secondary;
        break;
      default:
        //
    }
    if (fill) {
      result.fill = fill;
    }
    return result;
  }
}