/* LeftSubtreeIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Januar 2019
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../../common/icons/menu/MetusSvgIcon";

class LeftSubtreeIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <g id="order_L" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.5">
      <path
          d="M10.4229,10.985 C10.2369,10.703 10.0049,10.459 9.7479,10.241 L18.1639,4 L18.7589,4.802 L10.4229,10.985 Z M20.0002,12.401 L20.0002,13.401 L10.9492,13.401 L10.9492,12.401 L20,12.401 L20.0002,12.401 Z M9.748,15.5612 C10.005,15.3432 10.237,15.0992 10.423,14.8172 L18.759,20.9992 L18.164,21.8022 L9.748,15.5612 Z M7.5,14.401 C8.329,14.401 9,13.73 9,12.901 C9,12.072 8.329,11.401 7.5,11.401 C6.671,11.401 6,12.072 6,12.901 C6,13.73 6.671,14.401 7.5,14.401 Z M7.5,10.401 C8.878,10.401 10,11.523 10,12.901 C10,14.279 8.878,15.401 7.5,15.401 C6.122,15.401 5,14.279 5,12.901 C5,11.523 6.122,10.401 7.5,10.401 Z"
          id="Combined-Shape" fill="#000000"></path>
    </g>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(LeftSubtreeIcon);