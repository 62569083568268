import {
  Button,
  createStyles,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {attributeFormatTypeItems} from "../../common/constants/Enums";
import * as React from "react";
import autobind from "autobind-decorator";
import {StyleRules} from "@material-ui/core/styles";
import {AttributeFormatType} from "../../api/api";
import MetusTextField from "../../common/components/MetusTextField";
import Log from "../../common/utils/Logger";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {ShowDialogAction} from "../../common/actions/InteractionStateActions";
import EditAttributeNumberFormatDialog from "./EditAttributeNumberFormatDialog";
import EditAttributeDateFormatDialog from "./EditAttributeDateFormatDialog";

const log = Log.logger("EditAttributeFormatComponent");

const MD_DATE_FORMAT_PATTERN: string = "dd.MM.yyyy";
const MD_NUMBER_DEFAULT_FORMAT_PATTERN: string = "0,00";

const styles = (theme: Theme): StyleRules => {
  return createStyles({});
}

interface LocalProps {
  attributeFormatType?: AttributeFormatType;
  formatString?: string;
  onFormatTypeChange: any;
  onFormatStringChange: any;
  formatStringMissing: boolean;
}

interface LocalState {
  attributeFormatType: AttributeFormatType;
  formatString: string;
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class EditAttributeDefinitionFormatTypeComponent extends React.Component<StyledLocalProps, LocalState> {

  formatTypeToFormatStringMap: Map<AttributeFormatType, string> = new Map<AttributeFormatType, string>();

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {
      attributeFormatType: props.attributeFormatType || "String",
      formatString: props.formatString || "",
    };
    if (props.attributeFormatType) {
      this.formatTypeToFormatStringMap.set(props.attributeFormatType, props.formatString);
    }
  }


  render(): JSX.Element {
    return <div>
      <RadioGroup
          name="attributeFormatType"
          value={this.state.attributeFormatType}
          onChange={this.handleAttributeFormatTypeSelect}>
        {attributeFormatTypeItems.map(afti => {
          return <FormControlLabel key={afti.name}
                                   data-testselector={`listitem-${afti.type}`}
                                   classes={{label: this.props.classes.formControlLabel}}
                                   value={afti.type}
                                   label={afti.name}
                                   control={<Radio/>}/>;
        })}
      </RadioGroup>
      {<div>
        <MetusTextField
            disabled={this.state.attributeFormatType === "String"}
            inputProps={{classes: {input: this.props.classes.input}}}
            id="formatString"
            placeholder="Format"
            value={this.state.formatString}
            error={this.props.formatStringMissing}
            onChange={this.handleChangeFormatString}
        />
        <Button disabled={this.state.attributeFormatType === "String"}
                onClick={this.handleCustomizeButtonPressed}>Customize</Button>
      </div>}
    </div>
  }

  @autobind
  private handleAttributeFormatTypeSelect(event: any): void {
    if (this.state.attributeFormatType !== event.target.value) {
      const formatString = this.formatTypeToFormatStringMap.get(event.target.value) || this.getDefaultFormatPattern(event.target.value);
      this.setState({
        attributeFormatType: event.target.value,
        formatString
      });
      this.props.onFormatTypeChange({
        attributeFormatType: event.target.value,
        formatString: formatString
      })
    }
  }

  private getDefaultFormatPattern(attributeFormatType: AttributeFormatType): string {
    let retVal: string;

    if (attributeFormatType === "Date") {
      retVal = MD_DATE_FORMAT_PATTERN;
    } else if (attributeFormatType === "Double") {
      retVal = MD_NUMBER_DEFAULT_FORMAT_PATTERN;
    }

    return retVal;
  }

  @autobind
  private handleChangeFormatString(event: any): void {
    const formatString = event.target.value;
    log.debug("formatString " + formatString);
    this.formatTypeToFormatStringMap.set(this.state.attributeFormatType, formatString);
    this.setState({formatString});
    this.props.onFormatStringChange(formatString);
  }

  @autobind
  private handleCustomizeButtonPressed(event: any) {
    if (this.state.attributeFormatType === "Double") {
      Dispatcher.dispatch(new ShowDialogAction(true, <EditAttributeNumberFormatDialog
          open={true}
          onSubmit={this.handleChangeFormatString}
          pattern={this.state.formatString}/>));
    } else if (this.state.attributeFormatType === "Date") {
      Dispatcher.dispatch(new ShowDialogAction(true, <EditAttributeDateFormatDialog
          open={true}
          onSubmit={this.handleChangeFormatString}
          pattern={this.state.formatString}/>));
    }
  }

}

export default withStyles(styles)(EditAttributeDefinitionFormatTypeComponent);
