/* CockpitsIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2018
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class Logo extends MetusSvgIcon {
  svg(): JSX.Element {
    return <g id="metus_pwc_logo" data-name="metus_pwc_logo">
      <g>
        <path d="M168.55,7.95l11.72,31.24,11.77-31.24h6.77V45.12h-4.69V14.19h-.1l-11.61,30.93h-4.22l-11.61-30.93h-.1v30.93h-4.69V7.95h6.77Z" />
        <path d="M232.7,7.95v4.17h-20.72v11.82h19.32v4.17h-19.32v12.86h20.88v4.17h-25.83V7.95h25.67Z" />
        <path d="M234.88,12.11V7.95h29.73v4.17h-12.39V45.12h-4.95V12.11h-12.39Z" />
        <path d="M294.6,42.39c-2.53,2.38-6.14,3.57-10.83,3.57s-8.53-1.14-11.22-3.41c-2.69-2.27-4.04-5.89-4.04-10.86V7.95h4.95V31.69c0,3.33,.89,5.86,2.66,7.58,1.77,1.72,4.32,2.58,7.65,2.58,3.16,0,5.56-.86,7.21-2.58,1.65-1.72,2.47-4.24,2.47-7.58V7.95h4.95V31.69c0,4.76-1.27,8.32-3.8,10.7Z" />
        <path d="M324.93,13.02c-1.65-1.2-3.76-1.8-6.33-1.8-1.04,0-2.07,.1-3.07,.31-1.01,.21-1.9,.56-2.68,1.04-.78,.49-1.41,1.14-1.87,1.95-.47,.82-.7,1.83-.7,3.05,0,1.15,.34,2.07,1.02,2.79s1.58,1.29,2.71,1.74c1.13,.45,2.4,.82,3.83,1.12,1.42,.29,2.87,.62,4.35,.96,1.48,.35,2.92,.76,4.35,1.22,1.42,.47,2.7,1.1,3.83,1.9,1.13,.8,2.03,1.81,2.71,3.02,.68,1.21,1.02,2.74,1.02,4.58,0,1.98-.44,3.67-1.33,5.08-.89,1.41-2.02,2.55-3.41,3.44-1.39,.89-2.94,1.53-4.66,1.93s-3.43,.6-5.13,.6c-2.08,0-4.05-.26-5.91-.78-1.86-.52-3.49-1.31-4.89-2.37-1.41-1.06-2.52-2.41-3.33-4.06-.82-1.65-1.22-3.6-1.22-5.86h4.69c0,1.56,.3,2.91,.91,4.04,.61,1.13,1.41,2.06,2.4,2.79s2.14,1.27,3.46,1.61c1.32,.35,2.67,.52,4.06,.52,1.11,0,2.23-.1,3.36-.31,1.13-.21,2.14-.56,3.05-1.07,.9-.5,1.63-1.19,2.19-2.06,.56-.87,.83-1.98,.83-3.33s-.34-2.33-1.02-3.12-1.58-1.45-2.71-1.95c-1.13-.5-2.4-.91-3.83-1.22-1.42-.31-2.87-.63-4.35-.96-1.48-.33-2.92-.71-4.35-1.15-1.42-.43-2.7-1.01-3.83-1.72-1.13-.71-2.03-1.63-2.71-2.76s-1.02-2.54-1.02-4.24c0-1.87,.38-3.5,1.15-4.87,.76-1.37,1.78-2.5,3.05-3.38,1.27-.89,2.71-1.54,4.32-1.98,1.61-.43,3.27-.65,4.97-.65,1.91,0,3.68,.23,5.31,.68,1.63,.45,3.06,1.16,4.3,2.13,1.23,.97,2.2,2.2,2.92,3.67,.71,1.48,1.1,3.24,1.17,5.29h-4.69c-.28-2.67-1.24-4.61-2.89-5.81Z" />
      </g>
      <g>
        <g>
          <path d="M127,44.79c-5.85,0-9.97-1.53-13.34-4.97-3.38-3.38-5.17-7.9-5.17-13.07,0-11.32,8.28-18.92,20.6-18.92,8.1,0,13.54,3.57,13.54,8.88,0,3.33-2.46,5.57-6.12,5.57-1.83,0-3.34-.44-5.33-1.56V10.71l-.58,.09c-6.48,1.05-9.77,5.79-9.77,14.11s4.49,14.01,11.18,14.01c3.12,0,5.98-1.09,10.98-3.52v5.43c-6.58,2.99-10.5,3.96-16,3.96Z"/>
          <path d="M129.09,8.33c7.8,0,13.04,3.37,13.04,8.38,0,3.03-2.26,5.07-5.61,5.07-1.66,0-3.05-.38-4.83-1.35V10.13l-1.16,.19c-8.42,1.36-10.19,8.69-10.19,14.6,0,8.68,4.69,14.51,11.68,14.51,3.06,0,5.85-1,10.48-3.22v4.31c-6.33,2.86-10.17,3.78-15.5,3.78-5.71,0-9.71-1.49-12.99-4.82-3.28-3.28-5.02-7.68-5.02-12.72,0-11.02,8.08-18.42,20.1-18.42m0-1c-12.53,0-21.1,7.84-21.1,19.42,0,5.21,1.77,9.88,5.31,13.42,3.45,3.52,7.68,5.12,13.69,5.12,5.55,0,9.61-.98,16.5-4.14v-6.55c-5.74,2.82-8.47,3.82-11.48,3.82-6.46,0-10.68-5.41-10.68-13.51s3.16-12.62,9.35-13.61v9.71c2.13,1.24,3.8,1.77,5.83,1.77,3.95,0,6.61-2.47,6.61-6.07,0-5.58-5.72-9.38-14.04-9.38h0Z" />
        </g>
        <g>
          <path d="M76.58,44.17V20.24l-14.56,23.93h-8.41V15.01l-7.11-.72v-2.49l12.6-3.07h4.41v25.12l15.15-25.12h7.86v28.61l10.09-13.45V9.68c.91-.92,2.71-1.85,4.73-1.85,3.28,0,5.84,2.49,5.84,5.67s-2.03,6.17-7.4,12.94l-13.86,17.73h-9.33Z"/>
          <path d="M101.32,8.33c2.99,0,5.34,2.27,5.34,5.17s-1.64,5.51-7.3,12.63l-13.71,17.54h-8.58V18.46l-1.85,3.05-13.49,22.17h-7.63V14.56l-.9-.09-6.22-.63v-1.64l12.16-2.97h3.85v26.41l1.86-3.08,14.08-23.34h7.08v29.61l1.8-2.4,9.09-12.11,.2-.27V9.89c.87-.79,2.46-1.56,4.23-1.56m0-1c-2.23,0-4.24,1.05-5.23,2.15v14.25l-9.09,12.11V8.22h-8.64l-14.37,23.82V8.22h-4.97l-13.04,3.18v3.33l7.11,.72v29.21h9.2l13.78-22.65v22.65h10.07l14.01-17.92c5.55-6.99,7.51-9.82,7.51-13.25s-2.71-6.17-6.34-6.17h0Z"/>
        </g>
        <g>
          <path d="M1.59,60.35v-2.49l5.33-1.33V14.77H1v-2.63L14.89,7.92h3.14v5.26l.76-.45c6.65-3.96,8.87-4.62,12.19-4.62,7.71,0,13.3,7.13,13.3,16.96,0,11.61-7.71,19.11-19.64,19.11-.69,0-3.17-.03-6.04-.36l-.56-.06v12.79l5.96,1.33v2.48s-22.39,0-22.39,0ZM22.68,14.77c-1.03,0-2.1,.12-4.22,.38l-.44,.05v25.45l.48,.02c.99,.05,1.42,.05,2.05,.05,7.77,0,11.88-4.83,11.88-13.97,0-7.73-3.46-11.98-9.74-11.98Z"/>
          <path d="M17.52,8.42v5.64l1.51-.9c6.55-3.9,8.72-4.55,11.93-4.55,7.42,0,12.8,6.92,12.8,16.46,0,11.3-7.51,18.61-19.14,18.61-.68,0-3.14-.03-5.99-.35l-1.11-.13v13.75l.78,.17,5.18,1.15v1.58s-21.39,0-21.39,0v-1.6l4.57-1.14,.76-.19V14.27H1.5v-1.76l13.46-4.09h2.57m3.03,32.8c7.98,0,12.38-5.14,12.38-14.47,0-10.86-6.42-12.48-10.24-12.48-1.06,0-2.14,.13-4.28,.39l-.88,.11v26.37l.95,.05c1,.05,1.45,.05,2.07,.05M18.52,7.42h-3.71L.5,11.77v3.5H6.43V56.14l-5.33,1.33v3.38H24.49v-3.38s-5.96-1.33-5.96-1.33v-11.82c2.39,.27,4.71,.36,6.1,.36,12.32,0,20.14-7.89,20.14-19.61,0-10.14-5.86-17.46-13.8-17.46-3.44,0-5.74,.71-12.44,4.69V7.42h0Zm2.03,32.8c-.62,0-1.05,0-2.03-.05V15.65c2.11-.26,3.16-.38,4.16-.38,6,0,9.24,3.99,9.24,11.48,0,8.85-3.97,13.47-11.38,13.47h0Z"/>
        </g>
    </g>
    </g>
  }

  viewBox(): string {
    return "0 0 350 65";
  }
}

export default withStyles(svgIconStyles)(Logo);
