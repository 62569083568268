import {SelectionKind, VisualElementId} from "../../../core/utils/Core";
import {VisualChartColumn} from "./VisualChartColumn";
import {VisualAttributeValue} from "./VisualAttributeValue";
import {Rect} from "../../../common/utils/Geometry";
import {VisualAttributeDefinition} from "../common/VisualAttributeDefinition";
import {VisualBaseElement} from "../common/CommonDiagramTypes";
import {computed, observable} from "mobx";
import {ElementStyleUtil, METUS_DEFAULT_STYLES} from "../../../commonviews/utils/ElementStyleUtil";
import {CSSProperties} from "react";
import {IBoundedVisual, VisualObjectBase} from "../VisualObject";
import {VisualBaseAttributeValue} from "../common/VisualBaseAttributeValue";
import {list, object, serializable} from "serializr";
import {VisualValueChartElement} from "../valuechart/VisualValueChartElement";

export class VisualChartElement extends VisualObjectBase<VisualChartElement, VisualElementId> implements VisualBaseElement, IBoundedVisual {
  @serializable(list(object(VisualValueChartElement))) @observable protected _children: undefined[] = [];
  get children(): undefined[] {
    return this._children;
  }
  @serializable(object(VisualElementId)) @observable public id: VisualElementId;
  @serializable @observable private _x: number;
  @serializable @observable public y: number;
  @serializable @observable public height: number;

  @observable public title: string;
  @observable private _visible: boolean = true;
  // is an object with css props as keys and their values, e.g. { fonts-size : "14px" }
  @observable public styles: any;
  @observable public selection: SelectionKind = SelectionKind.None;
  /** maps attribute name to its value */
  @observable public attributeValues: Map<string, VisualBaseAttributeValue>;

  get x(): number {
    return this._x || this.visualTable.header.x;
  }

  get visible(): boolean {
    return this._visible;
  }

  get width(): number {
    return this.visualTable.header.width;
  }

  constructor(table: VisualChartColumn,
              id: VisualElementId,
              title: string = null,
              y: number = 0,
              height: number = 0, selectionKind: SelectionKind = SelectionKind.None) {
    super(table, id);
    this.title = title;
    this.y = y;
    this.height = height;
    this.attributeValues = new Map();
    this.selection = selectionKind;
  }

  set visible(visible: boolean) {
    if (this._visible !== visible) {
      this._visible = visible;
    }
  }

  /**
   * returns the bounds in absolute diagram (svg) coordinates
   * @returns {Point}
   */
  @computed.struct
  public get internalBounds(): Rect {
    return {x: this.x, y: this.y, width: this.width, height: this.height};
  }

  @computed.struct get textStyles(): CSSProperties {
    return ElementStyleUtil.updateNodeTextStyle(this, METUS_DEFAULT_STYLES.visualElement);
  }

  @computed.struct get rectStyles(): CSSProperties {
    return ElementStyleUtil.updateNodeRectStyle(this, METUS_DEFAULT_STYLES.visualElement);
  }

  get visualTable(): VisualChartColumn {
    return this.parent as VisualChartColumn;
  }

  addAttributeValue(visualAttributeDefinition: VisualAttributeDefinition, value: string): VisualBaseAttributeValue {
    const attributeValue = new VisualAttributeValue(visualAttributeDefinition, this, value);
    this.attributeValues.set(visualAttributeDefinition.header.name, attributeValue);
    return attributeValue;
  }
}
