import * as React from "react";

import {Classifier} from "../../../common/utils/ClassifierLogger";
import Log from "../../../common/utils/Logger";
import {ModelLocationType, PersistencyState, ViewType} from "../../../common/constants/Enums";
import {BaseSectionProperties} from "../../../properties/model/PropertiesModel";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {Archive, Print} from "@material-ui/icons";
import {StyleRules} from "@material-ui/core/styles";
import PropertiesSectionLayout from "../../../properties/components/PropertiesSectionLayout";
import PropertiesButton from "../../../properties/components/PropertiesButton";
import autobind from "autobind-decorator";
import {ExportViewAction, PrintViewAction} from "../../actions/SharedViewActions";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {showMessageDialog} from "../../../common/utils/CommonDialogUtil";
import {observer} from "mobx-react";
import {configurationStore} from "../../../core/stores/ConfigurationStore";
import {getVersionOfViewType} from "../../../common/constants/ViewVersions";

// hack to enable consultants to export svg in embedded mode using external browser
function isChromeAndNotEmbedded() {
  var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  return raw ? parseInt(raw[2], 10) > 59 : false
}
const renderLog = Log.logger("properties", Classifier.render);
const log = Log.logger("properties");

const styles = (theme: Theme): StyleRules => createStyles({
  // https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a flash,
  // so some of these are just precautions. However in IE the element
  // is visible whilst the popup box asking the user for permission for
  // the web page to copy to the clipboard.
  //
  copyTextArea: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "2em",
    height: "2em",
    padding: 0,
    border: "none",
    outline: "none",
    boxShadow: "none",
    background: "transparent"
  }
});

export interface DownloadActionsProperties extends BaseSectionProperties {
  activeViewType: ViewType;
  activeViewState: PersistencyState;
  activeViewVersion: number;
  modelLocation: ModelLocationType;
}

type StyledLocalProps = DownloadActionsProperties & WithStyles<typeof styles>;

interface LocalState {
  link?: string;
}

@observer
class DownloadActionsPropertiesComponent extends React.Component<StyledLocalProps, LocalState> {
  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    renderLog.debug("Rendering DoenloadActionsPropertiesComponent");
    const isActive = this.props.activeViewId !== null;
    const isActiveDiagram = isActive && (this.props.activeViewType === ViewType.Chart || this.props.activeViewType === ViewType.ValueChart);
    // MO-1507 copy link should not be active on views created in power viewer, but already existing views will be saved as LocallySaved too, thus better enable all
    const isPrintable = (this.props.activeViewState !== PersistencyState.New) && (configurationStore.configuration.canPrint || isChromeAndNotEmbedded());
    const hasCorrectVersion = getVersionOfViewType(this.props.activeViewType) >= this.props.activeViewVersion;
    const canDownloadSvg = configurationStore.configuration.canDownloadSVG || isChromeAndNotEmbedded();

    return <PropertiesSectionLayout>
      <PropertiesButton onClick={this.print} disabled={!isActiveDiagram || !isPrintable || !hasCorrectVersion}
                        icon={<Print/>}>Print</PropertiesButton>

      <PropertiesButton onClick={this.exportViewAsFile} disabled={!isActiveDiagram || !canDownloadSvg || !hasCorrectVersion}
                        icon={<Archive/>}>SVG</PropertiesButton>
    </PropertiesSectionLayout>;
  }

  @autobind
  private print(): void {
    showMessageDialog(true, "Preparing Print View for Diagram.\nFor big Diagrams this might take several minutes, please be patient...", true);
    Dispatcher.dispatch(new PrintViewAction(this.props.activeViewId));
  }

  @autobind
  private exportViewAsFile(): void {
    // const {appCodeName, appName, appVersion,platform,product,productSub,userAgent,vendor,vendorSub} = navigator;
    // const object = {appCodeName,appName, appVersion,platform,product,productSub,userAgent,vendor,vendorSub};
    // console.log("User Agent Info: " + JSON.stringify(object));

    showMessageDialog(true, "Exporting View as SVG.\nFor big Diagrams this might take several minutes, please be patient...", true);
    Dispatcher.dispatch(new ExportViewAction(this.props.activeViewId));
  }
}

export default withStyles(styles)(DownloadActionsPropertiesComponent);
