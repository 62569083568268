/* ModelCreateDialog.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Juni 2020
 */
import * as React from "react";
import Log from "../../common/utils/Logger";
import {
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import {Classifier} from "../../common/utils/ClassifierLogger";
import MetusDialog from "../../common/components/MetusDialog";
import {hideDialog, showDialog, ValidationResult} from "../../common/utils/CommonDialogUtil";
import {createModel} from "../actions/ModelAsyncActionCreators";
import MetusTextField from "../../common/components/MetusTextField";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {HideLoadingAction} from "../../common/actions/InteractionStateActions";
import {MultiuserMode} from "../../api/api";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
  open: boolean;
}

interface LocalState {
  modelName: string;
  validationResult: ValidationResult;
  multiuserMode: MultiuserMode;
}

const styles = (theme: Theme): StyleRules => createStyles({
  button: {
    margin: theme.spacing(1),
  },
  multiuserradio: {
    marginTop: theme.spacing(3) ,
  }

});

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class ModelCreateDialog extends React.Component<StyledLocalProps, LocalState> {
  private initialState: LocalState = {
    modelName: undefined,
    validationResult: {isError: false, errorMessage: undefined},
    multiuserMode: "lock",
  };

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = this.initialState;
  }

  getDialogContent(): JSX.Element {
    const mumo:{name:string, type:MultiuserMode}[] = [{name: "Merge / Scenario", type: "merge"}, {name: "Exclusive Lock", type: "lock"}];
    return <React.Fragment>
      <MetusTextField
          id="db-name"
          data-testselector="db-name-input-field"
          value={this.state.modelName}
          onChange={this.handleModelNameChange}
          error={this.state.validationResult.isError}
          fullWidth={true}
          autoFocus={true}
      />
      <FormControl component="fieldset" classes={{root: this.props.classes.multiuserradio}}>
        <FormLabel component="legend">Multiuser Mode</FormLabel>
        <RadioGroup
            name="multiusermode"
            value={this.state.multiuserMode}
            onChange={this.handleMultiuserModeChange}>
          {mumo.map(ati => {
            return <FormControlLabel key={ati.name}
                                     data-testselector={`listitem-${ati.type}`}
                                     classes={{label: this.props.classes.formControlLabel}}
                                     value={ati.type}
                                     label={ati.name}
                                     control={<Radio/>}/>;
          })}
        </RadioGroup>

      </FormControl>

    </React.Fragment>
  }

  render(): JSX.Element {
    return <MetusDialog
        data-testselector="ModelCreateDialog"
        title="Create new Metus Model"
        open={this.props.open}
        onClose={this.handleClose}
        primaryButtonName={"Create"}
        onPrimaryButtonPressed={this.handleCreate}
        aux1ButtonName={"Cancel"}
        onAux1ButtonPressed={this.handleClose}
        errorMessage={this.state.validationResult.errorMessage}>
      {this.getDialogContent()}
    </MetusDialog>;
  }

  @autobind
  private handleModelNameChange(event: any): void {
    let modelName = event.target.value;

    const result = ModelCreateDialog.validateModelName(modelName);

    if (!result.isError) {
      this.setState(prevState => ({validationResult: {isError: false, errorMessage: undefined}}));
    }

    this.setState(prevState => ({modelName}));
  }

  @autobind
  private handleMultiuserModeChange(event: any): void {
    const multiuserMode = event.target.value;
    if (this.state.multiuserMode !== multiuserMode) {
      this.setState({multiuserMode});
    }
  }

  @autobind
  private async handleCreate(): Promise<void> {
    const modelName = this.state.modelName;
    const result = ModelCreateDialog.validateModelName(modelName);

    if (!result.isError) {
      try {
        await createModel(modelName, this.state.multiuserMode);
        hideDialog();
      } catch (e) {
        this.setState(prevState => ({
          validationResult: {isError: true, errorMessage: e.text},
        }));
        Dispatcher.dispatch(new HideLoadingAction());
      }
    } else {
      this.setState(prevState => ({
        validationResult: result,
      }));
    }
  }

  @autobind
  private handleClose(): void {
    hideDialog();
  }

  public static validateModelName(modelName: string): ValidationResult {
    let retVal: ValidationResult = {isError: false, errorMessage: undefined};
    if (!modelName) {
      retVal.isError = true;
      retVal.errorMessage = "Please enter a model name.";
    }
    return retVal;
  }

}

const StyledModelCreateDialog = withStyles(styles)(ModelCreateDialog);
export default StyledModelCreateDialog;

export function showModelCreateDialog(modelName?: string): void {
  showDialog(true, <StyledModelCreateDialog open={true}/>);
}

