/*
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Metus Team
 */
import * as React from "react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {createStyles, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import MetusExpansionPanelComponent from "./MetusExpansionPanelComponent";
import {metusFontSizes} from "../../common/theme/Theme";
import {observer} from "mobx-react";

const log = Log.logger("properties");
const renderLog = Log.logger("properties", Classifier.render);

const styles = (theme: Theme): StyleRules => createStyles({
  root: {
    width: "100%",
  },
  sectionHeader: {
    color: theme.metus.properties.primaryText,
    fontSize: metusFontSizes.sidebarPrimaryText,
  },
  expansionPanelDetails: {
    flexDirection: "column",
    paddingLeft: theme.spacing(1) + "px",
    paddingRight: theme.spacing(1) + "px",
    paddingTop: "0px",
  },
  expansionPanelSummary: {
    height: theme.spacing(7) + "px",
    minHeight: theme.spacing(7) + "px",
  },
  expansionPanel: {
    backgroundColor: "inherit",
    margin: "0px"
  },
  expansionPanelExpanded: {
    margin: "0px",
    borderBottom: "1px solid " + theme.metus.properties.iconDivider,
    borderTop: "none",
  },
});

interface LocalProps {
  sectionId: string;
  sectionLabel: string;
  isActive: boolean;
  sectionChangeHandler: (sectionId: string) => void;
}

interface LocalState {
}


type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

@observer
class PropertiesSectionComponent extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
    this.handleSectionChange = this.handleSectionChange.bind(this);
  }

  private handleSectionChange(): void {
    log.debug("ExpansionPanelSummary clicked");
    this.props.sectionChangeHandler(this.props.sectionId);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering PropertiesSectionComponent", this.props);
    return <MetusExpansionPanelComponent
        id={this.props.sectionId}
        summaryComponent={<Typography variant="button"
                                      classes={{button: this.props.classes.sectionHeader}}>{this.props.sectionLabel}</Typography>}
        classes={{expansionPanelDetails: this.props.classes.expansionPanelDetails}}
        sectionChangeHandler={this.props.sectionChangeHandler}
        expanded={true}>
      {this.props.children}
    </MetusExpansionPanelComponent>;
  }
}

export default withStyles(styles)(PropertiesSectionComponent);