import {ElementId, TableId} from "../Core";
import {modelStore} from "../../stores/ModelStore";

/**
 * Created by P.Bernhard on 02.10.2017.
 */

export abstract class ElementGraph {
  protected _relevantElements: ElementId[];

  protected constructor(relevantElements: ElementId[]) {
    this._relevantElements = relevantElements;
  }
}


export enum NeighbourType {
  Parents,
  Children
}

export class HierarchicalElementGraph extends ElementGraph {
  // parents left (logic from st)
  // children right (logic from st)
  // hierarchy begins with level 0, for users it starts with level 1

  private _levelForTable: Map<TableId, number>;
  private _tableForLevel: Map<number, TableId>;
  private _maxLevel: number;

  private _parentsPerTable: Map<ElementId, Set<ElementId>>;
  private _childrenPerTable: Map<ElementId, Set<ElementId>>;

  private _elementsPerLevel: Map<number, Set<ElementId>>;


  constructor(hierarchy: Map<TableId, number>, relevantElements: ElementId[]) {
    super(relevantElements);

    this.checkHierarchyAndSetMaxLevel(hierarchy);
    this._levelForTable = hierarchy;
    this.reverseHierarchyMap();
    this._parentsPerTable = new Map<ElementId, Set<ElementId>>();
    this._childrenPerTable = new Map<ElementId, Set<ElementId>>();
    this._elementsPerLevel = new Map<number, Set<ElementId>>();
  }

  private checkHierarchyAndSetMaxLevel(hierarchy: Map<TableId, number>): void {
    const levels: number[] = [];
    let maxLevel: number = -1;

    const keyIterator: IterableIterator<TableId> = hierarchy.keys();
    let key: TableId = keyIterator.next().value;

    while (key !== undefined) {
      const currentLevel: number = hierarchy.get(key);
      if (maxLevel < currentLevel)
        maxLevel = currentLevel;
      levels.push(currentLevel);
      key = keyIterator.next().value;
    }

    if (levels.length !== maxLevel + 1) {
      throw new Error("TableHierarchy: The given hierarchy is not valid!");
    }

    for (let i = maxLevel; i >= 0; i--) {
      if (levels.indexOf(i) < 0)
        throw new Error("TableHierarchy: The given hierarchy is not valid!");
    }

    this._maxLevel = maxLevel;
  }

  private reverseHierarchyMap(): void {
    const tableForLevel: Map<number, ElementId> = new Map<number, ElementId>();
    const keyIterator: IterableIterator<TableId> = this._levelForTable.keys();

    let key: TableId = keyIterator.next().value;

    while (key !== undefined) {
      tableForLevel.set(this._levelForTable.get(key), key);

      key = keyIterator.next().value;
    }

    this._tableForLevel = tableForLevel;
  }

  public get numberOfLevels(): number {
    return this._maxLevel + 1;
  }

  //noinspection JSUnusedGlobalSymbols
  public getTableForLevel(level: number): TableId {
    return this._tableForLevel.get(level);
  }

  public getLevelForTable(table: TableId): number {
    return this._levelForTable.get(table);
  }

  public get relevantElements(): ElementId[] {
    return this._relevantElements;
  }

  public getTableForElement(elementId: ElementId): TableId {
    if (!this._relevantElements.includes(elementId))
      throw new Error("The given element is not a relevant element!");

    return modelStore.getTableForElement(elementId);
  }

  public get allTables(): TableId[] {
    const allTables: TableId[] = [];
    for (let i: number = 0; i <= this._maxLevel; i++) {
      allTables.push(this._tableForLevel.get(i));
    }

    return allTables;
  }

  public get relevantElementsSortedByTable(): Map<TableId, ElementId[]> {
    const retVal: Map<TableId, ElementId[]> = new Map();

    for (const elementId of this._relevantElements) {
      const tableId: TableId = modelStore.getTableForElement(elementId);

      let elementsForTable: ElementId[];

      if (retVal.has(tableId))
        elementsForTable = retVal.get(tableId);
      else {
        elementsForTable = [];
        retVal.set(tableId, elementsForTable);
      }

      elementsForTable.push(elementId);
    }

    return retVal;
  }

  public getParents(start: ElementId): Set<ElementId> {
    return this.getNeighbours(start, NeighbourType.Parents);
  }

  public getChildren(start: ElementId): Set<ElementId> {
    return this.getNeighbours(start, NeighbourType.Children);
  }

  private getNeighbours(start: ElementId, neighbourType: NeighbourType): Set<ElementId> {
    if (neighbourType === NeighbourType.Parents && this._parentsPerTable.has(start))
      return this._parentsPerTable.get(start);
    else if (neighbourType === NeighbourType.Children && this._childrenPerTable.has(start))
      return this._childrenPerTable.get(start);
    else {
      const relevantDirectlyConnectedElementsForAllNeighbourTables: ElementId[] = this.getRelevantDirectlyConnectedElementsForAllNeighbourTables(start, neighbourType);
      const allRelevantDirectlyAndIndirectlyConnectedElementsForAllNeighbourTables: Set<ElementId> = new Set<ElementId>();

      for (const connectedNeighbourElement of relevantDirectlyConnectedElementsForAllNeighbourTables) {
        allRelevantDirectlyAndIndirectlyConnectedElementsForAllNeighbourTables.add(connectedNeighbourElement);

        this.getNeighbours(connectedNeighbourElement, neighbourType).forEach(element => {
          allRelevantDirectlyAndIndirectlyConnectedElementsForAllNeighbourTables.add(element);
        });
      }

      if (neighbourType === NeighbourType.Parents)
        this._parentsPerTable.set(start, allRelevantDirectlyAndIndirectlyConnectedElementsForAllNeighbourTables);
      else
        this._childrenPerTable.set(start, allRelevantDirectlyAndIndirectlyConnectedElementsForAllNeighbourTables);

      return allRelevantDirectlyAndIndirectlyConnectedElementsForAllNeighbourTables;
    }
  }

  private getRelevantDirectlyConnectedElementsForAllNeighbourTables(element: ElementId, neighbourType: NeighbourType): ElementId[] {
    const neighbourTables: TableId[] = this.getNeighbourTables(element, neighbourType);
    let relevantConnectedElementsPerTable: ElementId[] = [];

    for (const neighbourTable of neighbourTables) {
      relevantConnectedElementsPerTable = relevantConnectedElementsPerTable.concat(this.getRelevantConnectedElementsForTable(element, neighbourTable));
    }

    return relevantConnectedElementsPerTable;
  }

  private getRelevantConnectedElementsForTable(element: ElementId, targetTable: TableId): ElementId[] {
    return modelStore.getConnectedElementIdsToTable(element, targetTable).filter(element => this._relevantElements.indexOf(element) >= 0);
  }

  private getNeighbourTables(element: ElementId, neighbourType: NeighbourType): TableId[] {
    const currentTable: TableId = this.getHierarchyTableForElement(element);

    let level: number = this._levelForTable.get(currentTable);
    if (neighbourType === NeighbourType.Parents)
      level--;
    else
      level++;

    const neightbourTables: TableId[] = [];

    while (level >= 0 && level <= this._maxLevel) {
      neightbourTables.push(this._tableForLevel.get(level));

      if (neighbourType === NeighbourType.Parents)
        level--;
      else
        level++;
    }

    return neightbourTables;
  }

  private getHierarchyTableForElement(element: ElementId): TableId {
    const currentTable: TableId = modelStore.getTableForElement(element);

    if (!this._levelForTable.has(currentTable))
      throw new Error("The given element's table is not within the hierarchy!");

    return currentTable;
  }

  public getElementsPerLevel(level: number): Set<ElementId> {
    if (!this._elementsPerLevel.has(level)) {
      const elements: ElementId[] = modelStore.getElementsForTable(this._tableForLevel.get(level)).map(element => {
        return element["id"];
      }).filter(elementId => {
        return this._relevantElements.includes(elementId);
      });

      this._elementsPerLevel.set(level, new Set<ElementId>(elements));
    }

    return this._elementsPerLevel.get(level);
  }

}