import StoreBase from "../../common/stores/StoreBase";
import Log from "../../common/utils/Logger";
import {PersistencyState, ViewType} from "../../common/constants/Enums";
import {CockpitData, CockpitInfo, OpenView} from "../../api/api";
import * as _ from "lodash";
import {TableId, ViewId} from "../../core/utils/Core";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {
  Corner,
  createBalancedTreeFromLeaves,
  createExpandUpdate,
  createRemoveUpdate,
  getLeaves,
  getNodeAtPath,
  getOtherDirection,
  getPathToCorner,
  isParent,
  MosaicDirection,
  MosaicNode,
  MosaicParent,
  updateTree
} from "react-mosaic-component";
import {modelStore} from "../../core/stores/ModelStore";
import {Dispatcher} from "../../common/utils/Dispatcher";
import autobind from "autobind-decorator";
import {MosaicPath} from "react-mosaic-component/lib/types";
import {action, computed, observable} from "mobx";

import {
  addTreeItem,
  deleteTreeItem,
  ListItemHierarchy,
  moveTreeItemToFolder,
  moveTreeItemToNewPosition,
  renameTreeItem,
  transformHierarchyJsonToListItemHierarchy
} from "../../core/models/ListItemHierarchy";
import {ViewManagerActions} from "../actions/ViewManagerActions";
import {ResetModelLocationAction, SelectModelLocationAction} from "../../modelselection/actions/ModelSelectionActions";
import {LoadActions, LoadWebViewHierarchyAction} from "../../modelselection/actions/ModelAsyncActionCreators";
import {NavigationActions, SetViewHierarchyFilterAction} from "../../core/actions/NavigationActions";
import {LoadWebViewAction, SaveViewAction} from "../../commonviews/actions/SharedViewAsyncActions";
import {ViewInfo} from "../../commonviews/models/ViewInfo";
import {viewHierarchy2ViewInfos} from "../../core/models/TreeViewModelTransformation";
import {ZoomViewAction} from "../../commonviews/actions/SharedViewActions";
import {SelectModelAction} from "../../core/actions/CoreAsyncActions";
import {IEditorState} from "../../commonviews/models/IEditorState";
import {IViewManager, viewManagerRegistry} from "../../commonviews/models/ViewManager";
import {DiagramActions} from "../../diagram/actions/DiagramAsyncActionCreators";
import {COCKPIT_VERSION, TABLE_VERSION} from "../../common/constants/ViewVersions";

const log = Log.logger("MetusStore", Classifier.action);

export const EMPTY_PATH: MosaicPath = [];
export const FIRST_PATH: MosaicPath = ["first"];
export const FIRST_WINDOW_INDEX: number = 1;

/**
 * manages global application state:
 *  - currently selected project
 *  - openend editors/views/window arrangement

 */

export interface CockpitActionPayload {
  id?: ViewId;
  name?: string;
  cockpitToOpen?: CockpitInfo;
}

function filterNavigationTree(root: ListItemHierarchy, predicate: (arg: ListItemHierarchy) => boolean): ListItemHierarchy {
  return root ? {
    id: root.id,
    type: root.type,
    name: root.name,
    children: root.children.filter(predicate),
    viewVersion: root.viewVersion
  } : root;
}

export class MetusStore extends StoreBase implements IViewManager {
  windowCounter: number;
  private _lastWindowArrangement: MosaicNode<number>;
  @observable windowArrangement: MosaicNode<number>;
  @observable private _editorStateByWindowIndex: Map<number, EditorState>;
  @observable private _allViewInfos: ViewInfo[];
  @observable private _activeViewId: ViewId;
  @observable private _cockpitAndViewHierarchy: ListItemHierarchy;
  @observable private _viewHierarchyFilter: string;
  @observable private _currentCockpit: CockpitInfo;

  get viewHierarchyFilter(): string {
    return this._viewHierarchyFilter;
  }

  set viewHierarchyFilter(value: string) {
    this._viewHierarchyFilter = value;
  }

  public get cockpitAndViewHierarchy(): ListItemHierarchy {
    return this._cockpitAndViewHierarchy;
  }

  @computed get cockpitNavigationTree(): ListItemHierarchy {
    return filterNavigationTree(this._cockpitAndViewHierarchy, listItemHierarchy => listItemHierarchy.viewType === ViewType.Cockpit);
  }

  @computed get webViewWithFolderNavigationTree(): ListItemHierarchy {
    return filterNavigationTree(this._cockpitAndViewHierarchy, listItemHierarchy => listItemHierarchy.viewType !== ViewType.Cockpit);
  }

  constructor() {
    super();
    this.init();
  }

  @action
  accept(actionParam: ResetModelLocationAction
      | ViewManagerActions
      | SelectModelLocationAction
      | SelectModelAction
      | LoadActions
      | NavigationActions
      | LoadWebViewAction
      | SaveViewAction
      | ZoomViewAction
      | LoadWebViewHierarchyAction
      | SetViewHierarchyFilterAction
      | DiagramActions): void {

    log.debug("MetusStore accepting", actionParam);

    switch (actionParam.type) {
      case "resetModelLocation":
      case "selectmodellocation":
        log.debug("ModelSelectionStore: Model location selected", actionParam.payload);
        if (!actionParam.payload) {
          this.reset();
          this._currentCockpit = undefined;
          this.notify(actionParam.type);
        }
        break;
      case "selectmodel":
        this.reset();
        this.notify(actionParam.type);
        break;
      case "loadFoldersAndTables":
        Dispatcher.waitFor([modelStore.dispatchToken]);
        log.debug("Got list of all classic tables");
        const tablesViewInfos: ViewInfo[] = this.classicTableList2ViewInfos(modelStore.tableNameByTableId);
        tablesViewInfos.forEach(viewInfo => this.insertViewInfo(viewInfo));
        this.notify(actionParam.type);
        break;
        /* Actions for updating view infos */
      case "webview":
      case "loadMatrix":
        this.setLoadedState(actionParam.resourceId);
        break;
      case "renameView":
        this.renameViewInfo(actionParam.resourceId, actionParam.payload);
        this.notify(actionParam.type);
        break;
      case "saveView":
        this.saveViewInfo(actionParam.resourceId, actionParam.payload);
        this.notify(actionParam.type);
        break;
        /* Actions for webview hierarchy */
      case "webviewHierarchy":
        this._cockpitAndViewHierarchy = transformHierarchyJsonToListItemHierarchy(actionParam.payload);
        log.debug("MetusStore: web view hierarchy converted", actionParam.payload, this._cockpitAndViewHierarchy);
        const webViews: ViewInfo[] = viewHierarchy2ViewInfos(this._cockpitAndViewHierarchy);
        webViews.forEach(viewInfo => this.insertViewInfo(viewInfo));
        this.notify(actionParam.type);
        break;
      case "addTreeItem":
        addTreeItem(this._cockpitAndViewHierarchy, actionParam.resourceId, actionParam.payload);
        this.notify(actionParam.type);
        break;
      case "renameTreeItem":
        renameTreeItem(this._cockpitAndViewHierarchy, actionParam.resourceId, actionParam.payload);
        this.notify(actionParam.type);
        break;
      case "moveTreeItemToFolder":
        const sourceId = actionParam.resourceId;
        const targetId = actionParam.payload;
        log.debug(`MetusStore: moving item ${sourceId} to new parent ${targetId}`);
        moveTreeItemToFolder(this._cockpitAndViewHierarchy, sourceId, targetId);
        this.notify(actionParam.type);
        break;
      case "moveTreeItemToNewPosition": {
        const sourceId = actionParam.resourceId;
        const payload = actionParam.payload;
        log.debug(`MetusStore: moving item ${sourceId} to new position ${payload.targetId}`);
        if (payload.isCockpit) {
          const resortedNavigationTree = moveTreeItemToNewPosition(this.cockpitNavigationTree, payload);
          this.refreshNavigationTree(this.webViewWithFolderNavigationTree, resortedNavigationTree);
        } else {
          const resortedNavigationTree = moveTreeItemToNewPosition(this.webViewWithFolderNavigationTree, payload);
          this.refreshNavigationTree(resortedNavigationTree, this.cockpitNavigationTree);
        }
        break;
      }
      case "deleteTreeItem":
        log.debug(`MetusStore: deleting navigation item ${actionParam.resourceId}`);
        deleteTreeItem(this._cockpitAndViewHierarchy, actionParam.resourceId);
        this.notify(actionParam.type);
        break;
        /* Actions for open views */
      case "newView":
        const viewInfo: ViewInfo = actionParam.payload;
        this.insertViewInfo(viewInfo);
        this.refreshEditorOrOpenNewOne(viewInfo.id);
        break;
      case "openView":
        this.refreshEditorOrOpenNewOne(actionParam.resourceId, actionParam.payload);
        break;
      case "activateView":
        if (this._activeViewId !== actionParam.resourceId) {
          this._activeViewId = actionParam.resourceId;
          this.notify(actionParam.type);
        }
        break;
      case "deactivateView":
        if (this._activeViewId === actionParam.resourceId) {
          this._activeViewId = null;
          this.notify(actionParam.type);
        }
        break;
      case "toggleFullscreen":
        this.toggleEditorFullscreen(actionParam.payload.windowIndex, actionParam.payload.isFullscreen);
        break;
      case "closeEditor":
        this.closeEditor(actionParam.payload)
        this.setCurrentCockpitDirty();
        break;
      case "zoomView": {
        const {windowIndex, zoomingStatus} = actionParam.payload;
        const editorState = this._editorStateByWindowIndex.get(windowIndex);
        editorState.scale = zoomingStatus.scale;
        editorState.isZoomOutOfLowerBounds = zoomingStatus.outOfLowerBounds;
        editorState.isZoomOutOfUpperBounds = zoomingStatus.outOfUpperBounds;
        break;
      }
      case "scrollView":
        const editorState = this._editorStateByWindowIndex.get(actionParam.payload.windowIndex);
        editorState.scrollLeft = actionParam.payload.scrollCoordinates.scrollLeft;
        editorState.scrollTop = actionParam.payload.scrollCoordinates.scrollTop;
        break;
      case "toggleHeaderExpanded": {
        const editorState = this._editorStateByWindowIndex.get(actionParam.payload.windowIndex);
        if (editorState) {
          editorState.isHeaderExpanded = actionParam.payload.isHeaderExpanded
        } else {
          log.warn("Did not find editorState, collapse ignored");
        }
        break;
      }
      case "newCockpit": {
        const cockpitInfo = actionParam.payload as CockpitInfo;
        this.insertViewInfo(new ViewInfo(ViewType.Cockpit, cockpitInfo.id, cockpitInfo.name, PersistencyState.New, COCKPIT_VERSION));
        this._currentCockpit = {...cockpitInfo, isDirty: false};
        break;
      }
      case "openCockpit": {
        this.initWindowArrangementsAndCockpits();
        const cockpit = actionParam.payload as CockpitData;
        const viewInfo = this.getViewInfoById(cockpit.id);
        // a dummy cockpit for restoring a window arrangement without a cockpit context does not contain a cockpitInfo
        if (cockpit.cockpitInfo) {
          this._currentCockpit = {...cockpit.cockpitInfo, name: viewInfo.name, isDirty: false};
        }
        this.windowArrangement = cockpit.windowArrangement;
        /* Initialize window counter appropriately to avoid duplicate ids. */
        const leaves: number[] = getLeaves(this.windowArrangement);
        this.windowCounter = Math.max(...leaves) + 1;
        /* Open views */
        let activate = true;
        cockpit.openViews.forEach((openView: OpenView) => {
          this.refreshEditorOrOpenNewOne(openView.id, openView.windowPath, activate, false);
          activate = false;
        });
        this.notify(actionParam.type);
        break;
      }
      case "closeCockpit":
        this.initWindowArrangementsAndCockpits();
        this._activeViewId = null;
        this.notify(actionParam.type);
        break;
      case "saveCockpit":
        this.setCurrentCockpitDirty(false);
        break;
      case "windowArrangementChanged":
        const newWindowArrangement = actionParam.payload;
        if (newWindowArrangement === null) {
          this.initWindowArrangementsAndCockpits();
        } else {
          this.windowArrangement = newWindowArrangement;
        }
        this.setCurrentCockpitDirty();
        break;
      case "updateWindowPath":
        const {windowIndex, windowPath} = actionParam.payload as any;
        this.updateWindowPathByWindowIndex(windowIndex, windowPath);
        break;
      case "removeView":
        const viewId: ViewId = actionParam.resourceId;
        this.updateWindowsAfterRemovingAView(viewId);
        this.removeViewInfo(viewId);
        if (this._currentCockpit && this.openViews().map(openView => openView.id).includes(viewId)) {
          // If it's a view in a currently open cockpit. -> Mark cockpit as dirty
          this.setCurrentCockpitDirty();
        } else if (this._currentCockpit && this._currentCockpit.id === viewId) {
          // If it's a cockpit -> close it
          this.initWindowArrangementsAndCockpits();
        }
        this.notify(actionParam.type);
        break;
      case "setViewHierarchyFilter":
        const filterExpression = actionParam.payload;
        this.viewHierarchyFilter = filterExpression;
        break;
    }
  }

  private refreshNavigationTree(webViewsAndFolder: ListItemHierarchy, cockpits: ListItemHierarchy): void {
    this._cockpitAndViewHierarchy.children.clear();
    this._cockpitAndViewHierarchy.children.insertValuesAtEndOfList(webViewsAndFolder.children.toArray());
    this._cockpitAndViewHierarchy.children.insertValuesAtEndOfList(cockpits.children.toArray());
  }

  /**
   * Is called by 'removeView'.
   * @param viewId
   */
  private updateWindowsAfterRemovingAView(viewId: ViewId): void {
    const windowPaths = this.getOpenWindowPathsForViewId(viewId);

    windowPaths.forEach((windowPath: MosaicPath) => {
      this.updateMosaicNode(undefined, windowPath, "Remove view with id " + viewId);
    })
  }

  private getOpenWindowPathsForViewId(viewId: ViewId): MosaicPath[] {
    return this.openViews()
        .filter(openView => openView.id === viewId)
        .map(openView => openView.windowPath);
  }

  /**
   * @return view infos of all views open in a workbench window
   */
  public getOpenViewInfos(): ViewInfo[] {
    const openViews: OpenView[] = this.openViews();
    return openViews.map(openView => this.getViewInfoById(openView.id)).filter(viewInfo => !!viewInfo);
  }

  updateWindowPathByWindowIndex(windowIndex: number, windowPath: MosaicPath): void {
    const editorState = this.getEditorStateByWindowIndex(windowIndex);
    if (editorState) {
      editorState.windowPath = windowPath;
    }
  }

  public getCurrentCockpitData(): CockpitData {
    if (this._currentCockpit) {
      return {
        id: this._currentCockpit.id,
        windowArrangement: this.isFullscreen ? this._lastWindowArrangement : this.windowArrangement,
        openViews: this.openViews(),
      };
    }
    return undefined;
  }

  public openViews(): OpenView[] {
    const retVal: OpenView[] = [];
    const openWindows: number[] = getLeaves(this.windowArrangement);
    const filteredMap = new Map(
        Array.from(this._editorStateByWindowIndex.entries()).filter(([index, editorState]) => openWindows.includes(index))
    );

    filteredMap.forEach((editorState: EditorState, windowIndex: number) => {
      let openView: OpenView;
      const windowPath = editorState.windowPath;
      const viewInfo: ViewInfo = this.allViewInfos.find(viewInfo => viewInfo.id === editorState.viewId);
      if (viewInfo) {
        openView = {id: editorState.viewId, type: viewInfo.type, windowPath};
      } else {
        openView = {id: undefined, type: undefined, windowPath};
      }
      retVal.push(openView);
    })

    return retVal;
  }

  private toggleEditorFullscreen(windowIndex: number, isFullscreen: boolean): void {
    const updates = [];
    const editorState: EditorState = this._editorStateByWindowIndex.get(windowIndex);
    editorState.isFullscreen = isFullscreen;

    /* Going to fullscreen mode */
    if (isFullscreen) {
      this._lastWindowArrangement = this.getMosaicNode(this.windowArrangement);
      updates.push(createExpandUpdate(editorState.windowPathAsArray(), 100));
      this.windowArrangement = updateTree(this.windowArrangement, updates);
    } else {
      this.windowArrangement = this.getMosaicNode(this._lastWindowArrangement);
      this._lastWindowArrangement = null;
    }
  }

  private getMosaicNode(node: MosaicNode<number>): MosaicNode<number> {
    let retVal: MosaicNode<number>;
    if (isParent(node)) {
      retVal = {...node as MosaicParent<number>};
    } else {
      retVal = node;
    }
    return retVal;
  }

  private renameViewInfo(id: ViewId, name: string): void {
    this._allViewInfos.filter(viewInfo => viewInfo.id === id)
        .map(viewInfo => {
          viewInfo.name = name;
          this.updateOrInsertViewInfo(viewInfo);
          if (this._currentCockpit && this._currentCockpit.id === id && ViewType.Cockpit === viewInfo.type) {
            this._currentCockpit.name = name;
          }
        });
  }

  private closeEditor(windowIndex): void {
    const updates = [];
    const editorState: EditorState = this.getEditorStateByWindowIndex(windowIndex);

    if (editorState.windowPath.length > 0) {
      /* Close the window */
      updates.push(createRemoveUpdate(this.windowArrangement, editorState.windowPathAsArray()));
      this.windowArrangement = updateTree(this.windowArrangement, updates);
    } else {
      /* Keep last window but show empty view editor. */
      this.updateMosaicNode(undefined, editorState.windowPathAsArray(), "closeLastWindow");
    }

    /* Create a balanced window arrangement if coming back from full screen */
    if (editorState.isFullscreen) {
      const leaves: number[] = getLeaves(this.windowArrangement);
      this.windowArrangement = createBalancedTreeFromLeaves(leaves, "row");
      this._lastWindowArrangement = null;
    }

    this._editorStateByWindowIndex.delete(windowIndex);
  }

  /**
   * A new view should be openened. Determines the number of open mosaic windows and if editors are contained.
   * Is called by 'newView', 'openView', 'openCockpit'
   * @param viewId view id of view to show
   * @param windowPath view is opened at the given path; if nothing given, at the next free position
   * @param activate view is activated if true
   * @param setDirty initial dirty state of view
   */
  private refreshEditorOrOpenNewOne(viewId: ViewId, windowPath?: MosaicPath, activate: boolean = true, setDirty: boolean = true): void {
    // [] is also a valid path.
    if (activate) {
      this._activeViewId = viewId;
    }

    if (windowPath) {
      this.updateMosaicNode(viewId, windowPath, "openViewInExistingEditor");
    } else {
      const windowPath = this.findFirstEmptyWindowPath();

      if (windowPath) {
        this.updateMosaicNode(viewId, windowPath, "openViewInExistingEditor");
      } else {
        this.addMosaicNode(viewId);
        this.notify("openViewInNewEditor");
      }
    }

    this.setCurrentCockpitDirty(setDirty);
  }

  /**
   * @return first free window path; undefined if no free window exists, otherwise first window path which has no editor
   */
  private findFirstEmptyWindowPath(): MosaicPath {
    let result: MosaicPath = undefined;
    const openWindowIndexes: number[] = getLeaves(this.windowArrangement);

    if (openWindowIndexes.length >= 1) {
      const openEmptyWindowStates: EditorState[] = openWindowIndexes
          .map(windowIndex => this.getEditorStateByWindowIndex(windowIndex))
          .filter(editorState => !editorState.viewId);

      result = openEmptyWindowStates.length > 0 ? openEmptyWindowStates[0].windowPath : undefined;
    }

    return result;
  }

  private updateMosaicNode(viewId: ViewId, windowPath: MosaicPath, reason: string): void {
    const windowIndex = ++this.windowCounter;
    this._editorStateByWindowIndex.set(windowIndex, new EditorState(viewId, windowPath));
    this.updateIndexAtPath(windowIndex, windowPath);
    this.notify(reason);
  }

  private updateIndexAtPath(windowIndex: number, windowPath: MosaicPath): void {
    const update = [
      {
        path: windowPath,
        spec: {
          $set: windowIndex
        },
      },
    ];

    this.windowArrangement = updateTree(this.windowArrangement, update);

    if (this._lastWindowArrangement) {
      this._lastWindowArrangement = updateTree(this._lastWindowArrangement, update);
    }
  }

  private addMosaicNode(viewId: ViewId): void {
    const windowIndex = ++this.windowCounter;
    const windowPath: MosaicPath = getPathToCorner(this.windowArrangement, Corner.TOP_RIGHT);
    this._editorStateByWindowIndex.set(windowIndex, new EditorState(viewId, windowPath));
    const direction: MosaicDirection = this.otherDirection(this.windowArrangement as MosaicParent<number>, windowPath);
    const destination: MosaicNode<number> = getNodeAtPath(this.windowArrangement, windowPath);

    let first: MosaicNode<number>;
    let second: MosaicNode<number>;
    if (direction === "row") {
      first = destination;
      second = windowIndex;
    } else {
      first = windowIndex;
      second = destination;
    }

    this.windowArrangement = updateTree(this.windowArrangement, [
      {
        path: windowPath,
        spec: {
          $set: {
            direction,
            first,
            second,
          },
        },
      },
    ]);

  }

  private otherDirection(currentNode: MosaicParent<number>, windowPath: MosaicPath): MosaicDirection {
    const parent = getNodeAtPath(currentNode, _.dropRight(windowPath)) as MosaicParent<number>;
    return parent ? getOtherDirection(parent.direction) : "row";
  }

  @autobind
  public createNode(): number {
    return ++this.windowCounter;
  }

  /**
   * Filters out hierarchies and convert WebViewListEntry to ViewInfo.
   */

  private classicTableList2ViewInfos(tableIdToNameMap: Map<TableId, string>): ViewInfo[] {
    const viewInfos: ViewInfo[] = [];
    modelStore.tableNameByTableId.forEach((tableName: string, tableId: TableId, tableIdToNameMap: Map<TableId, string>): void => {
      // TODO don't pass TABLE_VERSION, instead pass real version of each table. Tables don't have a version at this point, so TABLE_VERSION has no impact right now
      viewInfos.push(new ViewInfo(ViewType.Table, tableId, tableName, PersistencyState.Loadable, TABLE_VERSION));
    });

    return viewInfos;
  }

  private setLoadedState(viewId: ViewId): void {
    const viewInfo = this.getViewInfoById(viewId);
    /*
    * usually a view info has been created before by processing a chartlist or weblist action,
    * but sometimes, e.g. in test scenarios, those actions have not been processed
    */
    if (viewInfo !== undefined) {
      viewInfo.persistencyState = PersistencyState.Loaded;
      this.updateOrInsertViewInfo(viewInfo);
    }
  }

  private saveViewInfo(id: ViewId, persistencyState: PersistencyState): void {
    this._allViewInfos.filter(viewInfo => viewInfo.id === id)
        .map(viewInfo => {
          viewInfo.persistencyState = persistencyState;
          this.updateOrInsertViewInfo(viewInfo);
        });
  }

  private insertViewInfo(viewInfo: ViewInfo): void {
    this.updateOrInsertViewInfo(viewInfo);
  }

  private updateOrInsertViewInfo(viewInfo: ViewInfo): void {
    const existing = this._allViewInfos.findIndex(view => view.id === viewInfo.id);
    if (existing > -1) {
      this._allViewInfos[existing] = viewInfo;
    } else {
      this._allViewInfos.push(viewInfo);
    }
  }

  private removeViewInfo(viewId: ViewId): void {
    _.remove(this._allViewInfos, (view: ViewInfo) => view.id === viewId);
  }

  @autobind
  public getViewInfoByWindowIndex(windowIndex: number): ViewInfo {
    let retVal: ViewInfo;
    const editorState = this.getEditorStateByWindowIndex(windowIndex);
    if (editorState) {
      retVal = this.getViewInfoById(editorState.viewId);
    }
    return retVal;
  }

  @autobind
  public getEditorStateByWindowIndex(windowIndex: number): EditorState {
    const editorState = this._editorStateByWindowIndex.get(windowIndex);
    return editorState ? editorState : new EditorState(undefined, undefined);
  }

  public get allEditorStates(): Map<number, EditorState> {
    return this._editorStateByWindowIndex;
  }

  /**
   *
   * @param viewId
   * @return view info for the given viewId, undefined if view is not found
   */
  public getViewInfoById(viewId: ViewId): ViewInfo {
    return this._allViewInfos.find((view) => {
      return view.id === viewId;
    });
  }

  public get activeViewId(): ViewId {
    return this._activeViewId;
  }

  public get allViewInfos(): ViewInfo[] {
    return this._allViewInfos.filter(view => view !== null);
  }

  public get isFullscreen(): boolean {
    let retVal: boolean = false;
    for (const editorState of this._editorStateByWindowIndex.values()) {
      if (editorState.isFullscreen) {
        retVal = true;
        break;
      }
    }
    return retVal;
  }

  private setCurrentCockpitDirty(isDirty: boolean = true): void {
    if (this._currentCockpit) {
      this._currentCockpit.isDirty = isDirty;
    }
  }

  public get currentCockpit(): CockpitInfo {
    return this._currentCockpit;
  }

  public reset(): void {
    this.init();
  }

  public init(): void {
    this.initViewHierarchy();
    this.initWindowArrangementsAndCockpits();
  }

  public initViewHierarchy(): void {
    if (this._allViewInfos) {
      this._allViewInfos.splice(0, this.allViewInfos.length)
    } else {
      this._allViewInfos = [];
    }
    this._cockpitAndViewHierarchy = undefined;
  }

  public initWindowArrangementsAndCockpits(): void {
    this._lastWindowArrangement = null;
    this._activeViewId = null;
    this.windowCounter = 1;
    this.windowArrangement = 1;
    this._currentCockpit = undefined;
    this._editorStateByWindowIndex = new Map();
    this._editorStateByWindowIndex.set(1, new EditorState(undefined, EMPTY_PATH));
  }

  public get currentCockpitId(): ViewId {
    return this._currentCockpit ? this._currentCockpit.id : undefined;
  }
}


export class EditorState implements IEditorState {
  @observable public isFullscreen: boolean;
  @observable public scale: number;
  @observable public isHeaderExpanded: boolean;
  @observable public windowPath: MosaicPath;
  @observable public isZoomOutOfLowerBounds: boolean;
  @observable public isZoomOutOfUpperBounds: boolean;
  @observable public scrollTop: number;
  @observable public scrollLeft: number;

  constructor(public readonly viewId: ViewId,
              windowPath: MosaicPath,
              isFullscreen: boolean = false,
              isHeaderExpanded: boolean = true,
              scale: number = 1,
              isZoomOutOfLowerBounds: boolean = undefined,
              isZoomOutOfUpperBounds: boolean = undefined
  ) {

    this.isFullscreen = isFullscreen;
    this.isHeaderExpanded = isHeaderExpanded;
    this.scale = scale;
    this.windowPath = windowPath;
    this.isZoomOutOfLowerBounds = isZoomOutOfLowerBounds;
    this.isZoomOutOfUpperBounds = isZoomOutOfUpperBounds;
    this.scrollTop = 0;
    this.scrollLeft = 0;
  }

  /**
   *  Get window path as native array and not as an observable in order to pass to a library which is not mobx-ified
   */
  public windowPathAsArray() {
    return this.windowPath ? this.windowPath.slice() : this.windowPath;
  }
}

const metusStore = new MetusStore();
// register metus store as view manager to decouple commonviews functionality from workbench
viewManagerRegistry.viewManager = metusStore;
export {metusStore};
