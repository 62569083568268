/* CommonDialogUtil.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Marco van Meegen, 17.09.2018
 */
import {Dispatcher} from "./Dispatcher";
import {ShowDialogAction, ShowErrorDialogAction} from "../actions/InteractionStateActions";
import * as React from "react";
import {MessageDialog} from "../components/MessageDialog";
import ErrorDialog from "../components/ErrorDialog";
import {ConfirmationDialog} from "../components/ConfirmationDialog";


export interface ValidationResult {
  isError: boolean;
  errorMessage?: string;
}

export function showErrorDialog(show: boolean, message?: string, title?: string): void {
  Dispatcher.dispatch(new ShowErrorDialogAction(show, <ErrorDialog open={show} message={message}
                                                                   title={title}/>, message));
}

/**
 * helpers to create dialogs using the unified dialog api, avoiding action creators to be dependent on React Components
 */

export function showMessageDialog(show: boolean, message?: string, suppressOkButton: boolean = false): void {
  Dispatcher.dispatch(new ShowDialogAction(show, <MessageDialog open={show} message={message}
                                                                suppressOkButton={suppressOkButton}/>));
}

export function showConfirmationDialog(message: string, onConfirmed: () => void, onRejected?: () => void): void {
  Dispatcher.dispatch(new ShowDialogAction(true, <ConfirmationDialog open={true} message={message} onConfirmed={onConfirmed}
                                                                     onRejected={onRejected}/>));
}

export function showDialog(show: boolean, dialog?: JSX.Element): void {
  Dispatcher.dispatch(new ShowDialogAction(show, dialog));
}

export function hideDialog(): void {
  Dispatcher.dispatch(new ShowDialogAction(false));
}
