/* InterfacesIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, März 2019
 */
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";
import {withStyles} from "@material-ui/core";
import * as React from "react";

class InterfacesIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <style type="text/css">{`
      .st0inter{fill:#FFFFFF;}
      .st1inter{fill:#FFFFFF;fill-opacity:0.5;}
     `}</style>
      <g>
        <rect x="42.79" y="40.57" className="st0inter" width="20.79" height="0.63"/>
        <rect x="17.6" y="40.57" className="st0inter" width="20.78" height="0.63"/>
      </g>
      <path className="st1inter" d="M62.03,23.47H19.15c-0.86,0-1.55,0.69-1.55,1.55v12.61c0,0.86,0.69,1.55,1.55,1.55h19.23v-3.31
	c-1.7-0.82-2.88-2.56-2.88-4.57c0-2.81,2.28-5.08,5.08-5.08c2.81,0,5.08,2.28,5.08,5.08c0,2.02-1.18,3.75-2.88,4.57v3.31h19.23
	c0.86,0,1.55-0.69,1.55-1.55V25.02C63.58,24.17,62.88,23.47,62.03,23.47z"/>
      <path className="st1inter" d="M62.03,42.53H42.8v3.28c1.7,0.82,2.87,2.56,2.87,4.57c0,2.81-2.28,5.08-5.08,5.08c-2.81,0-5.08-2.28-5.08-5.08
	c0-2.02,1.18-3.75,2.88-4.57v-3.28H19.15c-0.86,0-1.55,0.69-1.55,1.55v12.61c0,0.86,0.69,1.55,1.55,1.55h42.87
	c0.86,0,1.55-0.69,1.55-1.55V44.08C63.58,43.23,62.88,42.53,62.03,42.53z"/>
      <path className="st0inter" d="M42.48,46.02V35.7c1.69-0.73,2.87-2.41,2.87-4.37c0-2.63-2.13-4.77-4.77-4.77c-2.63,0-4.77,2.13-4.77,4.77
	c0,1.96,1.18,3.64,2.87,4.37v10.31c-1.69,0.73-2.87,2.41-2.87,4.37c0,2.63,2.13,4.77,4.77,4.77c2.63,0,4.77-2.13,4.77-4.77
	C45.36,48.43,44.17,46.75,42.48,46.02z"/>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(InterfacesIcon);
