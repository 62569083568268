/* ViewContainer.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by MeegenM, September 2017
 */
import * as React from "react";
import EmptyViewComponent from "./EmptyViewComponent";
import Log from "../../common/utils/Logger";
import {ViewType} from "../../common/constants/Enums";
import {DiagramModel} from "../../diagram/models/DiagramModel";
import {DiagramComponent} from "../../diagram/components/common/DiagramComponent";
import {observer} from "mobx-react";
import {diagramStore} from "../../diagram/stores/DiagramStore";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {MosaicPath} from "react-mosaic-component/lib/types";
import {MatrixComponent} from "../../matrix/components/MatrixComponent";
import {matrixStore} from "../../matrix/stores/MatrixStore";
import {MatrixModel} from "../../matrix/models/MatrixModel";
import {EditorState, FIRST_PATH, FIRST_WINDOW_INDEX} from "../stores/MetusStore";
import {ShowRenderingProgressComponent} from "../../common/components/ShowRenderingProgressComponent";
import {ViewInfo} from "../../commonviews/models/ViewInfo";
import {getVersionOfViewType} from "../../common/constants/ViewVersions";
import DisabledViewComponent from "./DisabledViewComponent";

const ConcurrentMode = (React as any).unstable_ConcurrentMode;

const log = Log.logger("ViewContainer");
const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
  viewInfo: ViewInfo;
  windowPath: MosaicPath;
  windowIndex: number;
  editorState: EditorState;
}

// supports deep links showing only diagram, nothing else
@observer
export class ViewContainer extends React.Component<LocalProps, undefined> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering ViewContainer " + (this.props.viewInfo ? this.props.viewInfo.name : undefined));
    let retVal: JSX.Element = undefined;
    const viewInfo = this.props.viewInfo;

    if (viewInfo) {
      if(viewInfo.viewVersion > getVersionOfViewType(viewInfo.type)) {
        return <DisabledViewComponent></DisabledViewComponent>
      }
      if (viewInfo.type === ViewType.Chart || viewInfo.type === ViewType.ValueChart) {
        const diagram = diagramStore.getDiagramForId(viewInfo.id);
        retVal = diagram ? this.renderDiagram(diagram) : this.renderLoading();
      } else if (viewInfo.type === ViewType.StructuredTable || viewInfo.type === ViewType.Table || viewInfo.type === ViewType.Matrix || viewInfo.type === ViewType.ChainMatrix) {
        const matrix = matrixStore.getMatrixById(viewInfo.id);
        retVal = matrix ? this.renderMatrix(matrix) : this.renderLoading();
      }

    } else {
      retVal = <EmptyViewComponent windowPath={this.props.windowPath}/>;
    }

    return retVal;
  }

  private renderLoading(): JSX.Element {
    return <h3>loading view...</h3>;
  }

  private renderDiagram(diagram: DiagramModel): JSX.Element {
    const viewInfo = this.props.viewInfo;
    // TODO reenable react concurrent mode here
    return <ShowRenderingProgressComponent>
      <DiagramComponent viewModel={diagram}
                        viewInfo={viewInfo}
                        windowPath={this.props.windowPath !== undefined ? this.props.windowPath : FIRST_PATH}
                        isHeaderExpanded={this.props.editorState.isHeaderExpanded}
                        windowIndex={this.props.windowIndex !== undefined ? this.props.windowIndex : FIRST_WINDOW_INDEX}/>
    </ShowRenderingProgressComponent>;
  }

  private renderMatrix(matrix: MatrixModel): JSX.Element {
    const viewInfo = this.props.viewInfo;
    return <ShowRenderingProgressComponent>
      <MatrixComponent viewModel={matrix} viewInfo={viewInfo}
                       editorState={this.props.editorState}
                       windowPath={this.props.windowPath !== undefined ? this.props.windowPath : FIRST_PATH}
                       windowIndex={this.props.windowIndex !== undefined ? this.props.windowIndex : FIRST_WINDOW_INDEX}/>
    </ShowRenderingProgressComponent>;
  }
}
