import {ActionBase} from "../../common/actions/BaseAction";
import {FolderId, TableId, ViewId} from "../utils/Core";
import {UUID} from "../../api/api";
import {ServerWritingAction} from "./CoreActions";
import {MoveTreeItemToNewPositionPayload, TreeItemPayload} from "./NavigationPayloads";

export class AddNavigationItemAction extends ActionBase<TreeItemPayload> {
  type: "addTreeItem" = "addTreeItem";

  constructor(resourceId: string, payload: TreeItemPayload, groupId?: UUID) {
    super(payload, resourceId, groupId);
  }
}

export class DeleteNavigationItemAction extends ActionBase<undefined> implements ServerWritingAction {
  type: "deleteTreeItem" = "deleteTreeItem";

  constructor(public commandId:UUID, resourceId: string, groupId?:UUID) {
    super(undefined, resourceId, groupId);
  }
}

export type NavigationItemId = ViewId|TableId|FolderId;


export class RenameNavigationItemAction extends ActionBase<NavigationItemId> {
  type: "renameTreeItem" = "renameTreeItem";

  constructor(resourceId: string, newName: string, commandId:UUID, groupId?:UUID) {
    super(newName, resourceId, groupId);
    this.commandId = commandId;
  }
}

export class MoveNavigationItemToFolderAction extends ActionBase<NavigationItemId> implements ServerWritingAction {
  type: "moveTreeItemToFolder" = "moveTreeItemToFolder";

  constructor(public commandId: UUID, resourceId: string, targetFolder: FolderId) {
    super(targetFolder, resourceId);
  }
}

export class MoveNavigationItemToNewPositionAction extends ActionBase<MoveTreeItemToNewPositionPayload> implements ServerWritingAction {
  type: "moveTreeItemToNewPosition" = "moveTreeItemToNewPosition";

  constructor(public commandId: UUID, resourceId: string, payload: MoveTreeItemToNewPositionPayload) {
    super(payload, resourceId);
  }
}

export class SetViewHierarchyFilterAction extends ActionBase<string> {
  type: "setViewHierarchyFilter" = "setViewHierarchyFilter";

  constructor(filterExpression) {
    super(filterExpression);
  }
}

export type NavigationActions =
    AddNavigationItemAction
    | DeleteNavigationItemAction
    | RenameNavigationItemAction
    | MoveNavigationItemToFolderAction
    | MoveNavigationItemToNewPositionAction
    ;
