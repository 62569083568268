// register metus store as view manager to decouple commonviews functionality from workbench
import {viewManagerRegistry} from "../../../commonviews/models/ViewManager";
import {PropertiesModel} from "../../../properties/model/PropertiesModel";
import {IMetusPropertiesProviderContext} from "../../../commonviews/contexts/IMetusPropertiesProviderContext";
import {toolboxTabDefinitions} from "./ToolboxTabDefinitions";
import {Validate} from "../../../common/utils/Validate";

Validate.notNull(viewManagerRegistry.viewManager);
export const metusPropertiesDerivedModel = new PropertiesModel<IMetusPropertiesProviderContext>();
metusPropertiesDerivedModel.init(toolboxTabDefinitions, viewManagerRegistry.viewManager);
