/**
 * Created by vanmeegen on 05.12.2018
 */

// lib imports
import * as React from "react";
import "../../css/metusapp.css";
import {observer} from "mobx-react";
import Log from "../../../common/utils/Logger";
import {Classifier} from "../../utils/ClassifierLogger";
import autobind from "autobind-decorator";
import {CommonVisualConstants} from "../../constants/CommonVisualConstants";
import {ClickableAreaFiller} from "../ClickableAreaFiller";

const log = Log.logger("common");
const renderLog = Log.logger("common", Classifier.render);

interface LocalProps {
  /**
   * x position, if undefined 0 is used
   */
  x?: number;
  /**
   * y position, if undefined 0 is used
   */
  y?: number;
  /**
   * width, if undefined 24 is used
   */
  width?: number;
  /**
   * height, if undefined 24 is used
   */
  height?: number;

  /**
   * external path to load svg content from, fragments are used to embed only the content into svg, not the svg itself
   */
  href: string;

  /**
   * called if icon is clicked
   */
  onClick?: (e: React.MouseEvent) => void;
  /**
   * optional testselector for integration tests
   */
  testselector?: string;
}

/**
 * SVGClickableIcon includes an icon fragment into an svg and makes its whole bounds clickable to avoid default onClick behavior only on painted areas
 */
@observer
export class SVGClickableIcon extends React.Component<LocalProps, any> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    const x = this.props.x || 0;
    const y = this.props.y || 0;
    const width = this.props.width || CommonVisualConstants.TABLE_HEADER_ICON_WIDTH;
    const height = this.props.height || CommonVisualConstants.TABLE_HEADER_ICON_HEIGHT;
    return <g data-testselector={this.props.testselector ? this.props.testselector : "SVGClickableIcon"}
              style={{cursor: "pointer"}}>
      <use href={this.props.href} x={x} width={width} height={height}/>
      <ClickableAreaFiller width={width} height={height} x={x} y={y} onClick={this.onClick}/>
    </g>;
  }

  @autobind
  private onClick(e: React.MouseEvent): void {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }
}