import React from "react";
import autobind from "autobind-decorator";
import {createStyles, TextField, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";


const styles = (theme: Theme): StyleRules => {
  return createStyles({
    blankLine: {
      height: "56px"
    },
  });
}

interface LocalProps {
  formula?: string;
  onChange: any;
}

interface LocalState {
  formula: string;
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class EditFormulaComponent extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {
      formula: props.formula || ""
    }
  }

  render(): JSX.Element {
    return (
        <TextField multiline={true}
                   rows={5}
                   variant={"outlined"}
                   autoFocus
                   id="formula"
                   placeholder="Formula"
                   value={this.state.formula}
                   onChange={this.handleChangeFormulaString}/>);
  }

  @autobind
  handleChangeFormulaString(event: any) {
    this.setState({formula: event.target.value});
    this.props.onChange(event.target.value);
  }

}

export default withStyles(styles)(EditFormulaComponent);