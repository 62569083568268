import {DiagramVisualConstants} from "../../commonviews/constants/DiagramVisualConstants";

/**
 * VM20181015  TODO whole sync should be done in DiagramModel, not here
 * too much knowledge of interna is needed here, too much duplicated code
 */
export function firstYPositionInTable(): number {
  return DiagramVisualConstants.TABLE_HEADER_YPOS + DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH;

}

export function nextYPosition(baseYPosition: number, additionalElementCount: number): number {
  return baseYPosition + (additionalElementCount * (DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT + DiagramVisualConstants.DEFAULT_GAP_BETWEEN_ELEMENTS));
}