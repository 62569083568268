// lib imports
import * as React from "react";
import {observer} from "mobx-react";
import {VisualHeader} from "../../commonviews/models/VisualHeader";
import {DiagramVisualConstants} from "../../commonviews/constants/DiagramVisualConstants";
import {getLevelDefaultColor, getLevelDefaultTextColor} from "../../core/utils/LevelColorUtil";
import {VisualAttributeId, VisualTableId} from "../../core/utils/Core";
import {EmptyTableHeaderComponent} from "../../commonviews/components/EmptyTableHeaderComponent";
import {ViewContext} from "../../commonviews/contexts/ViewContext";
import {Classifier} from "../../common/utils/ClassifierLogger";
import Log from "../../common/utils/Logger";
import {default as ReactDnD, DropTarget} from "react-dnd";
import {identity} from "../../common/utils/FunctionUtil";
import DragTypes from "../../common/constants/DragTypes";
import {joinTableSectionHeaderComponentDropSpec} from "./JoinTableSectionHeaderComponentDropSpec";
import {AttributeHeaderComponent} from "../../commonviews/components/AttributeHeaderComponent";
import {TableColumnHeaderComponentNoDnd} from "../../commonviews/components/TableColumnHeaderComponent";
import {dragTypeAdapterManager} from "../../commonviews/utils/DragTypeAdapterManager";

const log = Log.logger("diagram");
const renderLog = Log.logger("diagram", Classifier.render);

export interface JoinTableSectionHeaderComponentProps {
  viewContext: ViewContext;
  joinTableId: VisualTableId | undefined;
  joinTableAttributeName: string;
  title: string;
  height: number;
  width: number;
  connectDropTarget?: ReactDnD.ConnectDropTarget;
}

@observer
class JoinTableSectionHeaderComponentNoDnd extends React.Component<JoinTableSectionHeaderComponentProps> {
  constructor(props: JoinTableSectionHeaderComponentProps) {
    super(props);
  }

  render(): React.ReactNode {
    const vid = this.props.joinTableId;
    const vattId = new VisualAttributeId(vid, this.props.joinTableAttributeName);
    let content;
    const connectDropTarget = this.props.connectDropTarget || identity;
    const tablePartHeight = DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH * 2 + DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT;
    const fullHeight = tablePartHeight + DiagramVisualConstants.TABLE_HEADER_ATTRIBUTE_HEADER_HEIGHT + DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH;
    if (vid !== undefined) {
      const header = new VisualHeader(this.props.title, 0, 0, DiagramVisualConstants.DEFAULT_TABLE_WIDTH, fullHeight)
      const attHeader = new VisualHeader(this.props.joinTableAttributeName, 0, tablePartHeight, DiagramVisualConstants.DEFAULT_TABLE_WIDTH, DiagramVisualConstants.TABLE_HEADER_ATTRIBUTE_HEADER_HEIGHT)
      const levelColor = getLevelDefaultColor(0, 1);
      const textColor = getLevelDefaultTextColor(0, 1);
      content = <g data-testselector={"cells/jointable"}>
        <TableColumnHeaderComponentNoDnd viewContext={this.props.viewContext} header={header}
                                         id={vid} index={0} backgroundColor={levelColor} textColor={textColor}/>
        <line style={{stroke: "#808080", strokeWidth: "1px"}} x1={header.x}
              y1={DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH}
              x2={header.x + header.width}
              y2={DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH}/>
        <AttributeHeaderComponent attributeHeader={attHeader} attributeId={vattId} filterText=""
                                  filterTextIsValid={true} attributeHeaderRotation={0} attributeTitleHeight={0}
                                  drawSeparator={false} isFilterVisible={false}/>
      </g>;
    } else {
      content = <g data-testselector={"cells/jointable"}>
          <EmptyTableHeaderComponent text="Drag Join Table here" width={DiagramVisualConstants.DEFAULT_TABLE_WIDTH}
                                     height={tablePartHeight}/>
      </g>;
    }
    return connectDropTarget(<svg style={{height: this.props.height, width: this.props.width}}>
      {content}
      {this.props.children}
    </svg>);
  }
}

function collect(connect: ReactDnD.DropTargetConnector, monitor: ReactDnD.DropTargetMonitor): Object {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

export const JoinTableSectionHeaderComponent = DropTarget([...dragTypeAdapterManager.getCompatibleTypes(DragTypes.CORE_TABLE), ...dragTypeAdapterManager.getCompatibleTypes(DragTypes.CORE_ATTRIBUTE_DEFINITION)], joinTableSectionHeaderComponentDropSpec, collect)(JoinTableSectionHeaderComponentNoDnd);
