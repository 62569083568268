import {observer} from "mobx-react";
import * as React from "react";
import Log from "../../../common/utils/Logger";
import {calcFontSizeFromScale, StyledMatrixCellRendererProps, styles} from "./common";
import {withStyles} from "@material-ui/core";

const log = Log.logger("MatrixComponent");

/**
 * matrix cell renderer for chain matrix columns.
 */
@observer
class ChainMatrixCellRendererComponent extends React.Component<StyledMatrixCellRendererProps> {
  constructor(props: StyledMatrixCellRendererProps) {
    super(props);
  }

  render(): JSX.Element {
    let result = null;
    const cellValue = this.props.getValue();
    if (Array.isArray(cellValue)) {
      result = cellValue.join(";");
    } else if (cellValue !== undefined) {
      result = cellValue;
    }
    return <span data-tip={result} style={{fontSize: calcFontSizeFromScale(this.props.context)}}>{result}</span>;
  }

}

export default withStyles(styles)(ChainMatrixCellRendererComponent);
