import {observer} from "mobx-react";
import * as React from "react";
import Log from "../../../common/utils/Logger";
import {convertToPercentage} from "../../models/common/MatrixCellSharedFunctions";
import {calcFontSizeFromScale, StyledMatrixCellRendererProps, styles} from "./common";
import {withStyles} from "@material-ui/core";

const log = Log.logger("PercentCellRendererComponent");

@observer
class PercentCellRendererComponent extends React.Component<StyledMatrixCellRendererProps> {
  constructor(props: StyledMatrixCellRendererProps) {
    super(props);
  }

  render(): JSX.Element {
    const formattedValue = this.props.formatValue(convertToPercentage(this.props.getValue()));
    return <span style={{fontSize: calcFontSizeFromScale(this.props.context)}}>{formattedValue}</span>;
  }

}

export default withStyles(styles)(PercentCellRendererComponent);
