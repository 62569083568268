// lib imports
import * as React from "react";
import {CSSProperties} from "react";
import Log from "../../../common/utils/Logger";
import {VisualAttributeIdString, VisualTableId, VisualTableIdString} from "../../../core/utils/Core";
import {RemoveTableFromViewAction} from "../../../commonviews/actions/SharedViewActions";
import {DndTargetFeedbackAction} from "../../../common/actions/InteractionStateActions";
import * as ReactDnD from "react-dnd";
import {DropTarget} from "react-dnd";
import {Cursor, getNameForListItemType, TreeItemType} from "../../../common/constants/Enums";
import DragTypes from "../../../common/constants/DragTypes";
import {NodeProps} from "../../../workbench/components/TreeListItemComponent";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {ViewerContext} from "../../utils/ViewerContext";
import {NestedElementComponent} from "../common/NestedElementComponent";
import {ValueChartHeaderComponent} from "./ValueChartLevelHeaderComponent";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {VisualValueChart} from "../../models/valuechart/VisualValueChart";
import {observer} from "mobx-react";
import EmptyValueChartIcon from "../../../common/icons/constructionHelper/EmptyValueChartIcon";
import {Rect} from "../../../common/utils/Geometry";
import {SVGTextBoxComponent} from "../../../common/components/SVGTextBoxComponent";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";
import {ContextMenuTrigger} from "react-contextmenu";
import {VisualValueChartElement} from "../../models/valuechart/VisualValueChartElement";
import {ComponentMapper, mapVirtualized, VirtualVisibility} from "../../../common/utils/VirtualRenderUtil";
import {identity} from "../../../common/utils/FunctionUtil";
import {LightweightNestedValueChartElement} from "./LightweightNestedValueChartElement";

const log = Log.logger("valuechart");
const dndLog = Log.logger("valuechart", Classifier.dnd);
const renderLog = Log.logger("diagram", Classifier.render);

type VisualTableNameAndId = { name: string, id: any };

interface ValueChartComponentProps {
  viewerContext: ViewerContext;
  visibleSVGRect: Rect;
  viewModel: VisualValueChart;
  viewerFilters: Map<VisualAttributeIdString, string>;
  filterTextsValidities: Map<VisualAttributeIdString, boolean>;
  isHeaderExpanded: boolean;
  connectDropTarget?: ReactDnD.ConnectDropTarget;
}

function collectContextMenu(props: any): { id: VisualTableIdString } {
  return {id: props["data-id"]};
}

// App pure component
@observer
export class ValueChartComponentNoDnd extends React.Component<ValueChartComponentProps> {
  constructor(props: ValueChartComponentProps) {
    super(props);
    this.onRemoveTable = this.onRemoveTable.bind(this);
  }

  render(): JSX.Element {
    const model = this.props.viewModel;
    renderLog.debug(`Rendering ValueChartComponent with ${model.children} nodes`);
    const rectStyles: CSSProperties = {fill: "aliceBlue", stroke: "rgb(0,0,0)"};
    const tables: VisualTableNameAndId[] = this.props.viewModel.levels.map(level => (
        {
          name: level.header.name,
          id: level.id
        }
    ));
    // to debug relative and absolute positions
    // const allElementRects = [];
    // model.accept(v => {
    //   if (v instanceof ImVisualValueChartElement) {
    //     allElementRects.push(<rect x={v.x} y={v.y} width={v.width} height={v.height}
    //                                style={{fill: "transparent", stroke: "red"}}/>);
    //   }
    //   return VisitState.CONTINUE;
    // });
    let result: JSX.Element;
    if (this.props.viewModel.levels.length > 0) {
      const connectDropTarget = this.props.connectDropTarget || identity;
      const componentMapper: ComponentMapper<VisualValueChartElement> = (vcElement: VisualValueChartElement, visibility: VirtualVisibility, levelCount: number, isThumbNail: boolean): JSX.Element =>
          visibility === VirtualVisibility.INVISIBLE ?
              <LightweightNestedValueChartElement key={vcElement.id.toKey()} viewModel={vcElement}/> :
              <NestedElementComponent key={vcElement.id.toKey()}
                                      renderWithoutDetails={isThumbNail}
                                      visualAttributeDefinitions={vcElement.level.visualAttributeDefinitions}
                                      visibleSVGRect={this.props.visibleSVGRect}
                                      visualTableId={vcElement.level.id}
                                      viewModel={vcElement}
                                      levelCount={model.levels.length}
                                      hidden={false}
                                      viewerContext={this.props.viewerContext}
                                      wrapLinesInAttributes={this.props.visibleSVGRect === undefined}/>;

      const virtualMappedElements = mapVirtualized(this.props.visibleSVGRect, this.props.viewModel.levels.length, model.visibleElements, componentMapper, this.props.viewerContext.scale, "Value Chart " + this.props.viewModel.getDiagram().name);
      const emptyElement =
          <ContextMenuTrigger id={"cm_dg_NestedElementComponentValueChartEmpty" + this.props.viewerContext.windowIndex}
                              data-id={model.rootLevel.id.toKey()} collect={collectContextMenu} renderTag="g"
                              holdToDisplay={-1}
                              attributes={{"data-testselector": "contextmenutrigger"} as any}>
            <React.Fragment>
              <rect x={model.bounds.x} y={model.headerHeight + DiagramVisualConstants.HEADER_ELEMENT_VERTICAL_GAP}
                    width={model.bounds.width}
                    height={model.headerHeight + DiagramVisualConstants.HEADER_ELEMENT_VERTICAL_GAP}
                    style={{fill: "transparent", stroke: "lightgray"}}></rect>
              <SVGTextBoxComponent width={model.bounds.width} height={100} style={{fill: "lightgray"}}
                                   text="No elements to show" x={model.bounds.x}
                                   y={model.headerHeight + DiagramVisualConstants.HEADER_ELEMENT_VERTICAL_GAP}
                                   wrapLines={true}/>
            </React.Fragment>
          </ContextMenuTrigger>;

      result = <g>
        {this.props.isHeaderExpanded && <ValueChartHeaderComponent levels={this.props.viewModel.levels}
                                                                   viewerContext={this.props.viewerContext}
                                                                   viewerFilters={this.props.viewerFilters}
                                                                   filterTextsValidities={this.props.filterTextsValidities}
                                                                   isAttributeVisible={true}/>}
        {connectDropTarget(
            <g data-testselector="ValueChartElements">
              {model.visibleElements.length > 0 ? virtualMappedElements : emptyElement}
            </g>
        )}
        {/*allElementRects */}
      </g>;
    } else {
      result = <EmptyValueChartIcon style={{width: "100%", height: "100%"}} data-testselector={"ConstructionHelper"}/>;
    }

    return result;
  }

  private onRemoveTable(visualTableId: VisualTableId, name: string): void {
    const viewId = this.props.viewerContext.viewId;
    log.debug("remove table " + name + ", id: " + visualTableId + ", view: " + viewId);
    Dispatcher.dispatch(new RemoveTableFromViewAction(viewId, visualTableId));
  }
}


const dropTarget: ReactDnD.DropTargetSpec<ValueChartComponentProps> = {
  hover(props: ValueChartComponentProps, monitor: ReactDnD.DropTargetMonitor, component: React.Component<ValueChartComponentProps>): void {
    dndLog.debug("Hover");
    const item: NodeProps = monitor.getItem() as NodeProps;
    // noinspection JSRedundantSwitchStatement
    switch (monitor.getItemType()) {
      case DragTypes.CORE_TABLE:
        Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.NO_DROP, `can not drop ${getNameForListItemType(item.type)} '${item.name}' ...`));
        break;
      default:
        dndLog.error("Hovering with unknown type, please handle type or remove it from DropTarget types. If type is adaptable, please adapt it");
        break;
    }
  },

  canDrop(props: ValueChartComponentProps, monitor: ReactDnD.DropTargetMonitor): boolean {
    const item: NodeProps = monitor.getItem() as NodeProps;
    const canDrop = (item.type === TreeItemType.Attribute || item.type === TreeItemType.View);
    dndLog.debug(`ValueChartComponent can drop: ${canDrop}`);
    return canDrop;
  },

  drop(props: ValueChartComponentProps, monitor: ReactDnD.DropTargetMonitor, component: React.Component<ValueChartComponentProps>): Object {
    const viewId = this.props.viewerContext.viewId;
    dndLog.debug(`Dropped ${monitor.getItemType().toString()} to ValueChart ${viewId}`);
    const mousePosition = monitor.getClientOffset() ? monitor.getClientOffset() : {x: 100, y: 100};
    const targetPosition = {
      x: mousePosition.x,
      y: mousePosition.y
    };
    return {
      viewId,
      targetPosition,
      windowPath: props.viewerContext.windowPath,
      windowIndex: props.viewerContext.windowIndex,
    };
  }
};

function collect(connect: ReactDnD.DropTargetConnector, monitor: ReactDnD.DropTargetMonitor): Object {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

export const ValueChartComponent = DropTarget([DragTypes.CORE_TABLE], dropTarget, collect)(ValueChartComponentNoDnd);
