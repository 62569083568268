/* DeserializedModel.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2020
 */
import * as _ from "lodash";

export interface DeserializedModel<ST> {
  /**
   * Stores serialized data in deserialized model when loading from server.
   * @param serializedViewModel
   */
  setLoadedSerializedModel(serializedViewModel: ST): void;
  /**
   * Retrieves the stored serialized data.
   */
  getLoadedSerializedModel(): JSON;
  /**
   * Get current model as serialized data.
   */
  getCurrentModelAsJSON(): JSON;
  /**
   * Serialize current model and store it in model.
   */
  refreshLoadedSerializedModel(): void;
}

export function hasViewChanged<ST>(model: DeserializedModel<ST>): boolean {
  const hasChanged = !_.isEqual(model.getLoadedSerializedModel(), model.getCurrentModelAsJSON());
  if (hasChanged === true) {
    model.refreshLoadedSerializedModel();
  }
  return hasChanged;
}
