import {VisualBaseAttributeValue} from "../common/VisualBaseAttributeValue";
import {VisualAttributeDefinition} from "../common/VisualAttributeDefinition";
import {VisualBaseElement} from "../common/CommonDiagramTypes";

export class VisualAttributeValue extends VisualBaseAttributeValue {
  get x(): number {
    return this.attributeDefinition.header.x;
  }

  get y(): number {
    return this.visualElement.y;
  }

  get  dx(): number {
    return this.attributeDefinition.header.x - this.visualElement.x;
  }

  get dy(): number {
    return this.attributeDefinition.header.y;
  }

  get width(): number {
  return this.attributeDefinition.header.width;
}

  get height(): number {
  return this.visualElement.height;
}

constructor(visualAttributeDefinition: VisualAttributeDefinition, visualElement: VisualBaseElement, value: string) {
    super(visualAttributeDefinition, visualElement, value);
  }
}