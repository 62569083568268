import {object, serializable} from "serializr";
import {observable} from "mobx";
import {VisualTableId} from "../../core/utils/Core";
import {NAME_ATT_NAME} from "../../api/api";
import {MatrixCellConfigurationPayload} from "./MatrixModel";

/**
 * configures what exactly should be displayed and edited in a matrix cell.
 * Currently the following possibilities exist,where r is the row element and c the column element:
 * <ul>
 *   <li>a <b>simple matrix</b> has no joinTable defined (joinTableId = undefined). It displays the connection strength of connection r->c, or empty if no connection exists</li>
 *   <li>a <b>join table matrix</b> defines cell values by a join table. The cell value is calculated using all elements j1..jn of the join table, which are connected to both r and c. There are several subcases of what is displayed:
 *      <ul>
 *        <li>if <b>showCount is true</b>, the count of such elements is displayed, in the example above n.</li>
 *        <li>otherwise the attribute value(s) defined by the joinTableAttributeId of the element(s) are displayed separated by ';'</li>
 *      </ul>
 *   </li>
 * </ul>
 * @author Marco van Meegen
 */
export class MatrixCellConfiguration {
  @serializable(object(VisualTableId)) @observable joinTableId?: VisualTableId;
  @serializable @observable joinTableAttributeName?: string;
  @serializable @observable showCount: boolean = false;


  constructor(joinTableId: VisualTableId = undefined, joinTableAttributeName: string = NAME_ATT_NAME, showCount: boolean = false) {
    this.joinTableId = joinTableId;
    this.joinTableAttributeName = joinTableAttributeName;
    this.showCount = showCount;
  }

  public isJoinTable(): boolean {
    return this.joinTableId !== undefined;
  }

  public setCellConfiguration(payload: MatrixCellConfigurationPayload): void {
    this.joinTableId = payload.joinTableId;
    this.joinTableAttributeName = payload.joinTableAttributeName;
    this.showCount = payload.showCount;
  }

}