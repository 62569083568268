/* DnDInsertTableChart.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Januar 2019
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";
import theme from "../../theme/Theme";

class DnDInsertTableChartIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    const highlightColor = theme.metus.dialog.buttonFill
    const highlightColorLight = theme.metus.dialog.buttonFillOnHover
    return <React.Fragment>
      <g id="layer1" transform="translate(-230,-630)">
        <g transform="translate(-75.706414,-192.69716)" id="layer1-5"/>
        <path
            style={{
              fill: "#f2f2f2",
              fillRule: "evenodd",
              stroke: "#000000",
              strokeWidth: 0,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeOpacity: 1
            }}
            d="m 117.30964,643.63016 8.21429,-11.25 7.32142,11.25 z"
            id="path12476"
        />
        <rect
            style={{fill: "#000000", fillOpacity: 0}}
            id="rect12488"
            width="65.178574"
            height="25.892857"
            x="88.928574"
            y="667.54077"/>
        <rect
            style={{
              fill: "#f2f2f2",
              fillOpacity: 1,
              stroke: "#000000",
              strokeWidth: 0,
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeOpacity: 1
            }}
            id="rect12490"
            width="219.99998"
            height="69.999992"
            x="74.689888"
            y="643.35284"/>
        <g
            transform="translate(44.061827,586.77236)"
            id="g12">
          <g
              id="g14">
            <g
                id="g16">
              <path
                  style={{fill: "#ededed"}}
                  id="path18"
                  d="m 43.91,91.44 c 15.16,0.14 28.01,0.15 31.52,-0.02 6.91,-0.32 7.81,-5.18 7.81,-10.74 0,-6.36 1.26,-11.72 -5.91,-12 -6.64,-0.26 -19.57,-0.4 -35.88,-0.43"
                  className="st4"/>
              <path
                  style={{fill: "#a8a8a8"}}
                  id="path20"
                  d="m 43.91,90.76 c 1.03,-0.22 2.05,-0.17 3.07,-0.17 l 3.07,0 6.14,-0.01 12.27,-0.08 c 2.04,0 4.09,0.01 6.12,-0.03 1.98,-0.01 3.88,-0.31 5.24,-1.4 1.37,-1.05 2.02,-2.88 2.28,-4.78 0.28,-1.91 0.16,-3.95 0.24,-6.03 C 82.38,76.23 82.5,74.16 82,72.46 81.74,71.62 81.31,70.95 80.64,70.5 79.98,70.05 79.1,69.81 78.18,69.7 77.28,69.6 76.2,69.6 75.2,69.57 L 72.14,69.5 66,69.42 C 61.91,69.34 57.82,69.41 53.72,69.27 51.67,69.23 49.63,69.11 47.58,69.04 L 44.51,68.93 42.97,68.88 42.2,68.85 c -0.26,-0.01 -0.51,-0.03 -0.77,0.08 l -0.02,0.01 c -0.38,0.17 -0.83,-0.01 -0.99,-0.4 -0.17,-0.38 0.01,-0.83 0.4,-0.99 0.21,-0.09 0.43,-0.08 0.62,0.01 0.26,0.12 0.51,0.1 0.77,0.08 l 0.77,-0.05 1.54,-0.1 3.07,-0.17 c 2.05,-0.02 4.1,-0.02 6.14,0.03 l 12.29,0.19 6.15,0.13 3.08,0.09 c 1.04,0.05 2.02,0.05 3.14,0.17 1.09,0.13 2.24,0.41 3.26,1.1 1.03,0.68 1.74,1.81 2.05,2.91 0.62,2.23 0.43,4.33 0.4,6.37 -0.08,2.02 0.04,4.08 -0.27,6.22 -0.14,1.06 -0.39,2.14 -0.83,3.18 -0.44,1.04 -1.13,2.03 -2.05,2.76 -1.85,1.51 -4.25,1.81 -6.3,1.85 -2.06,0.08 -4.11,0.11 -6.16,0.14 L 56.2,92.42 50.06,92.37 46.99,92.34 c -1.02,-0.02 -2.05,0.02 -3.07,-0.22 -0.38,-0.09 -0.61,-0.46 -0.53,-0.84 0.05,-0.27 0.27,-0.47 0.52,-0.52 z"
                  className="st3"/>
            </g>
          </g>
        </g>
        <g
            transform="translate(44.061827,586.77236)"
            id="g22">
          <g
              id="g24">
            <path
                style={{fill: "#ededed"}}
                id="path26"
                d="m 237.6,67.2 c -14.75,0.01 -27.81,0.09 -33.86,0.23 -7.74,0.18 -8.1,5.56 -8.07,11.21 0.03,5.74 0.56,11.74 8.44,11.55 4.66,-0.11 24.71,0.89 34.38,0.33"
                className="st4"/>
            <path
                style={{fill: "#a8a8a8"}}
                id="path28"
                d="m 237.6,67.89 c -0.53,0.25 -1.05,0.18 -1.58,0.2 l -1.58,0.03 -3.15,0.05 c -2.1,0.01 -4.2,0.12 -6.31,0.07 -4.21,-0.13 -8.41,0.02 -12.61,0.07 l -6.3,0.11 c -1.03,0.01 -2.14,-0.08 -3.1,0 -0.97,0.08 -1.93,0.24 -2.77,0.6 -1.73,0.66 -2.8,2.21 -3.23,4.11 -0.45,1.89 -0.44,3.98 -0.44,6.06 0.02,2.07 0.1,4.14 0.7,5.99 0.29,0.92 0.75,1.76 1.36,2.42 0.62,0.66 1.42,1.14 2.32,1.42 0.9,0.29 1.89,0.39 2.88,0.4 1.03,0 2.13,0 3.17,0.03 l 6.31,0.19 c 4.2,0.14 8.4,0.28 12.6,0.36 2.1,0.05 4.2,0.06 6.3,0.07 2.09,-0.02 4.2,0.02 6.27,-0.25 0.38,-0.05 0.72,0.22 0.77,0.59 0.05,0.38 -0.22,0.72 -0.59,0.77 -0.02,0 -0.05,0 -0.07,0.01 l -0.02,0 c -8.47,0.22 -16.88,0.17 -25.29,-0.01 l -6.3,-0.1 c -1.06,-0.01 -2.06,0 -3.14,0.02 -1.12,0.01 -2.27,-0.08 -3.4,-0.43 -1.13,-0.32 -2.24,-0.96 -3.09,-1.86 -0.84,-0.89 -1.42,-1.98 -1.78,-3.07 -0.72,-2.2 -0.79,-4.4 -0.83,-6.53 -0.01,-2.11 -0.05,-4.28 0.45,-6.51 0.26,-1.11 0.69,-2.24 1.43,-3.23 0.73,-0.99 1.77,-1.76 2.87,-2.21 1.1,-0.47 2.25,-0.67 3.37,-0.76 1.15,-0.09 2.14,-0.02 3.21,-0.04 l 6.32,-0.04 c 8.42,0.12 16.83,-0.43 25.24,0.09 0.36,0.02 0.64,0.34 0.62,0.7 -0.02,0.25 -0.16,0.45 -0.37,0.55 l -0.24,0.13 z"
                className="st3"/>
          </g>
          <g
              id="g30">
            <path
                style={{fill: "none", stroke: "#a8a8a8", strokeWidth: 3, strokeMiterlimit: 10}}
                id="path32"
                d="m 219.29,84.73 c -2.97,-0.05 -5.93,-0.09 -8.9,-0.14"
                className="st16"/>
            <path
                style={{fill: "none", stroke: "#a8a8a8", strokeWidth: 3, strokeMiterlimit: 10}}
                id="path34"
                d="m 219.06,79.91 c -2.89,0.08 -5.79,0.15 -8.68,0.23"
                className="st16"/>
            <path
                style={{fill: "none", stroke: "#a8a8a8", strokeWidth: 3, strokeMiterlimit: 10}}
                id="path36"
                d="m 219.08,74.91 c -3.14,0.28 -6.12,0.11 -8.45,-0.14"
                className="st16"/>
            <line
                style={{fill: "none", stroke: "#a8a8a8", strokeWidth: 3, strokeMiterlimit: 10}}
                id="line38"
                y2="84.589996"
                x2="207.27"
                y1="84.620003"
                x1="204.28999"
                className="st16"/>
            <line
                style={{fill: "none", stroke: "#a8a8a8", strokeWidth: 3, strokeMiterlimit: 10}}
                id="line40"
                y2="79.620003"
                x2="207.07001"
                y1="79.650002"
                x1="203.95"
                className="st16"/>
            <line
                style={{fill: "none", stroke: "#a8a8a8", strokeWidth: 3, strokeMiterlimit: 10}}
                id="line42"
                y2="74.709999"
                x2="207.32001"
                y1="74.739998"
                x1="203.89999"
                className="st16"/>
          </g>
        </g>
        <g
            transform="translate(44.061827,586.77236)"
            id="g54">
          <g
              id="g56">
            <g
                id="g58">
              <g
                  id="g60">
                <path
                    style={{fill: highlightColorLight}}
                    id="path62"
                    d="m 168.11,66.89 c -14.41,-0.97 -42.62,-1.21 -51.53,-0.53 -6.93,0.52 -9.66,4.76 -9.06,13.06 0.45,6.21 1.67,12.38 8.87,12.69 15.47,0.66 36.31,-0.06 50.4,-1.28 7.92,-0.69 8.76,-5.98 8.78,-11.95 0.01,-5.5 -0.16,-11.5 -7.46,-11.99 z"
                    className="st11"/>
                <path
                    style={{fill: highlightColor}}
                    id="path64"
                    d="m 168.06,67.58 c -1.78,0.08 -3.55,-0.01 -5.32,-0.1 l -5.32,-0.19 c -3.55,-0.11 -7.1,-0.18 -10.65,-0.23 -7.1,-0.11 -14.2,-0.09 -21.29,0 -1.77,0.02 -3.54,0.05 -5.31,0.11 -0.88,0.03 -1.77,0.06 -2.64,0.11 -0.89,0.04 -1.71,0.1 -2.53,0.26 -1.62,0.3 -3.13,0.94 -4.23,2.03 -1.1,1.09 -1.77,2.6 -2.1,4.22 -0.34,1.63 -0.36,3.35 -0.27,5.07 0.11,1.74 0.28,3.49 0.63,5.15 0.35,1.66 0.9,3.27 1.85,4.52 0.93,1.27 2.29,2.09 3.86,2.42 0.79,0.17 1.57,0.21 2.49,0.23 l 2.65,0.09 c 1.77,0.05 3.54,0.08 5.31,0.09 3.54,0.03 7.09,0.01 10.64,-0.05 7.09,-0.13 14.19,-0.39 21.27,-0.83 3.52,-0.19 7.14,-0.38 10.56,-0.69 1.65,-0.22 3.24,-0.74 4.43,-1.76 1.19,-1.01 1.91,-2.51 2.27,-4.14 0.37,-1.64 0.44,-3.38 0.47,-5.14 0.02,-1.77 0.03,-3.52 -0.24,-5.2 -0.26,-1.67 -0.84,-3.29 -2.01,-4.37 -1.15,-1.07 -2.83,-1.66 -4.52,-1.6 z m 0.09,-1.37 c 0.93,0.14 1.85,0.33 2.76,0.6 0.91,0.29 1.8,0.76 2.54,1.42 1.52,1.33 2.23,3.27 2.57,5.08 0.35,1.84 0.38,3.67 0.41,5.45 0.02,1.8 0.01,3.64 -0.36,5.51 -0.38,1.84 -1.23,3.79 -2.79,5.14 -1.54,1.36 -3.5,1.98 -5.35,2.26 -0.95,0.14 -1.83,0.19 -2.72,0.26 l -2.67,0.19 -5.34,0.32 c -7.11,0.41 -14.23,0.65 -21.35,0.75 -3.56,0.04 -7.12,0.05 -10.69,0.01 -1.78,-0.02 -3.56,-0.06 -5.35,-0.12 l -2.67,-0.1 c -0.86,-0.02 -1.86,-0.07 -2.8,-0.28 -0.95,-0.21 -1.88,-0.57 -2.73,-1.09 -0.84,-0.53 -1.59,-1.22 -2.17,-2.01 -1.18,-1.59 -1.77,-3.43 -2.15,-5.22 -0.38,-1.81 -0.54,-3.61 -0.65,-5.41 -0.1,-1.82 -0.06,-3.67 0.3,-5.52 0.36,-1.83 1.14,-3.72 2.55,-5.14 1.41,-1.42 3.31,-2.21 5.15,-2.56 0.93,-0.18 1.87,-0.26 2.76,-0.31 0.9,-0.07 1.79,-0.1 2.68,-0.15 1.79,-0.08 3.57,-0.13 5.35,-0.17 7.13,-0.13 14.24,-0.05 21.36,0.08 3.56,0.07 7.12,0.16 10.67,0.3 l 5.34,0.22 c 0.89,0.05 1.78,0.08 2.67,0.15 0.91,0.08 1.8,0.2 2.68,0.34 z"
                    className="st12"/>
              </g>
            </g>
          </g>
          <path
              style={{fill: "white"}}
              id="path66"
              d="m 136.59,80.98 c 2.82,1.74 6.49,2.03 9.54,0.74 -0.02,2.46 -2.38,4.65 -4.84,4.48 -2.45,-0.18 -5.02,-2.78 -4.7,-5.22 z"
              className="st12"/>
          <g
              id="g68">
            <path
                style={{fill: "white"}}
                id="path70"
                d="m 126.63,75.32 c -0.1,-0.18 -0.05,-0.24 0,-0.29 l 0.14,-0.17 c 0.1,-0.11 0.2,-0.22 0.31,-0.32 0.22,-0.21 0.49,-0.33 0.75,-0.45 0.26,-0.14 0.55,-0.17 0.83,-0.16 0.28,0 0.55,-0.07 0.82,-0.08 0.54,-0.02 1.08,0.01 1.58,0.16 0.25,0.06 0.48,0.17 0.66,0.39 0.09,0.1 0.18,0.2 0.26,0.3 l 0.12,0.15 c 0.02,0.03 0.04,0.04 0.07,0.06 0.03,0 0.01,0.1 0.11,-0.03 0.34,0.17 0.47,0.59 0.3,0.92 -0.17,0.34 -0.59,0.47 -0.92,0.3 0.06,-0.17 -0.02,-0.07 -0.04,-0.08 -0.03,0.01 -0.06,0.01 -0.1,0.03 l -0.21,0.1 c -0.13,0.06 -0.22,0.01 -0.31,-0.01 -0.16,-0.1 -0.32,-0.22 -0.49,-0.27 -0.34,-0.13 -0.69,-0.22 -1.03,-0.23 -0.17,0 -0.34,-0.01 -0.49,0.06 -0.15,0.08 -0.29,0.17 -0.43,0.22 -0.14,0.05 -0.26,0.14 -0.39,0.2 -0.06,0.03 -0.13,0.07 -0.19,0.1 l -0.09,0.06 c -0.03,0.03 -0.04,0.06 -0.22,-0.05 L 127.3,76 c -0.29,-0.17 -0.52,-0.41 -0.67,-0.68 z"
                className="st12"/>
          </g>
          <g
              id="g72">
            <path
                style={{fill: "white"}}
                id="path74"
                d="m 149.1,74.73 c -0.03,-0.21 0.04,-0.23 0.09,-0.26 l 0.17,-0.11 0.36,-0.2 c 0.25,-0.13 0.52,-0.18 0.79,-0.25 0.27,-0.07 0.55,-0.05 0.82,0 0.27,0.04 0.54,0.02 0.81,0.03 0.53,0.04 1.09,0.11 1.59,0.33 0.26,0.1 0.5,0.25 0.66,0.5 l 0.24,0.36 0.1,0.19 c 0.02,0.03 0.04,0.05 0.06,0.08 0.03,0.01 0,0.1 0.13,0 0.29,0.24 0.32,0.68 0.08,0.97 -0.24,0.29 -0.68,0.32 -0.97,0.08 0.09,-0.15 0,-0.07 -0.02,-0.09 -0.03,0 -0.06,0 -0.1,0.01 l -0.22,0.06 c -0.14,0.04 -0.2,-0.01 -0.28,-0.05 -0.13,-0.12 -0.27,-0.25 -0.44,-0.32 -0.32,-0.17 -0.68,-0.29 -1.05,-0.34 -0.18,-0.02 -0.37,-0.06 -0.56,-0.01 -0.18,0.06 -0.36,0.13 -0.54,0.14 -0.18,0.03 -0.34,0.1 -0.52,0.12 l -0.26,0.06 -0.13,0.04 c -0.04,0.02 -0.07,0.05 -0.21,-0.12 l -0.17,-0.2 c -0.22,-0.3 -0.37,-0.66 -0.43,-1.02 z"
                className="st12"/>
          </g>
          <g
              id="g76">
            <g
                id="g78">
              <path
                  style={{fill: highlightColor}}
                  id="path80"
                  d="m 110.13,97.07 c 1.36,-0.05 2.69,-0.01 4.03,0.03 1.34,0.04 2.67,0.07 4,0.15 0.24,0.01 0.43,0.22 0.41,0.46 -0.01,0.24 -0.22,0.43 -0.46,0.41 -2.66,-0.12 -5.33,-0.36 -7.96,-0.36 l -0.01,0 c -0.19,0 -0.34,-0.15 -0.34,-0.34 0,-0.19 0.15,-0.34 0.33,-0.35 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path82"
                  d="m 126.15,97.63 7.99,0.26 0.01,0 c 0.25,0.01 0.44,0.22 0.43,0.46 -0.01,0.25 -0.22,0.44 -0.46,0.43 l 0,0 -8.01,-0.27 c -0.24,-0.01 -0.44,-0.21 -0.43,-0.46 0.01,-0.24 0.21,-0.43 0.47,-0.42 l 0,0 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path84"
                  d="m 142.1,97.84 c 1.32,-0.08 2.64,-0.16 3.97,-0.28 l 3.99,-0.34 c 0.26,-0.02 0.48,0.17 0.5,0.42 0.02,0.26 -0.17,0.48 -0.42,0.5 l -0.01,0 c -2.66,0.21 -5.3,0.46 -7.99,0.6 -0.25,0.01 -0.46,-0.18 -0.48,-0.43 -0.01,-0.25 0.18,-0.46 0.42,-0.48 l 0.02,0.01 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path86"
                  d="m 158.06,96.69 c 2.67,-0.13 5.37,-0.17 8.03,-0.06 l 0.01,0 c 0.27,0.01 0.48,0.24 0.47,0.51 -0.01,0.27 -0.23,0.47 -0.5,0.47 -1.34,-0.03 -2.66,-0.07 -3.98,-0.06 -1.33,0.01 -2.65,0.05 -3.98,0.11 -0.26,0.01 -0.49,-0.19 -0.5,-0.46 -0.02,-0.28 0.19,-0.5 0.45,-0.51 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path88"
                  d="m 173.91,96.27 c 2.44,-0.69 4.38,-2.61 5.7,-4.82 0.13,-0.21 0.41,-0.28 0.62,-0.15 0.21,0.13 0.28,0.41 0.15,0.62 l 0,0 c -0.71,1.17 -1.56,2.27 -2.6,3.19 -1.03,0.92 -2.27,1.65 -3.62,2.04 -0.24,0.07 -0.5,-0.07 -0.57,-0.31 -0.07,-0.24 0.07,-0.5 0.32,-0.57 l 0,0 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path90"
                  d="m 182.34,84.14 c 0.53,-2.57 0.75,-5.22 0.66,-7.84 -0.01,-0.24 0.18,-0.45 0.42,-0.46 0.24,-0.01 0.45,0.18 0.46,0.42 l 0,0.01 c 0.08,2.7 -0.13,5.4 -0.67,8.05 -0.05,0.24 -0.28,0.39 -0.52,0.35 -0.24,-0.05 -0.4,-0.29 -0.35,-0.53 l 0,0 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path92"
                  d="m 181.36,68.7 c -1.06,-2.36 -2.82,-4.39 -5.09,-5.59 -0.22,-0.11 -0.3,-0.38 -0.19,-0.6 0.11,-0.22 0.38,-0.3 0.6,-0.19 l 0.01,0 c 2.46,1.29 4.36,3.51 5.47,6 l 0,0.01 c 0.1,0.22 0,0.48 -0.22,0.58 -0.22,0.11 -0.48,0.02 -0.58,-0.21 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path94"
                  d="m 168.71,61.4 c -2.65,-0.23 -5.3,-0.36 -7.96,-0.46 -0.25,-0.01 -0.45,-0.22 -0.44,-0.47 0.01,-0.25 0.22,-0.45 0.47,-0.44 l 0,0 c 2.67,0.1 5.34,0.24 8.01,0.47 0.25,0.02 0.43,0.24 0.41,0.49 -0.02,0.25 -0.24,0.43 -0.49,0.41 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path96"
                  d="m 152.75,60.79 -7.99,0.02 -0.01,0 c -0.26,0 -0.47,-0.21 -0.47,-0.47 0,-0.26 0.21,-0.47 0.47,-0.47 l 0,0 8.01,-0.01 c 0.25,0 0.46,0.21 0.46,0.46 0,0.26 -0.21,0.47 -0.47,0.47 l 0,0 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path98"
                  d="m 136.76,60.95 -7.99,0.22 c -0.26,0.01 -0.49,-0.2 -0.5,-0.46 -0.01,-0.26 0.2,-0.49 0.46,-0.5 l 0,0 8,-0.21 c 0.26,-0.01 0.48,0.2 0.49,0.46 0.01,0.26 -0.2,0.49 -0.46,0.49 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path100"
                  d="m 120.78,61.45 -8,0.25 c -0.26,0.01 -0.48,-0.2 -0.49,-0.46 -0.01,-0.26 0.2,-0.48 0.46,-0.49 l 8,-0.27 c 0.27,-0.01 0.49,0.2 0.51,0.47 0,0.26 -0.21,0.49 -0.48,0.5 l 0,0 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path102"
                  d="m 105.31,63.15 c -2.1,1.45 -3.44,3.82 -4.29,6.27 -0.08,0.23 -0.33,0.36 -0.57,0.28 -0.23,-0.08 -0.36,-0.34 -0.28,-0.57 0.44,-1.29 1.01,-2.55 1.76,-3.71 0.75,-1.16 1.69,-2.22 2.86,-3.03 0.21,-0.14 0.49,-0.09 0.64,0.12 0.14,0.21 0.09,0.5 -0.12,0.64 l 0,0 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path104"
                  d="m 99.6,77.16 c -0.03,1.32 -0.11,2.64 -0.04,3.96 0.05,1.32 0.17,2.64 0.37,3.94 0.03,0.21 -0.11,0.41 -0.33,0.44 -0.21,0.03 -0.41,-0.11 -0.44,-0.32 -0.22,-1.33 -0.35,-2.68 -0.42,-4.03 -0.08,-1.35 -0.02,-2.7 0,-4.04 0,-0.24 0.2,-0.43 0.44,-0.43 0.24,0 0.43,0.2 0.43,0.44 l 0,0 -0.01,0.04 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path106"
                  d="m 102.34,92.51 c 0.66,1.12 1.48,2.15 2.5,2.94 1.01,0.8 2.21,1.34 3.48,1.55 0.16,0.03 0.27,0.18 0.25,0.34 -0.02,0.16 -0.18,0.27 -0.34,0.25 l 0,0 c -1.36,-0.23 -2.68,-0.79 -3.77,-1.64 -1.1,-0.84 -1.99,-1.93 -2.69,-3.1 -0.1,-0.16 -0.05,-0.37 0.12,-0.47 0.14,-0.09 0.35,-0.04 0.45,0.13 0,-0.01 0,0 0,0 z"
                  className="st12"/>
            </g>
          </g>
        </g>
        <g
            transform="translate(44.061827,586.77236)"
            id="g108">
          <path
              style={{fill: highlightColor}}
              id="path110"
              d="m 107.88,82.74 c -0.17,0.25 -0.4,0.28 -0.63,0.33 -0.22,0.05 -0.44,0.09 -0.67,0.13 -0.45,0.07 -0.89,0.12 -1.33,0.16 -0.89,0.08 -1.75,0.04 -2.61,0.02 -1.74,-0.07 -3.46,-0.47 -5.19,-0.86 -1.72,-0.44 -3.42,-1.18 -4.89,-2.28 -0.75,-0.53 -1.41,-1.2 -1.9,-1.99 C 90.42,77.86 90.19,77.46 90,77.03 L 89.73,76.4 c -0.05,-0.1 -0.08,-0.21 -0.12,-0.32 -0.06,-0.1 -0.02,-0.24 -0.21,-0.28 -0.15,-0.35 0.01,-0.75 0.36,-0.9 0.35,-0.15 0.75,0.01 0.9,0.36 -0.12,0.16 0.03,0.2 0.07,0.29 0.06,0.08 0.12,0.16 0.19,0.23 0.15,0.14 0.29,0.28 0.44,0.41 0.28,0.27 0.51,0.56 0.75,0.86 0.46,0.6 0.98,1.18 1.61,1.64 1.23,0.96 2.67,1.64 4.21,2.09 1.53,0.5 3.18,0.55 4.81,0.63 0.82,0.04 1.65,0.04 2.44,0.04 0.4,0 0.8,-0.02 1.19,-0.06 l 0.58,-0.07 c 0.19,-0.03 0.38,-0.08 0.61,0.08 l 0.07,0.05 c 0.42,0.28 0.53,0.85 0.25,1.27 0.01,0 0,0.01 0,0.02 z"
              className="st12"/>
        </g>
        <g transform="translate(44.061827,586.77236)" id="g112">
          <path
              style={{fill: highlightColor}}
              id="path114"
              d="m 175.51,75.11 c 0.07,-0.23 0.21,-0.27 0.34,-0.32 l 0.4,-0.16 0.81,-0.28 c 0.55,-0.18 1.11,-0.28 1.68,-0.4 0.56,-0.12 1.14,-0.14 1.72,-0.12 0.58,0.01 1.15,-0.05 1.73,-0.04 1.15,0.01 2.32,0.08 3.46,0.36 0.57,0.13 1.13,0.32 1.63,0.65 l 0.74,0.48 0.35,0.26 0.18,0.12 c 0.07,0.02 0.09,0.14 0.23,0.02 0.34,0.18 0.46,0.59 0.29,0.93 -0.18,0.34 -0.59,0.46 -0.93,0.29 0.02,-0.18 -0.09,-0.1 -0.15,-0.13 -0.07,-0.01 -0.14,-0.02 -0.21,-0.02 l -0.42,0 c -0.28,0.01 -0.51,-0.1 -0.75,-0.18 -0.46,-0.2 -0.94,-0.4 -1.43,-0.53 -0.99,-0.27 -2.01,-0.43 -3.05,-0.46 -0.52,-0.01 -1.04,-0.03 -1.56,0.05 -0.52,0.09 -1.02,0.2 -1.54,0.27 -0.51,0.08 -1.01,0.21 -1.53,0.31 l -0.76,0.17 -0.38,0.1 c -0.13,0.04 -0.24,0.09 -0.44,-0.07 l -0.06,-0.05 c -0.34,-0.31 -0.48,-0.8 -0.35,-1.25 z"
              className="st12"/>
        </g>
        <g id="g116" transform="translate(27.858398,583.53167)">
          <text
              style={{fontSize: "18px", fontFamily: theme.metus.typography.fontFamily, fill: highlightColor}}
              id="text118"
              className="st12 st19 st20"
              transform="translate(68.5648,123.2395)">Insert a table here.
          </text>
        </g>
        <path
            style={{fill: "none", stroke: highlightColor, strokeLinecap: "round", strokeMiterlimit: 10}}
            id="path120"
            d="m 131.86183,662.14236 c -0.34,-0.15 -0.67,-0.37 -0.85,-0.7 -0.15,-0.28 -0.19,-0.6 -0.2,-0.92 -0.02,-0.47 0.02,-0.95 0.24,-1.36 0.29,-0.54 0.88,-0.88 1.49,-0.97 0.61,-0.08 1.23,0.06 1.79,0.31 0.24,0.11 0.48,0.24 0.63,0.46 0.13,0.18 0.18,0.41 0.2,0.63 0.19,1.95 -1.49,3.33 -3.3,2.55 z"
            className="st21"/>
        <path
            style={{fill: "none", stroke: highlightColor, strokeLinecap: "round", strokeMiterlimit: 10}}
            id="path122"
            d="m 233.17183,662.98236 c 0.23,-0.4 1.05,-0.89 1.53,-1 0.63,-0.14 1.23,0.37 1.5,0.91 0.38,0.77 0.39,1.69 0.01,2.46 -0.37,0.77 -1.18,1.4 -2.11,1.43 -0.9,0.03 -1.77,-0.64 -1.77,-1.48 0.01,-0.77 0.45,-1.66 0.84,-2.32 z"
            className="st21"/>
      </g>
      <g transform="translate(-49.992128,208.73141)" id="layer1-6"/>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 744.09448819 1052.3622047";
  }
}

export default withStyles(svgIconStyles)(DnDInsertTableChartIcon);
