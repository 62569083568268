/* ExitFullScreenIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Oktober 2019
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class EnterFullScreenIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <style type="text/css">{`
      .st0exit-full{fill-rule:evenodd;clip-rule:evenodd;}      
     `}</style>
      <g>
        <polygon className="st0exit-full" points="8,16 8,20 10,20 10,14 4,14 4,16"/>
        <polygon className="st0exit-full" points="8,8 4,8 4,10 10,10 10,4 8,4"/>
        <polygon className="st0exit-full" points="16,16 20,16 20,14 14,14 14,20 16,20"/>
        <polygon className="st0exit-full" points="20,10 20,8 16,8 16,4 14,4 14,10"/>
      </g>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(EnterFullScreenIcon);
