export class CommonVisualConstants {
  /**
   * height of table header icons
   */
  public static TABLE_HEADER_ICON_HEIGHT: number = 24;
  /**
   * width of table header icons
   */
  public static TABLE_HEADER_ICON_WIDTH: number = 24;
  /**
   * gap to leave between icons and title
   */
  public static TABLE_HEADER_ICON_GAP: number = 1;

}