/* OverwriteWriteLockDialog.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import * as React from "react";
import {ChangeEvent} from "react";
import Log from "../../common/utils/Logger";
import {
  createStyles,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import {Classifier} from "../../common/utils/ClassifierLogger";
import MetusDialog from "../../common/components/MetusDialog";
import {showDialog} from "../../common/utils/CommonDialogUtil";
import {acquireWriteLock} from "../../modelselection/actions/ModelAsyncActionCreators";
import {MetusCheckbox} from "../../common/components/MetusCheckboxComponent";
import {Workspace} from "../../api/api";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import MetusTextField from "../../common/components/MetusTextField";
import {createWorkspace, loadWorkspaces} from "../../core/services/CoreDataServices";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
  open: boolean;
  workspaces: Workspace[];
}

interface LocalState {
  workspaceId:string;
  overwriteLock:boolean;
  isAddSzenarioActive:boolean;
  newSzenarioName:string;
}

const styles = (theme: Theme): StyleRules => createStyles({
  button: {
    margin: theme.spacing(1),
  },
  confirmVisible: {
    visibility: "visible",
  },
  confirmHidden: {
    visibility: "hidden",
  },
  list: {
    "overflow-y": "auto",
    "max-height": "400px"
  },
  addScenarioFakelist: {
  }
});

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class ChooseWorkspaceDialog extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = { workspaceId: undefined, overwriteLock: false, isAddSzenarioActive: false, newSzenarioName: undefined };
  }

  getDialogContent(): JSX.Element {
    return <React.Fragment><List className={this.props.classes.list}>
      {this.props.workspaces.map(workspace => {
        return <ListItem>
          <Radio checked={this.state.workspaceId === workspace.id}
                 onChange={this.handleWorkspaceSelect}
                 value={workspace.id}
                 name="radio-button-workspace"
                 inputProps={{'aria-label': workspace.name}}/>
          <ListItemText id={workspace.id} primary={workspace.name}/>
          <IconButton edge={"end"}>{workspace.isLocked ? <LockIcon/> : <LockOpenIcon/>}</IconButton>
        </ListItem>
      })}
    </List>{this.state.isAddSzenarioActive ? this.getAddScenarioAreaActive() : this.getAddScenarioAreaInactive()}</React.Fragment>;
  }

  getAddScenarioAreaInactive() {
    return <ListItem
        data-testselector={"setAddSzenarioActive"}
        role={undefined}
        dense
        button
        onClick={() => {
          this.setState({isAddSzenarioActive: true})
        }}>
      <ListItemIcon>
        <AddIcon/>
      </ListItemIcon>
      <ListItemText primary="Add New Scenario"/>
    </ListItem>
  }

  @autobind
  private addNewSzenario() {
    const newSzenarioName = this.state.newSzenarioName;
    createWorkspace(newSzenarioName).then(workspaceId => {
      console.log("Workspace created ..", workspaceId);
      loadWorkspaces().then(result =>
          showDialog(true, <StyledChooseWorkspaceDialog open={true} workspaces={result}/> )
      )
    })
    this.cancelAddSzenarioActive();
  }

  @autobind
  private cancelAddSzenarioActive() {
    this.setState( {isAddSzenarioActive: false, newSzenarioName: undefined})
  }

  getAddScenarioAreaActive() {
    return <List className={this.props.classes.addScenarioFakelist}><ListItem
        data-testselector={"addSzenarioActive"}
        role={undefined}
        dense
        button>
      <MetusTextField
          id="new-szenario-title"
          autoFocus={true}
          value={this.state.newSzenarioName}
          placeholder={"Enter Szenario Name"}
          onChange={(evt) => {
            this.setState({newSzenarioName: evt.target.value})
          }}
          onKeyPress={evt => {
            if (evt.key === 'Enter') {
              this.addNewSzenario()
            }
          }}
      />
    <ListItemSecondaryAction>
      <IconButton
          aria-label="add-icon"
          disabled={this.state.newSzenarioName === undefined || this.state.newSzenarioName.length === 0}
          onClick={this.addNewSzenario}>
        <DoneIcon/>
      </IconButton>
      <IconButton
          edge="end"
          aria-label="cancel-icon"
          onClick={this.cancelAddSzenarioActive}>
        <CloseIcon/>
      </IconButton>
    </ListItemSecondaryAction></ListItem></List>;
  }

  @autobind
  private handleWorkspaceSelect(event: any): void {
    const workspaceId:string = event.target.value;
    if (this.state.workspaceId !== workspaceId) {
      this.setState({workspaceId, overwriteLock: false});
    }
  }

  render(): JSX.Element {
    const isSelectedWorkspaceLocked = this.selectedWorkspace()?.isLocked;
    const confirmationLockedSzenario = <FormControlLabel
        classes={{root: isSelectedWorkspaceLocked ? this.props.classes.confirmVisible : this.props.classes.confirmHidden}}
        label="The selected szenario is locked. Checking it out may overwrite changes!"
        checked={this.state.overwriteLock}
        control={<MetusCheckbox/>}
        onChange={this.setOverwriteLock}
    />
    const isPrimaryButtonDisabled = this.selectedWorkspace() === undefined || (isSelectedWorkspaceLocked && !this.state.overwriteLock);
    return <MetusDialog
        data-testselector="ChooseWorkspaceDialog"
        title="Checkout Szenario"
        open={this.props.open}
        onClose={this.handleClose}
        primaryButtonName={"Checkout"}
        onPrimaryButtonPressed={this.handleCheckout}
        primaryButtonDisabled={isPrimaryButtonDisabled}
        additionalButtonBarEntry={confirmationLockedSzenario}>
      {this.getDialogContent()}
    </MetusDialog>;
  }

  @autobind
  private setOverwriteLock(event: ChangeEvent<any>, checked: boolean): void {
    this.setState({overwriteLock: checked});
  }

  @autobind
  private handleClose():void {
    showDialog(false);
  }

  @autobind
  private createOrUpdateWriteLock(changeSetId:string=undefined): void {
    acquireWriteLock(this.state.overwriteLock, changeSetId);
    this.handleClose();
  }

  @autobind
  private handleCheckout(): void {
    if (this.state.workspaceId )  {
      this.createOrUpdateWriteLock(this.state.workspaceId);
      this.handleClose();
    }
  }

  @autobind
  private handleNewWorkspace(): void {
    this.createOrUpdateWriteLock();
    this.handleClose();
  }

  private selectedWorkspace() {
    return this.props.workspaces.find(workspace => workspace.id === this.state.workspaceId);
  }
}

const StyledChooseWorkspaceDialog = withStyles(styles)(ChooseWorkspaceDialog);
export default StyledChooseWorkspaceDialog;

/*
export function showOverwriteWriteLockDialog(display: boolean): void {
  showDialog(display, <StyledChooseWorkspaceDialog open={display}/>);
}
 */

