/* ViewContext.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by MeegenM, November 2019
 */
import {ViewId} from "../../core/utils/Core";
import {observable} from "mobx";
import * as React from 'react';
import {ViewType} from "../../common/constants/Enums";

export interface IViewContext {
  /** view id the consumer is in */
  viewId: ViewId,
  /** window the view is opened in */
  windowIndex: number
}

const ctxt = React.createContext<IViewContext | null>(null);

export const ViewContextProvider = ctxt.Provider;

export const ViewContextConsumer = ctxt.Consumer;

/**
 *
 * @param viewContext
 * @return unique id for this view, can be used for context menu identifiers to avoid id clashes
 */
export function viewContextId(viewContext: IViewContext): string {
  return "ViewId" + viewContext.viewId + viewContext.windowIndex;
}

/**
 * an observable implementation of the view context interface
 */
export class ViewContext implements IViewContext {
  constructor(viewId: ViewId, viewType: ViewType, windowIndex: number) {
    this.viewId = viewId;
    this.viewType = viewType;
    this.windowIndex = windowIndex;
  }

  /** current view id */
  @observable viewId: ViewId;

  /** type of view */
  @observable viewType: ViewType;

  /** window index where current view is hosted */
  @observable windowIndex: number;
}