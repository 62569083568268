import * as rootlog from "loglevel";
import {Logger} from "loglevel";

/* An extension of the loglevel Logger which allows to configure two levels of
   logging: a component level and another level called qualifier. Qualifiers are
   cross cutting concerns like dnd or event logging. Per default they inherit the level
   of their parent but a qualifier level can also be set independently of their parent.

   Example: Given two components DiagramModel and Graphstore using the qualifier dnd
   const graphLogger = new Classifier("DiagramModel");
   const graphStoreLogger = new Classifier("DiagramStore");
   const graphDndLogger = new Classifier("DiagramModel", "dnd");
   const graphStoreDndLogger = new Classifier("DiagramStore", "dnd");

   Scenario 1: set all to Info

   rootlog.setDefaultLevel(LogLevel.INFO);

   Scenario 2: DiagramModel and graphDnd to INFO, diagramStore and graphStoreDnd to WARN
   rootlog.setDefaultLevel(LogLevel.WARN);
   (rootlog.getLogger("graph") as Log).setLevel(LogLevel.INFO, persist);
    => graphDnd inherits INFO from graph

   Scenario 3: All dnd logging to DEBUG, rest to WARN
   rootlog.setDefaultLevel(LogLevel.WARN);
   ClassifierLogger.setClassifierLevel("dnd", LogLevel.DEBUG);

 */

export default rootlog;

export enum Classifier {
  event,
  dnd,
  update,
  action,
  render
}

export enum LogLevel {
  // noinspection JSUnusedGlobalSymbols
  TRACE = 0,
  DEBUG = 1,
  INFO = 2,
  WARN = 3,
  ERROR = 4,
  SILENT = 5
}

const classifierLoggerForComponent: Map<string, ClassifierLogger[]> = new Map<string, ClassifierLogger[]>();
const classifierDefaults: Map<Classifier, LogLevel> = new Map<Classifier, LogLevel>();
const classifierLevelForComponent: Map<string, LogLevel> = new Map<string, LogLevel>();

export class ClassifierLogger  {

  constructor(public component: string, public classifier: Classifier = null) {
    if (this.classifier != null) {
      ClassifierLogger.assignClassifierLoggerToComponentName(this, this.component);

      const classifierForComponentLevel = ClassifierLogger.getClassifierLevelForComponent(component, classifier);
      if (classifierForComponentLevel) {
        this.setLevel(classifierForComponentLevel);
      }
      else if (classifierDefaults.has(classifier)) {
        this.setLevel(classifierDefaults.get(classifier));
      } else {
        // Inherit level from component. If not set yet we use the default level.
        this.setLevel(rootlog.getLogger(component).getLevel() as LogLevel);
      }
    }
  }

  setLevel(loglevel: LogLevel): void {
    rootlog.getLogger(this.loggerName).setLevel(loglevel, false);

    if (this.classifier === null) {
      const subs = classifierLoggerForComponent.get(this.loggerName);
      subs && subs.forEach( cl => cl.setLevel(loglevel));
    }
  }

  getLog(): Logger {
    return rootlog.getLogger(this.loggerName);
  }

  get loggerName(): string {
    let result: string = null;

    if (this.classifier != null) {
      result = `${this.component}.${this.classifier}`;
    } else {
      result = this.component;
    }
    return result;
  }

  static setLevelForClassifier(classifier: Classifier, loglevel: LogLevel): void{
    classifierDefaults.set(classifier, loglevel);

    const values = classifierLoggerForComponent.values();
    let loggerNames: ClassifierLogger[] = values.next().value || [];
    do {
      loggerNames
          .filter(cln => {return cln.classifier === classifier;})
          .forEach(cln => cln.setLevel(loglevel));
    } while (loggerNames = values.next().value);
  }

  static getLevelForClassifier(classifier: Classifier): LogLevel {
    return classifierDefaults.get(classifier);
  }

  static assignClassifierLoggerToComponentName(nsubLogger: ClassifierLogger, componentName: string): void {
    let newValue = [];
    if (classifierLoggerForComponent.has(componentName)) {
      newValue = [...classifierLoggerForComponent.get(componentName), nsubLogger];
    } else {
      newValue = [nsubLogger];
    }
    classifierLoggerForComponent.set(componentName, newValue );
  }

  static setLevelForComponentsClassifier(componentName: string, classifier: Classifier, level: LogLevel):void {
    ClassifierLogger.setClassifierLevelForComponent(componentName, classifier, level);
    if (classifierLoggerForComponent.has(componentName)) {
      classifierLoggerForComponent.get(componentName).filter(it => it.classifier === classifier ).forEach(it =>  it.setLevel(level));
    }
  }

  static getClassifierLevelForComponent(component:string, classifier:Classifier):LogLevel {
    const key = component + "." + classifier;
    return classifierLevelForComponent.get(key);
  }

  static setClassifierLevelForComponent(component:string, classifier:Classifier, loglevel:LogLevel):void {
    const key = component + "." + classifier;
    classifierLevelForComponent.set(key, loglevel);
  }
}
