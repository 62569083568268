import {IFilter} from "../Filter";
import {IStaticFilterMatcher} from "./IStaticFilterMatcher";
import {ElementId} from "../../Core";
import {modelStore} from "../../../stores/ModelStore";

export class StaticAttributeFilter implements IFilter {
  _matcher;
  _attributeName;

  constructor(attributeName: string, staticFilterMatcher: IStaticFilterMatcher) {
    this._attributeName = attributeName;
    this._matcher = staticFilterMatcher;
  }

  matches(nodeIds: ElementId[]): ElementId[] {
    return nodeIds.filter(nodeId => this._matcher.valueSatisfiesFilterCondition(this.getAttributeValue(nodeId)));
  }

  protected getAttributeValue(nodeId: ElementId): string {
    const attributeValue: string = modelStore.getElement(nodeId)[this._attributeName];
    return attributeValue === null ? "" : attributeValue;
  }

  get matcher(): IStaticFilterMatcher {
    return this._matcher;
  }

}