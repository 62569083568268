import {Rect, Rectangle} from "./Geometry";
import Log from "./Logger";
import {Classifier} from "./ClassifierLogger";

const renderLog = Log.logger("diagram", Classifier.render);

/**
 * visibility flag the virtual render util passes to the component mapper, so client can decide how the component is rendered dependent on visibility
 */
export enum VirtualVisibility {
  /** fully visible and interactive, intersects visible client area */
  FULL,
  /** not visible but might be prerendered with lower priority to optimize scrolling behavior */
  PRERENDERED,
  /** nothing or simple and maybe non interactive sketch */
  INVISIBLE
}

/**
 * calculate the prerendering rect from the visible rect by trippling size in each direction like this (v = visible rect, p = prerender rect)
 * |p|p|p|
 * |p|v|p|
 * |p|p|p|
 * @param visibleRect visible rect
 * @param factor factor to inflate rect to all sides, default is one
 */
export function getPrerenderRect(visibleRect: Rect, factor: number = 1): Rect {
  // make it triple size and height
  return {
    x: visibleRect.x - visibleRect.width * factor,
    y: visibleRect.y - visibleRect.height * factor,
    width: visibleRect.width + 2 * visibleRect.width * factor,
    height: visibleRect.height + 2 * visibleRect.height * factor
  };
}

/**
 *
 * @param oldVisibleRect
 * @param newVisibleRect
 * @return true if the newVisibleRect is more than half of the prerender rect moved from the old position in any direction
 */
export function isRerenderThresholdReached(oldVisibleRect: Rect, newVisibleRect: Rect): boolean {
  if (oldVisibleRect === undefined || newVisibleRect === undefined) {
    // virtual mode is disabled if undefined; if equal, no update needed; otherwise mode was switched, rerender
    return oldVisibleRect !== newVisibleRect;
  } else {
    // only rerender if new visible rect is not contained in half sized prerender rect anymore
    const halfPrerenderRect = getPrerenderRect(oldVisibleRect, 0.5);
    return !Rectangle.fromJS(halfPrerenderRect).containsRect(Rectangle.fromJS(newVisibleRect));
  }
}

export type ComponentMapper<T> = (modelElement: T, visibility: VirtualVisibility, levelCount: number, isThumbNail: boolean) => JSX.Element;

/**
 * renders a list of model elements which define a rect the same coordinates as the visibleRect using the given componentMapper render function.
 * @param visibleRect the visible rectangle used for virtualization; if undefined, no virtualization is done, all model elements are mapped with hidden = false and in full detail
 * @param levelCount number of levels in this view, needed to determine level colors
 * @param visuals visuals to map; they must supply coordinates in the same coordinate system as visibleRect
 * @param componentMapper render function to use; it is called with a model element and visibility and thumbnail flag; if the mapper returns sth != undefined for a visual, it is added to the result list
 * @param zoomFactor current zoom factor to determine if thumbnail rendering should be used
 * @param logMessagePrefix prefix used for logging to pass the context for virtualization
 * @return list of rendered JSX elements as defined by the component mapper
 */
export function mapVirtualized<T extends Rect>(visibleRect: Rect, levelCount: number, visuals: T[], componentMapper: ComponentMapper<T>, zoomFactor: number = undefined, logMessagePrefix: string = ""): JSX.Element[] {
  let result: JSX.Element[];
  let visibleCount: number = 0;
  let hiddenCount: number = 0;
  if (visibleRect) {
    result = [];
    const prerenderRect = getPrerenderRect(visibleRect);
    visuals.forEach(value => {
      // if height less than 5 pixel text does not make sense anymore, thus use reduced details
      const isThumbNail = zoomFactor && (value.height * zoomFactor < 7);
      let component: JSX.Element = undefined;
      if (Rectangle.intersects(visibleRect, value)) {
        // visible in current client area
        component = componentMapper(value, VirtualVisibility.FULL,levelCount, isThumbNail);
        visibleCount++;
      } else if (Rectangle.intersects(prerenderRect, value)) {
        // invisible, but in prerender rect thus visible when scrolling
        component = componentMapper(value, VirtualVisibility.PRERENDERED,levelCount, isThumbNail);
        hiddenCount++;
      } else {
        // invisible might be rendered as simple preview, default would be undefined to not render them at all
        component = componentMapper(value, VirtualVisibility.INVISIBLE, levelCount,isThumbNail);
      }
      if (component) {
        result.push(component);
      }
    });
  } else {
    // no visible rect given means virtual rendering is deactivated
    result = visuals.map(value => componentMapper(value, VirtualVisibility.FULL, levelCount,false));
    visibleCount = result.length;
  }
  const message = logMessagePrefix + " #Elements: " + visuals.length + ", #visible: " + visibleCount + ", #hidden: " + hiddenCount;
  renderLog.debug(message);
  return result;
}
