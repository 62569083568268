/**
 * facade to decouple view state management from workbench module. The workbench will register its view manager here, so all modules can lookup view information like active view,
 * view info and editor state information.
 */
import {Validate} from "../../common/utils/Validate";
import {ViewId} from "../../core/utils/Core";
import {ViewInfo} from "./ViewInfo";
import {IEditorState} from "./IEditorState";

export interface IViewManager {
  /**
   * @param viewId
   * @return ViewInfo info for given view, undefined if view not found
   */
  getViewInfoById(viewId: ViewId): ViewInfo;

  /**
   * the active view, undefined if none active
   */
  activeViewId: ViewId;

  /**
   * the open cockpit, undefined if no cockpit
   */
  currentCockpitId: ViewId;

  /**
   * @param windowIndex
   * @return IEditorState editor state, contains information depending on editor, not view like maximised, header collapsed, zoom factor
   */
  getEditorStateByWindowIndex(windowIndex: number): IEditorState;

  /**
   * @return view infos for all open windows, open windows without an open view are ignored
   */
  getOpenViewInfos(): ViewInfo[];
}

class ViewManagerRegistry {
  private _viewManager: IViewManager = undefined;

  set viewManager(viewManager: IViewManager) {
    Validate.isTrue(this._viewManager === undefined || viewManager === undefined, "A ViewManager is already registered");
    this._viewManager = viewManager;
  }

  get viewManager(): IViewManager {
    return this._viewManager;
  }
}

export const viewManagerRegistry = new ViewManagerRegistry();
