import Action from "../../common/actions/BaseAction";
import Log from "../../common/utils/Logger";
import {DiagramModel} from "../models/DiagramModel";
import {
  ElementId,
  ElementObject,
  TableId,
  ViewId,
  VisualAttributeId,
  VisualId,
  VisualTableId
} from "../../core/utils/Core";
import {DiagramSyncActions, ResizePayload} from "../actions/DiagramActions";
import {Connection, DiagramData, Table, UUID} from "../../api/api";
import {ViewType} from "../../common/constants/Enums";
import * as _ from "lodash";
import StoreBase from "../../common/stores/StoreBase";
import {attributeValues2ElementObjects, modelStore} from "../../core/stores/ModelStore";
import {DiagramActions} from "../actions/DiagramAsyncActionCreators";
import {ChartDiagramModel} from "../models/chart/ChartDiagramModel";
import {ValueChartDiagramModel} from "../models/valuechart/ValueChartDiagramModel";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {VisualValueChartLevel} from "../models/valuechart/VisualValueChartLevel";
import {AutoLayoutOptions} from "../utils/autolayout/AutoLayoutOptions";
import {action, observable} from "mobx";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {LoadActions} from "../../modelselection/actions/ModelAsyncActionCreators";
import {NewViewAction} from "../../workbench/actions/ViewManagerActions";
import {
  ReorderTablesAction,
  ReorderTablesActionPayload,
  SharedViewActions,
  ToggleHeaderExpandedAction,
  UpdateViewerFilterForAttributePayload
} from "../../commonviews/actions/SharedViewActions";
import {AddAttributeToViewPayload, LoadWebViewAction} from "../../commonviews/actions/SharedViewAsyncActions";
import {Validate} from "../../common/utils/Validate";
import {ViewInfo} from "../../commonviews/models/ViewInfo";
import {
  CoreActions,
  CoreAsyncActions,
  LoadConnectionsPayload,
  SelectModelAction
} from "../../core/actions/CoreAsyncActions";
import {AttributesToDelete} from "../../core/actions/CoreActions";
import {VisualBaseTable} from "../models/common/CommonDiagramTypes";
import {VisualTextBox} from "../models/common/VisualTextBox";
import {viewManagerRegistry} from "../../commonviews/models/ViewManager";
import {nextYPosition} from "../utils/DiagramPositionUtil";

const log = Log.logger("DiagramStore", Classifier.action);


/**
 * manages a diagram
 */
export class DiagramStore extends StoreBase {
  @observable private _diagrams: Map<ViewId, DiagramModel> = new Map();

  constructor() {
    super();
    this.accept = this.accept.bind(this);
  }

  @action accept(actionParam: DiagramActions | LoadActions | CoreActions | SelectModelAction | NewViewAction | LoadWebViewAction | ReorderTablesAction): void {
    log.debug("DiagramStore accepting " + actionParam.type, actionParam);
    const start = this.time();
    switch (actionParam.type) {
        //  handle actions which are not dispatched to a diagram
      case "selectmodel":
        this.reset();
        this.notify(actionParam.type);
        break;
      case "newView":
        const viewInfo: ViewInfo = actionParam.payload;
        if (viewInfo.type === ViewType.Chart || viewInfo.type === ViewType.ValueChart) {
          log.debug("Creating a new diagram view.");
          this.createNewDiagram(viewInfo.id, viewInfo.name, viewInfo.type);
        }
        break;
      case "webview": {
        // open existing view
        if (actionParam.resourceId !== "WebViewHierarchy") {
          const viewInfo: ViewInfo = viewManagerRegistry.viewManager.getViewInfoById(actionParam.resourceId);
          if (viewInfo && _.includes([ViewType.Chart, ViewType.ValueChart], viewInfo.type)) {
            log.debug("Add Diagram View of type " + viewInfo.type, actionParam.resourceId);
            this.createNewDiagram(actionParam.resourceId, viewInfo.name, viewInfo.type, actionParam.payload);
            this.logAndNotify(actionParam, start);
          }
        }
        break;
      }
      case "selectelements":
      case "clearselection":
        Dispatcher.waitFor([modelStore.dispatchToken]);
        this._diagrams.forEach(diagram => {
          if (diagram.showConnectedOnly) {
            diagram.onConnectedToSelectionStateChange(undefined);
          }
          diagram.updateSelectionState(modelStore.primarySelection, modelStore.secondarySelection);
        });
        this.logAndNotify(actionParam, start);
        break;

      case "deleteTables":
        const tableIds: TableId[] = actionParam.payload;
        this.removeTablesFromAllDiagrams(tableIds, actionParam.groupId);
        break;

      case "deleteAttributes":
        const payload: AttributesToDelete = actionParam.payload;
        // Remove attribute from (Value)Charts
        this.removeAttributesFromAllDiagrams(payload.tableIds, payload.attributeNames, actionParam.groupId);
        break;

      default:
        //  actions which are dispatched to one ore more diagram(s)
        const diagram = this.getDiagramForId(actionParam.resourceId);
        let processed: boolean = false;
        if (diagram) {
          if (diagram instanceof ChartDiagramModel) {
            processed = this.acceptChartActions(actionParam, diagram, processed);
          } else if (diagram instanceof ValueChartDiagramModel) { // end chart only actions
            processed = this.acceptValueChartActions(actionParam, diagram, processed);
          }
          if (!processed) {
            processed = this.acceptCommonDiagramActions(actionParam as DiagramSyncActions, diagram, processed);
          }
        } else {
          // diagram not targeted directly, try common actions
          this._diagrams.forEach((d) => {
            const accepted = this.acceptCoreModelActions(actionParam as CoreActions, d, processed);
            processed = processed || accepted;
          });
        }
        if (processed) {
          this.logAndNotify(actionParam, start);
        } else {
          log.debug("DiagramStore ignored", actionParam);
        }
    }
  }

  /**
   * accepts actions which modify only the core model and are not targeted to a view, but all views must listen for syncing their state with core model state
   * @param {Action} action
   * @param {DiagramModel} diagram
   * @param {boolean} processed
   * @returns {boolean}
   */
  private acceptCoreModelActions(action: CoreActions, diagram: DiagramModel, processed: boolean): boolean {
    const start = this.time();
    switch (action.type) {
      case "updateAttributeValues": {
        Dispatcher.waitFor([modelStore.dispatchToken]);
        log.debug("updateAttributeValues", action.payload);
        const element: ElementObject = action.payload;
        diagram.mergeAttributeValues(action.resourceId, [element]);
        this.logAndNotify(action, start);
        break;
      }
      case "duplicateElements":
        Dispatcher.waitFor([modelStore.dispatchToken]);
        const {originalElementIds, newElementIds, viewId, newY} = action.payload;

        if(diagram instanceof ChartDiagramModel) {
          diagram.duplicateElements(originalElementIds, newElementIds, newY);
        }


        for (let i = 0; i < newElementIds.length; i++) {
          const newElementTableId: TableId = modelStore.getTableForElement(newElementIds[i]);
          const newElementName: string = modelStore.getElement(newElementIds[i])["name"];

          if(!(diagram instanceof ChartDiagramModel)) {
            diagram.newVisualElement(newElementTableId, newElementIds[i], newElementName, null);
          }
          const connections: Connection[] = [];
          const connectedElementIds: ReadonlySet<ElementId> = modelStore.getConnectedElements(newElementIds[i]);
          connectedElementIds.forEach((connectedElementId: ElementId) => {
                const connection: Connection = modelStore.getConnection(newElementIds[i], connectedElementId, true);
                connections.push(connection);
              }
          )

          diagram.addConnections(connections);

          diagram.mergeAttributeValues(newElementTableId, modelStore.getElementsById([newElementIds[i]]));
        }

        this.logAndNotify(action, start);
        break;
      case "newElement":
        Dispatcher.waitFor([modelStore.dispatchToken]);
        log.debug("new Element");
        const {elementId, tableId, name, x, y} = action.payload;
        diagram.newVisualElement(tableId, elementId, name, y);
        this.logAndNotify(action, start);
        break;
      case "moveElementsToTable":
        log.debug("moveElementToTable", action.payload);
        const {previousToNewElementIdsMap, sourceTableId, targetTableId, lostAttributeNames, isCopy} = action.payload;
        this.moveElementsToTable(diagram, previousToNewElementIdsMap, sourceTableId, targetTableId, lostAttributeNames, isCopy, action.payload.y);
        this.logAndNotify(action, start);
        break;
      case "deleteElements":
        Dispatcher.waitFor([modelStore.dispatchToken]);
        log.debug("deleteElements");
        const elementIds: ElementId[] = action.payload;
        diagram.deleteVisualElements(...elementIds);
        this.logAndNotify(action, start);
        break;
      case "toggleConnections":
        Dispatcher.waitFor([modelStore.dispatchToken]);
        log.debug("new Connection");
        const {sourceElementIds, targetElementId} = action.payload;
        diagram.toggleConnections(sourceElementIds, targetElementId);
        this.logAndNotify(action, start);
        break;
      case "createConnection": {
        const connection: Connection = action.payload;
        diagram.addConnections([connection]);
        break;
      }
      case "deleteConnection": {
        const connection: Connection = action.payload;
        diagram.deleteConnections([connection]);
        break;
      }
    }
    return processed;
  }

  /**
   * accepts actions which are common to all diagram types but targeted to a specific diagram
   * @param {Action} action
   * @param {DiagramModel} diagram
   * @param {boolean} processed
   * @returns {boolean}
   */
  private acceptCommonDiagramActions(action: DiagramSyncActions | SharedViewActions | CoreAsyncActions, diagram: DiagramModel, processed: boolean): boolean {
    const start = this.time();
    switch (action.type) {
      case "addTableToView":
        log.debug("Add table to view");
        const visualTableId = action.payload.visualTableId;
        const title = modelStore.getTableName(visualTableId.tableId);
        diagram.addTable(visualTableId, title, action.payload.insertPosition);
        processed = true;
        break;
      case "addAttributeToView":
        log.debug("Add attribute to view");
        diagram.addAttribute(action.payload as AddAttributeToViewPayload);
        processed = true;
        break;
      case "removeTableFromView":
        log.debug("Remove table from view");
        diagram.removeTable(action.payload.visualTableId);
        processed = true;
        break;
      case "removeAttributeFromView":
        log.debug("Remove attribute from view");
        diagram.removeAttribute(action.payload.visualAttributeId);
        processed = true;
        break;
      case "moveattribute": {
        log.debug("moveattribute", action.payload);
        const {dx, dy, visualAttributeId} = action.payload;
        diagram.moveAttributeColumn(visualAttributeId, dx, dy);
        processed = true;
        break;
      }
      case "resizeTable":
        log.debug("resizeTable");
        const payload: ResizePayload = action.payload;
        diagram.resizeTable(payload.visualId as VisualTableId, payload.direction, payload.dx, payload.dy);
        processed = true;
        break;
      case "resizeAttribute":
        log.debug("resizeAttribute");
        const payload1: ResizePayload = action.payload;
        diagram.resizeAttribute(payload1.visualId as VisualAttributeId, payload1.direction, payload1.dx, payload1.dy);
        processed = true;
        break;
      case "resizeVisualObject":
        log.debug("resizeTextbox");
        const resizePayload: ResizePayload = action.payload;
        diagram.resizeVisualObject(resizePayload.visualId as VisualId, resizePayload.direction, resizePayload.dx, resizePayload.dy);
        processed = true;
        break;
      case "showConnectedToSelection":
        log.debug("showConnectedToSelection");
        diagram.showConnectedOnly = action.payload;
        diagram.onConnectedToSelectionStateChange(action.payload);
        processed = true;
        break;
      case "setConditionalFormats":
        log.debug("setConditionalFormats");
        const payload2 = action.payload;
        diagram.setConditionalFormats(payload2.visualAttributeId, payload2.conditionalFormats);
        processed = true;
        break;
      case "updateViewerFilterForAttribute":
        log.debug("updateViewerFilterForAttribute");
        const {visualAttributeId, filterString}: UpdateViewerFilterForAttributePayload = action.payload;
        diagram.updateViewerFilterForAttribute(visualAttributeId, filterString);
        processed = true;
        break;
      case "toggleFilterLine":
        log.debug("toggleFilterLine");
        diagram.toggleFilterLine(action.payload);
        processed = true;
        break;
      case "rotateAttributeHeaders":
        log.debug("rotateAttributeHeaders");
        diagram.rotateAttributeHeaders();
        processed = true;
        break;
      case "changeAttributeHeaderHeight":
        log.debug("changeAttributeHeaderHeight");
        const newHeight: number = action.payload;
        diagram.attributeHeaderHeight = newHeight;
        processed = true;
        break;
      case "printView":
        // fall through
      case "exportView":
        // these actions are handled by the view, thus notify
        processed = true;
        break;
      case "newTextbox":
        log.debug("newTextbox", action.payload);
        const {x, y} = action.payload;
        const newTextbox = new VisualTextBox(diagram, x, y);
        diagram.textBoxes.push(newTextbox);
        diagram.addChildren(newTextbox);
        processed = true;
        break;
      case "updateTextboxText":
        log.debug("updateTextboxText", action.payload);
        const {id, newText} = action.payload;
        const textBox = diagram.textBoxes.find(tB => tB.id === id);
        if (textBox) {
          textBox.text = newText;
        }
        processed = true;
        break;
      case "removeTextboxFromView":
        log.debug("removeTextboxFromView", action.payload);
        const textboxId = action.payload;
        const index = diagram.textBoxes.findIndex(tB => tB.id === textboxId);
        diagram.textBoxes.splice(index, 1);
        diagram.removeChildById(textboxId);
        processed = true;
        break;
    }
    return processed;
  }

  private acceptValueChartActions(action: DiagramActions | LoadActions | CoreActions | ReorderTablesAction | ToggleHeaderExpandedAction, diagram: ValueChartDiagramModel, processed: boolean): boolean {
    const valueChart = diagram.valueChart;
    switch (action.type) {
      case "loadAttributeValues":
        Dispatcher.waitFor([modelStore.dispatchToken]);
        const tables: Table[] = action.payload;
        tables.forEach(table => {
          log.debug("Adding attribute values", table.tableId);
          diagram.valueChart.updateAllElementProperties();
        });
        processed = true;
        break;
      case "loadConnections":
        Dispatcher.waitFor([modelStore.dispatchToken]);
        const {connections, newTableIds}: LoadConnectionsPayload = action.payload;
        log.debug("value chart connections arrived", newTableIds);
        valueChart.updateRootVisualElements();
        processed = true;
        break;

      case "changeColumnCountValueChartLevel":
        log.debug("changeColumnCountValueChartLevel");
        const {visualTableId, columnCount} = action.payload;
        const level: VisualValueChartLevel = valueChart.getLevel(visualTableId);
        if (level) {
          level.columns = columnCount;
          valueChart.layout();
        }
        processed = true;
        break;
      case "reorderTables": {
        log.debug(action.type, action.payload);
        const {sourceVisualTableId, viewId, newIndex} = action.payload;
        if (viewId === diagram.id) {
          // move requested
          const payload1: ReorderTablesActionPayload = action.payload;
          valueChart.moveLevel(sourceVisualTableId, newIndex);
        } else {
          // otherwise add it
          const vtid = new VisualTableId(sourceVisualTableId.tableId);
          valueChart.addTable(vtid, modelStore.getTableName(sourceVisualTableId.tableId));
        }
        break;
      }
      case "toggleHeaderExpanded":
        log.debug("toggleHeaderExpanded");
        diagram.isHeaderExpanded = action.payload.isHeaderExpanded;
        diagram.valueChart.layout();
        processed = true;
        break;

      case "sortChartColumn": {
        log.debug("sort value chart column", action.payload);
        const {visualAttributeId, ascending} = action.payload;
        diagram.sortValueChartColumn(visualAttributeId, ascending);
        processed = true;
        break;
      }

    }
    return processed;
  }

  private acceptChartActions(action: DiagramActions | LoadActions | CoreActions | ReorderTablesAction, diagram: ChartDiagramModel, processed: boolean): boolean {
    switch (action.type) {
      case "loadAttributeValues": {
        Dispatcher.waitFor([modelStore.dispatchToken]);
        const tables: Table[] = action.payload;
        tables.forEach(table => {
          log.debug("Adding attribute values", table.tableId);
          diagram.mergeAttributeValues(table.tableId, attributeValues2ElementObjects(table.attributeValues));
        });
        processed = true;
        break;
      }
      case "movetable": {
        log.debug("movetable", action.payload);
        const {dx, dy, visualTableId} = action.payload;
        diagram.moveTableColumn(visualTableId, dx, dy);
        processed = true;
        break;
      }
      case "loadConnections":
        Dispatcher.waitFor([modelStore.dispatchToken]);
        const {connections, newTableIds}: LoadConnectionsPayload = action.payload;
        log.debug("diagrams connections arrived", connections);
        diagram.initVisualConnections(connections);
        processed = true;
        break;
      case "movenodes": {
        log.debug("movenodes", action.payload);
        const {dx, dy, nodes} = action.payload;
        diagram.moveNodes(nodes, dx, dy);
        processed = true;
        break;
      }
      case "autolayout":
        log.debug("autolayout");
        const payload2: AutoLayoutOptions = action.payload;
        diagram.autolayout(payload2);
        processed = true;
        break;
      case "updateAutoLayoutProperties":
        const autoLayoutOptions: AutoLayoutOptions = action.payload;
        diagram.autolayout(autoLayoutOptions, false /* doLayout*/);
        processed = true;
        break;
      case "sortChartColumn": {
        log.debug("sort chart column", action.payload);
        const {visualAttributeId, ascending} = action.payload;
        diagram.sortChartColumn(visualAttributeId, ascending);
        processed = true;
        break;
      }
    }
    return processed;
  }

  /**
   * @return {DiagramModel[]} only non-null entries, null entries are skipped
   */
  get diagrams(): Array<DiagramModel> {
    return Array.from(this._diagrams.values());
  }

  /**
   * @returns {Array<ChartDiagramModel>} only chart diagrams are returned
   */
  get charts(): Array<ChartDiagramModel> {
    return Array.from(this._diagrams.values()).filter(diagram => diagram instanceof ChartDiagramModel) as ChartDiagramModel[];
  }

  public createNewDiagram(id: ViewId, title: string, type: ViewType, diagramData?: DiagramData): DiagramModel {
    Validate.isTrue(!this._diagrams.has(id), "ViewId already exists in DiagramStore: " + id);
    let diagram: DiagramModel = null;
    if (type === ViewType.Chart) {
      diagram = new ChartDiagramModel(id, title, type, diagramData);
    } else if (type === ViewType.ValueChart) {
      diagram = new ValueChartDiagramModel(id, title, type, diagramData);
    } else {
      throw Error("DiagramStore cannot create Diagram of Type " + ViewType[type]);
    }

    diagram.setLoadedSerializedModel(diagramData);

    this._diagrams.set(id, diagram);
    return diagram;
  }

  /**
   * @param {ViewId} viewId viewId to open
   * @returns {DiagramModel} diagram with the given view id
   */
  public getDiagramForId(viewId: ViewId): DiagramModel {
    return this._diagrams.get(viewId);
  }

  reset(): void {
    this._diagrams.clear();
  }

  private time(): number {
    if (typeof performance !== "undefined") {
      return performance.now();
    } else {
      return Date.now();
    }
  }

  private logAndNotify(action: Action, start: number): void {
    const ms = this.time() - start;
    log.debug(`accept took ${ms} ms`, action);
    const start2 = this.time();
    this.notify(action.type);
    const ms2 = this.time() - start2;
    log.debug(`notify took ${ms2} ms`, action);
  }

  private removeTablesFromAllDiagrams(tableIds: TableId[], groupId?: UUID): void {
    const tableIdSet = new Set<TableId>(tableIds);
    this.diagrams.forEach(d => d.visualTables.filter((visualTable: VisualBaseTable) => tableIdSet.has(visualTable.id.tableId)).map(visualTable => {
      d.removeTable(visualTable.id);
    }));
  }

  private removeAttributesFromAllDiagrams(tableIds: TableId[], attributeNames: string[][], groupId?: UUID): void {
    const tableIdSet = new Set<TableId>(tableIds);
    this.diagrams.forEach(d => d.visualAttributeIds(tableIds, attributeNames).forEach(vAttId => {
      d.removeAttribute(vAttId);
    }));
  }

  private moveElementsToTable(diagram: DiagramModel, previousToNewElementIdsMap: Map<ElementId, ElementId>, sourceTableId: TableId, targetTableId: TableId, lostAttributeNames: string[], isCopy: boolean, y: number): void {
    const distanceBetweenElements = nextYPosition(0, 1);
    const distanceToMoveExistingElements = distanceBetweenElements * previousToNewElementIdsMap.size;
    diagram.getVisualTablesForTable(targetTableId).forEach(visualTable => {
      visualTable.elements.forEach(visualElement => {
        if (visualElement.y > y) {
          visualElement.y += distanceToMoveExistingElements;
        }
      });
    });
    let currentY = y;
    previousToNewElementIdsMap.forEach((newElementId, previousElementId) => {
      const visualElementsToMove = diagram.getVisualElementsByElementId(previousElementId);
      if (visualElementsToMove.length > 0) {
        // can't retrieve connections and attribute values from the model store, since it is getting updated simultaniously and it thus the element can already be deleted
        const attributeValues = visualElementsToMove[0].attributeValues;
        const connections = diagram.connections.filter((connection) => connection.source.id.elementId === previousElementId || connection.target.id.elementId === previousElementId);
        if (!isCopy) {
          diagram.deleteVisualElements(previousElementId);
        }
        diagram.newVisualElement(targetTableId, newElementId, attributeValues.get("name").value, currentY);
        currentY += distanceBetweenElements;
        const newVisualElements = diagram.getVisualElementsByElementId(newElementId);
        const newConnections: Connection[] = connections.map((connection) => {
          const targetElementId = connection.target.id.elementId === previousElementId ? newElementId : connection.target.id.elementId;
          const sourceElementId = connection.source.id.elementId === previousElementId ? newElementId : connection.source.id.elementId;
          return {targetElementId, sourceElementId, comment: "", strength: 1};
        });
        // load the connections to the source table because those didn't get displayed up until now and thus didn't exist in the connections saved in the chart
        const connectionsToSource = modelStore.getConnectedElementIdsToTable(newElementId, sourceTableId).map(connectedElement => {
          return {targetElementId: connectedElement, sourceElementId: newElementId, comment: "", strength: 1};
        });
        newConnections.push(...connectionsToSource);
        diagram.addConnections(newConnections);
        const newElementObject: ElementObject = {id: newElementId};
        attributeValues.forEach((value, attName) => {
          if (!lostAttributeNames.includes(attName)) {
            newElementObject[attName] = value.value
          }
        });
        diagram.mergeAttributeValues(targetTableId, [newElementObject]);
      }
    });
  }
}

// singleton
export const diagramStore = new DiagramStore();
