// lib imports
import * as React from "react";

import Log from "../../../common/utils/Logger";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {IconButton} from "@material-ui/core";
import autobind from "autobind-decorator";
import MetusSidebarEntry from "../../../common/components/MetusSidebarEntry";
import ViewsIcon from "../../../common/icons/menu/ViewsIcon";
import MetusSidebar from "../../../common/components/MetusSidebar";
import TablesIcon from "../../../common/icons/menu/TablesIcon";
import CockpitsIcon from "../../../common/icons/menu/CockpitsIcon";
import {Info} from "@material-ui/icons";
import {observer} from "mobx-react";
import CockpitsNavigationContainer from "./CockpitsNavigationContainer";
import ViewsNavigationContainer from "./ViewsNavigationContainer";
import TablesNavigationContainer from "./TablesNavigationContainer";
import AttachFilterBarComponent from "./AttachFilterBarComponent";
import {showNewElementDialog, showNewTableDialog} from "../NewElementDialog";
import {showInfoDialog} from "../../../common/components/InfoDialog";
import {showNewCockpitDialog} from "../NewCockpitDialog";
import {configurationStore} from "../../../core/stores/ConfigurationStore";
import {ModelLocationType} from "../../../common/constants/Enums";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
  modelLocation: ModelLocationType;
  onClosedChanged?: (isClosed: boolean) => void;
  closed?: boolean;
}

@observer
class NavigationSidebarComponent extends React.Component<LocalProps, {}> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering NavigationSidebarComponent", this.state, this.props);
    return <MetusSidebar type="navigation" onVisibleEntryChange={this.onVisibleEntryChange}
                         visibleEntryIndex={this.props.closed ? null : 0}
                         additionalIconbarEntries={[this.createInfoIcon()]}>
      <MetusSidebarEntry icon={<CockpitsIcon data-testselector="CockpitsIconBarEntry"/>} subtitle={"Cockpits"}>
        <AttachFilterBarComponent title={"Cockpits"} onNewElement={this.createCockpit} showButton={true}>
          <CockpitsNavigationContainer/>
        </AttachFilterBarComponent>
      </MetusSidebarEntry>;

      <MetusSidebarEntry icon={<ViewsIcon data-testselector="ViewsIconBarEntry"/>} subtitle={"Views"}>
        <AttachFilterBarComponent title={"Views"} onNewElement={this.createNewElement} showButton={true}>
          <ViewsNavigationContainer/>
        </AttachFilterBarComponent>
      </MetusSidebarEntry>

      <MetusSidebarEntry icon={<TablesIcon data-testselector="TablesIconBarEntry"/>} subtitle={"Tables"}>
        <AttachFilterBarComponent title={"Tables"} onNewElement={this.createTable}
                                  showButton={configurationStore.canWriteToServer()}>
          <TablesNavigationContainer/>
        </AttachFilterBarComponent>
      </MetusSidebarEntry>
    </MetusSidebar>;
  }

  private createCockpit(): void {
    log.debug("Create Cockpit");
    showNewCockpitDialog(true);
  }

  private createNewElement(): void {
    log.debug("Create New Element");
    showNewElementDialog(true);
  }

  private createTable(): void {
    log.debug("Create Table");
    showNewTableDialog(true);
  }

  private createInfoIcon(): JSX.Element {
    return <IconButton key="icon_info"
                       aria-label="show info"
                       onClick={(): void => showInfoDialog(true)}>
      <Info/>
    </IconButton>;
  }

  @autobind
  private onVisibleEntryChange(entry: number): void {
    const isClosed = entry === null;
    this.props.onClosedChanged(isClosed);
  }

}

export default NavigationSidebarComponent;
