/**
 * descriptive type; this point denotes a browser client location
 */
export type ClientLocation = Point;

export class Point {
  readonly x: number;
  readonly y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  //noinspection JSUnusedGlobalSymbols
  minus(p: Point): Point {
    return new Point(this.x - p.x, this.y - p.y);
  }

  //noinspection JSUnusedGlobalSymbols
  plus(p: Point): Point {
    return new Point(this.x + p.x, this.y + p.y);
  }

  isZero(): boolean {
    return this.x === 0 && this.y === 0;
  }

  add(dx: number, dy: number): Point {
    return new Point(this.x + dx, this.y + dy);
  }

  /**
   *
   * @param {Point} other
   * @param dx max coordinate difference in x direction
   * @param dy max coordinate difference in y direction
   * @returns {boolean} true if this point is close to the other point, i.e. coordinate difference in each direction is less than given delta
   */
  // noinspection JSUnusedGlobalSymbols
  isCloseTo(other: Point, dx: number, dy: number): boolean {
    const d = this.minus(other);
    // noinspection JSSuspiciousNameCombination
    const result = Math.abs(d.x) <= dx && Math.abs(d.y) <= dy;
    return result;
  }

  scale(scale: number): Point {
    return new Point(this.x * scale, this.y * scale);
  }
}

export class Size {
  readonly width: number;
  readonly height: number;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }
}

export type Rect = {
  x: number;
  y: number;
  width: number;
  height: number;
};

/**
 * a rectangle defined by x,y, width, height
 */
export class Rectangle {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;

  /**
   * create minimal rect with these two points inside, i.e. topleft and bottomright corner,
   * normalizes coordinates so that width and height always are >= 0
   * @param a
   * @param b
   */
  constructor(a: Point, b: Point) {
    this.x = Math.min(a.x, b.x);
    this.y = Math.min(a.y, b.y);
    this.width = Math.abs(b.x - a.x);
    this.height = Math.abs(b.y - a.y);
  }


  /**
   * true if point is contained in rectangle
   * @param point
   */
  containsPoint(point: Point): boolean {
    return point.x >= this.x && point.x <= this.x + this.width && point.y >= this.y && point.y <= this.y + this.height;
  }

  /**
   * test if the given rectangles intersect
   * @param rect1
   * @param rect2
   * @return true if they intersect, false if not
   */
  static intersects(rect1: (Rectangle | Rect), rect2: (Rectangle | Rect)): boolean {
    if (rect2.x < rect1.x + rect1.width && rect1.x < rect2.x + rect2.width && rect2.y < rect1.y + rect1.height)
      return rect1.y < rect2.y + rect2.height;
    else
      return false;
  }

  /**
   * get flat javascript object
   */
  get toJS(): Rect {
    return {x: this.x, y: this.y, width: this.width, height: this.height};
  }

  /**
   * true if rect is contained in rectangle
   * @param rect
   */
  containsRect(rect: Rectangle): boolean {
    return this.containsPoint(rect.topLeft) && this.containsPoint(rect.bottomRight);
  }

  getMiddle(): Point {
    return new Point(this.x + this.width / 2, this.y + this.height / 2);
  }

  get topLeft(): Point {
    return new Point(this.x, this.y);
  }

  get bottomRight(): Point {
    return new Point(this.x + this.width, this.y + this.height);
  }

  /**
   * create rect from x,y, width, height
   * @param x
   * @param y
   * @param width
   * @param height
   * @return {Rectangle}
   */
  static from(x: number, y: number, width: number, height: number): Rectangle {
    return new Rectangle(new Point(x, y), new Point(x + width, y + height));
  }

  /**
   * create rect from js Rect structure
   * @param rect
   * @return {Rectangle}
   */
  static fromJS(rect: Rect): Rectangle {
    return Rectangle.fromPoints(rect.x, rect.y, rect.x + rect.width, rect.y + rect.height);
  }

  /**
   * create rect from two points (x,y), (x2,y2)
   * @param x
   * @param y
   * @param x2
   * @param y2
   * @return {Rectangle}
   */
  static fromPoints(x: number, y: number, x2: number, y2: number): Rectangle {
    return new Rectangle(new Point(x, y), new Point(x2, y2));
  }

  /**
   * union of given rects
   * @param rects
   * @return smallest rectangle containing all given rects, or undefined if no rects given
   */
  static union(...rects: (Rectangle | Rect)[]): Rectangle {
    let result: Rectangle = undefined;
    rects.forEach(value => {
        result = result ? result.union(value) : (value instanceof Rectangle ? value : Rectangle.fromJS(value));
    });
    return result;
  }

  /**
   * true if point is contained in rectangle
   * @param point
   */
  contains(x: number, y: number): boolean {
    return x >= this.x && x <= this.x + this.width && y >= this.y && y <= this.y + this.height;
  }

  /**
   * @return bigger rectangle containing both rectangles
   * @param other
   */
  union(other: Rectangle | Rect): Rectangle {
    const minX = Math.min(this.x, other.x);
    const minY = Math.min(this.y, other.y);
    const maxX = Math.max(this.x + this.width, other.x + other.width);
    const maxY = Math.max(this.y + this.height, other.y + other.height);
    return new Rectangle(new Point(minX, minY), new Point(maxX, maxY));

  }
}

