/* ConfirmModelDeleteDialog.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Juni 2020
 */
import * as React from "react";
import Log from "../../common/utils/Logger";
import {createStyles, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import {Classifier} from "../../common/utils/ClassifierLogger";
import MetusDialog from "../../common/components/MetusDialog";
import {hideDialog, showDialog} from "../../common/utils/CommonDialogUtil";
import {deleteModel} from "../actions/ModelAsyncActionCreators";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
  modelName: string;
  open: boolean;
}

interface LocalState {
}

const styles = (theme: Theme): StyleRules => createStyles({
  button: {
    margin: theme.spacing(1),
  },
});

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class ConfirmModelDeleteDialog extends React.Component<StyledLocalProps, LocalState> {
  constructor(props: StyledLocalProps) {
    super(props);
  }

  getDialogContent(): JSX.Element {
    return <Typography variant={"body2"}>
      {`You are about to delete model '${this.props.modelName}'. All data will be lost. Do you wish to continue?`}
    </Typography>;
  }

  render(): JSX.Element {
    return <MetusDialog
        data-testselector="ConfirmModelDeleteDialog"
        title="Confirm model deletion"
        open={this.props.open}
        onClose={this.handleClose}
        primaryButtonName={"Confirm delete"}
        onPrimaryButtonPressed={this.handleConfirm}
        aux1ButtonName={"Cancel"}
        onAux1ButtonPressed={this.handleClose}>
      {this.getDialogContent()}
    </MetusDialog>;
  }

  @autobind
  private handleClose(): void {
    hideDialog();
  }

  @autobind
  private handleConfirm(): void {
    deleteModel(this.props.modelName);
    hideDialog();
  }
}

const StyledConfirmModelDeleteDialog = withStyles(styles)(ConfirmModelDeleteDialog);
export default StyledConfirmModelDeleteDialog;

export function showConfirmModelDeleteDialog(modelName?: string): void {
  showDialog(true, <StyledConfirmModelDeleteDialog open={true} modelName={modelName}/>);
}

