/* RESTCallActionCreators.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, August 2017
 */
import {Configuration} from "../../common/utils/Configuration";
import {get, queueOriginalRequestAndDispatchAuthenticationNeededAction} from "../../core/utils/RESTCallUtil";
import Log from "../../common/utils/Logger";
import localViewStore from "../stores/LocalViewStore";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {LoadActionType, ServerRequestInfo} from "../../common/actions/BaseAction";
import {UUID} from "../../api/api";
import * as _ from "lodash";
import {configurationStore} from "../../core/stores/ConfigurationStore";

export const baseurl = `${Configuration.API_SERVER_URL}/metus-services`;
const log = Log.logger("RESTCallActionCreators");

export function isLocal(loadType: LoadActionType = null): boolean {
  const isReadOnly = !configurationStore.canWriteToServer();
  return isReadOnly && (loadType === null || loadType === "webview" || loadType === "loadMatrix");
}

export async function loadAndDispatchAction(
    loadType: LoadActionType,
    resourceId: UUID,
    url: string,
    undoable: boolean = true,
    groupId: string = undefined,
    headers: { [index: string]: string } = {},
    knownErrorCodes: number[] = []): Promise<any> {
    const json: Object = await load(loadType, resourceId, url, undoable, groupId, headers, knownErrorCodes);
    log.debug(`loadResource: dispatching json of type ${loadType}`);
    Dispatcher.dispatch({type: loadType, resourceId: resourceId, undoable: undoable, groupId: groupId, payload: json});
    return json;
}

export function load<T>(
    loadType: LoadActionType,
    resourceId: UUID,
    url: string,
    undoable: boolean = true,
    groupId: string = undefined,
    headers: { [index: string]: string } = {},
    knownErrorCodes: number[] = []): Promise<T> {
    return isLocal(loadType) && localViewStore.hasWebView(resourceId)
        ? localViewStore.getWebView(resourceId)
        : get(url, authenticationExecutorRead(url, loadType, resourceId, undoable, groupId), knownErrorCodes, headers);
}

export function authenticationExecutorRead(url: string, loadType: LoadActionType, resourceId: UUID, undoable: boolean, groupId: string): PromiseExecutorType {
  const serverRequestInfo = new ServerRequestInfo(url, "GET", undoable, groupId, loadType, resourceId);
  return _.partialRight(queueOriginalRequestAndDispatchAuthenticationNeededAction, serverRequestInfo);
}

export type PromiseExecutorType = (originalResolve: (T) => void, originalReject: (reason?: any) => void) => void;


