/* ModularityIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2018
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";


class ModularityIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <g id="co-/-Modularity" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
          d="M19,44 C17.346,44 16,45.346 16,47 L16,61 C16,62.654 17.346,64 19,64 L33,64 C34.654,64 36,62.654 36,61 L36,47 C36,45.346 34.654,44 33,44 L19,44 Z M33,66 L19,66 C16.243,66 14,63.757 14,61 L14,47 C14,44.243 16.243,42 19,42 L33,42 C35.757,42 38,44.243 38,47 L38,61 C38,63.757 35.757,66 33,66 Z M47,44 C45.346,44 44,45.346 44,47 L44,61 C44,62.654 45.346,64 47,64 L61,64 C62.654,64 64,62.654 64,61 L64,47 C64,45.346 62.654,44 61,44 L47,44 Z M61,66 L47,66 C44.243,66 42,63.757 42,61 L42,47 C42,44.243 44.243,42 47,42 L61,42 C63.757,42 66,44.243 66,47 L66,61 C66,63.757 63.757,66 61,66 Z M19,16 C17.346,16 16,17.346 16,19 L16,33 C16,34.654 17.346,36 19,36 L33,36 C34.654,36 36,34.654 36,33 L36,19 C36,17.346 34.654,16 33,16 L19,16 Z M33,38 L19,38 C16.243,38 14,35.757 14,33 L14,19 C14,16.243 16.243,14 19,14 L33,14 C35.757,14 38,16.243 38,19 L38,33 C38,35.757 35.757,38 33,38 Z"
          id="Fill-3" fill="#FFFFFF"></path>
      <path
          d="M66,10 C68.209,10 70,11.791 70,14 L70,32 C70,34.209 68.209,36 66,36 L48,36 C45.791,36 44,34.209 44,32 L44,14 C44,11.791 45.791,10 48,10 L66,10 Z M64,24 L64,22 L58,22 L58,16 L56,16 L56,22 L50,22 L50,24 L56,24 L56,30 L58,30 L58,24 L64,24 Z"
          id="Combined-Shape" fillOpacity="0.5" fill="#FFFFFF"></path>
    </g>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(ModularityIcon);
