import {Backend, BackendFactory, Identifier} from "dnd-core";
import createBackend from "react-dnd-touch-backend";

// import {TouchBackendOptions} from "react-dnd-touch-backend";
export interface ITestBackend extends Backend {
  didCallSetup: boolean;
  didCallTeardown: boolean;
  simulateBeginDrag(sourceIds: Identifier[], options?: any): void;
  simulatePublishDragSource(): void;
  simulateHover(targetIds: Identifier[], options?: any): void;
  simulateDrop(): void;
  simulateEndDrag(): void;
}

const MetusDndBackend: BackendFactory = (manager: any) => createBackend(manager, undefined, {enableMouseEvents: true})


export default MetusDndBackend;
