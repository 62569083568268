// lib imports
import * as React from "react";

import Log from "../../../common/utils/Logger";
import TreeView from "../TreeView";
import {transformTablesAndFoldersIntoTreeItems} from "../../../core/models/TreeViewModelTransformation";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {metusStore} from "../../stores/MetusStore";
import {observer} from "mobx-react";
import {ContextMenu, MenuItem} from "react-contextmenu";
import {ViewType} from "../../../common/constants/Enums";
import autobind from "autobind-decorator";
import {TableId} from "../../../core/utils/Core";
import NewAttributeDialog, {showCreateNewAttributeDialog} from "../../../commonviews/components/NewAttributeDialog";
import {modelStore} from "../../../core/stores/ModelStore";
import {deleteAttribute} from "../../../core/services/CoreDataServices";
import {ViewInfo} from "../../../commonviews/models/ViewInfo";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

interface LocalProps {
}


@observer
class TablesNavigationContainer extends React.Component<LocalProps, {}> {

  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering Tables Sidebar entry");

    const viewInfos = metusStore.allViewInfos;
    const tableViewInfos: ViewInfo[] = viewInfos.filter(viewInfo => viewInfo.type === ViewType.Table);
    const treeListItems = transformTablesAndFoldersIntoTreeItems(modelStore.tableHierarchy, modelStore.attributeDefinitions, tableViewInfos);

    return <React.Fragment>

      <NewAttributeDialog open={false} tableId={null}/>

      <TreeView listItems={treeListItems} type={"tables"}/>

      {/* Context menu on attribute item */}
      <ContextMenu id={"otherAttribute"} className="context-menu">
        <MenuItem onClick={this.editAttribute}
                  attributes={{className: "contextmenu-option--editattribute"} as any}>Edit...</MenuItem>
        <MenuItem onClick={this.deleteAttributeContext}>Delete</MenuItem>
      </ContextMenu>

    </React.Fragment>;

  }

  @autobind
  private deleteAttributeContext(event: any, data: void, target: any): void {
    const tableId: TableId = data["elementId"];
    const attributeName: string = data["attributeName"];
    deleteAttribute(tableId, attributeName);
  }

  @autobind
  private editAttribute(event: any, data: void, target: any): void {
    const tableId = data["elementId"];
    const attributeName = data["attributeName"];
    showCreateNewAttributeDialog(true, tableId, attributeName);
  }


}

export default TablesNavigationContainer;
