import * as React from "react";
// With material-ui
// be sure to have <MuiTreeList /> inside <MuiThemeProvider />
import {FontDownload as NoneAttributeIcon, Image as ImageAttributeIcon,} from "@material-ui/icons";
import {MuiTreeList} from "./MuiTreeList";
import Log from "../../common/utils/Logger";
import {TreeItemType, ViewType} from "../../common/constants/Enums";
import {TreeListItem} from "../../core/models/TreeViewModelTransformation";
import {openCockpit, openViewAndLoadIfNecessary} from "../actions/ViewManagerAsyncActionCreators";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {AttributeType, ViewId} from "../../core/utils/Core";
import {SvgIconProps} from "@material-ui/core/SvgIcon";
import {StyleRules} from "@material-ui/core/styles";
import {default as TableIcon} from "../../common/icons/navigation/TableIcon";
import LinkAttributeIcon from "../../common/icons/navigation/LinkAttributeIcon";
import MemoAttributeIcon from "../../common/icons/navigation/MemoAttributeIcon";
import AttachmentAttributeIcon from "../../common/icons/navigation/AttachmentAttributeIcon";
import ReferenceAttributeIcon from "../../common/icons/navigation/ReferenceAttributeIcon";
import FormulaAttributeIcon from "../../common/icons/navigation/FormulaAttributeIcon";
import StringAttributeIcon from "../../common/icons/navigation/StringAttributeIcon";
import ChartIcon from "../../common/icons/navigation/ChartIcon";
import {default as TablesFolderIcon} from "../../common/icons/navigation/TablesFolderIcon";
import ViewsFolderIcon from "../../common/icons/navigation/ViewsFolderIcon";
import CockpitsFolderIcon from "../../common/icons/navigation/CockpitsFolderIcon";
import StructuredTableIcon from "../../common/icons/navigation/StructuredTableIcon";
import ValueChartIcon from "../../common/icons/navigation/ValueChartIcon";
import MatrixIcon from "../../common/icons/navigation/MatrixIcon";
import ChainMatrixIcon from "../../common/icons/navigation/ChainMatrixIcon";
import ModularityIcon from "../../common/icons/menu/cockpittypes/ModularityIcon";
import {CloseCockpitAction} from "../actions/ViewManagerActions";
import {CockpitActionPayload, metusStore} from "../stores/MetusStore";
import {showSaveCockpitDialog} from "./SaveCockpitDialog";
import {Validate} from "../../common/utils/Validate";
import {debounceByParam} from "../../common/utils/FunctionUtil";
import {Dispatcher} from "../../common/utils/Dispatcher";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

type TreeViewType = "views" | "tables" | "cockpits";

function openHandler(id: any, listItem: TreeListItem): void {
  // TODO version disable opening
  log.debug("Selected item", id, listItem);
  if (listItem.viewType === ViewType.Cockpit) {
    if (metusStore.currentCockpit && metusStore.currentCockpit.isDirty) {
      const payload: CockpitActionPayload = {
        id: metusStore.currentCockpit.id,
        name: metusStore.currentCockpit.name,
        cockpitToOpen: {
          id: listItem.id as ViewId,
          iconColor: listItem.svgIconColor,
          iconType: listItem.svgIconType
        },
      };
      showSaveCockpitDialog(true, new CloseCockpitAction(payload));
    } else {
      Dispatcher.dispatch(new CloseCockpitAction({}));
      openCockpit(listItem.id as ViewId, listItem.svgIconColor, listItem.svgIconType);
    }
  } else if (listItem.type === TreeItemType.View || listItem.type === TreeItemType.Table) {
    openViewAndLoadIfNecessary(listItem.id as ViewId, listItem.viewType);
  }
}

const memoMap = new Map();
// debounce open handler to avoid opening view twice using accidental double click
// split parameter into two, debounce separately by id
const onOpenHandler = (listItem: TreeListItem) => debounceByParam(memoMap, openHandler, 500, {
  leading: true,
  trailing: false
})(listItem.id, listItem);

interface LocalProps {
  listItems: TreeListItem[];
  expandAll?: boolean;
  type: TreeViewType;
}

const styles = (theme: Theme): StyleRules => createStyles({
  svgIcon: {
    fill: "currentColor",
  }
});

interface TreeViewState {
  listItemsWithIcons: TreeListItem[];
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class TreeView extends React.Component<StyledLocalProps, TreeViewState> {
  constructor(props: StyledLocalProps) {
    super(props);
    setIconComponent(props.type, props.classes.svgIcon, props.listItems);
    this.state = {listItemsWithIcons: props.listItems};
  }

  render(): JSX.Element {
    renderLog.debug("Rendering TreeView");
    // clear memoMap on every tree render to avoid growing infinitely
    memoMap.clear();
    return <MuiTreeList
        listItems={this.props.listItems}
        contentKey="name"
        handleClick={onOpenHandler}
        expandedStateLocalStorageKey={this.props.type}>
    </MuiTreeList>;
  }

  static getDerivedStateFromProps(nextProps: StyledLocalProps, state: TreeViewState): TreeViewState {
    let result = null;
    if (nextProps.listItems !== state.listItemsWithIcons) {
      setIconComponent(nextProps.type, nextProps.classes.svgIcon, nextProps.listItems);
      result = {listItemsWithIcons: nextProps.listItems};
    }
    return result;
  }

}


function setIconComponent(treeViewType: TreeViewType, svgIconClass: any, listItems: TreeListItem[]): void {
  listItems.forEach(listItem => {
    listItem.icon = createSvgIconComponentByListItemType(treeViewType, svgIconClass, listItem);
  });
}

function createSvgIconComponentByListItemType(treeViewType: TreeViewType, svgIconClass: any, listItem: TreeListItem): JSX.Element {
  let icon: React.ComponentType<SvgIconProps>;

  switch (listItem.type) {
    case TreeItemType.Folder:
      icon = getFolderIcon(treeViewType);
      break;
    case TreeItemType.Table:
      icon = TableIcon;
      break;
    case TreeItemType.Attribute:
      icon = getAttributeTypeIcon(listItem.attributeType);
      break;
    case TreeItemType.View:
      icon = getViewTypeIcon(listItem.viewType);
      break;
    default:
      icon = ModularityIcon;
  }

  return React.createElement(icon, {classes: {root: svgIconClass}});
}

function getFolderIcon(treeViewType: TreeViewType): React.ComponentType<SvgIconProps> {
  let retVal: React.ComponentType<SvgIconProps>;

  switch (treeViewType) {
    case "tables":
      retVal = TablesFolderIcon;
      break;
    case "views":
      retVal = ViewsFolderIcon;
      break;
    case "cockpits":
      retVal = CockpitsFolderIcon;
      break;
  }

  return retVal;
}

function getAttributeTypeIcon(attributeType: AttributeType): React.ComponentType<SvgIconProps> {
  let retVal: React.ComponentType<SvgIconProps>;

  switch (attributeType) {
    case "String":
      retVal = StringAttributeIcon;
      break;
    case "Link":
      retVal = LinkAttributeIcon;
      break;
    case "Memo":
      retVal = MemoAttributeIcon;
      break;
    case "Image":
      retVal = ImageAttributeIcon;
      break;
    case "Attachment":
      retVal = AttachmentAttributeIcon;
      break;
    case "Derived":
      retVal = ReferenceAttributeIcon;
      break;
    case "Formula":
      retVal = FormulaAttributeIcon;
      break;
    default:
      retVal = NoneAttributeIcon;
      break;
  }

  return retVal;
}

function getViewTypeIcon(viewType: ViewType): React.ComponentType<SvgIconProps> {
  let retVal: React.ComponentType<SvgIconProps>;

  switch (viewType) {
    case ViewType.Table:
    case ViewType.StructuredTable:
      retVal = StructuredTableIcon;
      break;
    case ViewType.ValueChart:
      retVal = ValueChartIcon;
      break;
    case ViewType.Matrix:
      retVal = MatrixIcon;
      break;
    case ViewType.ChainMatrix:
      retVal = ChainMatrixIcon;
      break;
    case ViewType.Chart:
      retVal = ChartIcon;
      break;
    case ViewType.Cockpit:
      break;
    default:
      Validate.fail(`No icon available for this view type ${ViewType[viewType]}`)
  }

  return retVal;
}


export default withStyles(styles)(TreeView);
