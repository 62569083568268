/* ModelInfo.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2020
 */
import {observable} from "mobx";
import {ModelLocationType} from "../../common/constants/Enums";

export class ModelInfo {
  @observable public location: ModelLocationType;
  @observable public name: string;
  @observable public version: string;

  get locationString(): string {
    return ModelLocationType[this.location];
  }

  /** models will have an id, please use this for unique reference */
  get id(): string {
    return this.name;
  }

  constructor(location: ModelLocationType, name: string, version: string = "0") {
    this.location = location;
    this.name = name;
    this.version = version;
  }
}

