import {CSSProperties} from "react";

export class StyleUtil {
  /** styles for texts are all styles which start with font
   color is also handled and converted to fill explicitly in the code for nodes because selection state needs to be considered
   */
  public static toNodeTextStyle = (styleName: string): string => {
    return styleName.startsWith("font") || styleName.includes("Align") ? styleName : null;
  }

  public static toNodeRectStyle = (styleName: string): string => {
    // color is used for text only
    // for nodes, fill is handled explicitly in the code, because selection state needs to be considered
    if (styleName === "fill" || styleName === "color") {
      return null;
    }
    return styleName.startsWith("font") || styleName.includes("Align") ? null : styleName;
  }

  // text styles for headers, attribute and attribute header
  public static toTextStyle = (styleName: any): string => {
    if (styleName === "color") {
      return "color";
    } else {
      return styleName.startsWith("font") || styleName.includes("Align") ? styleName : null;
    }
  }

// node styles for headers, attribute and attribute header
  public static toRectStyle = (styleName: any): string => {
    return (styleName === "color" || styleName.startsWith("font")) || styleName.includes("Align") ? null : styleName;
  }

  // update style using a filter to produce only a subset of the styles given in defaultStyles and specificStyles parameters.
  // Merge defaultStyles and specific styles.
  // handle metusBorder and strokeWidth
  public static updateStyles(stylesToConvert: CSSProperties, defaultStyles: any, styleFilter: any): CSSProperties {
    const convertF = (styles: CSSProperties): CSSProperties => {
      const result: CSSProperties = {};
      for (const k in styles) {
        //noinspection JSUnfilteredForInLoop
        if (!(k.startsWith("metus") || k === "strokeWidth")) {
          //noinspection JSUnfilteredForInLoop
          const styleName = styleFilter(k);
          if (styleName) {
            //noinspection JSUnfilteredForInLoop
            result[styleName] = styles[k];
          }
        }
      }
      return result;
    };
    // only set defaults on first time
    const result = {...convertF(defaultStyles), ...convertF(stylesToConvert)};

    // now convert the metus style "metusBorder" and the input CSS Style strokeWidth to the resulting CSS style strokeWidth
    const k = styleFilter("strokeWidth");
    if (k) {
      const strokeWidth = (stylesToConvert && stylesToConvert["strokeWidth"])
          || defaultStyles && defaultStyles["strokeWidth"];
      result["strokeWidth"] = strokeWidth || 0;
    }

    return result;
  }
}