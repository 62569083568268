import * as React from "react";
import {StyledTreeListItemComponent, TreeListItemComponent} from "./TreeListItemComponent";
import {AttributeId, FolderId, TableId, ViewId} from "../../core/utils/Core";
import {TreeItemType, ViewType} from "../../common/constants/Enums";
import {TreeListItem} from "../../core/models/TreeViewModelTransformation";
import Log from "../../common/utils/Logger";
import {renameTreeItem} from "../actions/ViewManagerAsyncActionCreators";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import {CSSTransition} from "react-transition-group";
import {WordBreakOpportunityComponent} from "../../common/components/WordBreakOpportunityComponent";
import EditableTextComponent from "../../common/components/EditableTextComponent";
import {Classifier} from "../../common/utils/ClassifierLogger";
import autobind from "autobind-decorator";
import {FiberNew} from "@material-ui/icons";
import CollapsedIcon from "../../common/icons/navigation/CollapsedIcon";
import ExpandedIcon from "../../common/icons/navigation/ExpandedIcon";
import {optionalHandler} from "../../common/utils/FunctionUtil";
import {modelStore} from "../../core/stores/ModelStore";
import {deleteTable} from "../../core/services/CoreDataServices";
import {metusStore} from "../stores/MetusStore";
import {filterListItemsAndRevealParents} from "./TreeViewFilter";
import {observer} from "mobx-react";
import {showErrorDialog} from "../../common/utils/CommonDialogUtil";
import {deleteFolder, deleteView} from "../../commonviews/services/ViewServices";
import _ from "lodash";
import {showCreateNewAttributeDialog} from "../../commonviews/components/NewAttributeDialog";
import {findTreeItem} from "../../core/models/ListItemHierarchy";
import {showNewElementDialog, showNewTableDialog} from "./NewElementDialog";
import {configurationStore} from "../../core/stores/ConfigurationStore";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

const dirtyIconStyles = {
  marginLeft: -16,
  paddingRight: 6,
  width: "10px",
  height: "10px"
};

function collect(props: any): any {
  const data = props["data-id"].split("/");

  if (data.length === 2)
    return {
      elementId: data[0],
      attributeName: data[1]
    };
  else
    return {
      elementId: data[0]
    };
}

interface LocalProps {
  listItems: TreeListItem[];
  contentKey: string;
  handleClick?: (listItem: TreeListItem) => void;
  handleDoubleClick?: (listItem: TreeListItem) => void;
  expandedStateLocalStorageKey?: string;
}

interface TreeViewState {
  expandedListItemIndices: number[];
  activeListItemIndices: number[];
  itemInEditingMode: string;
}

@observer
export class MuiTreeList extends React.Component<LocalProps, TreeViewState> {
  constructor(props: LocalProps) {
    super(props);
    this.state = this.getStateFromLocalStorage();
  }

  @autobind
  private handleTouchTap(listItem: TreeListItem, index: number): void {
    log.debug("Handle touch tap", listItem);
    if (listItem.childIndices) {
      this.setState({
        expandedListItemIndices: this.state.expandedListItemIndices,
        activeListItemIndices: []
      });

      const indexOfListItemInArray = this.state.expandedListItemIndices.indexOf(index);
      if (indexOfListItemInArray === -1) {
        this.setState({
          expandedListItemIndices: this.state.expandedListItemIndices.concat([index]),
          activeListItemIndices: this.state.activeListItemIndices
        });
      } else {
        const newArray = [...this.state.expandedListItemIndices];
        newArray.splice(indexOfListItemInArray, 1);
        this.setState({
          expandedListItemIndices: newArray,
          activeListItemIndices: this.state.activeListItemIndices
        });
      }
    } else {
      let activeListItemIndices: number[];

      if (this.commandKeyIsPressed) {
        if (this.state.activeListItemIndices.indexOf(index) >= 0) {
          activeListItemIndices = this.state.activeListItemIndices;
          activeListItemIndices.splice(activeListItemIndices.indexOf(index), 1);
        } else {
          activeListItemIndices = this.state.activeListItemIndices;
          activeListItemIndices.push(index);
        }
      } else {
        if (this.state.activeListItemIndices.indexOf(index) >= 0) {
          activeListItemIndices = [];
        } else {
          activeListItemIndices = [index];
        }
      }

      this.setState({
        expandedListItemIndices: this.state.expandedListItemIndices,
        activeListItemIndices: activeListItemIndices
      });
    }

  }

  private expandAllWhenFiltering(visibleIds: Set<string | AttributeId>) {
    const expandedListItemIndices = [];
    const listItems = this.props.listItems;
    // code would be much better to read if expandedListItemIndices were Set instead of Array
    listItems.forEach((listItem, listItemIndex) => {
      if (listItem.childIndices && visibleIds.has(listItem.id)) {
        if (!expandedListItemIndices.includes(listItemIndex)) {
          expandedListItemIndices.push(listItemIndex);
        }
      }
    })

    // checking for update is necessary because otherwise React throws exception due to to many calls
    // to setState while rendering
    const isUpdated = !_.isEqual(expandedListItemIndices, this.state.expandedListItemIndices);
    if (isUpdated) {
      this.setState({
        expandedListItemIndices,
      });
    }
  }

  componentDidMount(): void {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
    window.addEventListener("blur", this.saveStateToLocalStorage);
  }

  private getLocalStorageKey(): string {
    const rootId = this.props.expandedStateLocalStorageKey;
    return rootId ? "ExpandedListItems-" + rootId : null;
  }

  @autobind
  private saveStateToLocalStorage(): void {
    const localStorageKey = this.getLocalStorageKey();
    if (localStorageKey) {
      const expandedIds = this.convertListItemIndicesToIds(this.state.expandedListItemIndices);
      log.debug("Writing expanded state of list items to local storage", expandedIds);
      window.localStorage.setItem(localStorageKey, JSON.stringify(expandedIds));
    }
  }

  private convertListItemIndicesToIds(indices: number[]): any[] {
    // if there were Attribute List Items expanded, the result would also include Attribute Ids, which would not be
    // deserializable later. Since Attributes are leaves in the tree, they are not exandable and that case does not matter.
    return indices.map(i => this.props.listItems[i] ? this.props.listItems[i].id : null).filter(id => id !== null);
  }

  private convertIdsToListItemIndices(ids: string[]): number[] {
    return ids.map(id => this.props.listItems.findIndex(item => item.id === id)).filter(index => index !== -1);
  }

  private getStateFromLocalStorage(): TreeViewState {
    let expandedListItemIndices = [];
    const localStorageKey = this.getLocalStorageKey();
    if (localStorageKey) {
      const savedState = window.localStorage && window.localStorage.getItem(this.getLocalStorageKey());
      if (savedState) {
        try {
          const expandedIds = JSON.parse(savedState);
          expandedListItemIndices = this.convertIdsToListItemIndices(expandedIds);
          log.debug("Got expanded state of list items from local storage", localStorageKey, expandedListItemIndices);
        } catch {
          // local storage content not deserializable: somebody might have modified it, there might be a versioning issue, ...
        }
      }
    }
    return {
      expandedListItemIndices,
      activeListItemIndices: [],
      itemInEditingMode: null
    };
  }

  componentWillUnmount(): void {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
    window.removeEventListener("blur", this.saveStateToLocalStorage);
    this.saveStateToLocalStorage();
  }

  private commandKeyIsPressed: boolean = false;

  @autobind
  handleKeyUp(e: any): void {
    if (e.keyCode === 17) {
      this.commandKeyIsPressed = false;
    } else {
    }
  }

  @autobind
  handleKeyDown(e: any): void {
    if (e.keyCode === 17) {
      this.commandKeyIsPressed = true;
    } else {
    }
  }


  @autobind
  private deleteView(event: any, data: void, target: any): void {
    const viewId: ViewId = data["elementId"];
    log.debug("Delete View", viewId);
    deleteView(viewId);
  }

  @autobind
  private deleteTableContext(event: any, data: void, target: any): void {
    const tableId: TableId = data["elementId"];
    deleteTable(tableId);
  }

  @autobind
  private renameElement(event: any, data: void, target: any): void {
    const elementId: any = data["elementId"];
    log.debug("Rename Element", elementId);
    this.setState(oldState => ({itemInEditingMode: elementId}));
  }

  render(): JSX.Element {
    renderLog.debug("Rendering MuiTreeList");
    let {listItems, contentKey} = this.props;
    const startingDepth = 1;
    const expandedListItems = this.state.expandedListItemIndices;
    const activeListItems = this.state.activeListItemIndices;
    const listHeight = "48px";
    let visibleIds: Set<string | AttributeId> = null;
    if (metusStore.viewHierarchyFilter !== null && metusStore.viewHierarchyFilter !== undefined && metusStore.viewHierarchyFilter.length > 0) {
      const visibleListItems = filterListItemsAndRevealParents(listItems, metusStore.viewHierarchyFilter);
      visibleIds = new Set<string | AttributeId>(visibleListItems.map(li => li.id));
      this.expandAllWhenFiltering(visibleIds);
    }

    const listItemsModified: TreeListItem[] = listItems.map((listItem: TreeListItem, i) => {
      const isVisible = (visibleIds === null || visibleIds.has(listItem.id));
      listItem.shouldRender = isVisible && (listItem.level >= startingDepth && parentsAreExpanded(listItem));
      return listItem;
    });
    log.debug("Rendering items", listItemsModified);

    const listItemsJSX = listItemsModified.map((listItem: TreeListItem, i) => {
      if (listItem.shouldRender) {
        let primaryElement = <WordBreakOpportunityComponent text={listItem.name}/>;
        const idString: string = listItem.id instanceof AttributeId ? listItem.id.toKey() : listItem.id;

        if (listItem.disabled) {
          return <StyledTreeListItemComponent
              id={idString}
              name={listItem.name}
              data-testselector={TreeItemType[listItem.type] + "/" + listItem.name + "/" + listItem.level.toString() + "/" + ViewType[listItem.viewType]}
              type={listItem.type}
              viewType={listItem.viewType}
              dragIds={[]}
              key={idString}
              primaryElement={primaryElement}
              icon={listItem.icon}
              isHighLighted={listItem.isHighLighted}
              isActive={listItem.isActive}
              svgIconColor={listItem.svgIconColor}
              svgIconType={listItem.svgIconType}
              disabled={true}
              leftAvatar={null}
              expandCollapseIcon={(!listItem.childIndices) ? null : (expandedListItems.indexOf(i) === -1) ?
                  <CollapsedIcon data-testselector="ListItemToggle"/> :
                  <ExpandedIcon data-testselector="ListItemToggle"/>}
              onTouchTap={(): void => {
              }}
              onClick={(): void => {
              }}
              onDoubleClick={(): void => {
              }}
              onExpandCollapseIconClick={(): void => {
              }}
              level={listItem.level}
              markBorderBottom={false} // todo set to appropriate value, needed for (unused) dnd
          />
        }

        let dragIds: (string | AttributeId)[] = [];

        for (const index of this.state.activeListItemIndices) {
          if (index >= 0 && index < listItems.length) {
            const item: TreeListItem = listItems[index];
            dragIds.push(item.id);
          }
        }

        if (dragIds.indexOf(listItem.id) < 0) {
          dragIds = [listItem.id];
        }

        const itemIsInEditingMode = listItem.id === this.state.itemInEditingMode;
        log.debug("itemIsInEditingMode", itemIsInEditingMode);
        if (itemIsInEditingMode) {
          primaryElement = <EditableTextComponent
              textElement={<div onClick={(e): void => e.stopPropagation()}>{primaryElement}</div>}
              onUpdate={(value: string): void => {
                // only trigger rename if the name changed
                if (this.props.listItems.find(lI => lI.id === listItem.id)?.name !== value) {
                  renameTreeItem(listItem.type, listItem.id as string, value);
                }
                this.setState(oldState => ({itemInEditingMode: null}));
              }}
              onCancel={(): void => this.setState(oldState => ({itemInEditingMode: null}))}
              isStartInEditMode={true}
              minWidth={150}/>;
        }

        const treeListItemComponent = (
            <TreeListItemComponent
                id={idString}
                name={listItem.name}
                data-testselector={TreeItemType[listItem.type] + "/" + listItem.name + "/" + listItem.level.toString() + "/" + ViewType[listItem.viewType]}
                type={listItem.type}
                viewType={listItem.viewType}
                dragIds={dragIds}
                key={idString}
                primaryElement={primaryElement}
                icon={listItem.icon}
                isHighLighted={listItem.isHighLighted}
                isActive={listItem.isActive}
                svgIconColor={listItem.svgIconColor}
                svgIconType={listItem.svgIconType}
                disabled={false}
                leftAvatar={listItem.isDirty ? <FiberNew/> : null}
                expandCollapseIcon={(!listItem.childIndices) ? null : (expandedListItems.indexOf(i) === -1) ?
                    <CollapsedIcon data-testselector="ListItemToggle"/> :
                    <ExpandedIcon data-testselector="ListItemToggle"/>}
                onTouchTap={(): void => this.handleTouchTap(listItem, i)}
                onClick={(): void => {
                  optionalHandler(this.props.handleClick)(listItem);
                }}
                onDoubleClick={(): void => {
                  optionalHandler(this.props.handleDoubleClick)(listItem);
                }}
                onExpandCollapseIconClick={(): void => {
                  this.handleTouchTap(listItem, i);
                }}
                level={listItem.level}/>
        );

        const {id, dataId, renderTrigger} = getContextMenuIds(listItem, listItemsModified);
        if (renderTrigger) {
          return (
              <ContextMenuTrigger id={id}
                                  key={idString}
                                  data-id={dataId}
                                  collect={collect}
                                  holdToDisplay={-1}
                                  attributes={{"data-testselector": "contextmenutrigger"} as any}>
                {treeListItemComponent}
              </ContextMenuTrigger>
          );
        } else {
          return treeListItemComponent;
        }
      } else {
        return null;
      }
    }); // End map method

    // TODO: migrate transition group correctly, see https://github.com/reactjs/react-transition-group/blob/master/Migration.md
    return <div>
      <CSSTransition classNames="tree-list" timeout={{enter: 300, exit: 150}}>
        <React.Fragment>{listItemsJSX}</React.Fragment>
      </CSSTransition>
      <ContextMenu id={"folder"} className="context-menu">
        <MenuItem onClick={this.deleteFolder}
                  attributes={{className: "contextmenu-option--delete"} as any}>Delete</MenuItem>
        <MenuItem onClick={this.renameElement}
                  attributes={{className: "contextmenu-option--rename"} as any}>Rename</MenuItem>
        <MenuItem onClick={this.openNewItemDialog}
                  attributes={{className: "contextmenu-option--new"} as any}>New ...</MenuItem>
      </ContextMenu>
      <ContextMenu id={"view"} className="context-menu">
        <MenuItem onClick={this.deleteView}
                  attributes={{className: "contextmenu-option--delete"} as any}>Delete</MenuItem>
        <MenuItem onClick={this.renameElement}
                  attributes={{className: "contextmenu-option--rename"} as any}>Rename</MenuItem>
      </ContextMenu>
      <ContextMenu id={"table"} data-testselector="" className="context-menu">
        <MenuItem onClick={this.createNewAttribute} attributes={{className: "contextmenu-option--newattribute"} as any}>New
          Attribute</MenuItem>
        <MenuItem onClick={this.deleteTableContext}
                  attributes={{className: "contextmenu-option--delete"} as any}>Delete</MenuItem>
        <MenuItem onClick={this.renameElement}
                  attributes={{className: "contextmenu-option--rename"} as any}>Rename</MenuItem>
      </ContextMenu>
    </div>;

    function parentsAreExpanded(listItem: TreeListItem): boolean {
      if (listItem.level > startingDepth) {
        if (expandedListItems.indexOf(listItem.parentIndex) === -1) {
          return false;
        } else {
          const parent = listItems.filter((_listItem, index) => {
            return index === listItem.parentIndex;
          })[0];
          return parentsAreExpanded(parent);
        }
      } else {
        return true;
      }
    }

  } // End render method

  @autobind
  private deleteFolder(event: any, data: void, target: any): void {
    const folderId: FolderId = data["elementId"];
    const folderIndex = this.props.listItems.findIndex(listItem => {
      return listItem.id === folderId;
    });
    const folder = this.props.listItems[folderIndex];
    if ((folder.childIndices || []).length !== 0) {
      showErrorDialog(true, "Folder not empty", "Folder can't be deleted, since it still contains children. You have to delete all children first.");
    } else {
      log.debug("Delete Folder", folderId);
      deleteFolder(folderId);
    }
  }

  @autobind
  private createNewAttribute(event: any, data: void, target: any): void {
    const tableId: TableId = data["elementId"];
    showCreateNewAttributeDialog(true, tableId);
  }

  @autobind
  private openNewItemDialog(event: any, data: void, target: any): void {
    const folderId = data["elementId"];
    const inTableAccordeon = findTreeItem(modelStore.tableHierarchy, folderId) !== null;
    log.debug("Create child for folder", folderId);
    if (inTableAccordeon) {
      showNewTableDialog(true, folderId);
    } else {
      showNewElementDialog(true, folderId);
    }
  }

}

export function getContextMenuIds(listItem: TreeListItem, allListItems: TreeListItem[]): { id: string, dataId: string, renderTrigger: boolean } {
  let id;
  let dataId = listItem.id + "";
  let renderTrigger = true;

  if (listItem.type === TreeItemType.Attribute) {
    dataId = allListItems[listItem.parentIndex].id + "/" + listItem.name;
    if (listItem.name === "name") {
      id = "nameAttribute";
    } else {
      id = "otherAttribute";
    }
    renderTrigger = configurationStore.canWriteToServer();
  } else if (listItem.type === TreeItemType.Table) {
    id = "table";
    renderTrigger = configurationStore.canWriteToServer();
  } else if (listItem.type === TreeItemType.Folder) {
    id = "folder";
    const child = (listItem.childIndices && listItem.childIndices[0]) ? allListItems[listItem.childIndices[0]] : undefined;
    renderTrigger = configurationStore.canWriteToServer() || (child === undefined || child.type !== TreeItemType.Table);
  } else if (listItem.type === TreeItemType.View) {
    id = "view";
  } else {
    id = "nonMenu";
    dataId = "noDataNeeded";
  }

  return {id, dataId, renderTrigger};
}

