import * as React from "react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {ViewId} from "../../core/utils/Core";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

const styles = (theme: Theme):StyleRules => createStyles({
  activated: {
    height: "100%",
    border: "4px solid",
    background: "rgba(0,0,0,0.05)",
    borderColor: theme.metus.properties.active.highlight,
  }
});

interface LocalProps {
  viewId: ViewId;
  activeViewId: ViewId;
  handleSetActiveAction: (viewId: ViewId) => void;
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class ViewActivationComponent extends React.Component<StyledLocalProps, any> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  @autobind onHandleActivation(event: React.MouseEvent<HTMLDivElement>): void {
    log.debug("View activated", this.props.viewId);
    this.props.handleSetActiveAction(this.props.viewId);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering ViewActivationComponent");
    const isActive = this.props.activeViewId !== null && this.props.activeViewId === this.props.viewId;
    const className =  isActive ? this.props.classes.activated : this.props.classes.root;
    return <div onMouseDownCapture={this.onHandleActivation} className={className}
                data-active={isActive}>{this.props.children} </div>;
  }
}

export default withStyles(styles)(ViewActivationComponent);