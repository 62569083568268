// lib imports
import * as React from "react";
import Log from "../../../common/utils/Logger";
import {AttributeHeaderComponent} from "../../../commonviews/components/AttributeHeaderComponent";
import {HandleComponent} from "../common/HandleComponent";
import {Direction} from "../../../common/utils/Direction";
import {ResizeAttributeAction, ToggleFilterLineAction} from "../../actions/DiagramActions";
import {UpdateViewerFilterForAttributeAction} from "../../../commonviews/actions/SharedViewActions";
import {ViewerContext} from "../../utils/ViewerContext";
import {VisualHeader} from "../../../commonviews/models/VisualHeader";
import {observer} from "mobx-react";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {VisualAttributeDefinition} from "../../models/common/VisualAttributeDefinition";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {BoundedVisual} from "../../models/VisualObject";
import autobind from "autobind-decorator";
import {RotationDegree} from "../../../api/api";

const log = Log.logger("diagram");
const renderLog = Log.logger("diagram", Classifier.render);

/**
 * attribute column, consisting of header and attribute values arranged in a column
 * @author Marco van Meegen
 *
 */
interface LocalProps {
  viewerContext: ViewerContext;
  attribute: VisualAttributeDefinition;
  filterText: string;
  filterTextIsValid: boolean;
  drawSeparator: boolean;
  isFilterVisible: boolean;
  attributeTitleHeight: number;
  attributeHeaderRotation: RotationDegree;
  supportsAtFilter?: boolean;
}

@observer
export class AttributeDefinitionComponent extends React.Component<LocalProps, any> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering AttributeDefinitionComponent");
    const attributeHeader: VisualHeader = this.props.attribute.header;
    // customize EAST handle to be active on whole title area right border

    const rect = new BoundedVisual(attributeHeader.x, attributeHeader.y, attributeHeader.width, this.props.attributeTitleHeight);
    const customizedHandleInfo = {
      EAST: {
        y: rect.y,
        height: rect.height
      }
    };
    return <g>
      <AttributeHeaderComponent attributeHeader={attributeHeader}
                                attributeId={this.props.attribute.id}
                                onFilterChange={this.onFilterChange}
                                filterText={this.props.filterText}
                                filterTextIsValid={this.props.filterTextIsValid}
                                attributeHeaderRotation={this.props.attributeHeaderRotation}
                                attributeTitleHeight={this.props.attributeTitleHeight}
                                windowIndex={this.props.viewerContext.windowIndex}
                                drawSeparator={this.props.drawSeparator}
                                isFilterVisible={this.props.isFilterVisible}
                                onFilterToggle={this.onFilterToggle}
                                supportsAtFilter={this.props.supportsAtFilter}
      />
      <HandleComponent scale={this.props.viewerContext.scale}
                       EAST={this.onDragHandle}
                       customizedHandleInfo={customizedHandleInfo}
                       visualModel={rect}
                       visualId={this.props.attribute.id}
      />

    </g>;
  }

  @autobind
  private onFilterToggle(): void {
    Dispatcher.dispatch(new ToggleFilterLineAction(this.props.viewerContext.viewId, this.props.attribute.id.visualTableId));
  }

  @autobind
  private onDragHandle(direction: Direction, dx: number, dy: number): void {
    Dispatcher.dispatch(new ResizeAttributeAction(this.props.viewerContext.viewId, this.props.attribute.id, direction, dx, dy));
  }

  /** send filter change action */
  @autobind
  private onFilterChange(value: string): void {
    Dispatcher.dispatch(new UpdateViewerFilterForAttributeAction(this.props.viewerContext.viewId, this.props.attribute.id, value));
  }
}