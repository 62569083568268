/* EmptyEditorIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Januar 2019
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";
import theme from "../../theme/Theme";

class EmptyEditorIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    const highlightColor = theme.metus.selection.fill
    return <React.Fragment>
      <style type="text/css">{`
        .st0{display:none;}
        .st1{display:inline;fill:#EDEDED;}
        .st2{fill:#D7D7D7;}
        .st3{fill:#A8A8A8;}
        .st4{fill:#EDEDED;}
        .st5{fill:#F6F6F6;}
        .st6{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:11.9781,11.9781;}
        .st7{opacity:0.25;fill:#A8A8A8;}
        .st8{fill:none;stroke:#A8A8A8;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
        .st9{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:12;}
        .st10{display:inline;}
        .st11{fill:#CEE6FE;}
        .st12{fill:${highlightColor};}
        .st13{display:inline;fill:${highlightColor};}
        .st14{display:inline;fill:none;stroke:#24496E;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
        .st15{}
        .st16{font-size:45px;}
        .st17{fill:#D4D4D4;}
        .st18{fill:none;stroke:#A8A8A8;stroke-width:3;stroke-miterlimit:10;}
        .st19{fill:#24496E;}
        .st20{fill:#FFFFFF;}
        .st21{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-miterlimit:10;}
        .st22{fill:#CEE6FE;stroke:${highlightColor};stroke-linecap:round;stroke-miterlimit:10;}
        .st23{fill:#D6C365;}
        .st24{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:12.0034,12.0034;}
        .st25{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:12.1971,12.1971;}
        .st26{fill:none;stroke:#D6C365;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:12.0853,12.0853;}
        .st27{display:inline;fill:#24496E;}
        .st28{opacity:0.8;}
        .st29{opacity:0.3;}
        .st30{fill:#AEBCC9;}
        .st31{opacity:0.81;fill:#D6C365;}
        .st32{opacity:0.2;fill:#9CCDFD;}
      `}</style>
      <g id="BG" className="st0">
      </g>
      <g id="Ebene_1">
        <g>
          <path className="st3" d="M571.02,335.4c5.81-1.04,11.6-2.18,17.26-3.75c2.84-0.75,5.62-1.7,8.38-2.7c2.73-1.08,5.45-2.18,8.04-3.54
			c5.24-2.61,10.09-5.96,14.44-9.85c4.33-3.91,8.08-8.45,11.23-13.39c6.3-9.87,10.32-21.15,12.18-32.72
			c1.87-11.58,1.49-23.5-1.17-34.91c-2.66-11.4-7.44-22.36-14.35-31.81c-3.46-4.71-7.46-9.03-12.01-12.68
			c-4.54-3.64-9.63-6.62-15.02-8.86c-10.81-4.5-22.67-6.04-34.42-6.15c-5.89-0.07-11.79,0.16-17.69,0.51
			c-5.9,0.35-11.78,0.86-17.61,1.67c-11.64,1.66-23.18,4.62-33.54,10.11c-5.18,2.71-9.99,6.11-14.26,10.09
			c-1.12,0.94-2.1,2.02-3.11,3.08l-1.51,1.58c-0.5,0.53-0.94,1.11-1.41,1.67c-1.84,2.25-3.5,4.65-4.98,7.17
			c-3.01,5.01-5.36,10.41-7.27,15.95c-1.92,5.54-3.35,11.26-4.35,17.05c-2.01,11.57-2.36,23.47-0.6,35.06
			c1.75,11.54,5.83,22.92,13.34,31.87c3.73,4.48,8.16,8.35,12.99,11.63c4.87,3.23,10.15,5.85,15.63,7.92
			c5.5,2.04,11.2,3.57,16.98,4.66c5.79,1.07,11.65,1.75,17.54,2.09c5.89,0.31,11.8,0.34,17.69,0.04c2.95-0.13,5.89-0.37,8.83-0.64
			C565.18,336.28,568.12,335.95,571.02,335.4z M571.23,336.76c-2.96,0.3-5.9,0.72-8.85,1.06c-2.96,0.31-5.92,0.59-8.89,0.76
			c-5.94,0.38-11.89,0.43-17.85,0.18c-5.95-0.28-11.9-0.86-17.77-1.94c-5.87-1.09-11.67-2.63-17.29-4.69
			c-5.61-2.1-11.02-4.76-16.04-8.07c-4.98-3.36-9.56-7.34-13.42-11.96c-3.85-4.62-6.99-9.88-9.16-15.47
			c-2.21-5.59-3.74-11.42-4.61-17.34c-1.78-11.84-1.4-23.92,0.64-35.67c1.02-5.88,2.48-11.69,4.44-17.33
			c1.95-5.64,4.36-11.15,7.45-16.3c1.52-2.58,3.24-5.06,5.15-7.39c0.49-0.57,0.95-1.17,1.46-1.72l1.55-1.63
			c1.04-1.08,2.05-2.2,3.2-3.16c4.39-4.09,9.34-7.58,14.65-10.35c10.62-5.61,22.38-8.6,34.15-10.27c5.9-0.81,11.83-1.32,17.76-1.66
			c5.93-0.35,11.87-0.57,17.82-0.5c11.87,0.15,23.96,1.63,35.09,6.23c5.55,2.28,10.79,5.33,15.49,9.08c4.7,3.75,8.83,8.18,12.39,13
			c7.12,9.66,12,20.86,14.69,32.51c2.7,11.66,3.07,23.84,1.15,35.65c-1.92,11.8-6.03,23.3-12.5,33.41
			c-3.23,5.05-7.09,9.72-11.56,13.74c-4.48,3.99-9.47,7.43-14.85,10.09c-2.66,1.39-5.44,2.51-8.22,3.61
			c-2.81,1.01-5.65,1.97-8.53,2.73C583.01,334.98,577.12,335.99,571.23,336.76z"/>
        </g>
      </g>
      <g id="Ebene_8">
        <g>
          <path className="st3" d="M291.05,495.36c5.77-0.26,11.52-0.49,17.26-0.93c5.74-0.42,11.44-1.18,17.08-2.24
			c1.4-0.3,2.82-0.51,4.21-0.87l4.18-1.02c2.74-0.81,5.51-1.58,8.17-2.62c5.35-1.99,10.59-4.37,15.38-7.48
			c9.64-6.1,17.95-14.35,23.51-24.35c5.71-9.91,9.54-20.87,11.99-32.06c2.41-11.2,3.44-22.73,2.78-34.17
			c-0.34-5.71-1.14-11.4-2.49-16.95c-1.34-5.55-3.27-10.96-5.95-15.98c-5.36-10.02-13.61-18.46-23.3-24.47
			c-9.69-6.07-20.61-10.06-31.8-12.51c-11.21-2.45-22.72-3.53-34.21-3.57c-11.5-0.05-23.01,0.88-34.34,2.8
			c-5.65,0.99-11.25,2.29-16.69,4.06c-5.44,1.77-10.7,4.07-15.64,6.91c-4.93,2.85-9.56,6.25-13.63,10.23
			c-4.08,3.97-7.66,8.45-10.77,13.24c-3.11,4.79-5.74,9.89-7.93,15.17c-2.15,5.3-3.84,10.78-5.12,16.36
			c-2.6,11.15-3.14,22.75-1.88,34.13c0.7,5.69,1.85,11.32,3.46,16.82c0.43,1.37,0.79,2.76,1.29,4.1l1.46,4.05l1.66,3.97
			c0.56,1.32,1.22,2.59,1.83,3.89c2.56,5.13,5.57,10.04,9.07,14.57c0.91,1.1,1.74,2.28,2.72,3.33c0.97,1.05,1.89,2.15,2.9,3.17
			c1.03,0.99,2.04,2.01,3.09,2.98c1.11,0.91,2.2,1.84,3.34,2.69l-0.84,1.08c-1.06-1.01-2.19-1.93-3.29-2.88
			c-1.08-0.98-2.12-2-3.19-2.99c-1.04-1.02-2-2.12-3-3.18c-1.01-1.06-1.87-2.23-2.81-3.35c-3.63-4.56-6.76-9.51-9.42-14.7
			c-5.4-10.36-8.59-21.77-10.07-33.32c-1.32-11.58-0.8-23.4,1.81-34.77c1.28-5.69,3-11.28,5.2-16.68
			c2.24-5.39,4.93-10.59,8.11-15.48c3.18-4.89,6.86-9.48,11.06-13.56c4.19-4.09,8.95-7.57,14.01-10.5c5.07-2.91,10.45-5.26,16-7.05
			c5.55-1.8,11.23-3.11,16.96-4.11c11.46-1.93,23.06-2.85,34.67-2.8c11.6,0.06,23.23,1.15,34.59,3.65
			c11.34,2.49,22.45,6.55,32.35,12.77c9.89,6.18,18.4,14.76,23.97,25.1c2.78,5.17,4.77,10.72,6.16,16.38
			c1.4,5.67,2.22,11.47,2.59,17.28c0.73,11.63-0.32,23.33-2.8,34.7c-2.5,11.35-6.4,22.47-12.25,32.59
			c-2.94,5.03-6.33,9.88-10.52,13.99l-3.14,3.06c-1.11,0.96-2.24,1.88-3.36,2.82c-1.09,0.97-2.31,1.79-3.5,2.64
			c-1.2,0.84-2.36,1.73-3.64,2.45c-4.92,3.18-10.27,5.61-15.74,7.63c-2.72,1.05-5.54,1.83-8.33,2.65l-4.24,1.03
			c-1.41,0.36-2.85,0.58-4.27,0.88c-11.44,2.15-23.08,2.9-34.65,2.78L291.05,495.36z"/>
        </g>
        <g>
          <g>
            <path className="st3" d="M353.14,609.9c-6.16-2.69-12.48-4.96-18.93-6.79c-6.45-1.83-13.06-3.08-19.73-3.82
				c-13.32-1.5-26.92-0.72-39.89,2.57c-6.48,1.68-12.79,3.97-18.79,6.93c-5.98,2.99-11.63,6.63-16.8,10.86
				c-1.29,1.06-2.53,2.18-3.78,3.29c-1.18,1.18-2.44,2.28-3.57,3.51c-2.32,2.4-4.5,4.93-6.55,7.58c-4.1,5.29-7.69,10.98-10.95,16.84
				c-1.63,2.94-3.18,5.92-4.67,8.93c-0.74,1.51-1.51,3.02-2.15,4.54l-1.01,2.28l-0.87,2.34c-1.18,3.11-2.05,6.34-2.81,9.58
				c-0.67,3.27-1.25,6.56-1.54,9.89c-0.66,6.65-0.63,13.37,0.01,20.04c0.63,6.67,1.75,13.3,3.35,19.82
				c1.59,6.5,3.82,12.84,6.87,18.77c1.49,2.98,3.28,5.8,5.18,8.54c2,2.66,4.08,5.27,6.46,7.61c2.31,2.41,4.88,4.53,7.5,6.59
				c2.7,1.96,5.46,3.84,8.38,5.47c5.8,3.29,11.99,6.02,18.22,8.51c12.49,4.96,25.53,8.55,38.82,10.46
				c13.27,1.92,26.86,2.22,40.02-0.22c13.17-2.36,25.68-7.92,36.24-16.13c10.55-8.2,19.22-18.77,25.4-30.62
				c12.3-23.76,14.62-52.45,6.39-77.97c-4.06-12.76-10.56-24.78-19.2-35.04c-4.33-5.12-9.17-9.8-14.47-13.92
				c-1.3-1.06-2.68-2.02-4.05-2.98c-1.35-1-2.8-1.84-4.21-2.76c-1.41-0.91-2.9-1.68-4.35-2.53
				C356.19,611.29,354.65,610.61,353.14,609.9z M353.72,608.65l4.51,2.38c1.48,0.84,3,1.6,4.44,2.51c1.44,0.91,2.92,1.75,4.3,2.75
				c1.4,0.96,2.81,1.92,4.14,2.98c5.42,4.12,10.4,8.82,14.84,13.98c8.92,10.31,15.56,22.54,19.72,35.51
				c4.19,12.97,5.78,26.77,4.69,40.36c-1.1,13.58-4.8,26.97-11.11,39.07c-6.31,12.08-15.16,22.85-25.95,31.22
				c-10.78,8.37-23.63,14.07-37.06,16.46c-13.43,2.48-27.21,2.14-40.62,0.19c-13.44-1.95-26.62-5.59-39.22-10.6
				c-6.3-2.52-12.5-5.26-18.44-8.64c-2.98-1.66-5.8-3.59-8.56-5.6c-2.68-2.11-5.32-4.29-7.69-6.77c-2.44-2.4-4.58-5.08-6.63-7.82
				c-1.95-2.81-3.8-5.69-5.33-8.75c-3.14-6.07-5.43-12.54-7.06-19.15c-1.64-6.59-2.79-13.31-3.44-20.08
				c-0.66-6.77-0.72-13.61-0.06-20.4c0.65-6.78,2.03-13.53,4.42-19.95l0.9-2.4l1.04-2.35c0.68-1.58,1.44-3.08,2.19-4.6
				c1.51-3.04,3.07-6.04,4.73-9.01c3.3-5.93,6.95-11.69,11.13-17.07c2.09-2.69,4.32-5.28,6.69-7.73c1.16-1.25,2.44-2.39,3.65-3.59
				c1.28-1.13,2.55-2.27,3.86-3.35c5.29-4.31,11.07-8.02,17.17-11.07c6.12-3.01,12.56-5.34,19.16-7.04
				c13.22-3.34,27.03-4.11,40.54-2.57C328.19,599.03,341.51,602.67,353.72,608.65z"/>
          </g>
        </g>
        <g>
          <g>
            <path className="st3" d="M969.13,667.42c-8.93-9.98-18.57-19.34-29.31-27.32c-10.73-7.97-22.34-14.83-34.77-19.71
				c-12.4-4.87-25.71-7.61-39.02-7.04c-13.3,0.51-26.43,4.07-38.35,9.97c-23.9,11.75-42.6,33.63-50.35,59.14
				c-3.86,12.74-5.09,26.29-3.41,39.5c1.72,13.19,6.23,26.05,13.45,37.23c3.59,5.6,7.87,10.72,12.55,15.48
				c4.66,4.78,9.75,9.13,15.12,13.08c10.74,7.92,22.76,14.09,35.46,18.16c12.7,3.99,26.13,5.87,39.42,4.85
				c6.63-0.5,13.22-1.64,19.6-3.53c6.38-1.89,12.54-4.46,18.43-7.59c5.9-3.11,11.54-6.71,17-10.57c2.72-1.93,5.45-3.91,8.06-5.97
				c1.29-1.03,2.59-2.06,3.8-3.2c0.61-0.56,1.24-1.09,1.83-1.69l1.74-1.77c4.63-4.75,8.51-10.21,11.84-15.97
				c3.34-5.76,6.01-11.9,8.19-18.21c2.17-6.3,3.91-12.8,4.98-19.37c1.04-6.57,1.36-13.28,0.52-19.87
				c-0.87-6.58-2.72-13.05-5.67-19.02c-0.75-1.49-1.47-2.99-2.35-4.41c-0.42-0.72-0.82-1.45-1.27-2.15l-1.37-2.09
				c-0.88-1.42-1.93-2.72-2.9-4.08C971.33,669.93,970.2,668.69,969.13,667.42z M970.17,666.53c2.06,2.69,4.2,5.3,6.1,8.11l1.42,2.11
				c0.46,0.71,0.88,1.45,1.32,2.18c0.9,1.44,1.66,2.96,2.44,4.47c3.09,6.06,5.06,12.68,6.02,19.42c0.91,6.76,0.69,13.64-0.35,20.35
				c-1.08,6.72-2.81,13.27-5,19.68c-2.2,6.41-4.9,12.66-8.29,18.55c-3.38,5.88-7.33,11.48-12.07,16.37l-1.79,1.82
				c-0.6,0.61-1.25,1.16-1.87,1.74c-1.23,1.18-2.57,2.24-3.9,3.3c-2.66,2.11-5.39,4.07-8.14,6.03c-5.52,3.89-11.23,7.53-17.23,10.68
				c-5.98,3.17-12.28,5.79-18.79,7.72c-6.51,1.93-13.24,3.09-20,3.59c-13.54,1.03-27.21-0.9-40.12-4.97
				c-12.9-4.15-25.09-10.41-35.98-18.46c-5.45-4.01-10.6-8.43-15.33-13.28c-4.73-4.82-9.11-10.07-12.77-15.78
				c-7.36-11.42-11.94-24.54-13.69-37.98c-1.68-13.46-0.49-27.25,3.41-40.25c7.85-25.99,26.8-48.43,51.23-60.35
				c12.16-6,25.55-9.62,39.13-10.12c13.6-0.56,27.18,2.26,39.76,7.22c12.61,4.97,24.34,11.93,35.17,19.99
				c5.4,4.06,10.58,8.41,15.48,13.06C961.3,656.34,965.88,661.32,970.17,666.53z"/>
          </g>
        </g>
        <g>
          <path className="st3" d="M978.49,528.01c4.9-3.09,9.7-6.31,14.22-9.92c4.53-3.57,8.73-7.56,12.56-11.87
			c7.61-8.64,13.31-18.91,17.02-29.81c3.7-10.91,5.27-22.51,4.83-34.04c-0.19-5.77-0.82-11.52-1.76-17.22l-0.77-4.27l-0.9-4.24
			c-0.61-2.8-1.45-5.54-2.38-8.27c-1.9-5.44-4.18-10.77-6.86-15.87c-2.66-5.1-5.68-10.06-9.42-14.37c-0.89-1.12-1.94-2.07-2.92-3.09
			c-1.08-0.9-2.1-1.85-3.32-2.66l-3.53-2.51c-1.19-0.82-2.32-1.72-3.58-2.44l-3.7-2.26c-1.24-0.73-2.43-1.56-3.74-2.19l-3.84-2
			c-1.29-0.64-2.53-1.39-3.88-1.92c-20.94-9.79-44.66-13.47-67.58-10.61c-11.46,1.41-22.72,4.43-33.35,8.94
			c-5.33,2.21-10.52,4.8-15.42,7.83c-4.91,3.02-9.58,6.43-13.82,10.32c-2.12,1.94-4.16,3.97-6.05,6.14
			c-1.93,2.13-3.57,4.49-5.3,6.79c-0.82,1.17-1.54,2.42-2.32,3.63l-1.14,1.83l-1.01,1.91c-0.66,1.28-1.35,2.54-1.99,3.83l-1.72,3.96
			c-0.61,1.3-1.07,2.67-1.55,4.03c-0.46,1.36-1.01,2.7-1.4,4.08c-0.77,2.77-1.63,5.53-2.17,8.36c-1.3,5.62-1.94,11.37-2.3,17.12
			l-0.14,4.32l-0.04,1.08l0.03,1.08l0.05,2.16c0,2.89,0.3,5.76,0.53,8.63c0.67,5.72,1.58,11.43,3.14,16.98
			c2.9,11.16,7.98,21.71,14.44,31.25c3.3,4.74,6.98,9.2,10.97,13.38c4.08,4.09,8.4,7.93,13.07,11.34c4.7,3.35,9.64,6.39,14.83,8.93
			c1.3,0.63,2.63,1.19,3.95,1.78c1.31,0.62,2.66,1.12,4.02,1.62c2.68,1.08,5.47,1.89,8.27,2.59l-0.35,1.33
			c-2.77-0.99-5.61-1.68-8.35-2.75c-1.38-0.49-2.76-0.97-4.1-1.58c-1.34-0.59-2.71-1.13-4.03-1.76c-5.31-2.5-10.35-5.52-15.17-8.88
			c-4.77-3.42-9.25-7.24-13.4-11.4c-4.07-4.24-7.83-8.76-11.2-13.58c-6.61-9.69-11.8-20.41-14.8-31.78
			c-1.61-5.65-2.55-11.46-3.22-17.29c-0.24-2.92-0.54-5.84-0.54-8.78l-0.05-2.2l-0.03-1.1l0.04-1.1l0.15-4.4
			c0.37-5.85,1.03-11.7,2.36-17.42c0.54-2.89,1.42-5.69,2.22-8.52c0.4-1.41,0.96-2.77,1.43-4.16c0.49-1.38,0.96-2.78,1.59-4.11
			l1.76-4.04c0.65-1.32,1.36-2.61,2.03-3.91l1.03-1.95l1.17-1.87c0.8-1.24,1.53-2.51,2.38-3.72c1.77-2.35,3.46-4.77,5.44-6.95
			c1.94-2.22,4.03-4.29,6.2-6.27c4.34-3.97,9.11-7.45,14.11-10.51c5-3.08,10.26-5.7,15.67-7.94c10.79-4.57,22.21-7.62,33.84-9.04
			c23.26-2.88,47.28,0.92,68.56,10.74c1.37,0.54,2.63,1.29,3.94,1.94l3.91,2.01c1.33,0.63,2.53,1.47,3.8,2.21l3.76,2.28
			c1.28,0.72,2.43,1.63,3.64,2.46l3.59,2.54c1.22,0.79,2.34,1.82,3.49,2.78c1.04,1.07,2.15,2.07,3.08,3.23
			c3.9,4.48,6.99,9.52,9.71,14.72c2.72,5.2,5.01,10.61,6.95,16.15c0.94,2.78,1.81,5.6,2.43,8.49l0.91,4.29l0.77,4.32
			c0.95,5.78,1.58,11.62,1.76,17.48c0.43,11.72-1.19,23.56-4.98,34.69c-3.79,11.11-9.62,21.58-17.42,30.4
			c-7.81,8.79-17.18,16.13-27.42,21.79L978.49,528.01z"/>
        </g>
        <g>
          <path className="st3" d="M559.51,865.53c3.55,3.23,7.21,6.33,11.13,9.07c3.91,2.76,8.07,5.14,12.42,7.11
			c8.7,3.98,18.22,5.85,27.76,6.4c1.19,0.11,2.39,0.1,3.59,0.11l3.59,0.03l3.59-0.17c1.19-0.08,2.39-0.08,3.58-0.24
			c2.38-0.28,4.77-0.43,7.13-0.87l3.55-0.57c1.18-0.2,2.35-0.49,3.52-0.73c9.37-2.02,18.54-5.04,27.18-9.2
			c8.64-4.14,16.75-9.36,24.07-15.54c7.28-6.21,13.69-13.45,18.95-21.44c1.23-2.05,2.57-4.04,3.65-6.17
			c0.55-1.06,1.17-2.09,1.67-3.17l1.51-3.25c1.87-4.39,3.55-8.89,4.58-13.56c2.27-9.28,2.54-19,0.85-28.39
			c-0.88-4.69-2.18-9.29-3.91-13.74c-1.78-4.43-3.95-8.7-6.43-12.78c-2.51-4.07-5.39-7.9-8.47-11.56
			c-3.11-3.64-6.52-7.01-10.08-10.22c-7.17-6.36-15.14-11.83-23.81-15.87c-8.66-4.05-18-6.54-27.54-7.37
			c-2.38-0.28-4.77-0.34-7.16-0.46c-2.39-0.01-4.78-0.04-7.17,0.12c-4.77,0.27-9.54,0.78-14.24,1.71
			c-9.42,1.77-18.58,4.83-27.37,8.69c-1.11,0.46-2.19,0.99-3.28,1.49c-1.09,0.51-2.19,1-3.26,1.55c-2.15,1.08-4.32,2.12-6.36,3.35
			c-4.14,2.41-8.1,5.15-11.92,8.06c-3.81,2.92-7.48,6.04-10.86,9.44c-0.83,0.87-1.66,1.73-2.46,2.62c-0.76,0.92-1.59,1.79-2.29,2.76
			c-1.5,1.86-2.78,3.88-3.72,6.04l-1.24-0.58c0.68-1.04,1.17-2.19,1.9-3.18c0.33-0.52,0.65-1.05,0.99-1.55l1.09-1.48
			c0.7-1.01,1.53-1.9,2.3-2.86c0.8-0.92,1.63-1.81,2.46-2.71c3.38-3.52,7.04-6.74,10.87-9.75c3.83-2.99,7.78-5.85,12-8.33
			c2.11-1.27,4.3-2.33,6.46-3.43c1.08-0.56,2.19-1.06,3.28-1.58c1.1-0.51,2.19-1.05,3.31-1.52c8.89-3.94,18.17-7.08,27.75-8.92
			c4.78-0.96,9.64-1.5,14.51-1.76c2.44-0.15,4.88-0.12,7.31-0.11c2.44,0.12,4.88,0.19,7.3,0.48c9.71,0.86,19.32,3.43,28.15,7.57
			c8.85,4.14,16.97,9.73,24.24,16.19c3.61,3.27,7.08,6.7,10.24,10.41c3.14,3.73,6.07,7.64,8.63,11.8c2.53,4.17,4.74,8.54,6.56,13.08
			c1.77,4.56,3.1,9.28,3.99,14.09c1.73,9.62,1.42,19.62-0.91,29.11l-0.91,3.55l-1.12,3.49c-0.34,1.17-0.82,2.3-1.26,3.44
			c-0.46,1.13-0.84,2.3-1.38,3.39l-1.53,3.32c-0.51,1.11-1.13,2.16-1.69,3.24c-1.09,2.18-2.45,4.22-3.7,6.31
			c-5.33,8.17-11.84,15.56-19.23,21.91c-7.41,6.33-15.69,11.65-24.48,15.83c-8.79,4.2-18.11,7.25-27.62,9.29
			c-1.19,0.24-2.38,0.53-3.58,0.73l-3.61,0.57c-2.4,0.44-4.83,0.59-7.25,0.87c-1.21,0.16-2.43,0.16-3.65,0.23l-3.65,0.17l-3.66-0.03
			c-1.22-0.01-2.44,0-3.65-0.12c-2.43-0.17-4.87-0.3-7.28-0.7c-1.21-0.18-2.42-0.3-3.62-0.54l-3.59-0.75
			c-0.6-0.13-1.2-0.23-1.79-0.4l-1.76-0.51l-3.52-1.03c-2.32-0.79-4.59-1.69-6.83-2.68c-8.9-4.05-17.04-9.79-23.77-16.83
			L559.51,865.53z"/>
        </g>
        <g>
          <path className="st3" d="M444.5,194.59c1.72,12.79,14.64,19.3,26.39,20.81c6.03,0.69,12.21,0.02,17.93-2.03
			c2.84-1.04,5.56-2.43,7.85-4.36c2.27-1.93,4.13-4.32,5.42-7.02c2.64-5.38,3.29-11.72,2.03-17.57c-1.32-5.85-4.68-11.21-9.3-15.06
			c-2.28-1.96-4.92-3.48-7.67-4.66c-1.38-0.58-2.83-1.02-4.29-1.4c-1.47-0.33-2.96-0.62-4.47-0.75c-3.01-0.29-6.09-0.19-9.1,0.32
			c-1.51,0.23-3,0.59-4.48,1c-0.74,0.21-1.47,0.44-2.19,0.7c-0.72,0.25-1.46,0.48-2.11,0.91l-0.54-1.26
			c0.8-0.13,1.47-0.56,2.22-0.82c0.73-0.3,1.47-0.57,2.22-0.83c1.5-0.51,3.03-0.95,4.59-1.26c3.11-0.7,6.34-0.87,9.53-0.62
			c6.4,0.45,12.64,3.05,17.52,7.2c4.91,4.12,8.49,9.84,9.88,16.13c1.33,6.28,0.61,12.99-2.25,18.77c-1.4,2.9-3.44,5.5-5.91,7.58
			c-2.5,2.05-5.36,3.59-8.34,4.71c-5.97,2.19-12.46,3.07-18.79,2.26c-6.32-0.77-12.46-3.02-17.75-6.58
			c-0.65-0.47-1.32-0.89-1.94-1.39c-0.62-0.51-1.24-0.98-1.86-1.51c-1.27-1.17-2.2-2.54-2.97-3.95c-0.77-1.42-1.41-2.89-1.94-4.4
			c-0.52-1.51-0.99-3.05-1.05-4.7L444.5,194.59z"/>
        </g>
        <g>
          <path className="st3" d="M948.5,620.99c-1.67,1.03-3.33,2.02-4.91,3.14c-1.58,1.11-3.06,2.36-4.4,3.74c-2.71,2.74-4.73,6.08-6.16,9.66
			c-1.46,3.58-2.24,7.42-2.56,11.28c-0.32,3.86-0.11,7.78,0.64,11.58c1.47,7.58,5.14,14.77,10.63,20.23
			c2.71,2.75,5.9,5.05,9.36,6.75c1.74,0.84,3.55,1.51,5.4,2.05c1.87,0.49,3.76,0.85,5.68,1.03c7.71,0.72,15.54-1.29,22.38-4.94
			c6.86-3.62,12.99-8.91,16.34-15.89c0.85-1.73,1.57-3.52,2.14-5.36c0.54-1.85,0.96-3.73,1.24-5.64c0.54-3.83,0.48-7.74,0-11.61
			c-0.23-1.93-0.56-3.9-1-5.76c-0.48-1.87-1.12-3.73-1.86-5.53c-0.75-1.8-1.6-3.57-2.59-5.24c-0.51-0.82-1.04-1.64-1.63-2.39
			c-0.59-0.75-1.21-1.5-2.02-1.98l0.85-1.07c3.09,2.87,5.19,6.37,6.85,10.05c0.82,1.84,1.58,3.72,2.12,5.7
			c0.5,2.01,0.85,3.95,1.13,5.96c0.57,3.99,0.63,8.09,0.08,12.12c-0.29,2.01-0.73,4-1.31,5.95c-0.6,1.94-1.38,3.83-2.27,5.65
			c-1.79,3.66-4.31,6.97-7.28,9.73c-2.97,2.78-6.34,5.06-9.91,6.94c-7.11,3.77-15.29,5.86-23.41,5.08c-2.02-0.2-4.02-0.59-5.99-1.11
			c-1.95-0.57-3.86-1.29-5.69-2.18c-3.63-1.82-7-4.16-9.87-7.03c-2.9-2.83-5.34-6.14-7.25-9.72c-1.94-3.56-3.27-7.46-4.01-11.44
			c-0.77-3.98-0.97-8.06-0.61-12.1c0.35-4.03,1.19-8.04,2.74-11.81c2.92-7.62,9.14-13.95,16.57-17.09L948.5,620.99z"/>
        </g>
        <g>
          <path className="st3" d="M408.08,779.42c-0.66-1.47-1.25-2.95-1.99-4.36c-0.75-1.41-1.57-2.78-2.55-4.04
			c-0.96-1.27-2.02-2.47-3.19-3.56c-1.15-1.1-2.41-2.1-3.73-2.98c-5.28-3.61-11.77-5.18-18.15-4.86c-6.37,0.34-12.74,2.23-18.24,5.5
			c-2.73,1.65-5.21,3.72-7.06,6.28c-1.89,2.56-3.25,5.47-3.98,8.55c-0.74,3.08-0.83,6.31-0.51,9.5c0.07,0.8,0.19,1.6,0.3,2.38
			c0.13,0.75,0.32,1.53,0.54,2.29c0.44,1.53,1.01,3.03,1.68,4.47c0.68,1.43,1.47,2.81,2.44,3.98c0.49,0.58,1,1.05,1.66,1.55
			c0.63,0.49,1.23,1.02,1.91,1.46c2.61,1.86,5.46,3.38,8.46,4.52c6,2.27,12.53,3.12,18.93,2.4c3.2-0.35,6.36-1.13,9.37-2.27
			c1.5-0.58,2.96-1.27,4.34-2.09c0.69-0.41,1.36-0.85,2.01-1.33c0.63-0.49,1.31-0.94,1.78-1.61l0.95,0.99
			c-5,4.62-11.52,7.34-18.2,8.15c-6.7,0.88-13.58-0.05-19.86-2.45c-3.14-1.21-6.13-2.82-8.87-4.78c-0.71-0.46-1.34-1.02-2-1.54
			c-0.65-0.49-1.35-1.13-1.91-1.81c-1.12-1.36-1.96-2.86-2.68-4.4c-0.71-1.54-1.3-3.12-1.76-4.75c-0.24-0.81-0.44-1.64-0.58-2.5
			c-0.12-0.84-0.24-1.66-0.31-2.51c-0.32-3.34-0.23-6.78,0.58-10.09c0.79-3.31,2.28-6.46,4.3-9.18c2.03-2.75,4.65-5.02,7.52-6.79
			c2.88-1.78,5.98-3.11,9.18-4.13c3.21-1.01,6.55-1.62,9.93-1.75c3.37-0.14,6.78,0.19,10.05,1.07c3.28,0.86,6.42,2.3,9.21,4.21
			c1.4,0.95,2.73,2.01,3.95,3.19c1.24,1.16,2.36,2.44,3.37,3.8c1.04,1.34,1.91,2.81,2.69,4.31c0.78,1.5,1.4,3.1,1.73,4.77
			L408.08,779.42z"/>
        </g>
        <g>
          <path className="st3" d="M359.92,286.56c0.13-1,0.5-1.93,0.94-2.83c0.44-0.9,0.95-1.77,1.54-2.6c1.18-1.64,2.72-3.11,4.59-4.05
			c1.91-0.96,4.08-1.26,6.18-0.9c2.08,0.35,4.17,1.46,5.41,3.36l-1.77,0.18c1.34-3.43,3.04-6.91,5.84-9.64
			c1.39-1.35,3.05-2.47,4.91-3.14c1.85-0.66,3.82-0.94,5.76-0.82c1.95,0.13,3.87,0.66,5.61,1.58c1.74,0.93,3.25,2.29,4.39,3.88
			c2.28,3.19,3.19,7.35,2.24,11.22l-1.91-0.63c1.15-2.81,3.78-4.72,6.54-5.45c2.77-0.77,5.89-0.47,8.39,1.09
			c2.48,1.53,4.14,4.05,4.96,6.7c0.41,1.33,0.62,2.71,0.63,4.09l-0.03,1.03l-0.11,1.03l-0.19,1.02c-0.09,0.33-0.18,0.66-0.16,1.03
			l-1.32-0.37c0.35-0.55,0.31-1.21,0.39-1.82l0.02-0.94l-0.05-0.93c-0.11-1.24-0.37-2.46-0.82-3.6c-0.83-2.29-2.3-4.35-4.34-5.59
			c-1.99-1.24-4.58-1.51-6.89-0.88c-2.32,0.61-4.41,2.15-5.3,4.31l-1.68-0.56c0.83-3.27,0.06-6.92-1.9-9.7
			c-1.93-2.82-5.2-4.55-8.62-4.77c-1.7-0.12-3.44,0.1-5.03,0.72c-1.59,0.6-3.01,1.59-4.22,2.81c-2.43,2.46-4,5.72-5.36,9.02
			l-0.68,1.65l-0.95-1.49c-0.88-1.37-2.44-2.26-4.13-2.56c-1.69-0.3-3.5-0.07-5.03,0.69c-1.57,0.78-2.9,2.01-3.96,3.46
			c-0.53,0.73-1,1.51-1.4,2.33c-0.4,0.82-0.75,1.66-1.22,2.48L359.92,286.56z"/>
        </g>
        <g>
          <path className="st3" d="M694.41,245.26c-0.06-0.93,0.19-1.86,0.57-2.7c0.39-0.84,0.9-1.62,1.52-2.31c1.23-1.38,2.87-2.41,4.72-2.83
			c3.7-0.8,7.7,0.9,9.7,4.15l-1.81,0.43c0.32-4.68,0.83-9.57,2.98-14.02c2.04-4.45,6.05-8.16,10.95-9.37
			c2.43-0.63,5.16-0.42,7.48,0.67c2.35,1.05,4.2,2.98,5.49,5.08c2.54,4.3,3.37,9.13,3.98,13.8l-1.89-0.39
			c1.36-2.36,3.69-4.01,6.22-4.77c2.54-0.73,5.38-0.59,7.77,0.68c2.38,1.27,4.06,3.51,4.88,5.95c0.41,1.23,0.61,2.52,0.58,3.81
			l-0.07,0.96l-0.17,0.95l-0.26,0.93c-0.12,0.3-0.23,0.6-0.26,0.94l-1.26-0.55c0.38-0.45,0.42-1.04,0.54-1.58l0.08-0.84l0-0.84
			c-0.05-1.12-0.3-2.22-0.72-3.25c-0.87-2.02-2.27-3.86-4.21-4.81c-1.9-0.97-4.24-1.12-6.36-0.51c-2.11,0.62-4.03,1.99-5.12,3.86
			l-1.34,2.32l-0.33-2.66c-0.57-4.57-1.4-9.21-3.66-13.07c-1.15-1.88-2.71-3.51-4.67-4.4c-1.94-0.93-4.18-1.12-6.3-0.59
			c-1.06,0.25-2.09,0.65-3.07,1.17c-0.97,0.53-1.87,1.19-2.7,1.92c-1.67,1.46-2.92,3.33-3.86,5.37c-1.85,4.11-2.48,8.72-2.81,13.34
			l-0.21,2.94l-1.49-2.53c-1.49-2.54-4.8-3.99-7.69-3.35c-1.44,0.31-2.78,1.13-3.79,2.24c-0.5,0.56-0.93,1.19-1.24,1.87
			c-0.32,0.68-0.55,1.38-0.79,2.11L694.41,245.26z"/>
        </g>
        <g>
          <path className="st3" d="M135.43,554.51c0.35-1.3,1.24-2.33,2.11-3.3c0.9-0.96,1.93-1.79,3.05-2.47c2.24-1.37,4.86-2.16,7.52-2.14
			c2.67,0.02,5.29,0.84,7.53,2.24c2.25,1.4,4.08,3.46,5.23,5.86l-1.78,0.8c-0.51-1.26-0.55-2.67-0.28-3.89
			c0.26-1.24,0.75-2.35,1.27-3.41c1.1-2.09,2.3-4,3.71-5.88c1.4-1.85,3.04-3.6,5.03-4.97c1-0.67,2.09-1.24,3.25-1.65
			c1.17-0.38,2.4-0.6,3.63-0.63c2.48-0.07,4.86,0.68,6.99,1.67c2.15,0.99,4.07,2.31,5.91,3.66c1.86,1.33,3.68,2.91,5.07,4.85
			c0.7,0.97,1.28,2.08,1.55,3.32c0.07,0.31,0.12,0.62,0.13,0.94c0.04,0.32,0,0.64-0.03,0.96c-0.01,0.32-0.14,0.63-0.21,0.94
			c-0.1,0.3-0.25,0.58-0.28,0.93l-1.22-0.63c0.38-0.37,0.4-0.91,0.44-1.37c0-0.24,0.01-0.48-0.04-0.72
			c-0.02-0.24-0.09-0.48-0.16-0.71c-0.29-0.94-0.88-1.8-1.53-2.61c-1.32-1.66-3-3.02-4.82-4.31c-1.81-1.31-3.65-2.56-5.62-3.49
			c-1.96-0.92-4.04-1.58-6.13-1.53c-1.04,0.02-2.07,0.2-3.07,0.52c-0.99,0.34-1.93,0.82-2.82,1.41c-1.77,1.19-3.3,2.78-4.64,4.52
			c-1.33,1.74-2.55,3.66-3.58,5.58c-1,1.9-1.6,4.07-0.84,5.8l-1.59,0.72c-1.01-2.06-2.67-3.78-4.61-5.02
			c-1.94-1.24-4.21-1.97-6.5-2.01c-2.29-0.04-4.59,0.63-6.57,1.81c-0.99,0.59-1.91,1.32-2.7,2.15c-0.83,0.82-1.39,1.81-2.17,2.71
			L135.43,554.51z"/>
        </g>
        <g>
          <path className="st3" d="M474.11,716.85c-0.26-0.6-0.45-1.23-0.55-1.87c-0.06-0.65-0.18-1.29-0.16-1.95c-0.01-0.65-0.02-1.3,0.06-1.95
			c0.04-0.65,0.15-1.29,0.27-1.93c0.49-2.55,1.52-4.98,2.98-7.13c2.87-4.28,7.46-7.55,12.63-8.43c2.57-0.48,5.33-0.28,7.79,0.67
			c2.47,0.92,4.66,2.44,6.46,4.31c1.81,1.88,3.19,4.09,4.29,6.38c1.1,2.29,1.92,4.67,2.7,7.03l-1.94,0.14
			c0.48-2.74,1.93-5.2,3.94-7.05c1.99-1.85,4.66-3.09,7.45-3.13c2.84-0.08,5.51,1.2,7.41,3.12c0.95,0.97,1.71,2.14,2.15,3.45
			c0.22,0.65,0.34,1.34,0.37,2.03c0.01,0.35-0.03,0.69-0.05,1.04c-0.06,0.34-0.15,0.67-0.12,1.04l-1.32-0.38
			c0.32-0.5,0.23-1.1,0.21-1.65c-0.08-0.56-0.21-1.1-0.44-1.61c-0.44-1.03-1.14-1.95-2-2.69c-1.64-1.56-3.9-2.49-6.12-2.43
			c-2.28,0.05-4.52,1.05-6.23,2.62c-1.72,1.56-2.97,3.68-3.37,5.94l-0.62,3.5l-1.09-3.38c-1.5-4.65-3.21-9.3-6.48-12.75
			c-1.63-1.7-3.59-3.1-5.77-3.9c-2.19-0.79-4.55-0.92-6.84-0.45c-4.6,0.93-8.78,3.73-11.43,7.63c-1.34,1.93-2.29,4.14-2.76,6.44
			c-0.12,0.57-0.23,1.15-0.26,1.73c-0.08,0.58-0.08,1.17-0.07,1.75c-0.03,0.59,0.08,1.16,0.12,1.74c0.04,0.58,0.18,1.15,0.15,1.76
			L474.11,716.85z"/>
        </g>
        <g>
          <path className="st3" d="M978.54,845.63c-0.26-0.6-0.45-1.23-0.55-1.87c-0.06-0.65-0.18-1.29-0.16-1.95c-0.01-0.65-0.02-1.3,0.06-1.95
			c0.04-0.65,0.15-1.29,0.27-1.93c0.49-2.55,1.52-4.98,2.98-7.13c2.87-4.28,7.46-7.55,12.63-8.43c2.57-0.48,5.33-0.28,7.79,0.67
			c2.47,0.92,4.66,2.44,6.46,4.31c1.81,1.88,3.19,4.09,4.29,6.38c1.1,2.29,1.92,4.67,2.7,7.03l-1.94,0.14
			c0.48-2.74,1.93-5.2,3.94-7.05c1.99-1.85,4.66-3.09,7.45-3.13c2.84-0.08,5.51,1.2,7.41,3.12c0.95,0.97,1.71,2.14,2.15,3.45
			c0.22,0.65,0.34,1.34,0.37,2.03c0.01,0.35-0.03,0.69-0.05,1.04c-0.06,0.34-0.15,0.67-0.12,1.04l-1.32-0.38
			c0.32-0.5,0.23-1.1,0.21-1.65c-0.08-0.56-0.21-1.1-0.44-1.61c-0.44-1.03-1.14-1.95-2-2.69c-1.64-1.56-3.9-2.49-6.12-2.43
			c-2.28,0.05-4.52,1.05-6.23,2.62c-1.72,1.56-2.97,3.68-3.37,5.94l-0.62,3.5l-1.09-3.38c-1.5-4.65-3.21-9.3-6.48-12.75
			c-1.63-1.7-3.59-3.1-5.77-3.9c-2.19-0.79-4.55-0.92-6.84-0.45c-4.6,0.93-8.78,3.73-11.43,7.63c-1.34,1.93-2.29,4.14-2.76,6.44
			c-0.12,0.57-0.23,1.15-0.26,1.73c-0.08,0.58-0.08,1.17-0.07,1.75c-0.03,0.59,0.08,1.16,0.12,1.74c0.04,0.58,0.18,1.15,0.15,1.76
			L978.54,845.63z"/>
        </g>
        <g>
          <path className="st3" d="M238.07,865.89c0.02-0.69,0.12-1.35,0.19-2.02c0.01-0.67,0.15-1.34,0.12-2.01c-0.01-0.67,0.02-1.34-0.07-2.01
			l-0.09-1c-0.04-0.33-0.12-0.66-0.17-0.99c-0.48-2.65-1.46-5.21-2.85-7.52c-2.8-4.6-7.36-8.13-12.58-9.24
			c-2.59-0.55-5.24-0.38-7.67,0.61c-2.42,0.99-4.6,2.65-6.36,4.68c-1.78,2.01-3.13,4.41-4.21,6.93c-1.09,2.52-1.92,5.17-2.73,7.85
			l-1.19,3.94l-0.67-4.07c-0.43-2.6-1.73-5.07-3.6-6.94c-1.86-1.87-4.33-3.13-6.9-3.23c-2.51-0.11-5.05,1.12-6.78,3.02
			c-1.75,1.88-2.68,4.54-2.03,6.92l-2.01,0.37c-0.25-2.09-1.74-4.01-3.58-5.44c-0.93-0.72-1.94-1.34-3.01-1.84
			c-1.02-0.52-2.06-0.73-3.18-0.65c-2.21,0.13-4.37,1.49-5.22,3.57c-0.43,1.03-0.6,2.2-0.46,3.33c0.07,0.57,0.2,1.13,0.42,1.66
			c0.22,0.52,0.46,1.06,0.93,1.4l-1.06,0.87c-0.31-0.64-0.73-1.16-1.01-1.8c-0.3-0.62-0.49-1.28-0.61-1.95
			c-0.24-1.35-0.13-2.77,0.35-4.1c0.49-1.32,1.39-2.53,2.54-3.36c1.16-0.83,2.54-1.31,3.95-1.45c1.39-0.15,2.92,0.13,4.14,0.74
			c1.21,0.54,2.34,1.24,3.39,2.05c1.06,0.81,2.02,1.76,2.81,2.89c0.8,1.11,1.36,2.46,1.53,3.89l-1.79,0.33
			c-0.42-1.55-0.33-3.22,0.15-4.72c0.47-1.5,1.3-2.86,2.35-3.99c2.08-2.25,5.09-3.75,8.27-3.61c3.12,0.13,6.02,1.66,8.13,3.81
			c2.12,2.16,3.58,4.94,4.07,7.95l-1.64-0.11c1.64-5.35,3.5-10.92,7.3-15.43c1.89-2.22,4.25-4.1,7-5.26
			c1.37-0.58,2.85-0.94,4.35-1.08c1.5-0.12,3,0.02,4.45,0.32c2.89,0.62,5.59,1.94,7.93,3.7c2.34,1.77,4.3,4,5.82,6.49
			c1.49,2.51,2.53,5.28,3.03,8.16c0.06,0.36,0.13,0.72,0.18,1.08l0.09,1.09c0.09,0.73,0.06,1.46,0.06,2.19
			c0.01,0.73-0.1,1.46-0.18,2.19c-0.1,0.72-0.34,1.43-0.56,2.12L238.07,865.89z"/>
        </g>
      </g>
      <g id="Ebene_2">
      </g>
      <g id="Ebene_3">
        <g>
          <g>
            <path className="st12" d="M907.84,468.27c-1.33,0.3-2.64,0.3-3.96,0.28l-3.95-0.07l-7.9-0.15l-1.75-0.03l-0.05-1.77l-0.45-15.55
				c-0.13-5.18-0.34-10.36-0.41-15.55l-0.17-15.55c-0.03-5.18-0.24-10.37-0.35-15.55l-0.04-1.95l1.95,0l32.53,0.06
				c5.42,0.03,10.84,0,16.26,0.08l8.13,0.21l8.13,0.32l1.63,0.07l-0.05,1.52l-0.24,7.14l-0.09,3.57l-0.05,1.78
				c-0.01,0.59-0.07,1.19,0.17,1.78l0.02,0.04c0.31,0.77-0.06,1.64-0.82,1.95c-0.77,0.31-1.64-0.06-1.95-0.82
				c-0.16-0.39-0.14-0.8,0.01-1.16c0.26-0.59,0.18-1.19,0.14-1.78l-0.12-1.78l-0.24-3.57l-0.24-3.57c-0.04-1.19-0.11-2.38-0.13-3.57
				l1.92,1.93l-8.13-0.03l-8.13-0.14l-16.26-0.16l-32.53-0.29l1.69-1.72c0.11,5.18,0.14,10.37,0.32,15.55l0.5,15.55
				c0.16,5.18,0.21,10.37,0.32,15.55l0.28,15.55l-1.74-1.75l7.9,0.23l3.95,0.11c1.32,0.04,2.63,0.11,3.94,0.49l0.01,0
				c0.75,0.21,1.19,1,0.97,1.75C908.74,467.8,908.32,468.16,907.84,468.27z"/>
          </g>
          <g>
            <path className="st12" d="M959.83,439.77c-0.38-0.92-0.47-1.86-0.52-2.79l-0.18-2.81l-0.36-5.62l1.97,1.81
				c-3.8,0.21-7.6,0.38-11.4,0.52c-3.8,0.13-7.6,0.34-11.41,0.39l-11.41,0.23l-11.41,0.47l1.85-2l1,21.11
				c0.14,3.52,0.38,7.04,0.44,10.56c0.07,3.52,0,7.05-0.02,10.58l-1.56-1.49l4.57,0.21l2.29,0.08l1.14,0.04
				c0.38,0.02,0.76,0.05,1.14-0.18l0.05-0.03c0.77-0.47,1.78-0.22,2.24,0.55c0.47,0.77,0.22,1.78-0.55,2.24
				c-0.55,0.33-1.22,0.3-1.73-0.03c-0.38-0.25-0.76-0.19-1.14-0.16l-1.14,0.1c-0.76,0.05-1.52,0.15-2.29,0.23l-2.29,0.24
				c-0.76,0.07-1.52,0.1-2.29,0.15l-1.76,0.12l-0.15-1.95c-0.28-3.51-0.33-7.04-0.42-10.56c-0.07-3.52-0.25-7.04-0.36-10.56
				l-0.79-21.12l-0.06-1.72l1.7-0.05l11.41-0.34l11.4-0.57c3.8-0.22,7.6-0.31,11.41-0.46c3.8-0.15,7.61-0.27,11.41-0.35l1.83-0.04
				l0.07,1.79l0.21,5.63l0.11,2.81c0.05,0.94,0.05,1.88-0.25,2.83l0,0.01c-0.24,0.77-1.06,1.19-1.83,0.95
				C960.31,440.47,959.99,440.15,959.83,439.77z"/>
          </g>
          <polygon className="st12" points="965.25,478.78 934.35,478.35 935.05,448.63 966.21,449.37 		"/>
        </g>
        <g>
          <g>
            <path className="st12" d="M283.54,726.42c-1.33,0.3-2.64,0.3-3.96,0.28l-3.95-0.07l-7.9-0.15l-1.75-0.03l-0.05-1.77l-0.45-15.55
				c-0.13-5.18-0.34-10.36-0.41-15.55l-0.17-15.55c-0.03-5.18-0.24-10.37-0.35-15.55l-0.04-1.95l1.95,0l32.53,0.06
				c5.42,0.03,10.84,0,16.26,0.08l8.13,0.21l8.13,0.32l1.63,0.07l-0.05,1.52l-0.24,7.14l-0.09,3.57l-0.05,1.78
				c-0.01,0.59-0.07,1.19,0.17,1.78l0.02,0.04c0.31,0.77-0.06,1.64-0.82,1.95c-0.77,0.31-1.64-0.06-1.95-0.82
				c-0.16-0.39-0.14-0.8,0.01-1.16c0.26-0.59,0.18-1.19,0.14-1.78l-0.12-1.78l-0.24-3.57l-0.24-3.57c-0.04-1.19-0.11-2.38-0.13-3.57
				l1.92,1.93l-8.13-0.03l-8.13-0.14l-16.26-0.16l-32.53-0.29l1.69-1.72c0.11,5.18,0.14,10.37,0.32,15.55l0.5,15.55
				c0.16,5.18,0.21,10.37,0.32,15.55l0.28,15.55l-1.74-1.75l7.9,0.23l3.95,0.11c1.32,0.04,2.63,0.11,3.94,0.49l0.01,0
				c0.75,0.21,1.19,1,0.97,1.75C284.45,725.94,284.03,726.31,283.54,726.42z"/>
          </g>
          <g>
            <path className="st12" d="M335.54,697.91c-0.38-0.92-0.47-1.86-0.52-2.79l-0.18-2.81l-0.36-5.62l1.97,1.81
				c-3.8,0.21-7.6,0.38-11.4,0.52c-3.8,0.13-7.6,0.34-11.41,0.39l-11.41,0.23l-11.41,0.47l1.85-2l1,21.11
				c0.14,3.52,0.38,7.04,0.44,10.56c0.07,3.52,0,7.05-0.02,10.58l-1.56-1.49l4.57,0.21l2.29,0.08l1.14,0.04
				c0.38,0.02,0.76,0.05,1.14-0.18l0.05-0.03c0.77-0.47,1.78-0.22,2.24,0.55c0.47,0.77,0.22,1.78-0.55,2.24
				c-0.55,0.33-1.22,0.3-1.73-0.03c-0.38-0.25-0.76-0.19-1.14-0.16l-1.14,0.1c-0.76,0.05-1.52,0.15-2.29,0.23l-2.29,0.24
				c-0.76,0.07-1.52,0.1-2.29,0.15l-1.76,0.12l-0.15-1.95c-0.28-3.51-0.33-7.04-0.42-10.56c-0.07-3.52-0.25-7.04-0.36-10.56
				l-0.79-21.12l-0.06-1.72l1.7-0.05l11.41-0.34l11.4-0.57c3.8-0.22,7.6-0.31,11.41-0.46c3.8-0.15,7.61-0.27,11.41-0.35l1.83-0.04
				l0.07,1.79l0.21,5.63l0.11,2.81c0.05,0.94,0.05,1.88-0.25,2.83l0,0.01c-0.24,0.77-1.06,1.19-1.83,0.95
				C336.02,698.61,335.7,698.3,335.54,697.91z"/>
          </g>
          <polygon className="st12" points="340.96,736.92 310.06,736.5 310.76,706.78 341.92,707.51 		"/>
        </g>
        <g>
          <polygon className="st12" points="305.7,368.49 338.38,367.4 337.29,393.54 305.7,393.54 		"/>
          <polygon className="st12" points="245,418.25 276.27,419.51 277.36,393.36 245,393.19 		"/>
          <polygon className="st12" points="337.81,414.14 305.34,411.33 304.24,441.97 335.66,442.59 		"/>
          <g>
            <path className="st12" d="M309.97,382.21c-0.95,0.5-1.94,0.5-2.92,0.59l-2.95,0.21c-1.97,0.12-3.94,0.22-5.91,0.3
				c-1.97,0.09-3.94,0.07-5.91,0.07c-1.97-0.01-3.94-0.05-5.91-0.11l2.12-2.27c0.37,3.61,0.59,7.24,0.8,10.87
				c0.19,3.63,0.51,7.24,0.67,10.87c0.38,7.25,0.47,14.52,0.36,21.79l-2.21-2.09c1.69-0.15,3.38-0.2,5.07-0.17
				c1.69,0.06,3.38,0.19,5.06,0.39c1.68,0.19,3.34,0.44,5,0.75l2.47,0.47l1.23,0.26c0.41,0.07,0.8,0.29,1.27,0.07l0.08-0.04
				c0.88-0.42,1.93-0.05,2.35,0.83c0.42,0.88,0.05,1.93-0.83,2.35c-0.74,0.35-1.6,0.15-2.11-0.45c-0.34-0.4-0.77-0.27-1.17-0.34
				l-1.22-0.07l-2.44,0c-1.62,0.03-3.22-0.08-4.83-0.14c-3.2-0.16-6.42-0.3-9.63-0.05l-0.01,0c-1,0.08-1.87-0.66-1.95-1.66
				c-0.01-0.06-0.01-0.13-0.01-0.2c0.2-7.18,0.17-14.37-0.09-21.55c-0.15-3.59-0.27-7.18-0.61-10.76
				c-0.32-3.58-0.75-7.15-1.16-10.72c-0.12-1.02,0.61-1.94,1.63-2.06c0.09-0.01,0.18-0.01,0.27-0.01l0.03,0
				c1.94,0.07,3.87,0.11,5.81,0.13l5.81,0c1.94-0.01,3.87-0.04,5.81-0.09l2.9-0.11c0.97-0.04,1.93-0.17,2.93,0.21l0.02,0.01
				c0.77,0.29,1.16,1.16,0.87,1.93C310.54,381.77,310.28,382.05,309.97,382.21z"/>
          </g>
        </g>
        <g>
          <polygon className="st12" points="637.61,772.39 670.28,771.3 669.19,797.44 637.61,797.44 		"/>
          <polygon className="st12" points="576.9,822.15 608.18,823.41 609.26,797.27 576.9,797.09 		"/>
          <polygon className="st12" points="669.71,818.05 637.24,815.24 636.14,845.87 667.56,846.49 		"/>
          <g>
            <path className="st12" d="M641.88,786.11c-0.95,0.5-1.94,0.5-2.92,0.59l-2.95,0.21c-1.97,0.12-3.94,0.22-5.91,0.3
				c-1.97,0.09-3.94,0.07-5.91,0.07c-1.97-0.01-3.94-0.05-5.91-0.11l2.12-2.27c0.37,3.61,0.59,7.24,0.8,10.87
				c0.19,3.63,0.51,7.24,0.67,10.87c0.38,7.25,0.47,14.52,0.36,21.79l-2.21-2.09c1.69-0.15,3.38-0.2,5.07-0.17
				c1.69,0.06,3.38,0.19,5.06,0.39c1.68,0.19,3.34,0.44,5,0.75l2.47,0.47l1.23,0.26c0.41,0.07,0.8,0.29,1.27,0.07l0.08-0.04
				c0.88-0.42,1.93-0.05,2.35,0.83c0.42,0.88,0.05,1.93-0.83,2.35c-0.74,0.35-1.6,0.15-2.11-0.45c-0.34-0.4-0.77-0.27-1.17-0.34
				l-1.22-0.07l-2.44,0c-1.62,0.03-3.22-0.08-4.83-0.14c-3.2-0.16-6.42-0.3-9.63-0.05l-0.01,0c-1,0.08-1.87-0.66-1.95-1.66
				c-0.01-0.06-0.01-0.13-0.01-0.2c0.2-7.18,0.17-14.37-0.09-21.55c-0.15-3.59-0.27-7.18-0.61-10.76
				c-0.32-3.58-0.75-7.15-1.16-10.72c-0.12-1.02,0.61-1.94,1.63-2.06c0.09-0.01,0.18-0.01,0.27-0.01l0.03,0
				c1.94,0.07,3.87,0.11,5.81,0.13l5.81,0c1.94-0.01,3.87-0.04,5.81-0.09l2.9-0.11c0.97-0.04,1.93-0.17,2.93,0.21l0.02,0.01
				c0.77,0.29,1.16,1.16,0.87,1.93C642.44,785.67,642.19,785.95,641.88,786.11z"/>
          </g>
        </g>
      </g>
      <g id="Ebene_6">
        <g>
          <path className="st3" d="M662.7,477.95c6.24,3.42,12.36,7.05,18.48,10.68l18.34,10.93c12.2,7.34,24.37,14.71,36.37,22.39
			c3,1.93,5.98,3.87,8.93,5.88c1.47,1.01,2.94,2.03,4.38,3.12c0.72,0.54,1.44,1.1,2.14,1.72c0.35,0.32,0.7,0.62,1.06,1.08
			c0.09,0.12,0.19,0.25,0.29,0.47c0.04,0.08,0.12,0.31,0.13,0.41c0.01,0.1,0.03,0.22,0.03,0.31c0,0.4-0.08,0.71-0.18,0.99
			c-0.41,1.07-1,1.8-1.58,2.54c-0.59,0.72-1.21,1.39-1.85,2.04c-1.27,1.3-2.6,2.51-3.95,3.7c-2.7,2.37-5.48,4.61-8.28,6.81
			c-11.26,8.77-22.92,16.95-34.68,24.98c-5.88,4.01-11.8,7.97-17.75,11.88c-5.95,3.91-11.95,7.76-18.04,11.45
			c-12.18,7.4-24.54,14.47-37.01,21.33c-6.24,3.42-12.52,6.79-18.86,10.04c-3.17,1.62-6.36,3.22-9.58,4.76
			c-3.25,1.51-6.43,3.06-9.95,4.22c-0.24,0.07-0.43,0.13-0.71,0.2c-0.41,0.08-0.71,0.05-0.98,0.02c-0.55-0.06-0.99-0.2-1.46-0.33
			c-0.9-0.27-1.75-0.6-2.59-0.94c-1.68-0.69-3.3-1.44-4.92-2.22c-3.22-1.56-6.38-3.22-9.52-4.91c-6.28-3.38-12.47-6.9-18.63-10.48
			c-12.31-7.16-24.48-14.55-36.56-22.09c-6.04-3.77-12.05-7.58-18.04-11.44l-8.95-5.79c-3-1.89-6.03-3.73-9.08-5.55
			c-12.2-7.29-24.58-14.3-36.73-21.76c-3.03-1.87-6.05-3.77-9.02-5.78c-1.48-1.01-2.95-2.04-4.39-3.16
			c-0.72-0.56-1.42-1.14-2.11-1.79c-0.34-0.33-0.68-0.67-1-1.09c-0.16-0.21-0.32-0.44-0.47-0.73c-0.13-0.29-0.32-0.67-0.22-1.3
			c0.05-0.31,0.26-0.64,0.44-0.8l0.24-0.24l0.48-0.47l0.97-0.94l1.99-1.82c1.34-1.19,2.7-2.36,4.08-3.5
			c2.75-2.29,5.57-4.48,8.42-6.63c11.4-8.59,23.29-16.45,35.21-24.23c11.94-7.76,23.98-15.31,35.89-23.11
			c12.01-7.66,24.19-15.02,36.42-22.3c6.12-3.64,12.25-7.24,18.41-10.8l9.27-5.3l4.67-2.6l2.34-1.3l1.19-0.63l0.61-0.32l0.33-0.16
			c0.12-0.05,0.23-0.11,0.51-0.16c0.38-0.03,0.71,0.24,0.74,0.62c0.03,0.38-0.24,0.71-0.62,0.74c0.06-0.01-0.04,0.01-0.13,0.04
			l-0.28,0.11l-0.59,0.26l-1.18,0.59l-2.35,1.24l-4.63,2.63l-9.2,5.38c-6.12,3.62-12.21,7.29-18.3,10.97
			c-6.08,3.69-12.14,7.4-18.18,11.16c-6.04,3.76-12.09,7.49-18.06,11.33c-23.77,15.63-48.15,30.39-70.91,47.38
			c-2.83,2.13-5.63,4.31-8.36,6.57c-1.36,1.13-2.7,2.28-4.02,3.45l-1.95,1.78l-0.95,0.92l-0.47,0.46l-0.24,0.23
			c0.01,0,0.08-0.11,0.09-0.17c0.02-0.14-0.01-0.06,0.06,0.05c0.06,0.12,0.15,0.26,0.27,0.41c0.23,0.29,0.52,0.59,0.82,0.88
			c0.61,0.58,1.28,1.13,1.96,1.67c1.38,1.07,2.82,2.09,4.28,3.08c2.93,1.98,5.93,3.88,8.95,5.74c12.1,7.44,24.47,14.47,36.69,21.78
			c3.06,1.83,6.1,3.68,9.12,5.58l8.96,5.8c5.97,3.85,11.98,7.66,18.01,11.43c12.06,7.54,24.21,14.93,36.49,22.09
			c6.14,3.57,12.32,7.09,18.57,10.46c3.12,1.68,6.27,3.33,9.45,4.88c1.59,0.77,3.19,1.52,4.81,2.18c0.81,0.33,1.62,0.64,2.43,0.89
			c0.4,0.11,0.81,0.23,1.16,0.26c0.17,0.02,0.33,0.02,0.38,0.01l0.58-0.17c3.26-1.06,6.5-2.62,9.7-4.11
			c3.2-1.52,6.38-3.11,9.54-4.73c6.32-3.23,12.59-6.59,18.83-10c12.47-6.83,24.8-13.91,36.93-21.32c6.07-3.7,12.03-7.54,17.96-11.47
			c5.93-3.92,11.83-7.89,17.69-11.91c5.86-4.02,11.69-8.1,17.47-12.23c5.78-4.13,11.51-8.33,17.1-12.69
			c2.79-2.18,5.55-4.41,8.21-6.73c1.33-1.16,2.63-2.35,3.85-3.59c0.61-0.62,1.2-1.26,1.74-1.91c0.53-0.64,1.04-1.36,1.27-1.97
			c0.05-0.15,0.07-0.28,0.07-0.34c0-0.02-0.01-0.02,0-0.03c0-0.01,0.03,0.1,0.02,0.08c0,0.01-0.03-0.05-0.07-0.1
			c-0.19-0.25-0.51-0.55-0.82-0.82c-0.63-0.56-1.32-1.1-2.02-1.63c-1.4-1.06-2.85-2.07-4.31-3.07c-2.92-2-5.89-3.94-8.88-5.87
			c-11.96-7.67-24.11-15.07-36.29-22.42l-18.31-10.95c-6.12-3.63-12.25-7.22-18.27-11.02c-0.32-0.2-0.42-0.63-0.22-0.95
			C661.98,477.88,662.38,477.78,662.7,477.95z"/>
        </g>
        <path className="st3" d="M591.14,587.61c-5.23,0-80.31-46.83-80.84-49.68c-0.58-3.07,26.91-21.59,36.93-28.05
		c13.79-8.88,37.07-23.55,42.57-23.82c4.91-0.23,24.51,14.81,42.82,25.32c9.69,5.56,38.71,22.1,37.08,23.74
		c-8.3,8.3-30.4,21.81-40.2,28.28C617.49,571.34,597.34,587.61,591.14,587.61z"/>
        <g>
          <path className="st3" d="M680,470.12c0.16,0.14-20.94,5.8-22.64,5.77c-0.33-3.39-2.07-14.35-2.66-21.01
			C664.62,460.65,675.42,466.12,680,470.12z"/>
          <g>
            <g>
              <path className="st3" d="M730.15,321.67c-4.31,6.5-8.88,12.82-13.35,19.21L703.32,360c-9,12.74-18.05,25.45-27.26,38.04
					c-4.62,6.29-9.28,12.55-14.04,18.74l-3.6,4.62c-1.2,1.52-2.33,3.03-3.17,4.66c-1.78,3.23-1.66,7.02-1.41,10.92
					c0.34,7.76,1.04,15.52,1.8,23.27c0.4,3.87,0.81,7.75,1.41,11.56c0.08,0.47,0.16,0.95,0.25,1.41c0.09,0.43,0.21,0.97,0.31,1.21
					c-0.13-0.17-0.13-0.1,0.01-0.07c0.13,0.04,0.3,0.07,0.5,0.08c0.4,0.03,0.85,0,1.31-0.04c1.84-0.19,3.73-0.64,5.59-1.11
					c7.49-1.94,14.88-4.48,22.36-6.72c1.88-0.56,3.76-1.11,5.68-1.58c0.98-0.24,1.89-0.43,2.81-0.7c0.91-0.26,1.82-0.55,2.72-0.88
					c0.9-0.32,1.78-0.67,2.63-1.07c0.84-0.38,1.72-0.84,2.37-1.31c0.63-0.5,1.29-1.23,1.91-1.94c0.62-0.72,1.23-1.47,1.83-2.23
					c9.57-12.27,18.7-24.91,27.72-37.62c18.07-25.41,35.47-51.29,53.83-76.55l0.17,1.21c-7.87-6.15-15.91-12.07-24.09-17.81
					c-4.09-2.86-8.22-5.68-12.38-8.43c-2.08-1.38-4.18-2.74-6.28-4.08c-2.11-1.34-4.2-2.7-6.4-3.88l-0.01,0
					c-0.33-0.18-0.46-0.6-0.28-0.93c0.18-0.33,0.6-0.46,0.93-0.28c0.02,0.01,0.05,0.03,0.07,0.04c2.06,1.43,4.24,2.67,6.36,3.99
					c2.13,1.31,4.26,2.64,6.37,3.99c4.23,2.69,8.41,5.45,12.55,8.26c4.14,2.82,8.27,5.66,12.29,8.64c4.04,2.96,8.04,5.97,12,9.04
					l0.73,0.56l-0.54,0.74c-9.11,12.64-18,25.45-26.92,38.25c-8.91,12.8-17.83,25.6-26.87,38.32c-9.05,12.71-18.2,25.35-27.82,37.66
					c-0.61,0.77-1.23,1.54-1.88,2.29c-0.67,0.75-1.31,1.49-2.19,2.19c-0.92,0.66-1.79,1.08-2.71,1.51
					c-0.91,0.42-1.84,0.78-2.78,1.12c-0.94,0.34-1.88,0.64-2.84,0.91c-0.96,0.28-1.95,0.48-2.87,0.71c-1.87,0.46-3.73,1-5.59,1.55
					c-7.46,2.22-14.84,4.74-22.45,6.71c-1.91,0.47-3.82,0.93-5.86,1.14c-0.51,0.05-1.03,0.08-1.6,0.04
					c-0.28-0.02-0.58-0.06-0.91-0.16c-0.3-0.11-0.78-0.26-1.06-0.87c-0.26-0.64-0.32-1.08-0.43-1.59c-0.1-0.49-0.19-0.98-0.27-1.47
					c-0.62-3.89-1.05-7.77-1.47-11.65c-0.8-7.77-1.53-15.54-1.91-23.36c-0.12-1.93-0.25-3.93-0.09-5.96
					c0.14-2.03,0.7-4.12,1.65-5.94c0.93-1.84,2.17-3.45,3.38-4.98l3.59-4.6c4.76-6.16,9.42-12.4,14.05-18.68
					c9.23-12.56,18.29-25.25,27.31-37.97l13.51-19.1c4.52-6.36,8.94-12.78,13.69-18.98c0.23-0.3,0.66-0.36,0.96-0.13
					C730.28,320.97,730.35,321.37,730.15,321.67z"/>
            </g>
            <g>
              <path className="st3" d="M677.26,420.31c0.52-1.1,1.29-2,1.96-2.97c0.7-0.96,1.37-1.94,2.05-2.91c1.34-1.96,2.66-3.94,3.96-5.93
					l7.71-12.04c5.22-7.99,10.47-16,15.91-23.84c5.41-7.87,10.91-15.67,16.46-23.44c5.55-7.77,11.48-15.26,17.21-22.91l0.1-0.13
					c0.23-0.31,0.67-0.37,0.98-0.14c0.31,0.23,0.37,0.67,0.14,0.98c-0.04,0.05-0.08,0.09-0.13,0.13c-0.48,0.38-0.78,0.9-1.13,1.39
					l-1.02,1.47l-2.03,2.94c-1.36,1.96-2.69,3.94-4.12,5.85l-8.42,11.56c-5.57,7.75-11.08,15.53-16.53,23.36
					c-2.72,3.91-5.43,7.84-8.08,11.8c-2.68,3.94-5.17,8-7.72,12.03c-2.54,4.04-5.15,8.04-7.78,12.03c-1.32,1.99-2.67,3.97-4.04,5.93
					c-0.69,0.98-1.38,1.96-2.09,2.92c-0.71,0.96-1.38,1.96-2.31,2.77c-0.29,0.25-0.73,0.22-0.99-0.07
					c-0.19-0.22-0.22-0.52-0.11-0.76L677.26,420.31z"/>
            </g>
            <g>
              <path className="st3" d="M692.05,427.6c0.45-1.11,1.15-2.07,1.78-3.06l1.95-2.95l3.94-5.88c2.66-3.9,5.33-7.78,8.05-11.63
					c2.73-3.84,5.48-7.64,8-11.6c1.25-1.98,2.48-3.98,3.66-6c0.57-1,1.21-2.06,1.87-3.05c0.66-0.99,1.32-1.97,2-2.94
					c5.41-7.75,10.98-15.34,16.48-22.99l8.39-11.36c1.4-1.89,2.79-3.79,4.15-5.71c0.69-0.95,1.36-1.91,2.02-2.88
					c0.33-0.48,0.68-0.96,0.98-1.45c0.31-0.49,0.67-0.96,0.85-1.52l0.01-0.04c0.12-0.37,0.52-0.58,0.9-0.46
					c0.37,0.12,0.58,0.52,0.46,0.9c-0.04,0.11-0.1,0.21-0.18,0.29c-0.45,0.43-0.69,0.97-1,1.49c-0.29,0.53-0.61,1.02-0.91,1.53
					c-0.61,1.02-1.23,2.02-1.87,3.01c-1.28,1.99-2.55,3.98-3.93,5.89c-2.7,3.87-5.51,7.65-8.29,11.46
					c-5.53,7.63-11.13,15.22-16.54,22.9c-0.68,0.96-1.34,1.93-1.99,2.9c-0.64,0.97-1.23,1.92-1.84,2.96
					c-1.2,2.04-2.46,4.04-3.73,6.04c-2.58,3.97-5.2,7.9-7.89,11.77l-8.11,11.56l-3.98,5.82l-1.97,2.93
					c-0.66,0.97-1.26,1.99-2.11,2.84l-0.01,0.01c-0.27,0.27-0.72,0.27-0.99,0C692,428.16,691.95,427.86,692.05,427.6z"/>
            </g>
            <g>
              <path className="st3" d="M707.25,437.78c0.54-1.05,1.29-1.94,1.97-2.89c0.68-0.95,1.35-1.9,2.01-2.86c1.32-1.93,2.6-3.89,3.87-5.86
					c2.55-3.94,5.03-7.94,7.7-11.84c2.73-3.88,5.53-7.61,8.27-11.44l8.16-11.5l16.29-23.01c1.35-1.93,2.72-3.83,4.11-5.73
					c1.38-1.9,2.76-3.8,4.17-5.68l4.17-5.68l2.07-2.85l1.03-1.43c0.33-0.48,0.72-0.92,0.92-1.49c0.13-0.36,0.53-0.55,0.89-0.43
					c0.36,0.13,0.55,0.53,0.43,0.89c-0.02,0.06-0.05,0.12-0.09,0.17l-0.09,0.12c-2.74,3.85-5.04,7.96-7.84,11.74
					c-2.68,3.86-5.49,7.63-8.19,11.47l-16.39,22.94l-8.22,11.46c-2.69,3.84-5.48,7.66-8.09,11.52c-2.62,3.87-5.17,7.81-7.75,11.75
					c-1.29,1.97-2.6,3.93-3.95,5.87c-0.67,0.97-1.36,1.93-2.06,2.89c-0.72,0.94-1.39,1.93-2.32,2.7c-0.3,0.25-0.74,0.21-0.98-0.09
					C707.15,438.33,707.12,438.03,707.25,437.78L707.25,437.78z"/>
            </g>
            <g>
              <path className="st3" d="M657.65,429.12c0.95-0.33,1.94-0.26,2.91-0.37l2.92-0.25c1.94-0.18,3.88-0.4,5.79-0.68
					c0.51-0.08,0.99,0.27,1.09,0.77l0,0.02c0.46,2.4,0.59,4.84,0.59,7.24c0.01,2.4-0.16,4.75-0.15,7.07l-1.22-0.94
					c1.28-0.31,2.54-0.69,3.8-1.11c1.26-0.41,2.51-0.91,3.78-1.38c2.54-0.95,5.24-1.74,7.99-2.12c0.53-0.07,1.02,0.3,1.1,0.83
					c0.01,0.04,0.01,0.07,0.01,0.11l0,0.01l0.27,12.75l-1.19-0.98c2.44-0.46,4.91-1.05,7.4-1.55c2.5-0.51,5.04-0.9,7.69-0.93l0.04,0
					c0.45,0,0.83,0.32,0.91,0.75c0.27,1.52,0.4,3.05,0.45,4.57c0.03,0.76,0.02,1.52,0.01,2.28l-0.03,1.14
					c0.01,0.38-0.12,0.75,0.04,1.15l0.04,0.1c0.16,0.41-0.05,0.88-0.46,1.04s-0.88-0.05-1.04-0.46c-0.1-0.26-0.05-0.54,0.09-0.75
					c0.12-0.17,0.12-0.35,0.1-0.54l-0.04-0.55l-0.11-1.1c-0.07-0.73-0.21-1.46-0.29-2.18c-0.11-0.72-0.16-1.45-0.27-2.16
					c-0.09-0.72-0.24-1.42-0.37-2.13l0.96,0.76c-2.41,0.03-4.89,0.42-7.34,0.9c-2.46,0.48-4.92,1.05-7.44,1.52
					c-0.48,0.09-0.95-0.23-1.04-0.72c-0.01-0.05-0.01-0.1-0.01-0.15l-0.2-12.75l0.97,0.84c-2.61,0.33-5.13,1.04-7.63,1.96
					c-2.51,0.9-5.06,1.92-7.73,2.62c-0.47,0.12-0.94-0.16-1.06-0.62c-0.02-0.07-0.03-0.14-0.03-0.2l0-0.02
					c-0.03-2.39,0.03-4.76,0.05-7.1c0.02-2.33-0.09-4.64-0.49-6.89l1.02,0.74c-1.97,0.26-3.94,0.46-5.9,0.62l-2.95,0.21
					c-0.99,0.03-1.96,0.22-2.97-0.03c-0.38-0.09-0.62-0.48-0.52-0.87C657.25,429.39,657.43,429.2,657.65,429.12L657.65,429.12z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path className="st3" d="M544.33,329.59c-0.18,1.33-0.51,2.62-0.85,3.9l-1.02,3.84l-0.01,0.05c-0.13,0.47-0.61,0.76-1.09,0.63
					c-0.47-0.13-0.76-0.61-0.63-1.09l0,0l1.06-3.89c0.35-1.3,0.72-2.59,1.24-3.83c0.15-0.35,0.55-0.52,0.9-0.37
					c0.29,0.12,0.46,0.43,0.42,0.73L544.33,329.59z"/>
              <path className="st3" d="M540.83,345.11l-1.22,7.86l-0.01,0.05c-0.08,0.51-0.56,0.86-1.07,0.78c-0.51-0.08-0.86-0.56-0.78-1.06
					l0-0.01l1.27-7.95c0.08-0.5,0.55-0.83,1.04-0.76c0.49,0.08,0.83,0.54,0.76,1.04L540.83,345.11z"/>
              <path className="st3" d="M538.8,360.89l-0.42,7.94l0,0.04c-0.03,0.54-0.49,0.95-1.03,0.92c-0.54-0.03-0.95-0.49-0.92-1.03l0-0.01
					l0.47-8.03c0.03-0.53,0.48-0.93,1.01-0.9c0.52,0.03,0.93,0.48,0.9,1L538.8,360.89z"/>
              <path className="st3" d="M538.28,376.79l0.24,7.96l0,0.04c0.02,0.5-0.38,0.92-0.88,0.93c-0.5,0.02-0.91-0.37-0.93-0.87l-0.34-8.04
					c-0.02-0.52,0.39-0.97,0.91-0.99s0.97,0.39,0.99,0.91c0,0,0,0,0,0.01L538.28,376.79z"/>
              <path className="st3" d="M539.13,392.68l1,7.9l0,0.04c0.06,0.49-0.28,0.94-0.77,1c-0.49,0.06-0.94-0.28-1-0.77l0,0l-0.98-7.98
					c-0.06-0.48,0.28-0.92,0.76-0.98s0.92,0.28,0.98,0.76L539.13,392.68z"/>
              <path className="st3" d="M541.47,408.43l1.65,7.79l0.01,0.04c0.11,0.5-0.21,0.99-0.71,1.1s-0.99-0.21-1.1-0.71l0,0l-1.63-7.87
					c-0.1-0.49,0.21-0.97,0.71-1.08c0.49-0.1,0.97,0.21,1.08,0.71L541.47,408.43z"/>
              <path className="st3" d="M545.08,423.93l2.26,7.63l0.01,0.04c0.15,0.5-0.14,1.04-0.64,1.19c-0.5,0.15-1.04-0.14-1.19-0.64l0,0
					l-2.26-7.71c-0.15-0.5,0.14-1.02,0.64-1.17c0.5-0.15,1.02,0.14,1.17,0.64L545.08,423.93z"/>
              <path className="st3" d="M549.91,439.09c0.95,2.48,1.94,4.94,2.85,7.43l0.02,0.06c0.19,0.5-0.07,1.06-0.58,1.25
					c-0.5,0.18-1.05-0.07-1.24-0.56l-2.87-7.51c-0.19-0.5,0.06-1.06,0.56-1.25c0.5-0.19,1.06,0.06,1.25,0.56L549.91,439.09z"/>
              <path className="st3" d="M555.86,453.85l3.38,7.21l0.01,0.02c0.2,0.43,0.02,0.94-0.41,1.14c-0.41,0.19-0.9,0.03-1.12-0.37
					c-0.64-1.18-1.2-2.4-1.8-3.6l-1.78-3.61c-0.23-0.47-0.04-1.04,0.43-1.27c0.47-0.23,1.04-0.04,1.27,0.43c0,0,0,0.01,0.01,0.01
					L555.86,453.85z"/>
              <path className="st3" d="M562.99,468.1l1.01,1.73c0.35,0.57,0.64,1.18,1.11,1.67l0.02,0.02c0.27,0.28,0.26,0.73-0.02,1
					s-0.73,0.26-1-0.02c-0.07-0.08-0.13-0.17-0.16-0.27c-0.22-0.66-0.64-1.19-1-1.76l-1.11-1.7c-0.2-0.31-0.12-0.72,0.19-0.93
					c0.31-0.2,0.72-0.12,0.93,0.19c0.01,0.01,0.01,0.02,0.02,0.03L562.99,468.1z"/>
            </g>
          </g>
          <path className="st3" d="M565.61,475.68c-3.93-2.48-8.05-4.36-12.25-5.58c6.96-3.11,10.55-5.38,15.54-8.97
			c-0.35,4.98-1.32,9.83-2.85,14.3"/>
        </g>
        <g>
          <g>
            <g>
              <path className="st3" d="M378.47,411.71c0.67,0.06,1.33,0.17,1.99,0.29c0.66,0.12,1.32,0.21,1.98,0.36l3.94,0.82
					c0.48,0.1,0.79,0.57,0.69,1.05s-0.57,0.79-1.05,0.69l0,0l-0.04-0.01l-3.89-0.83c-1.3-0.28-2.59-0.57-3.86-1.01l-0.03-0.01
					c-0.36-0.12-0.55-0.52-0.43-0.88C377.87,411.86,378.16,411.68,378.47,411.71z"/>
              <path className="st3" d="M394.17,415.17l7.7,2.35c0.49,0.15,0.76,0.67,0.62,1.16c-0.15,0.49-0.67,0.76-1.16,0.62l0,0l-0.04-0.01
					l-7.61-2.36l-0.04-0.01c-0.48-0.15-0.75-0.66-0.6-1.13S393.69,415.03,394.17,415.17z"/>
              <path className="st3" d="M409.45,420.21l7.46,3.02c0.49,0.2,0.73,0.76,0.53,1.26c-0.2,0.49-0.76,0.73-1.26,0.53l0,0l-0.04-0.02
					l-7.37-3.02l-0.04-0.01c-0.48-0.2-0.71-0.75-0.52-1.23C408.42,420.24,408.97,420.01,409.45,420.21z"/>
              <path className="st3" d="M424.23,426.56l7.14,3.7c0.46,0.24,0.64,0.81,0.4,1.27c-0.24,0.46-0.8,0.64-1.26,0.41l-0.04-0.02
					l-7.11-3.59l-0.04-0.02c-0.48-0.24-0.68-0.83-0.43-1.31S423.74,426.32,424.23,426.56L424.23,426.56z"/>
              <path className="st3" d="M438.36,434.25l6.82,4.25c0.41,0.26,0.54,0.8,0.28,1.21c-0.25,0.41-0.79,0.53-1.2,0.28l-0.03-0.02
					l-6.79-4.18l-0.03-0.02c-0.42-0.26-0.55-0.81-0.29-1.24C437.38,434.12,437.93,433.99,438.36,434.25L438.36,434.25z"/>
              <path className="st3" d="M451.85,442.99l6.49,4.75c0.4,0.29,0.49,0.86,0.2,1.26c-0.29,0.4-0.86,0.49-1.26,0.2l0,0l-0.03-0.02
					l-6.42-4.73l-0.03-0.02c-0.4-0.29-0.48-0.85-0.19-1.24C450.9,442.79,451.46,442.7,451.85,442.99z"/>
              <path className="st3" d="M464.64,452.73l6.11,5.22c0.39,0.33,0.44,0.92,0.1,1.31c-0.33,0.39-0.92,0.44-1.31,0.1l0,0l-0.03-0.02
					l-6.04-5.2l-0.03-0.02c-0.38-0.33-0.43-0.91-0.1-1.29C463.68,452.44,464.26,452.4,464.64,452.73z"/>
              <path className="st3" d="M476.67,463.4l5.71,5.66c0.37,0.37,0.38,0.98,0.01,1.35c-0.37,0.37-0.98,0.38-1.35,0.01l0,0l-0.02-0.02
					l-5.64-5.63l-0.02-0.02c-0.37-0.37-0.37-0.96,0-1.33S476.3,463.03,476.67,463.4z"/>
              <path className="st3" d="M487.87,474.93c1.77,2.02,3.51,4.05,5.24,6.1c0.35,0.41,0.29,1.02-0.11,1.37
					c-0.41,0.34-1.01,0.29-1.36-0.11l-0.01-0.02l-5.19-6.04l-0.02-0.03c-0.35-0.41-0.3-1.02,0.1-1.37
					C486.91,474.49,487.52,474.53,487.87,474.93z"/>
              <path className="st3" d="M498.07,487.35l4.69,6.53c0.28,0.39,0.19,0.92-0.2,1.2c-0.37,0.27-0.89,0.19-1.17-0.16l-0.03-0.04
					c-0.83-1.04-1.59-2.12-2.39-3.18l-2.38-3.2l-0.03-0.04c-0.31-0.42-0.22-1,0.19-1.31c0.42-0.31,1-0.22,1.31,0.19
					C498.07,487.34,498.07,487.35,498.07,487.35z"/>
              <path className="st3" d="M507.19,500.57l1.49,2.42c0.52,0.79,0.93,1.65,1.58,2.36l0,0c0.26,0.28,0.24,0.73-0.04,0.99
					c-0.28,0.26-0.73,0.24-0.99-0.04c-0.06-0.07-0.11-0.15-0.14-0.23c-0.17-0.45-0.42-0.85-0.68-1.24l-0.79-1.17l-1.59-2.34l0-0.01
					c-0.22-0.32-0.13-0.75,0.18-0.97c0.32-0.22,0.75-0.13,0.97,0.18C507.18,500.56,507.19,500.57,507.19,500.57z"/>
            </g>
          </g>
          <path className="st3" d="M513.34,510.14c0.89-4.05,0.96-9.03,0.89-14.05c-4.83,2.9-12.21,7.18-15.92,9.93
			c4.76,2.13,9.72,3.62,14.6,4.41"/>
        </g>
        <g>
          <path className="st3" d="M507.13,564.92c-0.72,4.92-2.63,10.5-4.7,16.07c-3.69-5.37-9.39-13.43-12.02-18.15
			c5.62-0.26,11.19,0.27,16.4,1.57"/>
          <g>
            <g>
              <path className="st3" d="M375.27,645.8c0.92-0.98,1.94-1.84,2.98-2.69l3.09-2.55c0.38-0.31,0.94-0.26,1.25,0.12
					c0.31,0.38,0.26,0.94-0.12,1.25l0,0l-0.01,0.01l-3.09,2.53c-1.03,0.85-2.07,1.68-3.21,2.38l-0.01,0.01
					c-0.32,0.2-0.75,0.1-0.95-0.22C375.01,646.36,375.06,646.02,375.27,645.8z"/>
              <path className="st3" d="M387.58,635.55l6.32-4.93c0.41-0.32,0.99-0.24,1.31,0.16c0.32,0.41,0.24,0.99-0.16,1.31l0,0l-0.01,0.01
					l-6.33,4.88l-0.01,0.01c-0.4,0.31-0.97,0.23-1.28-0.17C387.11,636.42,387.18,635.85,387.58,635.55z"/>
              <path className="st3" d="M400.28,625.77l6.44-4.77c0.43-0.32,1.05-0.23,1.37,0.2s0.23,1.05-0.2,1.37l0,0l-0.01,0.01l-6.45,4.72
					l-0.01,0.01c-0.43,0.31-1.02,0.22-1.34-0.21C399.77,626.68,399.86,626.09,400.28,625.77z"/>
              <path className="st3" d="M413.25,616.36l6.59-4.55c0.41-0.28,0.97-0.18,1.26,0.23c0.28,0.41,0.18,0.97-0.22,1.25l-0.01,0.01
					l-6.52,4.61l-0.01,0.01c-0.43,0.3-1.02,0.2-1.32-0.23C412.71,617.27,412.81,616.67,413.25,616.36
					C413.24,616.37,413.25,616.37,413.25,616.36z"/>
              <path className="st3" d="M426.48,607.33l6.67-4.44c0.41-0.27,0.97-0.16,1.24,0.25c0.27,0.41,0.16,0.97-0.25,1.24l0,0l-0.01,0.01
					l-6.67,4.4l-0.01,0.01c-0.4,0.27-0.95,0.16-1.21-0.25C425.97,608.13,426.08,607.59,426.48,607.33z"/>
              <path className="st3" d="M439.87,598.52l6.79-4.26c0.43-0.27,1.01-0.14,1.28,0.29c0.27,0.43,0.14,1.01-0.29,1.28l0,0l-0.01,0.01
					l-6.78,4.22l-0.01,0.01c-0.43,0.27-0.99,0.14-1.25-0.29C439.32,599.35,439.44,598.79,439.87,598.52z"/>
              <path className="st3" d="M453.51,590.1l6.93-4.04c0.46-0.27,1.04-0.11,1.3,0.34c0.27,0.46,0.11,1.04-0.34,1.3l0,0l-0.02,0.01
					l-6.91,4l-0.01,0.01c-0.45,0.26-1.03,0.11-1.29-0.34C452.91,590.94,453.06,590.36,453.51,590.1z"/>
              <path className="st3" d="M467.45,582.17c2.37-1.24,4.73-2.5,7.12-3.71c0.48-0.24,1.06-0.05,1.3,0.43c0.24,0.48,0.05,1.05-0.42,1.3
					l-0.01,0l-7.08,3.67l-0.02,0.01c-0.47,0.25-1.06,0.06-1.3-0.41C466.8,583,466.98,582.42,467.45,582.17z"/>
              <path className="st3" d="M481.84,575.04l7.42-3.1c0.43-0.18,0.93,0.02,1.11,0.45c0.17,0.41-0.01,0.89-0.41,1.08l-0.06,0.03
					c-1.19,0.58-2.41,1.11-3.63,1.64l-3.64,1.6l-0.05,0.02c-0.48,0.21-1.03-0.01-1.24-0.48c-0.21-0.48,0.01-1.03,0.48-1.24
					C481.83,575.05,481.84,575.04,481.84,575.04z"/>
              <path className="st3" d="M496.84,569.23l1.71-0.51c0.57-0.17,1.15-0.31,1.68-0.61c0.34-0.19,0.77-0.07,0.96,0.27
					c0.19,0.34,0.07,0.77-0.27,0.96c-0.09,0.05-0.19,0.08-0.29,0.09l-0.02,0c-0.6,0.04-1.14,0.27-1.7,0.46l-1.66,0.59l-0.01,0
					c-0.34,0.12-0.72-0.06-0.84-0.4c-0.12-0.34,0.06-0.72,0.4-0.84C496.81,569.24,496.83,569.23,496.84,569.23z"/>
            </g>
          </g>
        </g>
        <g>
          <path className="st3" d="M616.59,599.17c-2.65,4.2-4.83,9.69-6.87,15.27c6.3-1.68,15.87-4.1,20.93-5.97
			c-4.11-3.85-8.68-7.06-13.48-9.47"/>
          <g>
            <g>
              <path className="st3" d="M620.01,607.61c0.58,1.22,0.98,2.5,1.38,3.77l1.21,3.83c0.15,0.47-0.11,0.97-0.58,1.12
					c-0.47,0.15-0.97-0.11-1.12-0.58l0-0.01l-0.01-0.03l-1.18-3.8c-0.4-1.27-0.79-2.53-1-3.86l0-0.03c-0.06-0.38,0.2-0.73,0.57-0.79
					C619.59,607.17,619.88,607.34,620.01,607.61z"/>
              <path className="st3" d="M624.76,622.94l1.92,7.79c0.12,0.5-0.18,1.01-0.69,1.14c-0.5,0.12-1.01-0.18-1.14-0.69l0-0.01l-0.01-0.03
					l-1.86-7.75l-0.01-0.02c-0.12-0.49,0.18-0.99,0.68-1.1C624.14,622.16,624.64,622.46,624.76,622.94z"/>
              <path className="st3" d="M628.4,638.58c0.5,2.63,1.04,5.25,1.52,7.88c0.1,0.53-0.26,1.04-0.78,1.13c-0.53,0.1-1.03-0.25-1.13-0.77
					l0-0.01l-1.49-7.84l0-0.02c-0.1-0.52,0.24-1.03,0.77-1.13C627.8,637.71,628.3,638.05,628.4,638.58z"/>
              <path className="st3" d="M631.2,654.37l1.11,7.94c0.07,0.48-0.27,0.92-0.74,0.99c-0.47,0.07-0.91-0.26-0.99-0.73l0-0.02l-1.2-7.89
					l0-0.02c-0.08-0.5,0.27-0.98,0.77-1.05c0.5-0.08,0.98,0.27,1.05,0.77C631.2,654.36,631.2,654.37,631.2,654.37z"/>
              <path className="st3" d="M633.33,670.27l0.86,7.97c0.05,0.5-0.31,0.95-0.81,1c-0.5,0.05-0.95-0.31-1-0.81l0,0l0-0.02l-0.82-7.94
					l0-0.02c-0.05-0.49,0.3-0.93,0.79-0.98C632.85,669.43,633.28,669.78,633.33,670.27z"/>
              <path className="st3" d="M634.89,686.23l0.51,8.01c0.03,0.52-0.36,0.97-0.88,1c-0.52,0.03-0.97-0.36-1-0.88l0,0l0-0.02l-0.48-7.97
					l0-0.02c-0.03-0.51,0.36-0.95,0.87-0.98C634.42,685.34,634.85,685.72,634.89,686.23z"/>
              <path className="st3" d="M635.7,702.26l0.09,8.03c0.01,0.54-0.42,0.97-0.96,0.98c-0.54,0.01-0.97-0.42-0.98-0.96v0l0-0.03
					l-0.06-7.97l0-0.03c0-0.53,0.42-0.96,0.95-0.96C635.27,701.31,635.7,701.73,635.7,702.26z"/>
              <path className="st3" d="M635.56,718.33c-0.2,2.68-0.43,5.35-0.66,8.02c-0.04,0.5-0.49,0.87-0.99,0.83
					c-0.49-0.04-0.86-0.47-0.83-0.96l0-0.03c0.17-2.65,0.34-5.29,0.55-7.94l0-0.06c0.04-0.53,0.51-0.93,1.04-0.89
					C635.2,717.33,635.6,717.8,635.56,718.33z"/>
              <path className="st3" d="M633.73,734.33c-0.22,1.16-0.45,2.32-0.72,3.48l-0.43,1.73c-0.14,0.58-0.35,1.13-0.38,1.74l0,0.02
					c-0.02,0.39-0.35,0.69-0.74,0.68s-0.69-0.35-0.68-0.74c0.01-0.12,0.04-0.23,0.1-0.33c0.3-0.52,0.38-1.1,0.5-1.66l0.33-1.71
					c0.2-1.14,0.37-2.3,0.52-3.45c0.06-0.42,0.44-0.71,0.85-0.65c0.42,0.06,0.71,0.44,0.65,0.85c0,0.01,0,0.02-0.01,0.03
					L633.73,734.33z"/>
            </g>
          </g>
        </g>
        <g>
          <path className="st3"
                d="M684.63,558.2c1.78,3.91,4.81,8.08,8,12.2c2.24-5.4,5.75-13.54,7.15-18.12c-5.31,1.2-10.39,3.03-14.97,5.41"
          />
          <g>
            <g>
              <path className="st3" d="M694.21,559.22c0.67,0.1,1.32,0.27,1.97,0.46c0.65,0.18,1.3,0.35,1.94,0.58c1.27,0.43,2.54,0.89,3.79,1.38
					c0.46,0.18,0.69,0.69,0.51,1.15s-0.69,0.69-1.15,0.51l-0.01,0c-1.23-0.48-2.47-0.94-3.72-1.37c-1.25-0.44-2.5-0.85-3.72-1.38
					c-0.35-0.15-0.51-0.56-0.36-0.91C693.59,559.34,693.91,559.17,694.21,559.22z"/>
              <path className="st3" d="M709.33,564.75l7.25,3.47c0.46,0.22,0.66,0.77,0.44,1.24s-0.77,0.66-1.24,0.44l0,0l-0.04-0.02l-7.16-3.47
					l-0.03-0.02c-0.45-0.22-0.64-0.76-0.42-1.21C708.34,564.72,708.88,564.53,709.33,564.75z"/>
              <path className="st3" d="M723.69,571.95l6.98,3.96c0.46,0.26,0.63,0.85,0.36,1.32c-0.26,0.46-0.85,0.63-1.32,0.36l0,0l-0.03-0.02
					l-6.92-3.97l-0.02-0.01c-0.45-0.26-0.61-0.84-0.35-1.29C722.66,571.85,723.24,571.69,723.69,571.95z"/>
              <path className="st3" d="M737.54,580.07l6.72,4.39c0.43,0.28,0.55,0.86,0.27,1.29c-0.28,0.43-0.85,0.55-1.29,0.28l-0.02-0.01
					l-6.73-4.3l-0.02-0.01c-0.45-0.29-0.59-0.89-0.3-1.35C736.47,579.91,737.07,579.78,737.54,580.07L737.54,580.07z"/>
              <path className="st3" d="M750.86,589.02l6.5,4.7c0.39,0.28,0.48,0.83,0.2,1.23c-0.28,0.39-0.83,0.48-1.23,0.2l-0.02-0.01l-6.48-4.65
					l-0.02-0.01c-0.4-0.29-0.49-0.85-0.21-1.25C749.9,588.83,750.46,588.73,750.86,589.02L750.86,589.02z"/>
              <path className="st3" d="M763.76,598.56l6.27,5.01c0.39,0.31,0.45,0.88,0.14,1.27s-0.88,0.45-1.27,0.14l0,0l-0.02-0.02l-6.22-5
					l-0.02-0.01c-0.38-0.31-0.44-0.87-0.14-1.25C762.82,598.32,763.38,598.25,763.76,598.56z"/>
              <path className="st3" d="M776.17,608.74l5.98,5.36c0.38,0.34,0.42,0.93,0.07,1.32c-0.34,0.38-0.93,0.42-1.32,0.07l0,0l-0.02-0.02
					l-5.92-5.34l-0.02-0.02c-0.38-0.34-0.41-0.92-0.07-1.3C775.21,608.44,775.79,608.4,776.17,608.74z"/>
              <path className="st3" d="M787.97,619.65l5.61,5.75c0.37,0.38,0.36,0.99-0.02,1.36c-0.38,0.37-0.99,0.36-1.36-0.02l0,0l-0.03-0.03
					l-5.55-5.72l-0.02-0.02c-0.36-0.37-0.35-0.97,0.02-1.34C787.01,619.27,787.6,619.28,787.97,619.65z"/>
              <path className="st3" d="M798.97,631.38c1.71,2.07,3.37,4.18,5.06,6.26c0.34,0.41,0.27,1.02-0.14,1.36
					c-0.41,0.34-1.02,0.27-1.36-0.14l-0.02-0.03l-5.01-6.18l-0.03-0.04c-0.34-0.42-0.27-1.03,0.14-1.37
					C798.02,630.91,798.63,630.97,798.97,631.38z"/>
              <path className="st3" d="M808.7,644.2l4.22,6.86c0.24,0.38,0.12,0.88-0.27,1.12c-0.37,0.23-0.86,0.12-1.1-0.24l-0.04-0.05
					c-0.73-1.1-1.5-2.18-2.2-3.3l-2.14-3.34l-0.04-0.06c-0.27-0.43-0.15-1,0.28-1.27c0.43-0.27,1-0.15,1.27,0.28
					C808.69,644.19,808.69,644.2,808.7,644.2z"/>
              <path className="st3" d="M816.6,658.24l0.64,1.48c0.21,0.5,0.43,0.99,0.74,1.44c0.22,0.32,0.15,0.75-0.17,0.98s-0.75,0.15-0.98-0.17
					c-0.05-0.08-0.09-0.16-0.11-0.25l0-0.02c-0.12-0.53-0.34-1.01-0.58-1.48l-0.7-1.43l-0.01-0.01c-0.15-0.32-0.02-0.7,0.29-0.85
					c0.32-0.16,0.7-0.02,0.85,0.29C816.59,658.22,816.6,658.23,816.6,658.24z"/>
            </g>
          </g>
        </g>
        <g>
          <path className="st3"
                d="M699.63,525.5c2.95-4,7.25-8.03,11.7-11.98c0.73,6.48,1.98,16.27,2.09,21.67c-5.09-2.42-9.75-5.5-13.74-9.09"
          />
          <g>
            <g>
              <path className="st3" d="M707.79,524.09c1.31-0.03,2.61,0,3.92,0c1.31,0,2.63-0.06,3.94-0.14c0.49-0.03,0.91,0.34,0.94,0.83
					s-0.34,0.91-0.83,0.94l-0.01,0c-1.35,0.08-2.7,0.12-4.05,0.12c-1.35,0-2.72-0.07-4.07-0.39c-0.37-0.09-0.6-0.46-0.51-0.83
					C707.2,524.31,707.48,524.1,707.79,524.09L707.79,524.09z"/>
              <path className="st3" d="M723.54,523.14l7.86-1.25l0.03-0.01c0.51-0.08,0.99,0.27,1.07,0.78c0.08,0.51-0.27,0.99-0.78,1.07l-0.01,0
					l-7.95,1.22c-0.5,0.08-0.96-0.27-1.04-0.76c-0.08-0.5,0.26-0.96,0.76-1.04L723.54,523.14z"/>
              <path className="st3" d="M739.22,520.35l7.78-1.75l0.02,0c0.53-0.12,1.05,0.21,1.17,0.74c0.12,0.53-0.21,1.05-0.74,1.17l-0.01,0
					l-7.84,1.71c-0.52,0.11-1.03-0.21-1.14-0.73c-0.11-0.51,0.21-1.02,0.73-1.14L739.22,520.35z"/>
              <path className="st3" d="M754.77,516.74l7.73-1.99l0.02,0c0.48-0.12,0.97,0.17,1.1,0.65c0.12,0.48-0.16,0.97-0.64,1.09l-7.74,2.09
					c-0.5,0.14-1.02-0.16-1.16-0.67c-0.14-0.5,0.16-1.02,0.67-1.16c0,0,0,0,0.01,0L754.77,516.74z"/>
              <path className="st3" d="M770.19,512.6l7.64-2.31l0.01,0c0.47-0.14,0.97,0.12,1.12,0.6c0.14,0.47-0.12,0.97-0.6,1.12l0,0l-7.68,2.29
					c-0.46,0.14-0.95-0.13-1.09-0.59s0.13-0.95,0.59-1.09L770.19,512.6z"/>
              <path className="st3" d="M785.43,507.85l7.56-2.57l0.01-0.01c0.49-0.17,1.01,0.09,1.18,0.58c0.17,0.49-0.09,1.01-0.58,1.18l0,0
					l-7.6,2.55c-0.48,0.16-0.99-0.1-1.15-0.58s0.1-0.99,0.58-1.16L785.43,507.85z"/>
              <path className="st3" d="M800.51,502.58l7.45-2.84l0.02-0.01c0.49-0.19,1.05,0.06,1.23,0.55s-0.06,1.05-0.55,1.23l0,0l-7.5,2.83
					c-0.49,0.18-1.03-0.06-1.22-0.55c-0.18-0.49,0.06-1.03,0.55-1.22L800.51,502.58z"/>
              <path className="st3" d="M815.34,496.72c2.43-1.08,4.86-2.14,7.31-3.18l0.05-0.02c0.49-0.21,1.06,0.02,1.27,0.52
					c0.21,0.49-0.02,1.05-0.51,1.27l-7.37,3.2c-0.49,0.21-1.06-0.01-1.27-0.5c-0.21-0.49,0.01-1.05,0.49-1.27L815.34,496.72z"/>
              <path className="st3" d="M829.86,490.17l7.02-3.72l0.05-0.03c0.4-0.21,0.89-0.06,1.1,0.34c0.2,0.39,0.07,0.86-0.31,1.08
					c-1.16,0.68-2.3,1.39-3.48,2.03l-3.53,1.96c-0.45,0.25-1.01,0.09-1.26-0.36s-0.09-1.01,0.36-1.26c0,0,0.01,0,0.01-0.01
					L829.86,490.17z"/>
              <path className="st3" d="M843.5,482.09c0.5-0.42,1.04-0.79,1.44-1.31l0.03-0.04c0.24-0.31,0.68-0.37,0.99-0.14s0.37,0.68,0.14,0.99
					c-0.07,0.09-0.15,0.16-0.24,0.2c-0.62,0.32-1.08,0.81-1.58,1.26c-0.26,0.22-0.65,0.2-0.87-0.06s-0.2-0.65,0.06-0.87
					c0,0,0,0,0.01,0L843.5,482.09z"/>
            </g>
          </g>
        </g>
        <path className="st12" d="M588.59,226c0.51,3.88,1,16.41-0.62,16.38c-24.09-0.47-52.64,0.01-76.28-1.87
		c-0.3-9.55,0.31-15.88,0.31-15.88C516.41,224.93,588.71,226.82,588.59,226z"/>
        <g>
          <path className="st12" d="M585.98,265.23c-0.16,0.11-0.31,0.33-0.35,0.53c-0.03,0.14-0.02,0.1-0.02,0.09l0-0.03l-0.01-0.1l-0.02-0.21
			c-0.01-0.14-0.03-0.28-0.04-0.41l-0.04-0.83c0.01-0.55-0.01-1.1,0.01-1.66c0.03-1.1,0.05-2.21,0.05-3.32
			c0.01-2.21-0.03-4.43-0.12-6.64l1.26,1.33c-5.86-0.46-11.75-0.7-17.65-0.83l-17.73-0.29c-5.92-0.1-11.84-0.18-17.77-0.2
			l-2.22-0.03l-1.11-0.01l-0.28,0l-0.07,0c0.01,0-0.14,0,0.06,0c0.27,0,0.56-0.18,0.62-0.23c0.12-0.08,0.21-0.22,0.22-0.21
			c0.02-0.02,0.05-0.09,0.05-0.08c0,0.02,0,0-0.02,0.07c-0.12,0.41-0.2,1.18-0.24,1.85c-0.04,0.7-0.06,1.42-0.07,2.14
			c0,2.88,0.12,5.87,0.42,8.67c0.09,0.81-0.5,1.54-1.31,1.63c-0.78,0.08-1.49-0.46-1.62-1.22l-0.01-0.05
			c-0.06-0.32,0.06-0.83,0.32-1.13c0.34-0.36,0.2-0.19,0.33-0.3c0.07-0.05,0.14-0.09,0.21-0.12c0.09-0.04,0.13-0.06,0.14-0.06
			c0.08-0.03,0.21-0.07,0.24-0.07c0.1-0.02,0.17-0.03,0.23-0.04l0.3-0.04l0.5-0.03l0.93-0.04l1.82-0.03l3.6-0.01l7.17,0.06
			l14.32,0.25c4.77,0.17,9.54,0.38,14.3,0.66l7.15,0.41l3.58,0.2l1.79,0.1c0.3,0.02,0.6,0.04,0.9,0.05c0.15-0.01,0.3,0.01,0.45-0.01
			c0.08-0.01,0.15-0.01,0.23-0.03l0.12-0.02c0,0,0.04,0,0.01-0.01c-0.07-0.01-0.14,0-0.22,0.01
			C586.24,265.05,586.08,265.14,585.98,265.23z M587.32,266.8c-0.14,0.09-0.33,0.18-0.52,0.2c-0.1,0.01-0.19,0.02-0.27,0.01
			c-0.05-0.01-0.03-0.01-0.05-0.01l-0.1-0.04c-0.07-0.02-0.14-0.03-0.21-0.05c-0.14-0.04-0.29-0.03-0.44-0.05
			c-0.3-0.01-0.59,0-0.89,0l-1.79,0.02l-3.58,0.1c-2.39,0.08-4.77,0.1-7.15,0.04c-4.77-0.07-9.53-0.3-14.3-0.44l-14.3-0.32
			l-7.14-0.1l-3.56-0.01l-1.77,0.02l-0.86,0.03l-0.4,0.02l-0.15,0.02c-0.02,0-0.03,0,0,0c-0.01,0,0.07-0.02,0.11-0.03
			c-0.02,0.01,0.18-0.07,0.26-0.14c0.11-0.09-0.03,0.06,0.27-0.25c0.22-0.25,0.32-0.69,0.27-0.96l-2.58,0.32
			c-0.31-3.07-0.4-6.01-0.38-9.04c0.01-0.76,0.03-1.52,0.09-2.29c0.07-0.79,0.1-1.52,0.38-2.49c0.03-0.11,0.1-0.28,0.17-0.43
			c0.03-0.08,0.11-0.2,0.18-0.3c0.04-0.08,0.19-0.25,0.37-0.38c0.12-0.11,0.47-0.28,0.79-0.3c0.22-0.02,0.13,0,0.19-0.01l0.07,0
			l0.28,0.01l1.11,0.02l2.22,0.04c5.91,0.12,11.83,0.09,17.75,0.07l8.89,0.01c2.96,0.05,5.93,0.12,8.9,0.2
			c5.93,0.18,11.87,0.48,17.81,1c0.68,0.06,1.2,0.62,1.22,1.29c0.07,2.25,0.09,4.5,0.06,6.75c-0.02,1.13-0.04,2.25-0.08,3.38
			c-0.01,0.56-0.08,1.13-0.12,1.69l-0.13,0.84c-0.02,0.14-0.06,0.28-0.09,0.42l-0.05,0.21l-0.03,0.11l-0.01,0.03
			c0,0.01,0-0.02-0.02,0.15C587.63,266.4,587.48,266.65,587.32,266.8z"/>
        </g>
        <g>
          <path className="st12" d="M584.7,289.72c-0.13,0.08-0.26,0.22-0.32,0.37c-0.03,0.06-0.06,0.19-0.06,0.19l0-0.03l-0.01-0.12l0-0.24
			c0-0.16-0.01-0.32,0-0.48c0.02-0.32,0-0.64,0.04-0.96c0.04-0.64,0.09-1.27,0.16-1.91c0.12-1.27,0.22-2.54,0.29-3.81
			c0.15-2.54,0.17-5.09,0.09-7.62l1.39,1.34c-4.81,0.03-9.6-0.05-14.38-0.15c-4.78-0.12-9.57-0.17-14.34-0.36
			c-4.77-0.16-9.54-0.31-14.3-0.36c-4.75-0.03-9.53,0.07-14.16,0.55l1.26-1.47c0.07,2.64-0.2,5.12-0.42,7.59
			c-0.11,1.23-0.23,2.45-0.31,3.66c-0.08,1.19-0.12,2.41-0.01,3.43c0.09,0.82-0.5,1.55-1.32,1.64c-0.78,0.08-1.49-0.46-1.63-1.22
			l-0.01-0.05c-0.06-0.33,0.06-0.83,0.32-1.13c0.34-0.36,0.2-0.19,0.33-0.3c0.07-0.05,0.14-0.09,0.21-0.12
			c0.09-0.04,0.13-0.06,0.14-0.06c0.08-0.03,0.21-0.07,0.24-0.07c0.1-0.02,0.17-0.03,0.23-0.04l0.3-0.03l0.5-0.03l0.93-0.04
			l1.82-0.03l3.6-0.01l7.17,0.06c4.78,0.07,9.55,0.13,14.32,0.26l7.15,0.28l7.15,0.38l7.15,0.41l3.58,0.19l1.79,0.1
			c0.3,0.01,0.6,0.04,0.9,0.05c0.15,0,0.3,0.01,0.45-0.01c0.08-0.01,0.15-0.01,0.23-0.03l0.12-0.02c0,0,0.04,0,0.01-0.01
			c-0.06-0.01-0.13,0-0.21,0.01C584.95,289.56,584.8,289.63,584.7,289.72z M585.98,291.34c-0.14,0.09-0.32,0.16-0.5,0.18
			c-0.09,0.01-0.18,0.01-0.26,0.01c-0.05-0.01-0.03-0.01-0.05-0.01l-0.1-0.04c-0.07-0.02-0.14-0.03-0.21-0.05
			c-0.14-0.04-0.29-0.03-0.44-0.05c-0.3-0.02-0.59,0-0.89,0l-1.79,0.02c-1.19,0.01-2.39,0.07-3.58,0.1
			c-2.39,0.07-4.77,0.1-7.15,0.05c-4.77-0.06-9.53-0.29-14.3-0.43l-14.3-0.32l-7.14-0.1l-3.56-0.01l-1.77,0.02l-0.86,0.03l-0.4,0.02
			l-0.15,0.02c-0.02,0-0.03,0,0,0c-0.01,0,0.07-0.02,0.11-0.03c-0.02,0.01,0.18-0.07,0.26-0.14c0.11-0.1-0.03,0.06,0.27-0.25
			c0.22-0.25,0.32-0.69,0.27-0.97l-2.59,0.32c-0.14-1.42-0.06-2.68,0.02-3.95c0.09-1.26,0.22-2.5,0.34-3.73
			c0.24-2.45,0.51-4.91,0.45-7.24l0-0.03c-0.01-0.65,0.48-1.2,1.12-1.27c4.87-0.5,9.67-0.49,14.48-0.53
			c4.8-0.03,9.59,0.04,14.37,0.12c4.79,0.07,9.56,0.28,14.34,0.4c4.77,0.14,9.55,0.26,14.3,0.27h0.01c0.72,0,1.31,0.58,1.33,1.3
			c0.07,2.63,0.02,5.26-0.16,7.87c-0.08,1.31-0.19,2.61-0.33,3.92c-0.06,0.65-0.15,1.3-0.26,1.95c-0.05,0.32-0.14,0.64-0.21,0.97
			c-0.03,0.16-0.08,0.32-0.13,0.48l-0.07,0.24l-0.04,0.12l-0.01,0.03c0,0.02-0.03,0.17-0.07,0.25
			C586.25,291.04,586.11,291.22,585.98,291.34z"/>
        </g>
        <path className="st12" d="M917.86,691.37c0.51,3.88,1,16.41-0.62,16.38c-24.09-0.47-52.64,0.01-76.28-1.87
		c-0.3-9.55,0.31-15.88,0.31-15.88C845.68,690.3,917.98,692.18,917.86,691.37z"/>
        <g>
          <path className="st12" d="M915.25,730.6c-0.16,0.11-0.31,0.33-0.35,0.53c-0.03,0.14-0.02,0.1-0.02,0.09l0-0.03l-0.01-0.1l-0.02-0.21
			c-0.01-0.14-0.03-0.28-0.04-0.41l-0.04-0.83c0.01-0.55-0.01-1.1,0.01-1.66c0.03-1.1,0.05-2.21,0.05-3.32
			c0.01-2.21-0.03-4.43-0.12-6.64l1.26,1.33c-5.86-0.46-11.75-0.7-17.65-0.83l-17.73-0.29c-5.92-0.1-11.84-0.18-17.77-0.2
			l-2.22-0.03l-1.11-0.01l-0.28,0l-0.07,0c0.01,0-0.14,0,0.06,0c0.27,0,0.56-0.18,0.62-0.23c0.12-0.08,0.21-0.22,0.22-0.21
			c0.02-0.02,0.05-0.09,0.05-0.08c0,0.02,0,0-0.02,0.07c-0.12,0.41-0.2,1.18-0.24,1.85c-0.04,0.7-0.06,1.42-0.07,2.14
			c0,2.88,0.12,5.87,0.42,8.67c0.09,0.81-0.5,1.54-1.31,1.63c-0.78,0.08-1.49-0.46-1.62-1.22l-0.01-0.05
			c-0.06-0.32,0.06-0.83,0.32-1.13c0.34-0.36,0.2-0.19,0.33-0.3c0.07-0.05,0.14-0.09,0.21-0.12c0.09-0.04,0.13-0.06,0.14-0.06
			c0.08-0.03,0.21-0.07,0.24-0.07c0.1-0.02,0.17-0.03,0.23-0.04l0.3-0.04l0.5-0.03l0.93-0.04l1.82-0.03l3.6-0.01l7.17,0.06
			l14.32,0.25c4.77,0.17,9.54,0.38,14.3,0.66l7.15,0.41l3.58,0.2l1.79,0.1c0.3,0.02,0.6,0.04,0.9,0.05c0.15-0.01,0.3,0.01,0.45-0.01
			c0.08-0.01,0.15-0.01,0.23-0.03l0.12-0.02c0,0,0.04,0,0.01-0.01c-0.07-0.01-0.14,0-0.22,0.01
			C915.5,730.42,915.35,730.5,915.25,730.6z M916.59,732.16c-0.14,0.09-0.33,0.18-0.52,0.2c-0.1,0.01-0.19,0.02-0.27,0.01
			c-0.05-0.01-0.03-0.01-0.05-0.01l-0.1-0.04c-0.07-0.02-0.14-0.03-0.21-0.05c-0.14-0.04-0.29-0.03-0.44-0.05
			c-0.3-0.01-0.59,0-0.89,0l-1.79,0.02l-3.58,0.1c-2.39,0.08-4.77,0.1-7.15,0.04c-4.77-0.07-9.53-0.3-14.3-0.44l-14.3-0.32
			l-7.14-0.1l-3.56-0.01l-1.77,0.02l-0.86,0.03l-0.4,0.02l-0.15,0.02c-0.02,0-0.03,0,0,0c-0.01,0,0.07-0.02,0.11-0.03
			c-0.02,0.01,0.18-0.07,0.26-0.14c0.11-0.09-0.03,0.06,0.27-0.25c0.22-0.25,0.32-0.69,0.27-0.96l-2.58,0.32
			c-0.31-3.07-0.4-6.01-0.38-9.04c0.01-0.76,0.03-1.52,0.09-2.29c0.07-0.79,0.1-1.52,0.38-2.49c0.03-0.11,0.1-0.28,0.17-0.43
			c0.03-0.08,0.11-0.2,0.18-0.3c0.04-0.08,0.19-0.25,0.37-0.38c0.12-0.11,0.47-0.28,0.79-0.3c0.22-0.02,0.13,0,0.19-0.01l0.07,0
			l0.28,0.01l1.11,0.02l2.22,0.04c5.91,0.12,11.83,0.09,17.75,0.07l8.89,0.01c2.96,0.05,5.93,0.12,8.9,0.2
			c5.93,0.18,11.87,0.48,17.81,1c0.68,0.06,1.2,0.62,1.22,1.29c0.07,2.25,0.09,4.5,0.06,6.75c-0.02,1.13-0.04,2.25-0.08,3.38
			c-0.01,0.56-0.08,1.13-0.12,1.69l-0.13,0.84c-0.02,0.14-0.06,0.28-0.09,0.42l-0.05,0.21l-0.03,0.11l-0.01,0.03
			c0,0.01,0-0.02-0.02,0.15C916.9,731.77,916.75,732.02,916.59,732.16z"/>
        </g>
        <g>
          <path className="st12" d="M913.97,755.09c-0.13,0.08-0.26,0.22-0.32,0.37c-0.03,0.06-0.06,0.19-0.06,0.19l0-0.03l-0.01-0.12l0-0.24
			c0-0.16-0.01-0.32,0-0.48c0.02-0.32,0-0.64,0.04-0.96c0.04-0.64,0.09-1.27,0.16-1.91c0.12-1.27,0.22-2.54,0.29-3.81
			c0.15-2.54,0.17-5.09,0.09-7.62l1.39,1.34c-4.81,0.03-9.6-0.05-14.38-0.15c-4.78-0.12-9.57-0.17-14.34-0.36
			c-4.77-0.16-9.54-0.31-14.3-0.36c-4.75-0.03-9.53,0.07-14.16,0.55l1.26-1.47c0.07,2.64-0.2,5.12-0.42,7.59
			c-0.11,1.23-0.23,2.45-0.31,3.66c-0.08,1.19-0.12,2.41-0.01,3.43c0.09,0.82-0.5,1.55-1.32,1.64c-0.78,0.08-1.49-0.46-1.63-1.22
			l-0.01-0.05c-0.06-0.33,0.06-0.83,0.32-1.13c0.34-0.36,0.2-0.19,0.33-0.3c0.07-0.05,0.14-0.09,0.21-0.12
			c0.09-0.04,0.13-0.06,0.14-0.06c0.08-0.03,0.21-0.07,0.24-0.07c0.1-0.02,0.17-0.03,0.23-0.04l0.3-0.03l0.5-0.03l0.93-0.04
			l1.82-0.03l3.6-0.01l7.17,0.06c4.78,0.07,9.55,0.13,14.32,0.26l7.15,0.28l7.15,0.38l7.15,0.41l3.58,0.19l1.79,0.1
			c0.3,0.01,0.6,0.04,0.9,0.05c0.15,0,0.3,0.01,0.45-0.01c0.08-0.01,0.15-0.01,0.23-0.03l0.12-0.02c0,0,0.04,0,0.01-0.01
			c-0.06-0.01-0.13,0-0.21,0.01C914.22,754.92,914.07,755,913.97,755.09z M915.25,756.7c-0.14,0.09-0.32,0.16-0.5,0.18
			c-0.09,0.01-0.18,0.01-0.26,0.01c-0.05-0.01-0.03-0.01-0.05-0.01l-0.1-0.04c-0.07-0.02-0.14-0.03-0.21-0.05
			c-0.14-0.04-0.29-0.03-0.44-0.05c-0.3-0.02-0.59,0-0.89,0l-1.79,0.02c-1.19,0.01-2.39,0.07-3.58,0.1
			c-2.39,0.07-4.77,0.1-7.15,0.05c-4.77-0.06-9.53-0.29-14.3-0.43l-14.3-0.32l-7.14-0.1l-3.56-0.01l-1.77,0.02l-0.86,0.03l-0.4,0.02
			l-0.15,0.02c-0.02,0-0.03,0,0,0c-0.01,0,0.07-0.02,0.11-0.03c-0.02,0.01,0.18-0.07,0.26-0.14c0.11-0.1-0.03,0.06,0.27-0.25
			c0.22-0.25,0.32-0.69,0.27-0.97l-2.59,0.32c-0.14-1.42-0.06-2.68,0.02-3.95c0.09-1.26,0.22-2.5,0.34-3.73
			c0.24-2.45,0.51-4.91,0.45-7.24l0-0.03c-0.01-0.65,0.48-1.2,1.12-1.27c4.87-0.5,9.67-0.49,14.48-0.53
			c4.8-0.03,9.59,0.04,14.37,0.12c4.79,0.07,9.56,0.28,14.34,0.4c4.77,0.14,9.55,0.26,14.3,0.27h0.01c0.72,0,1.31,0.58,1.33,1.3
			c0.07,2.63,0.02,5.26-0.16,7.87c-0.08,1.31-0.19,2.61-0.33,3.92c-0.06,0.65-0.15,1.3-0.26,1.95c-0.05,0.32-0.14,0.64-0.21,0.97
			c-0.03,0.16-0.08,0.32-0.13,0.48l-0.07,0.24l-0.04,0.12l-0.01,0.03c0,0.02-0.03,0.17-0.07,0.25
			C915.52,756.4,915.38,756.58,915.25,756.7z"/>
        </g>
        <g>
          <g>
            <path className="st3" d="M761.74,153.11c0.09-0.32,0.31-0.49,0.51-0.66l0.62-0.53l1.24-1.06c0.82-0.71,1.68-1.38,2.52-2.07
				c1.69-1.37,3.5-2.58,5.14-4.01l5.05-4.12c0.83-0.7,1.7-1.35,2.63-1.93l1.38-0.88c0.23-0.15,0.46-0.3,0.68-0.45l0.34-0.23
				c0.1-0.09,0.27-0.1,0.27-0.31l0.86,1.07c-0.22-0.05-0.25,0.13-0.35,0.21c-0.09,0.1-0.19,0.2-0.27,0.31l-0.53,0.65
				c-0.36,0.41-0.78,0.76-1.2,1.1c-0.87,0.66-1.75,1.29-2.6,1.97l-5.14,4.02c-1.74,1.3-3.34,2.78-5.04,4.14
				c-0.85,0.67-1.68,1.37-2.55,2.03l-1.29,0.99l-0.65,0.5c-0.22,0.16-0.42,0.34-0.76,0.36L761.74,153.11z"/>
          </g>
          <g>
            <path className="st3" d="M764.7,137.85c0.31,0.02,0.47,0.19,0.65,0.34l0.53,0.47l1.06,0.93c0.72,0.62,1.39,1.28,2.08,1.91
				c1.38,1.29,2.62,2.72,4.05,3.96l4.15,3.85c0.7,0.63,1.37,1.3,1.96,2.05l0.91,1.1c0.15,0.19,0.3,0.37,0.46,0.54l0.23,0.27
				c0.09,0.07,0.11,0.23,0.31,0.19l-0.95,0.99c0.04-0.21-0.13-0.2-0.22-0.29c-0.1-0.06-0.2-0.13-0.31-0.19l-0.64-0.36
				c-0.41-0.26-0.76-0.57-1.1-0.9c-0.67-0.67-1.3-1.37-1.99-2.01l-4.05-3.95c-1.32-1.35-2.79-2.54-4.16-3.83
				c-0.68-0.65-1.38-1.28-2.04-1.96l-1-1l-0.5-0.5c-0.17-0.16-0.35-0.32-0.38-0.63L764.7,137.85z"/>
          </g>
        </g>
        <g>
          <g>
            <path className="st21" d="M308.44,242.81c-0.4,0.07-0.56-0.13-0.75-0.28l-0.58-0.47l-1.16-0.92c-0.79-0.6-1.52-1.27-2.28-1.89
				c-0.78-0.61-1.49-1.3-2.2-2c-0.72-0.69-1.52-1.28-2.3-1.91c-1.55-1.26-3.16-2.47-4.66-3.81c-0.77-0.65-1.5-1.34-2.11-2.16
				l-0.95-1.2l-0.47-0.6l-0.25-0.28c-0.11-0.07-0.1-0.26-0.36-0.16c-0.42-0.38-0.45-1.03-0.06-1.45c0.38-0.42,1.03-0.45,1.45-0.06
				c-0.09,0.28,0.13,0.23,0.22,0.31c0.12,0.06,0.23,0.12,0.35,0.17c0.25,0.1,0.49,0.2,0.73,0.3c0.48,0.21,0.84,0.55,1.22,0.87
				c0.7,0.7,1.39,1.42,2.14,2.07c1.46,1.34,2.94,2.65,4.47,3.94c0.76,0.64,1.5,1.31,2.31,1.9c0.82,0.58,1.63,1.17,2.39,1.84
				c0.76,0.66,1.54,1.29,2.27,1.99l1.09,1.04l0.54,0.52c0.18,0.18,0.39,0.32,0.36,0.72C309.77,242.07,309.17,242.69,308.44,242.81z"
            />
          </g>
          <g>
            <path className="st21" d="M240.15,184.81c-0.38,0.08-0.53-0.1-0.72-0.24l-0.55-0.43l-1.1-0.86c-0.74-0.57-1.42-1.21-2.13-1.82
				c-0.72-0.6-1.35-1.29-1.97-2c-0.62-0.7-1.34-1.3-2.01-1.94c-1.35-1.28-2.73-2.5-4.05-3.77c-0.68-0.61-1.32-1.26-1.87-2.02
				l-0.86-1.11l-0.43-0.55c-0.07-0.09-0.15-0.17-0.23-0.26c-0.1-0.06-0.09-0.25-0.34-0.14c-0.43-0.37-0.47-1.02-0.1-1.45
				s1.02-0.47,1.45-0.1c-0.09,0.28,0.12,0.22,0.21,0.3c0.11,0.05,0.22,0.11,0.33,0.15c0.24,0.08,0.47,0.17,0.7,0.26
				c0.46,0.18,0.8,0.51,1.15,0.81c0.66,0.67,1.29,1.36,1.97,2l3.93,3.96c0.66,0.65,1.29,1.32,2,1.91c0.72,0.57,1.44,1.15,2.1,1.79
				c0.67,0.62,1.38,1.21,2.04,1.86l1,0.95l0.5,0.48c0.17,0.16,0.37,0.28,0.33,0.68l0,0.03
				C241.42,184.05,240.86,184.65,240.15,184.81z"/>
          </g>
          <g>
            <path className="st21" d="M291.99,175.9c-0.12-0.35,0.03-0.49,0.14-0.65l0.34-0.49l0.68-0.98c0.44-0.66,0.95-1.26,1.41-1.9
				c0.45-0.65,0.99-1.23,1.53-1.8c0.53-0.58,0.95-1.25,1.41-1.9c0.92-1.29,1.77-2.66,2.77-3.93c0.47-0.65,1-1.27,1.66-1.77
				l0.95-0.78l0.48-0.39c0.08-0.06,0.15-0.14,0.22-0.21c0.05-0.09,0.22-0.08,0.1-0.31c0.36-0.44,1.01-0.5,1.45-0.14
				c0.44,0.36,0.5,1.01,0.14,1.45c-0.27-0.09-0.19,0.1-0.25,0.18c-0.03,0.1-0.08,0.19-0.1,0.3c-0.05,0.21-0.11,0.42-0.17,0.63
				c-0.12,0.41-0.38,0.71-0.61,1.03c-0.53,0.58-1.08,1.15-1.56,1.78c-1,1.22-1.96,2.47-2.91,3.75c-0.48,0.64-0.99,1.26-1.42,1.94
				c-0.42,0.69-0.86,1.37-1.36,1.99c-0.5,0.63-0.97,1.28-1.5,1.88l-0.79,0.9l-0.4,0.45c-0.14,0.15-0.23,0.33-0.61,0.28l-0.01,0
				C292.82,177.1,292.22,176.58,291.99,175.9z"/>
          </g>
          <g>
            <path className="st21" d="M228.49,244.68c-0.14-0.33,0-0.44,0.09-0.58l0.29-0.42l0.59-0.84c0.39-0.57,0.85-1.08,1.29-1.62
				c0.42-0.55,0.95-1.02,1.48-1.47c0.53-0.45,0.95-0.99,1.4-1.48c0.89-0.98,1.69-2.05,2.57-3.06c0.42-0.52,0.88-1.01,1.47-1.4
				l0.84-0.62l0.42-0.31c0.07-0.05,0.13-0.11,0.19-0.17c0.04-0.08,0.2-0.05,0.07-0.27c0.36-0.44,1.01-0.5,1.45-0.14
				c0.44,0.36,0.5,1.01,0.14,1.45c-0.26-0.1-0.17,0.08-0.23,0.14c-0.03,0.09-0.06,0.17-0.08,0.27c-0.03,0.19-0.07,0.38-0.12,0.57
				c-0.09,0.37-0.32,0.62-0.53,0.89c-0.48,0.49-0.98,0.96-1.42,1.48c-0.93,1.01-1.87,2.02-2.83,3.02c-0.48,0.5-0.98,0.96-1.39,1.49
				c-0.4,0.54-0.79,1.08-1.24,1.57c-0.44,0.5-0.85,1.03-1.31,1.51l-0.68,0.74l-0.34,0.37c-0.12,0.12-0.19,0.28-0.55,0.2l-0.04-0.01
				C229.32,245.83,228.75,245.32,228.49,244.68z"/>
          </g>
          <g>
            <path className="st21" d="M266.18,149.45c0.32,0.25,0.29,0.5,0.3,0.75l0.05,0.75l0.12,1.5c0.1,1,0.12,2,0.18,3.01
				c0.09,1,0.06,2.01,0.02,3.03c-0.03,1.01,0.08,2.02,0.13,3.03c0.11,2.02,0.29,4.05,0.31,6.08c0.04,1.02,0.02,2.03-0.17,3.05
				l-0.25,1.52l-0.13,0.76l-0.04,0.38c0.02,0.13-0.12,0.25,0.12,0.38c0,0.57-0.47,1.03-1.03,1.02s-1.03-0.47-1.02-1.03
				c0.26-0.12,0.08-0.25,0.09-0.38c-0.04-0.13-0.06-0.25-0.11-0.38c-0.1-0.25-0.19-0.5-0.27-0.75c-0.16-0.5-0.16-1-0.17-1.5
				c0.05-1,0.11-2.01,0.09-3.01c0.01-2.01-0.02-4.02-0.09-6.04c-0.04-1.01-0.04-2.02-0.15-3.02c-0.13-1.01-0.24-2.01-0.27-3.02
				c-0.03-1.01-0.11-2.03-0.1-3.05l0.01-1.53l0.02-0.77c0.01-0.26-0.03-0.51,0.29-0.77C264.74,148.96,265.59,148.98,266.18,149.45
				L266.18,149.45z"/>
          </g>
          <g>
            <path className="st21" d="M267.96,236.37c0.32,0.22,0.3,0.45,0.32,0.67l0.07,0.68l0.14,1.36c0.1,0.91,0.12,1.82,0.19,2.73
				c0.09,0.91,0.06,1.82,0.02,2.73c-0.02,0.91,0.09,1.82,0.16,2.73c0.14,1.82,0.37,3.64,0.44,5.48c0.07,0.92,0.07,1.84-0.09,2.76
				l-0.2,1.39l-0.1,0.69c-0.02,0.11-0.02,0.23-0.03,0.34c0.02,0.11-0.12,0.23,0.13,0.34c0.02,0.57-0.43,1.04-1,1.06
				s-1.04-0.43-1.06-1c0.26-0.12,0.07-0.23,0.07-0.34c-0.04-0.11-0.07-0.23-0.12-0.34c-0.1-0.22-0.2-0.45-0.29-0.67
				c-0.18-0.45-0.19-0.9-0.22-1.36c0.02-0.91,0.05-1.82,0.01-2.73c-0.04-1.82-0.12-3.64-0.22-5.47c-0.05-0.91-0.06-1.83-0.18-2.75
				c-0.13-0.91-0.25-1.83-0.27-2.74c-0.03-0.92-0.11-1.83-0.1-2.75l0-1.38l0-0.69c0-0.23-0.05-0.46,0.27-0.7l0.01-0.01
				C266.53,235.96,267.35,235.96,267.96,236.37z"/>
          </g>
          <g>
            <path className="st21" d="M230.41,204.53c-0.17,0.33-0.37,0.31-0.57,0.34l-0.58,0.08l-1.16,0.17c-0.77,0.12-1.56,0.16-2.33,0.24
				c-0.77,0.1-1.56,0.09-2.34,0.07c-0.78-0.01-1.56,0.12-2.33,0.2c-1.55,0.16-3.11,0.42-4.69,0.53c-0.78,0.08-1.57,0.1-2.37-0.04
				l-1.19-0.17l-0.6-0.09c-0.1-0.01-0.2-0.02-0.3-0.02c-0.1,0.03-0.2-0.11-0.29,0.14c-0.57,0.04-1.06-0.39-1.09-0.96
				c-0.04-0.57,0.39-1.06,0.96-1.09c0.11,0.26,0.2,0.07,0.3,0.07c0.1-0.04,0.19-0.07,0.29-0.13c0.19-0.11,0.38-0.21,0.57-0.31
				c0.38-0.19,0.77-0.2,1.16-0.25c0.78,0,1.57,0.02,2.34-0.04c1.56-0.07,3.11-0.17,4.68-0.3c0.78-0.07,1.57-0.09,2.35-0.22
				c0.78-0.15,1.56-0.28,2.34-0.32c0.79-0.05,1.57-0.15,2.35-0.16l1.18-0.03l0.59-0.01c0.2,0,0.39-0.06,0.61,0.25l0.01,0.01
				C230.72,203.11,230.74,203.9,230.41,204.53z"/>
          </g>
          <g>
            <path className="st21" d="M324.28,203.76c-0.15,0.33-0.33,0.3-0.5,0.33l-0.52,0.08l-1.03,0.15c-0.69,0.11-1.38,0.13-2.08,0.19
				c-0.69,0.08-1.39,0.04-2.09-0.03c-0.7-0.05-1.39,0.03-2.07,0.07c-1.37,0.07-2.72,0.25-4.1,0.3c-0.68,0.06-1.37,0.06-2.07-0.1
				l-1.05-0.19l-0.52-0.09c-0.09-0.01-0.17-0.02-0.26-0.03c-0.08,0.03-0.18-0.11-0.25,0.14c-0.57,0.04-1.06-0.39-1.09-0.96
				s0.39-1.06,0.96-1.09c0.1,0.26,0.18,0.07,0.26,0.07c0.08-0.04,0.17-0.07,0.25-0.12c0.17-0.11,0.33-0.21,0.5-0.3
				c0.33-0.19,0.68-0.2,1.03-0.23c0.69,0.01,1.39,0.05,2.08,0.01l4.17-0.08c0.69-0.02,1.38,0,2.07-0.08
				c0.68-0.11,1.36-0.2,2.04-0.22c0.68-0.03,1.36-0.1,2.05-0.1l1.03-0.01l0.52-0.01c0.17,0,0.34-0.05,0.53,0.26l0.01,0.02
				C324.56,202.37,324.57,203.14,324.28,203.76z"/>
          </g>
        </g>
        <g>
          <g>
            <path className="st3" d="M808.51,883.93c-0.32,0-0.5-0.16-0.7-0.31l-0.59-0.44l-1.18-0.88c-0.79-0.58-1.54-1.21-2.32-1.82
				c-1.53-1.22-2.94-2.6-4.52-3.77l-4.61-3.66c-0.78-0.6-1.52-1.24-2.2-1.96l-1.03-1.06c-0.17-0.18-0.35-0.35-0.52-0.52l-0.26-0.26
				c-0.1-0.07-0.14-0.22-0.33-0.17l0.86-1.06c-0.02,0.21,0.16,0.2,0.25,0.28c0.11,0.06,0.22,0.12,0.33,0.18l0.68,0.32
				c0.44,0.23,0.83,0.53,1.21,0.84c0.74,0.64,1.47,1.31,2.23,1.92l4.52,3.76c1.48,1.29,3.1,2.41,4.63,3.64
				c0.76,0.62,1.54,1.22,2.28,1.86l1.12,0.95l0.56,0.48c0.19,0.16,0.39,0.3,0.45,0.62L808.51,883.93z"/>
          </g>
          <g>
            <path className="st3" d="M793.96,883.52c-0.04-0.29,0.09-0.45,0.21-0.62l0.34-0.52l0.69-1.05c0.45-0.7,0.95-1.37,1.43-2.05
				c0.96-1.36,2.09-2.6,2.99-4l2.88-4.09c0.47-0.69,0.98-1.35,1.58-1.94l0.87-0.91c0.15-0.15,0.29-0.3,0.43-0.46l0.21-0.23
				c0.05-0.09,0.19-0.12,0.12-0.3l1.11,0.81c-0.2-0.02-0.17,0.14-0.23,0.22c-0.04,0.1-0.09,0.19-0.13,0.3l-0.22,0.61
				c-0.17,0.39-0.4,0.74-0.64,1.08c-0.51,0.66-1.05,1.3-1.54,1.97l-2.99,4.01c-1.04,1.31-1.89,2.75-2.86,4.1
				c-0.49,0.67-0.96,1.36-1.47,2.02l-0.76,0.99l-0.38,0.5c-0.12,0.17-0.24,0.34-0.53,0.39L793.96,883.52z"/>
          </g>
        </g>
      </g>
      <g id="Ebene_9">
        <g>
          <text transform="translate(820.4815 145.9301)">
            <tspan x="0" y="0" className="st12 st15 st16">Drag &amp; drop</tspan>
            <tspan x="0" y="38" className="st12 st15 st16">a view from</tspan>
            <tspan x="0" y="76" className="st12 st15 st16">your view bar.</tspan>
          </text>
        </g>
      </g>
      <g id="Ebene_5">
      </g>
      <g id="Ebene_10">
      </g>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 1202.56 1074.54";
  }
}

export default withStyles(svgIconStyles)(EmptyEditorIcon);
