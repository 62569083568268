/* LeftSubtreeIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Januar 2019
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../../common/icons/menu/MetusSvgIcon";

class FilterToolbarIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <g id="icon-/-filter-toolbar">
      <path d="M2,3 L3.707,4.706 L10.414,11.414 L10.414,19 L10.414,21.414 L12.121,19.707 L14.121,17.707 L14.415,17.413 L14.415,16.999 L14.415,11.414 L21.121,4.706 L22.828,3 L2,3 Z M11.414,11 L4.414,4 L20.414,4 L13.414,11 L13.414,16.999 L11.414,19 L11.414,11 Z" id="FilterToolbarIcon"></path>
    </g>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(FilterToolbarIcon);