import {ElementId, TableId} from "../Core";
import {allConnectedParentsMatcher, HierarchicalNodeCollectorMatcher} from "./NodeMatcher";
import {getFilterMatcher, IFilterMatcher} from "./FilterMatcher";
import {AttributeFilterData, createAttributeFilterForMatcher, ElementFilter, IFilter} from "./Filter";
import {HierarchicalElementGraph} from "../elementgraph/ElementGraph";
import {TableNodeCollector, TableNodeCollectorData} from "./NodeCollector";
import {ViewType} from "../../../common/constants/Enums";
import {FilteringStep, FilterTextCheckerResult, HierarchicalFilterData, processFilterText} from "./FilterTextProcessor";

/**
 * Created by P.Bernhard on 07.11.2017.
 */

/**
 * convenience method to create a simple filter matcher which can evaluate the filter on a specific attribute without fuzz,
 * pass the element id and get boolean if it matches
 *
 */
export function getSimpleMatcher(filterExpression: string, tableId: TableId, attributeName: string): SimpleMatcher {
  return new SimpleMatcher(attributeName, tableId, filterExpression);
}

export class SimpleMatcher {
  private matcher: IFilterMatcher;
  private filter: IFilter;

  public constructor(private attributeName: string, private tableId: TableId, private filterExpression: string) {
    this.matcher = getFilterMatcher(filterExpression, tableId, attributeName);
    this.filter = createAttributeFilterForMatcher(attributeName, tableId, filterExpression, this.matcher);
  }

  /**
   * true if the given element matches the filter or if filter is not valid; false if filter is valid but does not match
   * @param elementId
   */
  public matches(elementId: string): boolean {
    return this.filter === undefined || this.filter.matches([elementId]).length > 0;
  }

  /**
   * @return true if the filter is valid for the given view type; no hierarchy is created
   * @param viewType
   */
  public isValid(viewType: ViewType): boolean {
    const hierarchicalFilterData: HierarchicalFilterData =
        new HierarchicalFilterData(
            this.attributeName,
            this.filterExpression,
            null,
            viewType,
            this.tableId);

    const filterTextCheckerResult: FilterTextCheckerResult = processFilterText(hierarchicalFilterData);
    return filterTextCheckerResult.isValid;
  }
}

class FilteringStep3 extends FilteringStep {

  constructor(nodeCollectorMatcher: HierarchicalNodeCollectorMatcher) {
    super();

    if (!nodeCollectorMatcher) {
      throw new Error("Step 3 must provide a collector!");
    }

    this._filterMatcher = null;
    this._nodeCollectorMatcher = nodeCollectorMatcher;
  }

  public get stepNum(): number {
    return 3;
  }
}

enum CollectMatcherMode {
  CollectHierarchicalNodeCollectorMatcher,
  CollectAttributeFilterData
}

export function filterValueChart(filterTextCheckerResults: FilterTextCheckerResult[], tableId: TableId, elementId: ElementId, viewType: ViewType): boolean {
  if (viewType !== ViewType.ValueChart)
    throw new Error("This function is just for Value Charts");

  const hierarchy: HierarchicalElementGraph = new HierarchicalElementGraph(new Map([[tableId, 0]]), [elementId]);
  preprocessViewSpecialities(filterTextCheckerResults, hierarchy, viewType);

  const filterSteps: FilteringStepsEvaluator = new FilteringStepsEvaluator(filterTextCheckerResults, hierarchy);
  return filterSteps.processFilteringSteps().length === 1;
}

export function filter(filterTextCheckerResults: FilterTextCheckerResult[], hierarchy: HierarchicalElementGraph, viewType: ViewType): ElementId[] {
  if (viewType !== ViewType.Chart && viewType !== ViewType.StructuredTable)
    throw new Error("This function ist just for Charts and Structured Tables");

  preprocessViewSpecialities(filterTextCheckerResults, hierarchy, viewType);

  const filterSteps: FilteringStepsEvaluator = new FilteringStepsEvaluator(filterTextCheckerResults, hierarchy);
  return filterSteps.processFilteringSteps();
}

function preprocessViewSpecialities(filterTextCheckerResults: FilterTextCheckerResult[], hierarchy: HierarchicalElementGraph, viewType: ViewType): void {
  if (viewType === ViewType.StructuredTable) {
    preprocessStructuredTable(filterTextCheckerResults, hierarchy);
  }
  else if (viewType === ViewType.ValueChart) {
    preprocessValueChart(filterTextCheckerResults);
  }
}

function preprocessStructuredTable(filterTextCheckerResults: FilterTextCheckerResult[], hierarchy: HierarchicalElementGraph): void {
  const allTables: TableId[] = hierarchy.allTables;
  const filterTextCheckerResult: FilterTextCheckerResult = new FilterTextCheckerResult(allTables, "name", "", true);

  const filterringStep3: FilteringStep3 = new FilteringStep3(allConnectedParentsMatcher);

  for (const table of allTables) {
    filterTextCheckerResult.setStep(table, filterringStep3);
  }

  filterTextCheckerResults.push(filterTextCheckerResult);
}

function preprocessValueChart(filterTextCheckerResults: FilterTextCheckerResult[]): void {
  let tableId: TableId = undefined;
  const attributeNames: string[] = [];
  for (const filterTextCheckerResult of filterTextCheckerResults) {
    if (filterTextCheckerResult.tables.length > 1 || filterTextCheckerResult.tables.length === 0)
      throw new Error("For a Value Chart one FilterTextCheckerResult must have exactly one table in the tables list!");
    if (tableId === undefined) {
      tableId = filterTextCheckerResult.tables[0];
    } else if (tableId !== filterTextCheckerResult.tables[0]) {
      throw new Error("For one filtering result for a value chart all given FilterTextCheckerResult must contain equal tables");
    } else if (attributeNames.includes(filterTextCheckerResult.attributeName)) {
      throw new Error("For one filtering result for a value chart all given FilterTextCheckerResult must contain different attribute names");
    }
  }
}

class FilteringStepsEvaluator {
  constructor(readonly filterTextCheckerResults: FilterTextCheckerResult[], readonly hierarchy: HierarchicalElementGraph) {
  }

  public processFilteringSteps(): ElementId[] {
    const relevantElementsSortedByTableBeforeSteps: Map<TableId, ElementId[]> = this.hierarchy.relevantElementsSortedByTable;
    const relevantElementsSortedByTableAfterStep1: Map<TableId, ElementId[]> = this.processFilteringStep1(relevantElementsSortedByTableBeforeSteps);
    const relevantElementsSortedByTableAfterStep2: Map<TableId, ElementId[]> = this.processFilteringStep2(relevantElementsSortedByTableAfterStep1);
    const relevantElementsSortedByTableAfterStep3: Map<TableId, ElementId[]> = this.processFilteringStep3(relevantElementsSortedByTableAfterStep2);

    let remainingElements: ElementId[] = [];
    relevantElementsSortedByTableAfterStep3.forEach((nodes, key, map) => {
      remainingElements = remainingElements.concat(nodes.filter(node => {
        return !remainingElements.includes(node);
      }));
    });

    return remainingElements;
  }


  private processFilteringStep1(remainingNodesPerTable: Map<TableId, ElementId[]>): Map<TableId, ElementId[]> {
    let retVal: Map<TableId, ElementId[]> = remainingNodesPerTable;

    // collects 0-n filter data for all tables
    const attributeFilterDataPerTable: Map<TableId, AttributeFilterData[]> =
        this.collectMatchersForStep(
            1,
            CollectMatcherMode.CollectAttributeFilterData) as Map<TableId, AttributeFilterData[]>;

    // Every NodeCollectorMatcher must have exactly one or more AttributeFilterMatcher
    if (attributeFilterDataPerTable.size !== 0) {
      // apply all filters from step before on each table (make an element filter from all attribute filters) and put the remaining nodes into a map
      const remainingNodesPerTableAfterFiltering: Map<TableId, ElementId[]> =
          this.collectRemainingNodesPerTableWithFiltering(attributeFilterDataPerTable, remainingNodesPerTable);

      // collects 0-1 nodeCollectorMatcher for all tables
      const hierarchicalNodeCollectorMatcherPerTable: Map<TableId, HierarchicalNodeCollectorMatcher> =
          this.collectMatchersForStep(
              1,
              CollectMatcherMode.CollectHierarchicalNodeCollectorMatcher) as Map<TableId, HierarchicalNodeCollectorMatcher>;

      // apply all nodeCollectors from the step before  on the remaining elements from the filtering step for each table and put the collected nodes into a map
      const collectedNodesPerTableAfterNodeCollecting: Map<TableId, Set<ElementId>> =
          this.collectNodesPerTableWithPathFiltering(
              hierarchicalNodeCollectorMatcherPerTable, remainingNodesPerTableAfterFiltering);

      // make an intersection of all the collected elements from the step before
      retVal = this.sortNodesPerTable(this.intersectCollectedNodesPerTable(collectedNodesPerTableAfterNodeCollecting));
    }

    return retVal;
  }

  private processFilteringStep2(remainingNodesPerTable: Map<TableId, ElementId[]>): Map<TableId, ElementId[]> {
    let retVal: Map<TableId, ElementId[]> = remainingNodesPerTable;

    // collects 0-n filter data for all tables
    const attributeFilterDataPerTable: Map<TableId, AttributeFilterData[]> =
        this.collectMatchersForStep(
            2,
            CollectMatcherMode.CollectAttributeFilterData) as Map<TableId, AttributeFilterData[]>;

    if (attributeFilterDataPerTable.size !== 0) {
      // apply all filters from step before on each table's given elements (make an element filter from all attribute filters) and put the remaining nodes into a map
      retVal = this.collectRemainingNodesPerTableWithFiltering(attributeFilterDataPerTable, remainingNodesPerTable);
    }

    return retVal;
  }

  private processFilteringStep3(remainingNodesPerTable: Map<TableId, ElementId[]>): Map<TableId, ElementId[]> {
    let retVal: Map<TableId, ElementId[]> = remainingNodesPerTable;

    const hierarchicalNodeCollectorMatcherPerTable: Map<TableId, HierarchicalNodeCollectorMatcher> =
        this.collectMatchersForStep(
            3,
            CollectMatcherMode.CollectHierarchicalNodeCollectorMatcher) as Map<TableId, HierarchicalNodeCollectorMatcher>;

    if (hierarchicalNodeCollectorMatcherPerTable.size !== 0) {
      retVal = this.setToArrayCollectedNodesPerTable(this.collectNodesPerTableWithPathFiltering(hierarchicalNodeCollectorMatcherPerTable, remainingNodesPerTable));
    }

    return retVal;
  }


  // Collect all matchers tablewise
  private collectMatchersForStep(stepNum: number, collectMatcherMode: CollectMatcherMode): Map<TableId, AttributeFilterData[]> | Map<TableId, HierarchicalNodeCollectorMatcher> {
    let collectedMatchers: Map<TableId, AttributeFilterData[]> | Map<TableId, HierarchicalNodeCollectorMatcher>;

    if (collectMatcherMode === CollectMatcherMode.CollectAttributeFilterData) {
      collectedMatchers = new Map<TableId, AttributeFilterData[]>();
    }
    else if (collectMatcherMode === CollectMatcherMode.CollectHierarchicalNodeCollectorMatcher) {
      collectedMatchers = new Map<TableId, HierarchicalNodeCollectorMatcher>();
    }

    for (const filterTextCheckerResult of this.filterTextCheckerResults) {
      for (const table of filterTextCheckerResult.tables) {
        if (collectMatcherMode === CollectMatcherMode.CollectAttributeFilterData) {
          this.collectAllAttributeFilterDataPerTableForStep(
              table, collectedMatchers as Map<TableId, AttributeFilterData[]>, stepNum, filterTextCheckerResult);
        }
        else if (collectMatcherMode === CollectMatcherMode.CollectHierarchicalNodeCollectorMatcher) {
          this.collectHierarchicalNodeCollectorMatcherPerTableForStep(
              table, collectedMatchers as Map<TableId, HierarchicalNodeCollectorMatcher>, stepNum, filterTextCheckerResult);
        }
      }
    }

    return collectedMatchers;
  }

  private collectAllAttributeFilterDataPerTableForStep(table: TableId, attributeFilterDataPerTable: Map<TableId, AttributeFilterData[]>, stepNum: number, filterTextCheckerResult: FilterTextCheckerResult): void {
    const attributeFilterData: AttributeFilterData = filterTextCheckerResult.getAttributeFilterDataForTable(table, stepNum, this.hierarchy);
    if (attributeFilterData) {
      let existingAttributeFilterData: AttributeFilterData[];

      if (attributeFilterDataPerTable.has(table)) {
        existingAttributeFilterData = attributeFilterDataPerTable.get(table);
      } else {
        existingAttributeFilterData = [];
        attributeFilterDataPerTable.set(table, existingAttributeFilterData);
      }

      existingAttributeFilterData.push(attributeFilterData);
    }
  }

  private collectHierarchicalNodeCollectorMatcherPerTableForStep(table: TableId, hierarchicalNodeCollectorMatcherPerTable: Map<TableId, HierarchicalNodeCollectorMatcher>, stepNum: number, filterTextCheckerResult: FilterTextCheckerResult): void {
    const nodeCollectorMatcher: HierarchicalNodeCollectorMatcher = filterTextCheckerResult.getNodeCollectorMatcherForTable(table, stepNum);
    if (nodeCollectorMatcher) {
      if (hierarchicalNodeCollectorMatcherPerTable.has(table)) {
        const existingHierarchicalNodeCollectorMatcherPerTable: HierarchicalNodeCollectorMatcher = hierarchicalNodeCollectorMatcherPerTable.get(table);

        if (existingHierarchicalNodeCollectorMatcherPerTable.constructor.name !== nodeCollectorMatcher.constructor.name)
          throw new Error("Just one kind of path filter per table is allowed!");
      } else {
        hierarchicalNodeCollectorMatcherPerTable.set(table, nodeCollectorMatcher);
      }
    }
  }


  // applies the given filter to the given remaining nodes of all tables
  private collectRemainingNodesPerTableWithFiltering(attributeFilterDataPerTable: Map<TableId, AttributeFilterData[]>, remainingNodesPerTable: Map<TableId, ElementId[]>): Map<TableId, ElementId[]> {
    const retVal: Map<TableId, ElementId[]> = new Map();

    remainingNodesPerTable.forEach((nodes, table, map) => {
      let attributesFilterDataForTable: AttributeFilterData[] = [];

      if (attributeFilterDataPerTable.has(table)) {
        attributesFilterDataForTable = attributeFilterDataPerTable.get(table);
      }

      const elementFilter: ElementFilter = new ElementFilter(attributesFilterDataForTable, table);
      const remainingNodes: ElementId[] = elementFilter.matches(nodes);

      retVal.set(table, remainingNodes);
    });

    return retVal;
  }


  // applies the given nodeCollectorMatcher to the given remaining nodes of all tables
  private collectNodesPerTableWithPathFiltering(hierarchicalNodeCollectorMatcherPerTable: Map<TableId, HierarchicalNodeCollectorMatcher>, remainingNodesPerTable: Map<TableId, ElementId[]>): Map<TableId, Set<ElementId>> {
    const remainingNodesPerTableAfterNodeCollecting: Map<TableId, Set<ElementId>> = new Map<TableId, Set<ElementId>>();

    remainingNodesPerTable.forEach((nodes, table, map) => {
      if (hierarchicalNodeCollectorMatcherPerTable.size === 0) {
        remainingNodesPerTableAfterNodeCollecting.set(table, new Set<ElementId>(nodes));
      } else {
        if (hierarchicalNodeCollectorMatcherPerTable.has(table)) {
          const remainingNodesForTable: ElementId[] = remainingNodesPerTable.get(table);
          const tableNodeCollectorData: TableNodeCollectorData = new TableNodeCollectorData(remainingNodesForTable, table, this.hierarchy);
          const hierarchicalNodeCollectorMatcher: HierarchicalNodeCollectorMatcher = hierarchicalNodeCollectorMatcherPerTable.get(table);
          if (hierarchicalNodeCollectorMatcher) {
            const tableNodeCollector: TableNodeCollector = new TableNodeCollector(hierarchicalNodeCollectorMatcher);
            remainingNodesPerTableAfterNodeCollecting.set(table, tableNodeCollector.collectNodes(tableNodeCollectorData));
          }
        }
      }
    });

    return remainingNodesPerTableAfterNodeCollecting;
  }


  // intersect all nodes that have been collected starting from each table
  private intersectCollectedNodesPerTable(remainingNodesPerTable: Map<TableId, Set<ElementId>>): Set<ElementId> {
    let intersection: Set<ElementId> = new Set<ElementId>(this.hierarchy.relevantElements);

    remainingNodesPerTable.forEach((nodes, table, map) => {
      const restNodes: Set<ElementId> = new Set<ElementId>();
      intersection.forEach(nodeId => {
        if (nodes.has(nodeId)) {
          restNodes.add(nodeId);
        }
      });
      intersection = restNodes;
    });

    return intersection;
  }

  private setToArrayCollectedNodesPerTable(remainingNodesPerTable: Map<TableId, Set<ElementId>>): Map<TableId, ElementId[]> {
    const result: Map<TableId, ElementId[]> = new Map<TableId, ElementId[]>();

    remainingNodesPerTable.forEach((nodes, table, map) => {
      result.set(table, Array.from(nodes));
    });

    return result;
  }


  private sortNodesPerTable(nodeIds: Set<ElementId>): Map<TableId, ElementId[]> {
    const nodesSortedPerTable: Map<TableId, ElementId[]> = new Map<TableId, ElementId[]>();

    nodeIds.forEach(nodeId => {
      const table: TableId = this.hierarchy.getTableForElement(nodeId);
      let nodesPerTable: ElementId[];

      if (nodesSortedPerTable.has(table)) {
        nodesPerTable = nodesSortedPerTable.get(table);
      } else {
        nodesPerTable = [];
        nodesSortedPerTable.set(table, nodesPerTable);
      }

      nodesPerTable.push(nodeId);
    });

    return nodesSortedPerTable;
  }

}