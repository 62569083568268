/* RESTUtil.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, August 2017
 */
import Log from "../../common/utils/Logger";
import {HTTPStatusError} from "../../common/utils/Exceptions/HTTPStatusError";
import {showErrorDialog} from "../../common/utils/CommonDialogUtil";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {
  AuthenticationNeededAction,
  HideLoadingAction,
  ShowLoadingAction
} from "../../common/actions/InteractionStateActions";
import {WriteResult} from "../../api/api";
import {HttpManipulationVerb, ServerRequestInfo} from "../../common/actions/BaseAction";
import * as _ from "lodash";
import {PromiseExecutorType} from "../../commonviews/actions/RESTCallActionCreatorsBase";
import {configurationStore} from "../stores/ConfigurationStore";

const log = Log.logger("RestCallUtil");

export type HttpHeaders = { [index: string]: string };

// when there is a read-only model, the put/post/delete-methods return a fake response
// they must not return null or undefined in order to be distinguishable from a failed write response, which returns null or undefined
export const fakeWriteResult = {commandId: "dummy-command-id"};

export interface ExplicitStatusCodes {
  allowedStatusCodes?: number[];
  knownErrorCodes?: number[];
}

/**
 *
 * @param {string} body
 * @param {string} url
 * @param {HttpHeaders} headers
 * @param forceWrite
 * @param explizitStatusCodes
 * @returns {Promise<Response>}
 */
export function post(
    body: string,
    url: string,
    headers?: HttpHeaders,
    forceWrite: boolean = false,
    explizitStatusCodes: ExplicitStatusCodes = {}): Promise<void | WriteResult<any>> {

  if (!forceWrite && !configurationStore.canWriteToServer()) {
    return Promise.resolve(fakeWriteResult);
  }

  Dispatcher.dispatch(new ShowLoadingAction());

  const requestInit: RequestInit = {
    credentials: "include",
    method: "POST",
    body: body,
    headers: headers as any
  };

  const allowedStatusCodes: number[] = [];
  if (explizitStatusCodes.allowedStatusCodes === undefined) {
    // if not specified, expect 201 = created
    allowedStatusCodes.push(201);
  } else {
    allowedStatusCodes.push(...explizitStatusCodes.allowedStatusCodes);
  }

  const retryRequestAfterAuthPromise = new Promise<WriteResult<any>>((resolve: (result: WriteResult<any>) => void, reject: (reason?: any) => void): void => {
    const onfullfilled = _.partialRight(fulfilledHandler, url, resolve, reject, "POST", allowedStatusCodes);
    window.fetch(url, requestInit).then(onfullfilled)
  }).catch((error: HTTPStatusError) => {
    if (explizitStatusCodes.knownErrorCodes?.indexOf(error.status) >= 0) {
      throw error;
    } else {
      errorHandler(error, url);
    }
  });

  return retryRequestAfterAuthPromise;
}

/**
 *
 * @param {string} body
 * @param {string} url
 * @param {HttpHeaders} headers
 * @returns {Promise<Response>}
 */
export function put(
    body: string,
    url: string,
    headers?: HttpHeaders,
    forceWrite: boolean = false): Promise<void | WriteResult<any>> {

  if (!forceWrite && !configurationStore.canWriteToServer()) {
    return Promise.resolve(fakeWriteResult);
  }

  Dispatcher.dispatch(new ShowLoadingAction());

  const requestInit: RequestInit = {
    credentials: "include",
    method: "PUT",
    body: body,
    headers: headers as any
  };

  const retryRequestAfterAuthPromise = new Promise<WriteResult<any>>((resolve: (result: WriteResult<any>) => void, reject: (reason?: any) => void): void => {
    const onfullfilled = _.partialRight(fulfilledHandler, url, resolve, reject, "PUT", [200]);
    window.fetch(url, requestInit).then(onfullfilled)
  }).catch(error => {
    errorHandler(error, url);
  });

  return retryRequestAfterAuthPromise;
}

/**
 *
 * @param {string} url
 * @param force send delete even in non-embedded mode
 * @returns {Promise<Response>}
 */
export function deleteResource(url: string, force: boolean = false, is401Valid: boolean = false): Promise<void | WriteResult<any>> {
  if (!configurationStore.canWriteToServer() && !force) {
    return Promise.resolve(fakeWriteResult);
  }

  Dispatcher.dispatch(new ShowLoadingAction());

  const requestInit: RequestInit = {
    credentials: "include",
    method: "DELETE"
  };

  const retryRequestAfterAuthPromise = new Promise<WriteResult<any>>((resolve: (result: WriteResult<any>) => void, reject: (reason?: any) => void): void => {
    const onfullfilled = _.partialRight(fulfilledHandler, url, resolve, reject, "DELETE", [200], is401Valid);
    window.fetch(url, requestInit).then(onfullfilled)
  }).catch(error => {
    errorHandler(error, url);
  });

  return retryRequestAfterAuthPromise;
}

export function authenticationExecutorWrite(url: string, httpVerb: HttpManipulationVerb): PromiseExecutorType {
  const serverRequestInfo = new ServerRequestInfo(url, httpVerb);
  return _.partialRight(queueOriginalRequestAndDispatchAuthenticationNeededAction, serverRequestInfo);
}

export function queueOriginalRequestAndDispatchAuthenticationNeededAction<T>(originalResolve: (T) => void, originalReject: (reason?: any) => void, serverRequestInfo: ServerRequestInfo): void {
  log.debug(`Authenticating`);
  serverRequestInfo.setPromiseCallbacks(originalResolve, originalReject);
  Dispatcher.dispatch(new AuthenticationNeededAction(serverRequestInfo));
}

function fulfilledHandler<T>(
    response: Response,
    url: string,
    resolve: (result: WriteResult<T>) => void,
    reject: (reason?: any) => void,
    httpVerb: HttpManipulationVerb,
    allowedStatusCodes: number[],
    is401Valid: boolean = false): void {

  if (allowedStatusCodes.indexOf(response.status) >= 0) {
    response.json().then(json => {
          const result: WriteResult<T> = {commandId: json["commandId"], json: json.json as T};
          resolve(result);
          Dispatcher.dispatch(new HideLoadingAction());
        }
    )
  } else if (response.status === 401 || response.status === 402) {
    if (!is401Valid) {
      authenticationExecutorWrite(url, httpVerb)(resolve, reject);
    }
    resolve({commandId: null, json: null});
    Dispatcher.dispatch(new HideLoadingAction());
  } else {
    response.text().then(text => {
      const error = new HTTPStatusError(httpVerb, url, response.status, response.statusText, text);
      reject(error);
    });
  }
}

export interface HttpGetRequestData {
  url: string;
  knownErrorCodes?: number[];
  headers?: HttpHeaders;
}

/**
 * fetch url, authenticate if needed
 * @param url url to fetch from
 * @param authenticateExecutor if url returns 401 or 402 the original request is saved in a server request info with the appropriate resolve function,
 *   a login dialog is showed, form submit will issue the  server request info which finally call resolveOriginalPromise which resolves the returned promise
 * @param knownErrorCodes error codes which are not handeled by the global error dialog but specific.
 * @param headers headers passed to fetch
 */
export function get<T>(
    url: string,
    authenticateExecutor: (originalResolve: (T) => void, originalReject: (reason?: any) => void) => void,
    knownErrorCodes: number[] = [],
    headers?: HttpHeaders): Promise<void | T> {

  Dispatcher.dispatch(new ShowLoadingAction());

  const requestInit: RequestInit = {
    credentials: "include",
    method: "GET",
    headers: headers
  };

  // wrap fetch result in another promise which is resolved by fetch if already authenticated,
  // otherwise wrapped in a ServerRequestInfo which resolves the promise refetching the original request after login
  const retryRequestAfterAuthPromise = new Promise<T>(async (resolve: (T) => void, reject: (reason?: any) => void): Promise<void> => {

    try {
      const response: Response = await window.fetch(url, requestInit);

      log.debug("loadResource: got response", response);
      if (response.status === 200) {
        // ok, just pass resolved result to encapsulating promise
        resolve(response.json());
        Dispatcher.dispatch(new HideLoadingAction());
      } else if (response.status === 401 || response.status === 402) {
        // authenticate and queue request for retry after login
        authenticateExecutor(resolve, reject);
        Dispatcher.dispatch(new HideLoadingAction());
      } else {
        const text = await response.text();
        reject(new HTTPStatusError("GET", url, response.status, response.statusText, text));
      }
    } catch (error) {
      reject(error);
    }
  }).catch(error => {
    if (knownErrorCodes.indexOf(error.status) >= 0) {
      throw error;
    } else {
      errorHandler(error, url);
    }
  });

  return retryRequestAfterAuthPromise;
}


function errorHandler(error: any, url: string): void {
  log.error("Error fetching URL " + url + ": " + error.message);
  Dispatcher.dispatch(new HideLoadingAction());
  showErrorDialog(true, error.message, error.text);
}
