/* EditorComponent.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Juni 2017
 */
import * as React from "react";
import * as ReactDnD from "react-dnd";
import {Cursor} from "../../common/constants/Enums";
import DragTypes from "../../common/constants/DragTypes";
import {NodeProps} from "./TreeListItemComponent";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {DndTargetFeedbackAction} from "../../common/actions/InteractionStateActions";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {MosaicPath} from "react-mosaic-component/lib/types";
import EmptyEditorIcon from "../../common/icons/constructionHelper/EmptyEditorIcon";

const dndlog = Log.logger("EmptyViewComponent", Classifier.dnd);

interface LocalProps {
  windowPath: MosaicPath;
  connectDropTarget?: ReactDnD.ConnectDropTarget;
}

export class EmptyViewComponentNoDnd extends React.Component<LocalProps, any> {

  render(): JSX.Element {
    return this.props.connectDropTarget(
        <div className="emptyViewDiv"><EmptyEditorIcon style={{width: "100%", height: "100%"}}
                                                       data-testselector={"ConstructionHelper"}/></div>);
  }
}

//noinspection JSUnusedLocalSymbols
const dropTarget: ReactDnD.DropTargetSpec<LocalProps> = {
  hover(props: LocalProps, monitor: ReactDnD.DropTargetMonitor): void {
    dndlog.debug("Hover, setting target feedback image and move cursor");
    const item: NodeProps = monitor.getItem() as NodeProps;
    Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.MOVE, <img
        src={require("../../common/images/metusChart.svg")} width="400"/>));
  },

  drop(props: LocalProps, monitor: ReactDnD.DropTargetMonitor, component: React.Component<LocalProps, any>): Object {
    return {windowPath: props.windowPath};
  }

};

function collect(connect: ReactDnD.DropTargetConnector, monitor: ReactDnD.DropTargetMonitor): Object {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

export default ReactDnD.DropTarget([DragTypes.VIEW, DragTypes.CORE_TABLE], dropTarget, collect)(EmptyViewComponentNoDnd);
