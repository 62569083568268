import * as React from "react";
import {Route, RouteProps} from "react-router";
import deepEqual from "deep-equal";

interface LocalProps {
  onEnter: (prop: LocalProps, location:any) => void;
}

/**
 * HOC wrapper around react-router V4 Route to enable easy migration of onEnter callback
 * @author Marco van Meegen
 */
export class RouteWithEnter extends React.Component<RouteProps & LocalProps, any> {
  private lastComputedMatch: any = {};

  constructor(props: RouteProps & LocalProps) {
    super(props);
  }


  componentDidMount(): void {
    const onEnter = (this.props as LocalProps).onEnter;
    if (onEnter) {
      this.lastComputedMatch = Object.assign({}, (this.props as any).computedMatch);
      onEnter(this.lastComputedMatch, this.props.location);
    }
  }

  render(): JSX.Element {
    return <Route {...this.props} />;
  }

  componentDidUpdate(): void {
    const onEnter = (this.props as LocalProps).onEnter;
    if (onEnter && !deepEqual(this.lastComputedMatch, (this.props as any).computedMatch)) {
      this.lastComputedMatch = Object.assign({}, (this.props as any).computedMatch);
      onEnter(this.lastComputedMatch, this.props.location);
    }
  }
}
