import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class MenuToRightIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <polygon transform="translate(11.995000, 12.002000) scale(-1, 1) translate(-11.995000, -12.002000) "
                    points="8.843 4.3 7.43 5.717 13.729 12.002 7.43 18.288 8.843 19.704 16.56 12.002"></polygon>
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(MenuToRightIcon);