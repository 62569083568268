/* CockpitsIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2018
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "./MetusSvgIcon";

class ViewsIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <g>
        <path
            d="M26,14 L26,10 L32,10 L32.001,14 L26,14 Z M26,22 L32,22 L32.001,26 L26,26 L26,22 Z M5,20 L5,16 L11,16 L11.001,20 L5,20 Z M32,8 L26,8 C24.897,8 24,8.897 24,10 L24,11 L22,11 C20.105,11 18.94,12.566 17.813,14.081 C16.696,15.582 15.641,17 14,17 L13,17 L13,16 C13,14.897 12.103,14 11,14 L5,14 C3.897,14 3,14.897 3,16 L3,20 C3,21.103 3.897,22 5,22 L11,22 C12.103,22 13,21.103 13,20 L13,19 L14,19 C15.641,19 16.696,20.418 17.813,21.919 C18.94,23.434 20.105,25 22,25 L24,25 L24,26 C24,27.103 24.897,28 26,28 L32,28 C33.103,28 34,27.103 34,26 L34,22 C34,20.897 33.103,20 32,20 L26,20 C24.897,20 24,20.897 24,22 L24,23 L22,23 C21.11,23 20.326,21.946 19.418,20.726 C18.726,19.796 17.931,18.75 16.907,18 C17.931,17.25 18.726,16.204 19.418,15.274 C20.326,14.054 21.11,13 22,13 L24,13 L24,14 C24,15.103 24.897,16 26,16 L32,16 C33.103,16 34,15.103 34,14 L34,10 C34,8.897 33.103,8 32,8 L32,8 Z"
            />
      </g>
    </React.Fragment>
  }

  viewBox(): string {
    return "0 0 36 36";
  }
}

export default withStyles(svgIconStyles)(ViewsIcon);
