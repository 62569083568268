import {list, object, serializable} from "serializr";
import {observable} from "mobx";
import {FilteredHierarchy} from "./FilteredHierarchy";
import {IFilter} from "../../core/utils/filter/Filter";
import {FilterDefinition} from "./FilterDefinition";
import {IStaticFilterMatcher} from "../../core/utils/filter/static/IStaticFilterMatcher";
import {ContainsCaseInsensitiveStringStaticFilterMatcher} from "../../core/utils/filter/static/ContainsCaseInsensitiveStringStaticFilterMatcher";
import {OrStaticFilterMatcher} from "../../core/utils/filter/static/OrStaticFilterMatcher";
import {StaticAttributeFilter} from "../../core/utils/filter/static/StaticAttributeFilter";
import {GreaterThanStaticFilterMatcher} from "../../core/utils/filter/static/GreaterThanStaticFilterMatcher";
import {Simulate} from "react-dom/test-utils";
import {FilterDefinitionUpdate} from "../../commonviews/actions/SharedViewActions";

export class ColumnFilteredHierarchy extends FilteredHierarchy {

  @serializable(list(object(FilterDefinition))) @observable private _filterDefinitions: FilterDefinition[];

  constructor(filterDefinitions:FilterDefinition[] = []) {
    super();
    this._filterDefinitions = filterDefinitions;
  }

  private getOredMatcher(matchers:IStaticFilterMatcher[]):IStaticFilterMatcher {
    let result;
    if (matchers.length > 1) {
      result = new OrStaticFilterMatcher(...matchers);
    } else {
      result = matchers[0];
    }
    return result;
  }

  public getFilterForTable(tableId: string): IFilter[] {
    const result = [];
    this.filterDefinitions.forEach(filterDefinition => {
      const attributeName = filterDefinition.attributeName;
      return filterDefinition.tableIds.filter(id => id === tableId).forEach( tableId => {
        if (filterDefinition.filterRules.length > 0) {
          const matchers = filterDefinition.filterRules.map( filterRule => {
            // todo: check attributeformattype here and create matcher accordingly
            switch (filterRule.type) {
              case "contains":
                return new ContainsCaseInsensitiveStringStaticFilterMatcher(filterRule.expression);
              case "greaterThan":
                return new GreaterThanStaticFilterMatcher(filterRule.expression);
              default:
                throw new Error("Unknown filter rule type: " + filterRule.type);
            }

          });
          result.push(new StaticAttributeFilter(attributeName, this.getOredMatcher(matchers)))
        }
      });
    });
    return result;
  }

  get filterDefinitions(): FilterDefinition[] {
    return this._filterDefinitions;
  }

  removeFilterDefinition(idToBeDeleted:string) {
    const idx = this._filterDefinitions.findIndex(filterDefinition => filterDefinition.id === idToBeDeleted);
    if (idx !== -1) {
      this._filterDefinitions.splice(idx, 1);
    }
  }

  addFilterDefinition(filterDefinition: FilterDefinition): void {
    this._filterDefinitions.push(filterDefinition);
  }

  updateFilterDefinition(update: FilterDefinitionUpdate) {
    const filterDefinition = this._filterDefinitions.find(filterDefinition => filterDefinition.id === update.id);
    if (filterDefinition) {
      for (let key in update) {
        if (key !== "id") {
          filterDefinition[key] = update[key];
        }
      }
    }
  }
}