import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {CockpitIconType, getListItemTypeForName, TreeItemType, ViewType} from "../../common/constants/Enums";
import {NavigationItemId} from "../actions/NavigationActions";
import {HierarchyEntry, isFolderEntry, isViewEntry} from "../../api/api";
import LinkedList from "../../common/utils/LinkedList";
import {MoveTreeItemToNewPositionPayload, TreeItemPayload} from "../actions/NavigationPayloads";

const log = Log.logger("MetusStore", Classifier.action);

export interface ListItemHierarchy {
  id?: string;
  type: TreeItemType;
  name?: string;
  children?: LinkedList<ListItemHierarchy>;
  viewType?: ViewType;
  svgIconColor?: string;
  svgIconType?: CockpitIconType;
  viewVersion: number;
}

export function addTreeItem(root: ListItemHierarchy, id: NavigationItemId, payload: TreeItemPayload): void {
  log.debug("Add left Tree Item");
  const newItem = {
    id,
    name: payload.name,
    type: payload.type,
    viewVersion: payload.viewVersion
  } as ListItemHierarchy;

  if (payload.viewType !== undefined) {
    newItem.viewType = payload.viewType;
  }

  if (payload.svgIconColor !== undefined) {
    newItem.svgIconColor = payload.svgIconColor;
  }

  if (payload.svgIconType !== undefined) {
    newItem.svgIconType = payload.svgIconType;
  }

  const parentFolder: ListItemHierarchy = findTreeItem(root, payload.parentId);

  if (parentFolder) {
    if (parentFolder.children === undefined) {
      parentFolder.children = new LinkedList<ListItemHierarchy>(newItem);
    } else {
      parentFolder.children.insertTail(newItem);
    }
  }
}

export function renameTreeItem(root: ListItemHierarchy, id: string, newName: string): void {
  const treeItem: ListItemHierarchy = findTreeItem(root, id);
  if (treeItem === null) {
    log.error("Could not rename tree item, because it does not exist", id);
  } else {
    log.debug(`Renaming tree Item ${id} to ${newName}`);
    treeItem.name = newName;
  }
}

export function moveTreeItemToNewPosition(root: ListItemHierarchy, payload: MoveTreeItemToNewPositionPayload): ListItemHierarchy {
  const sourceTreeItem = findTreeItem(root, payload.sourceId);
  const targetTreeItem = findTreeItem(root, payload.targetId);
  const targetTree = findSubtreeWhereItemWithIdLivesIn(root, payload.targetId);
  const sourceTree = findSubtreeWhereItemWithIdLivesIn(root, payload.sourceId);
  const itemsInTargetTree = targetTree.children;
  const itemsInSourceTree = sourceTree.children;
  const targetIndex = itemsInTargetTree.indexOf(targetTreeItem);
  const sourceIndex = itemsInSourceTree.indexOf(sourceTreeItem);

  if (sourceTree === targetTree) {
    if (sourceIndex > targetIndex) {
      itemsInTargetTree.insertAt(sourceTreeItem, targetIndex);
      itemsInSourceTree.removeAt(sourceIndex + 1);
    } else if (sourceIndex < targetIndex) {
      itemsInTargetTree.insertAt(sourceTreeItem, targetIndex + 1);
      itemsInSourceTree.removeAt(sourceIndex);
    }
  } else {
    itemsInTargetTree.insertAt(sourceTreeItem, targetIndex);
    itemsInSourceTree.removeAt(sourceIndex);
  }

  return root;
}

export function moveTreeItemToFolder(root: ListItemHierarchy, sourceId: string, targetId: string): void {
  const sourceTreeItem = findTreeItem(root, sourceId);
  if (sourceTreeItem == null) {
    log.error("Could not move tree item, because source-id does not exist", sourceId);
    return;
  }

  const targetTreeItem = findTreeItem(root, targetId);
  if (targetTreeItem == null) {
    log.error("Could not move tree item, because target-id does not exist", targetId);
    return;
  }

  // remove source from old parent
  const oldParent = findSubtreeWhereItemWithIdLivesIn(root, sourceId);

  // Add source to new parent
  if (targetTreeItem.children === undefined) {
    targetTreeItem.children = new LinkedList<ListItemHierarchy>(sourceTreeItem);
  } else {
    targetTreeItem.children.insertTail(sourceTreeItem);
  }

  oldParent.children.remove(sourceTreeItem);

  // connect all children of source to source's old parent if parent is moved into its own subtree
  if (sourceTreeItem.children !== undefined && findTreeItem(sourceTreeItem, targetId)) {
    oldParent.children.insertValuesAtEndOfList(sourceTreeItem.children.toArray());
    sourceTreeItem.children = undefined;
  }
}

export function deleteTreeItem(current: ListItemHierarchy, id: string): void {
  if (current && current.type === TreeItemType.Folder && current.children) {

    current.children.forEach(node => {
      const currentChild = node.value;

      if (currentChild.type === TreeItemType.Folder && currentChild.children && currentChild.children.size() > 0) {
        deleteTreeItem(currentChild, id);
      } else if (id === currentChild.id) {
        // delete folder only if it is empty
        current.children.remove(currentChild);
      }

    });

  }
}

export function transformHierarchyJsonToListItemHierarchy(json: HierarchyEntry): ListItemHierarchy {
  let result: ListItemHierarchy = null;

  if (json && json.type !== undefined) {
    result = {
      type: getListItemTypeForName(json.type),
      name: json.name,
      id: json.uuid,
      // if a view has no viewVersion (because it is too old), then it's set to 0
      viewVersion: (json as any).props !== undefined && (json as any).props.viewVersion !== undefined ? (json as any).props.viewVersion as number : 0
    };

    if (isFolderEntry(json) && json.children) {
      const treeItems: ListItemHierarchy[] = json.children.map(child => transformHierarchyJsonToListItemHierarchy(child));
      result.children = new LinkedList(treeItems);
    }

    if (isViewEntry(json)) {
      if (json.viewType) {
        result.viewType = ViewType[json.viewType] as any;
      }
      if (json.props && json.props.svgIconColor) {
        result.svgIconColor = json.props.svgIconColor;
      }
      if (json.props && json.props.svgIconType) {
        result.svgIconType = CockpitIconType[json.props.svgIconType] as any;
      }
    }

    if (result.type === TreeItemType.Table) {
      result.viewType = ViewType.Table;
    }

  }
  return result;
}

/* Tree traversal */

export function findTreeItem(tree: ListItemHierarchy, id: string): ListItemHierarchy {
  let result = null;
  if (tree) {
    if (tree.id === id) {
      result = tree;
    } else {
      if (tree.children !== undefined) {
        for (const child of tree.children) {
          result = findTreeItem(child.value, id);
          if (result != null) {
            break;
          }
        }
      }
    }
  }
  return result;
}

export function findSubtreeWhereItemWithIdLivesIn(tree: ListItemHierarchy, id: string): ListItemHierarchy {
  let result = null;

  if (tree.children !== undefined && id !== undefined) {
    for (const child of tree.children) {
      if (child.value.id === id) {
        result = tree;
      } else {
        result = findSubtreeWhereItemWithIdLivesIn(child.value, id);
      }
      if (result !== null) {
        break;
      }
    }
  }
  return result;
}

export function findSuccessorId(root: ListItemHierarchy, id: string): string {
  let retVal: string = undefined;

  const treeItem = findTreeItem(root, id);
  if (treeItem == null) {
    log.error("No tree item with this id found", id);
    return retVal;
  }

  const tree = findSubtreeWhereItemWithIdLivesIn(root, id);
  if (tree) {
    return tree.children?.getNextValue(treeItem)?.id;
  }

  return retVal;
}

export function findPredecessorId(root: ListItemHierarchy, id: string): string {
  let retVal: string = undefined;

  const treeItem = findTreeItem(root, id);
  if (treeItem == null) {
    log.error("No tree item with this id found", id);
    return retVal;
  }

  const tree = findSubtreeWhereItemWithIdLivesIn(root, id);
  if (tree) {
    return tree.children?.getPreviousValue(treeItem)?.id;
  }

  return retVal;
}

export function calcMovingDirection(root: ListItemHierarchy, sourceId: string, targetId: string): boolean {
  const sourceTreeItem = findTreeItem(root, sourceId);
  const targetTreeItem = findTreeItem(root, targetId);
  const sourceTree = findSubtreeWhereItemWithIdLivesIn(root, sourceId);
  const targetTree = findSubtreeWhereItemWithIdLivesIn(root, targetId);
  const itemsInSourceTree = sourceTree.children;
  const itemsInTargetTree = targetTree.children;
  const sourceIndex = itemsInSourceTree.indexOf(sourceTreeItem);
  const targetIndex = itemsInTargetTree.indexOf(targetTreeItem);

  if (sourceTree === targetTree) {
    return sourceIndex < targetIndex;
  } else {
    return undefined;
  }

}
