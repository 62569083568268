// lib imports
import * as React from "react";
import "../../../common/css/metusapp.css";
import {VisualConnection} from "../../models/chart/VisualConnection";
import {observer} from "mobx-react";
import Log from "../../../common/utils/Logger";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {VisualChartElement} from "../../models/chart/VisualChartElement";

const log = Log.logger("diagram");
const renderLog = Log.logger("diagram", Classifier.render);

interface LocalProps {
  visualConnection: VisualConnection;
  hidden: boolean;
  animationCount?: number;
}

// App pure component
@observer
export class ConnectionComponent extends React.Component<LocalProps, any> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    const visualConnection = this.props.visualConnection;
    renderLog.debug("Rendering ConnectionComponent", visualConnection);
    let source: VisualChartElement, target: VisualChartElement;
    if (visualConnection.source.x < visualConnection.target.x) {
      source = visualConnection.source;
      target = visualConnection.target;
    } else {
      source = visualConnection.target;
      target = visualConnection.source;
    }
    const x1 = source.x + source.width;
    const y1 = source.y + source.height / 2;
    const x2 = target.x;
    const y2 = target.y + target.height / 2;
    const testSelector = "connection/" + source.id.elementId + "/" + target.id.elementId;
    const xc = (x2 + x1) / 2;
    const path = `M ${x1} ${y1} C ${xc} ${y1} ${xc} ${y2} ${x2} ${y2}`;
    return <path data-testselector={testSelector} d={path} visibility={this.props.hidden ? "hidden" : "visible"}/>;
  }

  private getWidth(width: number): number {
    return width || 150;
  }
}