// lib imports
import * as React from "react";
import Log from "../../../common/utils/Logger";
import {AttributeDefinitionComponent} from "./AttributeDefinitionComponent";
import {ViewerContext} from "../../utils/ViewerContext";
import {VisualAttributeIdString} from "../../../core/utils/Core";
import {observer} from "mobx-react";
import {VisualAttributeDefinition} from "../../models/common/VisualAttributeDefinition";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {VisualBaseTable} from "../../models/common/CommonDiagramTypes";
import autobind from "autobind-decorator";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";

const log = Log.logger("diagram");
const renderLog = Log.logger("diagram", Classifier.render);

/**
 * list of Attribute columns
 * @author Marco van Meegen
 *
 */
interface LocalProps {
  viewerContext: ViewerContext;
  table: VisualBaseTable;
  viewerFilters: Map<VisualAttributeIdString, string>;
  filterTextsValidities: Map<VisualAttributeIdString, boolean>;
  supportsAtFilter?: boolean;
  isFilterVisible?: boolean;
}

@observer
export class AttributeDefinitionsComponent extends React.Component<LocalProps, any> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug(`Rendering AttributeDefinitionsComponent for table ${this.props.table.id}`);
    const attributes = Array.from(this.props.table.visualAttributeDefinitions.values()).sort((a, b) => a.header.x - b.header.x);
    const lastIndex = attributes.length - 1;
    const attributeTitleSeparatorY = DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + 2 * DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH + this.props.table.attributeHeaderHeight;
    return <g>
      <line style={{stroke: "#808080", strokeWidth: "1px"}} x1={this.props.table.header.x}
            y1={DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH}
            x2={this.props.table.header.x+this.props.table.header.width}
            y2={DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH}/>
      {this.props.isFilterVisible ? <line style={{stroke: "#808080", strokeWidth: "1px"}} x1={0}
                                          y1={attributeTitleSeparatorY}
                                          x2={this.props.table.header.width}
                                          y2={attributeTitleSeparatorY}/> : undefined}

      {attributes.map((visualAttributeDefinition: VisualAttributeDefinition, index: number) =>
          <AttributeDefinitionComponent key={visualAttributeDefinition.id.toKey()}
                                        viewerContext={this.props.viewerContext}
                                        attribute={visualAttributeDefinition}
                                        attributeTitleHeight={this.props.table.attributeHeaderHeight}
                                        attributeHeaderRotation={this.props.table.attributeHeaderRotation}
                                        filterText={this.getFilterTextForAttribute(visualAttributeDefinition.id.toKey())}
                                        filterTextIsValid={this.getFilterTextValidityForAttribute(visualAttributeDefinition.id.toKey())}
                                        drawSeparator={index !== this.props.table.visualAttributeDefinitions.size - 1}
                                        supportsAtFilter={this.props.supportsAtFilter}
                                        isFilterVisible={this.props.table.isFilterVisible}
          />)}
    </g>;
  }

  @autobind
  private getFilterTextForAttribute(id: VisualAttributeIdString): string {
    // const id: VisualTableId = null;
    let filterTextForAttribute: string = "";
    this.props.viewerFilters.forEach((filterText: string, key: VisualAttributeIdString): void => {
      if (id === key)
        filterTextForAttribute = filterText;
    });

    return filterTextForAttribute;
  }

  @autobind
  private getFilterTextValidityForAttribute(id: VisualAttributeIdString): boolean {
    let filterTextValidity: boolean = true;
    this.props.filterTextsValidities.forEach((isValid: boolean, key: VisualAttributeIdString): void => {
      if (id === key)
        filterTextValidity = isValid;
    });

    return filterTextValidity;
  }
}


