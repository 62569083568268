import {AttributeDefinition, NAME_ATT_NAME} from "../../api/api";

/**
 * comparer for attribute definitions, case independent lexical order, name attribute is always first
 * @param attributeDefinition1
 * @param attributeDefinition2
 */
export function compareAttributeDefinitions(attributeDefinition1: AttributeDefinition, attributeDefinition2: AttributeDefinition): number {
  let result;
  if (attributeDefinition1.name.toLowerCase() === NAME_ATT_NAME) {
    result = attributeDefinition2.name.toLowerCase() === NAME_ATT_NAME ? 0 : -1;
  } else {
    result = attributeDefinition1.name.toLowerCase().localeCompare(attributeDefinition2.name.toLowerCase());
  }
  return result;
}
