import * as React from "react";
import autobind from "autobind-decorator";
import {
  Typography,
  Button, Dialog, withStyles, Theme, StyleRules, createStyles, WithStyles, IconButton
} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {hideDialog} from "../utils/CommonDialogUtil";
import {Classifier} from "../utils/ClassifierLogger";
import Log from "../../common/utils/Logger";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

export interface DialogProps {
  open: boolean;
  title: string;
  dialogContent: JSX.Element | JSX.Element[];
  onSubmit: any;
}

export const dialogContentStyles = (theme: Theme): StyleRules => {

  return createStyles({
    dialogContent: {
      display: "grid",
      alignItems: "center",
      gridRow: 2,
      gridColumn: "span 2"
    },
    sectionLabel: {
      fontSize: "17px",
      fontWeight: "bold",
      color: theme.metus.dialog.primaryText,
      marginBottom: "22px",
      marginTop: "10px"
    },
    section: {
      display: "grid",
      alignItems: "center"
    },
    inputLabel: {
      fontSize: "16px",
      color: theme.metus.dialog.primaryText,
    },
    inputSmall: {
      maxWidth: "50px"
    },
    inputMedium: {
      maxWidth: "100px"
    },
    inputLarge: {
      maxWidth: "150px"
    },
    toggleButtonSelected: {
      margin: "5px 5px 5px 5px",
      backgroundColor: "#0000001F",
    },
    toggleButton: {
      margin: "5px 5px 5px 5px",
    }
  });
}

const styles = (theme: Theme): StyleRules => {

  const margin = {
    margin: `${theme.spacing(3)}px ${theme.spacing(3)}px 0px ${theme.spacing(3)}px`,
  }

  return createStyles({
    dialogTitle: {
      fontSize: "20px",
      fontWeight: "bold",
      color: theme.metus.dialog.primaryText,
    },
    dialogContent: {
      ...margin,
      gridRow: 2,
      gridColumn: "span 2",
      display: "grid",
    },
    header: {
      ...margin,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      gridRow: 1,
      gridColumn: "span 2"
    },
    closeIconButton: {
      color: theme.palette.secondary[600],
    },
    closeIcon: {
      height: "16px",
      width: "16px",
    },
    buttons: {
      ...margin,
      marginBottom: "25px",
      gridRow: 3,
      gridColumn: 2,
      display: "flex",
      justifyContent: "space-between",
    },
    buttonContained: {
      width: "auto",
      minWidth: "140px",
      backgroundColor: theme.metus.dialog.buttonFill,
      height: "auto",
      marginLeft: "auto",// align to the right if there is only one button
    },
    footer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "80px",
      backgroundColor: "#de323c",
      gridRow: 5,
      gridColumn: "span 2"
    },
    cancelIconContainer: {
      alignSelf: "center",
      color: theme.palette.primary[50],
      paddingLeft: "16px",
    },
    cancelIcon: {
      height: "40px",
      width: "40px",
    },
    errorTextContainer: {
      alignSelf: "center",
      paddingLeft: "160px",
    },
    typographyErrorText: {
      fontSize: "21px",
      fontWeight: 500,
      color: theme.palette.primary[50],
    }
  });
};

type StyledLocalProps = DialogProps & WithStyles<typeof styles>;

class BaseDialog<T extends StyledLocalProps> extends React.Component<StyledLocalProps> {
  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering BaseDialog");
    const {classes} = this.props;

    return <Dialog
        data-testselector="BaseDialog"
        scroll="paper"
        classes={{paperScrollPaper: classes.paperScrollPaper}}
        disableBackdropClick={true}
        open={this.props.open}
        onClose={this.handleClose}>

      <div className={classes.header}>
        <Typography className={classes.dialogTitle}>{this.props.title}</Typography>
        <IconButton onClick={this.handleClose} data-testselector="closeDialogButton"
                    classes={{root: classes.closeIconButton}}>
          <Close classes={{root: classes.closeIcon}}/>
        </IconButton>
      </div>

      <div className={classes.dialogContent}>{this.props.dialogContent}</div>


      <div className={classes.buttons}>
        <Button
            data-testselector={"SubmitButton"}
            classes={{contained: classes.buttonContained}}
            variant={"contained"}
            type="button"
            onClick={this.handleSubmit}>{"Ok"}
        </Button>
      </div>
    </Dialog>;
  }

  @autobind
  private handleClose(): void {
    hideDialog();
  }

  @autobind
  private handleSubmit(): void {
    this.props.onSubmit();
  }
}

export default withStyles(styles)(BaseDialog);
