import {AttributeFormatType} from "../../../api/api";
import {Validate} from "../../../common/utils/Validate";
import {VisualValueChartElement} from "../../../diagram/models/valuechart/VisualValueChartElement";
import {VisualAttributeDefinition} from "../../../diagram/models/common/VisualAttributeDefinition";
import {VisualChartElement} from "../../../diagram/models/chart/VisualChartElement";
import {VisualAttributeId} from "../Core";

/**
 * attributeValueComparator comparing two strings in Metus internal representation
 * @param a internal representation of an attribute value to compare
 * @param b internal representation of an attribute value to compare
 * @param type attribute type: double, string, date
 * @param ignoreCase only applicable for string type attributes, case would be ignored in comparison
 * @return -1,0,1 if a </=/> b
 */
export function attributeValueComparator(a: string, b: string, type: AttributeFormatType, ignoreCase: boolean = false): number {
  let result: number;
  let definedA, definedB;
  switch (type) {
    case "Double":
      // parseFloat can translate undefined,null to NaN, thus no definedA needed here
      const numericA = Number.parseFloat(a);
      const numericB = Number.parseFloat(b);

      result = numberComparator(numericA, numericB);
      // ensure that
      break;
    case "Date":
      // date in metus internal format is yyyymmdd, thus lexicographic comparison works
      // oops, year 10000 problem, this saves my pension ;-)
      definedA = a ?? "99991231";
      definedB = b ?? "99991231";
      result = definedA.localeCompare(definedB);
      break;
    case "String":
      definedA = a ?? "";
      definedB = b ?? "";
      result = segmentedStringComparator(definedA, definedB, ignoreCase);
      break;

    default:
      Validate.isTrue(false, "Attribute Type " + type + " not implemented!");
      break;
  }
  return result;
}

/**
 * HOF to negate the result of a comparator for descending sort order
 * @param comparator
 */
export function negate<P>(comparator: (a: P, b: P) => number): (a: P, b: P) => number {
  return (a: P, b: P) => -comparator(a, b);
}

/**
 * Compares elements from Chart and Value Chart
 * has to be used with lodash partialRight to use it as a compare function in array.sort()
 * @param a
 * @param b
 * @param visualAttributeDefinition
 * @param visualAttributeId
 */
export function chartElementComparator(a: VisualValueChartElement | VisualChartElement,
                                       b: VisualValueChartElement | VisualChartElement,
                                       visualAttributeDefinition: VisualAttributeDefinition,
                                       visualAttributeId: VisualAttributeId): any {
  const aAttValue = a.attributeValues.get(visualAttributeId.attributeName)?.value;
  const bAttValue = b.attributeValues.get(visualAttributeId.attributeName)?.value;
  // this can deal with undefined, null, NaN and wtf
  // if no format type available, use string
  return attributeValueComparator(aAttValue, bAttValue, visualAttributeDefinition.attributeDefinition.formatType ?? "String");
}

/**
 * Ported from Metus Classic
 * Compares numbers in string format correctly
 * See tests for better comprehension
 * @param a
 * @param b
 * @param ignoreCase
 */
export function segmentedStringComparator(a: string, b: string, ignoreCase: boolean = false): number {
  let result: number;
  let s1: string, s2: string;
  s1 = a ?? "";
  s2 = b ?? "";

  let index: number = 0;
  let s1len = s1.length;
  let s2len = s2.length;

  while (
      index < s1len
      && index < s2len
      && s1.charAt(index) === s2.charAt(index)
      && isNaN(Number(s1.charAt(index)))
      && isNaN(Number(s2.charAt(index)))) {
    index++;
  }

  s1 = s1.substring(index);
  s2 = s2.substring(index);
  s1len = s1.length;
  s2len = s2.length;

  if (s1len === 0 && s2len === 0) return 0;
  else if (s1len === 0) return -1;
  else if (s2len === 0) return 1;

  let index1: number = 0;
  while (index1 < s1len && !isNaN(Number(s1.charAt(index1)))) {
    index1++;
  }

  let index2: number = 0;
  while (index2 < s2len && !isNaN(Number(s2.charAt(index2)))) {
    index2++;
  }

  if (index1 > 0 && index2 > 0) {
    const number1 = Number(s1.substring(0, index1));
    const number2 = Number(s2.substring(0, index2));

    result = numberComparator(number1, number2);

    if (result !== 0 && result !== -0) {
      return result;
    } else {
      const remainder1 = s1.substring(index1);
      const remainder2 = s2.substring(index2);
      return segmentedStringComparator(remainder1, remainder2, ignoreCase);
    }
  } else {
    const s1FirstChar = s1.substring(0, 1);
    const s2FirstChar = s2.substring(0, 1);
    return ignoreCase ?
        s1FirstChar.toLocaleUpperCase().localeCompare(s2FirstChar.toLocaleUpperCase()) :
        s1FirstChar.localeCompare(s2FirstChar);
  }
}

function numberComparator(a: number, b: number): number {
  const sign = Math.sign(a - b);
  if (Number.isFinite(sign)) {
    // both must be regular numbers, return sign of diff
    return sign;
  } else if ((Number.isFinite(a) || a === Number.NEGATIVE_INFINITY) && !(b === Number.NEGATIVE_INFINITY)) {
    // a number is smaller than any strange value but negative infinity
    return -1;
  } else {
    // otherwise test on equality or return bigger
    return a === b || (Number.isNaN(a) && Number.isNaN(b)) ? 0 : 1;
  }
}
