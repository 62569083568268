import * as React from "react";
import {ChangeEvent} from "react";

import {Classifier} from "../../../../common/utils/ClassifierLogger";
import Log from "../../../../common/utils/Logger";
import autobind from "autobind-decorator";
import TextFieldComponent from "../../../../common/components/materialuiderived/TextFieldComponent";
import {observer} from "mobx-react";
import FilteredExpressionIcon from "../../../../properties/icons/FilterExpressionIcon";
import {action} from "mobx";
import {FilterRuleType, IFilterRule} from "../../../models/FilterRule";
import DropDownItemComponent from "../../../../common/components/materialuiderived/DropDownItemComponent";
import DropDownComponent from "../../../../common/components/materialuiderived/DropDownComponent";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";

const log = Log.logger("properties");
const renderLog = Log.logger("MatrixFilterPropertiesSection", Classifier.render);

export interface LocalProps {
  filterRule: IFilterRule;
}

const styles = (theme: Theme): StyleRules => createStyles({
  typeDropDown: {
    width: "160px",
  }
});

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

@observer
class AttributeFilterRuleComponent extends React.Component<StyledLocalProps, any> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  @autobind
  @action
  private handleFilterTextChange(event: ChangeEvent<{ value: string }>): void {
    const newFilterText = event.target.value;
    log.debug("Update filter rule expression", newFilterText);
    this.props.filterRule.expression = newFilterText;
  }

  @autobind
  @action
  private handleFilterTypeChange(event: ChangeEvent<{ value: string }>): void {
    const newFilterType = event.target.value as FilterRuleType;
    log.debug("Update filter rule expression", newFilterType);
    this.props.filterRule.type = newFilterType;
  }

  private getAllFilterRulyTypesAndNames():{key: FilterRuleType, value: string}[] {
    return [{key: "contains", value: "contains"}, {key: "greaterThan", value: ">"}]
  }

  render(): JSX.Element {
    log.debug("Rendering Attribute Filter Rule Component", this.props);
    let filterRule = this.props.filterRule;
    return <React.Fragment>
      <FilteredExpressionIcon/>
      <DropDownComponent
          value={this.props.filterRule.type}
          onChange={this.handleFilterTypeChange}
          classes={{textFieldRoot: this.props.classes.typeDropDown}}
          variant="outlined"
      >
        {this.getAllFilterRulyTypesAndNames().map(typeAndName => <DropDownItemComponent key={typeAndName.key}
                                                                                        value={typeAndName.key}>{typeAndName.value}</DropDownItemComponent>)}
      </DropDownComponent>
      <TextFieldComponent value={filterRule.expression} onChange={this.handleFilterTextChange} variant="outlined"/>
    </React.Fragment>;
  }

}

export default withStyles(styles)(AttributeFilterRuleComponent);