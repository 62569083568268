import * as React from "react";
import {SvgIcon, Theme} from "@material-ui/core";
import {createStyles, StyleRules, withStyles, WithStyles} from "@material-ui/styles";

const styles = (theme: Theme): StyleRules => createStyles({
  root: {
    fill: theme.palette.secondary[700],
    height: "24px",
    width: "24px",
  },
});

interface LocalProps {
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class AddRowTableHeaderIcon extends React.Component<StyledLocalProps, any> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    return <SvgIcon viewBox="0 0 24 24" className={this.props.classes.root}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.5">
        <path
            d="M7.9998,3 L7.9998,8 L3.0008,8 L3.0008,21 L7.9998,21 L8.9998,21 L21.0008,21 L21.0008,9 L21.0008,8 L21.0008,3 L7.9998,3 Z M8.9998,8.001 L20.0008,8.001 L20.0008,4 L8.9998,4 L8.9998,8.001 Z M8.9998,20 L20.0008,20 L20.0008,9 L8.9998,9 L8.9998,20 Z"
            fill="#000000"></path>
      </g>
    </SvgIcon>;
  }
}

export default withStyles(styles)(AddRowTableHeaderIcon);