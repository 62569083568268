import * as React from "react";
import {createStyles, MenuItem, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles/withStyles";


const styles = (theme: Theme):StyleRules => createStyles({
  selectMenuItemRoot: {
    color: "rgba(0, 0, 0, 0.8)",
    fontSize: "12px",
    fontWeight: "normal",
    minHeight: "inherit",
  },
});


interface LocalState {
}

// vM20200528: HACK typeof MenuItem does not seem to include value, thus add it here to fix typescript errors
type LocalProps = typeof MenuItem & { value: any } & WithStyles<typeof styles>;

class DropDownItemComponent extends React.Component<LocalProps, LocalState> {

  render(): JSX.Element {
    return <MenuItem
        {...this.props}
        classes={{root: this.props.classes.selectMenuItemRoot}}>
      {this.props.children}
    </MenuItem>;
  }
}

export default withStyles(styles)(DropDownItemComponent);