import {ElementId, ElementObject, VisualTableId} from "../../core/utils/Core";
import {modelStore} from "../../core/stores/ModelStore";
import {MatrixCellConfiguration} from "./MatrixCellConfiguration";
import {createConnection, deleteConnection, updateConnection} from "../../core/services/CoreDataServices";

export interface IMatrixCellValueProvider<T = any> {
  getCellValue(rowElementId: ElementId, columnElementId: ElementId): T;

  /**
   * sets the cell value; if not defined, cell is not editable
   * @param rowElementId
   * @param columnElementId
   * @param value
   */
  setCellValue?(rowElementId: ElementId, columnElementId: ElementId, value: T): void;
}

/**
 * simple matrix cell provider returns the connection strength if a connection exists between row and column element, otherwise returns undefined
 * @param rowElementId
 * @param columnElementId
 */
export class SimpleCellValueProvider implements IMatrixCellValueProvider<number> {
  getCellValue(rowElementId: ElementId, columnElementId: ElementId): number {
    const connection = modelStore.getConnection(rowElementId, columnElementId);
    return connection ? connection.strength : undefined;
  }

  /**
   * setter will create or modify existing connection if strength is given, delete a connection if strength is undefined
   * @param rowElementId
   * @param columnElementId
   * @param strength
   */
  setCellValue(rowElementId: ElementId, columnElementId: ElementId, strength: number): void {
    const connectedElements = modelStore.getConnectedElements(rowElementId);
    const isConnected: boolean = connectedElements.has(columnElementId);
    if (strength !== undefined && strength !== null) {
      if (isConnected) {
        // edit strength
        updateConnection(rowElementId, columnElementId, strength);
      } else {
        // create new connection
        createConnection(rowElementId, columnElementId, strength);
      }
    } else {
      // delete connection
      if (isConnected) {
        deleteConnection(rowElementId, columnElementId);
      }
    }
  }
}

/**
 * Join Table matrix cell provider returns all elements of the join table connected to both row and column element, then <b>Join Elements</b>.
 */
export class JoinTableCellValueProvider implements IMatrixCellValueProvider<ElementObject[] | number> {
  /**
   *
   * @param _joinTableId join table id
   * @param _joinTableAttributeName this attribute of Join Elements is shown in the cell concatenated by ";" if more than one; if undefined is passed, the count of Join Elements is shown
   */
  constructor(private _joinTableId: VisualTableId, private _joinTableAttributeName?: string) {

  }

  getCellValue(rowElementId: ElementId, columnElementId: ElementId): ElementObject[] | number {
    const connectedElements = modelStore.getConnectedElementIdsToTable(rowElementId, this.joinTableId.tableId);
    const joinElements = connectedElements.filter(elementId => modelStore.isConnected(elementId, columnElementId));
    return this.joinTableAttributeName ? joinElements.map(elementId => modelStore.getElement(elementId)[this.joinTableAttributeName]) : joinElements.length;

  }

  public get joinTableId(): VisualTableId {
    return this._joinTableId;
  }

  public get joinTableAttributeName(): string {
    return this._joinTableAttributeName;
  }
}

/**
 * creates a suitable IMatrixCellValueProvider implementation for the given cell configuration
 * @param matrixCellConfiguration
 */
export function MatrixCellValueProviderFactory(matrixCellConfiguration: MatrixCellConfiguration): IMatrixCellValueProvider {
  let result: IMatrixCellValueProvider;
  if (matrixCellConfiguration.joinTableId) {
    // join table matrix showing attribute values of join elements or count
    result = new JoinTableCellValueProvider(matrixCellConfiguration.joinTableId, matrixCellConfiguration.showCount ? undefined : matrixCellConfiguration.joinTableAttributeName);
  } else {
    // standard matrix showing connection strength
    result = new SimpleCellValueProvider();
  }
  return result;
}
