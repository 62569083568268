import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";



class CockpitsFolderIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <path d="M1,2 L8,2 L10,4.001 L23,4.001 L23,21 L1,21 L1,2 Z M8.238,9.691 L12.006,12.382 L15.772,9.691 L12.004,7 L8.238,9.691 Z M3.604,13.001 L7.372,15.692 L11.146,12.996 L7.378,10.305 L3.604,13.001 Z M12.867,12.996 L16.637,15.691 L20.404,13.001 L16.632,10.306 L12.867,12.996 Z M8.232,16.306 L12.004,19 L15.778,16.305 L12.006,13.611 L8.232,16.306 Z M14.5864,12.9964 L16.6324,11.5354 L18.6834,13.0004 L16.6374,14.4614 L14.5864,12.9964 Z"/>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(CockpitsFolderIcon);