/* ConceptsIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, März 2019
 */
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";
import {withStyles} from "@material-ui/core";
import * as React from "react";

class ConceptsIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <style type="text/css">{`
      .st0concept{fill:#FFFFFF;fill-opacity:0.5;}
      .st1concept{fill:#FFFFFF;}
     `}</style>
      <path className="st0concept" d="M64.54,48.12h-3.05c-0.34-1.63-0.98-3.16-1.83-4.53c0.03-0.02,0.05-0.04,0.08-0.06l2.18-2.18
	c0.53-0.53,0.53-1.4,0-1.93l-2.18-2.18c-0.53-0.53-1.4-0.53-1.93,0l-2.14,2.14c-1.53-1.07-3.28-1.84-5.17-2.24v-3.07
	c0-0.75-0.61-1.36-1.36-1.36h-3.08c-0.75,0-1.36,0.61-1.36,1.36v3.07c-1.77,0.37-3.41,1.07-4.87,2.03
	c-0.03-0.04-0.06-0.08-0.09-0.11l-2.18-2.18c-0.53-0.53-1.4-0.53-1.93,0l-2.18,2.18c-0.53,0.53-0.53,1.4,0,1.93l2.18,2.18
	c0.04,0.04,0.08,0.06,0.11,0.09c-0.96,1.46-1.66,3.1-2.03,4.87h-3.05c-0.75,0-1.36,0.61-1.36,1.36v3.08c0,0.75,0.61,1.36,1.36,1.36
	h3.05c0.39,1.89,1.17,3.64,2.24,5.17c0,0-0.01,0-0.01,0.01l-2.18,2.18c-0.53,0.53-0.53,1.4,0,1.93l2.18,2.18
	c0.53,0.53,1.4,0.53,1.93,0l2.18-2.18c0.04-0.04,0.07-0.09,0.11-0.13c1.38,0.85,2.9,1.49,4.54,1.83v3.07c0,0.75,0.61,1.36,1.36,1.36
	h3.08c0.75,0,1.36-0.61,1.36-1.36v-3.07c1.89-0.4,3.65-1.17,5.18-2.24c0.03,0.03,0.05,0.07,0.08,0.09l2.18,2.18
	c0.53,0.53,1.4,0.53,1.93,0l2.18-2.18c0.53-0.53,0.53-1.4,0-1.93l-2.18-2.18c-0.07-0.07-0.14-0.13-0.22-0.18
	c0.86-1.38,1.5-2.91,1.84-4.55h3.05c0.75,0,1.36-0.61,1.36-1.36v-3.08C65.9,48.73,65.29,48.12,64.54,48.12z M47.59,59.96
	c-4.93,0-8.93-4.01-8.93-8.93c0-4.93,4.01-8.93,8.93-8.93c4.93,0,8.93,4.01,8.93,8.93C56.53,55.95,52.52,59.96,47.59,59.96z"/>
      <path className="st1concept" d="M38.15,20.63H36.2c-0.1,0-0.19,0.03-0.28,0.06c-0.22-1.05-0.63-2.03-1.17-2.91c0.11-0.05,0.22-0.11,0.32-0.21
	l1.38-1.38c0.37-0.37,0.37-0.96,0-1.33l-1.38-1.38c-0.37-0.37-0.96-0.37-1.33,0l-1.38,1.38c-0.07,0.07-0.11,0.15-0.15,0.23
	c-0.98-0.68-2.1-1.18-3.32-1.43c0.03-0.09,0.06-0.19,0.06-0.29v-1.97c0-0.51-0.42-0.93-0.93-0.93h-1.97c-0.51,0-0.93,0.42-0.93,0.93
	v1.97c0,0.1,0.03,0.2,0.06,0.29c-1.14,0.24-2.2,0.69-3.14,1.32c-0.05-0.13-0.11-0.24-0.21-0.35l-1.38-1.38
	c-0.37-0.37-0.96-0.37-1.33,0l-1.38,1.38c-0.37,0.37-0.37,0.96,0,1.33l1.38,1.38c0.1,0.1,0.22,0.17,0.35,0.21
	c-0.62,0.94-1.08,2-1.31,3.14c-0.09-0.03-0.18-0.06-0.28-0.06h-1.95c-0.52,0-0.94,0.42-0.94,0.94v1.95c0,0.52,0.42,0.94,0.94,0.94
	h1.95c0.1,0,0.19-0.03,0.28-0.06c0.25,1.21,0.75,2.34,1.44,3.32c-0.09,0.04-0.18,0.1-0.26,0.17l-1.38,1.38
	c-0.37,0.37-0.37,0.96,0,1.32l1.38,1.38c0.37,0.37,0.96,0.37,1.32,0l1.38-1.38c0.1-0.1,0.17-0.23,0.21-0.36
	c0.89,0.55,1.88,0.97,2.93,1.19c-0.03,0.09-0.06,0.19-0.06,0.3v1.94c0,0.52,0.42,0.94,0.94,0.94H28c0.52,0,0.94-0.42,0.94-0.94
	v-1.94c0-0.11-0.03-0.2-0.06-0.3c1.22-0.25,2.35-0.76,3.34-1.46c0.05,0.12,0.11,0.24,0.21,0.34l1.37,1.37
	c0.37,0.37,0.97,0.37,1.34,0l1.37-1.37c0.37-0.37,0.37-0.97,0-1.34l-1.37-1.37c-0.13-0.13-0.28-0.19-0.44-0.23
	c0.57-0.9,0.99-1.9,1.21-2.97c0.09,0.03,0.18,0.06,0.28,0.06h1.95c0.52,0,0.94-0.42,0.94-0.94v-1.95
	C39.09,21.05,38.67,20.63,38.15,20.63z M27.03,28.7c-3.39,0-6.15-2.76-6.15-6.15s2.76-6.15,6.15-6.15c3.39,0,6.15,2.76,6.15,6.15
	S30.42,28.7,27.03,28.7z"/>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(ConceptsIcon);
