import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";
import theme from "../../theme/Theme";


class FolderIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return  <polygon fill={theme.metus.navigation.icon} points="7 10 11.9995823 15 17 10"></polygon>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(FolderIcon);