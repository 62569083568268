import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "./MetusSvgIcon";


class CheckboxIcon extends MetusSvgIcon {

  svg(): JSX.Element {
    return <g id="check_L-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.5">
      <path d="M4,20 L20,20 L20,4 L4,4 L4,20 Z M5,19 L19,19 L19,5 L5,5 L5,19 Z" id="Fill-1" fill="#000000"></path>
    </g>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(CheckboxIcon);