import * as React from "react";
import {AttributeId, ElementId, TableId} from "../../core/utils/Core";
import {
  areJoinCellEditorParams,
  elementPathKeyToLeafElementId,
  JoinCellEditorParams,
  ReferenceAttributeCellEditorParams
} from "../models/MatrixCoreDefinitions";
import autobind from "autobind-decorator";
import {getEditElementConnectionsDialog} from "../../commonviews/components/EditElementConnectionsDialog";
import {AttributeDefinition} from "../../api/api";
import {RowNode} from "ag-grid-community";


interface LocalState {
  editor: JSX.Element
}

export class MatrixConnectionsCellEditorComponent extends React.Component<JoinCellEditorParams | ReferenceAttributeCellEditorParams, LocalState> {
  constructor(props: JoinCellEditorParams | ReferenceAttributeCellEditorParams) {
    super(props);

    this.state = {editor: null};
  }

  async componentDidMount() {
    const referenceAttributeId = this.getAttributeId();
    const referencedAttributeId: AttributeId = areJoinCellEditorParams(this.props) ? this.props.joinTableAttributeId : this.props.referenceAttributeId;
    const rowElementId: ElementId = this.getRowElementId();
    const columnElementId: ElementId = areJoinCellEditorParams(this.props) ? this.props.colDef.field : undefined;
    const viewId = areJoinCellEditorParams(this.props) ? undefined : this.props.context.viewId;

    const editor = await getEditElementConnectionsDialog(
        rowElementId,
        referenceAttributeId,
        referencedAttributeId,
        viewId,
        true,
        columnElementId,
        this.props.stopEditing);

    this.setState(prevState => ({
      editor
    }));
  }

  render(): JSX.Element {
    return this.state.editor;
  }

  // this is called if the editor is closed. it is crucial in the case of cancelling (closing the dialog without submitting)
  // because otherwise the cell would remain blank (the cell is cleared once editing starts).
  getValue(): any {
    const rowNode = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex);
    const value = rowNode.data[this.props.colDef.field]
    return value;
  }

  // returns the attribute id of the column attribute if it is triggered by a cell in a rowColumn
  // returns undefined otherwise
  @autobind
  getAttributeId(): AttributeId {
    const rowNode: RowNode = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex);
    const tableId: TableId = this.props.context.getTableIdByNodeLevel(rowNode.level)
    const attributeDefinition: AttributeDefinition = areJoinCellEditorParams(this.props) ? undefined : this.props.context.getAttributeDefinitionByNodeLevel(rowNode.level, this.props.column.getColDef().field);
    return attributeDefinition ? new AttributeId(tableId, attributeDefinition.name) : undefined;
  }


  @autobind
  getRowElementId(): ElementId {
    const rowNode = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex);
    return elementPathKeyToLeafElementId(rowNode.id);
  }


  /**
   * It seems obvious, that this component should return true.
   * However, if used as popup, it must not handle positioning itself, like we do in MetusDialog.
   * The positioning would be done from AG-Grid in a wrapper component which also handels closing the popup when
   * the user clicks outside of the popup.
   *
   * One drawback when used as internal editor is that the cell editor value will be cleared when the dialog opens.
   */
  isPopup(): boolean {
    return true;
  }
}
