/* ErrorDialog.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Januar 2018
 */
import * as React from "react";

import Log from "../utils/Logger";
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  Accordion,
  IconButton,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import {Close, ExpandMore} from "@material-ui/icons";
import {StyleRules} from "@material-ui/core/styles";
import {Dispatcher} from "../utils/Dispatcher";
import {ShowErrorDialogAction} from "../actions/InteractionStateActions";


const log = Log.logger("ErrorDialog");

interface LocalProps {
  open: boolean;
  message: string;
  title?: string; // short message like "Table already exists"
}

const styles = (theme:Theme):StyleRules => createStyles({
  dialogPaper: {
    width: "880px",
    maxWidth: "880px",
    height: "504px",
    maxHeight: "504px",
    alignItems: "center",
    margin: 0,
    backgroundColor: theme.metus.dialog.dialogBackgroundColor,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%"
  },
  closeIconButton: {
    color: theme.palette.secondary[600],
  },
  closeIcon: {
    height: "16px",
    width: "16px",
  },
  dialogTitle: {
    flex: "0 0 auto",
    margin: 0,
    padding: "35px 0 35px 0"
  },
  typographyTitle: {
    fontSize: "36px",
    fontWeight: 300,
    color: theme.metus.dialog.errorTypography,
  },
  typographyContent: {
    fontSize: "21px",
    fontWeight: 400,
    color: theme.metus.dialog.primaryText,
  },
  expansionPanel: {
    backgroundColor: theme.metus.dialog.errorFooterColor,
  },
  expansionPanelSummary: {
    backgroundColor: theme.metus.dialog.dialogBackgroundColor
  },
  dialogActionsRoot: {
    paddingBottom: "20px",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "80px",
    backgroundColor: theme.metus.dialog.errorFooterColor,
  },
  cancelIconContainer: {
    alignSelf: "center",
    color: theme.palette.primary[50],
    paddingLeft: "16px",
  },
  cancelIcon: {
    height: "40px",
    width: "40px",
  },
  errorTextContainer: {
    alignSelf: "center",
    paddingLeft: "160px",
  },
  typographyErrorText: {
    fontSize: "21px",
    fontWeight: 500,
    color: theme.palette.primary[50],
  },
  buttonStyle: {
    backgroundColor: theme.metus.dialog.buttonFill,
    color: "white",
    "&:hover": {
      backgroundColor: theme.metus.dialog.buttonFillOnHover

    }
  },
});


type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class ErrorDialog extends React.Component<StyledLocalProps, any> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  handleClose = (): void => {
    Dispatcher.dispatch(new ShowErrorDialogAction(false, undefined, undefined));
  }

  render(): JSX.Element {
    const title = "An error occurred.";
    const message = this.props.title || "An error has occurred accessing the Server. Please make sure your Network Connection is working and reload Metus Online.";
    return <Dialog
        data-testselector="ErrorDialog"
        open={this.props.open}
        classes={{paper: this.props.classes.dialogPaper}}
        onClose={this.handleClose}>
      <div className={this.props.classes.header}>
        <IconButton onClick={this.handleClose} data-testselector="closeDialogButton"
                    classes={{root: this.props.classes.closeIconButton}}>
          <Close classes={{root: this.props.classes.closeIcon}}/>
        </IconButton>
      </div>
      <div className={this.props.classes.dialogTitle}>
        <Typography className={this.props.classes.typographyTitle}>{title}</Typography>
      </div>
      <DialogContent>
        <form id="form.ErrorDialog" onSubmit={this.handleClose}>
          <Accordion className={this.props.classes.expansionPanel}>
            <AccordionSummary expandIcon={<ExpandMore/>} className={this.props.classes.expansionPanelSummary}>
              <Typography
                  className={this.props.classes.typographyContent}>{message}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={this.props.classes.typographyContent}>{this.props.message}</Typography>
            </AccordionDetails>
          </Accordion>
        </form>
      </DialogContent>
      <div className={this.props.classes.dialogActionsRoot}>
        <Button form="form.ErrorDialog" variant={"contained"} type="submit" data-testselector="ErrorDialogClose">Close</Button>
      </div>
    </Dialog>;
  }
}

export default withStyles(styles)(ErrorDialog);
