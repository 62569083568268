import {NotUndoableAction} from "../../common/actions/BaseAction";
import {ModelLocationType} from "../../common/constants/Enums";

export class SelectModelLocationAction extends NotUndoableAction<ModelLocationType> {
  type: "selectmodellocation" = "selectmodellocation";

  constructor(modelLocation: ModelLocationType) {
    super(modelLocation);
  }

}

export class ResetModelLocationAction extends NotUndoableAction<void> {
  type: "resetModelLocation" = "resetModelLocation";

  constructor() {
    super();
  }

}
