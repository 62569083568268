/* DataIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, März 2019
 */
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";
import {withStyles} from "@material-ui/core";
import * as React from "react";

class DataIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <style type="text/css">{`
        .st0data{fill:#FFFFFF;}
        .st1data{fill:#FFFFFF;fill-opacity:0.5;}
     `}</style>
      <path className="st0data" d="M49.41,34.79H38.35c-0.21,0-0.38-0.17-0.38-0.38v-0.55c0-0.21,0.17-0.38,0.38-0.38h11.06
	c0.21,0,0.38,0.17,0.38,0.38v0.55C49.79,34.62,49.62,34.79,49.41,34.79z"/>
      <path className="st0data" d="M55.94,43.24h-22.6c-0.21,0-0.38-0.17-0.38-0.38v-0.55c0-0.21,0.17-0.38,0.38-0.38h22.6
	c0.21,0,0.38,0.17,0.38,0.38v0.55C56.31,43.07,56.15,43.24,55.94,43.24z"/>
      <path className="st0data" d="M67.23,34.82H54.95c-0.21,0-0.39-0.17-0.39-0.39V33.9c0-0.21,0.17-0.39,0.39-0.39h12.28
	c0.21,0,0.39,0.17,0.39,0.39v0.53C67.62,34.64,67.44,34.82,67.23,34.82z"/>
      <path className="st0data" d="M67.2,51.76H56.69c-0.21,0-0.38-0.17-0.38-0.38v-0.55c0-0.21,0.17-0.38,0.38-0.38H67.2
	c0.21,0,0.38,0.17,0.38,0.38v0.55C67.58,51.59,67.41,51.76,67.2,51.76z"/>
      <path className="st0data" d="M67.3,43.24h-6.76c-0.21,0-0.38-0.17-0.38-0.38v-0.55c0-0.21,0.17-0.38,0.38-0.38h6.76
	c0.21,0,0.38,0.17,0.38,0.38v0.55C67.67,43.07,67.5,43.24,67.3,43.24z"/>
      <path className="st0data" d="M51.43,51.81h-14.4c-0.21,0-0.38-0.17-0.38-0.38v-0.53c0-0.21,0.17-0.38,0.38-0.38h14.4
	c0.21,0,0.38,0.17,0.38,0.38v0.53C51.81,51.63,51.64,51.81,51.43,51.81z"/>
      <path className="st0data" d="M53.25,35.48h-1.79c-0.38,0-0.69-0.31-0.69-0.69v-1.79c0-0.38,0.31-0.69,0.69-0.69h1.79
	c0.38,0,0.69,0.31,0.69,0.69v1.79C53.94,35.17,53.63,35.48,53.25,35.48z"/>
      <path className="st0data" d="M59.13,44.23h-1.79c-0.38,0-0.69-0.31-0.69-0.69v-1.79c0-0.38,0.31-0.69,0.69-0.69h1.79
	c0.38,0,0.69,0.31,0.69,0.69v1.79C59.82,43.92,59.51,44.23,59.13,44.23z"/>
      <path className="st0data" d="M55.35,52.61h-1.79c-0.38,0-0.69-0.31-0.69-0.69v-1.79c0-0.38,0.31-0.69,0.69-0.69h1.79
	c0.38,0,0.69,0.31,0.69,0.69v1.79C56.04,52.3,55.73,52.61,55.35,52.61z"/>
      <g>
        <g>
          <path className="st1data" d="M15.66,65.4h55.51c0.57,0,1.03,0.46,1.03,1.03v0c0,0.57-0.46,1.03-1.03,1.03H15.66
			c-0.57,0-1.03-0.46-1.03-1.03v0C14.63,65.86,15.09,65.4,15.66,65.4z"/>

          <rect x="41.64" y="62.13" transform="matrix(-7.837325e-11 -1 1 -7.837325e-11 -19.2789 107.0374)"
                className="st1data" width="4.48" height="2.06"/>
        </g>
        <path className="st1data" d="M69.4,23.83H17.43c-1.57,0-2.83,1.27-2.83,2.83v31.42c0,1.57,1.27,2.83,2.83,2.83H69.4
		c1.57,0,2.83-1.27,2.83-2.83V26.66C72.23,25.1,70.96,23.83,69.4,23.83z M70.23,57.56c0,0.78-0.63,1.42-1.42,1.42h-50.8
		c-0.78,0-1.42-0.63-1.42-1.42V27.19c0-0.78,0.63-1.42,1.42-1.42h50.8c0.78,0,1.42,0.63,1.42,1.42V57.56z"/>
      </g>
      <path className="st0data" d="M49.86,19.79l-5.55,4.9l-4.82-5.47l5.5-4.85c-2.1-0.56-4.43-0.1-6.19,1.45c-2.04,1.8-2.72,4.57-1.97,7.01
	L16.69,40.61c-2.34-1.08-5.2-0.76-7.26,1.06c-1.75,1.55-2.5,3.8-2.2,5.96l5.5-4.85l4.82,5.47L12,53.13
	c2.13,0.62,4.52,0.17,6.31-1.41c2.04-1.8,2.72-4.56,1.97-7l20.15-17.79c2.34,1.08,5.19,0.75,7.24-1.06
	C49.47,24.3,50.21,21.99,49.86,19.79z"/>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(DataIcon);
