/* ProductkitIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, März 2019
 */
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";
import {withStyles} from "@material-ui/core";
import * as React from "react";

class ProductkitIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <style type="text/css">{`
      .st0product{fill:#FFFFFF;fill-opacity:0.5;}
      .st1product{fill:#FFFFFF;}
     `}</style>
      <path className="st0product" d="M66.07,63.6H13.96c-0.63,0-1.13-0.51-1.13-1.13V42.08c0-0.63,0.51-1.13,1.13-1.13h52.12
	c0.63,0,1.13,0.51,1.13,1.13v20.39C67.21,63.1,66.7,63.6,66.07,63.6z"/>
      <path className="st0product"
            d="M29.26,40.94H17.93V22.88c0-0.78,0.63-1.42,1.42-1.42h8.5c0.78,0,1.42,0.63,1.42,1.42V40.94z"/>
      <path className="st1product"
            d="M27.84,45.61h-8.5c-0.78,0-1.42-0.63-1.42-1.42v-3.25h11.33v3.25C29.26,44.98,28.62,45.61,27.84,45.61z"/>
      <path className="st1product"
            d="M44.27,58.57h-8.5c-0.78,0-1.42-0.63-1.42-1.42V40.94h11.33v16.21C45.69,57.94,45.05,58.57,44.27,58.57z"/>
      <path className="st1product"
            d="M60.7,53.01h-8.5c-0.78,0-1.42-0.63-1.42-1.42V40.94h11.33v10.65C62.12,52.37,61.49,53.01,60.7,53.01z"/>
      <path className="st0product"
            d="M45.69,40.94H34.36v-3.7c0-0.78,0.63-1.42,1.42-1.42h8.5c0.78,0,1.42,0.63,1.42,1.42V40.94z"/>
      <path className="st0product"
            d="M62.12,40.94H50.79V17.61c0-0.78,0.63-1.42,1.42-1.42h8.5c0.78,0,1.42,0.63,1.42,1.42V40.94z"/>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(ProductkitIcon);
