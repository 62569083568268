/* CockpitHeaderComponent.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, November 2018
 */
import * as React from "react";
import Log from "../../common/utils/Logger";
import {createStyles, IconButton, Theme, Tooltip, Typography, withStyles, WithStyles} from "@material-ui/core";
import {Close, Save} from "@material-ui/icons";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import {ViewId} from "../../core/utils/Core";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {CloseCockpitAction, SaveCockpitAction} from "../actions/ViewManagerActions";
import {CockpitIconType, ViewType} from "../../common/constants/Enums";
import {getCockpitIcon} from "./NewCockpitDialog";
import {showSaveCockpitDialog} from "./SaveCockpitDialog";
import {saveView} from "../actions/ViewManagerAsyncActionCreators";
import {COCKPIT_VERSION} from "../../common/constants/ViewVersions";

const log = Log.logger("CockpitHeaderComponent");

const styles = (theme: Theme): StyleRules => {
  return createStyles({
    root: {
      flex: "0 0 auto",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      borderBottom: "1px solid #999",
      padding: theme.spacing(1) + "px",
    },
    textComponent: {
      minHeight: "1.3em", // needed if header is empty
      paddingTop: "11px",
      height: "100%",
      width: "100%",
    },
    typo: {
      color: "#FFFFFF",
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    iconButton: {
      color: "#FFFFFF",
      marginLeft: theme.spacing(1) + "px",
    },
    icon: {
      height: "24px",
      width: "24px",
    },
  });
};

interface LocalProps {
  cockpitId: ViewId;
  cockpitName: string;
  backgroundColor: string;
  iconType: CockpitIconType;
  isDirty: boolean;
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class CockpitHeaderComponent extends React.Component<StyledLocalProps, any> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    log.debug("Rendering view header.");
    const cockpitIcon = getCockpitIcon(this.props.iconType);
    const backgroundColor = this.props.backgroundColor ? this.props.backgroundColor : "#7C86DA";

    return <div className={this.props.classes.root} style={{backgroundColor}}>
      <div style={{paddingRight: 10}}>
        <cockpitIcon.type style={{fontSize: 40}}/>
      </div>

      <div data-testselector="ViewHeaderTitle"
           className={this.props.classes.textComponent}>
        <Typography className={this.props.classes.typo} variant={"h6"}
                    data-testselector="ViewHeaderTitle">{this.props.cockpitName}
        </Typography>
      </div>

      <div className={this.props.classes.buttons}>
        <Tooltip title="Save" enterDelay={500}>
          <IconButton onClick={this.save} data-testselector="saveCockpitButton"
                      classes={{root: this.props.classes.iconButton}} disabled={!this.props.isDirty}>
            <Save classes={{root: this.props.classes.icon}}/>
          </IconButton>
        </Tooltip>

        <Tooltip title="Close" enterDelay={500}>
          <IconButton onClick={this.close} data-testselector="closeCockpitButton"
                      classes={{root: this.props.classes.iconButton}}>
            <Close classes={{root: this.props.classes.icon}}/>
          </IconButton>
        </Tooltip>
      </div>
    </div>;
  }

  @autobind
  private save(): void {
    const {cockpitId, cockpitName} = this.props;
    // TODO version: add viewInfo to this component, to be able to pass correct cockpit version here
    saveView(ViewType.Cockpit, cockpitId, cockpitName, COCKPIT_VERSION, false);
    Dispatcher.dispatch(new SaveCockpitAction({}));
  }

  @autobind
  private close(): void {
    if (this.props.isDirty) {
      showSaveCockpitDialog(true, new CloseCockpitAction({id: this.props.cockpitId, name: this.props.cockpitName}));
    } else {
      // send save action in order to save all views in the cockpit, which otherwise won't get saved since the focusLost event isn't triggered
      const {cockpitId, cockpitName} = this.props;
      // TODO version: add viewInfo to this component, to be able to pass correct cockpit version here
      saveView(ViewType.Cockpit, cockpitId, cockpitName, COCKPIT_VERSION, false);
      Dispatcher.dispatch(new CloseCockpitAction({}));
    }
  }

}

export default withStyles(styles)(CockpitHeaderComponent);
