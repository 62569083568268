/* ValueChartDiagramModel.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Marco van Meegen, April 2018
 */

import {DiagramModel} from "../DiagramModel";
import {
  ElementId,
  ElementObject,
  generateId,
  TableId,
  ViewId,
  VisualAttributeId,
  VisualAttributeIdString,
  VisualTableId
} from "../../../core/utils/Core";
import {ViewType} from "../../../common/constants/Enums";
import {Connection, DiagramData} from "../../../api/api";
import {VisualAttributeDefinition} from "../common/VisualAttributeDefinition";
import {VisualValueChartLevel} from "./VisualValueChartLevel";
import {handleAfterDeserializeChildren, VisualValueChart} from "./VisualValueChart";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";
import {ConditionalFormat} from "../../../commonviews/models/ConditionalFormat";
import {modelStore} from "../../../core/stores/ModelStore";
import {list, object, serializable} from "serializr";
import {observable} from "mobx";
import {AddAttributeToViewPayload, Coords} from "../../../commonviews/actions/SharedViewAsyncActions";
import {viewManagerRegistry} from "../../../commonviews/models/ViewManager";
import {chartElementComparator, negate} from "../../../core/utils/comparator/AttributeValueComparator";
import {VisualValueChartElement} from "./VisualValueChartElement";
import * as _ from "lodash";

export class ValueChartDiagramModel extends DiagramModel {
  @serializable(list(object(VisualValueChart), handleAfterDeserializeChildren)) @observable protected _children: VisualValueChart[] = [];
  get children(): VisualValueChart[] {
    return this._children;
  }

  /**
   * new ValueChartDiagramModel
   * @param viewId view UUID
   * @param title view title
   * @param type view type
   * @param diagramData serialized form of value chart data
   */
  constructor(viewId: ViewId, title: string, type: ViewType, diagramData?: DiagramData) {
    super(viewId, title, type);
    if (diagramData === undefined) {
      // currently a value chart diagram has exactly one value chart
      this.addChildren(new VisualValueChart(this, generateId()));
    } else {
      super.initFromSerializedDiagram(diagramData);
    }
  }

  deleteVisualElements(...elementIds: ElementId[]): void {
    this.valueChart.removeVisualElements(...elementIds);
  }

  /**
   * add attribute to first visual table for given table
   * @param a
   */
  addAttribute(a: AddAttributeToViewPayload): VisualAttributeDefinition {
    const visualTableId = a.visualAttributeId.visualTableId;
    const attName = a.visualAttributeId.attributeName;
    const x = a.initial ? 0 : a.requestedCoords.x;
    return this.valueChart.addAttributeDefinition(visualTableId, attName, x, DiagramVisualConstants.DEFAULT_ATT_WIDTH, DiagramVisualConstants.DEFAULT_ATT_HEIGHT, a.initial, a.addToRight);
  }

  removeAttribute(visualAttributeId: VisualAttributeId): void {
    const level = this.valueChart.removeVisualAttributeDefinition(visualAttributeId);
    if (level !== undefined) {
      const viewerFiltersToDelete: VisualAttributeIdString[] = [];
      this.viewerFilters.forEach((value, key: VisualAttributeIdString, map) => {
        if (key === visualAttributeId.toKey())
          viewerFiltersToDelete.push(key);
      });
      viewerFiltersToDelete.forEach((visualAttributeIdString: VisualAttributeIdString) => {
        this.viewerFilters.delete(visualAttributeIdString);
      });
      this.synchronizeConditionalFormats(visualAttributeId);
      this.valueChart.updateElementProperties(level, ...level.elements);
      this.valueChart.layout();
    } else {
      throw new Error("Could not remove attribute because table was not found: " + visualAttributeId.toKey());
    }
  }

  public addTable(visualTableId: VisualTableId, title: string, insertPosition: Coords | number): VisualValueChartLevel {
    const vc = this.children[0] as VisualValueChart;
    return vc.addTable(visualTableId, modelStore.getTableName(visualTableId.tableId));
  }

  removeTable(visualTableId: VisualTableId): VisualValueChartLevel {
    const vc = this.children[0] as VisualValueChart;
    return vc.removeLevel(visualTableId);
  }

  /**
   * convenience method to retrieve the single element which is a value chart
   * @returns {VisualValueChart}
   */
  get valueChart(): VisualValueChart {
    const vc: VisualValueChart = this.children.find(child => child instanceof VisualValueChart);
    return vc;
  }


  protected diagramInitialized(): void {
    this.valueChart.updateRootVisualElements();
  }

  mergeAttributeValues(tableId: TableId, elements: ElementObject[]): void {
    this.valueChart.mergeAttributeValues(tableId, elements);
  }

  toggleConnections(sourceElementIds: ElementId[], targetElementId: ElementId): void {
    this.valueChart.updateAfterConnectionsChanged();
  }

  newVisualElement(tableId: TableId, elementId: ElementId, name: string, y: number): void {
    this.valueChart.newVisualElement(tableId, elementId, name);
  }

  public getVisualTable(visualTableId: VisualTableId): VisualValueChartLevel {
    return visualTableId.hasNoVisualId() ? this.getFirstVisualTableForTable(visualTableId.tableId) : this.valueChart.getLevel(visualTableId);
  }

  public getVisualTablesForTable(tableId: TableId): VisualValueChartLevel[] {
    return super.getVisualTablesForTable(tableId).filter(v => v instanceof VisualValueChartLevel) as VisualValueChartLevel[];
  }

  public getFirstVisualTableForTable(tableId: TableId): VisualValueChartLevel {
    const matchingTables = this.getVisualTablesForTable(tableId);
    return matchingTables.length > 0 ? matchingTables[0] : null;
  }

  protected adjustNodeAndAttributePositions(): void {
    this.valueChart.levels.forEach((t: VisualValueChartLevel) => {
      t.setHeaderRotation(this.attributeHeaderRotation, this.attributeHeaderRotation === 0 ? DiagramVisualConstants.TABLE_HEADER_ATTRIBUTE_HEADER_HEIGHT : this.attributeHeaderHeight);
    });
    this.valueChart.layout();
  }

  updateAllFilteredElements(forceAutoLayout: boolean): void {
    this.valueChart.updateAllDerivedProperties();
  }

  onConnectedToSelectionStateChange(newState: boolean): void {
    // selection state changes --> only update visibility, not attribute values

    // update saved secondary selection only if this view is not active
    if (this.showConnectedOnly && !(viewManagerRegistry.viewManager.activeViewId === this.id)) {
      // MO-1343 save secondary selection so it does not change when something is clicked in current editor
      this.valueChart.secondarySelection = new Set(modelStore.secondarySelection);
    }
    if (newState !== undefined) {
      // toggled on or off ==> update
      this.valueChart.updateAllDerivedProperties();
    } else if (this.showConnectedOnly) {
      // if current selection changes, only update if show connected mode enabled
      this.valueChart.updateAllDerivedProperties();
    }
  }

  protected updateConditionalFormats(conditionalFormats: ConditionalFormat[], visualAttributeId: VisualAttributeId): void {
    // reevaluate derived properties for the level where conditional formats have changed
    const visualTable: VisualValueChartLevel = this.getVisualTable(visualAttributeId.visualTableId) as VisualValueChartLevel;
    this.valueChart.updateDerivedProperties(visualTable, visualTable.elements);
  }

  addConnections(connections: Connection[]): void {
    this.valueChart.updateAfterConnectionsChanged();
  }

  deleteConnections(connections: Connection[]): void {
    this.valueChart.updateAfterConnectionsChanged();
  }

  sortValueChartColumn(visualAttributeId: VisualAttributeId, ascending: boolean): void {
    const visualValueChartLevel = this.getVisualTable(visualAttributeId.visualTableId);
    if (visualValueChartLevel != null) {

      const comparator = _.partialRight(chartElementComparator, this.getVisualAttributeDefinition(visualAttributeId), visualAttributeId);

      const level: number = visualValueChartLevel.index;

      if (level === 0) {
        // highest level
        this.valueChart.sortElementChildren(ascending ? comparator : negate(comparator));
      } else {
        // iterate over parents
        const parentElements: VisualValueChartElement[] = this.valueChart.levels[level - 1].elements;

        parentElements.forEach(parent => {
          parent.sortChildren(ascending ? comparator : negate(comparator));
        })
      }
      this.valueChart.layout();
    }
  }
}
