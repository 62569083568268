import {
  Button,
  FormControl, FormLabel,
  TextField,
  withStyles
} from "@material-ui/core";
import {WithStyles} from "@material-ui/styles";
import * as React from "react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {AttributeDefinition} from "../../api/api";
import autobind from "autobind-decorator";
import {
  DatePatternDescription, generateDatePatternFromDatePatternDescription,
  Order,
  renderDisplayValue, Year
} from "../../common/utils/FormatUtil";
import {hideDialog} from "../../common/utils/CommonDialogUtil";
import BaseDialog, {dialogContentStyles} from "../../common/components/BaseDialog";


const renderLog = Log.logger("editAttributeNumberFormatDialog", Classifier.render);
const DEFAULT_EXAMPLE_DATE: string = "2020-08-15";
const DEFAULT_DATE_PATTERN_DESCRIPTION: DatePatternDescription = {
  order: 0,
  year: 1,
  separator: ".",
}
const DEFAULT_PATTERN: string = Order[0];


interface LocalProps {
  open: boolean,
  pattern?: string,
  onSubmit: any,
}

interface LocalState {
  open: boolean,
  errorMessage: string,
  order: Order,
  year: Year,
  separator: string,
  pattern: string,
  exampleDate: string,
  formattedExample: string,
}

type StyledLocalProps = LocalProps & WithStyles<typeof dialogContentStyles>;

class EditAttributeDateFormatDialog extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
    // TODO: implement interpretation of existing pattern
    const formattedExample = this.getFormattedDate(DEFAULT_EXAMPLE_DATE, DEFAULT_PATTERN);
    this.state = {
      open: props.open,
      errorMessage: undefined,
      pattern: props.pattern || DEFAULT_PATTERN,
      ...DEFAULT_DATE_PATTERN_DESCRIPTION,
      exampleDate: DEFAULT_EXAMPLE_DATE,
      formattedExample,
    }
  }

  render(): JSX.Element {
    renderLog.debug("Rendering EditAttributeDateFormatDialog");
    const {classes} = this.props;
    const paperProps = !!this.state.errorMessage ? classes.dialogPaperError : classes.dialogPaper;

    return <BaseDialog
        open={this.props.open}
        onSubmit={this.handleSubmit}
        dialogContent={this.dialogContent()}
        title={"Date Formatter"}>
    </BaseDialog>
  }

  @autobind
  handleSubmit(event: any): void {
    this.props.onSubmit({target: {value: this.state.pattern}});
    hideDialog();
  }

  dialogContent(): JSX.Element {
    return (
        <div id="form.EditAttributeDateFormatDialog" className={this.props.classes.dialogContent}>
          <FormLabel className={this.props.classes.sectionLabel} style={{gridRow: 1}}>Formatting Properties</FormLabel>
          {this.formattingProps()}
          <FormLabel className={this.props.classes.sectionLabel} style={{gridRow: 3}}>Resulting Pattern and
            Example</FormLabel>
          {this.patternAndExample()}
        </div>
    );
  }

  formattingProps(): JSX.Element {
    const orderLabel = (
        <FormLabel className={this.props.classes.inputLabel} style={{gridRow: 1, gridColumn: 1}}>Order:</FormLabel>);
    const orderButtons = (<div
        style={{gridRow: 1, gridColumn: 2}}>
      <Button
          key={0}
          size={"small"}
          variant={"outlined"}
          className={this.state.order === 0 ? this.props.classes.toggleButtonSelected : this.props.classes.toggleButton}
          onClick={() => {
            this.handleOrderChange(0);
          }}>dd/mm/yyyy</Button>
      <Button
          key={1}
          size={"small"}
          variant={"outlined"}
          className={this.state.order === 1 ? this.props.classes.toggleButtonSelected : this.props.classes.toggleButton}
          onClick={() => {
            this.handleOrderChange(1);
          }}>mm/dd/yyyy</Button>
      <Button
          key={2}
          size={"small"}
          variant={"outlined"}
          className={this.state.order === 2 ? this.props.classes.toggleButtonSelected : this.props.classes.toggleButton}
          onClick={() => {
            this.handleOrderChange(2);
          }}>yyyy/mm/dd</Button>
    </div>);
    const yearLabel = (
        <FormLabel className={this.props.classes.inputLabel} style={{gridRow: 2, gridColumn: 1}}>Minimum Fraction
          Digits:</FormLabel>);
    const yearButtons = (<div
        style={{gridRow: 2, gridColumn: 2}}>
      <Button
          key={0}
          size={"small"}
          variant={"outlined"}
          className={this.state.year === 0 ? this.props.classes.toggleButtonSelected : this.props.classes.toggleButton}
          onClick={() => {
            this.handleYearChange(0);
          }}>yy</Button>
      <Button
          key={1}
          size={"small"}
          variant={"outlined"}
          className={this.state.year === 1 ? this.props.classes.toggleButtonSelected : this.props.classes.toggleButton}
          onClick={() => {
            this.handleYearChange(1);
          }}>yyyy</Button>
    </div>);
    const separatorLabel = (
        <FormLabel className={this.props.classes.inputLabel} style={{gridRow: 3, gridColumn: 1}}>Separator:</FormLabel>)
    const separator = (<TextField
        className={this.props.classes.inputSmall}
        style={{gridRow: 3, gridColumn: 2}}
        id={"separator"}
        value={this.state.separator}
        onChange={this.handleSeparatorChange}>
    </TextField>);

    return (<FormControl className={this.props.classes.section} style={{gridRow: 2}}>
      {orderLabel}
      {orderButtons}
      {yearLabel}
      {yearButtons}
      {separatorLabel}
      {separator}
    </FormControl>)
  }

  patternAndExample(): JSX.Element {
    const patternLabel = (
        <FormLabel className={this.props.classes.inputLabel} style={{gridRow: 1, gridColumn: 1}}>Pattern:</FormLabel>)
    const pattern = (<TextField
        className={this.props.classes.inputLarge}
        style={{gridRow: 1, gridColumn: 2}}
        id={"pattern"}
        value={this.state.pattern}
        disabled={true}>
    </TextField>);
    const exampleDateLabel = (<FormLabel className={this.props.classes.inputLabel} style={{gridRow: 2, gridColumn: 1}}>Example
      Date:</FormLabel>)
    const exampleDate = (<TextField
        className={this.props.classes.inputLarge}
        style={{gridRow: 2, gridColumn: 2}}
        id={"exampleDate"}
        defaultValue={this.state.exampleDate}
        type={"date"}
        onChange={this.handleExampleDateChange}>
    </TextField>);
    const formattedExampleLabel = (
        <FormLabel className={this.props.classes.inputLabel} style={{gridRow: 3, gridColumn: 1}}>Formatted:</FormLabel>)
    const formattedExample = (<TextField
        className={this.props.classes.inputLarge}
        style={{gridRow: 3, gridColumn: 2}}
        id={"formattedExample"}
        value={this.state.formattedExample}
        disabled={true}>
    </TextField>);
    return (<FormControl className={this.props.classes.section}
                         style={{gridRow: 4, gridTemplateColumns: "120px auto", display: "grid"}}>
          {patternLabel}
          {pattern}
          {exampleDateLabel}
          {exampleDate}
          {formattedExampleLabel}
          {formattedExample}
        </FormControl>
    )
  }

  @autobind
  handleOrderChange(newOrder: number) {
    this.updateState({order: newOrder})
  }

  @autobind
  handleYearChange(newValue: number) {
    this.updateState({year: newValue});
  }

  @autobind
  handleSeparatorChange(event: any) {
    this.updateState({separator: event.target.value})
  }

  @autobind
  handleExampleDateChange(event: any) {
    this.updateState({exampleDate: event.target.value});
  }

  @autobind
  updateState(updatedStateValues: Partial<LocalState>): void {
    updatedStateValues.pattern = this.getPattern(updatedStateValues);
    updatedStateValues.formattedExample = this.getFormattedExample(updatedStateValues);
    this.setState(updatedStateValues as LocalState);
  }

  @autobind
  getPattern(updatedStateValues: Partial<LocalState>): string {
    const updatedState = {...this.state, ...updatedStateValues};
    const datePatternDescription: DatePatternDescription = {
      order: updatedState.order,
      year: updatedState.year,
      separator: updatedState.separator,
    }
    return generateDatePatternFromDatePatternDescription(datePatternDescription);
  }

  @autobind
  getFormattedExample(updatedStateValues: Partial<LocalState>): string {
    const updatedState = {...this.state, ...updatedStateValues};
    return this.getFormattedDate(updatedState.exampleDate, updatedState.pattern);
  }

  @autobind
  getFormattedDate(date: string, pattern: string): string {
    const dummyAttributeDefinition: Partial<AttributeDefinition> = {
      pattern: pattern,
      editPattern: "",
      type: "String",
      formatType: "Date"
    };
    return renderDisplayValue(date, dummyAttributeDefinition as AttributeDefinition);
  }
}

export default withStyles(dialogContentStyles)(EditAttributeDateFormatDialog);
