import * as React from "react";
import {CSSProperties} from "react";
import {getBrandedImagePath} from "../utils/ImageUtil";

interface LocalProps {
  src: string;
  style: CSSProperties;
}

interface LocalState {
  useDefault: boolean;
}

/**
 * loads src image relative to branding directory; if failed, loads the default from images directory
 * styles are passed to the embedded img tag
 * @author Marco van Meegen
 */
export class BrandedImageComponent extends React.Component<LocalProps, LocalState> {

  constructor(props: LocalProps) {
    super(props);
    this.onError = this.onError.bind(this);
    this.state = {useDefault: false};
  }

  onError(): void {
    this.setState((state) => ({useDefault: true}));
  }

  render(): JSX.Element {
    return <img src={getBrandedImagePath(this.props.src, this.state.useDefault)} onError={this.onError} style={this.props.style}/>;
  }
}

