import {
  ChartAttributeData,
  ChartTableData,
  ConditionalFormatData,
  DiagramData,
  FilterData,
  HeaderData,
  VisualElementEntry, VisualTextBoxData,
  VisualValueChartData,
  VisualValueChartElementData,
  VisualValueChartLevelData
} from "../../api/api";
import {VisualAttributeId, VisualAttributeIdString, VisualTableIdString} from "../../core/utils/Core";
import {DiagramModel} from "./DiagramModel";
import {VisualValueChartLevel} from "./valuechart/VisualValueChartLevel";
import {VisualValueChartElement} from "./valuechart/VisualValueChartElement";
import {VisualHeader} from "../../commonviews/models/VisualHeader";
import {VisualChartColumn} from "./chart/VisualChartColumn";
import {VisualValueChart} from "./valuechart/VisualValueChart";
import {VisualAttributeDefinition} from "./common/VisualAttributeDefinition";
import {VisualTextBox} from "./common/VisualTextBox";

export class DiagramModelSerializer {

  static toJSON(diagramModel: DiagramModel): DiagramData {
    const filterData: FilterData[] = [];
    diagramModel.viewerFilters.forEach((filterText: string, visualAttributeIdString: VisualAttributeIdString, map: Map<VisualAttributeIdString, string>): void => {
      const visualAttributeId: VisualAttributeId = VisualAttributeId.fromKey(visualAttributeIdString);
      filterData.push({
        visualTables: [[visualAttributeId.visualTableId.tableId, visualAttributeId.visualTableId.visualId]],
        attribute: visualAttributeId.attributeName,
        filterText: filterText
      });
    });

    const tables = diagramModel.children.map(child => {
      if (child instanceof VisualChartColumn) {
        const r: ChartTableData = this.serializeHeader(child.header) as ChartTableData;
        r.id = {tableId: child.id.tableId, visualId: child.id.visualId};
        r.displayName = r.name;

        // map attributes
        r.attributes = this.serializeAttributeDefinitions(child.visualAttributeDefinitions);
        r.visualElements = Array.from(child.visualElements.values()).map(node => {
          return [node.id.elementId, node.x, node.y, node.width, node.height] as VisualElementEntry;
        });
        return r;
      } else if (child instanceof VisualValueChart) {
        const result: VisualValueChartData = {
          id: diagramModel.id,
          type: "VisualValueChartData",
          tables: child.levels.map(vcLevel => this.serializeLevel(vcLevel)),
          children: this.serializeChildren(child.elementChildren)
        };
        return result;
      } else if (child instanceof VisualTextBox) {
        const result: VisualTextBoxData = {
          id: child.id,
          x: child.x,
          y: child.y,
          width: child.width,
          height: child.height,
          text: child.text
        }
        return result;
      }
    });

    let selectedTable: VisualTableIdString;
    if (diagramModel.autoLayoutOptions && diagramModel.autoLayoutOptions.selectedTable) {
      selectedTable = diagramModel.autoLayoutOptions.selectedTable.toKey();
    }
    return {
      id: diagramModel.id,
      children: tables,
      autoLayoutOptions: {...diagramModel.autoLayoutOptions, selectedTable: selectedTable},
      filterData: filterData,
      attributeHeaderRotation: diagramModel.attributeHeaderRotation,
      attributeHeaderHeight: diagramModel.attributeHeaderHeight,
      isHeaderExpanded: diagramModel.isHeaderExpanded
    } as DiagramData;
  }

  private static serializeAttributeDefinitions(visualAttributeDefinitions: Map<string, VisualAttributeDefinition>): ChartAttributeData[] {
    return Array.from(visualAttributeDefinitions.values()).map((visualAttributeDefinition: VisualAttributeDefinition) => {
      const result: ChartAttributeData = {} as ChartAttributeData;
      // serialized format: {attributeId: "myNameAtt", filterExpression: "W", styles: {fill: "green"}}
      const serializedConditionalFormats = [];

      if (visualAttributeDefinition.conditionalFormats !== undefined) {
        visualAttributeDefinition.conditionalFormats.forEach(cf => {
          const serializedCondFormat: ConditionalFormatData = {
            attributeName: cf.operand.attributeName,
            filterExpression: cf.filterExpression,
            styles: cf.styles
          };
          serializedConditionalFormats.push(serializedCondFormat);
        });
      }

      ({
        x: result.x,
        y: result.y,
        width: result.width,
        height: result.height,
        name: result.name
      } = visualAttributeDefinition.header);
      result.displayName = result.name;
      result.conditionalFormats = serializedConditionalFormats;
      return result;
    });
  }

  private static serializeHeader(header: VisualHeader): HeaderData {
    const r: any = {};
    ({x: r.x, y: r.y, width: r.width, height: r.height, name: r.name} = header);
    return r;
  }

  private static serializeLevel(level: VisualValueChartLevel): VisualValueChartLevelData {
    const {tableId, visualId} = level.id;
    const {columns, titleWidth, contentDx, contentDy} = level;
    const header = this.serializeHeader(level.header);
    const attributeDefinitions = this.serializeAttributeDefinitions(level.visualAttributeDefinitions);

    return {
      id: {tableId, visualId},
      columns,
      titleWidth,
      contentDx,
      contentDy,
      attributes: attributeDefinitions,
      header
    };
  }

  private static serializeChildren(elements: VisualValueChartElement[]): VisualValueChartElementData[] {
    return elements.map(vvcElement => {
      return {
        visualId: {
          elementId: vvcElement.id.elementId,
          visualId: vvcElement.id.visualId
        },
        title: vvcElement.title,
        styles: vvcElement.styles,
        levelIndex: vvcElement.level.index,
        children: this.serializeChildren(vvcElement.children)
      };
    });
  }
}
