import {UndoActionsProperties} from "../components/UndoActionsComponent";
import {undoStore} from "../stores/UndoStore";
import {
  IMetusPropertiesProviderContext,
  MetusPropertiesProvider
} from "../../commonviews/contexts/IMetusPropertiesProviderContext";
import {configurationStore} from "../../core/stores/ConfigurationStore";

export const undoActionsPropertiesProvider: MetusPropertiesProvider = (context: IMetusPropertiesProviderContext): UndoActionsProperties => {
  return configurationStore.configuration.canUndo ? {
    activeViewId: context.activeViewId,
    undoActions: undoStore.undoActions,
    redoActions: undoStore.redoActions,
    isDebug: false
  }: null;
};
