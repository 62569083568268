// lib imports
import * as React from "react";
import {CSSProperties} from "react";
import Log from "../../../common/utils/Logger";
import {calculateInternalValue, renderDisplayValue, renderPrefilledEditValue} from "../../../common/utils/FormatUtil";
import {SVGTextBoxComponent} from "../../../common/components/SVGTextBoxComponent";
import {SVGImageComponent} from "../../../common/components/SVGImageComponent";
import EditableTextComponent from "../../../common/components/EditableTextComponent";
import {AttributeDefinition, isReferenceAttributeDefinition, ReferenceAttributeDefinition} from "../../../api/api";
import {observer} from "mobx-react";
import {VisualBaseAttributeValue} from "../../models/common/VisualBaseAttributeValue";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {Color} from "csstype";
import autobind from "autobind-decorator";
import {getImageUri} from "../../../core/utils/ImageUtil";
import {getEditElementConnectionsDialog} from "../../../commonviews/components/EditElementConnectionsDialog";
import {AttributeId, TableId, ViewId} from "../../../core/utils/Core";
import {ClickableAreaFiller} from "../../../common/components/ClickableAreaFiller";
import {ContextMenuTrigger} from "react-contextmenu";
import {AttributeContextMenuData} from "../../interfaces/ContextMenuDataTypes";
import {ExtendedContextMenuTriggerType} from "../../../commonviews/types/ContextMenuInterfaces";
import {showDialog} from "../../../common/utils/CommonDialogUtil";

const log = Log.logger("diagram");
const renderLog = Log.logger("diagram", Classifier.render);

/**
 * single attribute value
 * @author Marco van Meegen
 *
 */
interface LocalProps {
  attributeName: string;
  tableId: TableId;
  viewId: ViewId;
  windowIndex: number;
  wrapLines: boolean;
  attributeValue: VisualBaseAttributeValue;
  xOffset: number;
  attributeDefinition: AttributeDefinition;
  updateValue: (attributeName: string, newValue: string) => void;
  cancelEditing: (attributeName: string) => void;
  /** if a separator line should be drawn right of the attribute compartment */
  drawSeparator: boolean;
  animate?: boolean;
  /** true if edit mode should be activated on first creation */
  edit?: boolean;
  textColor?: Color;
  scale: number;
}

// ContextMenuTrigger props are passed to collect function
function collect(props: AttributeContextMenuData): AttributeContextMenuData {
  return {tableId: props.tableId, attributeName: props.attributeName, attributeValue: props.attributeValue};
}

@observer
export class AttributeValueComponent extends React.Component<LocalProps> {
  constructor(props: LocalProps) {
    super(props);
  }


  render(): JSX.Element {
    const attributeValue: VisualBaseAttributeValue = this.props.attributeValue;
    renderLog.debug("Rendering AttributeValueComponent", attributeValue ? attributeValue.value : undefined);

    // TODO: make rect smaller to not overwrite border of element if inside; since element box not known here this is more of a heuristic
    const insetRect: number = 2;

    let element;

    let style = attributeValue.textStyles;
    if (this.props.textColor) {
      style = {...attributeValue.textStyles, color: this.props.textColor};
    }
    let displayValue = "";
    if (this.props.attributeDefinition.type === "Image" && (attributeValue.value && attributeValue.value.length > 0)) {
      element = <SVGImageComponent
          uri={getImageUri(attributeValue.value)}
          width={attributeValue.width}
          height={attributeValue.height - insetRect}/>;
    } else {
      displayValue = renderDisplayValue(attributeValue.value, this.props.attributeDefinition);
      if (this.props.attributeDefinition.formatType === "Double") {
        style = {...style, textAlign: "right"};
      }
      element = <SVGTextBoxComponent
          text={displayValue}
          width={attributeValue.width}
          height={attributeValue.height - insetRect}
          style={style} wrapLines={this.props.wrapLines}/>;
    }

    // always render rect to force group size to width and height, otherwise it adjusts to containing text and is not clickable on empty text
    // const hasBg: boolean = this.props.rectStyle != null && this.props.rectStyle.hasOwnProperty("fill");
    let rectStyles: any = {...attributeValue.rectStyles, ...attributeValue.conditionalStyles};
    const hasBg: boolean = rectStyles.hasOwnProperty("fill");
    rectStyles = hasBg ? rectStyles : {...rectStyles, fillOpacity: 0, strokeOpacity: 0};
    const rect = <rect y="1" width={attributeValue.width} height={attributeValue.height - insetRect}
                       style={rectStyles}/>;

    element = <g>{rect}{element}</g>;

    if (this.props.attributeDefinition.editable) {
      if (isReferenceAttributeDefinition(this.props.attributeDefinition)) {
        element = <g onDoubleClick={this.handleReferenceAttributeValueDoubleClick}>
          {element}
          <ClickableAreaFiller
              width={attributeValue.width}
              height={attributeValue.height}/>
        </g>;
      } else {
        element = <EditableTextComponent
            textElement={element}
            onUpdate={this.handleUpdateAttribute}
            onCancel={this.handleCancelEditing}
            onEdit={this.onEditValueHandler}
            isReplaceTextElement={true}
            isStartInEditMode={this.props.edit}
            scale={this.props.scale}
        />;
      }
    }

    const transform: string = `translate(${this.props.xOffset + attributeValue.x},0)`;

    const gstyle: CSSProperties = {};
    if (this.props.animate) {
      gstyle.transition = "transform 2s";
    }

    // TODO: separator size breaks value component size and thus messes up galen tests, fix this
    const result = <g className="gattribute"
                      data-testselector={"text" + "-" + attributeValue.value + "-" + attributeValue.attributeDefinition.id.toKey()}
                      transform={transform}
                      style={gstyle}
                      width={attributeValue.width}
                      height={attributeValue.height}
                      data-tip={displayValue}
    >
      <line x1={attributeValue.width + 1} y1={1} x2={attributeValue.width + 1}
            y2={attributeValue.height}
            style={{strokeWidth: "1px", stroke: this.props.drawSeparator ? "#808080" : "none"}}/>
      {element}
    </g>;

    return this.wrapInAttributeContextMenuTrigger(this.props.attributeDefinition, attributeValue, result);
  }

  private wrapInAttributeContextMenuTrigger(attributeDefinition: AttributeDefinition, attributeValue: VisualBaseAttributeValue, elementToWrap: JSX.Element): JSX.Element {
    let result = elementToWrap;
    if (attributeDefinition.type === "Link") {
      try {
        const url = new URL(attributeValue.value);
        // wrap in trigger
        const AttributeContextMenuTrigger = ContextMenuTrigger as ExtendedContextMenuTriggerType<AttributeContextMenuData>;
        result = <AttributeContextMenuTrigger id={"cm_dg_AttributeValue" + this.props.windowIndex} collect={collect}
                                              renderTag="g" tableId={this.props.tableId}
                                              attributeName={this.props.attributeName}
                                              attributeValue={attributeValue.value}>{elementToWrap}</AttributeContextMenuTrigger>;
      } catch (e) {
        // ignore if no valid url
      }
    }
    return result;
  }


  @autobind
  private onEditValueHandler(): string {
    return renderPrefilledEditValue(this.props.attributeValue.value, this.props.attributeDefinition);
  }

  @autobind
  private handleUpdateAttribute(userValue: string): void {
    log.debug("Updating attribute with id for value", this.props.attributeName, userValue);
    const rawValue: string = calculateInternalValue(userValue, this.props.attributeDefinition);
    this.props.updateValue(this.props.attributeName, rawValue);
  }

  @autobind
  private async handleReferenceAttributeValueDoubleClick(): Promise<void> {
    const referenceAttributeId = new AttributeId(this.props.tableId, this.props.attributeName);
    // can bes safely casted since this call is only triggered if the render method already checked the type of the attribute definition
    const referencedAttributeName = (this.props.attributeDefinition as ReferenceAttributeDefinition).referencedAttributeName;
    const referencedTableId = (this.props.attributeDefinition as ReferenceAttributeDefinition).referencedTableId;
    const referencedAttributeId = new AttributeId(referencedTableId, referencedAttributeName);

    showDialog(true, await getEditElementConnectionsDialog(
        this.props.attributeValue.visualElement.id.elementId,
        referenceAttributeId,
        referencedAttributeId,
        this.props.viewId,
        false));
  }

  @autobind
  private handleCancelEditing(): void {
    log.debug("CancelEditing with id for value", this.props.attributeName);
    this.props.cancelEditing(this.props.attributeName);
  }

}

