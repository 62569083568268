/* HTTPErrorStatusException.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Januar 2018
 */

export class HTTPStatusError extends Error {
  private readonly _url: string;
  private readonly _method: string;

  constructor(method: string, url: string, public readonly status: number, statusText: string, public text: string = "") {
    super(undefined);
    this.name = "HTTPStatusError";
    this._url = url;
    this._method = method;
    this.message = `The HTTP request with method '${this._method}' and URI '${this._url}' failed with status: ${status + " " + statusText}`;
  }

}
