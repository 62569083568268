import Log from "../../../common/utils/Logger";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";
import {Direction, DirectionHelper} from "../../../common/utils/Direction";

const log = Log.logger("ChartColumnArranger");

export enum ColumnUpdateMode {
  MOVE = "MOVE",
  INSERT_BEFORE = "INSERT_BEFORE",
  INSERT_AFTER = "INSERT_AFTER",
  SIZE = "SIZE"
}

/**
 * listen to size changes done by layout
 */
export interface SizeListener {
  /**
   * will be notified after size change was done
   * @param oldSize
   * @param newSize
   */
  onContainerSizeChanged(oldSize: number, newSize: number): void;
}

export interface LayoutableColumn {
  name: string; // attribute name which is unique within a table
  x: number;
  width: number;
}

export interface ColumnUpdate {
  mode: ColumnUpdateMode;
  move?: LayoutableColumn; // an existing or new column, it must exist if mode is MOVE
  target?: LayoutableColumn; // undefined if mode is MOVE
}

/**
 * lays out columns in a container to be adjacent (using DiagramVisualConstants.ATTRIBUTE_HEADER_GAP as gap) filling the container.
 *
 * Offers the additional features:
 * - insert a new column at a specific position
 * - rearrange columns using drag and drop
 * - will automatically adapt the container size to match the cumulated column sizes
 * - will automatically shrink columns proportionally to their size when container is shrunk
 */
export class ContainerColumnLayout {
  private readonly _container: LayoutableColumn;
  private listeners: Map<string, SizeListener> = new Map();

  /**
   * references to columns to arrange; their coordinates will be manipulated inplace thus side-effect is layouting them
   */
  private _columns: LayoutableColumn[];
  /**
   * if no columns are available, but still a size is calculated by value chart nesting, it will be stored here and force the container to this size if no columns are available
   */
  private emptyColumnWidth: number;

  public constructor(container: LayoutableColumn, columns: LayoutableColumn[]) {
    this._container = container;
    this._columns = columns ? columns : [];
    this.emptyColumnWidth = DiagramVisualConstants.DEFAULT_TABLE_WIDTH;
    this.layout();
  }

  public get columns(): LayoutableColumn[] {
    return this._columns;
  }

  public get container(): LayoutableColumn {
    return this._container;
  }

  /**
   * Creates an update spec for a column, whose x coordinate has been changed. Depending on the new x coordinate,
   * the mode of the update spec can result in move, insert_before or insert_after.
   * @param {LayoutableColumn} updatedColumn, the proposed update. a column with that name must exist in this.columns, the x coordinate has changed
   * @returns {ColumnUpdateMode}
   */
  public createChartColumnUpdate(updatedColumn: LayoutableColumn): ColumnUpdate {
    const result: ColumnUpdate = {
      mode: ColumnUpdateMode.MOVE,
      move: updatedColumn
    };

    if (this._columns.length > 0) {
      const currentPosition = this._columns.findIndex(column => column.name === updatedColumn.name);
      const insertPosition = this.findInsertPosition(updatedColumn);
      // move ?
      const isMove = insertPosition === currentPosition || insertPosition === currentPosition + 1;
      if (!isMove) {
        const targetColumn = insertPosition < this._columns.length ? this._columns[insertPosition] : undefined;
        result.mode = targetColumn && updatedColumn.x < targetColumn.x + targetColumn.width / 2 ? ColumnUpdateMode.INSERT_BEFORE : ColumnUpdateMode.INSERT_AFTER;
        result.target = result.mode === ColumnUpdateMode.INSERT_BEFORE ? this._columns[insertPosition] : this._columns[insertPosition - 1];
      }

    }
    log.debug("Created Chart column update spec", result);
    return result;
  }


  public createChartColumnUpdateForNewColumn(updatedChartColumn: LayoutableColumn): ColumnUpdate {
    let result = null;
    const insertPosition = this.findInsertPosition(updatedChartColumn);
    if (insertPosition === this._columns.length) {
      result = {
        mode: ColumnUpdateMode.INSERT_AFTER,
        target: this._columns[this._columns.length - 1],
        move: updatedChartColumn
      };
    } else {
      result = {
        mode: ColumnUpdateMode.INSERT_BEFORE,
        target: this._columns[insertPosition],
        move: updatedChartColumn
      };
    }
    return result;
  }

  /** Manipulates the elements of the columns array according to the update spec and ensures that all elements are positioned
   * with a distance of DiagramVisualConstants.ATTRIBUTE_HEADER_GAP. In order to do so the x coordinates of the columns will be updated.
   * The update can result in reordering of elements, adding new elements or moving existing elements.
   * @param {ColumnUpdate} update, is an update spec
   * @param {number} firstX
   * @param {boolean} adjustContainerWidth true if container width should be adapted to new arranged columns, default true
   * @returns {LayoutableColumn[]} this.columns
   */
  public rearrangeChartColumns(update: ColumnUpdate, firstX: number, adjustContainerWidth: boolean = true): LayoutableColumn[] {
    log.debug("Rearranging Columns", update);
    if (update.mode !== ColumnUpdateMode.SIZE) {
      const existingPosition = this._columns.findIndex((currentChartColumn) => currentChartColumn.name === update.move.name);
      if (update.mode === ColumnUpdateMode.MOVE && existingPosition === -1) {
        log.warn("Chart column with name '" + update.move.name + "' does not exist, ignoring rearrange request");
        return this._columns;
      }
      let insertPosition = null;
      if (update.mode === ColumnUpdateMode.MOVE) {
        // replace
        this._columns.splice(existingPosition, 1, update.move);
      } else {
        // the moved column can exist, but must not
        if (existingPosition !== -1) {
          this._columns.splice(existingPosition, 1);
        }
        if (update.target) {
          insertPosition = this._columns.findIndex((currentChartColumn) => currentChartColumn.name === update.target.name);
          if (insertPosition === -1) {
            // should not happen, can only happen if attribute is dropped twice
            log.warn("Chart column with name '" + update.target.name + "' does not exist but is used as target");
            insertPosition = 0;
          }
        } else {
          insertPosition = 0;
        }
        if (update.mode === ColumnUpdateMode.INSERT_AFTER) {
          insertPosition += 1;
        }
        this._columns.splice(insertPosition, 0, update.move);
      }
    }
    // recalculate all x positions
    let x = firstX;
    this._columns.forEach(c => {
      c.x = x;
      x = x + c.width + DiagramVisualConstants.ATTRIBUTE_HEADER_GAP;
    });
    if (adjustContainerWidth) {
      this.updateContainerWidth();
    }
    return this._columns;
  }

  /**
   * move a column by a delta
   * @param column column to update
   * @param dx delta to move
   * @param {boolean} adjustContainerWidth true if container width should be adapted to new arranged columns, default true
   */
  public updateColumn(column: LayoutableColumn, dx: number, adjustContainerWidth: boolean = true): void {
    const newPos = {name: column.name, x: column.x + dx, width: column.width};
    const update = this.createChartColumnUpdate(newPos);
    column.x += dx;
    update.move = column;
    this.rearrangeChartColumns(update, this._container.x, adjustContainerWidth);
  }

  /**
   * resize the container by dx, resizing all columns proportionally to fit. It is not guaranteed that the resulting size will match expectation because of rounding problems.
   * @param d direction to resize
   * @param dx delta to resize
   */
  public resizeContainer(d: Direction, dx: number): void {
    const header = this._container;
    // ignore gaps since they will not shrink
    const originalContainerWidth = header.width;
    const oldWidthToDistribute = originalContainerWidth - (Math.max(0, this._columns.length - 1)) * DiagramVisualConstants.ATTRIBUTE_HEADER_GAP;
    const resized = DirectionHelper.resize(d, dx, 0, header.x, 0, originalContainerWidth, 0);
    const newContainerWidth = Math.max(resized.width, 5);

    // proportionally resize all attribute headers
    const newWidthToDistribute = newContainerWidth - (Math.max(0, this._columns.length - 1)) * DiagramVisualConstants.ATTRIBUTE_HEADER_GAP;
    let cumulatedAttWidth: number = 0;
    this._columns.forEach((c: LayoutableColumn) => {
      const factor = c.width / oldWidthToDistribute;
      c.width = Math.max(DiagramVisualConstants.MINIMUM_ATT_WIDTH, Math.round(newWidthToDistribute * factor));
      cumulatedAttWidth += c.width;
      // update by zero delta will use new size
      this.updateColumn(this.getColumn(c.name), 0, false);
    });

    // what to do with the remainder on float problems ? --> easy solution: put it on the first attribute, usually this will be the name
    if (this._columns.length > 0 && newWidthToDistribute !== cumulatedAttWidth) {
      this._columns[0].width += (newWidthToDistribute - cumulatedAttWidth);
      this.updateColumn(this.getColumn(this._columns[0].name), 0, false);
    }
    // force container to this size even if no columns available
    if (this._columns.length === 0) {
      this.emptyColumnWidth = newWidthToDistribute;
    }
    this.updateContainerWidth(false);
  }

  /**
   * lays out columns in such a way, that they are positioned with a distance of DiagramVisualConstants.ATTRIBUTE_HEADER_GAP.
   * columns array will be sorted in ascending x order, all positions will be adjusted to match the width, starting at container x position.
   */
  public layout(): void {
    this._columns = this._columns.sort((c1, c2) => c1.x - c2.x);
    this.rearrangeChartColumns({mode: ColumnUpdateMode.SIZE}, this._container.x);
  }

  addColumn(column: LayoutableColumn): void {
    const update = this.createChartColumnUpdateForNewColumn(column);
    this.rearrangeChartColumns(update, this._container.x);
  }

  addColumns(columns: LayoutableColumn[]): void {
    this._columns = this._columns.concat(columns);
    this.layout();
  }

  public removeColumn(name: string): void {
    this._columns = this._columns.filter(column => column.name !== name);
    this.layout();
  }

  private getColumn(name: string): LayoutableColumn {
    return this._columns.find(c => c.name === name);
  }

  /**
   * returns the index of the first column which is on the right side of the updated ChartColumn.
   * returns length if there is no such column
   * @param {LayoutableColumn} updatedChartColumn
   * @returns {number}
   */
  private findInsertPosition(updatedChartColumn: LayoutableColumn): number {
// insert the updatedChartColumn into the list of this.columns
    let insertPosition = this._columns.findIndex((currentChartColumn) => updatedChartColumn.x < currentChartColumn.x + currentChartColumn.width / 2);
    if (insertPosition === -1) {
      insertPosition = this._columns.length;
    }
    return insertPosition;
  }

  /**
   * add a size listener
   * @param key key to add it with
   * @param listener listener to add
   */
  public addListener(key: string, listener: SizeListener): void {
    this.listeners.set(key, listener);
  }

  /**
   * reomve listener registered by key
   * @param key key
   * @return true if listener was found and removed
   */
  public removeListener(key: string): boolean {
    return this.listeners.delete(key);
  }

  private updateContainerWidth(notifyListeners: boolean = true): void {
    const newWidth = this._columns.length > 0 ? this._columns[this._columns.length - 1].x + this._columns[this._columns.length - 1].width - this._columns[0].x : this.emptyColumnWidth;
    if (newWidth !== this._container.width) {
      const oldWidth = this._container.width;
      this._container.width = newWidth;
      if (notifyListeners) {
        this.listeners.forEach(l => l.onContainerSizeChanged(oldWidth, newWidth));
      }
    }
  }

}
