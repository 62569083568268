/* ToolboxComponent.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Juli 2017
 */
import * as React from "react";

import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {Button, createStyles, Theme, WithStyles, withStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import {ButtonProps} from "@material-ui/core/Button/Button";
import {observer} from "mobx-react";


const styles = (theme: Theme): StyleRules => createStyles({
  leftIcon: {
    marginRight: theme.spacing(1) + "px",
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  button: {
    maxWidth: "240px",
    padding: "0px",
    alignSelf: "center",
    width: "100%",
    /*
        "&:hover": {
          opacity: 0.9,
          backgroundColor: theme.metus.properties.active.fill,
        }
    */
  },
  active: {
    color: "#6a6a6a",
    backgroundColor: theme.metus.properties.active.fill,
    border: "0px",
  },
  disabled: {
    color: "#b9b9b9",
    backgroundColor: theme.metus.properties.fill,
  }
});

const log = Log.logger("properties");
const renderLog = Log.logger("properties", Classifier.render);

interface LocalProps extends ButtonProps {
  icon?: React.ReactElement<any>;
}

interface LocalState {
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

@observer
class PropertiesButton extends React.PureComponent<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering PropertiesButton", this.props);
    const {icon, ...actionProps} = this.props;
    return <Button variant={"outlined"} {...actionProps}
                   className={this.props.classes.button}
                   classes={{outlined: this.props.classes.active, disabled: this.props.classes.disabled}}>
      {this.props.icon ? React.cloneElement(this.props.icon, {className: this.props.classes.leftIcon}) : null}
      {this.props.children}
    </Button>;
  }

}

export default withStyles(styles)(PropertiesButton);
