import * as React from "react";
import Log from "../../common/utils/Logger";
import "ag-grid-enterprise";
import "ag-grid-enterprise/chartsModule";
import * as ReactDnD from "react-dnd";
import {DropTarget} from "react-dnd";
import DragTypes from "../../common/constants/DragTypes";
import {AttributeId, ViewId, VisualAttributeId, VisualTableId} from "../../core/utils/Core";
import {Cursor, getNameForListItemType, Target} from "../../common/constants/Enums";
import {TreeListItemProps} from "../../workbench/components/TreeListItemComponent";
import {DndTargetFeedbackAction} from "../../common/actions/InteractionStateActions";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {Dispatcher} from "../../common/utils/Dispatcher";
import EmptyStructuredTableIcon from "../../common/icons/constructionHelper/EmptyStructuredTableIcon";
import autobind from "autobind-decorator";
import {modelStore} from "../../core/stores/ModelStore";
import {observer} from "mobx-react";
import {MatrixModel} from "../models/MatrixModel";
import {MatrixGridComponent} from "./MatrixGridComponent";
import {identity} from "../../common/utils/FunctionUtil";
import {MatrixHeaderComponent} from "./MatrixHeaderComponent";
import {ViewContext, ViewContextProvider} from "../../commonviews/contexts/ViewContext";
import EmptyMatrixEditorIcon from "../../common/icons/constructionHelper/EmptyMatrixEditorIcon";
import {ViewInfo} from "../../commonviews/models/ViewInfo";
import {RemoveTableFromViewAction} from "../../commonviews/actions/SharedViewActions";
import {IEditorState} from "../../commonviews/models/IEditorState";
import {addAttributeToMatrix} from "../actions/MatrixAsyncActionCreators";

const log = Log.logger("MatrixComponent");
const dndLog = Log.logger("MatrixComponent", Classifier.dnd);
const rowHeight: number = 15;

export interface MatrixComponentProps {
  viewModel: MatrixModel;
  connectDropTarget?: ReactDnD.ConnectDropTarget;
  windowPath: any;
  windowIndex: number;
  editorState: IEditorState;
  viewInfo: ViewInfo;
}




/**
 * MatrixComponent renders a matrix and structured table.
 * It uses the MatrixModel as data model, which is observable, so changes will rerender the MatrixComponent using mobx mechanisms.
 * ag-grid is in a separate MatrixGridComponent which handles the ag-grid updates.
 */
@observer
class MatrixComponentNoDnd extends React.Component<MatrixComponentProps, any> {
  private _matrix: MatrixModel;

  constructor(props: MatrixComponentProps) {
    super(props);

    this._matrix = this.props.viewModel;

  }

  render(): JSX.Element {
    log.debug("Rendering MatrixComponent", this.props);
    const connectDropTarget = this.props.connectDropTarget || identity;
    const viewContext = new ViewContext(this.props.viewModel.id, this.props.viewModel.viewType, this.props.windowIndex);
    return (<ViewContextProvider value={viewContext}>
          {connectDropTarget(<div className="editor"
                                  data-testselector={"Editor" + this.props.windowIndex}
                                  data-testviewtype="Matrix">
            <div className="treegrid">
              {this.props.editorState.isHeaderExpanded && <div style={{display: "flex"}}>
                <MatrixHeaderComponent matrix={this.props.viewModel}
                                       viewContext={viewContext}/>
              </div>}
              {this.renderGrid()}
            </div>
          </div>)}
        </ViewContextProvider>
    );
  }

  private renderGrid(): JSX.Element {
    let retVal: JSX.Element;

    if (this.props.viewModel.rowHierarchy.tables.length > 0) {
      retVal = (
          <MatrixGridComponent
              windowIndex={this.props.windowIndex}
              viewId={this.props.viewInfo.id}
              viewModel={this.props.viewModel}
              editorState={this.props.editorState}
          />
      );
    } else {
      retVal = <div className="ag-overlay-loading-center" style={{height: "100%"}}>
        {this.props.viewModel.isStructuredTable ? <EmptyStructuredTableIcon style={{width: "100%", height: "100%"}}
                                                                            data-testselector={"ConstructionHelper"}/> :
            <EmptyMatrixEditorIcon style={{width: "100%", height: "100%"}}/>}
      </div>;
    }

    return retVal;
  }

  /** why is this so strange ? */
  @autobind
  private handleRemoveTable(evt: any, data: { handleRequestDelete: (tableName: string) => void, visualTableId: VisualTableId, tableName: string }, target: any): void {
    log.debug("remove table" + data);
    data.handleRequestDelete(data.tableName);
    this.onRemoveTable(data.visualTableId, data.tableName);
  }

  /** callback for row table header remove */
  @autobind
  private onRemoveTable(visualTableId: VisualTableId, name: string): void {
    Dispatcher.dispatch(new RemoveTableFromViewAction(this.props.viewInfo.id, visualTableId, Target.ROW, undefined));
  }
}

//noinspection JSUnusedLocalSymbols,JSUnusedLocalSymbols,JSUnusedLocalSymbols
const dropTarget: ReactDnD.DropTargetSpec<MatrixComponentProps> = {
  hover(props: MatrixComponentProps, monitor: ReactDnD.DropTargetMonitor, component: React.Component<MatrixComponentProps, any>): void {
    dndLog.debug("Hover");
    const treeListItemProps: TreeListItemProps = monitor.getItem() as TreeListItemProps;

    switch (monitor.getItemType()) {
      case DragTypes.CORE_ATTRIBUTE_DEFINITION:
        dndLog.debug("Setting Attribute target feedback");
        const attributeId: AttributeId = AttributeId.fromKey(treeListItemProps.id);
        if (props.viewModel.rowHierarchy.tables.map(vid => vid.tableId).includes(attributeId.tableId)) {
          if (props.viewModel.rowAttributeNames.indexOf(attributeId.attributeName) === -1) {
            Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.COPY, `adding ${getNameForListItemType(treeListItemProps.type)} '${treeListItemProps.name}' ...`));
          } else {
            Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.NO_DROP, ` ${getNameForListItemType(treeListItemProps.type)} '${treeListItemProps.name}' was already added`));
          }
        } else {
          const tableName = modelStore.tableNameByTableId.get(attributeId.tableId);
          Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.NO_DROP, `can not add ${getNameForListItemType(treeListItemProps.type)} '${treeListItemProps.name}', because table '${tableName}' is missing ...`));
        }
        break;

      case DragTypes.VIEW:
        dndLog.debug("Setting View target feedback");
        Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.MOVE, <img
            src={require("../../common/images/metusChart.svg")} alt="display view"
            width="400"/>));
        break;

      case DragTypes.CORE_TABLE:
        Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.NO_DROP, `can not drop ${getNameForListItemType(treeListItemProps.type)} '${treeListItemProps.name}' ...`));
        break;

      default:
        dndLog.error("Hovering with unknown type, please handle type or remove it from DropTarget types. If type is adaptable, please adapt it");
        break;
    }
  },

  canDrop(props: MatrixComponentProps, monitor: ReactDnD.DropTargetMonitor): boolean {
    dndLog.debug("canDrop");
    const treeListItemProps: TreeListItemProps = monitor.getItem() as TreeListItemProps;
    let result: boolean = false;

    switch (monitor.getItemType()) {
      case DragTypes.CORE_ATTRIBUTE_DEFINITION:
        dndLog.debug("Setting Attribute target feedback");
        const attributeId: AttributeId = AttributeId.fromKey(treeListItemProps.id);
        if (props.viewModel.rowHierarchy.tables.map(vid => vid.tableId).includes(attributeId.tableId)) {
          result = props.viewModel.rowAttributeNames.indexOf(attributeId.attributeName) === -1;
        } else {
          result = false;
        }
        break;

      case DragTypes.VIEW:
        result = true;
        break;

      case DragTypes.CORE_TABLE:
        result = false;
        break;

      default:
        result = false;
        break;
    }
    return result;
  },

  drop(props: MatrixComponentProps, monitor: ReactDnD.DropTargetMonitor, component: React.Component<MatrixComponentProps, any>): Object {
    const viewId: ViewId = props.viewInfo.id;
    dndLog.debug(`Dropped ${monitor.getItemType().toString()} to StructuredTable ${viewId}`);
    const mousePosition = monitor.getClientOffset() ? monitor.getClientOffset() : {x: 100, y: 100};
    const targetPosition = {
      x: mousePosition.x,
      y: mousePosition.y
    };
    const result = {
      viewInfo: props.viewInfo,
      targetPosition,
      windowPath: props.windowPath,
      windowIndex: props.windowIndex,
    };
    switch (monitor.getItemType()) {
      case DragTypes.CORE_ATTRIBUTE_DEFINITION:
        const item: TreeListItemProps = monitor.getItem() as TreeListItemProps;
        log.debug(`Attribute dropped, creating action`, item.id);
        for (let i = 0; i < item.dragIds.length; i++) {
          const attId: AttributeId = item.dragIds[i] as AttributeId;
          // dummy visual attribute with no id will be treated specially in accept
          const visualAttributeId = new VisualAttributeId(VisualTableId.noVisualId(attId.tableId), attId.attributeName);
          addAttributeToMatrix(viewId, visualAttributeId, targetPosition, false, false, false);
        }
        break;
      default:
        break;
    }

    return result;
  }
};

function collect(connect: ReactDnD.DropTargetConnector, monitor: ReactDnD.DropTargetMonitor): Object {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
  };
}

export const MatrixComponent = DropTarget([DragTypes.CORE_ATTRIBUTE_DEFINITION, DragTypes.CORE_TABLE, DragTypes.VIEW], dropTarget, collect)(MatrixComponentNoDnd);

