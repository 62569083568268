import {ViewId} from "../../core/utils/Core";
import {MosaicNode, MosaicPath} from "react-mosaic-component";
import {ActionBase, NotRecordedAction} from "../../common/actions/BaseAction";
import {CockpitData, CockpitInfo, UUID} from "../../api/api";
import {CockpitActionPayload,} from "../stores/MetusStore";
import {ServerWritingAction} from "../../core/actions/CoreActions";
import {
  RemoveViewAction,
  ToggleHeaderExpandedAction,
  ZoomViewAction
} from "../../commonviews/actions/SharedViewActions";
import {ViewInfo} from "../../commonviews/models/ViewInfo";

export class OpenViewAction extends ActionBase<MosaicPath> {
  type: "openView" = "openView";

  constructor(viewId: ViewId, windowPath: MosaicPath, groupId: UUID) {
    super(windowPath, viewId, groupId);
  }
}

export class ActivateViewAction extends NotRecordedAction<undefined> {
  type: "activateView" = "activateView";

  constructor(viewId: ViewId) {
    super(undefined, viewId);
  }
}

export class DeactivateViewAction extends NotRecordedAction<undefined> {
  type: "deactivateView" = "deactivateView";

  constructor(viewId: ViewId) {
    super(undefined, viewId);
  }
}

export class CloseEditorAction extends ActionBase<number> {
  type: "closeEditor" = "closeEditor";

  constructor(windowIndex) {
    super(windowIndex);
  }
}

export class ToggleFullScreenAction extends NotRecordedAction<{ windowIndex: number, isFullscreen: boolean }> {
  type: "toggleFullscreen" = "toggleFullscreen";

  constructor(windowIndex: number, isFullscreen: boolean) {
    super({windowIndex, isFullscreen});
  }
}

export class NewViewAction extends ActionBase<ViewInfo> {
  type: "newView" = "newView";

  constructor(viewInfo: ViewInfo, groupId?: UUID) {
    super(viewInfo, undefined, groupId);
  }
}

export class NewCockpitAction extends ActionBase<CockpitInfo> {
  type: "newCockpit" = "newCockpit";

  constructor(cockpitInfo: CockpitInfo, groupId?) {
    super(cockpitInfo, undefined, groupId);
  }
}

export class OpenCockpitAction extends ActionBase<CockpitData> {
  type: "openCockpit" = "openCockpit";

  constructor(cockpitData: CockpitData) {
    super(cockpitData);
  }
}

export class CloseCockpitAction extends ActionBase<CockpitActionPayload> {
  type: "closeCockpit" = "closeCockpit";

  constructor(payload: CockpitActionPayload) {
    super(payload);
  }
}

export class SaveCockpitAction extends ActionBase<CockpitActionPayload> {
  type: "saveCockpit" = "saveCockpit";

  constructor(payload: CockpitActionPayload) {
    super(payload);
  }
}

export class WindowArrangementChangedAction extends ActionBase<MosaicNode<number>> {
  type: "windowArrangementChanged" = "windowArrangementChanged";

  constructor(windowArrangement: MosaicNode<number>) {
    super(windowArrangement);
  }
}

export class RenameViewAction extends ActionBase<string> implements ServerWritingAction {
  type: "renameView" = "renameView";

  constructor(public commandId: string, name: string, viewId: ViewId) {
    super(name, viewId);
  }
}

export interface UpdateWindowPathActionPayload {
  windowIndex: number;
  windowPath: MosaicPath;
}

export class UpdateWindowPathAction extends ActionBase<UpdateWindowPathActionPayload> {
  type: "updateWindowPath" = "updateWindowPath";

  constructor(payload: UpdateWindowPathActionPayload) {
    super(payload, undefined, undefined, true, true, false);
  }
}

export type ViewManagerActions =
    RemoveViewAction
    | OpenViewAction
    | ActivateViewAction
    | DeactivateViewAction
    | ToggleFullScreenAction
    | CloseEditorAction
    | NewViewAction
    | NewCockpitAction
    | OpenCockpitAction
    | CloseCockpitAction
    | SaveCockpitAction
    | WindowArrangementChangedAction
    | RenameViewAction
    | ZoomViewAction
    | ToggleHeaderExpandedAction
    | UpdateWindowPathAction
    ;

