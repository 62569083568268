/* EmptyChartIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Januar 2019
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";
import theme from "../../theme/Theme";

class EmptyChartIcon extends MetusSvgIcon {

  svg(): JSX.Element {
    const highlightColor = theme.metus.selection.fill
    return <React.Fragment>
      <style type="text/css">{`
        .st0{display:none;}
        .st1{display:inline;fill:#EDEDED;}
        .st2{fill:#D7D7D7;}
        .st3{fill:#A8A8A8;}
        .st4{fill:#EDEDED;}
        .st5{fill:#F6F6F6;}
        .st6{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:11.9781,11.9781;}
        .st7{opacity:0.25;fill:#A8A8A8;}
        .st8{fill:none;stroke:#A8A8A8;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
        .st9{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:12;}
        .st10{display:inline;}
        .st11{fill:#CEE6FE;}
        .st12{fill:${highlightColor};}
        .st13{display:inline;fill:${highlightColor};}
        .st14{display:inline;fill:none;stroke:#24496E;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
        .st15{}
        .st16{font-size:30px;}
        .st17{fill:#D4D4D4;}
        .st18{fill:none;stroke:#A8A8A8;stroke-width:3;stroke-miterlimit:10;}
        .st19{fill:#24496E;}
        .st20{fill:#FFFFFF;}
        .st21{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-miterlimit:10;}
        .st22{fill:#CEE6FE;stroke:${highlightColor};stroke-linecap:round;stroke-miterlimit:10;}
        .st23{fill:#D6C365;}
        .st24{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:12.0034,12.0034;}
        .st25{fill:none;stroke:${highlightColor};stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:12.1971,12.1971;}
        .st26{fill:none;stroke:#D6C365;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:12.0853,12.0853;}
        .st27{display:inline;fill:#24496E;}
        .st28{opacity:0.8;}
        .st29{opacity:0.3;}
        .st30{fill:#AEBCC9;}
        .st31{opacity:0.81;fill:#D6C365;}
        .st32{opacity:0.2;fill:#9CCDFD;}
      `}</style>
      <g id="BG" className="st0">
        <rect className="st1" width="1043.7" height="868.33"/>
      </g>
      <g id="Ebene_1">
        <g>
          <path className="st4" d="M437.25,425.24c6.82,0.24,23.29-0.96,37.43,0c31.8,2.15,73.97,0.34,87.35,0.96
			c20.8,0.96,24.08,30.85,2.5,31.58c-23.28,0.79-104.83-0.15-128.11-0.96C413.56,456.02,414.39,424.44,437.25,425.24z"/>
          <path className="st3" d="M437.28,424.55c7.32-0.21,14.75-0.51,22.16-0.58c3.71-0.03,7.42-0.01,11.14,0.13
			c3.73,0.15,7.4,0.42,11.09,0.59c14.75,0.69,29.54,0.69,44.33,0.58l22.2-0.2c3.7-0.02,7.41-0.02,11.13,0.05l2.8,0.1
			c0.96,0.1,1.93,0.14,2.88,0.36c1.91,0.35,3.78,0.96,5.5,1.9c3.44,1.84,6.28,4.81,7.9,8.35c1.64,3.52,2.22,7.57,1.36,11.42
			c-0.43,1.92-1.23,3.77-2.4,5.37c-1.17,1.61-2.7,2.94-4.42,3.91c-3.42,2.03-7.6,2.25-11.19,2.26c-14.82,0.32-29.62,0.26-44.42,0.21
			c-14.8-0.07-29.6-0.21-44.4-0.41c-7.4-0.12-14.8-0.18-22.2-0.41l-11.1-0.34l-2.78-0.1c-0.92-0.04-1.91-0.13-2.85-0.24
			c-1.91-0.27-3.78-0.81-5.56-1.6c-3.54-1.62-6.49-4.55-8.07-8.11c-1.6-3.55-1.84-7.65-0.81-11.37c1.02-3.73,3.44-7.08,6.73-9.13
			c1.64-1.02,3.46-1.72,5.32-2.15l1.41-0.27c0.47-0.07,0.95-0.1,1.42-0.15c0.24-0.02,0.47-0.05,0.71-0.08l0.71-0.03L437.28,424.55z
			 M437.23,425.92c-3.6-0.31-7.24,0.65-10.21,2.53c-2.95,1.94-5.06,5-5.93,8.39c-0.88,3.39-0.56,7.07,0.89,10.22
			c1.42,3.17,4.03,5.74,7.21,7.19c1.6,0.7,3.3,1.18,5.05,1.43c0.89,0.1,1.75,0.17,2.67,0.21l2.77,0.09l11.09,0.29l22.19,0.4
			c14.79,0.22,29.59,0.38,44.38,0.48c14.79,0.07,29.6,0.16,44.37-0.14l2.77-0.07c0.88-0.09,1.78-0.08,2.65-0.27
			c0.88-0.11,1.72-0.37,2.57-0.61l1.22-0.48c0.41-0.16,0.78-0.39,1.17-0.59c3.06-1.68,5.24-4.7,5.98-8.12
			c0.77-3.41,0.27-7.1-1.24-10.28c-1.49-3.21-4.06-5.86-7.16-7.5c-1.54-0.84-3.24-1.39-4.98-1.7c-0.86-0.2-1.75-0.22-2.64-0.31
			l-2.75-0.08c-3.68-0.05-7.38-0.03-11.07,0l-22.2,0.22c-14.8,0.08-29.62,0.04-44.43-0.68c-3.7-0.18-7.41-0.46-11.07-0.62
			c-3.68-0.15-7.36-0.18-11.05-0.16c-3.69,0.03-7.38,0.11-11.08,0.21C444.67,426.07,440.97,426.16,437.23,425.92z"/>
        </g>
        <g>
          <path className="st7" d="M308.27,469.42c-22.05,1.89-114.39,2.97-128.05,2.42c-25.56-1.02-28.81-35.77-2.69-36.73
			c23.77-0.87,104.5-0.98,128.28-0.13C329.15,435.82,331.57,467.42,308.27,469.42z"/>
          <path className="st3" d="M308.33,470.1c-1.93,0.19-3.86,0.43-5.79,0.55c-1.93,0.15-3.86,0.22-5.79,0.33l-11.58,0.45
			c-7.72,0.27-15.44,0.47-23.15,0.66c-15.44,0.36-30.87,0.61-46.31,0.79c-7.72,0.1-15.44,0.11-23.16,0.09
			c-3.87-0.02-7.72-0.02-11.61-0.15c-3.94-0.06-8.01-0.94-11.55-2.88c-3.55-1.92-6.53-4.9-8.44-8.46
			c-1.93-3.55-2.79-7.68-2.33-11.72c0.43-4.03,2.34-7.97,5.4-10.69c3.06-2.76,7.04-4.22,11-4.71c2.01-0.25,3.94-0.22,5.87-0.3
			l5.79-0.14l11.58-0.2l23.16-0.23c15.44-0.1,30.88-0.12,46.31-0.06c7.72,0.03,15.44,0.08,23.16,0.25
			c3.86,0.07,7.72,0.17,11.58,0.29l2.9,0.1c0.97,0.03,1.99,0.1,2.97,0.25c1.98,0.28,3.94,0.81,5.78,1.62
			c3.7,1.61,6.87,4.45,8.82,7.98c1.95,3.52,2.75,7.68,2.15,11.66c-0.58,3.98-2.63,7.77-5.78,10.29c-1.56,1.27-3.34,2.24-5.22,2.91
			C312.24,469.45,310.28,469.8,308.33,470.1z M308.21,468.74c0.94-0.05,1.88-0.12,2.8-0.32c0.91-0.21,1.82-0.46,2.69-0.8
			c1.74-0.66,3.38-1.59,4.78-2.78c2.84-2.37,4.62-5.84,5.09-9.47c1.01-7.33-3.33-14.94-10.15-17.76c-1.68-0.74-3.47-1.22-5.31-1.47
			c-1.81-0.27-3.75-0.22-5.67-0.31c-3.85-0.11-7.7-0.18-11.56-0.24c-7.71-0.12-15.43-0.18-23.14-0.23
			c-15.43-0.08-30.86-0.08-46.29-0.01l-23.14,0.19l-11.57,0.18l-5.78,0.14c-1.93,0.07-3.86,0.06-5.7,0.28
			c-3.69,0.45-7.3,1.79-10.03,4.25c-2.74,2.43-4.44,5.92-4.84,9.57c-0.42,3.66,0.36,7.45,2.12,10.7c1.75,3.26,4.49,5.97,7.75,7.71
			c3.27,1.76,6.99,2.57,10.76,2.61c3.83,0.1,7.69,0.09,11.55,0.09l23.14-0.15C246.54,470.61,277.45,470.18,308.21,468.74z"/>
        </g>
        <g>
          <path className="st4" d="M435.56,511.49c6.89-0.31,45.42,0.96,64.77,0.96c32.21,0,47.88-0.34,61.4-0.96
			c21.03-0.96,24.35,30.85,2.52,31.58c-23.54,0.79-106.01,0.16-129.54-0.96C414.53,541.16,414.53,512.44,435.56,511.49z"/>
          <path className="st3" d="M435.53,510.8c7.46-0.32,14.82-0.09,22.22,0.04l22.17,0.44c14.77,0.33,29.54,0.23,44.33,0.09
			c7.39-0.07,14.77-0.21,22.16-0.38c3.69-0.08,7.38-0.18,11.07-0.32l2.77-0.11c0.91-0.03,1.85-0.09,2.83-0.07
			c1.94,0.04,3.89,0.36,5.74,1.03c3.72,1.3,6.86,4.05,8.79,7.43c1.93,3.39,2.78,7.36,2.36,11.24c-0.4,3.86-2.21,7.7-5.31,10.21
			c-3.07,2.55-7.12,3.57-10.9,3.61c-7.41,0.22-14.8,0.28-22.2,0.33l-22.18,0.04c-14.79-0.03-29.58-0.14-44.36-0.32
			c-7.39-0.1-14.79-0.18-22.18-0.39l-11.09-0.36l-5.55-0.24c-1.81-0.06-3.82-0.2-5.68-0.73c-3.76-0.97-7.19-3.42-9.22-6.77
			c-2.05-3.33-2.75-7.39-2.12-11.2c0.62-3.81,2.65-7.41,5.7-9.8c1.51-1.2,3.25-2.1,5.06-2.7
			C431.75,511.29,433.64,511.02,435.53,510.8z M435.59,512.17c-0.9,0-1.8,0.04-2.68,0.2c-0.88,0.18-1.74,0.4-2.58,0.7
			c-1.68,0.6-3.25,1.46-4.6,2.58c-2.72,2.24-4.48,5.52-4.98,8.98c-0.52,3.46,0.24,7.07,2.04,10.01c1.81,2.95,4.81,5.07,8.19,5.94
			c1.7,0.47,3.41,0.59,5.29,0.64l5.53,0.21l11.07,0.31c7.39,0.16,14.78,0.3,22.17,0.4c14.78,0.21,29.56,0.34,44.34,0.39l22.17,0
			c7.39-0.03,14.78-0.08,22.15-0.29c3.58-0.03,7.14-0.97,9.83-3.19c2.71-2.18,4.32-5.55,4.69-9.04c0.39-3.49-0.39-7.13-2.15-10.17
			c-1.75-3.05-4.56-5.46-7.87-6.6c-1.65-0.59-3.4-0.87-5.16-0.9c-0.87-0.02-1.78,0.04-2.71,0.08l-2.77,0.12
			c-3.7,0.16-7.4,0.29-11.09,0.39c-7.4,0.2-14.79,0.27-22.19,0.33c-14.78,0.09-29.59,0.14-44.38-0.2L435.59,512.17z"/>
        </g>
        <g>
          <path className="st7" d="M560.34,369.95c-6.91-0.24-23.63,0.96-37.98,0c-32.26-2.15-75.04-0.34-88.61-0.96
			c-21.1-0.96-24.43-30.85-2.53-31.58c23.62-0.79,106.34,0.15,129.96,0.96C584.38,339.16,583.53,370.74,560.34,369.95z"/>
          <path className="st3" d="M560.31,370.63c-7.41,0.21-14.94,0.51-22.44,0.58c-3.76,0.03-7.51,0.01-11.28-0.13
			c-3.78-0.15-7.5-0.42-11.23-0.59c-14.94-0.69-29.91-0.69-44.88-0.58l-22.47,0.2c-3.75,0.01-7.5,0.02-11.26-0.05l-2.83-0.09
			c-0.47-0.02-0.97-0.08-1.46-0.12c-0.49-0.03-0.97-0.09-1.46-0.2c-1.94-0.3-3.83-0.93-5.59-1.83c-7.16-3.49-11.38-12.01-9.65-19.83
			c0.43-1.94,1.25-3.82,2.45-5.43c1.19-1.62,2.75-2.96,4.5-3.92c3.48-2.02,7.69-2.24,11.33-2.25c15.01-0.32,29.99-0.26,44.97-0.21
			c14.99,0.07,29.97,0.21,44.95,0.41c7.49,0.12,14.98,0.18,22.48,0.41l11.24,0.34c1.87,0.07,3.73,0.1,5.69,0.31
			c1.93,0.29,3.84,0.75,5.65,1.53c3.6,1.58,6.69,4.43,8.35,8.03c1.66,3.58,1.97,7.72,0.92,11.5c-1.03,3.78-3.51,7.16-6.85,9.2
			c-1.67,1.02-3.51,1.71-5.39,2.15l-1.42,0.27c-0.48,0.07-0.96,0.1-1.44,0.15c-0.24,0.02-0.48,0.05-0.72,0.08l-0.72,0.03
			L560.31,370.63z M560.36,369.26c3.64,0.31,7.34-0.64,10.36-2.53c3.01-1.94,5.17-5.03,6.04-8.46c0.9-3.43,0.5-7.15-0.99-10.33
			c-1.5-3.19-4.23-5.7-7.47-7.12c-1.63-0.69-3.38-1.11-5.15-1.37c-1.76-0.17-3.65-0.21-5.52-0.27l-11.23-0.28l-22.46-0.4
			c-14.98-0.22-29.96-0.38-44.94-0.47c-14.98-0.07-29.96-0.16-44.92,0.14l-2.8,0.07c-0.9,0.09-1.81,0.08-2.68,0.27
			c-0.89,0.11-1.75,0.37-2.61,0.61l-1.24,0.48c-0.42,0.15-0.79,0.39-1.19,0.59c-3.11,1.67-5.35,4.72-6.09,8.19
			c-0.78,3.45-0.22,7.19,1.36,10.38c1.56,3.22,4.21,5.87,7.4,7.45c1.59,0.8,3.31,1.37,5.08,1.63c0.44,0.09,0.88,0.15,1.33,0.17
			c0.45,0.03,0.89,0.09,1.35,0.11l2.79,0.08c3.73,0.05,7.47,0.03,11.21-0.01l22.47-0.22c14.99-0.08,29.99-0.04,44.99,0.69
			c3.75,0.19,7.5,0.46,11.21,0.62c3.72,0.15,7.46,0.18,11.19,0.15c3.74-0.03,7.47-0.11,11.22-0.21
			C552.83,369.11,556.57,369.02,560.36,369.26z"/>
        </g>
        <g>
          <path className="st4" d="M440.76,597.76c21.78-0.93,108.86,0.95,122.32,1.95c20.92,1.55,23.63,31.53,1.88,31.65
			c-23.46,0.12-99.07-2.44-122.49-3.92C419.46,625.98,417.74,598.74,440.76,597.76z"/>
          <path className="st3" d="M440.73,597.07c1.8-0.11,3.61-0.25,5.41-0.31c1.8-0.07,3.6-0.08,5.41-0.11l10.81-0.04
			c7.2,0,14.4,0.06,21.61,0.13c14.4,0.15,28.8,0.4,43.2,0.72c7.2,0.14,14.4,0.36,21.6,0.61c3.6,0.13,7.2,0.27,10.8,0.45
			c1.81,0.13,3.6,0.15,5.49,0.44c1.86,0.31,3.69,0.86,5.4,1.67c3.43,1.63,6.33,4.35,8.16,7.67c1.84,3.3,2.71,7.17,2.27,10.96
			c-0.22,1.89-0.77,3.76-1.69,5.46c-0.91,1.7-2.2,3.2-3.75,4.37c-3.1,2.37-7.04,3.21-10.73,3.19c-14.43,0.01-28.82-0.41-43.22-0.78
			c-14.4-0.4-28.79-0.87-43.18-1.41c-7.2-0.27-14.39-0.54-21.58-0.95l-10.79-0.61c-1.8-0.13-3.58-0.18-5.45-0.43
			c-1.84-0.25-3.67-0.68-5.42-1.35c-3.5-1.31-6.72-3.59-8.85-6.74c-2.13-3.12-3.1-7.09-2.38-10.84c0.35-1.87,1.11-3.66,2.21-5.21
			c1.09-1.55,2.52-2.85,4.1-3.85c1.59-1,3.33-1.71,5.13-2.18c0.9-0.24,1.8-0.41,2.72-0.54C438.9,597.27,439.81,597.17,440.73,597.07
			z M440.79,598.44c-1.76-0.04-3.52,0.22-5.19,0.72c-1.68,0.47-3.29,1.17-4.72,2.12c-2.88,1.87-4.96,4.86-5.5,8.19
			c-0.58,3.33,0.35,6.82,2.29,9.56c1.91,2.79,4.82,4.85,8.03,6.03c1.61,0.6,3.3,0.99,5.02,1.22c1.7,0.22,3.53,0.26,5.31,0.38
			l10.77,0.58c7.19,0.37,14.38,0.66,21.57,0.94c14.38,0.56,28.77,1.06,43.16,1.48c14.39,0.39,28.79,0.83,43.16,0.84
			c3.47,0.02,6.97-0.76,9.67-2.82c2.72-2.01,4.39-5.24,4.77-8.62c0.4-3.39-0.36-6.92-2.05-9.9c-1.67-3-4.3-5.44-7.4-6.9
			c-1.55-0.73-3.21-1.22-4.92-1.5c-1.68-0.26-3.51-0.28-5.29-0.4c-3.59-0.16-7.18-0.28-10.78-0.39l-21.58-0.58
			C498.34,598.72,469.52,598.22,440.79,598.44z"/>
        </g>
        <g>
          <path className="st4" d="M561.72,266.33c-6.79,0.31-44.79-0.96-63.86-0.96c-31.76,0-47.21,0.34-60.55,0.96
			c-20.74,0.96-24.01-30.85-2.49-31.58c23.21-0.79,104.53-0.16,127.73,0.96C582.45,236.66,582.45,265.37,561.72,266.33z"/>
          <path className="st3" d="M561.75,267.02c-7.37,0.32-14.64,0.08-21.96-0.04l-21.9-0.44c-14.6-0.33-29.19-0.23-43.79-0.09
			c-7.3,0.07-14.6,0.21-21.89,0.38c-3.65,0.08-7.29,0.19-10.94,0.32l-2.73,0.11c-0.89,0.03-1.85,0.1-2.81,0.05
			c-1.92-0.06-3.84-0.43-5.66-1.12c-3.65-1.37-6.69-4.16-8.52-7.54c-1.84-3.38-2.64-7.3-2.21-11.13c0.41-3.81,2.18-7.61,5.22-10.11
			c3.01-2.55,7.02-3.58,10.76-3.62c7.32-0.22,14.63-0.28,21.93-0.33l21.92-0.04c14.61,0.03,29.22,0.14,43.83,0.32
			c7.3,0.1,14.61,0.18,21.92,0.39l10.96,0.36l5.48,0.24c1.8,0.05,3.79,0.24,5.61,0.79c1.84,0.54,3.61,1.4,5.15,2.58
			c1.54,1.18,2.84,2.66,3.81,4.32c1.96,3.33,2.59,7.33,1.95,11.09c-0.63,3.76-2.61,7.32-5.61,9.7c-1.49,1.19-3.19,2.1-4.99,2.7
			C565.48,266.53,563.62,266.8,561.75,267.02z M561.68,265.65c0.88,0,1.78-0.04,2.65-0.2c0.87-0.18,1.72-0.41,2.55-0.7
			c1.65-0.6,3.2-1.46,4.53-2.58c2.67-2.23,4.39-5.48,4.89-8.9c0.53-3.42-0.18-6.99-1.9-9.93c-1.72-2.96-4.63-5.12-7.94-6.05
			c-1.68-0.49-3.35-0.65-5.19-0.69l-5.46-0.21l-10.94-0.31c-7.3-0.17-14.6-0.3-21.9-0.4c-14.6-0.21-29.21-0.34-43.81-0.39l-21.9,0
			c-7.3,0.03-14.6,0.08-21.88,0.29c-3.54,0.03-7.04,0.97-9.68,3.19c-2.66,2.18-4.23,5.51-4.61,8.95c-0.39,3.45,0.33,7.04,2.01,10.08
			c1.67,3.05,4.38,5.49,7.62,6.7c1.62,0.6,3.34,0.93,5.08,0.97c1.7,0.04,3.6-0.14,5.41-0.19c3.65-0.16,7.31-0.29,10.96-0.4
			c7.31-0.21,14.62-0.28,21.92-0.34c14.61-0.09,29.23-0.14,43.84,0.2L561.68,265.65z"/>
        </g>
        <g>
          <path className="st7" d="M681.85,308.16c7.13-0.14,24.35,1.13,39.15,0.42c33.28-1.6,77.37,0.51,91.36,0.1
			c21.76-0.64,25.48-27.78,2.92-28.68c-24.34-0.98-109.63-1.03-133.99-0.55C657.36,279.91,657.93,308.63,681.85,308.16z"/>
          <path className="st3" d="M681.83,307.47c3.84-0.18,7.64-0.08,11.44,0.05c3.8,0.12,7.59,0.24,11.38,0.3c3.79,0.06,7.58,0.08,11.36-0.01
			c3.77-0.09,7.57-0.3,11.38-0.44c15.22-0.51,30.44-0.44,45.64-0.22c7.6,0.1,15.2,0.26,22.8,0.43c3.8,0.07,7.59,0.15,11.38,0.16
			c1.89,0.01,3.8,0.01,5.65-0.07c1.83-0.05,3.63-0.41,5.37-0.91c3.48-1.02,6.6-3.15,8.71-6.04c2.11-2.87,3.12-6.59,2.44-10.05
			c-0.34-1.72-1.1-3.34-2.21-4.7c-1.1-1.36-2.54-2.44-4.14-3.23c-1.6-0.79-3.36-1.3-5.16-1.54c-0.45-0.08-0.9-0.14-1.36-0.16
			l-1.37-0.1l-2.84-0.09c-15.17-0.42-30.38-0.51-45.57-0.61c-15.2-0.08-30.4-0.09-45.59-0.06l-22.8,0.07l-11.39,0.04l-5.69,0.06
			c-1.84,0.08-3.68,0.21-5.45,0.68c-1.76,0.45-3.48,1.1-5.02,2.03c-1.54,0.94-2.9,2.16-3.96,3.59c-2.14,2.86-2.99,6.68-2.11,10.18
			c0.82,3.51,3.28,6.53,6.45,8.33C674.36,306.89,678.12,307.72,681.83,307.47z M681.86,308.84l-1.46-0.09
			c-0.49-0.01-0.97-0.06-1.45-0.11l-1.45-0.13l-1.44-0.25c-1.91-0.39-3.8-1-5.56-1.94c-1.74-0.95-3.33-2.22-4.6-3.78
			c-1.27-1.56-2.22-3.39-2.72-5.36c-1.05-3.92-0.21-8.32,2.23-11.6c1.2-1.64,2.73-3.02,4.45-4.08c1.72-1.05,3.6-1.77,5.52-2.27
			c1.92-0.51,3.9-0.67,5.85-0.76l5.71-0.08l11.41-0.09l22.81-0.07c15.2-0.01,30.41,0.03,45.61,0.13c15.2,0.12,30.4,0.23,45.62,0.68
			c3.72,0.06,7.87,0.27,11.46,2.1c1.79,0.88,3.45,2.12,4.73,3.71c1.28,1.58,2.17,3.48,2.56,5.47c0.78,4.01-0.4,8.19-2.73,11.42
			c-2.34,3.25-5.81,5.61-9.6,6.74c-1.89,0.56-3.84,0.95-5.81,1.02c-1.94,0.1-3.83,0.1-5.74,0.1c-3.81,0-7.61-0.05-11.41-0.11
			l-22.8-0.41c-15.19-0.25-30.38-0.35-45.55,0.12c-3.78,0.14-7.58,0.34-11.4,0.42c-3.82,0.08-7.63,0.06-11.43-0.02
			C697.02,309.46,689.39,309.14,681.86,308.84z"/>
        </g>
        <g>
          <path className="st7" d="M811.7,332.12c-22.07-1.12-114.42-0.54-128.07,0.21c-21.22,1.15-24.21,28.37-2.16,28.74
			c23.78,0.4,104.5-0.94,128.26-2C833.06,358.03,835.02,333.3,811.7,332.12z"/>
          <path className="st3" d="M811.66,332.8c-29.4-0.51-58.89-0.41-88.33-0.14l-22.09,0.24l-11.04,0.19c-1.84,0.04-3.68,0.08-5.5,0.16
			c-1.84,0.04-3.56,0.23-5.27,0.67c-3.4,0.85-6.57,2.67-8.81,5.33c-2.26,2.63-3.57,6.09-3.41,9.52c0.11,3.43,1.88,6.7,4.7,8.63
			c2.82,1.99,6.4,2.71,9.96,2.72c14.7,0.16,29.44-0.05,44.16-0.22c14.73-0.2,29.45-0.45,44.18-0.77l22.08-0.5l11.04-0.27l5.51-0.18
			c1.83-0.05,3.59-0.19,5.35-0.55c3.48-0.69,6.86-2.13,9.42-4.53c1.27-1.19,2.32-2.62,3.01-4.2c0.68-1.58,0.99-3.32,0.85-5.03
			c-0.15-1.71-0.76-3.37-1.74-4.8c-0.98-1.43-2.31-2.62-3.81-3.54c-1.51-0.92-3.18-1.6-4.91-2.05
			C815.27,333.04,813.47,332.78,811.66,332.8z M811.73,331.43c0.94,0.11,1.87,0.22,2.8,0.36c0.93,0.12,1.86,0.28,2.78,0.51
			c1.84,0.44,3.64,1.12,5.31,2.08c1.66,0.97,3.17,2.26,4.32,3.86c1.15,1.6,1.89,3.51,2.11,5.49c0.44,4-1.36,8.01-4.22,10.66
			c-2.86,2.71-6.55,4.27-10.27,5.03c-1.86,0.39-3.79,0.55-5.63,0.61l-5.53,0.2l-11.05,0.32l-22.1,0.5
			c-14.73,0.29-29.46,0.52-44.19,0.7c-14.73,0.14-29.46,0.33-44.21,0.15c-3.74-0.02-7.72-0.78-10.95-3.06
			c-1.6-1.13-2.97-2.62-3.9-4.36c-0.94-1.73-1.46-3.69-1.53-5.64c-0.17-3.93,1.33-7.79,3.82-10.74c2.49-2.98,6-5,9.71-5.94
			c1.84-0.49,3.79-0.7,5.64-0.75c1.85-0.09,3.7-0.14,5.54-0.19l11.05-0.24c7.37-0.14,14.73-0.21,22.1-0.27
			c14.73-0.11,29.47-0.15,44.2-0.12c7.37,0.02,14.73,0.05,22.1,0.14l11.05,0.16C804.36,330.96,808.04,331.12,811.73,331.43z"/>
        </g>
        <g>
          <path className="st4" d="M814.29,223.91c-7.16-0.27-47.19,0.95-67.29,0.98c-33.47,0.05-49.74-0.23-63.79-0.77
			c-21.85-0.83-25.25,28.09-2.58,28.72c24.46,0.68,110.13-0.04,134.57-1.09C836.17,250.84,836.13,224.74,814.29,223.91z"/>
          <path className="st3" d="M814.26,224.59l-45.25,0.92c-15.1,0.34-30.22,0.35-45.32,0.33c-7.55-0.02-15.11-0.06-22.66-0.19l-11.33-0.31
			l-5.66-0.21c-1.88-0.1-3.65-0.06-5.43,0.26c-3.53,0.62-6.86,2.35-9.17,5.06c-2.32,2.67-3.59,6.27-3.34,9.79
			c0.21,3.52,2.05,6.86,4.96,8.85c2.91,2.04,6.55,2.81,10.22,2.83c15.07,0.31,30.17,0.23,45.27,0.18
			c15.1-0.07,30.19-0.21,45.28-0.42l22.64-0.37l11.32-0.2l5.65-0.14c1.87-0.08,3.8-0.06,5.58-0.26c3.59-0.41,7.11-1.8,9.59-4.37
			c2.5-2.52,3.78-6.18,3.38-9.72c-0.38-3.55-2.42-6.85-5.39-8.91c-1.47-1.05-3.15-1.81-4.9-2.34
			C817.94,224.85,816.11,224.53,814.26,224.59z M814.31,223.22c1.92,0.22,3.85,0.42,5.73,0.93c1.88,0.52,3.7,1.31,5.34,2.42
			c3.3,2.18,5.7,5.84,6.2,9.86c0.26,2,0.07,4.06-0.56,5.99c-0.62,1.93-1.74,3.69-3.14,5.15c-2.82,2.94-6.77,4.49-10.65,4.95
			c-1.98,0.23-3.84,0.21-5.74,0.3l-5.67,0.17l-11.33,0.25l-22.65,0.35c-15.1,0.18-30.2,0.3-45.3,0.35
			c-15.1,0.02-30.2,0.08-45.32-0.25c-3.83-0.02-7.9-0.84-11.2-3.17c-1.64-1.15-3.04-2.67-4.01-4.44c-0.98-1.76-1.54-3.74-1.67-5.74
			c-0.27-4,1.15-8.03,3.74-11.06c2.58-3.06,6.31-5,10.18-5.7c0.97-0.17,1.95-0.28,2.93-0.33c0.97-0.04,1.98-0.02,2.9,0.02l5.65,0.17
			l11.31,0.24c7.54,0.14,15.09,0.22,22.63,0.26l22.64,0.05c7.54-0.01,15.08-0.14,22.63-0.27l22.64-0.44
			C799.18,223.15,806.7,222.93,814.31,223.22z"/>
        </g>
        <g>
          <path className="st7" d="M810.1,391.92c-7.07,0.15-24.13-1.11-38.8-0.38c-32.98,1.63-76.67-0.44-90.53-0.02
			c-21.56,0.66-25.28,27.8-2.92,28.69c24.12,0.96,108.64,0.93,132.78,0.43C834.34,420.14,833.81,391.42,810.1,391.92z"/>
          <path className="st3" d="M810.12,392.6c-3.81,0.19-7.58,0.08-11.35-0.04c-3.77-0.12-7.53-0.23-11.29-0.29
			c-3.76-0.06-7.52-0.07-11.27,0.02c-3.74,0.09-7.51,0.31-11.29,0.45c-15.1,0.52-30.2,0.46-45.28,0.26
			c-7.54-0.09-15.08-0.25-22.62-0.41c-3.77-0.07-7.53-0.14-11.29-0.15c-1.87,0-3.77-0.01-5.6,0.09c-1.81,0.07-3.6,0.42-5.31,0.95
			c-3.43,1.05-6.51,3.19-8.57,6.09c-2.06,2.87-3.04,6.56-2.36,10c0.34,1.71,1.09,3.32,2.18,4.67c1.09,1.35,2.51,2.44,4.09,3.22
			c1.58,0.79,3.32,1.3,5.11,1.54c0.89,0.18,1.8,0.17,2.71,0.26l2.82,0.09c15.05,0.41,30.13,0.49,45.21,0.57
			c15.08,0.06,30.15,0.07,45.23,0.02l22.61-0.09l11.3-0.05l5.64-0.07c1.82-0.07,3.64-0.25,5.39-0.71c3.48-0.9,6.78-2.76,8.84-5.69
			c2.09-2.87,2.89-6.66,2.03-10.12c-0.81-3.49-3.23-6.49-6.37-8.29C817.53,393.18,813.8,392.35,810.12,392.6z M810.09,391.23
			l1.45,0.09l0.72,0.03c0.24,0.03,0.48,0.06,0.72,0.07l1.44,0.13l1.43,0.24c1.9,0.39,3.77,1,5.51,1.94
			c1.72,0.95,3.29,2.22,4.55,3.77c1.26,1.55,2.18,3.38,2.69,5.32c1.03,3.89,0.25,8.25-2.13,11.53c-1.17,1.64-2.67,3.04-4.37,4.1
			c-1.69,1.06-3.55,1.81-5.45,2.33c-1.91,0.5-3.86,0.71-5.81,0.79l-5.67,0.1l-11.31,0.1l-22.62,0.09c-15.08,0.03-30.16,0-45.24-0.09
			c-15.08-0.1-30.16-0.2-45.26-0.64c-3.69-0.06-7.81-0.26-11.37-2.09c-1.78-0.88-3.42-2.12-4.68-3.7c-1.27-1.57-2.13-3.46-2.52-5.44
			c-0.78-3.98,0.37-8.12,2.64-11.35c2.28-3.25,5.7-5.63,9.45-6.8c1.87-0.59,3.81-0.98,5.76-1.06c1.93-0.11,3.81-0.11,5.7-0.12
			c3.78-0.01,7.55,0.04,11.32,0.1l22.61,0.39c15.07,0.23,30.14,0.33,45.19-0.16c3.75-0.14,7.52-0.34,11.31-0.43
			c3.79-0.08,7.57-0.06,11.34,0C795.06,390.62,802.62,390.94,810.09,391.23z"/>
        </g>
        <g>
          <path className="st4" d="M817.7,618.21c-22.07-1.12-114.42-0.53-128.07,0.21c-21.22,1.15-24.21,28.37-2.16,28.74
			c23.78,0.4,104.5-0.94,128.26-2C839.06,644.12,841.02,619.39,817.7,618.21z"/>
          <path className="st3" d="M817.66,618.89c-29.4-0.51-58.89-0.41-88.33-0.14l-22.09,0.24l-11.04,0.19c-1.84,0.04-3.68,0.08-5.5,0.16
			c-1.84,0.04-3.56,0.23-5.27,0.67c-3.4,0.85-6.57,2.67-8.81,5.33c-2.26,2.63-3.57,6.09-3.41,9.52c0.11,3.43,1.88,6.7,4.7,8.63
			c2.82,1.99,6.4,2.71,9.96,2.72c14.7,0.16,29.44-0.05,44.16-0.22c14.73-0.2,29.45-0.45,44.18-0.77l22.08-0.5l11.04-0.27l5.51-0.18
			c1.83-0.05,3.59-0.19,5.35-0.55c3.48-0.69,6.86-2.13,9.42-4.53c1.27-1.19,2.32-2.62,3.01-4.2c0.68-1.58,0.99-3.32,0.85-5.03
			c-0.15-1.71-0.76-3.37-1.74-4.8c-0.98-1.43-2.31-2.62-3.81-3.54c-1.51-0.92-3.18-1.6-4.91-2.04
			C821.27,619.13,819.47,618.87,817.66,618.89z M817.73,617.52c0.94,0.11,1.87,0.22,2.8,0.36c0.93,0.12,1.86,0.28,2.78,0.51
			c1.84,0.44,3.64,1.12,5.31,2.08c1.66,0.97,3.17,2.26,4.32,3.86c1.15,1.6,1.89,3.51,2.11,5.49c0.44,4-1.36,8.01-4.22,10.66
			c-2.86,2.71-6.55,4.27-10.27,5.03c-1.86,0.39-3.79,0.55-5.63,0.61l-5.53,0.2l-11.05,0.32l-22.1,0.5
			c-14.73,0.29-29.46,0.52-44.19,0.7c-14.73,0.14-29.46,0.33-44.21,0.15c-3.74-0.02-7.72-0.78-10.95-3.06
			c-1.6-1.13-2.97-2.62-3.9-4.36c-0.94-1.73-1.46-3.69-1.53-5.64c-0.17-3.93,1.33-7.79,3.82-10.74c2.49-2.98,6-5,9.71-5.94
			c1.84-0.49,3.79-0.7,5.64-0.75c1.85-0.09,3.7-0.14,5.54-0.19l11.05-0.24c7.37-0.14,14.73-0.21,22.1-0.27
			c14.73-0.11,29.47-0.15,44.2-0.12c7.37,0.02,14.73,0.05,22.1,0.14l11.05,0.16C810.36,617.05,814.04,617.22,817.73,617.52z"/>
        </g>
        <g>
          <path className="st4" d="M817.48,670.76c-7.02,0.15-23.96-1.11-38.52-0.38c-32.75,1.63-76.12-0.45-89.89-0.03
			c-21.41,0.66-25.1,27.8-2.89,28.68c23.95,0.96,107.87,0.94,131.84,0.44C841.55,698.99,841.02,670.27,817.48,670.76z"/>
          <path className="st3" d="M817.49,671.45c-3.79,0.19-7.53,0.08-11.28-0.05c-3.74-0.12-7.48-0.23-11.22-0.29
			c-3.74-0.06-7.47-0.07-11.2,0.02c-3.72,0.09-7.46,0.31-11.22,0.45c-15.01,0.52-30.01,0.46-45,0.26c-7.5-0.1-14.99-0.25-22.48-0.41
			c-3.74-0.07-7.49-0.14-11.22-0.15c-1.86,0-3.75-0.01-5.56,0.09c-1.8,0.08-3.57,0.43-5.27,0.98c-3.4,1.07-6.44,3.21-8.45,6.12
			c-2.02,2.88-2.97,6.55-2.28,9.96c0.34,1.7,1.08,3.3,2.16,4.65c1.07,1.35,2.48,2.43,4.05,3.21c1.57,0.79,3.3,1.3,5.07,1.54
			c0.88,0.18,1.79,0.18,2.69,0.26l2.8,0.09c14.95,0.41,29.95,0.49,44.92,0.57c14.98,0.06,29.97,0.07,44.95,0.02l22.47-0.09
			l11.23-0.05l5.6-0.07c1.81-0.06,3.61-0.28,5.35-0.72c3.44-0.93,6.7-2.81,8.72-5.73c2.05-2.87,2.8-6.64,1.95-10.09
			c-0.81-3.46-3.2-6.46-6.31-8.25C824.86,672.03,821.15,671.2,817.49,671.45z M817.47,670.08l1.44,0.09l0.72,0.03
			c0.24,0.03,0.48,0.06,0.72,0.07l1.43,0.13l1.42,0.25c1.89,0.39,3.75,1,5.47,1.94c3.43,1.87,6.19,5.16,7.17,9.06
			c1.02,3.86,0.29,8.19-2.04,11.47c-2.29,3.35-5.95,5.46-9.7,6.48c-1.9,0.49-3.84,0.74-5.77,0.81l-5.63,0.1l-11.24,0.1l-22.48,0.09
			c-14.99,0.02-29.97,0-44.96-0.09c-14.99-0.1-29.97-0.2-44.97-0.64c-3.66-0.06-7.77-0.26-11.3-2.1c-1.77-0.88-3.39-2.12-4.64-3.7
			c-1.25-1.57-2.11-3.44-2.5-5.4c-0.78-3.95,0.33-8.07,2.55-11.3c2.23-3.26,5.62-5.65,9.33-6.84c1.85-0.61,3.78-0.99,5.72-1.09
			c1.93-0.12,3.79-0.11,5.67-0.12c3.76-0.01,7.51,0.04,11.25,0.1l22.47,0.39c14.98,0.24,29.95,0.33,44.91-0.15
			c3.73-0.14,7.47-0.34,11.24-0.43c3.76-0.08,7.52-0.06,11.27,0C802.53,669.47,810.04,669.78,817.47,670.08z"/>
        </g>
        <g>
          <path className="st4" d="M683.69,198.8c22.09,1.09,114.55,0.41,128.21-0.34c21.24-1.18,24.27-28.4,2.2-28.75
			c-23.8-0.38-104.62,1.06-128.4,2.14C662.34,172.91,660.34,197.65,683.69,198.8z"/>
          <path className="st3" d="M683.72,198.12c29.43,0.48,58.95,0.35,88.42,0.05l22.11-0.27l11.05-0.2c1.84-0.04,3.68-0.09,5.51-0.17
			c1.84-0.05,3.56-0.23,5.28-0.67c3.41-0.85,6.57-2.67,8.83-5.32c2.27-2.63,3.59-6.09,3.44-9.52c-0.1-3.44-1.87-6.71-4.7-8.64
			c-2.82-1.99-6.41-2.7-9.97-2.71c-14.72-0.14-29.47,0.08-44.21,0.26c-14.74,0.21-29.48,0.48-44.22,0.81l-22.1,0.52l-11.05,0.28
			l-5.52,0.18c-1.84,0.05-3.6,0.19-5.35,0.55c-3.48,0.69-6.87,2.13-9.43,4.53c-1.27,1.19-2.33,2.61-3.03,4.2
			c-0.69,1.58-1,3.32-0.86,5.03c0.15,1.71,0.76,3.37,1.73,4.8c0.98,1.43,2.31,2.63,3.82,3.55c1.51,0.92,3.18,1.59,4.92,2.04
			C680.11,197.88,681.92,198.14,683.72,198.12z M683.66,199.49c-0.94-0.11-1.87-0.22-2.8-0.36c-0.93-0.12-1.87-0.28-2.78-0.51
			c-1.84-0.43-3.65-1.12-5.31-2.08c-1.66-0.97-3.18-2.26-4.33-3.87c-1.14-1.6-1.89-3.52-2.1-5.51c-0.43-4.01,1.39-8.02,4.26-10.66
			c2.86-2.7,6.56-4.26,10.28-5.02c1.86-0.39,3.79-0.56,5.64-0.61l5.53-0.21l11.06-0.33l22.12-0.52c14.74-0.31,29.49-0.56,44.24-0.74
			c14.75-0.16,29.49-0.36,44.26-0.19c3.75,0.02,7.73,0.77,10.96,3.05c1.61,1.13,2.97,2.63,3.91,4.37c0.94,1.74,1.45,3.69,1.52,5.65
			c0.16,3.93-1.35,7.79-3.85,10.74c-2.5,2.97-6.01,5-9.72,5.94c-1.84,0.49-3.8,0.7-5.64,0.76c-1.85,0.09-3.7,0.15-5.54,0.2
			l-11.06,0.25c-7.37,0.15-14.75,0.23-22.12,0.29c-14.75,0.13-29.49,0.18-44.24,0.16c-7.37-0.01-14.75-0.03-22.12-0.11L694.73,200
			C691.04,199.95,687.35,199.79,683.66,199.49z"/>
        </g>
        <g>
          <path className="st4" d="M682.71,588.66c7.12,0.36,46.99-0.37,67-0.16c33.32,0.35,49.52,0.84,63.5,1.54
			c21.74,1.1,25.48-27.78,2.92-28.68c-24.34-0.98-109.64-1.31-133.99-0.55C661.24,561.46,660.96,587.56,682.71,588.66z"/>
          <path className="st3" d="M682.74,587.98l45.08-0.37c15.05-0.15,30.1,0.02,45.14,0.23c7.52,0.11,15.05,0.25,22.57,0.47l11.28,0.45
			l5.64,0.28c1.87,0.13,3.63,0.1,5.41-0.21c3.52-0.59,6.84-2.3,9.16-4.98c2.33-2.65,3.62-6.22,3.4-9.73
			c-0.17-3.51-1.96-6.86-4.82-8.88c-2.86-2.07-6.49-2.89-10.14-2.95c-15-0.5-30.05-0.6-45.09-0.74
			c-15.04-0.11-30.08-0.16-45.11-0.13l-22.55,0.09l-11.27,0.06l-5.63,0.07c-1.87,0.06-3.78,0.01-5.55,0.2
			c-3.57,0.38-7.09,1.74-9.57,4.28c-2.5,2.5-3.8,6.13-3.43,9.67c0.34,3.54,2.33,6.85,5.25,8.95c1.45,1.06,3.11,1.85,4.84,2.4
			C679.07,587.68,680.9,588.01,682.74,587.98z M682.67,589.35c-1.91-0.25-3.83-0.47-5.7-1c-1.86-0.54-3.67-1.35-5.29-2.48
			c-3.26-2.22-5.6-5.89-6.05-9.9c-0.24-2-0.03-4.05,0.61-5.97c0.63-1.92,1.76-3.67,3.16-5.11c2.83-2.92,6.77-4.44,10.64-4.87
			c1.98-0.21,3.83-0.17,5.72-0.23l5.65-0.1l11.29-0.11l22.57-0.07c15.04,0,30.09,0.06,45.13,0.2c15.05,0.17,30.08,0.29,45.14,0.81
			c3.81,0.07,7.86,0.94,11.11,3.31c1.62,1.17,2.99,2.71,3.94,4.48c0.95,1.77,1.49,3.75,1.6,5.74c0.22,3.99-1.22,7.99-3.81,10.99
			c-2.59,3.03-6.32,4.95-10.18,5.61c-1.92,0.35-3.93,0.38-5.81,0.26l-5.63-0.24l-11.26-0.38c-7.51-0.23-15.03-0.4-22.54-0.53
			l-22.55-0.33c-7.51-0.08-15.03-0.05-22.55-0.01l-22.56,0.17C697.74,589.61,690.25,589.73,682.67,589.35z"/>
        </g>
        <g>
          <path className="st4" d="M684.92,531.97c22,1.09,114.05,0.41,127.65-0.34c21.15-1.18,24.16-28.4,2.19-28.75
			c-23.7-0.38-104.16,1.06-127.84,2.14C663.66,506.08,661.68,530.82,684.92,531.97z"/>
          <path className="st3" d="M684.96,531.29c29.32,0.48,58.72,0.35,88.08,0.05l22.02-0.27l11-0.2c1.83-0.04,3.66-0.09,5.48-0.17
			c1.83-0.05,3.54-0.24,5.25-0.69c3.39-0.86,6.53-2.69,8.76-5.35c2.24-2.63,3.54-6.08,3.39-9.51c-0.11-3.42-1.86-6.68-4.67-8.62
			c-2.81-1.98-6.38-2.7-9.92-2.71c-14.66-0.14-29.36,0.08-44.04,0.26c-14.68,0.21-29.37,0.48-44.05,0.81l-22.02,0.52l-11.01,0.28
			l-5.5,0.18c-1.83,0.05-3.58,0.2-5.33,0.56c-3.47,0.7-6.83,2.15-9.37,4.55c-1.26,1.2-2.31,2.62-2.99,4.2
			c-0.68,1.58-0.99,3.31-0.84,5.02c0.15,1.7,0.75,3.36,1.73,4.79c0.97,1.43,2.3,2.62,3.79,3.54c1.5,0.92,3.16,1.59,4.89,2.04
			C681.36,531.05,683.16,531.31,684.96,531.29z M684.89,532.66c-0.93-0.11-1.86-0.22-2.79-0.36c-0.93-0.12-1.86-0.28-2.77-0.51
			c-1.84-0.43-3.63-1.12-5.29-2.08c-1.66-0.97-3.16-2.26-4.3-3.86c-1.14-1.6-1.88-3.51-2.09-5.48c-0.43-3.99,1.35-7.99,4.19-10.64
			c2.84-2.71,6.51-4.29,10.22-5.05c1.85-0.4,3.78-0.57,5.62-0.62l5.51-0.21l11.02-0.33l22.03-0.52c14.69-0.31,29.38-0.56,44.07-0.75
			c14.69-0.16,29.37-0.36,44.09-0.2c3.73,0.02,7.7,0.77,10.92,3.05c1.6,1.13,2.95,2.62,3.88,4.36c0.93,1.73,1.44,3.68,1.52,5.63
			c0.17,3.92-1.32,7.77-3.79,10.72c-2.47,2.98-5.96,5.01-9.65,5.97c-1.84,0.49-3.78,0.72-5.62,0.77c-1.85,0.09-3.69,0.15-5.52,0.2
			l-11.02,0.25c-7.35,0.15-14.69,0.23-22.04,0.29c-14.69,0.13-29.38,0.18-44.07,0.16c-7.35-0.01-14.69-0.03-22.04-0.11l-11.02-0.15
			C692.24,533.12,688.56,532.96,684.89,532.66z"/>
        </g>
        <g>
          <path className="st4" d="M813.56,448.89c-7.06-0.35-46.56,0.41-66.39,0.22c-33.02-0.32-49.07-0.79-62.93-1.49
			c-21.54-1.08-25.28,27.8-2.92,28.69c24.12,0.96,108.65,1.21,132.78,0.43C834.8,476.07,835.1,449.97,813.56,448.89z"/>
          <path className="st3" d="M813.53,449.57l-44.72,0.41c-14.93,0.17-29.86,0.01-44.78-0.19c-7.46-0.11-14.93-0.23-22.39-0.45
			c-3.73-0.13-7.46-0.27-11.19-0.44l-5.6-0.28c-1.84-0.12-3.6-0.07-5.35,0.25c-3.48,0.61-6.75,2.35-9.01,5.05
			c-2.27,2.66-3.52,6.2-3.3,9.69c0.17,3.48,1.93,6.81,4.76,8.83c2.83,2.07,6.42,2.89,10.04,2.95c14.88,0.48,29.81,0.57,44.73,0.7
			c14.92,0.1,29.84,0.13,44.75,0.09l22.37-0.11l11.18-0.07l5.59-0.08c1.86-0.06,3.75-0.01,5.5-0.22c3.54-0.4,7.01-1.8,9.44-4.36
			c2.45-2.51,3.7-6.13,3.33-9.63c-0.34-3.51-2.3-6.81-5.18-8.9c-1.44-1.06-3.07-1.85-4.79-2.4
			C817.16,449.87,815.35,449.53,813.53,449.57z M813.59,448.2c1.9,0.24,3.8,0.46,5.66,1c1.85,0.54,3.64,1.35,5.24,2.48
			c3.22,2.22,5.52,5.87,5.98,9.84c0.51,3.95-0.84,8.15-3.64,11.03c-2.76,2.94-6.66,4.5-10.5,4.96c-1.96,0.24-3.81,0.18-5.68,0.26
			l-5.6,0.1l-11.2,0.13l-22.39,0.09c-14.92,0.01-29.85-0.04-44.77-0.16c-14.93-0.15-29.84-0.26-44.78-0.77
			c-3.78-0.07-7.8-0.94-11.02-3.31c-1.6-1.17-2.95-2.7-3.88-4.46c-0.94-1.76-1.46-3.72-1.58-5.69c-0.23-3.95,1.16-7.92,3.7-10.93
			c2.52-3.04,6.2-5.01,10.03-5.7c1.91-0.36,3.89-0.42,5.77-0.3l5.58,0.24c3.72,0.15,7.45,0.27,11.17,0.37
			c7.45,0.22,14.91,0.39,22.36,0.51l22.37,0.31c7.45,0.08,14.91,0.03,22.37-0.01l22.38-0.19
			C798.64,447.95,806.08,447.82,813.59,448.2z"/>
        </g>
        <g>
          <path className="st3" d="M411.41,443.29c-0.85,0.21-1.69,0.22-2.54,0.24l-2.54,0.06c-1.69,0.06-3.38,0.17-5.07,0.26
			c-3.38,0.22-6.75,0.51-10.11,0.89c-6.73,0.74-13.47,1.51-20.15,2.7l-20.09,3.47c-3.35,0.58-6.73,1.05-10.12,1.4
			c-1.7,0.18-3.4,0.31-5.1,0.47l-2.55,0.19l-1.28,0.09c-0.42,0.05-0.86,0-1.27,0.2l-0.01,0c-0.37,0.18-0.81,0.03-0.99-0.33
			c-0.18-0.37-0.03-0.81,0.33-0.99c0.18-0.09,0.37-0.1,0.55-0.05c0.44,0.14,0.85-0.03,1.27-0.08l1.26-0.21
			c0.84-0.14,1.67-0.26,2.51-0.43c1.67-0.32,3.35-0.49,5.03-0.73c3.35-0.47,6.72-0.9,10.06-1.46l20.12-3.33
			c6.73-1.02,13.45-2.17,20.24-2.86c3.39-0.35,6.8-0.6,10.21-0.79c1.71-0.07,3.41-0.16,5.12-0.21l2.56-0.03
			c0.85-0.01,1.71-0.04,2.56,0.15c0.38,0.08,0.62,0.46,0.53,0.84c-0.06,0.27-0.27,0.47-0.51,0.53L411.41,443.29z"/>
        </g>
        <g>
          <path className="st3" d="M411.53,353.44c-1.23,0.44-2.44,0.85-3.62,1.4c-1.18,0.54-2.29,1.25-3.37,1.99c-2.15,1.53-4.1,3.36-5.92,5.32
			c-3.64,3.93-6.8,8.33-9.79,12.84c-5.95,9.04-11.46,18.42-16.88,27.87c-5.46,9.41-11.03,18.81-17.42,27.7
			c-3.19,4.44-6.67,8.72-10.58,12.59c-1.96,1.93-4.01,3.77-6.19,5.46c-1.09,0.85-2.22,1.64-3.37,2.4c-0.57,0.39-1.18,0.72-1.76,1.09
			c-0.58,0.37-1.24,0.61-1.76,1.1c-0.29,0.27-0.73,0.25-1-0.03c-0.27-0.29-0.25-0.73,0.03-1c0.09-0.08,0.19-0.14,0.3-0.17l0.03-0.01
			c0.66-0.18,1.2-0.6,1.76-0.95c0.55-0.39,1.12-0.74,1.65-1.15c1.08-0.8,2.13-1.63,3.14-2.5c2.01-1.77,3.98-3.59,5.85-5.52
			c3.74-3.86,7.18-8.04,10.35-12.42c6.35-8.77,11.93-18.1,17.44-27.48c5.56-9.34,10.75-18.93,16.81-28.04
			c3.05-4.53,6.28-8.98,10.06-13.02c1.89-2.01,3.94-3.91,6.23-5.52c1.16-0.79,2.35-1.55,3.66-2.13c1.29-0.59,2.67-1.08,4.11-1.15
			c0.38-0.02,0.71,0.27,0.73,0.65c0.02,0.31-0.18,0.59-0.46,0.69L411.53,353.44z"/>
        </g>
        <g>
          <path className="st3" d="M414.7,251.86c-1.77,1.4-3.44,2.89-5.01,4.5c-1.55,1.64-2.98,3.4-4.29,5.25c-2.64,3.7-4.84,7.72-6.85,11.83
			c-3.98,8.25-7.1,16.92-9.91,25.67c-5.59,17.53-10.2,35.4-14.69,53.3c-4.55,17.88-9.06,35.81-14.95,53.37
			c-2.96,8.77-6.34,17.44-10.68,25.66c-2.17,4.11-4.6,8.1-7.44,11.81c-1.39,1.87-2.96,3.62-4.59,5.29c-0.85,0.81-1.68,1.63-2.6,2.36
			c-0.86,0.8-1.89,1.38-2.74,2.22c-0.27,0.27-0.71,0.27-0.98,0c-0.27-0.27-0.27-0.71,0-0.98c0.05-0.05,0.1-0.09,0.16-0.12l0.05-0.03
			c1-0.54,1.87-1.28,2.71-2.04c0.86-0.73,1.64-1.56,2.44-2.37c1.53-1.68,2.99-3.42,4.29-5.29c2.63-3.71,4.96-7.64,7.06-11.71
			c4.17-8.15,7.56-16.72,10.51-25.43c5.9-17.44,10.45-35.33,15.04-53.19l6.91-26.82c2.36-8.93,4.8-17.85,7.67-26.64
			c2.87-8.79,6.03-17.52,10.11-25.88c2.05-4.17,4.32-8.26,7.05-12.06c2.76-3.74,5.86-7.47,10-9.86c0.33-0.19,0.75-0.08,0.94,0.25
			c0.17,0.3,0.09,0.67-0.17,0.88L414.7,251.86z"/>
        </g>
        <g>
          <path className="st3" d="M413.03,525.82c-1.22,0.01-2.48-0.05-3.64-0.45c-0.58-0.18-1.2-0.29-1.76-0.54l-1.69-0.7
			c-0.58-0.21-1.08-0.55-1.62-0.83c-0.52-0.3-1.08-0.56-1.57-0.89c-0.99-0.68-2.04-1.29-2.95-2.05c-3.81-2.9-7.14-6.3-10.3-9.8
			c-6.29-7.05-11.7-14.74-17.52-22.07c-5.77-7.35-11.6-14.7-18.27-21.14c-3.35-3.2-6.86-6.23-10.69-8.78
			c-1.91-1.29-3.92-2.4-6.02-3.34c-0.51-0.27-1.05-0.45-1.59-0.64l-1.62-0.58c-0.54-0.21-1.11-0.29-1.66-0.45
			c-0.56-0.12-1.1-0.34-1.69-0.3l-0.08,0.01c-0.4,0.02-0.74-0.28-0.77-0.68c-0.02-0.4,0.28-0.74,0.68-0.77
			c0.14-0.01,0.28,0.02,0.39,0.09c0.55,0.3,1.16,0.31,1.74,0.44c0.59,0.12,1.19,0.17,1.76,0.35c1.14,0.35,2.33,0.6,3.44,1.07
			c2.26,0.82,4.4,1.94,6.41,3.23c4.05,2.55,7.63,5.7,11.04,8.97c6.78,6.6,12.61,14.02,18.35,21.43c5.69,7.46,11.39,14.9,17.55,21.88
			c3.08,3.48,6.33,6.82,9.97,9.63c0.88,0.75,1.87,1.32,2.8,1.98c0.47,0.33,0.99,0.56,1.47,0.85c0.5,0.26,0.98,0.59,1.51,0.78
			l1.55,0.66c0.51,0.24,1.07,0.33,1.6,0.5c1.07,0.38,2.17,0.4,3.26,0.78l0.14,0.05c0.35,0.12,0.54,0.51,0.42,0.87
			C413.57,525.64,413.31,525.82,413.03,525.82z"/>
        </g>
        <g>
          <path className="st3" d="M417.83,610.56c-1.94-0.49-3.76-1.38-5.44-2.45c-1.64-1.12-3.21-2.34-4.62-3.72
			c-2.86-2.72-5.24-5.85-7.41-9.08c-4.31-6.5-7.67-13.52-10.73-20.61c-3.07-7.1-5.67-14.37-8.2-21.65l-7.42-21.89
			c-4.93-14.57-9.81-29.18-16.05-43.19c-3.16-6.97-6.59-13.84-10.86-20.13c-2.13-3.14-4.53-6.12-7.3-8.69
			c-1.37-1.31-2.86-2.46-4.43-3.5c-0.8-0.5-1.6-1-2.45-1.4c-0.83-0.44-1.69-0.82-2.61-1.03c-0.37-0.08-0.6-0.45-0.52-0.82
			c0.08-0.37,0.45-0.6,0.82-0.52c0.03,0.01,0.06,0.01,0.08,0.02l0.07,0.02c7.5,2.73,13.49,8.43,17.86,14.84
			c4.48,6.39,7.92,13.38,11.1,20.42c6.25,14.15,11.09,28.81,15.99,43.41c4.83,14.61,9.76,29.21,15.71,43.35
			c3,7.05,6.3,13.99,10.48,20.36c2.1,3.16,4.4,6.21,7.12,8.81c1.34,1.32,2.82,2.48,4.35,3.53c1.57,1,3.22,1.82,4.91,2.62l0.03,0.02
			c0.34,0.16,0.49,0.57,0.32,0.92C418.48,610.49,418.15,610.64,417.83,610.56z"/>
        </g>
        <g>
          <path className="st3" d="M655.75,295.02c-0.47,0.13-0.93,0.23-1.4,0.27c-0.46,0.06-0.93,0.07-1.38,0.2c-0.91,0.2-1.84,0.36-2.72,0.71
			c-0.44,0.16-0.89,0.3-1.33,0.47l-1.29,0.6c-0.87,0.37-1.68,0.88-2.51,1.36c-3.27,1.98-6.22,4.51-8.99,7.22
			c-5.52,5.45-10.59,11.45-15.34,17.68c-4.82,6.19-9.52,12.51-14.78,18.44c-2.62,2.96-5.45,5.8-8.68,8.2
			c-0.78,0.63-1.67,1.13-2.5,1.7c-0.42,0.28-0.89,0.49-1.33,0.74c-0.46,0.22-0.87,0.51-1.36,0.68c-0.95,0.38-1.87,0.8-2.89,1.02
			c-0.5,0.13-0.98,0.29-1.5,0.32c-0.51,0.08-1.03,0.05-1.53,0.28c-0.37,0.17-0.81,0.01-0.97-0.36s-0.01-0.81,0.36-0.97
			c0.16-0.07,0.32-0.08,0.48-0.05l0.07,0.02c0.47,0.11,0.93-0.07,1.39-0.14c0.46-0.05,0.9-0.24,1.34-0.4
			c0.9-0.27,1.73-0.72,2.57-1.13c1.63-0.94,3.22-1.97,4.73-3.16c3.01-2.37,5.79-5.11,8.38-8.01c5.2-5.81,9.93-12.09,14.8-18.26
			c2.43-3.09,4.9-6.16,7.41-9.2c2.54-3.02,5.12-6.02,8-8.77c2.86-2.75,5.93-5.34,9.4-7.41c0.88-0.5,1.74-1.03,2.68-1.42l1.39-0.63
			c0.48-0.18,0.97-0.33,1.45-0.5c0.95-0.37,1.97-0.54,2.98-0.75c0.5-0.13,1.02-0.16,1.53-0.17c0.52-0.03,1.03,0,1.54,0.06
			c0.38,0.04,0.65,0.39,0.6,0.76c-0.03,0.29-0.24,0.51-0.5,0.58L655.75,295.02z"/>
        </g>
        <g>
          <path className="st3" d="M656.66,461.85c-3.01,0.22-5.98-0.34-8.92-0.85c-2.93-0.6-5.82-1.36-8.64-2.29
			c-5.66-1.84-11.02-4.36-16.5-6.48c-5.46-2.18-10.93-4.27-16.54-5.88l-2.1-0.6l-2.12-0.55c-1.41-0.36-2.81-0.77-4.24-1.06
			c-2.84-0.66-5.71-1.28-8.63-1.49l-0.31-0.02c-0.4-0.03-0.7-0.38-0.68-0.78s0.38-0.7,0.78-0.68c0.12,0.01,0.24,0.05,0.34,0.11
			c0.35,0.22,0.73,0.14,1.1,0.19l1.11,0.06l2.23,0.11c1.49,0.07,2.94,0.42,4.41,0.67c1.47,0.22,2.9,0.65,4.35,1
			c0.72,0.19,1.44,0.35,2.16,0.56l2.14,0.63c5.7,1.68,11.23,3.82,16.68,6.07c5.43,2.29,10.9,4.45,16.44,6.3
			c2.76,0.95,5.57,1.72,8.4,2.33c1.41,0.33,2.86,0.48,4.28,0.73c0.72,0.11,1.44,0.13,2.16,0.2c0.72,0.06,1.44,0.1,2.16,0.36
			l0.14,0.05c0.35,0.13,0.54,0.52,0.41,0.87C657.16,461.66,656.92,461.83,656.66,461.85z"/>
        </g>
        <g>
          <path className="st3" d="M653.42,187.33c-0.93,0.35-1.86,0.36-2.75,0.69c-0.45,0.15-0.92,0.22-1.35,0.44l-1.31,0.58
			c-0.44,0.17-0.84,0.47-1.26,0.7c-0.41,0.26-0.85,0.46-1.23,0.76c-0.78,0.58-1.6,1.1-2.33,1.76c-3.01,2.5-5.67,5.47-8.19,8.54
			c-5.02,6.18-9.7,12.7-14.33,19.28c-4.68,6.54-9.45,13.08-15.12,18.92c-2.82,2.92-5.94,5.63-9.44,7.77
			c-0.85,0.57-1.78,1.02-2.7,1.49c-0.89,0.51-1.88,0.84-2.83,1.24c-0.47,0.21-0.96,0.36-1.46,0.5l-1.48,0.43
			c-0.49,0.16-1.01,0.2-1.52,0.3c-0.51,0.11-1.02,0.11-1.51,0.37c-0.35,0.19-0.78,0.06-0.97-0.29s-0.06-0.78,0.29-0.97
			c0.09-0.05,0.18-0.07,0.27-0.08l0.22-0.02c1.95-0.17,3.79-0.95,5.56-1.72c0.87-0.44,1.76-0.83,2.59-1.34
			c0.85-0.47,1.72-0.92,2.52-1.49c3.28-2.14,6.31-4.71,9.07-7.56c5.56-5.68,10.33-12.14,15.04-18.62
			c4.75-6.46,9.14-13.22,14.29-19.47c2.59-3.11,5.34-6.13,8.53-8.73c0.77-0.68,1.65-1.24,2.49-1.85c0.41-0.31,0.89-0.54,1.34-0.81
			c0.46-0.25,0.88-0.55,1.38-0.75c0.96-0.42,1.92-0.87,2.96-1.11c1-0.36,2.1-0.41,3.16-0.37c0.38,0.02,0.67,0.33,0.65,0.71
			c-0.01,0.28-0.19,0.51-0.44,0.61L653.42,187.33z"/>
        </g>
        <g>
          <path className="st3" d="M653.39,240.29c-0.72,0.22-1.44,0.23-2.16,0.26c-0.72,0.03-1.44,0.04-2.15,0.1c-1.43,0.15-2.87,0.24-4.3,0.44
			c-2.86,0.38-5.7,0.88-8.53,1.5c-5.66,1.21-11.33,2.52-16.97,4.01c-5.66,1.43-11.38,2.72-17.19,3.6c-1.45,0.22-2.9,0.47-4.37,0.55
			c-1.47,0.12-2.92,0.29-4.4,0.29c-1.47,0.02-2.94,0.11-4.4,0.07l-2.2-0.04l-1.1-0.02c-0.37,0.02-0.73-0.08-1.1,0.09
			c-0.38,0.18-0.83,0.01-1.01-0.37s-0.01-0.83,0.37-1.01c0.21-0.1,0.45-0.09,0.65,0.01c0.36,0.17,0.72,0.04,1.09,0.03l1.08-0.1
			l2.16-0.2l2.16-0.19l2.16-0.14c1.44-0.08,2.88-0.23,4.32-0.36c1.44-0.11,2.87-0.32,4.3-0.53c5.73-0.82,11.4-2.06,17.05-3.43
			c5.67-1.31,11.26-2.99,17.01-4.17c2.87-0.59,5.78-1.06,8.7-1.42c2.93-0.26,5.87-0.68,8.83-0.34c0.38,0.04,0.64,0.38,0.6,0.76
			c-0.03,0.28-0.23,0.5-0.48,0.58L653.39,240.29z"/>
        </g>
        <g>
          <path className="st3" d="M658.05,520.11c-1.48,0.32-2.93,0.04-4.4,0.17c-1.47,0.05-2.93,0.09-4.4,0.21c-1.47,0.09-2.94,0.22-4.4,0.38
			c-1.47,0.13-2.93,0.31-4.4,0.49c-5.86,0.71-11.75,1.39-17.6,2.37c-5.86,0.92-11.73,1.85-17.62,2.66c-2.94,0.43-5.9,0.74-8.87,0.96
			l-4.46,0.28l-2.23,0.1l-1.12,0.05c-0.37,0.04-0.75-0.03-1.11,0.16l-0.01,0c-0.37,0.2-0.83,0.06-1.02-0.31
			c-0.2-0.37-0.06-0.83,0.31-1.02c0.2-0.11,0.43-0.11,0.63-0.04c0.38,0.15,0.74-0.01,1.11-0.04l1.1-0.17l2.19-0.34
			c1.46-0.25,2.93-0.39,4.4-0.54l4.41-0.48c1.47-0.15,2.94-0.36,4.41-0.54c5.87-0.77,11.73-1.66,17.61-2.52
			c5.88-0.81,11.73-1.85,17.65-2.52c1.48-0.16,2.96-0.33,4.45-0.45c1.48-0.14,2.97-0.26,4.47-0.33c1.49-0.11,2.99-0.14,4.49-0.17
			c0.75-0.03,1.5-0.02,2.25,0.01c0.75,0.02,1.51,0.02,2.25,0.24c0.37,0.11,0.59,0.5,0.48,0.88c-0.08,0.26-0.28,0.44-0.53,0.49
			L658.05,520.11z"/>
        </g>
        <g>
          <path className="st3" d="M656.68,574.83c-0.47,0.06-0.94,0.1-1.41,0.07c-0.24-0.01-0.47-0.01-0.71-0.03l-0.69-0.12l-1.39-0.26
			c-0.45-0.12-0.9-0.27-1.34-0.4c-1.8-0.54-3.46-1.37-5.08-2.25c-3.19-1.85-6.04-4.14-8.75-6.54c-5.39-4.85-10.02-10.34-15.03-15.43
			c-4.96-5.12-10.01-10.18-15.72-14.32c-2.87-2.03-5.86-3.88-9.05-5.29c-0.8-0.35-1.62-0.64-2.43-0.96
			c-0.83-0.28-1.67-0.49-2.51-0.75c-0.85-0.19-1.71-0.33-2.57-0.5l-1.3-0.11c-0.44-0.01-0.87-0.14-1.31,0l-0.07,0.02
			c-0.39,0.12-0.81-0.1-0.94-0.49c-0.12-0.39,0.1-0.81,0.49-0.94c0.18-0.06,0.37-0.04,0.53,0.03c0.46,0.2,0.91,0.11,1.37,0.13
			l1.38,0.01c0.91,0.09,1.83,0.17,2.75,0.3c0.9,0.2,1.82,0.33,2.7,0.6c0.87,0.31,1.75,0.59,2.6,0.93c3.42,1.38,6.53,3.35,9.48,5.45
			c5.87,4.3,10.95,9.45,15.89,14.64c4.9,5.23,9.8,10.46,15.03,15.24c2.62,2.38,5.37,4.61,8.37,6.39c1.52,0.84,3.07,1.64,4.7,2.14
			c0.41,0.12,0.81,0.27,1.22,0.38l1.25,0.25l0.63,0.12c0.21,0.01,0.42,0.04,0.63,0.06c0.42,0.04,0.84,0.13,1.27,0.26l0.1,0.03
			c0.36,0.11,0.57,0.49,0.47,0.85C657.17,574.62,656.94,574.8,656.68,574.83z"/>
        </g>
        <g>
          <path className="st3" d="M660.83,633.97c-0.81,0.09-1.61-0.03-2.41-0.14l-1.2-0.16c-0.4-0.06-0.78-0.16-1.18-0.24l-2.35-0.49
			c-0.78-0.16-1.54-0.4-2.31-0.6c-0.77-0.21-1.54-0.41-2.3-0.64l-2.27-0.72c-1.52-0.46-3.01-1-4.51-1.51
			c-5.97-2.1-11.75-4.6-17.72-6.56c-5.94-2.02-11.95-3.8-18.07-5c-1.53-0.31-3.06-0.59-4.6-0.81l-2.31-0.36l-1.15-0.18l-1.16-0.12
			c-1.55-0.14-3.1-0.38-4.66-0.45l-2.34-0.07l-1.17-0.03c-0.39,0.01-0.78-0.09-1.17,0.08L587.9,616c-0.39,0.17-0.83-0.01-1-0.4
			c-0.17-0.39,0.01-0.83,0.4-1c0.2-0.09,0.42-0.08,0.6,0c0.4,0.18,0.8,0.05,1.19,0.05l1.19-0.08l2.39-0.17
			c1.6-0.09,3.19,0.1,4.78,0.19c1.6,0.05,3.16,0.4,4.74,0.6c1.58,0.21,3.14,0.53,4.7,0.84c6.23,1.26,12.32,3.1,18.28,5.19
			l17.78,6.43c1.48,0.52,2.95,1.07,4.44,1.54l2.23,0.73c0.75,0.23,1.5,0.43,2.25,0.65c0.75,0.2,1.5,0.45,2.25,0.61l2.27,0.5
			c1.51,0.44,3.06,0.33,4.56,0.92l0.05,0.02c0.36,0.14,0.54,0.54,0.4,0.9C661.31,633.78,661.08,633.94,660.83,633.97z"/>
        </g>
        <g>
          <path className="st3" d="M659.49,686.94c-1.16,0.18-2.35-0.02-3.46-0.34c-1.11-0.33-2.16-0.81-3.16-1.34
			c-1.98-1.11-3.76-2.49-5.4-3.99c-3.26-3.01-6.04-6.42-8.68-9.9c-2.65-3.47-5.02-7.13-7.41-10.76c-2.35-3.65-4.74-7.27-7.13-10.88
			c-4.81-7.19-9.67-14.37-15.38-20.78c-2.88-3.17-5.91-6.2-9.34-8.67c-1.72-1.23-3.54-2.31-5.49-3.08c-0.98-0.37-1.97-0.72-3-0.92
			c-0.51-0.13-1.03-0.19-1.54-0.27c-0.52-0.06-1.04-0.15-1.57-0.02l-0.08,0.02c-0.39,0.1-0.79-0.14-0.88-0.53
			c-0.1-0.39,0.14-0.79,0.53-0.88c0.15-0.04,0.3-0.02,0.44,0.03c0.56,0.21,1.12,0.08,1.68,0.15c0.56,0.04,1.12,0.07,1.68,0.17
			c1.12,0.14,2.21,0.44,3.29,0.78c2.16,0.67,4.14,1.78,5.98,3.03c3.7,2.51,6.81,5.68,9.74,8.93c5.81,6.57,10.66,13.83,15.44,21.07
			c4.72,7.28,9.47,14.55,14.59,21.47c2.57,3.45,5.28,6.81,8.37,9.71c1.55,1.45,3.22,2.75,5.01,3.77c0.91,0.49,1.84,0.92,2.81,1.22
			c0.97,0.28,1.94,0.44,2.95,0.66l0.04,0.01c0.37,0.08,0.61,0.45,0.53,0.83C660,686.69,659.76,686.89,659.49,686.94z"/>
        </g>
        <g>
          <path className="st3" d="M655.62,407.11c-1.92-0.07-3.73-0.7-5.55-1.23c-0.91-0.28-1.78-0.66-2.68-0.99c-0.89-0.34-1.73-0.78-2.6-1.17
			c-3.41-1.68-6.57-3.86-9.44-6.32c-5.75-4.97-10.17-11.1-14.83-16.85c-4.61-5.8-9.31-11.55-15-16.12
			c-2.85-2.27-5.89-4.29-9.13-5.94c-0.82-0.4-1.62-0.84-2.47-1.16c-0.85-0.34-1.67-0.75-2.55-1.01c-1.73-0.59-3.47-1.19-5.3-1.42
			l-0.22-0.03c-0.39-0.05-0.67-0.41-0.62-0.81c0.05-0.39,0.41-0.67,0.81-0.62c0.11,0.01,0.22,0.06,0.31,0.11
			c0.42,0.28,0.91,0.26,1.37,0.37l1.4,0.24c0.93,0.16,1.84,0.43,2.76,0.66c1.84,0.48,3.62,1.16,5.33,1.99
			c3.44,1.61,6.61,3.76,9.55,6.12c5.88,4.78,10.62,10.67,15.2,16.51c4.54,5.88,9.19,11.68,14.7,16.5c2.75,2.4,5.75,4.51,8.99,6.14
			c0.83,0.38,1.63,0.81,2.47,1.14c0.85,0.32,1.68,0.69,2.55,0.96c0.87,0.26,1.73,0.55,2.61,0.79c0.89,0.2,1.77,0.39,2.63,0.79
			l0.06,0.03c0.35,0.16,0.49,0.57,0.33,0.92C656.15,406.97,655.89,407.12,655.62,407.11z"/>
        </g>
        <path className="st8" d="M586.2,354.21c11.15,0,48.55-8.68,67.66-8.68"/>
        <path className="st3" d="M310.62,447.37c-2.43-0.49-4.55-0.38-6.02,1.55c-1.46,1.93-1.63,4.69-0.41,6.78c0.35,0.6,0.83,1.16,1.45,1.5
		c0.71,0.38,1.56,0.46,2.37,0.4c2.57-0.17,5.11-1.74,5.89-4.11C314.67,451.13,313.14,447.88,310.62,447.37z"/>
        <path className="st3" d="M569.16,437.57c-2.43-0.49-4.55-0.38-6.02,1.55c-1.46,1.93-1.63,4.69-0.41,6.78c0.35,0.6,0.83,1.16,1.45,1.5
		c0.71,0.38,1.56,0.46,2.37,0.4c2.57-0.17,5.11-1.74,5.89-4.11C573.22,441.32,571.68,438.08,569.16,437.57z"/>
        <path className="st3" d="M568.31,357.22c1.1-2.22,1.55-4.3,0.06-6.22c-1.48-1.92-4.11-2.8-6.44-2.16c-0.67,0.19-1.34,0.5-1.83,1.02
		c-0.55,0.59-0.85,1.38-1,2.18c-0.5,2.52,0.36,5.39,2.44,6.75C563.63,360.16,567.16,359.52,568.31,357.22z"/>
        <path className="st3" d="M563.93,245.79c-2.43-0.49-4.55-0.38-6.02,1.55s-1.63,4.69-0.41,6.78c0.35,0.6,0.83,1.16,1.45,1.5
		c0.71,0.38,1.56,0.46,2.37,0.4c2.57-0.17,5.11-1.74,5.89-4.11C567.98,249.54,566.45,246.3,563.93,245.79z"/>
        <path className="st3" d="M566.5,523.02c-2.43-0.49-4.55-0.38-6.02,1.55c-1.46,1.93-1.63,4.69-0.41,6.78c0.35,0.6,0.83,1.16,1.45,1.5
		c0.71,0.38,1.56,0.46,2.37,0.4c2.57-0.17,5.11-1.74,5.89-4.11C570.55,526.78,569.01,523.53,566.5,523.02z"/>
        <path className="st3" d="M561.96,616.21c0.4,2.45,1.26,4.39,3.59,5.08s4.97-0.14,6.48-2.02c0.44-0.54,0.79-1.18,0.89-1.89
		c0.11-0.8-0.12-1.62-0.46-2.36c-1.07-2.34-3.44-4.16-5.93-4.05S561.55,613.67,561.96,616.21z"/>
        <path className="st9" d="M442.36,203.04c31.33-2.2,96.39,1.84,121.18-0.38c13.71-1.23,28.15-3.38,36.69-11.93s12.84-13.76,17.43-19.26
		c5.77-6.92,19.19-27.69,39.37-29.52c28.51-2.59,130.26-2.04,160.17-0.62c29.91,1.42,42.81,17.58,45.66,43.22
		c2.85,25.64,4.27,448.72,1.42,494.3c-2.85,45.58-11.4,48.43-47.01,54.13c-35.61,5.7-128.21-1.42-153.85-1.42
		s-56.98-59.83-72.65-66.95c-15.67-7.12-122.51-4.27-143.87-7.12s-52.71-12.82-69.8-42.74c-17.09-29.91-29.91-81.2-37.04-92.59
		s-25.64-24.22-45.58-27.07s-64.1-1.42-95.44-1.42s-71.23-1.42-74.07-31.34c-2.85-29.91,19.94-47.21,54.13-48.43
		c39.89-1.42,79.77-2.85,101.14-1.42s39.89-1.42,48.43-28.49c8.55-27.07,19.62-68.06,31.34-111.11
		C368.41,242.03,404.04,205.73,442.36,203.04z"/>
        <g className="st0">
          <g className="st10">
            <path className="st3" d="M446.58,247.99c0.75-0.64,1.5-0.57,2.26-0.62l2.26-0.11l4.52-0.22c3.02-0.17,6.04-0.16,9.06-0.25
				c3.02-0.12,6.04-0.01,9.07,0.14c3.02,0.11,6.05,0,9.07-0.03c6.05-0.1,12.1-0.34,18.15-0.24c3.03-0.02,6.05,0.09,9.07,0.54
				l4.53,0.58l2.26,0.3c0.38,0.05,0.75,0.07,1.13,0.11l0.57,0.05c0.19,0.02,0.38-0.01,0.57-0.27l-0.09,4.11
				c-0.18-0.29-0.37-0.31-0.56-0.29l-0.57,0.08c-0.38,0.05-0.76,0.13-1.14,0.21c-0.76,0.18-1.51,0.34-2.27,0.49
				c-1.51,0.3-3.02,0.25-4.52,0.26c-1.51-0.04-3.01-0.19-4.52-0.24c-1.51-0.08-3.01-0.09-4.52-0.13
				c-6.03-0.15-12.06-0.24-18.09-0.24c-3.02,0-6.03-0.06-9.05,0.09c-3.02,0.19-6.03,0.36-9.05,0.36c-3.02,0.02-6.04,0.13-9.06,0.07
				l-4.53-0.06l-2.27-0.03c-0.76-0.02-1.51,0.07-2.27-0.57L446.58,247.99z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M690.19,182.15c0.99-0.64,1.98-0.57,2.97-0.62l2.97-0.11l5.95-0.22c3.96-0.17,7.93-0.16,11.9-0.25
				c3.97-0.12,7.94-0.01,11.91,0.14c3.97,0.12,7.94-0.02,11.91-0.06l11.91-0.15c3.97-0.06,7.94-0.1,11.92-0.05
				c3.97-0.02,7.94,0.09,11.91,0.54l5.95,0.58l2.97,0.3c0.5,0.05,0.99,0.07,1.49,0.11l0.74,0.05c0.25,0.02,0.5-0.01,0.75-0.27
				l-0.07,4.11c-0.24-0.29-0.49-0.31-0.74-0.28l-0.74,0.09c-0.5,0.07-0.99,0.11-1.49,0.19c-0.99,0.18-1.99,0.34-2.98,0.49
				c-1.99,0.3-3.97,0.25-5.95,0.26c-1.98-0.04-3.96-0.19-5.94-0.24c-1.98-0.08-3.96-0.09-5.94-0.13
				c-7.92-0.15-15.85-0.24-23.78-0.24c-3.96,0-7.93-0.06-11.89,0.09c-3.96,0.19-7.93,0.36-11.89,0.36
				c-3.97,0.02-7.93,0.13-11.9,0.07l-5.95-0.06l-2.98-0.03c-0.99-0.02-1.98,0.07-2.98-0.57L690.19,182.15z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M443.41,610.06c1.04-0.64,2.08-0.57,3.13-0.62l3.13-0.11l6.26-0.22c4.17-0.17,8.35-0.16,12.52-0.25
				c4.18-0.12,8.35-0.01,12.53,0.14c4.18,0.12,8.36-0.02,12.54-0.06l12.54-0.15c4.18-0.06,8.36-0.1,12.54-0.05
				c4.18-0.02,8.36,0.09,12.54,0.54l6.26,0.58l3.13,0.3l1.57,0.11l0.78,0.05c0.26,0.02,0.52-0.01,0.79-0.27l-0.07,4.11
				c-0.26-0.29-0.52-0.31-0.78-0.28l-0.78,0.09c-0.52,0.07-1.04,0.11-1.57,0.19c-1.05,0.18-2.09,0.34-3.14,0.49
				c-2.09,0.3-4.17,0.25-6.26,0.26c-2.08-0.04-4.17-0.19-6.25-0.24c-2.08-0.08-4.17-0.09-6.26-0.13
				c-8.34-0.15-16.69-0.24-25.03-0.24c-4.17,0-8.35-0.06-12.52,0.09c-4.17,0.19-8.35,0.36-12.52,0.36
				c-4.17,0.02-8.35,0.13-12.53,0.07l-6.27-0.06l-3.13-0.03c-1.04-0.02-2.09,0.07-3.14-0.57L443.41,610.06z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M687.02,342.31c1.04-0.64,2.08-0.57,3.13-0.62l3.13-0.11l6.26-0.22c4.17-0.17,8.35-0.16,12.52-0.25
				c4.18-0.12,8.35-0.01,12.53,0.14c4.18,0.12,8.36-0.02,12.54-0.06l12.54-0.15c4.18-0.06,8.36-0.1,12.54-0.05
				c4.18-0.02,8.36,0.09,12.54,0.54l6.26,0.58l3.13,0.3l1.57,0.11l0.78,0.05c0.26,0.02,0.52-0.01,0.79-0.27l-0.07,4.11
				c-0.26-0.29-0.52-0.31-0.78-0.28l-0.78,0.09c-0.52,0.07-1.04,0.11-1.57,0.19c-1.05,0.18-2.09,0.34-3.14,0.49
				c-2.09,0.3-4.17,0.25-6.26,0.26c-2.08-0.04-4.17-0.19-6.25-0.24c-2.08-0.08-4.17-0.09-6.26-0.13
				c-8.34-0.15-16.69-0.24-25.03-0.24c-4.17,0-8.35-0.06-12.52,0.09c-4.17,0.19-8.35,0.36-12.52,0.36
				c-4.17,0.02-8.35,0.13-12.53,0.07l-6.27-0.06l-3.13-0.03c-1.04-0.02-2.09,0.07-3.14-0.57L687.02,342.31z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M436.38,350.87c0.51-0.65,1.01-0.91,1.52-1.04c0.51-0.11,1.02-0.06,1.53-0.08l3.06-0.04l3.06-0.02l3.05,0.08
				c4.07,0.08,8.14,0.32,12.21,0.32c4.07,0.14,8.14-0.07,12.2-0.32c4.07-0.21,8.13-0.27,12.2-0.37c4.06-0.08,8.13,0.06,12.19,0.17
				l12.18,0.29l12.18,0.37c4.06,0.14,8.11,0.03,12.18,0.61l0.11,3.43c-4.06,0.87-8.14,0.86-12.22,1.07l-12.23,0.44
				c-8.15,0.11-16.29,0.32-24.43,0.17c-4.07-0.12-8.14-0.22-12.2-0.47c-4.07-0.29-8.13-0.52-12.2-0.42
				c-4.07-0.02-8.13,0.19-12.2,0.27l-3.05,0.08l-3.04-0.01l-3.04-0.01c-0.51-0.02-1.01,0.05-1.52-0.06
				c-0.51-0.13-1.01-0.39-1.53-1.04L436.38,350.87z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M689.87,516.57c0.51-0.65,1.01-0.91,1.52-1.04c0.51-0.11,1.02-0.06,1.53-0.08l3.06-0.04l3.06-0.02l3.05,0.08
				c4.07,0.08,8.14,0.32,12.21,0.32c4.07,0.14,8.14-0.07,12.2-0.32c4.07-0.21,8.13-0.27,12.2-0.37c4.06-0.08,8.13,0.06,12.19,0.17
				l12.18,0.29l12.18,0.37c4.06,0.14,8.11,0.03,12.18,0.61l0.11,3.43c-4.06,0.87-8.14,0.86-12.22,1.07l-12.23,0.44
				c-8.15,0.11-16.29,0.32-24.43,0.17c-4.07-0.12-8.14-0.22-12.2-0.47c-4.07-0.29-8.13-0.52-12.2-0.42
				c-4.07-0.02-8.13,0.19-12.2,0.27l-3.05,0.08l-3.04-0.01l-3.04-0.01c-0.51-0.02-1.01,0.05-1.52-0.06
				c-0.51-0.13-1.01-0.39-1.53-1.04L689.87,516.57z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M185.09,450.73c0.51-0.65,1.01-0.91,1.52-1.04c0.51-0.11,1.02-0.06,1.53-0.08l3.06-0.04l3.06-0.02l3.05,0.08
				c4.07,0.08,8.14,0.32,12.21,0.32c4.07,0.14,8.14-0.07,12.2-0.32c4.07-0.21,8.13-0.27,12.2-0.37c4.06-0.08,8.13,0.06,12.19,0.17
				l12.18,0.29l12.18,0.37c4.06,0.14,8.11,0.03,12.18,0.61l0.11,3.43c-4.06,0.87-8.14,0.86-12.22,1.07l-12.23,0.44
				c-8.15,0.11-16.29,0.32-24.43,0.17c-4.07-0.12-8.14-0.22-12.2-0.47c-4.07-0.29-8.13-0.52-12.2-0.42
				c-4.07-0.02-8.13,0.19-12.2,0.27l-3.05,0.08l-3.04-0.01l-3.04-0.01c-0.51-0.02-1.01,0.05-1.52-0.06
				c-0.51-0.13-1.01-0.39-1.53-1.04L185.09,450.73z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M689.87,682.27c0.51-0.65,1.01-0.91,1.52-1.04c0.51-0.11,1.02-0.06,1.53-0.08l3.06-0.04l3.06-0.02l3.05,0.08
				c4.07,0.08,8.14,0.32,12.21,0.32c4.07,0.14,8.14-0.07,12.2-0.32c4.07-0.21,8.13-0.27,12.2-0.37c4.06-0.08,8.13,0.06,12.19,0.17
				l12.18,0.29l12.18,0.37c4.06,0.14,8.11,0.03,12.18,0.61l0.11,3.43c-4.06,0.87-8.14,0.86-12.22,1.07l-12.23,0.44
				c-8.15,0.11-16.29,0.32-24.43,0.17c-4.07-0.12-8.14-0.22-12.2-0.47c-4.07-0.29-8.13-0.52-12.2-0.42
				c-4.07-0.02-8.13,0.19-12.2,0.27l-3.05,0.08l-3.04-0.01l-3.04-0.01c-0.51-0.02-1.01,0.05-1.52-0.06
				c-0.51-0.13-1.01-0.39-1.53-1.04L689.87,682.27z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M529.49,443.14c-0.46,0.64-0.93,0.89-1.39,1.02c-0.46,0.11-0.92,0.04-1.38,0.06l-2.75-0.01l-2.75-0.02
				l-2.74-0.12c-3.65-0.13-7.3-0.43-10.95-0.49c-3.65-0.19-7.3-0.04-10.96,0.16c-3.65,0.15-7.31,0.16-10.95,0.2
				c-3.65,0.02-7.29-0.18-10.94-0.34l-10.93-0.47l-10.92-0.56c-3.64-0.21-7.27-0.17-10.92-0.82l-0.05-3.43
				c3.65-0.79,7.33-0.72,10.99-0.86l10.98-0.25c7.32,0.01,14.63-0.08,21.94,0.18c3.65,0.18,7.3,0.34,10.95,0.64
				c3.65,0.34,7.29,0.63,10.94,0.58c3.65,0.08,7.3-0.08,10.95-0.1c1.82-0.03,3.65-0.05,5.47-0.01l2.73,0.07
				c0.45,0.03,0.91-0.02,1.36,0.1c0.45,0.14,0.9,0.4,1.35,1.06L529.49,443.14z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M516.05,527.9c-0.42,0.64-0.83,0.91-1.25,1.04c-0.41,0.11-0.82,0.06-1.24,0.09l-2.48,0.08
				c-1.65,0.04-3.31,0.05-4.98,0.05c-3.32,0.03-6.67-0.05-10.01,0.1c-3.35,0.02-6.69,0.38-10.03,0.77
				c-3.35,0.34-6.71,0.51-10.07,0.68c-3.37,0.15-6.74,0.04-10.11-0.09c-3.37-0.14-6.75-0.3-10.12-0.55
				c-1.69-0.13-3.37-0.29-5.05-0.48c-1.68-0.19-3.36-0.34-5.04-0.58c-3.35-0.44-6.72-0.78-9.99-1.84l0.41-3.4
				c1.7-0.22,3.35-0.16,5.01-0.09l4.96,0.25c3.31,0.14,6.62,0.22,9.94,0.23c6.62,0.18,13.26-0.01,19.91-0.01
				c3.33,0.05,6.65,0.04,9.99,0.16c3.34,0.15,6.68,0.23,10.02-0.03c3.34-0.13,6.68-0.51,10.04-0.69l2.52-0.16l2.53-0.07
				c0.84-0.02,1.69-0.01,2.54,0.01c0.42,0.02,0.85-0.03,1.27,0.08c0.42,0.13,0.85,0.39,1.26,1.04L516.05,527.9z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M783.16,576.53c-0.53,0.64-1.06,0.9-1.58,1.03c-0.52,0.11-1.05,0.05-1.57,0.08c-1.05,0-2.1,0.02-3.15,0.05
				c-2.1,0.06-4.21,0.07-6.32,0.05c-4.22,0.02-8.45-0.08-12.68,0.06c-4.23,0-8.46,0.35-12.7,0.73c-4.24,0.33-8.48,0.48-12.73,0.64
				c-4.25,0.13-8.5,0.01-12.76-0.13c-4.25-0.15-8.51-0.32-12.76-0.59c-2.13-0.14-4.25-0.3-6.37-0.5c-2.12-0.2-4.25-0.35-6.36-0.6
				c-4.24-0.45-8.49-0.81-12.66-1.89l0.34-3.41c2.14-0.21,4.24-0.14,6.34-0.07l6.3,0.27c4.2,0.15,8.41,0.25,12.62,0.28
				c8.41,0.21,16.83,0.05,25.26,0.08c4.22,0.06,8.44,0.07,12.66,0.2c4.23,0.16,8.46,0.26,12.69,0.01c4.23-0.11,8.46-0.48,12.7-0.65
				l3.18-0.15l3.19-0.03c1.06-0.01,2.13-0.01,3.2,0c0.53,0.02,1.07-0.04,1.6,0.07c0.53,0.13,1.06,0.39,1.59,1.05L783.16,576.53z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M777.48,410.22c-0.46,0.64-0.93,0.89-1.39,1.02c-0.46,0.11-0.92,0.04-1.38,0.06l-2.75-0.01l-2.75-0.02
				l-2.74-0.12c-3.65-0.13-7.3-0.43-10.95-0.49c-3.65-0.19-7.3-0.04-10.96,0.16c-3.66,0.15-7.31,0.16-10.95,0.2
				c-3.65,0.02-7.29-0.18-10.94-0.34l-10.93-0.47l-10.92-0.56c-3.64-0.21-7.27-0.17-10.92-0.82l-0.05-3.43
				c3.65-0.79,7.33-0.72,10.99-0.86l10.98-0.25c7.32,0.01,14.63-0.08,21.94,0.18c3.65,0.18,7.3,0.34,10.95,0.64
				c3.65,0.34,7.29,0.63,10.94,0.58c3.65,0.08,7.3-0.08,10.95-0.1c1.82-0.03,3.65-0.05,5.47-0.01l2.73,0.07
				c0.45,0.03,0.91-0.02,1.36,0.1c0.45,0.14,0.9,0.4,1.35,1.06L777.48,410.22z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M754.62,239.72c-0.33,0.64-0.66,0.9-0.99,1.03c-0.33,0.11-0.66,0.05-0.99,0.08
				c-0.66,0.01-1.31,0.03-1.97,0.02c-1.31,0-2.62-0.06-3.92-0.15c-2.61-0.12-5.22-0.4-7.82-0.44c-2.6-0.18-5.22-0.01-7.83,0.21
				c-2.61,0.17-5.22,0.19-7.82,0.25c-2.6,0.04-5.2-0.14-7.8-0.29l-7.79-0.42l-7.78-0.51c-2.59-0.19-5.18-0.13-7.78-0.77l-0.09-3.43
				c2.61-0.81,5.24-0.75,7.86-0.91c2.62-0.12,5.24-0.22,7.86-0.3c5.23-0.03,10.46-0.15,15.68,0.08c2.61,0.16,5.21,0.3,7.82,0.59
				c2.6,0.32,5.2,0.6,7.81,0.53c2.6,0.06,5.22-0.11,7.82-0.15c1.3-0.04,2.6-0.06,3.9-0.04l1.94,0.06c0.32,0.03,0.65-0.02,0.97,0.09
				c0.32,0.13,0.64,0.4,0.96,1.05L754.62,239.72z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M767.34,464.26c-0.42,0.64-0.83,0.91-1.25,1.04c-0.41,0.11-0.82,0.06-1.24,0.09l-2.48,0.08
				c-1.65,0.04-3.31,0.05-4.98,0.05c-3.32,0.03-6.67-0.05-10.01,0.1c-3.35,0.02-6.69,0.38-10.03,0.77
				c-3.35,0.34-6.71,0.51-10.07,0.68c-3.37,0.15-6.74,0.04-10.11-0.09c-3.37-0.14-6.75-0.3-10.12-0.55
				c-1.69-0.13-3.37-0.29-5.05-0.48c-1.68-0.19-3.36-0.34-5.04-0.58c-3.35-0.44-6.72-0.78-9.99-1.84l0.41-3.4
				c1.7-0.22,3.35-0.16,5.01-0.09l4.96,0.25c3.31,0.14,6.62,0.22,9.94,0.23c6.62,0.18,13.26-0.01,19.91-0.01
				c3.33,0.05,6.65,0.04,9.99,0.16c3.34,0.15,6.68,0.23,10.02-0.03c3.34-0.13,6.68-0.51,10.04-0.69l2.52-0.16l2.53-0.07
				c0.84-0.02,1.69-0.01,2.54,0.01c0.42,0.02,0.85-0.03,1.27,0.08c0.42,0.13,0.85,0.39,1.26,1.04L767.34,464.26z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M692.65,292.7c0.42-0.65,0.83-0.91,1.24-1.05c0.41-0.11,0.82-0.07,1.23-0.1l2.48-0.1
				c1.65-0.05,3.31-0.07,4.98-0.08c3.32-0.06,6.67,0,10.01-0.17c3.35-0.04,6.68-0.43,10.03-0.84c3.35-0.36,6.7-0.56,10.07-0.75
				c3.37-0.17,6.74-0.09,10.11,0.02c3.37,0.12,6.75,0.25,10.12,0.48c1.69,0.12,3.37,0.27,5.05,0.45c1.68,0.18,3.37,0.32,5.04,0.54
				c3.36,0.42,6.73,0.74,10.01,1.78l-0.39,3.41c-1.7,0.23-3.35,0.18-5.01,0.12l-4.96-0.21c-3.31-0.11-6.62-0.18-9.94-0.17
				c-6.63-0.14-13.26,0.1-19.9,0.14c-3.33-0.03-6.65,0-9.99-0.09c-3.34-0.13-6.69-0.19-10.02,0.1c-3.34,0.15-6.67,0.55-10.03,0.76
				l-2.52,0.18l-2.53,0.09c-0.84,0.03-1.69,0.02-2.54,0.01c-0.42-0.02-0.85,0.04-1.27-0.07c-0.42-0.13-0.85-0.38-1.27-1.03
				L692.65,292.7z"/>
          </g>
          <g className="st10">
            <path className="st3" d="M776.13,632.98c-0.42,0.65-0.83,0.91-1.24,1.05c-0.41,0.11-0.82,0.07-1.23,0.1l-2.48,0.1
				c-1.65,0.05-3.31,0.07-4.98,0.08c-3.32,0.05-6.67,0-10.01,0.17c-3.35,0.04-6.68,0.42-10.03,0.84c-3.35,0.36-6.7,0.55-10.07,0.75
				c-3.37,0.17-6.74,0.09-10.11-0.02c-3.37-0.12-6.75-0.25-10.12-0.48c-1.69-0.12-3.37-0.27-5.05-0.45
				c-1.68-0.18-3.37-0.32-5.04-0.54c-3.36-0.42-6.73-0.74-10.01-1.78l0.39-3.41c1.7-0.23,3.35-0.18,5.01-0.12l4.96,0.21
				c3.31,0.11,6.62,0.18,9.94,0.17c6.63,0.14,13.26-0.1,19.9-0.14c3.33,0.03,6.65,0,9.99,0.09c3.34,0.13,6.69,0.19,10.02-0.1
				c3.34-0.15,6.67-0.55,10.03-0.76l2.52-0.18l2.53-0.09c0.84-0.03,1.69-0.02,2.54-0.01c0.42,0.02,0.85-0.04,1.27,0.07
				c0.42,0.13,0.85,0.38,1.27,1.03L776.13,632.98z"/>
          </g>
        </g>
      </g>
      <g id="Ebene_8">
      </g>
      <g id="Ebene_2">
      </g>
      <g id="Ebene_3">
        <g>
          <text transform="matrix(1 0 0 1 159.4815 214.9301)">
            <tspan x="0" y="0" className="st12 st15 st16">Drag &amp; drop</tspan>
            <tspan x="0" y="38" className="st12 st15 st16">a table to</tspan>
            <tspan x="0" y="76" className="st12 st15 st16">build a Chart.</tspan>
          </text>
        </g>
      </g>
      <g id="Ebene_6">
        <g>
          <path className="st3" d="M270,120.54c-0.06-0.93,0.19-1.86,0.57-2.7c0.39-0.84,0.9-1.62,1.52-2.31c1.23-1.38,2.87-2.41,4.72-2.83
			c3.7-0.8,7.7,0.9,9.7,4.15l-1.81,0.43c0.32-4.68,0.83-9.57,2.98-14.02c2.04-4.45,6.05-8.16,10.95-9.37
			c2.43-0.63,5.16-0.42,7.48,0.67c2.35,1.05,4.2,2.98,5.49,5.08c2.54,4.3,3.37,9.13,3.98,13.8l-1.89-0.39
			c1.36-2.36,3.69-4.01,6.22-4.77c2.54-0.73,5.38-0.59,7.77,0.68c2.38,1.27,4.06,3.51,4.88,5.95c0.41,1.23,0.61,2.52,0.58,3.81
			l-0.07,0.96l-0.17,0.95l-0.26,0.93c-0.12,0.3-0.23,0.6-0.26,0.94l-1.26-0.55c0.38-0.45,0.42-1.04,0.54-1.58l0.08-0.84l0-0.84
			c-0.05-1.12-0.3-2.22-0.72-3.25c-0.87-2.02-2.27-3.86-4.21-4.81c-1.9-0.97-4.24-1.12-6.36-0.51c-2.11,0.62-4.03,1.99-5.12,3.86
			l-1.34,2.32l-0.33-2.66c-0.57-4.57-1.4-9.21-3.66-13.07c-1.15-1.88-2.71-3.51-4.67-4.4c-1.94-0.93-4.18-1.12-6.3-0.59
			c-1.06,0.25-2.09,0.65-3.07,1.17c-0.97,0.53-1.87,1.19-2.7,1.92c-1.67,1.46-2.92,3.33-3.86,5.37c-1.85,4.11-2.48,8.72-2.81,13.34
			l-0.21,2.94l-1.49-2.53c-1.49-2.54-4.8-3.99-7.69-3.35c-1.44,0.31-2.78,1.13-3.79,2.24c-0.5,0.56-0.93,1.19-1.24,1.87
			c-0.32,0.68-0.55,1.38-0.79,2.11L270,120.54z"/>
        </g>
        <g>
          <path className="st3" d="M204.49,658.75c0.35-1.3,1.24-2.33,2.11-3.3c0.9-0.96,1.93-1.79,3.05-2.47c2.24-1.37,4.86-2.16,7.52-2.14
			c2.67,0.02,5.29,0.84,7.53,2.24c2.25,1.4,4.08,3.46,5.23,5.86l-1.78,0.8c-0.51-1.26-0.55-2.67-0.28-3.89
			c0.26-1.24,0.75-2.35,1.27-3.41c1.1-2.09,2.3-4,3.71-5.88c1.4-1.85,3.04-3.6,5.03-4.97c1-0.67,2.09-1.24,3.25-1.65
			c1.17-0.38,2.4-0.6,3.63-0.63c2.48-0.07,4.86,0.68,6.99,1.67c2.15,0.99,4.07,2.31,5.91,3.66c1.86,1.33,3.68,2.91,5.07,4.85
			c0.7,0.97,1.28,2.08,1.55,3.32c0.07,0.31,0.12,0.62,0.13,0.94c0.04,0.32,0,0.64-0.03,0.96c-0.01,0.32-0.14,0.63-0.21,0.94
			c-0.1,0.3-0.25,0.58-0.28,0.93l-1.22-0.63c0.38-0.37,0.4-0.91,0.44-1.37c0-0.24,0.01-0.48-0.04-0.72
			c-0.02-0.24-0.09-0.48-0.16-0.71c-0.29-0.94-0.88-1.8-1.53-2.61c-1.32-1.66-3-3.02-4.82-4.31c-1.81-1.31-3.65-2.56-5.62-3.49
			c-1.96-0.92-4.04-1.58-6.13-1.53c-1.04,0.02-2.07,0.2-3.07,0.52c-0.99,0.34-1.93,0.82-2.82,1.41c-1.77,1.19-3.3,2.78-4.64,4.52
			c-1.33,1.74-2.55,3.66-3.58,5.58c-1,1.9-1.6,4.07-0.84,5.8l-1.59,0.72c-1.01-2.06-2.67-3.78-4.61-5.02
			c-1.94-1.24-4.21-1.97-6.5-2.01c-2.29-0.04-4.59,0.63-6.57,1.81c-0.99,0.59-1.91,1.32-2.7,2.15c-0.83,0.82-1.39,1.81-2.17,2.71
			L204.49,658.75z"/>
        </g>
        <g>
          <path className="st3" d="M918.94,304.35c-0.26-0.6-0.45-1.23-0.55-1.87c-0.06-0.65-0.18-1.29-0.16-1.95c-0.01-0.65-0.02-1.3,0.06-1.95
			c0.04-0.65,0.15-1.29,0.27-1.93c0.49-2.55,1.52-4.98,2.98-7.13c2.87-4.28,7.46-7.55,12.63-8.43c2.57-0.48,5.33-0.28,7.79,0.67
			c2.47,0.92,4.66,2.44,6.46,4.31c1.81,1.88,3.19,4.09,4.29,6.38c1.1,2.29,1.92,4.67,2.7,7.03l-1.94,0.14
			c0.48-2.74,1.93-5.2,3.94-7.05c1.99-1.85,4.66-3.09,7.45-3.13c2.84-0.08,5.51,1.2,7.41,3.12c0.95,0.97,1.71,2.14,2.15,3.45
			c0.22,0.65,0.34,1.34,0.37,2.03c0.01,0.35-0.03,0.69-0.05,1.04c-0.06,0.34-0.15,0.67-0.12,1.04l-1.32-0.38
			c0.32-0.5,0.23-1.1,0.21-1.65c-0.08-0.56-0.21-1.1-0.44-1.61c-0.44-1.03-1.14-1.95-2-2.69c-1.64-1.56-3.9-2.49-6.12-2.43
			c-2.28,0.05-4.52,1.05-6.23,2.62c-1.72,1.56-2.97,3.68-3.37,5.94l-0.62,3.5l-1.09-3.38c-1.5-4.65-3.21-9.3-6.48-12.75
			c-1.63-1.7-3.59-3.1-5.77-3.9c-2.19-0.79-4.55-0.92-6.84-0.45c-4.6,0.93-8.78,3.73-11.43,7.63c-1.34,1.93-2.29,4.14-2.76,6.44
			c-0.12,0.57-0.23,1.15-0.26,1.73c-0.08,0.58-0.08,1.17-0.07,1.75c-0.03,0.59,0.08,1.16,0.12,1.74c0.04,0.58,0.18,1.15,0.15,1.76
			L918.94,304.35z"/>
        </g>
        <g>
          <g>
            <path className="st3" d="M471.63,163.85c0.09-0.32,0.31-0.49,0.51-0.66l0.62-0.53l1.24-1.06c0.82-0.71,1.68-1.38,2.52-2.07
				c1.69-1.37,3.5-2.58,5.14-4.01l5.05-4.12c0.83-0.7,1.7-1.35,2.63-1.93l1.38-0.88c0.23-0.15,0.46-0.3,0.68-0.45l0.34-0.23
				c0.1-0.09,0.27-0.1,0.27-0.31l0.86,1.07c-0.22-0.05-0.25,0.13-0.35,0.21c-0.09,0.1-0.19,0.2-0.27,0.31l-0.53,0.65
				c-0.36,0.41-0.78,0.76-1.2,1.1c-0.87,0.66-1.75,1.29-2.6,1.97l-5.14,4.02c-1.74,1.3-3.34,2.78-5.04,4.14
				c-0.85,0.67-1.68,1.37-2.55,2.03l-1.29,0.99l-0.65,0.5c-0.22,0.16-0.42,0.34-0.76,0.36L471.63,163.85z"/>
          </g>
          <g>
            <path className="st3" d="M474.58,148.6c0.31,0.02,0.47,0.19,0.65,0.34l0.53,0.47l1.06,0.93c0.72,0.62,1.39,1.28,2.08,1.91
				c1.38,1.29,2.62,2.72,4.05,3.96l4.15,3.85c0.7,0.63,1.37,1.3,1.96,2.05l0.91,1.1c0.15,0.19,0.3,0.37,0.46,0.54l0.23,0.27
				c0.09,0.07,0.11,0.23,0.31,0.19l-0.95,0.99c0.04-0.21-0.13-0.2-0.22-0.29c-0.1-0.06-0.2-0.13-0.31-0.19l-0.64-0.36
				c-0.41-0.26-0.76-0.57-1.1-0.9c-0.67-0.67-1.3-1.37-1.99-2.01l-4.05-3.95c-1.32-1.35-2.79-2.54-4.16-3.83
				c-0.68-0.65-1.38-1.28-2.04-1.96l-1-1l-0.5-0.5c-0.17-0.16-0.35-0.32-0.38-0.63L474.58,148.6z"/>
          </g>
        </g>
      </g>
      <g id="Ebene_9">
      </g>
      <g id="Ebene_5">
      </g>
      <g id="Ebene_10">
      </g>
    </React.Fragment>;
  }

  viewBox(): string {
    return "60 60 1043.7 868.33";
  }
}

export default withStyles(svgIconStyles)(EmptyChartIcon);
