import * as React from "react";
import {ChangeEvent} from "react";

import {Classifier} from "../../../../common/utils/ClassifierLogger";
import Log from "../../../../common/utils/Logger";
import {createStyles, IconButton, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import {modelStore} from "../../../../core/stores/ModelStore";
import {matrixStore} from "../../../stores/MatrixStore";
import DropDownComponent from "../../../../common/components/materialuiderived/DropDownComponent";
import DropDownItemComponent from "../../../../common/components/materialuiderived/DropDownItemComponent";
import {observer} from "mobx-react";
import FilterToolbarIcon from "../../../../properties/icons/FilterToolbarIcon";
import TrashCanIcon from "../../../../properties/icons/TrashCanIcon";
import {Close} from "@material-ui/icons";
import MetusExpansionPanelComponent from "../../../../properties/components/MetusExpansionPanelComponent";
import AddIcon from "../../../../common/icons/menu/AddIcon";
import {FilterDefinition} from "../../../models/FilterDefinition";
import AttributeFilterRuleComponent from "./AttributeFilterRuleComponent";
import {action} from "mobx";
import {FilterRule} from "../../../models/FilterRule";
import {Dispatcher} from "../../../../common/utils/Dispatcher";
import {
  DeleteFilterDefinitionAction,
  UpdateFilterDefinitionAction
} from "../../../../commonviews/actions/SharedViewActions";
import {ViewId} from "../../../../core/utils/Core";
import {createFilterDescription} from "../../../../commonviews/utils/AttributeFilterComponentHelper";
import TableIcon from "../../../../common/icons/navigation/TableIcon";
import FilteredAttributeIcon from "../../../../properties/icons/FilteredAttributeIcon";
import * as _ from "lodash";

const log = Log.logger("properties");


const renderLog = Log.logger("MatrixFilterPropertiesSection", Classifier.render);

const styles = (theme: Theme): StyleRules => createStyles({
  formControl: {
    minWidth: 200
  },
  leftIconInMenu: {
    marginRight: theme.spacing(1) + "px",
  },
  menuText: {
    fontSize: 12
  },
  row: {
    display: "flex",
    height: 38,
    alignItems: "center",
    color: "rgba(0,0,0,0.5)"
  },
  filterName: {
    flexGrow: 1,
  },
  filterNameInactive: {
    flexGrow: 1,
    opacity: 0.2,
  },
  filter: {
    display: "flex",
  },
  middle: {
    marginRight: 24,
  },
  smallText: {
    fontSize: "14px",
    color: "rgba(0,0,0,0.25)",
  }
});

export interface LocalProps {
  matrixId: ViewId;
  filterDefinition: FilterDefinition;
}

interface LocalState {
  isExpanded: boolean;
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

@observer
class AttributeFilterComponent extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {isExpanded: false};
  }

  @action
  updateFilter(update: Partial<FilterDefinition>): void {
    const matrixId = this.props.matrixId;
    const fullUpdate = Object.assign(update,  {id : this.props.filterDefinition.id});
    Dispatcher.dispatch(new UpdateFilterDefinitionAction(matrixId, fullUpdate))
  }

  @autobind
  private handleAttributeSelectionChange(event: ChangeEvent<{ value: string }>): void {
    const attributeName = event.target.value;
    log.debug("Update Attribute", attributeName);
    this.updateFilter({attributeName});
  }

  @autobind
  private handleTableSelectionChanged(event: ChangeEvent<{ value: string }>): void {
    const tableId = event.target.value;
    log.debug("AttributeFilterComponent table selected", tableId);
    this.updateFilter({tableIds: [tableId]});
  }

  private getCommonAttributeNamesOfSelectedTables(): string[] {
    const selectedTableIds = this.props.filterDefinition.tableIds;
    return modelStore.getCommonAttributeNames(selectedTableIds);
  }

  @autobind
  private handleFilterClose() {
    this.setState(oldState => ({isExpanded: !oldState.isExpanded}));
  }

  @autobind
  private createFilterRule():void {
    const filterRules = [...this.props.filterDefinition.filterRules, new FilterRule("contains")];
    log.debug("Update Filter Rule", filterRules);
    this.updateFilter({filterRules});
  }

  @action
  private deleteFilterRule(idx: number):void {
    const filterRules = [...this.props.filterDefinition.filterRules];
    filterRules.splice(idx, 1);
    log.debug("Delete Filter Rule", idx, filterRules);
    this.updateFilter({filterRules});
  }

  @autobind
  private deleteFilterDefinition():void {
    const matrixId = this.props.matrixId;
    log.debug("Delete Attribute Filter Definition");
    Dispatcher.dispatch(new DeleteFilterDefinitionAction(matrixId, this.props.filterDefinition.id));
  }

  render(): JSX.Element {
    renderLog.debug("Rendering Attribute Filter Component", this.props);
    let filterRules = this.props.filterDefinition.filterRules;
    return <MetusExpansionPanelComponent
        id={this.props.filterDefinition.id}
        summaryComponent={this.filterHeader()}
        expanded={true}>
      <div className={this.props.classes.row}>
        <TableIcon/>
        {this.renderTableFilter()}
      </div>
      <div className={this.props.classes.row}>
        <FilteredAttributeIcon/>
        {this.renderAttributeFilter()}
      </div>
      {filterRules.map((filterRule, idx) => <div className={this.props.classes.row}>
        <AttributeFilterRuleComponent filterRule={filterRule}/>
        <IconButton data-testselector="RemoveFilterRuleButton" onClick={this.deleteFilterRule.bind(this, idx)}>
          <Close/>
        </IconButton>
      </div>)}

      <div className={this.props.classes.row} onClick={this.createFilterRule}>
        <AddIcon/>
        <span className={this.props.classes.smallText}>Add a rule</span>
      </div>

    </MetusExpansionPanelComponent>;
  }

  renderAttributeFilter(): JSX.Element {
    log.debug("Rendering Attribute Filter");
    return <DropDownComponent
        value={this.props.filterDefinition.attributeName}
        onChange={this.handleAttributeSelectionChange}
        classes={{inputRoot: this.props.classes.middle}}
        variant="outlined">
      {this.getCommonAttributeNamesOfSelectedTables().map(attId => <DropDownItemComponent key={attId}
                                                                                          value={attId}>{attId}</DropDownItemComponent>)}
    </DropDownComponent>
  }

  renderTableFilter(): JSX.Element {
    const selectedTables = this.props.filterDefinition.tableIds || [];
    log.debug("Render Table Filter", selectedTables);
    const matrix = matrixStore.getMatrixById(this.props.matrixId);
    const columnTables = matrix ? matrix.columnHierarchy.tables : [];
    const uniqueColumnTables = _.uniqBy(columnTables, table => table.tableId);
    return <DropDownComponent
        value={selectedTables}
        onChange={this.handleTableSelectionChanged}
        classes={{textFieldRoot: this.props.classes.typeDropDown, inputRoot: this.props.classes.middle}}
        variant="outlined">
      {uniqueColumnTables.map(
          ({tableId}) => {
            const isChecked = selectedTables.indexOf(tableId) > -1;
            return <DropDownItemComponent key={tableId}
                                          value={tableId}>{modelStore.getTableName(tableId)}</DropDownItemComponent>;
          }
      )}
    </DropDownComponent>;
  }

  private filterHeader(): JSX.Element {
    const filterDescription = createFilterDescription(this.props.filterDefinition);
    return <React.Fragment>
      <FilterToolbarIcon classes={{root: this.props.classes.leftIconInMenu}}/>
      <Typography variant="button" classes={{button: filterDescription.isActive ? this.props.classes.filterName : this.props.classes.filterNameInactive}}><span data-tip={filterDescription.tooltip}>{filterDescription.headerName}</span></Typography>
      <IconButton onClick={this.deleteFilterDefinition} data-testselector="delete"><TrashCanIcon/></IconButton>
    </React.Fragment>
  }



}

export default withStyles(styles)(AttributeFilterComponent);
