/* SVGImageComponent.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Mai 2018
 */
import * as React from "react";
import {observer} from "mobx-react";

interface LocalProps {
  uri: string;
  width: number;
  height: number;
}

@observer export class SVGImageComponent extends React.Component<LocalProps, any> {
  constructor(props: LocalProps) {
    super(props);
  }

  /** 2 px margin */
  private static SIDE_MARGIN: number = 2;
  private static TOP_MARGIN: number = 1;

  render(): JSX.Element {
    const contentAvailableWidth = this.props.width - 2 * SVGImageComponent.SIDE_MARGIN;
    const contentAvailableHeight = this.props.height - 2 * SVGImageComponent.TOP_MARGIN;

    return <image width={contentAvailableWidth + "px"} height={contentAvailableHeight + "px"}
                  xlinkHref={this.props.uri}>
    </image>;

  }

}

