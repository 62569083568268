import {ActionBase, NotRecordedAction, NotRecordedNoParamsAction, ServerRequestInfo} from "./BaseAction";
import {Cursor} from "../constants/Enums";
import {ClientLocation} from "../utils/Geometry";

export class ShowDialogAction extends NotRecordedAction<{ show: boolean, dialog?: JSX.Element }> {
  type: "showDialog" = "showDialog";

  constructor(show: boolean, dialog?: JSX.Element) {
    super({show, dialog});
  }
}

export class ShowErrorDialogAction extends NotRecordedAction<{ show: boolean, dialog: JSX.Element, message?: string }> {
  type: "showErrorDialog" = "showErrorDialog";

  constructor(show: boolean, dialog: JSX.Element, message?: string) {
    super({show, dialog, message});
  }
}

export class DndSourceFeedbackAction extends NotRecordedAction<JSX.Element> {
  type: "dndSourceFeedback" = "dndSourceFeedback";

  /**
   * send dnd source feedback to display.
   * @param {JSX.Element} feedback, must be positioned to reflect getInitialSourceClientOffset () position in client coordinates, it will initially be drawn in a fixed position container starting at 0,0
   */
  constructor(feedback: JSX.Element) {
    super(feedback);
  }
}

export class DndTargetFeedbackAction extends NotRecordedAction<{ cursor: Cursor, targetFeedback?: JSX.Element | string, targetFeedbackLocation?: ClientLocation }> {
  type: "dndTargetFeedback" = "dndTargetFeedback";

  /**
   * send dnd target feedback to display.
   * @param cursor cursor style to set
   * @param {JSX.Element|string} targetFeedback, must be positioned in client coordinates, it will be drawn in a fixed position container starting at 0,0; if it is a string, the string is displayed in a box as feedback, otherwise the passed JSX.Element
   * @param targetFeedbackLocation optional client coordinate offset of target feedback, will be translated by this
   */
  constructor(cursor: Cursor, targetFeedback?: JSX.Element | string, targetFeedbackLocation?: ClientLocation) {
    super({cursor, targetFeedback, targetFeedbackLocation});
  }
}

/**
 * reset dnd interaction state after drag operation finished or canceled
 */
export class DndClearDragStateAction extends NotRecordedNoParamsAction {
  type: "dndClearDragState" = "dndClearDragState";
}

/**
 * show loading indicator to indicate server communication is still in progress
 */
export class ShowLoadingAction extends NotRecordedNoParamsAction {
  type: "show_loading" = "show_loading";
}

/**
 * hide loading indicator
 */
export class HideLoadingAction extends NotRecordedNoParamsAction {
  type: "hide_loading" = "hide_loading";
}

/**
 * show/hide login dialog, not recorded for undo/redo
 */
export class AuthenticationNeededAction extends NotRecordedAction<ServerRequestInfo | undefined> {
  type: "authenticationNeeded" = "authenticationNeeded";

  constructor(authenticationRequest: ServerRequestInfo) {
    super(authenticationRequest);
  }
}

/**
 * the original request could successfully be executed with the credentials from login dialog, the logged-in user name will be saved into the store
 */
export class AuthenticatedAction extends ActionBase<string> {
  type: "authenticated" = "authenticated";

  constructor(userName: string) {
    super(userName);
  }
}

/**
 * after processing the Login Dialog the original request which needed authentication is re-executed
 * with the given credentials from the login dialog, it could still fail
 */
export class AuthenticationInProgressAction extends NotRecordedAction<boolean> {
  readonly type: "authenticationInProgress" = "authenticationInProgress";

  constructor(authenticationInProgress: boolean) {
    super(authenticationInProgress);
  }
}

/**
 * reset authentication failed state
 */
export class SetAuthenticationFailedAction extends NotRecordedAction<boolean> {
  readonly type: "setAuthenticationFailed" = "setAuthenticationFailed";

  constructor(authenticationFailed: boolean) {
    super(authenticationFailed);
  }
}

export class MosaicWindowDragStartAction extends NotRecordedNoParamsAction {
  type: "mosaicWindowDragStart" = "mosaicWindowDragStart";
}

export type InteractionStateActions =
    DndTargetFeedbackAction
    | DndSourceFeedbackAction
    | ShowDialogAction
    | ShowErrorDialogAction
    | DndClearDragStateAction
    | AuthenticationNeededAction
    | AuthenticatedAction
    | AuthenticationInProgressAction
    | SetAuthenticationFailedAction
    | ShowLoadingAction
    | HideLoadingAction
    | MosaicWindowDragStartAction;


