import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../../common/icons/menu/MetusSvgIcon";

class TrashCanIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return  <g id="TrashCanIcon" stroke="none" stroke-width="1" fill-rule="evenodd">
      <path d="M8,9 L8,16.5 C8,17.327 8.673,18 9.5,18 L9.5,18 L14.5,18 C15.327,18 16,17.327 16,16.5 L16,16.5 L16,9 L17,9 L17,16.5 C17,17.878 15.879,19 14.5,19 L14.5,19 L9.5,19 C8.121,19 7,17.878 7,16.5 L7,16.5 L7,9 L8,9 Z M11,9 L11,16 L10,16 L10,9 L11,9 Z M14,9 L14,16 L13,16 L13,9 L14,9 Z M14,5 L14,6 L17,6 L17,7 L7,7 L7,6 L10,6 L10,5 L14,5 Z"></path>
    </g>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(TrashCanIcon);