import {connectMenu, ContextMenu, MenuItem} from "react-contextmenu";
import * as React from "react";


export const ATTRIBUTE_HEADER_CONTEXTMENU_PREFIX = "cm_dg_AttributeHeaderComponent";

interface AttributeHeaderContextMenuProps {
  onRemoveAttribute?: any;
  onEditConditionalFormat?: any;
  onSort?: any;
  trigger?: { disabled: boolean }
  sortable: boolean;
}

interface AttributeHeaderContextMenuDynamic extends AttributeHeaderContextMenuProps {
  id: string;
  windowIndex: number;
}

function AttributeHeaderContextMenu(props: AttributeHeaderContextMenuDynamic) {
  const sortMenuEntries =
      <>
        <MenuItem key="sorta-z" data={{ascending: true}} onClick={props.onSort}
                  attributes={{className: "contextmenu-option--sorta-z"} as any}>Sort A-Z</MenuItem>
        <MenuItem key="sortz-a" data={{ascending: false}} onClick={props.onSort}
                  attributes={{className: "contextmenu-option--sortz-a"} as any}>Sort Z-A</MenuItem>
      </>;
  return <ContextMenu
      id={props.id} className={"metusContextMenu"}>
    <MenuItem key="Delete" onClick={props.onRemoveAttribute} disabled={props.trigger ? props.trigger.disabled : false}
              attributes={{className: "contextmenu-option--delete"} as any}>Remove</MenuItem>
    <MenuItem key="ConditionalFormat" onClick={props.onEditConditionalFormat}
              attributes={{className: "contextmenu-option--conditionalformat"} as any}>Conditional
      Format...</MenuItem>
    {props.sortable ? sortMenuEntries : null}
  </ContextMenu>;
}

/**
 * react-contextmenu supports dynamic context menu by the connect method which must be passed the unique id, thus a factory is used here creating different react components for different ids,
 * see https://github.com/vkbansal/react-contextmenu/blob/master/examples/DynamicMenu.js
 * @param windowIndex
 */
export function createDynamicAttributeHeaderContextMenu(windowIndex: number): React.ComponentType<AttributeHeaderContextMenuProps> {
  return connectMenu<AttributeHeaderContextMenuDynamic>(ATTRIBUTE_HEADER_CONTEXTMENU_PREFIX + windowIndex)(AttributeHeaderContextMenu);
}
