import {createHashHistory, History} from "history";
import Log from "../../common/utils/Logger";
import {ModelInfo} from "../../core/models/ModelInfo";

const log = Log.logger("History");

const myHistory: History = createHashHistory({
  basename: "/"
});

myHistory.listen((location) => {
  log.debug(`The current URL is ${location.pathname}${location.search}`);
});

export default myHistory;

export function createRoute(info: ModelInfo, pathToAppend: string): string {
  const prefix = `/project/${info.locationString}/${info.name}/${info.version}`;
  return prefix + "/" + pathToAppend;
}
