// lib imports
import * as React from "react";
import Log from "../../../common/utils/Logger";
import {ChartColumnElementsComponent} from "./ChartColumnElementsComponent";
import {ViewerContext} from "../../utils/ViewerContext";
import {VisualAttributeIdString} from "../../../core/utils/Core";
import {VisualChartColumn} from "../../models/chart/VisualChartColumn";
import {observer} from "mobx-react";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {ChartColumnHeaderWithAttributesComponent} from "./ChartColumnHeaderWithAttributesComponent";
import {Rect} from "../../../common/utils/Geometry";

const log = Log.logger("diagram");
const renderLog = Log.logger("diagram", Classifier.render);

interface LocalProps {
  viewerContext: ViewerContext;
  visibleSVGRect: Rect;
  table: VisualChartColumn;
  viewerFilters: Map<VisualAttributeIdString, string>;
  filterTextsValidities: Map<VisualAttributeIdString, boolean>;
  animationCount?: number;
}

// App pure component
@observer
export class ChartColumnComponent extends React.Component<LocalProps, any> {
  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering ChartColumnComponent");
    const table: VisualChartColumn = this.props.table;
    return <g data-testselector={"table/" + table.header.name}>
      <ChartColumnHeaderWithAttributesComponent viewerContext={this.props.viewerContext} table={table}
                                                viewerFilters={this.props.viewerFilters}
                                                filterTextsValidities={this.props.filterTextsValidities}/>
      <ChartColumnElementsComponent table={table} viewerContext={this.props.viewerContext}
                                    visibleSVGRect={this.props.visibleSVGRect} animationCount={this.props.animationCount}/>
    </g>;
  }
}