import {ViewId, VisualAttributeId, VisualTableId} from "../../core/utils/Core";
import {ConditionalFormat} from "../models/ConditionalFormat";
import {ActionBase, NotRecordedAction} from "../../common/actions/BaseAction";
import {UUID} from "../../api/api";
import {FilterDefinition} from "../../matrix/models/FilterDefinition";
import {ZoomingStatus} from "../../common/utils/ZoomHelper";
import {Target} from "../../common/constants/Enums";

/*
 * actions shared between all types of views, based on core model informations
 */
export interface RemoveTableFromViewActionPayload {
  visualTableId: VisualTableId;
  target: Target;
}
export class RemoveTableFromViewAction extends ActionBase<RemoveTableFromViewActionPayload> {
  type: "removeTableFromView" = "removeTableFromView";

  constructor(viewId: ViewId, visualTableId: VisualTableId, target: Target = Target.ROW, groupId?: UUID) {
    super({visualTableId, target}, viewId, groupId);
  }
}

export class RemoveAttributeFromViewAction extends ActionBase<{ visualAttributeId: VisualAttributeId, isColumn: boolean }> {
  type: "removeAttributeFromView" = "removeAttributeFromView";

  constructor(viewId: ViewId, visualAttributeId: VisualAttributeId, groupId?: UUID, isColumn: boolean = false) {
    super({visualAttributeId, isColumn}, viewId, groupId);
  }
}

export class ShowConnectedToSelectionAction extends ActionBase<boolean> {
  type: "showConnectedToSelection" = "showConnectedToSelection";

  constructor(viewId: ViewId, showConnectedToSelection: boolean) {
    super(showConnectedToSelection, viewId);
  }
}

export class SetConditionalFormatsAction extends ActionBase<{ visualAttributeId: VisualAttributeId, conditionalFormats: ConditionalFormat[] }> {
  type: "setConditionalFormats" = "setConditionalFormats";

  constructor(viewId: ViewId, visualAttributeId: VisualAttributeId, conditionalFormats: ConditionalFormat[]) {
    super({visualAttributeId, conditionalFormats}, viewId);
  }
}

export interface UpdateViewerFilterForAttributePayload {
  visualAttributeId: VisualAttributeId;
  filterString: string;
}

export class UpdateViewerFilterForAttributeAction extends ActionBase<UpdateViewerFilterForAttributePayload> {
  type: "updateViewerFilterForAttribute" = "updateViewerFilterForAttribute";

  constructor(viewId: ViewId, visualAttributeId: VisualAttributeId, filterString: string) {
    super({visualAttributeId, filterString}, viewId);
  }
}

export class AddFilterDefinitionAction extends ActionBase<FilterDefinition> {
  type: "addFilterDefinition" = "addFilterDefinition";

  constructor(viewId: ViewId, filterDefinition: FilterDefinition) {
    super(filterDefinition, viewId);
  }
}

export class DeleteFilterDefinitionAction extends ActionBase<string> {
  type: "deleteFilterDefinition" = "deleteFilterDefinition";

  constructor(viewId: ViewId, filterDefinitionId: string) {
    super(filterDefinitionId, viewId);
  }
}

export type FilterDefinitionUpdate = Partial<FilterDefinition> & { id: FilterDefinition["id"] };

export class UpdateFilterDefinitionAction extends ActionBase<FilterDefinitionUpdate> {
  type: "updateFilterDefinition" = "updateFilterDefinition";

  constructor(matrixId: ViewId, update: FilterDefinitionUpdate) {
    super(update, matrixId);
  }
}

export class ExportViewAction extends ActionBase<undefined> {
  type: "exportView" = "exportView";

  constructor(viewId: ViewId) {
    super(undefined, viewId);
  }
}

export class PrintViewAction extends ActionBase<undefined> {
  type: "printView" = "printView";

  constructor(viewId: ViewId) {
    super(undefined, viewId);
  }
}

/**
 * set mode that attribute headers will be packed automatically one beneath the other on drag/drop
 */
export class RotateAttributeHeadersAction extends ActionBase<undefined> {
  type: "rotateAttributeHeaders" = "rotateAttributeHeaders";

  constructor(viewId: ViewId) {
    super(undefined, viewId);
  }
}

export class ChangeAttributeHeaderHeightAction extends ActionBase<number> {
  type: "changeAttributeHeaderHeight" = "changeAttributeHeaderHeight";

  constructor(viewId: ViewId, newHeight: number) {
    super(newHeight, viewId);
  }
}

export class ChangeAttributeHeaderWidthAction extends ActionBase<number> {
  type: "changeAttributeHeaderWidth" = "changeAttributeHeaderWidth";

  constructor(viewId: ViewId, newWidth: number) {
    super(newWidth, viewId);
  }
}

export class ToggleHeaderExpandedAction extends NotRecordedAction<{ windowIndex: number, isHeaderExpanded: boolean }> {
  type: "toggleHeaderExpanded" = "toggleHeaderExpanded";

  constructor(viewId: ViewId, windowIndex: number, isHeaderExpanded: boolean) {
    super({windowIndex, isHeaderExpanded}, viewId);
  }
}

export class ZoomViewAction extends NotRecordedAction<{ windowIndex: number, zoomingStatus: ZoomingStatus }> {
  type: "zoomView" = "zoomView";

  constructor(windowIndex: number, zoomingStatus: ZoomingStatus) {
    super({windowIndex, zoomingStatus});
  }
}

export interface ReorderTablesActionPayload {
  viewId: ViewId;
  sourceVisualTableId: VisualTableId;
  targetVisualTableId: VisualTableId;
  newIndex: number;
}

export class ReorderTablesAction extends ActionBase<ReorderTablesActionPayload> {
  type: "reorderTables" = "reorderTables";

  constructor(viewId: ViewId, sourceVisualTableId: VisualTableId, targetVisualTableId: VisualTableId, newIndex: number, groupId?: UUID) {
    super({viewId, sourceVisualTableId, targetVisualTableId, newIndex: newIndex}, viewId, groupId);
  }
}

export type SharedViewActions =
    RemoveTableFromViewAction
    | RemoveAttributeFromViewAction
    | ShowConnectedToSelectionAction
    | SetConditionalFormatsAction
    | UpdateViewerFilterForAttributeAction
    | AddFilterDefinitionAction
    | DeleteFilterDefinitionAction
    | UpdateFilterDefinitionAction
    | ExportViewAction
    | PrintViewAction
    | RotateAttributeHeadersAction
    | ChangeAttributeHeaderHeightAction
    | ChangeAttributeHeaderWidthAction
    | ToggleHeaderExpandedAction
    | ZoomViewAction
    | ReorderTablesAction
    ;

export class RemoveViewAction extends ActionBase<number> {
  type: "removeView" = "removeView";

  constructor(viewId: ViewId, groupId?: UUID) {
    super(undefined, viewId, groupId);
  }
}