import {
  Button,
  Checkbox,
  FormLabel,
  TextField,
  withStyles
} from "@material-ui/core";
import {WithStyles} from "@material-ui/styles";
import * as React from "react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {AttributeDefinition} from "../../api/api";
import autobind from "autobind-decorator";
import {
  generateNumberPatternFromNumberPatternDescription,
  NumberPatternDescription,
  renderDisplayValue
} from "../../common/utils/FormatUtil";
import {hideDialog} from "../../common/utils/CommonDialogUtil";
import BaseDialog, {dialogContentStyles} from "../../common/components/BaseDialog";


const renderLog = Log.logger("editAttributeNumberFormatDialog", Classifier.render);
const DEFAULT_EXAMPLE_NUMBER: number = 125.75;
const DEFAULT_PATTERN_DESCRIPTION: NumberPatternDescription = {
  positivePrefix: "",
  negativePrefix: "-",
  suffix: "€",
  minIntDigits: 0,
  minFractDigits: 2,
  useThousandSeparator: false,
}
const DEFAULT_PATTERN: string = "##,00€";

interface LocalProps {
  open: boolean,
  pattern?: string,
  onSubmit: any,
}

interface LocalState {
  open: boolean,
  errorMessage: string,
  positivePrefix: string,
  negativePrefix: string,
  suffix: string,
  minIntDigits: number,
  minFractDigits: number,
  useThousandSeparator: boolean,
  pattern: string,
  exampleNumber: number,
  formattedExample: string,
}

type StyledLocalProps = LocalProps & WithStyles<typeof dialogContentStyles>;

class EditAttributeNumberFormatDialog extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
    // TODO: implement interpretation of existing pattern
    const formattedExample = this.getFormattedNumber(DEFAULT_EXAMPLE_NUMBER, DEFAULT_PATTERN);
    this.state = {
      open: props.open,
      errorMessage: undefined,
      pattern: DEFAULT_PATTERN,
      ...DEFAULT_PATTERN_DESCRIPTION,
      exampleNumber: DEFAULT_EXAMPLE_NUMBER,
      formattedExample,
    }
  }

  render(): JSX.Element {
    renderLog.debug("Rendering EditAttributeNumberFormatDialog");
    const {classes} = this.props;
    const paperProps = !!this.state.errorMessage ? classes.dialogPaperError : classes.dialogPaper;

    return <BaseDialog
        dialogContent={this.dialogContent()}
        title={"Number Formatter"}
        open={true}
        onSubmit={this.handleSubmit}>
    </BaseDialog>

  }

  @autobind
  handleClose(): void {
    hideDialog();
  }

  @autobind
  handleSubmit(event: any): void {
    this.props.onSubmit({target: {value: this.state.pattern}});
    hideDialog();
  }

  dialogContent(): JSX.Element {
    return (
        <div id="form.EditAttributeNumberFormatDialog"
             className={this.props.classes.dialogContent}>
          <FormLabel className={this.props.classes.sectionLabel} style={{gridRow: 1}}>Formatting Properties</FormLabel>
          {this.formattingProps()}
          <FormLabel className={this.props.classes.sectionLabel} style={{gridRow: 3}}>Resulting Pattern and
            Example</FormLabel>
          {this.patternAndExample()}
        </div>
    );
  }

  formattingProps(): JSX.Element {
    return <div style={{
      display: "grid",
      alignItems: "center",
      gridRow: 2,
      gridColumn: "span 2"
    }}>{[...this.prefixAndSuffixGroup(), ...this.groupingAndDigitsGroup()]}</div>;
  }

  prefixAndSuffixGroup(): JSX.Element[] {
    const positivePrefixLabel = (
        <FormLabel className={this.props.classes.inputLabel} style={{gridRow: 1, gridColumn: 1}}>Positive
          Prefix:</FormLabel>);
    const positivePrefix = (<TextField
        className={this.props.classes.inputSmall}
        style={{gridRow: 1, gridColumn: 2}}
        id={"positivePrefix"}
        value={this.state.positivePrefix}
        onChange={this.handlePositivePrefixChange}>
    </TextField>);
    const negativePrefixLabel = (
        <FormLabel className={this.props.classes.inputLabel} style={{gridRow: 2, gridColumn: 1}}>Negative
          Prefix:</FormLabel>)
    const negativePrefix = (<TextField
        className={this.props.classes.inputSmall}
        style={{gridRow: 2, gridColumn: 2}}
        id={"negativePrefix"}
        value={this.state.negativePrefix}
        disabled={true}
        onChange={this.handleNegativePrefixChange}>
    </TextField>);
    const suffixLabel = (
        <FormLabel className={this.props.classes.inputLabel} style={{gridRow: 3, gridColumn: 1}}>Suffix:</FormLabel>);
    const suffix = (<TextField
        className={this.props.classes.inputSmall}
        style={{gridRow: 3, gridColumn: 2}}
        id={"suffix"}
        value={this.state.suffix}
        onChange={this.handleSuffixChange}>
    </TextField>);
    const thousandSeparatorLabel = (<FormLabel
        className={this.props.classes.inputLabel}
        style={{gridRow: 4, gridColumn: 1}}>Use 1000 Spearator:</FormLabel>)
    const thousandSeparatorCheckBox = (<Checkbox
        className={this.props.classes.inputSmall}
        style={{gridRow: 4, gridColumn: 2}}
        color={"primary"}
        onChange={this.handleThousandSeparatorChange}
        value={this.state.useThousandSeparator}></Checkbox>);
    return ([positivePrefixLabel,
      positivePrefix,
      negativePrefixLabel,
      negativePrefix,
      suffixLabel,
      suffix,
      thousandSeparatorLabel,
      thousandSeparatorCheckBox]);
  }

  groupingAndDigitsGroup(): JSX.Element[] {
    const minIntDigitsLabel = (
        <FormLabel className={this.props.classes.inputLabel}
                   style={{gridRow: 1, gridColumn: 3, marginLeft: "40px"}}>Minimum <br/>Integer Digits:</FormLabel>);
    const minIntDigitsButtons = (<div
        style={{gridRow: 1, gridColumn: 4, display: "grid"}}>
      <Button size={"small"}
              key={0}
              style={{gridRow: 1, gridColumn: 1}}
              variant={"outlined"}
              className={this.state.minIntDigits === 0 ? this.props.classes.toggleButtonSelected : this.props.classes.toggleButton}
              onClick={() => {
                this.handleIntDigitChange(0);
              }}>0</Button>
      <Button size={"small"}
              key={1}
              style={{gridRow: 1, gridColumn: 2}}
              variant={"outlined"}
              className={this.state.minIntDigits === 1 ? this.props.classes.toggleButtonSelected : this.props.classes.toggleButton}
              onClick={() => {
                this.handleIntDigitChange(1);
              }}>1</Button>
      <Button size={"small"}
              key={2}
              style={{gridRow: 2, gridColumn: 1}}
              variant={"outlined"}
              className={this.state.minIntDigits === 2 ? this.props.classes.toggleButtonSelected : this.props.classes.toggleButton}
              onClick={() => {
                this.handleIntDigitChange(2);
              }}>2</Button>
      <Button size={"small"}
              key={3}
              style={{gridRow: 2, gridColumn: 2}}
              variant={"outlined"}
              className={this.state.minIntDigits === 3 ? this.props.classes.toggleButtonSelected : this.props.classes.toggleButton}
              onClick={() => {
                this.handleIntDigitChange(3);
              }}>3</Button>
    </div>);
    const minFractDigitsLabel = (
        <FormLabel className={this.props.classes.inputLabel}
                   style={{gridRow: 3, gridColumn: 3, marginLeft: "40px"}}>Minimum <br/>Fraction Digits:</FormLabel>);
    const minFractDigitsButtons = (<div
        style={{gridRow: 3, gridColumn: 4, display: "grid"}}>
      <Button size={"small"}
              key={0}
              style={{gridRow: 1, gridColumn: 1}}
              variant={"outlined"}
              className={this.state.minFractDigits === 0 ? this.props.classes.toggleButtonSelected : this.props.classes.toggleButton}
              onClick={() => {
                this.handleFractDigitChange(0);
              }}>0</Button>
      <Button size={"small"}
              key={1}
              style={{gridRow: 1, gridColumn: 2}}
              variant={"outlined"}
              className={this.state.minFractDigits === 1 ? this.props.classes.toggleButtonSelected : this.props.classes.toggleButton}
              onClick={() => {
                this.handleFractDigitChange(1);
              }}>1</Button>
      <Button size={"small"}
              key={2}
              style={{gridRow: 2, gridColumn: 1}}
              variant={"outlined"}
              className={this.state.minFractDigits === 2 ? this.props.classes.toggleButtonSelected : this.props.classes.toggleButton}
              onClick={() => {
                this.handleFractDigitChange(2);
              }}>2</Button>
      <Button size={"small"}
              key={3}
              style={{gridRow: 2, gridColumn: 2}}
              variant={"outlined"}
              className={this.state.minFractDigits === 3 ? this.props.classes.toggleButtonSelected : this.props.classes.toggleButton}
              onClick={() => {
                this.handleFractDigitChange(3);
              }}>3</Button>
    </div>);
    return [minIntDigitsLabel,
      minIntDigitsButtons,
      minFractDigitsLabel,
      minFractDigitsButtons];
  }

  patternAndExample(): JSX.Element {
    const patternLabel = (
        <FormLabel className={this.props.classes.inputLabel} style={{gridRow: 7, gridColumn: 1}}>Pattern:</FormLabel>);
    const pattern = (<TextField
        size={"small"}
        className={this.props.classes.inputMedium}
        style={{gridRow: 7, gridColumn: 2}}
        id={"pattern"}
        variant={"outlined"}
        value={this.state.pattern}
        disabled={true}>
    </TextField>);
    const exampleNumberLabel = (
        <FormLabel className={this.props.classes.inputLabel} style={{gridRow: 8, gridColumn: 1}}>Example
          Number:</FormLabel>);
    const exampleNumber = (<TextField
        className={this.props.classes.inputMedium}
        size={"small"}
        style={{gridRow: 8, gridColumn: 2}}
        id={"exampleNumber"}
        variant={"outlined"}
        value={this.state.exampleNumber}
        type={"number"}
        onChange={this.handleExampleNumberChange}>
    </TextField>);
    const formattedExampleLabel = (<FormLabel className={this.props.classes.inputLabel}
                                              style={{gridRow: 9, gridColumn: 1}}>Formatted:</FormLabel>);
    const formattedExample = (<TextField
        size={"small"}
        className={this.props.classes.inputMedium}
        style={{gridRow: 9, gridColumn: 2}}
        id={"formattedExample"}
        variant={"outlined"}
        value={this.state.formattedExample}
        disabled={true}>
    </TextField>);
    return <div style={{
      display: "grid",
      gridRow: 4,
      gridColumn: "span 2",
      gridTemplateColumns: "150px auto",
      alignItems: "center"
    }}>{
      [patternLabel,
        pattern,
        exampleNumberLabel,
        exampleNumber,
        formattedExampleLabel,
        formattedExample]}</div>;
  }

  @autobind
  handlePositivePrefixChange(event: any) {
    this.updateState({positivePrefix: event.target.value})
  }

  @autobind
  handleNegativePrefixChange(event: any) {
    this.updateState({negativePrefix: event.target.value})
  }

  @autobind
  handleSuffixChange(event: any) {
    this.updateState({suffix: event.target.value})
  }

  @autobind
  handleThousandSeparatorChange(event: any) {
    this.updateState({useThousandSeparator: event.target.checked});
  }

  @autobind
  handleIntDigitChange(newValue: number) {
    this.updateState({minIntDigits: newValue})
  }

  @autobind
  handleFractDigitChange(newValue: number) {
    this.updateState({minFractDigits: newValue});
  }

  @autobind
  handleExampleNumberChange(event: any) {
    this.updateState({exampleNumber: event.target.value});
  }

  @autobind
  updateState(updatedStateValues: Partial<LocalState>): void {
    updatedStateValues.pattern = this.getPattern(updatedStateValues);
    updatedStateValues.formattedExample = this.getFormattedExample(updatedStateValues);
    this.setState(updatedStateValues as LocalState);
  }

  @autobind
  getPattern(updatedStateValues: Partial<LocalState>): string {
    const updatedState = {...this.state, ...updatedStateValues};
    return generateNumberPatternFromNumberPatternDescription(updatedState);
  }

  @autobind
  getFormattedExample(updatedStateValues: Partial<LocalState>): string {
    const updatedState = {...this.state, ...updatedStateValues};
    return this.getFormattedNumber(updatedState.exampleNumber, updatedState.pattern);
  }

  @autobind
  getFormattedNumber(number: number, pattern: string): string {
    const fakeAttributeDefinition: Partial<AttributeDefinition> = {
      pattern: pattern,
      editPattern: "",
      type: "String",
      formatType: "Double"
    };
    return renderDisplayValue("" + number, fakeAttributeDefinition as AttributeDefinition);
  }
}

export default withStyles(dialogContentStyles)(EditAttributeNumberFormatDialog);
