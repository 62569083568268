/* ToolboxComponent.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Juli 2017
 */
import * as React from "react";

import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import PropertiesTabComponent from "./PropertiesTabComponent";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {PropertiesModel, Tab} from "../model/PropertiesModel";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import MetusSidebarEntry from "../../common/components/MetusSidebarEntry";
import MetusSidebar from "../../common/components/MetusSidebar";
import {observer} from "mobx-react";

const f = (e: any): any => {
  return {a: 1};
};

const styles = (theme: Theme): StyleRules => {
  const activeColor = theme.palette.primary[500];
  const inactiveColor = theme.palette.primary[400];
  const tabCommon = {
    textAlign: ("center" as any),
    marginBottom: theme.spacing(2) + "px",
  };
  const iconCommon = {
    width: "32px",
    height: "32px",
  };
  return createStyles({
    root: {
      display: "flex",
      width: "100%",
      height: "100%",
    },
    tabBarRoot: {
      flex: "0 0 64px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: theme.palette.primary[300],
      height: "100%",
    },
    activeTabRoot: {
      flex: "1 1 auto",
    },
    flexVertical: {
      flexDirection: "column",
    },
    tab: {
      ...tabCommon,
      color: inactiveColor,
    },
    activeTab: {
      ...tabCommon,
      color: activeColor,
    },
    activeIcon: {
      ...iconCommon,
      fill: activeColor,
    },
    icon: {
      ...iconCommon,
      fill: inactiveColor,
    },
    openCloseButton: {
      margin: "8px 0px",
    }

  });
};

const log = Log.logger("properties");
const renderLog = Log.logger("properties", Classifier.render);

interface LocalProps<C> {
  propertiesModel: PropertiesModel<C>;
  onClosedChanged?: (isClosed: boolean) => void;
  closed?: boolean;
}

interface LocalState {
  activeSectionId: string;
  activeTabId: string;
}

type StyledLocalProps<C> = LocalProps<C> & WithStyles<typeof styles>;

@observer
class ToolboxComponent<C> extends React.Component<StyledLocalProps<C>, LocalState> {

  constructor(props: StyledLocalProps<C>) {
    super(props);
    this.state = this.getTabDefinitionsAndPropertiesForActiveElement();
  }

  private getTabDefinitionsAndPropertiesForActiveElement(): LocalState {
    const tabs: Tab<C>[] = this.props.propertiesModel.tabs.filter(t => t.visible);
    let result = {activeSectionId: null, activeTabId: null};
    if (tabs.length > 0) {
      const activeSectionExists = this.props.propertiesModel.getTabForSection(tabs, this.state && this.state.activeSectionId);
      if (!activeSectionExists) {
        const activeTabId = tabs[0].tabDefinitionId;
        const activeSectionId = tabs[0].sections[0].sectionDefinitionId;
        result = {activeSectionId, activeTabId};
      }
    }
    return result;
  }

  render(): JSX.Element {
    renderLog.debug("Rendering ToolboxComponent", this.state);
    const visibleTabs = this.props.propertiesModel.tabs.filter(t => t.visible);
    const tabEntries = visibleTabs.map((tab) => {
      const tabDefinition = this.props.propertiesModel.getTabDefinition(tab.tabDefinitionId);
      const {activeSectionId, activeTabId} = this.getTabDefinitionsAndPropertiesForActiveElement();

      return <MetusSidebarEntry icon={tabDefinition.icon} key={"icon" + tab.tabDefinitionId}>
        <PropertiesTabComponent
            key={tab.tabDefinitionId}
            classes={{root: this.props.classes.activeTabRoot}}
            tab={tab}
            activeSectionId={activeSectionId}
            sectionChangeHandler={this.setActiveSection}
            propertiesModel={this.props.propertiesModel}/>
      </MetusSidebarEntry>;
    });
    return <MetusSidebar type="properties" onVisibleEntryChange={this.onVisibleEntryChange}
                         visibleEntryIndex={this.props.closed ? null : 0}>
      {tabEntries}
    </MetusSidebar>;
  }

  @autobind
  private onVisibleEntryChange(visibleEntryIndex: number): void {
    const isClosed = visibleEntryIndex === null;
    this.props.onClosedChanged(isClosed);
    log.debug("Switching tab", visibleEntryIndex);
    const activeTab = this.props.propertiesModel.tabs[visibleEntryIndex];
    const activeSectionId = activeTab && activeTab.sections[0].sectionDefinitionId;
    this.setState(prevState => {
      return {activeTabId: activeTab && activeTab.tabDefinitionId, activeSectionId};
    });
  }

  private setActiveSection = (sectionId: string): void => {
    log.debug("Handling activation of section", sectionId);
    const activeSectionId = this.state.activeSectionId === sectionId ? null : sectionId;
    log.debug("Setting active Section", activeSectionId);
    this.setState((prevState) => {
      return {
        activeSectionId
      };
    });
  }
}

export default withStyles(styles)(ToolboxComponent);
