/* ChartDiagramModel.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Marco van Meegen, April 2018
 */

import {AttributeDefinition, Connection, DiagramData, LayoutAlgorithm, NAME_ATT_NAME} from "../../../api/api";
import {
  AttributeId,
  ElementId,
  ElementObject,
  TableId,
  ViewId,
  VisualAttributeId,
  VisualAttributeIdString,
  VisualElementId,
  VisualTableId,
  VisualTableIdString
} from "../../../core/utils/Core";
import {Validate} from "../../../common/utils/Validate";
import {
  AutoLayoutOptionProperties,
  AutoLayoutOptions,
  splitTablesIntoSubtrees
} from "../../utils/autolayout/AutoLayoutOptions";
import {layoutHierarchy} from "../../utils/autolayout/AutoLayout";
import Log from "../../../common/utils/Logger";
import {
  FilterTextCheckerResult,
  HierarchicalFilterData,
  processFilterText
} from "../../../core/utils/filter/FilterTextProcessor";
import {filter, getSimpleMatcher, SimpleMatcher} from "../../../core/utils/filter/Evaluator";
import {ViewType} from "../../../common/constants/Enums";
import {HierarchicalElementGraph} from "../../../core/utils/elementgraph/ElementGraph";
import * as _ from "lodash";
import {DiagramModel} from "../DiagramModel";
import {VisualObject} from "../VisualObject";
import {VisualChartColumn} from "./VisualChartColumn";
import {VisualAttributeDefinition} from "../common/VisualAttributeDefinition";
import {VisualChartElement} from "./VisualChartElement";
import {VisualConnection} from "./VisualConnection";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";
import {VisualBaseElement, VisualBaseTable} from "../common/CommonDiagramTypes";
import {VisualHeader} from "../../../commonviews/models/VisualHeader";
import {ConditionalFormat} from "../../../commonviews/models/ConditionalFormat";
import {VisualValueChartElement} from "../valuechart/VisualValueChartElement";
import {modelStore} from "../../../core/stores/ModelStore";
import {getTwoElementKey} from "../../../core/utils/TwoElementIdKey";
import {list, object, serializable} from "serializr";
import {computed, observable} from "mobx";
import {VisualValueChart} from "../valuechart/VisualValueChart";
import {AddAttributeToViewPayload, Coords} from "../../../commonviews/actions/SharedViewAsyncActions";
import {nextYPosition} from "../../utils/DiagramPositionUtil";
import {viewManagerRegistry} from "../../../commonviews/models/ViewManager";
import {chartElementComparator, negate} from "../../../core/utils/comparator/AttributeValueComparator";

const log = Log.logger("model");

function determineExtremeValue(columns: VisualChartColumn[], extremeFunction: (...values: number[]) => number, initialValue: number): number {
  return columns.reduce((prev: number, value: VisualChartColumn) => extremeFunction(prev, ...value.elements.map(e => (e.y + e.height / 2))), initialValue);
}

export class ChartDiagramModel extends DiagramModel {
  @serializable(list(object(VisualChartColumn))) @observable protected _children: VisualChartColumn[] = [];
  get children(): VisualChartColumn[] {
    return this._children;
  }

  /** map containing all table id -> chart column */
  @observable readonly chartTableColumns: Map<string, VisualChartColumn>;
  /** delta of nodes translated from their original y to make space for header */
  private lastDelta: number = 0;

  /**
   * new ChartDiagramModel
   * @param viewId view UUID
   * @param title view title
   * @param type view type
   * @param diagramData serialized form of graph data loaded from rest service
   */
  constructor(viewId: ViewId, title: string, type: ViewType, diagramData?: DiagramData) {
    super(viewId, title, type);
    this.chartTableColumns = new Map<string, VisualChartColumn>();

    if (diagramData) {
      this.initFromSerializedDiagram(diagramData);
    }
  }

  /**
   * convenience method to retrieve the single element which is a value chart
   * @returns {VisualValueChart}
   */
  get valueChart(): VisualValueChart {
    const vc = this.children.find(child => child instanceof VisualValueChart);
    return vc as any as VisualValueChart;
  }


  protected diagramInitialized(): void {
    this.lastDelta = this.getAttributeHeaderSpace();
    this.adjustNodeAndAttributePositions();
    // make sure all attribute headers are positioned correctly (migration after layout changes)
    this.chartTableColumns.forEach(c => c.visualAttributeDefinitions.forEach(vad => vad.header.y = this.getAttributeHeaderYPos()));
    if (this._autoLayoutOptions && this._autoLayoutOptions.autolayout) {
      // autolayout is defined, thus do it here
      this.autolayout();
    }
  }

  public moveNodes(visualNodeIds: Array<VisualElementId>, dx: number, dy: number): void {
    log.debug("Moving " + visualNodeIds.length + " visual nodes", visualNodeIds);
    visualNodeIds.forEach(nodeId => {
      const node: VisualBaseElement = this.getVisualElement(nodeId);
      if (node && node.visualTable) {
        node.visualTable.header.x = node.visualTable.header.x + dx;
        node.y = node.y + dy;
      }
      // ignore nodes not found, because these might be selections from other diagrams
    });
  }

  @computed.struct
  public get autoLayoutProperties(): AutoLayoutOptionProperties {
    const defaultOptions: AutoLayoutOptions = {
      preserveOrdering: false,
      autolayout: false,
      algorithm: LayoutAlgorithm.DAGRE,
      selectedTable: undefined,
    };

    const selectedTable = this.autoLayoutOptions ? this.autoLayoutOptions.selectedTable : undefined;
    const tableNames: { id: VisualTableIdString, name: string }[] = [];
    const xCoordinates: { id: string; x: number }[] = [];

    this.chartTableColumns.forEach((value, id) => {
      tableNames.push({id, name: value.header.name});
      xCoordinates.push({id, x: value.header.x});
    });

    /* Sort tables according to their x-coordinate */
    const allSortedTables = xCoordinates
        .sort((coord1, coord2) => coord1.x - coord2.x)
        .map(coord => coord.id);

    /* Split tables in two halfes according to the divider. */
    const {leftTables, rightTables} = splitTablesIntoSubtrees(selectedTable ? selectedTable.toKey() : undefined, allSortedTables);
    return {
      ...defaultOptions,
      ...this.autoLayoutOptions,
      selectedTable,
      leftTables,
      rightTables,
      tableNames,
      allSortedTables
    };
  }

  public mergeAttributeValues(tableId: TableId, elements: ElementObject[]): void {
    if (elements.length !== 0) {
      const chartTableColumns = this.getVisualTablesForTable(tableId);

      chartTableColumns.forEach(table => {
        this.initializeTableNodesIfNeeded(table, elements);
        log.debug("Create attribute values", elements);

        // if still numeric, convert id to string
        const nodes: VisualChartElement[] = elements.map(element => table.getNodeForId("" + element.id));

        Object.keys(elements[0]).forEach(attributeName => {
          if ("id" !== attributeName) {
            const attribute: VisualAttributeDefinition = table.getAttribute(attributeName);
            if (attribute !== undefined) {
              this.createAttributeValues(attribute, nodes, elements.map(element => element[attributeName]));
            }
          }
        });
      });

      // filter might need refreshing
      this.updateAllFilteredElements(false);
      this.updateConditionalFormatsForTable(tableId);
    }
  }

  public initVisualConnections(connections: Connection[]): void {
    this.connections = [];
    this.addVisualConnections(connections);
    this.updateAllFilteredElements(true);
  }

  private initializeTableNodesIfNeeded(table: VisualChartColumn, elements: ElementObject[]): void {
    if (table.visualElements.size === 0) {
      log.debug(`Initializing Table ${table.id.toKey()}`);
      let y = this.getFirstFreeYPosition();
      elements.forEach((element, idx) => {
        log.debug("Adding node", element.id);
        const newNode = new VisualChartElement(
            table,
            new VisualElementId("" + element.id),
            element.hasOwnProperty("name") ? (element as any).name : "",
            y += (DiagramVisualConstants.DEFAULT_GAP_BETWEEN_ELEMENTS + DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT),
            DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT);
        table.addNode(newNode);
      });
    }
  }

  public addTable(visualTableId: VisualTableId, title: string, requestedCoords: Coords): VisualChartColumn {
    const gTable = new VisualChartColumn(visualTableId.tableId, title, title, requestedCoords.x, DiagramVisualConstants.TABLE_HEADER_YPOS, DiagramVisualConstants.DEFAULT_TABLE_WIDTH);
    gTable.setHeaderRotation(this.attributeHeaderRotation, this.attributeHeaderRotation === 0 ? DiagramVisualConstants.TABLE_HEADER_ATTRIBUTE_HEADER_HEIGHT : this.attributeHeaderHeight);
    gTable.id = visualTableId;
    gTable.visualElements.forEach((value: VisualChartElement) => {
      value.y = value.y + this.getAttributeHeaderSpace();
    });
    this.addChildren(gTable);
    this.updateAllFilteredElements(true);
    return gTable;
  }

  public addVisualConnections(connectionsToAdd: Connection[]): void {
    const result = this.connections;
    connectionsToAdd.forEach((connection) => {
      // convert node ids to string if still numeric
      const sourceNodes: VisualChartElement[] = this.getVisualNodesForNode("" + connection.sourceElementId);
      const targetNodes: VisualChartElement[] = this.getVisualNodesForNode("" + connection.targetElementId);
      sourceNodes.forEach((sourceNode) => {
        targetNodes.forEach((targetNode) => {
          // #3759 do not show connections to self
          if (sourceNode && targetNode && (sourceNode.visualTable !== targetNode.visualTable)) {
            log.debug("Adding Visual Connection ", connection);
            result.push(new VisualConnection(sourceNode, targetNode));
          } else {
            log.debug("Unknown elements, not creating a visual connection", connection);
          }
        });
      });
    });
  }

  public getVisualTable(visualTableId: VisualTableId): VisualChartColumn {
    return visualTableId.hasNoVisualId() ? this.getFirstVisualTableForTable(visualTableId.tableId) : this.chartTableColumns.get(visualTableId.toKey());
  }

  public getVisualTablesForTable(tableId: TableId): VisualChartColumn[] {
    return super.getVisualTablesForTable(tableId).filter(v => v instanceof VisualChartColumn) as VisualChartColumn[];
  }

  public getFirstVisualTableForTable(tableId: TableId): VisualChartColumn {
    const matchingTables = this.getVisualTablesForTable(tableId);
    return matchingTables.length > 0 ? matchingTables[0] : null;
  }

  public addChildren(...childVisuals: VisualObject[]): void {
    super.addChildren(...childVisuals);
    childVisuals.forEach(c => {
      if (c instanceof VisualChartColumn) {
        this.chartTableColumns.set(c.id.toKey(), c);
      }
    });
  }

  public removeTable(visualTableId: VisualTableId): void {
    const table = this.getVisualTable(visualTableId);
    if (table != null) {
      // remove edges from or to nodes of this table
      const newEdges = this.connections.filter(edge => edge.source.visualTable !== table && edge.target.visualTable !== table);
      this.connections = newEdges;
      this.chartTableColumns.delete(visualTableId.toKey());
      this.removeChildById(visualTableId);

      // clean the current filter texts from the deleted table
      const viewerFiltersToDelete: VisualAttributeIdString[] = [];
      this.viewerFilters.forEach((value, key: VisualAttributeIdString, map) => {
        if (VisualAttributeId.fromKey(key).visualTableId.toKey() === visualTableId.toKey()) {
          viewerFiltersToDelete.push(key);
        }
      });
      viewerFiltersToDelete.forEach((visualAttributeIdString: VisualAttributeIdString) => {
        this.viewerFilters.delete(visualAttributeIdString);
      });

      this.updateAllFilteredElements(true);
    } else {
      throw new Error("Could not remove table because it was not found: " + visualTableId.toKey());
    }
  }

  public addAttribute(attribute: AddAttributeToViewPayload): VisualAttributeDefinition {
    let retVal: VisualAttributeDefinition = null;

    if (this.canAddAttributeHeader(new AttributeId(attribute.visualAttributeId.visualTableId.tableId, attribute.visualAttributeId.attributeName))) {
      const visualChartColumn: VisualChartColumn = this.getVisualTable(attribute.visualAttributeId.visualTableId);

      if (visualChartColumn != null) {
        const attName = attribute.visualAttributeId.attributeName;
        const attributeDefinition: AttributeDefinition = modelStore.getAttributeDefinition(visualChartColumn.id.tableId, attName);
        const dx = visualChartColumn.allAttributeNames.length === 0 ? 0 : DiagramVisualConstants.DEFAULT_TABLE_WIDTH;

        let x = attribute.requestedCoords.x;
        if (attribute.addToRight && visualChartColumn.allAttributeNames.length !== 0) {
          x = visualChartColumn.width + visualChartColumn.x;
        }

        retVal = new VisualAttributeDefinition(
            new VisualAttributeId(visualChartColumn.id, attName),
            attributeDefinition,
            attName,
            attName,
            x,
            this.getAttributeHeaderYPos(),
            DiagramVisualConstants.DEFAULT_TABLE_WIDTH,
            DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT);

        visualChartColumn.addAttributeColumn(retVal);

        this.moveColumnsRightOfColumn(visualChartColumn.id, dx);
      }
    }

    return retVal;
  }

  public removeAttribute(visualAttributeId: VisualAttributeId): void {
    const table = this.getVisualTable(visualAttributeId.visualTableId);
    if (table != null) {
      const attribute = table.getAttribute(visualAttributeId.attributeName);
      const baseWidth = table.allAttributeNames.length === 1 && visualAttributeId.attributeName === table.allAttributeNames[0] ? DiagramVisualConstants.DEFAULT_TABLE_WIDTH : 0;
      const dx = baseWidth - attribute.header.width;
      table.removeAttributeColumn(visualAttributeId.attributeName);

      // remove attribute values in visual elements
      Array.from(table.visualElements.values()).forEach(visualElement => visualElement.attributeValues.delete(visualAttributeId.attributeName));

      const viewerFiltersToDelete: VisualAttributeIdString[] = [];
      this.viewerFilters.forEach((value, key: VisualAttributeIdString, map) => {
        if (key === visualAttributeId.toKey())
          viewerFiltersToDelete.push(key);
      });
      viewerFiltersToDelete.forEach((visualAttributeIdString: VisualAttributeIdString) => {
        this.viewerFilters.delete(visualAttributeIdString);
      });

      this.updateAllFilteredElements(false);
      this.synchronizeConditionalFormats(visualAttributeId);
      this.moveColumnsRightOfColumn(table.id, dx);
    } else {
      throw new Error("Could not remove attribute because table was not found: " + visualAttributeId.toKey());
    }
  }

  private moveColumnsRightOfColumn(visualTableId: VisualTableId, dx: number): void {
    const table = this.getVisualTable(visualTableId);
    let ids = [];
    this.chartTableColumns.forEach((value, key) => {
      if (value.x > table.header.x) {
        ids.push(value.id);
      }
    });
    ids.forEach(id => {
      this.moveTableColumn(id, dx, 0);
    });
  }

  /**
   * @return true if anything changed
   */
  public updateAllFilteredElements(forceAutolayout: boolean): void {
    // cannot be done in ViewModel since autolayout cannot be done on view model
    let updated: boolean = false;

    this._filterTextValidityByVisualAttributeIdString.clear();

    if (this.viewerFilters.size > 0 || this.showConnectedOnly) {
      updated = this.applyFilter(updated);
    } else {
      this.chartTableColumns.forEach(table => {
        table.visualElements.forEach(element => {
          const matchUpdated = !element.visible;
          element.visible = true;
          updated = updated || matchUpdated;
        });
      });
    }

    // all changes affecting filters might affect autolayout even if filter state did not change
    if ((updated || forceAutolayout) && this._autoLayoutOptions && this._autoLayoutOptions.autolayout) {
      // autolayout graph after filtering
      this.autolayout();
    }
  }

  /** create new visual element at the given position for the given table and NodeId */
  newVisualElement(tableId: TableId, nodeId: ElementId, name: string, ys: number | Map<VisualTableId, number>): void {
    const tables: VisualChartColumn[] = this.getVisualTablesForTable(tableId);

    tables.forEach(table => {
      let y: number;

      if (ys && ys instanceof Map && ys.has(table.id)) {
        y = ys.get(table.id);
      } else {
        y = ys ? ys as number : this.nextPosition(table);
      }

      // create visual node
      // const gnode = new VisualElement(table, new VisualNodeId("" + nodeId), name, y - DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT / 2, DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT);
      const gnode = new VisualChartElement(table, new VisualElementId("" + nodeId), name, y, DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT);
      table.addNode(gnode);
      // TODO set styles
      // and empty values for visual attributes
      table.visualAttributeDefinitions.forEach(att => {
        const value = att.header.name === NAME_ATT_NAME ? name : undefined;
        this.createAttributeValues(att, [gnode], [value]);
      });
    });

    this.updateAllFilteredElements(true);
  }

  public duplicateElements(originalElementIds: ElementId[], newElementIds: ElementId[], lastY: number) {
    const distanceBetweenElements: number = nextYPosition(0, 1);

    const maxYByVisualTableId: Map<VisualTableId, number> = new Map<VisualTableId, number>();
    const yMoveDistanceByTableId: Map<VisualTableId, number> = new Map<VisualTableId, number>();

    // collect y position infos of visual elements
    originalElementIds.forEach((originalElementId: ElementId) => {
      const visualElements: VisualBaseElement[] = this.getVisualElementsByElementId(originalElementId);

      visualElements.forEach((visualElement: VisualBaseElement) => {
        const parentVisualTable: VisualBaseTable = visualElement.visualTable;
        // const y: number = visualElement ? visualElement.y + visualElement.height : null;
        const y: number = visualElement ? visualElement.y : null;

        if (y != null) {
          if (maxYByVisualTableId.has(parentVisualTable.id) && y > maxYByVisualTableId.get(parentVisualTable.id)) {
            maxYByVisualTableId.set(parentVisualTable.id, y);
          } else if (!maxYByVisualTableId.has(parentVisualTable.id)) {
            maxYByVisualTableId.set(parentVisualTable.id, y);
          }

          if(yMoveDistanceByTableId.has(parentVisualTable.id)) {
            yMoveDistanceByTableId.set(parentVisualTable.id, yMoveDistanceByTableId.get(parentVisualTable.id) + distanceBetweenElements);
          } else {
            yMoveDistanceByTableId.set(parentVisualTable.id, distanceBetweenElements);
          }
        }
      });
    });

    // Move current visual elements under last y per visual table
    const visualElements: VisualBaseElement[] = Array.from(this.visualElements.values());

    visualElements.forEach((visualElement: VisualBaseElement) => {
      const visualBaseTable: VisualBaseTable = visualElement.visualTable;
      const visualTableId: VisualTableId = visualBaseTable.id;
      if (maxYByVisualTableId.has(visualTableId)) {
      const yForCurrentVisualElement = visualElement.y;
      if(yForCurrentVisualElement > maxYByVisualTableId.get(visualTableId)) {
        visualElement.y += yMoveDistanceByTableId.get(visualTableId);
      }
    }
    });

    newElementIds.forEach((newElementId: ElementId) => {
      const parentTableId: TableId = modelStore.getTableForElement(newElementId);
      const parentVisualTableIds: VisualTableId[] = this.getVisualTablesForTable(parentTableId).map((visualChartColumn: VisualChartColumn) => {
        return visualChartColumn.id;
      });

      const ys: Map<VisualTableId, number> = new Map<VisualTableId, number>();

      parentVisualTableIds.forEach((parentVisualTableId: VisualTableId) => {
        if (maxYByVisualTableId.has(parentVisualTableId)) {
          ys.set(parentVisualTableId, maxYByVisualTableId.get(parentVisualTableId) + distanceBetweenElements);
          maxYByVisualTableId.set(parentVisualTableId, maxYByVisualTableId.get(parentVisualTableId) + distanceBetweenElements);
        }
      });

      this.newVisualElement(parentTableId, newElementId, modelStore.getElement(newElementId)["name"], ys);
    });
  }

  public autolayout(options?: AutoLayoutOptions, isDoLayout: boolean = true): void {
    log.debug("Autolayout", options);
    let optionsUsed = options;
    if (options) {
      this._autoLayoutOptions = options;
    } else {
      // no options passed => use saved options
      Validate.isDefined(this._autoLayoutOptions, "Graph.autolayout must not be called without parameter if there are no saved autolayout options");
      optionsUsed = this._autoLayoutOptions;
    }
    if (isDoLayout || (this._autoLayoutOptions && this._autoLayoutOptions.autolayout)) {
      const autolayoutOptionProperties = this.autoLayoutProperties;
      const leftTables = autolayoutOptionProperties.leftTables.map(vidKey => this.getVisualTable(VisualTableId.fromKey(vidKey)));
      const rightTables = autolayoutOptionProperties.rightTables.map(vidKey => this.getVisualTable(VisualTableId.fromKey(vidKey)));
      layoutHierarchy(leftTables, this.connections, this.getFirstFreeYPosition(), optionsUsed);
      layoutHierarchy(rightTables, this.connections, this.getFirstFreeYPosition(), optionsUsed);

      // MO-1406 center both autolayout trees against each other
      const leftMin: number = determineExtremeValue(leftTables, Math.min, Infinity);
      const leftMax: number = determineExtremeValue(leftTables, Math.max, -Infinity);
      const rightMin: number = determineExtremeValue(rightTables, Math.min, Infinity);
      const rightMax: number = determineExtremeValue(rightTables, Math.max, -Infinity);
      const leftDelta = leftMax - leftMin;
      const rightDelta = rightMax - rightMin;

      let columnsToAdjust;
      let delta;
      let side;
      if (leftDelta > rightDelta) {
        columnsToAdjust = rightTables;
        delta = leftMin - rightMin + (leftDelta - rightDelta) / 2;
        side = "right";
      } else {
        columnsToAdjust = leftTables;
        delta = rightMin - leftMin + (rightDelta - leftDelta) / 2;
        side = "left";
      }
      log.info("Delta to move " + side + " tree: " + delta);
      columnsToAdjust.forEach(vc => vc.elements.forEach(ve => ve.y += delta));
      this.animationCount++;
    }
  }

  // table must already be part of the chart, otherwise the attribute can not be added
  private canAddAttributeHeader(attributeId: AttributeId): boolean {
    return this.getFirstVisualTableForTable(attributeId.tableId) !== undefined;
  }

  /** find all visual nodes for a given node, might be multiple */
  getVisualNodesForNode(nodeId: ElementId): VisualChartElement[] {
    const result: VisualChartElement[] = [];
    this.chartTableColumns.forEach(table => {
      const node = table.getNodeForId(nodeId);
      if (node) {
        result.push(node);
      }
    });
    return result;
  }

  protected adjustNodeAndAttributePositions(): void {
    const attributeHeaderSpace: number = this.getAttributeHeaderSpace();
    const realDelta: number = attributeHeaderSpace - this.lastDelta;
    this.chartTableColumns.forEach((t: VisualChartColumn) => {
      t.setHeaderRotation(this.attributeHeaderRotation, this.attributeHeaderRotation === 0 ? DiagramVisualConstants.TABLE_HEADER_ATTRIBUTE_HEADER_HEIGHT : this.attributeHeaderHeight);
      t.visualElements.forEach((value: VisualChartElement) => {
        value.y = value.y + realDelta;
      });
    });
    this.lastDelta = attributeHeaderSpace;
  }

  /**
   * @returns {number} height of full attribute header including header height itself, gap to filter line, filter line height, another gap
   */
  private getAttributeHeaderSpace(): number {
    return (this.attributeHeaderRotation === 0 ? DiagramVisualConstants.TABLE_HEADER_ATTRIBUTE_HEADER_HEIGHT : this.attributeHeaderHeight) + 2 * DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH + DiagramVisualConstants.TABLE_HEADER_FILTER_HEADER_HEIGHT;
  }

  /**
   * @returns {number} position the first node can be drawn, this is under the filter line of the attribute header and depends on the attribute rotation and header size
   */
  private getFirstFreeYPosition(): number {
    return this.getAttributeHeaderYPos() + this.getAttributeHeaderSpace() + DiagramVisualConstants.HEADER_ELEMENT_VERTICAL_GAP;
  }

  private createAttributeValues(attribute: VisualAttributeDefinition, nodes: Array<VisualChartElement>, values: Array<any>): void {
    log.debug("Adding attribute Values to attribute", attribute, values);
    for (let i = 0; i < nodes.length; i++) {
      const node: VisualChartElement = nodes[i];
      if (node) {
        node.addAttributeValue(attribute, values[i]);
        if ("name" === attribute.header.name) {
          node.title = values[i];
        }
      } else {
        log.warn("Node not found for attribute " + attribute.header.name + " at attribute values index " + i);
      }
    }
  }

  toggleConnections(sourceElementIds: ElementId[], targetElementId: ElementId): void {
    sourceElementIds.forEach(sourceElementId => {
      let index = this.findEdgeIndex(sourceElementId, targetElementId);
      if (index < 0) {
        const connection: Connection = {sourceElementId, targetElementId, strength: 1};
        this.addVisualConnections([connection]);
      } else {
        // remove all edges if table is more than one time in chart
        while (index >= 0) {
          this.connections.splice(index, 1);
          index = this.findEdgeIndex(sourceElementId, targetElementId);
        }
      }
    });
    this.updateAllFilteredElements(true);
  }

  private applyFilter(updated: boolean): boolean {
    // TODO calculate update state correctly
    const result: boolean = true;
    let filteredElementsIds: ElementId[];
    try {
      const elementGraph = this.buildElementGraph();

      const [parsedFilters, valid] = this.buildFilterTextCheckerResult(elementGraph);

      filteredElementsIds = filter(parsedFilters, elementGraph, ViewType.Chart);
    } catch (ex) {
      // in error case show all elements
      console.log("Error evaluating filter: ", ex);
      const visualBaseElements = Array.from(this.visualElements.values());
      filteredElementsIds = Array.from(new Set<ElementId>(visualBaseElements.map(ve => ve.id.elementId)));
      // flag all filters as wrong since we dont know what the problem is
      this.viewerFilters.forEach((value: string, key: VisualAttributeIdString) => {
        this._filterTextValidityByVisualAttributeIdString.set(key, false);
      });
    }
    filteredElementsIds.forEach(
        elementId => this.getVisualNodesForNode(elementId).forEach(
            (element: VisualChartElement) => element.visible = true));
    return result;
  }

  private buildElementGraph(): HierarchicalElementGraph {
    const nodes: VisualChartElement[] = [];

    // reset match state
    this.chartTableColumns.forEach(t => {
      t.visualElements.forEach(n => {
        n.visible = false;
      });
      nodes.push(...Array.from(t.visualElements.values()));
    });

    const tables = Array.from(this.chartTableColumns.values()).sort((t1, t2) => t1.header.x - t2.header.x);

    const levelByTableId: Map<TableId, number> = new Map();
    let level: number = 0;
    tables.forEach((t, i) => {
      // fix MO-433 with same table multiple times in view
      if (!levelByTableId.has(t.id.tableId)) {
        levelByTableId.set(t.id.tableId, level++);
      }
    });

    let elementIds = nodes.map(n => n.id.elementId);

    elementIds = this.filterConnectedElements(elementIds);

    const result = new HierarchicalElementGraph(levelByTableId, elementIds);
    return result;
  }

  private filterConnectedElements(elementIds: ElementId[]): ElementId[] {
    if (!this.showConnectedOnly) {
      return elementIds;
    } else if (modelStore.secondarySelection.size === 0) {
      return [];
    }

    if (this.id !== viewManagerRegistry.viewManager.activeViewId) {
      this._connectedElementIds = Array.from(modelStore.secondarySelection);
    }

    return elementIds.filter(elementId => {
      if (this._connectedElementIds.includes(elementId)) {
        return true;
      }
    });
  }

  private buildFilterTextCheckerResult(elementGraph: HierarchicalElementGraph): [FilterTextCheckerResult[], boolean] {
    const retVal: FilterTextCheckerResult[] = [];
    let valid: boolean = true;

    this.viewerFilters.forEach((filterString, attId: VisualAttributeIdString) => {
      const data: HierarchicalFilterData =
          new HierarchicalFilterData(
              VisualAttributeId.fromKey(attId).attributeName,
              filterString,
              elementGraph,
              ViewType.Chart,
              VisualAttributeId.fromKey(attId).visualTableId.tableId);

      const filter = processFilterText(data);
      retVal.push(filter);
      this._filterTextValidityByVisualAttributeIdString.set(attId, filter.isValid);
      valid = valid && filter.isValid;
    });

    return [retVal, valid];
  }

  /**
   * find all visual elements for the matching node and delete them from the graph
   * @param {ElementId} nodeIds
   */
  deleteVisualElements(...nodeIds: ElementId[]): void {
    // delete nodes
    const deletedNodes: VisualElementId[] = [];
    nodeIds.forEach(id => {
      const visualElements = this.getVisualNodesForNode(id);
      visualElements.forEach(gNode => {
        gNode.visualTable.removeNodeForId(id);
        gNode.visualTable.visualElements.delete(gNode.id.toKey());

        deletedNodes.push(gNode.id);
      });
    });

    // and edges
    const indexesToBeRemoved: number[] = [];
    this.connections.forEach((e, idx) => {
      if (deletedNodes.indexOf(e.source.id) >= 0 || deletedNodes.indexOf(e.target.id) >= 0) {
        indexesToBeRemoved.push(idx);
      }
    });
    while (indexesToBeRemoved.length > 0) {
      this.connections.splice(indexesToBeRemoved.pop(), 1);
    }

    // updating filters
    this.updateAllFilteredElements(true);
  }

  /** find a connection independent of its direction */
  findEdgeIndex(sourceElementId: ElementId, targetElementId: ElementId): number {
    return this.connections.findIndex((e: VisualConnection) => (e.source.id.elementId === sourceElementId && e.target.id.elementId === targetElementId) || (e.source.id.elementId === targetElementId && e.target.id.elementId === sourceElementId));
  }

  onConnectedToSelectionStateChange(newState: boolean): void {
    // selection state changes --> only update visibility, not attribute values
    if (newState !== undefined) {
      // toggled on or off ==> update
      this.updateAllFilteredElements(true);
    } else if (this.showConnectedOnly) {
      // if current selection changes, only update if show connected mode enabled
      this.updateAllFilteredElements(true);
    }
  }

  /**
   * recalculates conditional formats for all attribute values for the given visual attribute definition
   * @param {ConditionalFormat[]} conditionalFormats conditional formats to update
   * @param {VisualAttributeId} visualAttributeId
   */
  protected updateConditionalFormats(conditionalFormats: ConditionalFormat[], visualAttributeId: VisualAttributeId): void {
    const vAttDef: VisualAttributeDefinition = this.getVisualAttributeDefinition(visualAttributeId);
    const visualTable: VisualBaseTable = this.getVisualTable(visualAttributeId.visualTableId);
    const matcher: SimpleMatcher[] = conditionalFormats.map(cf => getSimpleMatcher(cf.filterExpression, cf.operand.visualTableId.tableId, cf.operand.attributeName));
    visualTable.elements.forEach((visualElement: VisualChartElement | VisualValueChartElement) => {
      // value to be formatted
      const visualAttributeValue = visualElement.attributeValues.get(vAttDef.id.attributeName);
      if (visualAttributeValue) {
        let conditionalStyles = undefined;
        for (let i = 0; i < matcher.length; i++) {
          if (matcher[i].matches(visualElement.id.elementId)) {
            conditionalStyles = conditionalFormats[i].styles;
            break;
          }
        }
        visualAttributeValue.conditionalStyles = conditionalStyles;
      }
    });
  }

  private nextPosition(table: VisualChartColumn): number {
    let basePosition: number = this.getFirstFreeYPosition();
    const allYValues: number[] = Array.from(table.visualElements.values()).map(node => node.y);
    if (Array.isArray(allYValues) && allYValues.length > 0) {
      basePosition = _.max(allYValues);
    }
    return nextYPosition(basePosition, 1);
  }


  /**
   * #3685 truncate name header if overlapping others
   * @param header to truncate
   * @param attributes attributes to walk through for truncating name
   * @return header width, either unchanged or truncated
   */
  private getTruncateNameAttributeWidth(header: VisualHeader, attributes: VisualAttributeDefinition[]): number {
    if (header.name === "name") {
      // calculate minimum x-pos of other headers bigger than x-pos of name attribute
      let trunc = header.x + header.width;
      attributes.forEach((gAttribute: VisualAttributeDefinition) => {
        if (gAttribute.header.x > header.x) {
          trunc = Math.min(trunc, gAttribute.header.x);
        }
      });
      if (trunc < header.x + header.width) {
        return trunc - header.x - 1;
      }
    }
    return header.width;
  }

  /**
   * recalculates conditional formats for all attribute values and visual tables of the given table
   * @param {TableId} tableId
   */
  private updateConditionalFormatsForTable(tableId: TableId): void {
    const visualTables: VisualBaseTable[] = this.getVisualTablesForTable(tableId);
    visualTables.forEach(vt => {
      vt.visualAttributeDefinitions.forEach(vattDef => this.updateConditionalFormats(vattDef.conditionalFormats, vattDef.id));
    });
  }

  addConnections(connections: Connection[]): void {
    this.addVisualConnections(connections);
    this.updateAllFilteredElements(true);
  }

  deleteConnections(connections: Connection[]): void {
    const twoElementKeys = new Set(connections.map(c => getTwoElementKey(c.sourceElementId, c.targetElementId)));
    // remove all connections with same source/target id as one in the array or reversed
    const filteredConnections = this.connections.filter(c => !(twoElementKeys.has(getTwoElementKey(c.source.id.elementId, c.target.id.elementId)) || twoElementKeys.has(getTwoElementKey(c.target.id.elementId, c.source.id.elementId))));
    this.connections = filteredConnections;
  }

  sortChartColumn(visualAttributeId: VisualAttributeId, ascending: boolean): void {
    const visualChartColumn = this.getVisualTable(visualAttributeId.visualTableId);
    if (visualChartColumn != null) {
      const visualElements: VisualChartElement[] = Array.from(visualChartColumn.visualElements.values());
      // compare by attribute value and comparator suited for attribute type
      const comparator = _.partialRight(chartElementComparator, this.getVisualAttributeDefinition(visualAttributeId), visualAttributeId);

      visualElements.sort(ascending ? comparator : negate(comparator));
      let y = this.getFirstFreeYPosition();
      visualElements.forEach(ve => {
        ve.y = y;
        y += DiagramVisualConstants.DEFAULT_GAP_BETWEEN_ELEMENTS + ve.height;
      });
    }
  }
}

