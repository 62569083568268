/* DisabledViewComponent.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Klaus.Freiberger, März 2022
 */

import React from "react";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";

const styles = (theme: Theme): StyleRules => createStyles({
  disabledViewContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
});

type StyledLocalProps = WithStyles<typeof styles>;

const DisabledViewComponent = (props: StyledLocalProps) => {
  return <div className={props.classes.disabledViewContainer}>This view was edited with a newer incompatible version of
    Metus, so you are not allowed to open it.</div>;
}

export default withStyles(styles)(DisabledViewComponent);