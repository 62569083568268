import {DiagramVisualConstants} from "../constants/DiagramVisualConstants";
import {getLevelDefaultColor} from "../../core/utils/LevelColorUtil";
import {SVGTextBoxComponent} from "../../common/components/SVGTextBoxComponent";
import * as React from "react";

export function EmptyTableHeaderComponent(props: { text: string, width: number, height: number }) {
  return <g>
    <rect width={props.width} height={props.height}
          rx={2 * DiagramVisualConstants.TABLE_HEADER_ROUNDED_ARC}
          ry={2 * DiagramVisualConstants.TABLE_HEADER_ROUNDED_ARC} style={{
      fill: getLevelDefaultColor(0, 1),
      stroke: "#808080",
      strokeDasharray: 3
    }}/>
    <SVGTextBoxComponent text={props.text} width={props.width}
                         height={DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + 2 * DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH}
                         wrapLines={false}/>
  </g>;
}