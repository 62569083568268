// lib imports
import * as React from "react";
import Log from "../../common/utils/Logger";
import {modelSelectionStore} from "../../modelselection/stores/ModelSelectionStore";
import {
  acquireWriteLock,
  loadInitialModelData,
  logout,
  unlock
} from "../../modelselection/actions/ModelAsyncActionCreators";
import {createStyles, Theme, Typography, WithStyles, withStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import User from "../../common/icons/header/User";
import Logo from "../../common/icons/header/Logo";
import {Cached, Edit, EditOutlined, ExitToApp, NotInterested} from "@material-ui/icons";
import {observer} from "mobx-react";
import autobind from "autobind-decorator";
import {modelStore} from "../../core/stores/ModelStore";
import LinearProgress from "@material-ui/core/LinearProgress";
import {interactionStore} from "../../common/stores/InteractionStore";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import {CommitResultType, CommitStrategy, LockedType} from "../../api/api";
import {showOverwriteWriteLockDialog} from "./OverwriteWriteLockDialog";
import PropertiesButton from "../../properties/components/PropertiesButton";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {ResetModelLocationAction} from "../../modelselection/actions/ModelSelectionActions";
import {configurationStore} from "../../core/stores/ConfigurationStore";
import {loadWorkspaces} from "../../core/services/CoreDataServices";
import StyledChooseWorkspaceDialog from "./ChooseWorkspaceDialog";
import {showDialog} from "../../common/utils/CommonDialogUtil";
import {ModelLocationType} from "../../common/constants/Enums";
import {showMergeDialog} from "./MergeDialog";

const log = Log.logger("HeaderBarContainer");

const styles = (theme: Theme): StyleRules => createStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
    alignItems: "center",
    backgroundColor: theme.metus.main.fill,
    color: theme.metus.main.primaryText,
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    position: "relative",
    alignItems: "center",
  },
  // Logo 'Metus Online & Progress Bar'
  logoContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
  },
  logo: {
    marginLeft: 20,
    width: 130,
  },
  progress: {
    marginLeft: "20px",
    width: "130px",
    height: "2px",
    borderRadius: "1em",
  },
  progressColorPrimary: {
    backgroundColor: theme.metus.main.progressColor,
  },
  barColorPrimary: {
    backgroundColor: theme.metus.main.progressBarColor,
  },
  powerViewer: {
    marginLeft: 20,
    font: "inherit"
  },

  rightContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    flexWrap:"wrap",
    alignItems: "center",
    paddingRight: "20px",
  },
  button: {
    color: theme.metus.main.primaryText,
    display: "flex",
    paddingRight: "10px",
  },
  projectName: {
    paddingRight: "20px",
  },
  userSettings: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingRight: "20px",
  },
  userName: {
    paddingLeft: "5px",
  },
  userContextMenu: {
    display: "flex",
  },
  userContextMenuContainer: {
    zIndex: 50
  },
  userContextMenuTextItem: {
    paddingLeft: "8px",
  },
  writeLockSettings: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  iconButtonRoot: {
    minWidth: "70px",
    borderRadius: 5,
    padding: 4,
    marginRight: 5,
    // same background color like bar, should only be visible when hovering
    backgroundColor: theme.metus.navigation.headerBarIconButton,
    variant: "outlined",
    fontSize: "14px",
    color: theme.metus.main.fill,
    "&:hover": {
      // color: theme.metus.navigation.active.fill,
      backgroundColor: theme.metus.navigation.active.secondaryFill
    }
  },
  editNotInterested: {
  },

});


interface LocalProps {
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

// Since version 2.13.0 ContextMenuTriggerProps contains mouseButton
// which is not declared in typescript
const FixedContextMenuTrigger = ContextMenuTrigger as any;

/**
 * create an editor title and a menu bar, menu entries are added as children
 * @author Marco van Meegen
 */


@observer
class HeaderBarContainer extends React.Component<StyledLocalProps, undefined> {
  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    const userMenuId = "userMenu";

    // works for both multiuser modes
    const showEditing = configurationStore.writeLock.locked === LockedType.Self;
    // only makes sense for multiuserMode lock, in merge mode one is allowed edit another workspace;
    const showEditingOther = configurationStore.isLockedFromOtherUser();
    // discard editing makes only sense in merge mode
    const showDiscardEditing = configurationStore.modelMeta?.multiuserMode === "merge" && configurationStore.writeLock.locked === LockedType.Self;
    // for in-memory do not display a project name because it is not available in the client
    const selectedModel = (modelSelectionStore && modelSelectionStore.selectedModel && modelSelectionStore.selectedModel.name && modelSelectionStore.selectedModelLocation !== ModelLocationType.memory)
        ? modelSelectionStore.selectedModel.name : "";
    return <React.Fragment>
      <div className={this.props.classes.root}>
        <div className={this.props.classes.leftContainer}>
          <div className={this.props.classes.logoContainer}>
            <Logo classes={{root: this.props.classes.logo}} onClick={this.resetModelLocation}/>
            {interactionStore.isLoading ?
                <LinearProgress
                    classes={{
                      root: this.props.classes.progress,
                      colorPrimary: this.props.classes.progressColorPrimary,
                      barColorPrimary: this.props.classes.barColorPrimary
                    }}
                    color="primary"/>
                : undefined}
          </div>
          <div>
            {!configurationStore.canWriteToServer() &&
            <Typography variant="h6" color="inherit" className={this.props.classes.powerViewer}>power
              viewer</Typography>
            }
          </div>
        </div>

        <div className={this.props.classes.rightContainer}>
          {/*
          {modelSelectionStore.isLiveServer() ?
              <Button className={this.props.classes.button}
                      onClick={this.handleOpenAppSelectionDialog}>Apps
              </Button> : null
          }
*/}

          <div className={this.props.classes.projectName}>
            <Typography variant="h6" color="inherit"
                        classes={{root: this.props.classes.title}}>{selectedModel}</Typography>
          </div>

          {modelSelectionStore.selectedModelLocation === ModelLocationType.server &&
          <div className={this.props.classes.userSettings}>
            <div className={this.props.classes.userName}>
              <span className={this.props.classes.user}>{modelSelectionStore.user}</span>
            </div>
            {/* User Icon */}
            <div>
              <FixedContextMenuTrigger id={userMenuId} mouseButton={0}><User/></FixedContextMenuTrigger>
              <ContextMenu id={userMenuId}>
                <MenuItem onClick={this.logout}
                          attributes={{className: this.props.classes.userContextMenu} as any}><ExitToApp/><Typography
                    classes={{root: this.props.classes.userContextMenuTextItem}}>Log Out</Typography>
                </MenuItem>
              </ContextMenu>
            </div>
          </div>
          }

          {modelSelectionStore.isLiveServer() && modelSelectionStore.selectedModel &&
          <div className={this.props.classes.writeLockSettings}>
            <PropertiesButton classes={{button: this.props.classes.iconButtonRoot}} onClick={this.toggleWriteLock} data-testselector={this.getLockType()}>
              {showEditingOther &&  <NotInterested classes={{root: this.props.classes.editNotInterested}}/>}
              {showEditing ? <Edit/> : <EditOutlined/>}
            </PropertiesButton>
            {showDiscardEditing && <PropertiesButton classes={{button: this.props.classes.iconButtonRoot}} onClick={this.cancelWriteLock}>
              <NotInterested classes={{root: this.props.classes.editNotInterested}} data-testselector={"LoseLockAndDiscard"}/>
            </PropertiesButton>}
            {modelStore.modelDirty ?
                <PropertiesButton classes={{button: this.props.classes.iconButtonRoot}}
                                  onClick={this.reloadModel}><Cached/>
                </PropertiesButton> : null}
          </div>
          }
        </div>
      </div>
    </React.Fragment>
  }

  @autobind
  private resetModelLocation(): void {
    if (modelSelectionStore.selectedModelLocation !== ModelLocationType.memory) {
      Dispatcher.dispatch(new ResetModelLocationAction());
    }
  }

  @autobind logout(): void {
    logout(modelSelectionStore.selectedModelLocation);
  }

  @autobind
  private async toggleWriteLock() {
    if (configurationStore.writeLock.locked === LockedType.Self) {
      const commitResult = await unlock(CommitStrategy.CommitIfConflictFree);
      if (commitResult?.type === CommitResultType.ConflictDescription) {
        showMergeDialog(true, commitResult.conflicts)
      }
    } else if (configurationStore.writeLock.locked === LockedType.Other && configurationStore.modelMeta.multiuserMode === "lock") {
      showOverwriteWriteLockDialog(true);
    } else {
      if (configurationStore.modelMeta.multiuserMode === "lock") {
        acquireWriteLock(configurationStore.writeLock.locked === LockedType.Other);
      } else {
        loadWorkspaces().then(result =>
            showDialog(true, <StyledChooseWorkspaceDialog open={true} workspaces={result}/> )
        )
      }
    }
  }

  @autobind
  private cancelWriteLock() {
    unlock(CommitStrategy.Discard);
  }

  @autobind
  private getLockType() {
    const locked = configurationStore.writeLock.locked;
    if (locked === LockedType.Self){
      return "LockedSelf"
    } else if (locked === LockedType.None){
      return "LockedNone"
    } else if (locked === LockedType.Other){
      return "LockedOther"
    } else {
      return "UndefinedLockStatus"
    }
  }

  @autobind
  private reloadModel() {
    const modelInfo = modelStore.modelInfo;
    loadInitialModelData(modelInfo);
  }

  /*
    @autobind
    private handleOpenAppSelectionDialog(): void {
      showAppSelectionDialog(true);
    }
  */
}

export default withStyles(styles)(HeaderBarContainer);
