let emptyImage = void 0;

export function getEmptyImage(): JSX.Element {
  if (!emptyImage) {
    if (typeof Image === "undefined") {
      // hack for tests, use jsdom window.Image
      emptyImage = new (window as any).Image();
    } else {
      emptyImage = new Image();
    }
    emptyImage.src = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
  }

  return emptyImage;
}

/**
 * @param {string} path of relative branding image
 * @param {boolean} isDefault if true, get branded image path, if false, get bundled default image
 * @returns {string} path relative to images directory if isDefault=false, otherwise relative to /branding url which will be served by rest server
 */
export function getBrandedImagePath(path: string, isDefault: boolean): string {
  return isDefault ? require("../images/" + path): "/webapp/branding/" + path;
}