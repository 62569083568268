/**
 * Calculates Text Sizes for SVG Text
 * abstracted for tests and for svg/html size calculation
 * Created by MeegenM on 09.02.2017.
 *
 */

export interface TextSizer {
  /** activates sizer with given style, a secret svg text is created for measurement */
  activate(svgTextStyle: any): void;
  calculateWidth(text: string): number;
  /** deactivates size, secret svg text is removed */
  deactivate(): void;
  lineHeight(): number;
}

export class SVGTextSizer {
  private svg: SVGSVGElement;
  private text: SVGTextElement;
  private _lineHeight: number;

  constructor() {
  }

  public activate(svgTextStyle: any): void {
    // create secret svg to measure text
    this.svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    this.text = document.createElementNS("http://www.w3.org/2000/svg", "text");
    Object.assign(this.text.style, svgTextStyle);
    this.svg.appendChild(this.text);
    document.body.appendChild(this.svg);
    this.text.textContent = "T";

    if (isFunction(this.text.getBBox)) {
      this._lineHeight = this.text.getBBox().height;
    }
  }

  //noinspection JSUnusedGlobalSymbols
  public calculateWidth(text: string): number {
    this.text.textContent = text;
    if (isFunction(this.text.getComputedTextLength)) {
      return this.text.getComputedTextLength();
    }
    else {
      return 0;
    }
  }

  //noinspection JSUnusedGlobalSymbols
  public deactivate(): void {
    document.body.removeChild(this.svg);
  }

  //noinspection JSUnusedGlobalSymbols
  public lineHeight(): number {
    return this._lineHeight;
  }
}

function isFunction(functionToCheck: any): boolean {
  const getType = {};
  return functionToCheck && getType.toString.call(functionToCheck) === "[object Function]";
}
