import {
  ElementId,
  ElementObject,
  SelectionKind,
  TableId,
  ViewId,
  VisualAttributeId,
  VisualAttributeIdString,
  VisualElementId,
  VisualElementIdString,
  visualId,
  VisualId,
  VisualTableId,
  VisualTableIdString
} from "../../core/utils/Core";
import Log from "../../common/utils/Logger";
import {ViewType} from "../../common/constants/Enums";
import {VisualAttributeDefinition} from "./common/VisualAttributeDefinition";
import {BoundedVisualObjectBase, VisualObject, VisualObjectBase} from "./VisualObject";
import {VisualConnection} from "./chart/VisualConnection";
import {
  ChartAttributeData,
  ChartTableData,
  Connection,
  DiagramData,
  FilterData,
  LayoutAlgorithm,
  RotationDegree,
  VisualElementEntry, VisualTextBoxData,
  VisualValueChartData
} from "../../api/api";
import {VisualChartColumn} from "./chart/VisualChartColumn";
import {VisualValueChart} from "./valuechart/VisualValueChart";
import {VisualChartElement} from "./chart/VisualChartElement";
import {AutoLayoutOptions} from "../utils/autolayout/AutoLayoutOptions";
import {Direction, DirectionHelper} from "../../common/utils/Direction";
import {Validate} from "../../common/utils/Validate";
import {VisualValueChartLevel} from "./valuechart/VisualValueChartLevel";
import {Rectangle} from "../../common/utils/Geometry";
import {IVisitable, VisitState} from "../../common/utils/Visitor";
import {ConditionalFormat} from "../../commonviews/models/ConditionalFormat";
import {
  isVisualBaseElement,
  isVisualBaseTable,
  resizeAttributeInTable,
  VisualBaseElement,
  VisualBaseTable
} from "./common/CommonDiagramTypes";
import {DiagramVisualConstants} from "../../commonviews/constants/DiagramVisualConstants";
import {computed, observable} from "mobx";
import {modelStore} from "../../core/stores/ModelStore";
import {getFilterMatcher} from "../../core/utils/filter/FilterMatcher";
import shallowEqual from "shallowequal";
import {list, map, object, primitive, serializable, serialize,} from "serializr";
import {
  AddAttributeToViewPayload,
  AddTableToViewPayload,
  Coords
} from "../../commonviews/actions/SharedViewAsyncActions";
import {VisualTextBox} from "./common/VisualTextBox";
import {DeserializedModel} from "../../commonviews/models/DeserializedModel";
import {DiagramModelSerializer} from "./DiagramModelSerializer";

const log = Log.logger("model");

export abstract class DiagramModel extends VisualObjectBase<VisualObject, ViewId> implements DeserializedModel<DiagramData> {
  /**
   * number of objects allowed for efficient diagram rendering; bigger number will switch to virtualized mode
   */
  public static readonly VIRTUAL_RENDERING_THRESHOLD: number = 2000;

  @serializable @observable public id: ViewId;
  @serializable @observable public attributeHeaderRotation: RotationDegree = 0;
  @serializable @observable private _attributeHeaderHeight: number = DiagramVisualConstants.DEFAULT_ROTATED_HEADER_HEIGHT;
  /** last autolayout options used */
  @serializable(object(AutoLayoutOptions)) @observable protected _autoLayoutOptions: AutoLayoutOptions;
  /** TODO: replace string by parsed viewer filter for efficiency reasons */
  @serializable(map(primitive())) @observable protected _viewerFilters: Map<VisualAttributeIdString, string> = new Map();
  @serializable @observable isHeaderExpanded = true;
  @serializable(list(object(VisualTextBox))) @observable textBoxes: VisualTextBox[] = [];

  // TODO serializable ???
  @observable public connections: Array<VisualConnection>;
  @observable public type: ViewType;
  @observable public name: string;


  /**
   * each time this is increased, the render phase should use animations
   */
  @observable public animationCount: number = 0;
  @observable public showConnectedOnly: boolean = false;
  @observable protected _connectedElementIds: ElementId[] = [];
  @observable protected _filterTextValidityByVisualAttributeIdString: Map<VisualAttributeIdString, boolean> = new Map();
  /**
   * transient field, do not serialize, maintains a map of visual chart columns for fast access
   */
  @observable public visualChartColumns: Map<VisualTableIdString, VisualChartColumn>;

  protected constructor(id: ViewId, title: string, type: ViewType) {
    super(undefined, id);
    this.type = type;
    this.name = title;
    this.connections = [];
    this.visualChartColumns = new Map();
    this._autoLayoutOptions = new AutoLayoutOptions(LayoutAlgorithm.DAGRE, false, false);
  }

  abstract get valueChart(): VisualValueChart;

  /**
   * depending on the size of the diagram virtual rendering will be activated or not.
   * Formula used: (#Elements * #Attributes) + #Connections + other visual elements> VIRTUAL_RENDERING_THRESHOLD
   */
  @computed get virtualRenderingActive(): boolean {
    const complexity = this.complexity;
    const result = this.complexity > DiagramModel.VIRTUAL_RENDERING_THRESHOLD;
    log.debug("Diagram " + this.name + ".virtualRenderingActive = " + result + ", threshold: " + DiagramModel.VIRTUAL_RENDERING_THRESHOLD + ", diagram complexity = " + complexity);
    return result;
  }

  public get complexity(): number {
    let diagramObjectCount: number = this.visualTables.reduce((sum: number, visualTable) => sum + visualTable.elements.length * (visualTable.visualAttributeDefinitions.size + 1), 0);
    diagramObjectCount += this.connections.length;
    diagramObjectCount += this.children.filter(c => !isVisualBaseTable(c)).length;
    return diagramObjectCount;
  }

  /** attribute header height if rotated */
  @computed get attributeHeaderHeight(): number {
    return this._attributeHeaderHeight;
  }

  /** space to push attribute headers down if headers are rotated */
  set attributeHeaderHeight(newHeight: number) {
    this._attributeHeaderHeight = newHeight;
    this.adjustNodeAndAttributePositions();
  }

  /**
   * @return all visualisations of tables in this diagram
   */
  public get visualTables(): VisualBaseTable[] {
    const result: VisualBaseTable[] = Array.from(this.visualChartColumns.values());
    if (this.valueChart) {
      result.push(...this.valueChart.levels);
    }
    return result;
  }

  /**
   * @returns {TableId[]} list of table ids used in this diagram
   */
  public get tableIds(): TableId[] {
    return this.visualTables.map((t: VisualBaseTable) => t.id.tableId);
  }

  get autoLayoutOptions(): AutoLayoutOptions {
    return this._autoLayoutOptions;
  }

  public get viewerFilters(): Map<VisualAttributeIdString, string> {
    return this._viewerFilters;
  }

  public get filterTextsValidities(): Map<VisualAttributeIdString, boolean> {
    return this._filterTextValidityByVisualAttributeIdString;
  }

  public rotateAttributeHeaders(): void {
    switch (this.attributeHeaderRotation) {
      case 0:
        //   this.attributeHeaderRotation = 45;
        //   break;
        // case 45:
        this.attributeHeaderRotation = 90;
        break;
      default:
        // all other cases: set to 0
        this.attributeHeaderRotation = 0;
        break;
    }
    this.adjustNodeAndAttributePositions();
  }

  // noinspection JSUnusedGlobalSymbols
  public updateViewerFilterForAttribute(visualAttributeId: VisualAttributeId, filterExpression: string): void {
    if (filterExpression) {
      this.viewerFilters.set(visualAttributeId.toKey(), filterExpression);
      // validate filter
      const visualAttribute = this.getVisualAttributeDefinition(visualAttributeId, false);
      if (visualAttribute) {
        const matcher = getFilterMatcher(filterExpression, visualAttributeId.visualTableId.tableId, visualAttributeId.attributeName);
        this.filterTextsValidities.set(visualAttributeId.toKey(), !!matcher);
      } else {
        log.warn("Internal Error: Viewer Filter was updated but visual attribute not found");
      }
    } else {
      this.viewerFilters.delete(visualAttributeId.toKey());
      this.filterTextsValidities.delete(visualAttributeId.toKey());
    }
    this.updateAllFilteredElements(false);
  }

  /** y-pos of attribute header topmost position */
  protected getAttributeHeaderYPos(): number {
    return DiagramVisualConstants.TABLE_HEADER_YPOS + DiagramVisualConstants.TABLE_HEADER_TITLE_HEIGHT + DiagramVisualConstants.TABLE_HEADER_MARGIN_WIDTH;
  }

  protected init(valueChartDiagramData: VisualValueChartData): void {
  }

  public initFromSerializedAttDef(visualTableId: VisualTableId, attDef: ChartAttributeData): VisualAttributeDefinition {
    const result: VisualAttributeDefinition =
        new VisualAttributeDefinition(
            new VisualAttributeId(visualTableId, attDef.name),
            modelStore.getAttributeDefinition(visualTableId.tableId, attDef.name),
            attDef.name,
            attDef.displayName,
            attDef.x,
            attDef.y,
            attDef.width,
            attDef.height);

    if (attDef.conditionalFormats !== undefined) {
      attDef.conditionalFormats.forEach(cfData => {
        const condFormat = new ConditionalFormat(new VisualAttributeId(visualTableId, cfData.attributeName), cfData.filterExpression, cfData.styles);
        result.conditionalFormats.push(condFormat);
      });
    }
    return result;
  }

  public onAfterChildrenAdded(childVisuals: VisualObject[]): void {
    // maintain valueChart and visualChartColumns as shortcuts for searching visual children
    childVisuals.forEach(v => {
      if (v instanceof VisualChartColumn) {
        this.visualChartColumns.set(v.id.toKey(), v);
      }
    });
  }

  private readVisualElementsAsGNodes(gTable: VisualChartColumn, visualElements: VisualElementEntry[]): void {
    log.debug("Reading visual elements for table", gTable, visualElements);
    visualElements && visualElements.forEach(visualElement => {
          const [elementId, , y, , height] = visualElement;
          const gnode = new VisualChartElement(gTable, new VisualElementId("" + elementId), null, y, height);
          gTable.addNode(gnode);
        }
    );
  }

  public onBeforeChildrenDeleted(childVisuals: VisualObject[]): void {
    // maintain valueChart and visualChartColumns as shortcuts for searching visual children
    childVisuals.forEach(v => {
      if (v instanceof VisualValueChart) {
      } else if (v instanceof VisualChartColumn) {
        this.visualChartColumns.delete(v.id.toKey());
      }
    });
  }

  /**
   * @returns {VisualTableId[]} list of visual table ids used in this diagram
   */
  public visualTableIds(tableIds: TableId[]): VisualTableId[] {
    const allTableIds: TableId[] = this.tableIds;
    let allVisualTableIds: VisualTableId[] = [];
    for (const tableId of tableIds) {
      if (allTableIds.includes(tableId)) {
        const visualTableIds: VisualTableId[] = this.getVisualTablesForTable(tableId).map((visualBaseTable: VisualBaseTable): VisualTableId => {
          return visualBaseTable.id;
        });
        allVisualTableIds = allVisualTableIds.concat(visualTableIds);
      }
    }

    return allVisualTableIds;
  }

  /**
   * @returns {VisualAttributeId[]} list of visual attribute ids used in this diagram
   */
  public visualAttributeIds(tableIds: TableId[], attributeNamesPerTable: string[][]): VisualAttributeId[] {
    const allTableIds: TableId[] = this.tableIds;
    const allVisualTableIds: VisualAttributeId[] = [];

    for (let i: number = 0; i < tableIds.length; i++) {
      if (allTableIds.includes(tableIds[i])) {
        for (const visualTable of this.getVisualTablesForTable(tableIds[i])) {
          const visualAttributeDefinitionsByName = visualTable.visualAttributeDefinitions;

          for (const attributeName of attributeNamesPerTable[i]) {
            if (visualAttributeDefinitionsByName.has(attributeName)) {
              allVisualTableIds.push(visualAttributeDefinitionsByName.get(attributeName).id);
            }
          }
        }
      }
    }

    return allVisualTableIds;
  }

  // filter might need refreshing
  /**
   * @return true if anything changed
   */
  public abstract updateAllFilteredElements(forceAutoLayout: boolean): void;

  /**
   * connected to selection logic has to be updated, called when show connected mode is toggled or primary selection changes
   * @param newState: if show connected mode has changed, the new mode; otherwise undefined
   */
  public abstract onConnectedToSelectionStateChange(newState: boolean): void;

  /**
   * add a visual table to the diagram configuration
   * @param visualTableId visual id of table
   * @param title: header title for this table
   * @param insertPosition coordinatesor index where table should be inserted
   */
  public abstract addTable(visualTableId: VisualTableId, title: string, insertPosition: Coords | number): void;

  /**
   * add a visual attribute to the diagram configuration
   * @param {AddTableToViewPayload} a
   */
  public abstract addAttribute(a: AddAttributeToViewPayload): VisualAttributeDefinition;

  /**
   * remove visual table from the diagram configuration
   * @param {visualTableId} visualTableId visual table id of table to remove
   */
  public abstract removeTable(visualTableId: VisualTableId): void;

  /**
   * remove visual attribute from the diagram configuration
   * @param {VisualAttributeId} visualAttributeId visual attribute id of attribute to remove
   */
  public abstract removeAttribute(visualAttributeId: VisualAttributeId): void;

  /** create visual elements for newly created elements
   *
   * @param {TableId} tableId table of newly created element
   * @param {ElementId} elementId element id
   * @param {string} name element name, usually empty since name is an attribute
   * @param {number} y an y position might be passed for visual editors roundtrip creating a new element at a specific position
   */
  public abstract newVisualElement(tableId: TableId, elementId: ElementId, name: string, y: number): void;


  /**
   * update diagram when connections are created
   * @param connections connections to add
   */
  public abstract addConnections(connections: Connection[]): void;


  /**
   * update diagram when connections are deleted
   * @param connections connections to delete
   */
  public abstract deleteConnections(connections: Connection[]): void;


  /**
   * update diagram when connections are created or deleted
   * @param {ElementId[]} sourceElementIds
   * @param {ElementId} targetElementId
   */
  public abstract toggleConnections(sourceElementIds: ElementId[], targetElementId: ElementId): void;

  /**
   * find all visual elements for the given elements and delete them from the graph
   * @param {ElementId} elementIds
   */
  public abstract deleteVisualElements(...elementIds: ElementId[]): void;

  /**
   * update diagram with changed attribute values
   * @param {TableId} tableId
   * @param {ElementObject[]} elements
   */
  public abstract mergeAttributeValues(tableId: TableId, elements: ElementObject[]): void;


  /** resize a visual table level/column */
  public resizeTable(visualTableId: VisualTableId, d: Direction, dx: number, dy: number): void {
    const table = this.getVisualTable(visualTableId);
    Validate.notNull(table);
    table.resizeTable(d, dx);
  }

  public resizeAttribute(visualId: VisualAttributeId, d: Direction, dx: number, dy: number): void {
    const table = this.getVisualTable(visualId.visualTableId);
    Validate.notNull(table);
    resizeAttributeInTable(table, visualId, d, dx, dy);
  }

  public resizeVisualObject(visualId: VisualId, d: Direction, dx: number, dy: number): void {
    const visualObject = this.findVisual(visualId);
    if (visualObject instanceof BoundedVisualObjectBase) {
      const resized = DirectionHelper.resize(d, dx, dy, visualObject.x, visualObject.y, visualObject.width, visualObject.height);
      visualObject.setInternalBounds(resized);
    }
  }


  public moveAttributeColumn(visualAttributeId: VisualAttributeId, dx: number, dy: number): void {
    log.debug("Moving attribute column", visualAttributeId);
    const visualChartColumn = this.getVisualTable(visualAttributeId.visualTableId);
    visualChartColumn.updateAttributeColumn(visualAttributeId, dx, dy);
  }

  public moveTableColumn(visualTableId: VisualTableId, dx: number, dy: number): void {
    log.debug("Moving table", visualTableId);
    const table = this.getVisualTable(visualTableId);
    Validate.notNull(table);
    if (table !== undefined) {
      table.header.x = table.header.x + dx;
      table.header.y = table.header.y + dy;
      table.visualAttributeDefinitions.forEach((att: VisualAttributeDefinition) => {
        att.header.x = att.header.x + dx;
        att.header.y = att.header.y + dy;
      });
    }
  }

  public abstract getVisualTable(visualTableId: VisualTableId): VisualBaseTable;


  // noinspection JSUnusedGlobalSymbols
  /**
   * find a visualization of an element
   * @param {VisualElementId} vid
   * @returns {VisualBaseElement} visualization if found, undefined otherwise
   */
  public getVisualElement(vid: VisualElementId): VisualBaseElement {
    let result = undefined;
    const findVisualElementWithIdVisitor = (value: Object): VisitState => {
      if (isVisualBaseElement(value)) {
        if (vid.equals(value.id)) {
          result = value;
          return VisitState.TERMINATE;
        }
      }
      return VisitState.CONTINUE;
    };
    this.accept(findVisualElementWithIdVisitor);
    return result;
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * find a visual by its id
   * @param {VisualId} vid
   * @returns {any} visualization if found, undefined otherwise
   */
  public getVisualById(vid: VisualId): any {
    let result = undefined;
    const findVisualByIdVisitor = (value: any): VisitState => {
      if (vid === visualId(value)) {
        result = value;
        return VisitState.TERMINATE;
      }
      return VisitState.CONTINUE;
    };
    this.accept(findVisualByIdVisitor);
    return result;
  }

  /**
   * @returns {VisualBaseElement[]} all visual elements found on the diagram
   */
  // noinspection JSUnusedGlobalSymbols
  public get visualElements(): Map<VisualElementIdString, VisualBaseElement> {
    const result: Map<string, VisualBaseElement> = new Map();
    const visitor = (value: any): VisitState => {
      if (isVisualBaseElement(value)) {
        result.set(value.id.toKey(), value);
        return VisitState.CONTINUE;
      }
      return VisitState.CONTINUE;
    };
    this.accept(visitor);
    return result;
  }

  // noinspection JSUnusedGlobalSymbols
  public getVisualAttributeDefinition(attributeId: VisualAttributeId, throwIfNotFound: boolean = true): VisualAttributeDefinition {
    const t: VisualBaseTable = this.getVisualTable(attributeId.visualTableId);
    if (t) {
      const a: VisualAttributeDefinition = t.visualAttributeDefinitions.get(attributeId.attributeName);
      if (a) {
        return a;
      }
    }
    if (throwIfNotFound) {
      throw new Error("getAttribute: " + attributeId.attributeName + " not found in table " + attributeId.visualTableId.toKey());
    } else {
      return undefined;
    }
  }

  // noinspection JSUnusedGlobalSymbols
  public getVisualChartColumn(id: VisualTableId): VisualBaseTable {
    let result: VisualBaseTable = this.getVisualTable(id);
    if (result === undefined && this.valueChart !== undefined) {
      // try searching visual hierarchy
      result = this.valueChart.levels.find(level => shallowEqual(level.id, id)) as VisualValueChartLevel;
    }
    return result;
  }

  // noinspection JSUnusedGlobalSymbols
  /**
   * find visualisations of elements in the given rectangle
   * @param {Rectangle} rect
   * @returns {VisualElementId[]} visual ids of element visualisations in the given tracking rectangle
   */
  public getElementVisualsInRectangle(rect: Rectangle): VisualElementId[] {
    const result: VisualElementId[] = [];

    function findVisualElementsInRectVisitor(value: IVisitable): VisitState {
      if (isVisualBaseElement(value)) {
        if (rect.containsRect(Rectangle.fromJS(value.bounds))) {
          result.push(value.id);
        }
      }
      return VisitState.CONTINUE;
    }

    this.accept(findVisualElementsInRectVisitor);
    return result;
  }

  /**
   * updates the selection state for all visual elements on the diagram
   * @param {Set<ElementId>} primarySelection
   * @param {Set<ElementId>} secondarySelection
   */
  public updateSelectionState(primarySelection: Set<ElementId>, secondarySelection: Set<ElementId>): void {
    function visitor(value: any): VisitState {
      if (isVisualBaseElement(value)) {
        const elementId = value.id.elementId;
        // primary overrides secondary, thus do it first
        if (primarySelection.has(elementId)) {
          if (value.selection !== SelectionKind.Primary) {
            value.selection = SelectionKind.Primary;
          }
        } else if (secondarySelection.has(elementId)) {
          if (value.selection !== SelectionKind.Secondary) {
            value.selection = SelectionKind.Secondary;
          }
        } else {
          if (value.selection !== SelectionKind.None) {
            value.selection = SelectionKind.None;
          }
        }
      }
      return VisitState.CONTINUE;
    }

    this.accept(visitor);
  }

  public getVisualTablesForTable(tableId: TableId): VisualBaseTable[] {
    const result: VisualBaseTable[] = [];

    function visitor(value: any): VisitState {
      if (isVisualBaseTable(value)) {
        if (value.id.tableId === tableId) {
          result.push(value);
        }
      }
      return VisitState.CONTINUE;
    }

    this.accept(visitor);
    return result;
  }

  /**
   * get all visual elements for a given core element
   * @param elementId
   */
  public getVisualElementsByElementId(elementId: ElementId): VisualBaseElement[] {
    const result: VisualBaseElement[] = [];
    const visitor = (value: any): VisitState => {
      if (isVisualBaseElement(value) && value.id.elementId === elementId) {
        result.push(value);
      }
      return VisitState.CONTINUE;
    };
    this.accept(visitor);
    return result;
  }

  setConditionalFormats(visualAttributeId: VisualAttributeId, conditionalFormats: ConditionalFormat[]): void {
    this.getVisualAttributeDefinition(visualAttributeId).conditionalFormats = conditionalFormats;
    this.updateConditionalFormats(conditionalFormats, visualAttributeId);
  }

  protected abstract updateConditionalFormats(conditionalFormats: ConditionalFormat[], visualAttributeId: VisualAttributeId): void;

  /**
   * removes all conditional formats which reference the removed attribute and updates the formating for all elements of the table
   * @param {VisualAttributeId} visualAttributeIdToRemove
   */
  protected synchronizeConditionalFormats(visualAttributeIdToRemove: VisualAttributeId): void {
    const visualTable: VisualBaseTable = this.getVisualTable(visualAttributeIdToRemove.visualTableId);
    visualTable.visualAttributeDefinitions.forEach(vattDef => {
      let index;
      while ((index = vattDef.conditionalFormats.findIndex(cf => shallowEqual(visualAttributeIdToRemove, cf.operand))) !== -1) {
        vattDef.conditionalFormats.splice(index, 1);
      }
      this.updateConditionalFormats(vattDef.conditionalFormats, vattDef.id);
    });
  }

  protected initFromSerializedDiagram(diagramData: DiagramData): void {
    // and add all table header info to viewHeader map
    diagramData.children.forEach(childJson => {
      // instanceof ChartTableData ?
      if (childJson.hasOwnProperty("type")) {
        switch ((childJson as any).type) {
          case "VisualValueChartData":
            const typedChildJson = childJson as VisualValueChartData;
            const valueChart: VisualValueChart = new VisualValueChart(this, typedChildJson.id, typedChildJson);
            this.addChildren(valueChart);
            break;
        }
        // deserialize the given type
      } else if (childJson.hasOwnProperty("text")) {
        const childData = childJson as VisualTextBoxData;
        const textbox = new VisualTextBox(this, childData.x, childData.y, childData.width, childData.height);
        textbox.text = childData.text;
        this.textBoxes.push(textbox);
        this.addChildren(textbox);
      } else {
        const childData = childJson as ChartTableData;
        // compatibility: no type = ChartColumn
        log.debug("Creating new gTable", childData.name);
        const gTable = new VisualChartColumn(childData.id.tableId, childData.name, childData.displayName, childData.x, 0, childData.width, childData.height);
        if (childData.id) {
          // new charts should have an id
          gTable.id = new VisualTableId(childData.id.tableId, childData.id.visualId);
        } else {
          // classic charts do not have an id
          gTable.id = new VisualTableId(childData.name);
        }
        this.addChildren(gTable);
        this.readVisualElementsAsGNodes(gTable, childData.visualElements);
        // translate attribute headers down to avoid overlap with table header
        childData.attributes.forEach(attJson => {
          const visualAttributeDefinition = this.initFromSerializedAttDef(gTable.id, attJson);
          gTable.addAttributeColumn(visualAttributeDefinition);
        });
      }
    });

    const {autoLayoutOptions, attributeHeaderRotation, attributeHeaderHeight, isHeaderExpanded} = diagramData;
    this._autoLayoutOptions = autoLayoutOptions ? {...autoLayoutOptions, selectedTable: undefined} : undefined;
    this.attributeHeaderRotation = attributeHeaderRotation || 0;
    this._attributeHeaderHeight = attributeHeaderHeight || DiagramVisualConstants.DEFAULT_ROTATED_HEADER_HEIGHT;
    this.isHeaderExpanded = isHeaderExpanded === undefined ? true : isHeaderExpanded;

    // deserialize saved filter data
    diagramData.filterData.forEach((filterData: FilterData) => {
      filterData.visualTables.forEach((tidvidPair: [TableId, string]) => {
        const visualTableId = new VisualTableId(tidvidPair[0], tidvidPair[1]);
        const vattId: VisualAttributeId = new VisualAttributeId(visualTableId, filterData.attribute);
        this.updateViewerFilterForAttribute(vattId, filterData.filterText);
      });
    });

    this.diagramInitialized();
  }

  /**
   * hook for post initialization processing, called after a diagram was deserialized or created
   */
  protected diagramInitialized(): void {

  }


  /**
   * overload in subclasses to adjust heights if needed
   * @param visualTableId visual table to toggle the filter line
   */
  public toggleFilterLine(visualTableId: VisualTableId): boolean {
    const table: VisualBaseTable = this.getVisualTable(visualTableId);
    Validate.isDefined(table, "toggleFilterLine: Table not found");
    const result: boolean = table.toggleFilterLine();
    this.adjustNodeAndAttributePositions();
    return result;
  }

  /**
   * subclasses may reposition nodes and attributes after header rotation or size change
   */
  protected abstract adjustNodeAndAttributePositions(): void;

  private _loadedSerializedModel: JSON;

  getCurrentModelAsJSON(): JSON {
    return JSON.parse(JSON.stringify(DiagramModelSerializer.toJSON(this)));
  }

  setLoadedSerializedModel(serializedViewModel: DiagramData): void {
    if (serializedViewModel !== undefined) {
      this._loadedSerializedModel = JSON.parse(JSON.stringify(serializedViewModel));
    }
  }

  getLoadedSerializedModel(): JSON {
    return this._loadedSerializedModel;
  }

  public refreshLoadedSerializedModel(): void {
    this._loadedSerializedModel = this.getCurrentModelAsJSON();
  }

}





