import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class TableIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <path d="M11,8 L19,8 L19,6 L11,6 L11,8 Z M5,8 L9,8 L9,6 L5,6 L5,8 Z M11,13 L19,13 L19,11 L11,11 L11,13 Z M5,13 L9,13 L9,11 L5,11 L5,13 Z M11,18 L19,18 L19,16 L11,16 L11,18 Z M5,18 L9,18 L9,16 L5,16 L5,18 Z"/>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(TableIcon);