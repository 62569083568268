import StoreBase from "../../common/stores/StoreBase";
import Log from "../../common/utils/Logger";
import {Target, ViewType} from "../../common/constants/Enums";
import {AttributeId, TableId, ViewId, VisualAttributeId, VisualTableId} from "../../core/utils/Core";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {LoadActions} from "../../modelselection/actions/ModelAsyncActionCreators";
import {NewViewAction} from "../../workbench/actions/ViewManagerActions";
import {action, observable} from "mobx";
import {SharedViewActions} from "../../commonviews/actions/SharedViewActions";
import {MatrixDisplayMode, MatrixModel} from "../models/MatrixModel";
import {ToggleFilterLineAction} from "../../diagram/actions/DiagramActions";
import {MatrixSyncActions, ScrollDirection, ShowFilterAction} from "../actions/MatrixActions";
import {Validate} from "../../common/utils/Validate";
import {MatrixAsyncActions} from "../actions/MatrixAsyncActions";
import {NAME_ATT_NAME} from "../../api/api";
import {AddAttributeToViewPayload} from "../../commonviews/actions/SharedViewAsyncActions";
import {ViewInfo} from "../../commonviews/models/ViewInfo";
import {CoreActions, SelectModelAction} from "../../core/actions/CoreAsyncActions";
import {AttributesToDelete} from "../../core/actions/CoreActions";

const log = Log.logger("MatrixStore", Classifier.action);

export class MatrixStore extends StoreBase {
  @observable private _matrices: Map<ViewId, MatrixModel> = new Map();

  constructor() {
    super();
    this.reset();
  }

  @action
  accept(actionParam: MatrixSyncActions | MatrixAsyncActions | CoreActions | LoadActions | SelectModelAction | NewViewAction | SharedViewActions | ToggleFilterLineAction | ShowFilterAction): void {
    log.debug("MatrixStore accepting", actionParam);
    // 1. actions creating a matrix
    switch (actionParam.type) {
      case "selectmodel":
        this.reset();
        this.notify(actionParam.type);
        break;
      case "newView":
        const viewInfo: ViewInfo = actionParam.payload;
        if (viewInfo.type === ViewType.Matrix || viewInfo.type === ViewType.ChainMatrix || viewInfo.type === ViewType.StructuredTable || viewInfo.type === ViewType.Table) {
          this.createNewMatrix(viewInfo.id, viewInfo.type);
          this.notify(actionParam.type);
        }
        break;
      case "loadMatrix":
        log.debug(actionParam.type, actionParam.payload);
        this.addMatrixToStore(actionParam.payload);
        this.notify(actionParam.type);
        break;
    }
    // 2. actions modifying the core model
    switch (actionParam.type) {
      case "deleteTables":
        for (let matrix of this._matrices.values()) {
          const tableIds: TableId[] = actionParam.payload;
          for (let tableId of tableIds) {
            matrix.removeTable(tableId);
          }
        }
        break;
      case "deleteAttributes":
        const attrsToDelete: AttributesToDelete = actionParam.payload;
        for (let matrix of this._matrices.values()) {
          attrsToDelete.tableIds.forEach((tableId, index) => {
            for (let attName of attrsToDelete.attributeNames[index]) {
              const attId: AttributeId = new AttributeId(tableId, attName);
              matrix.removeAttribute(attId);
            }
          });
        }
        break;
    }
    // 3. actions modifying a matrix
    const matrix = this.getMatrixById(actionParam.resourceId);
    if (matrix) {
      switch (actionParam.type) {
        case "addTableToView":
          const index = actionParam.payload.insertPosition as number;
          this.addTableToMatrix(matrix, actionParam.payload.visualTableId, index, actionParam.payload.target === Target.COLUMN);
          break;
        case "removeTableFromView":
          const tableId2 = actionParam.payload.visualTableId;
          if (actionParam.payload.target === Target.COLUMN) {
            matrix.columnHierarchy.removeTable(tableId2);
            // reset column page
            matrix.columnBlockIndex = 0;
          } else {
            matrix.rowHierarchy.removeTable(tableId2);
          }
          break;
        case "toggleFilterLine":
          matrix.toggleTableFilter();
          break;
        case "addFilterDefinition":
          const filterDefinition = actionParam.payload;
          matrix.columnHierarchy.addFilterDefinition(filterDefinition);
          matrix.columnBlockIndex = 0;
          break;
        case "deleteFilterDefinition":
          log.debug(actionParam.type, actionParam.payload);
          const idToBeDeleted = actionParam.payload;
          matrix.columnHierarchy.removeFilterDefinition(idToBeDeleted);
          matrix.columnBlockIndex = 0;
          break;
        case "updateFilterDefinition":
          matrix.columnHierarchy.updateFilterDefinition(actionParam.payload);
          matrix.columnBlockIndex = 0;
          break;
        case "updateElementFilter":
          const {type, elementId} = actionParam.payload;
          if (type === "add") {
            matrix.columnHierarchy.addElementToFilter(actionParam.payload.visualTableId, elementId);
          } else if (type === "remove") {
            matrix.columnHierarchy.removeElementFromFilter(actionParam.payload.visualTableId, elementId);
          } else {
            log.error("Update element filterDefinition action payload has unexpected type:", type);
          }
          break;
        case "addAttributeToView":
          const payload: AddAttributeToViewPayload = actionParam.payload;
          if (payload.isColumn) {
            log.debug(actionParam.type + " to columns", actionParam.payload);
            matrix.columnAttributes.push(actionParam.payload.visualAttributeId.toAttributeId());
          } else {
            log.debug(actionParam.type + " to rows", actionParam.payload);
            const attributeWithSameNameAlreadyExists = matrix.rowAttributeNames.includes(actionParam.payload.visualAttributeId.attributeName);
            matrix.rowAttributes.push(actionParam.payload.visualAttributeId.toAttributeId());
            if (matrix.columnState && !attributeWithSameNameAlreadyExists && matrix.rowAttributeNames.length > 0) {
              const attributePositionIndex = matrix.rowAttributeNames.length - 1;
              matrix.columnState.splice(attributePositionIndex, 0, {colId: actionParam.payload.visualAttributeId.attributeName});
              matrix.attributeColumnAdded = true;
            }
          }
          break;
        case "removeAttributeFromView":
          const visualAttId = actionParam.payload.visualAttributeId;
          if (actionParam.payload.isColumn) {
            matrix.columnHierarchy.removeAttribute(visualAttId);
          } else {
            matrix.rowHierarchy.tables.forEach(visualTableId => {
              const currentVisAttId = new VisualAttributeId(visualTableId, visualAttId.attributeName);
              matrix.rowHierarchy.removeAttribute(currentVisAttId);
            });
          }
          break;
        case "reorderTables":
          const payload1 = actionParam.payload;
          let targetIndex: number = matrix.rowHierarchy.tables.findIndex(vtid => vtid.tableId === payload1.targetVisualTableId.tableId && vtid.visualId === payload1.targetVisualTableId.visualId);
          let targetTables: VisualTableId[];
          const targetIsRowHierarchy = targetIndex !== -1;
          if (targetIsRowHierarchy) {
            // drag to row hierarchy
            targetTables = matrix.rowHierarchy.tables;
          } else {
            // drag to column hierarchy
            targetIndex = matrix.columnHierarchy.tables.findIndex(visualTableId => visualTableId.tableId === payload1.targetVisualTableId.tableId && visualTableId.visualId === payload1.targetVisualTableId.visualId);
            Validate.isTrue(targetIndex !== -1, "ReorderTablesAction: table " + payload1.sourceVisualTableId + "does not exist in Matrix rows or columns");
            targetTables = matrix.columnHierarchy.tables;
            // reset column page
            matrix.columnBlockIndex = 0;
          }

          const sourceIndex = targetTables.findIndex(visualTableId => visualTableId.tableId === payload1.sourceVisualTableId.tableId && visualTableId.visualId === payload1.sourceVisualTableId.visualId);
          if (sourceIndex !== -1) {
            // source and target id in same array --> move
            targetTables.splice(sourceIndex, 1);
            // insert to new one
            targetTables.splice(targetIndex, 0, payload1.sourceVisualTableId);
          } else {
            // not in same array, thus add a new visual table with the same table id
            const vtid = new VisualTableId(payload1.sourceVisualTableId.tableId);
            const indexToAdd = targetIndex + 1;
            this.addTableToMatrix(matrix, vtid, indexToAdd, !targetIsRowHierarchy);
          }
          break;
        case "changeCellConfiguration":
          matrix.cellConfiguration.setCellConfiguration(actionParam.payload);
          break;
        case "rotateAttributeHeaders":
          matrix.rotateColumnHeaders();
          break;
        case "changeAttributeHeaderHeight":
          const newHeight: number = actionParam.payload;
          matrix.attributeHeaderHeight = newHeight;
          break;
        case "changeAttributeHeaderWidth":
          const newWidth: number = actionParam.payload;
          matrix.attributeHeaderWidth = newWidth;
          break;
        case "updateDisplayMode":
          const newMode: MatrixDisplayMode = actionParam.payload;
          matrix.displayMode = newMode;
          break;
        case "scrollPage":
          const scrollDirection: ScrollDirection = actionParam.payload;
          if (scrollDirection === "previous") {
            matrix.columnBlockIndex -= 1;
          } else {
            matrix.columnBlockIndex += 1;
          }
          break;
        case "saveQuickFilterText":
          const {columnName, filterText} = actionParam.payload;
          matrix.rowHierarchy.setFilterForAllTables(filterText, columnName);
          break;
        case "showConnectedToSelection":
          matrix.showConnectedOnly = actionParam.payload;
          break;
        case "showFilter":
          matrix.filterIsShown = actionParam.payload;
          break;
        case "updateMaxNumberOfColumnsPerPage":
          matrix.maxColumns = actionParam.payload;
          matrix.columnBlockIndex = 0;
          break;
        case "setWordWrapOnColumn":
          const {isWordWrap, columnId} = actionParam.payload;
          matrix.setWordWrapOnColumn(columnId, isWordWrap);
          break;
        case "setConditionalFormats":
          log.debug("setConditionalFormats");
          const payload2 = actionParam.payload;
          matrix.setColumnDefinitions(payload2.visualAttributeId.attributeName, payload2.conditionalFormats);
          break;

      }
      this.notify(actionParam.type);
    }
  }

  private addTableToMatrix(matrix: MatrixModel, visualTableId: VisualTableId, index: number, isColumn: boolean) {
    const tableId1 = visualTableId;
    let attributes: AttributeId[] = [];
    const hierarchy = isColumn ? matrix.columnHierarchy : matrix.rowHierarchy;
    let insertPosition = index;
    if (insertPosition === undefined) {
      insertPosition = hierarchy.tables.length;
    }
    hierarchy.tables.splice(insertPosition, 0, tableId1);
    const nameAtt = new AttributeId(tableId1.tableId, NAME_ATT_NAME);
    // add nameAtt if not already there
    if (!hierarchy.attributeIds.find(att => att.attributeName === nameAtt.attributeName && att.tableId === nameAtt.tableId)) {
      hierarchy.attributeIds.push(nameAtt);
    }
    // reset column page
    matrix.columnBlockIndex = 0;
  }

  public getMatrixById(viewId: ViewId): MatrixModel {
    return this._matrices.get(viewId);
  }

  public createNewMatrix(matrixId: ViewId, viewType: ViewType): MatrixModel {
    Validate.isTrue(!this._matrices.has(matrixId), "ViewId already exists in MatrixStore: " + matrixId);
    log.debug("Creating MatrixModel", matrixId);
    const matrix = new MatrixModel(matrixId, viewType);
    this._matrices.set(matrixId, matrix);
    return matrix;
  }

  public addMatrixToStore(matrixModel: MatrixModel): void {
    Validate.isTrue(!this._matrices.has(matrixModel.id), "ViewId already exists in MatrixStore: " + matrixModel.id);
    log.debug("Adding MatrixModel", matrixModel);
    this._matrices.set(matrixModel.id, matrixModel);
  }

  public reset(): void {
    log.debug("MatrixStore reset");
    this._matrices.clear();
  }

}

const matrixStore = new MatrixStore();
export {matrixStore};
