import * as React from "react";
import CheckboxIconChecked from "../icons/menu/CheckboxIconChecked";
import CheckboxIcon from "../icons/menu/CheckboxIcon";
import Checkbox, {CheckboxProps} from "@material-ui/core/Checkbox/Checkbox";

export class MetusCheckbox extends React.PureComponent<CheckboxProps, any> {

  render(): JSX.Element {
    return <Checkbox icon={<CheckboxIcon/>} checkedIcon={<CheckboxIconChecked/>} value={this.props.value}
                     data-testselector={"MetusCheckbox"} {...this.props}/>;
  }

}