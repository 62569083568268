/* UndoStore.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, September 2018
 */
import StoreBase from "../../common/stores/StoreBase";
import Action, {ActionBase} from "../../common/actions/BaseAction";
import Log from "../../common/utils/Logger";
import {ActionGroup} from "../actions/UndoRedoActions";
import {UUID} from "../../api/api";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {action, observable} from "mobx";

const log = Log.logger("Undo");

export class UndoStore extends StoreBase {
  public limit: number = 5000;
  @observable private _undo: Action[] = [];
  @observable private _redo: Action[] = [];
  private _lastSnapshot: any = undefined;
  private isReplaying: boolean = false;
  /**
   * maps groupIds to their action group
   */
  private groups: Map<UUID, ActionGroup> = new Map();

  constructor() {
    super(false);
    this.undo = this.undo.bind(this);
    this.redo = this.redo.bind(this);
  }

  @action
  accept(actionParam: Action): void {
    if (!this.isReplaying && !(actionParam.recordable === false)) {
      this.recordAction(actionParam);
    }
  }

  private recordAction(action: Action): void {
    log.debug("Recording action", action);
    if (this._undo.length === this.limit) {
      this.setSnapshot();
      this._undo = [];
      this.groups = new Map();
    }

    if (action.groupId === undefined) {
      this._undo.push(action);
    } else {
      // group handling
      if (this.groups.has(action.groupId)) {
        // group exists, add action to group, do not push explicit action
        this.groups.get(action.groupId).push(action);
      } else {
        // create new group and push instead of action
        const actionGroup = new ActionGroup(action);
        this.groups.set(action.groupId, actionGroup);
        this._undo.push(actionGroup);
      }
    }

    if (action.isCompleteUndoStep) {
      this.clearRedoStack();
    }
  }

  /**
   * make sure action groups are deleted from groups map when actions go out of scope
   */
  private clearRedoStack(): void {
    log.debug("Clearing redo stack");
    this._redo.forEach(action => {
      if (action instanceof ActionGroup) {
        this.groups.delete(action.groupId);
      }
    });
    this._redo.splice(0, this._redo.length);
  }

  private setSnapshot(): void {
    // TODO: find a way to serialize/deserialize stores
    // clear action groups map at snapshot time
  }

  public resetStoresToLastSnapshot(): void {
    Dispatcher.resetStoresForUndo();
  }

  // !!! violating the flux architecture: the store should modify data only within the action handling in accept method.
  // However, since nested Dispatching is also forbidden, the undo is called directly from action creator instead of
  // dispatching an undo action
  // @autobind disabled, binding in constructor due to conflict between sinon spy and autobind
  // see https://github.com/andreypopp/autobind-decorator/issues/47
  @action
  public undo():void {
    if (!this.hasUndo()) {
      return;
    }
    this.resetStoresToLastSnapshot(); // hierfür ist @action decorator nötig
    const undoCount = this.nextUndoStepActionCount();
    const actionsToReplay = this._undo.slice(0, this._undo.length-undoCount);
    this.replay(actionsToReplay);
    // remove actions which are undone now from undoActions stack and add to redoActions stack
    this.nextUndoStepActions().forEach(a => this._redo.push(a));
    this._undo.splice(this._undo.length-undoCount, undoCount);
  }

  // how many actions to replay in one go
  public nextUndoStepActionCount():number {
    let undoCount = 0;
    let idx = this._undo.length - 1;
    while (idx >= 0) {
      undoCount += 1;
      if (this._undo[idx--].isCompleteUndoStep) {
        break;
      }
    }
    return undoCount;
  }

  // the actions to undo in one go (usually one, except there are incomplete actions (isCompleteActionStep === false)
  // order is the order in which the actions need to be undone
  public nextUndoStepActions():Action[] {
    const nextUndoStepActions = this._undo.slice(this._undo.length-this.nextUndoStepActionCount());
    return nextUndoStepActions.reverse();
  }

  public nextRedoStepActionCount():number {
    let redoCount = 0;
    if (this.hasRedo()) {
      redoCount = 1;
      let idxToTestForIncomplete;
      // now add all upcoming incomplete actions
      while ((idxToTestForIncomplete = this._redo.length - redoCount - 1) >= 0) {
        if (!this._redo[idxToTestForIncomplete].isCompleteUndoStep) {
          redoCount +=1;
        } else {
          break;
        }
      }
    }
    return redoCount;
  }

  // next redo step actions in the order to apply
  public nextRedoStepActions(): Action[] {
    const nextRedoStepActions = this._redo.slice(this._redo.length-this.nextRedoStepActionCount());
    return nextRedoStepActions.reverse();
  }

  // !!! see comment for undo
  // @autobind disabled, binding in constructor due to conflict between sinon spy and autobind
  // see https://github.com/andreypopp/autobind-decorator/issues/47
  @action
  public redo():void {
    if (!this.hasRedo()) {
      return;
    }
    const nextRedoStepActions = this.nextRedoStepActions();
    this.replay(nextRedoStepActions);
    nextRedoStepActions.forEach( nextRedoStepAction => {
      this._undo.push(this._redo.pop());
    })
  }

  /**
   * replay actions without recording them
   * @param actions actions to replay
   */
  private replay(actions:ActionBase<any>[]):void {
    // breaking the convention that stores do not dispatch Actions
    const dispatchAction = Dispatcher.dispatch;
    this.isReplaying = true;

    actions.forEach(action => {
      if (action instanceof ActionGroup) {
        // replay each action of group
        (action as ActionGroup).actions.forEach(action => dispatchAction(action));
      } else {
        dispatchAction(action);
      }
    });

    // TODO: What if actions array is empty (undo stack contains only one element?)

    this.isReplaying = false;
  }

  public hasUndo(): boolean {
    return this.lastExecutedAction !== undefined && this.lastExecutedAction.undoable;
  }

  get lastExecutedAction(): Action {
    return this._undo.length > 0 ? this._undo[this._undo.length - 1] : undefined;
  }

  public get undoActions(): Action[] {
    return this._undo;
  }

  public hasRedo(): boolean {
    return this._redo.length > 0;
  }

  get redoActions(): Action[] {
    return this._redo;
  }

  reset(): void {
    this._lastSnapshot = undefined;
    this._undo.splice(0, this._undo.length);
    this._redo.splice(0, this._redo.length);
    this.groups = new Map();
    this.isReplaying = false;
  }

}

// singleton
const undoStore = new UndoStore();
export {undoStore};
