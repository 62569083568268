export class Mutex {
  private mutex = Promise.resolve();

  lock(): PromiseLike<() => void> {
    let begin: (unlock: () => void) => void = unlock => {
    };

    this.mutex = this.mutex.then(() => {
      return new Promise(begin);
    });

    return new Promise(res => {
      begin = res;
    });
  }
}

/**
 * wraps the async function returning a function which will serialize multiple calls to this function, so it is guaranteed that no call will be done before the previous calls have resolved their promises.
 *
 * Usage:
 * <ul>
 * <li>create a new function where multiple async calls will be executed one after the other
 * <p><code>
 * export const getConnectionsForTables = sequential(getUnsynchronizedConnectionsForTables);
 * </code>
 * </p></li>
 * <li>create two functions where multiple async calls of either or themselves will be executed one after the other
 * <p><code>
 * const mutex: Mutex = new Mutex();
 * export const getConnectionsForTables = sequential(getUnsynchronizedConnectionsForTables, mutex);
 * export const getTables = sequential(getUnsynchronizedTables, mutex);
 * </code></p></li>
 * </ul>
 * @param functionToSerialize function returning a promise; a second call will be blocked until the promise resolves
 * @param mutex if specified a function call will wait until it is unlocked before it executes (and locks this mutex); this can be used if several functions should be blocked against each other
 */
export function sequential<F extends (...args: any[]) => Promise<R>, R>(functionToSerialize: F, mutex: Mutex = undefined): (...args: Parameters<F>) => Promise<R> {
  const mutexToUse = mutex ? mutex : new Mutex();
  // wrap function call so promise will be set in local ongoingRequest during request execution
  const wrappedFunctionWaitingForLock = async function (...args: Parameters<F>): Promise<R> {
    // console.log("Args: ", args);
    const unlock = await mutexToUse.lock();
    try {
      return await Promise.resolve(functionToSerialize(...args));
    } finally {
      unlock();
    }
  };
  return wrappedFunctionWaitingForLock as F;
}

function logDuration<T extends (...args: any[]) => any>(func: T): (...funcArgs: Parameters<T>) => ReturnType<T> {
  const funcName = func.name;

  // Return a new function that tracks how long the original took
  return (...args: Parameters<T>): ReturnType<T> => {
    console.time(funcName);
    const results = func(...args);
    console.timeEnd(funcName);
    return results;
  };
}

export class SimpleAsyncFunctionSerializer {

  private promise: Promise<any>;

  constructor() {
    this.promise = Promise.resolve();
  }

  execSerialized(func: (...args: any[]) => any):Promise<any> {
    this.promise = this.promise.then(func);
    return this.promise;
  }
}