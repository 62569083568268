/* DnDInsertTableVC.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Januar 2019
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";
import theme from "../../theme/Theme";

class DnDInsertTableValueChartIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    const highlightColor = theme.metus.dialog.buttonFill
    const highlightColorLight = theme.metus.dialog.buttonFillOnHover
    return <React.Fragment>
      <g id="layer1" transform="translate(-210,-640)">
        <g transform="translate(-75.706414,-192.69716)" id="layer1-5"/>
        <path
            style={{
              fill: "#f2f2f2",
              fillRule: "evenodd",
              stroke: "#000000",
              strokeWidth: 0,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeOpacity: 1
            }}
            d="m 76.785601,672.28952 -11.25,-8.21429 11.25,-7.32142 z"
            id="path12476"
        />
        <rect
            style={{fill: "#000000;fillOpacity:0"}}
            id="rect12488"
            width="65.178574"
            height="25.892857"
            x="88.928574"
            y="667.54077"/>
        <rect
            style={{
              fill: "#f2f2f2",
              fillOpacity: 1,
              stroke: "#000000",
              strokeWidth: 0,
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeOpacity: 1
            }}
            id="rect12490"
            width="188.93779"
            height="119.99998"
            x="76.457657"
            y="642.59772"/>
        <g
            id="g124"
            transform="translate(-245.37674,452.50904)">
          <path
              style={{fill: "#ededed"}}
              id="path126"
              d="m 500.67,213.84 c -84.86,0.17 -157.05,0.52 -161.14,1.01 -5.52,0.66 -9.31,3.82 -10,14.33 -0.79,11.99 -0.49,31.77 -0.15,39.66 0.35,7.91 1.33,15.31 9.5,15.51 7.15,0.17 75.41,-0.31 159.3,-0.6"
              className="st4"/>
          <path
              style={{fill: "#a8a8a8"}}
              id="path128"
              d="m 500.68,214.53 c -4.17,0.18 -8.33,0.21 -12.5,0.22 l -12.5,0.05 -25,0.11 -50,0.27 c -16.66,0.15 -33.33,0.13 -49.99,0.34 l -6.24,0.09 -3.11,0.08 c -1.01,0.05 -2,0.07 -2.95,0.29 -1.9,0.38 -3.61,1.28 -4.8,2.73 -1.21,1.44 -1.93,3.32 -2.39,5.25 -0.46,1.95 -0.68,3.97 -0.79,6.03 -0.13,2.07 -0.2,4.14 -0.27,6.22 -0.13,4.15 -0.17,8.31 -0.17,12.47 0,4.16 0.04,8.32 0.13,12.48 0.12,4.15 0.14,8.35 0.59,12.39 0.25,2.01 0.64,4 1.47,5.72 0.8,1.73 2.15,3.06 3.9,3.64 0.87,0.3 1.81,0.44 2.78,0.47 l 3.1,0.03 6.24,0 49.99,-0.24 c 33.33,-0.29 66.67,0.07 100,-0.1 l 0.05,0 c 0.38,0 0.69,0.3 0.69,0.68 0,0.38 -0.3,0.69 -0.68,0.69 -0.02,0 -0.03,0 -0.05,0 -2.08,-0.14 -4.17,-0.05 -6.25,-0.03 l -6.25,0.11 -12.5,0.2 c -8.33,0.17 -16.67,0.15 -25,0.18 l -50,0.19 -50,0.17 -6.25,-0.01 -3.15,-0.03 c -1.11,-0.03 -2.24,-0.2 -3.33,-0.58 -1.09,-0.36 -2.12,-0.99 -2.96,-1.79 -0.84,-0.81 -1.5,-1.78 -1.98,-2.79 -0.96,-2.03 -1.37,-4.19 -1.63,-6.3 -0.46,-4.24 -0.47,-8.39 -0.59,-12.57 -0.08,-4.17 -0.12,-8.34 -0.11,-12.52 0.01,-4.17 0.05,-8.35 0.19,-12.52 0.07,-2.09 0.15,-4.18 0.28,-6.27 0.11,-2.1 0.34,-4.23 0.84,-6.33 0.51,-2.09 1.3,-4.21 2.76,-5.96 1.44,-1.78 3.62,-2.9 5.77,-3.33 1.08,-0.26 2.18,-0.29 3.24,-0.34 l 3.14,-0.09 6.26,-0.12 c 16.67,-0.24 33.34,-0.41 50.01,-0.47 l 50,-0.17 25,-0.06 12.5,-0.02 c 4.17,-0.01 8.33,0 12.5,0.17 0.38,0.02 0.67,0.33 0.66,0.71 -0.01,0.36 -0.3,0.64 -0.65,0.66 z"
              className="st3"/>
        </g>
        <g
            id="g130"
            transform="translate(-245.37674,452.50904)">
          <path
              style={{fill: "none", stroke: "#a8a8a8;strokeWidth:3;strokeMiterlimit:10"}}
              id="path132"
              d="m 361.39,234.27 c -3.57,-0.06 -7.14,-0.11 -10.71,-0.17"
              className="st16"/>
          <path
              style={{fill: "none", stroke: "#a8a8a8;strokeWidth:3;strokeMiterlimit:10"}}
              id="path134"
              d="m 361.11,228.48 c -3.48,0.09 -6.96,0.18 -10.45,0.28"
              className="st16"/>
          <path
              style={{fill: "none", stroke: "#a8a8a8;strokeWidth:3;strokeMiterlimit:10"}}
              id="path136"
              d="m 360.96,222.95 c -3.78,0.33 -7.36,0.13 -10.17,-0.17"
              className="st16"/>
          <line
              style={{fill: "none", stroke: "#a8a8a8;strokeWidth:3;strokeMiterlimit:10"}}
              id="line138"
              y2="234.11"
              x2="346.92999"
              y1="234.14"
              x1="343.34"
              className="st16"/>
          <line
              style={{fill: "none", stroke: "#a8a8a8;strokeWidth:3;strokeMiterlimit:10"}}
              id="line140"
              y2="228.13"
              x2="346.69"
              y1="228.17"
              x1="342.92999"
              className="st16"/>
          <line
              style={{fill: "none", stroke: "#a8a8a8;strokeWidth:3;strokeMiterlimit:10"}}
              id="line142"
              y2="222.71001"
              x2="346.81"
              y1="222.75"
              x1="342.69"
              className="st16"/>
        </g>
        <path
            style={{opacity: 0.25, fill: "#a8a8a8"}}
            id="path144"
            d="m 121.52325,695.13904 c 16.01,0.45 37.18,-1.26 52.67001,-0.34 23.21,1.39 45.39,2.16 81.94,2.8 -1.67,3.41 -4.21,6.78 -7.49,7.74 -5.15,1.5 -10.47,2.03 -15.76,2.57 -11.31,1.14 -22.64,2.27 -33.98,2.05 -25,-0.48 -64.89001,-8.28 -77.38001,-14.82"
            className="st7"/>
        <g
            id="g146"
            transform="translate(-245.37674,452.50904)">
          <path
              style={{fill: "#f6f6f6"}}
              id="path148"
              d="m 503.56,245.96 c -26.63,2.11 -58.06,3.79 -76.05,3.59 -18.42,-0.21 -27.22,-2.12 -38.12,-4.22 -9.85,-1.9 -16.78,-1.9 -22.36,-1.88 -5.57,0.02 -9.37,3.14 -10.07,11.79 -0.79,9.86 -0.5,9.45 -0.15,15.93 0.35,6.5 1.34,12.59 9.56,12.76 7.89,0.16 47.02,0.12 135.29,-0.07"
              className="st5"/>
          <path
              style={{fill: "#a8a8a8"}}
              id="path150"
              d="m 503.62,246.64 c -6.71,0.82 -13.45,1.19 -20.19,1.67 -6.74,0.45 -13.48,0.85 -20.22,1.2 -13.49,0.66 -26.99,1.27 -40.55,0.94 -6.77,-0.17 -13.57,-0.68 -20.28,-1.75 -3.36,-0.52 -6.69,-1.15 -10,-1.8 -3.32,-0.67 -6.59,-1.3 -9.91,-1.72 -3.32,-0.43 -6.65,-0.68 -10,-0.75 -1.67,-0.04 -3.35,-0.04 -5.04,-0.04 -1.63,-0.02 -3.17,0.19 -4.52,0.85 -1.35,0.64 -2.43,1.73 -3.18,3.07 -0.76,1.34 -1.22,2.89 -1.51,4.48 -0.3,1.58 -0.37,3.24 -0.51,4.93 -0.12,1.68 -0.24,3.35 -0.29,5.01 -0.06,1.66 0.01,3.28 0.12,4.97 l 0.31,5.04 c 0.13,1.65 0.34,3.28 0.8,4.78 0.45,1.5 1.19,2.87 2.32,3.79 1.11,0.94 2.6,1.42 4.15,1.6 0.77,0.1 1.58,0.08 2.43,0.1 l 2.52,0.02 10.12,0.03 40.49,-0.05 c 26.99,-0.22 53.99,0.23 80.98,0.17 l 0.06,0 c 0.38,0 0.69,0.31 0.69,0.69 0,0.38 -0.31,0.69 -0.69,0.69 -0.02,0 -0.04,0 -0.07,0 -1.69,-0.16 -3.37,-0.04 -5.06,-0.02 l -5.06,0.11 -10.12,0.19 c -6.75,0.13 -13.5,0.07 -20.25,0.11 l -40.49,-0.01 -40.49,-0.03 -10.13,-0.05 -2.54,-0.03 c -0.83,-0.02 -1.71,0 -2.61,-0.11 -1.77,-0.2 -3.63,-0.77 -5.09,-2 -1.48,-1.21 -2.38,-2.95 -2.88,-4.65 -0.52,-1.72 -0.73,-3.45 -0.86,-5.16 l -0.31,-5.06 c -0.12,-1.68 -0.19,-3.43 -0.12,-5.14 0.06,-1.71 0.17,-3.4 0.3,-5.09 0.14,-1.67 0.21,-3.38 0.54,-5.12 0.31,-1.72 0.81,-3.44 1.71,-5.03 0.88,-1.59 2.26,-2.99 3.95,-3.79 1.68,-0.82 3.56,-1.04 5.29,-1.02 1.69,0 3.39,0 5.08,0.05 3.4,0.09 6.8,0.34 10.17,0.75 3.38,0.41 6.74,1.04 10.04,1.68 3.31,0.63 6.62,1.23 9.94,1.72 6.64,0.99 13.33,1.5 20.05,1.71 6.72,0.22 13.45,0.11 20.19,-0.05 6.74,-0.18 13.47,-0.46 20.21,-0.79 6.74,-0.33 13.47,-0.72 20.2,-1.15 6.73,-0.4 13.45,-1.01 20.2,-1.25 0.38,-0.01 0.7,0.28 0.71,0.66 0.01,0.31 -0.25,0.61 -0.6,0.65 z"
              className="st3"/>
        </g>
        <g
            id="g152"
            transform="translate(-245.37674,452.50904)">
          <path
              style={{fill: "none", stroke: "#a8a8a8;strokeWidth:3;strokeMiterlimit:10"}}
              id="path154"
              d="m 375.04,256.01 c 3.57,0.09 7.14,0.18 10.7,0.27"
              className="st16"/>
          <path
              style={{fill: "none", stroke: "#a8a8a8;strokeWidth:3;strokeMiterlimit:10"}}
              id="path156"
              d="m 375.26,261.81 c 3.48,-0.06 6.97,-0.11 10.45,-0.17"
              className="st16"/>
          <path
              style={{fill: "none", stroke: "#a8a8a8;strokeWidth:3;strokeMiterlimit:10"}}
              id="path158"
              d="m 375.17,267.41 c 3.78,-0.29 6.94,-0.07 10.64,0.12"
              className="st16"/>
          <line
              style={{fill: "none", stroke: "#a8a8a8;strokeWidth:3;strokeMiterlimit:10"}}
              id="line160"
              y2="255.61"
              x2="367.67001"
              y1="255.61"
              x1="371.26001"
              className="st16"/>
          <line
              style={{fill: "none", stroke: "#a8a8a8;strokeWidth:3;strokeMiterlimit:10"}}
              id="line162"
              y2="261.59"
              x2="367.85001"
              y1="261.59"
              x1="371.62"
              className="st16"/>
          <line
              style={{fill: "none", stroke: "#a8a8a8;strokeWidth:3;strokeMiterlimit:10"}}
              id="line164"
              y2="267.09"
              x2="367.48999"
              y1="267.09"
              x1="371.62"
              className="st16"/>
        </g>
        <g
            id="g166"
            transform="translate(-245.37674,452.50904)">
          <g
              id="g168">
            <g
                id="g170">
              <g
                  id="g172">
                <path
                    style={{fill: highlightColorLight}}
                    id="path174"
                    d="m 473.42,200.74 c -14.41,-0.97 -42.62,-1.21 -51.53,-0.53 -6.93,0.52 -9.66,4.76 -9.06,13.06 0.45,6.21 1.67,12.38 8.87,12.69 15.47,0.66 36.31,-0.06 50.4,-1.28 7.92,-0.69 8.76,-5.98 8.78,-11.95 0.01,-5.5 -0.16,-11.5 -7.46,-11.99 z"
                    className="st11"/>
                <path
                    style={{fill: highlightColor}}
                    id="path176"
                    d="m 473.38,201.42 c -1.78,0.08 -3.55,-0.01 -5.32,-0.1 l -5.32,-0.19 c -3.55,-0.11 -7.1,-0.18 -10.65,-0.23 -7.1,-0.11 -14.2,-0.09 -21.29,0 -1.77,0.02 -3.54,0.05 -5.31,0.11 -0.88,0.03 -1.77,0.06 -2.64,0.11 -0.89,0.04 -1.71,0.1 -2.53,0.26 -1.62,0.3 -3.13,0.94 -4.23,2.03 -1.1,1.09 -1.77,2.6 -2.1,4.22 -0.34,1.63 -0.36,3.35 -0.27,5.07 0.11,1.74 0.28,3.49 0.63,5.15 0.35,1.66 0.9,3.27 1.85,4.52 0.93,1.27 2.29,2.09 3.86,2.42 0.79,0.17 1.57,0.21 2.49,0.23 l 2.65,0.09 c 1.77,0.05 3.54,0.08 5.31,0.09 3.54,0.03 7.09,0.01 10.64,-0.05 7.09,-0.13 14.19,-0.4 21.27,-0.83 3.52,-0.19 7.14,-0.38 10.56,-0.69 1.65,-0.22 3.24,-0.74 4.43,-1.76 1.19,-1.01 1.91,-2.51 2.27,-4.14 0.37,-1.64 0.44,-3.38 0.47,-5.14 0.02,-1.77 0.03,-3.52 -0.24,-5.2 -0.26,-1.67 -0.84,-3.29 -2.01,-4.37 -1.16,-1.07 -2.83,-1.65 -4.52,-1.6 z m 0.09,-1.37 c 0.93,0.14 1.85,0.33 2.76,0.6 0.91,0.29 1.8,0.76 2.54,1.42 1.52,1.33 2.23,3.27 2.57,5.08 0.35,1.84 0.38,3.67 0.41,5.45 0.02,1.8 0.01,3.64 -0.36,5.51 -0.38,1.84 -1.23,3.79 -2.79,5.14 -1.55,1.36 -3.5,1.98 -5.35,2.26 -0.95,0.14 -1.83,0.19 -2.72,0.26 l -2.67,0.19 -5.34,0.32 c -7.11,0.41 -14.23,0.65 -21.35,0.75 -3.56,0.04 -7.12,0.05 -10.69,0.01 -1.78,-0.02 -3.56,-0.06 -5.35,-0.12 l -2.67,-0.1 c -0.86,-0.02 -1.86,-0.07 -2.8,-0.28 -0.95,-0.21 -1.88,-0.57 -2.73,-1.09 -0.84,-0.53 -1.59,-1.22 -2.17,-2.01 -1.18,-1.59 -1.77,-3.43 -2.15,-5.22 -0.38,-1.81 -0.54,-3.61 -0.66,-5.41 -0.1,-1.82 -0.06,-3.67 0.3,-5.52 0.36,-1.83 1.13,-3.72 2.55,-5.14 1.41,-1.42 3.31,-2.21 5.15,-2.56 0.93,-0.18 1.87,-0.26 2.76,-0.31 0.9,-0.07 1.79,-0.1 2.68,-0.15 1.79,-0.08 3.57,-0.13 5.35,-0.17 7.13,-0.13 14.24,-0.05 21.36,0.08 3.56,0.07 7.12,0.16 10.67,0.3 l 5.34,0.22 c 0.89,0.05 1.78,0.08 2.67,0.15 0.92,0.08 1.8,0.21 2.69,0.34 z"
                    className="st12"/>
              </g>
            </g>
          </g>
          <path
              style={{fill: "white"}}
              id="path178"
              d="m 441.91,214.82 c 2.82,1.74 6.49,2.03 9.54,0.74 -0.02,2.46 -2.38,4.65 -4.84,4.48 -2.46,-0.18 -5.03,-2.78 -4.7,-5.22 z"
              className="st12"/>
          <g
              id="g180">
            <path
                style={{fill: "white"}}
                id="path182"
                d="m 431.94,209.17 c -0.1,-0.18 -0.05,-0.24 0,-0.29 l 0.14,-0.17 c 0.1,-0.11 0.2,-0.22 0.31,-0.32 0.22,-0.21 0.49,-0.33 0.75,-0.45 0.26,-0.14 0.55,-0.17 0.83,-0.16 0.28,0 0.55,-0.07 0.82,-0.08 0.54,-0.02 1.08,0.01 1.58,0.16 0.25,0.06 0.48,0.17 0.66,0.39 0.09,0.1 0.18,0.2 0.26,0.3 l 0.12,0.15 c 0.02,0.03 0.04,0.04 0.07,0.06 0.03,0 0.01,0.1 0.11,-0.03 0.34,0.17 0.47,0.59 0.3,0.92 -0.17,0.33 -0.59,0.47 -0.92,0.3 0.06,-0.17 -0.02,-0.07 -0.04,-0.08 -0.03,0.01 -0.06,0.01 -0.1,0.03 l -0.21,0.1 c -0.13,0.06 -0.22,0.01 -0.31,-0.01 -0.16,-0.1 -0.32,-0.22 -0.49,-0.27 -0.34,-0.13 -0.69,-0.22 -1.03,-0.23 -0.17,0 -0.34,-0.01 -0.49,0.06 -0.15,0.08 -0.29,0.17 -0.43,0.22 -0.14,0.05 -0.26,0.14 -0.39,0.2 -0.06,0.03 -0.13,0.07 -0.19,0.1 l -0.09,0.06 c -0.03,0.03 -0.04,0.06 -0.22,-0.05 l -0.37,-0.22 c -0.28,-0.18 -0.51,-0.43 -0.67,-0.69 z"
                className="st12"/>
          </g>
          <g
              id="g184">
            <path
                style={{fill: "white"}}
                id="path186"
                d="m 454.42,208.57 c -0.03,-0.21 0.04,-0.23 0.09,-0.26 l 0.17,-0.11 0.36,-0.2 c 0.25,-0.13 0.52,-0.18 0.79,-0.25 0.27,-0.07 0.55,-0.05 0.82,0 0.27,0.04 0.54,0.02 0.81,0.03 0.53,0.04 1.09,0.11 1.59,0.33 0.26,0.1 0.5,0.25 0.66,0.5 l 0.24,0.36 0.1,0.19 c 0.02,0.03 0.04,0.05 0.06,0.08 0.03,0.01 0,0.1 0.13,0 0.29,0.24 0.32,0.68 0.08,0.97 -0.24,0.29 -0.68,0.32 -0.97,0.08 0.09,-0.15 0,-0.07 -0.02,-0.09 -0.03,0 -0.06,0 -0.1,0.01 l -0.22,0.06 c -0.14,0.04 -0.2,-0.01 -0.28,-0.05 -0.13,-0.12 -0.27,-0.25 -0.44,-0.32 -0.32,-0.17 -0.68,-0.29 -1.05,-0.34 -0.18,-0.02 -0.37,-0.06 -0.56,-0.01 -0.18,0.06 -0.36,0.13 -0.54,0.14 -0.18,0.03 -0.34,0.1 -0.52,0.12 l -0.26,0.06 -0.13,0.04 c -0.04,0.02 -0.07,0.05 -0.21,-0.12 l -0.17,-0.2 c -0.22,-0.3 -0.37,-0.66 -0.43,-1.02 z"
                className="st12"/>
          </g>
          <g
              id="g188">
            <g
                id="g190">
              <path
                  style={{fill: highlightColor}}
                  id="path192"
                  d="m 415.45,230.92 c 1.36,-0.05 2.69,-0.01 4.03,0.03 1.34,0.04 2.67,0.07 4,0.15 0.24,0.01 0.43,0.22 0.41,0.46 -0.01,0.24 -0.22,0.43 -0.46,0.41 -2.66,-0.12 -5.33,-0.36 -7.96,-0.36 l -0.01,0 c -0.19,0 -0.34,-0.15 -0.34,-0.34 -0.01,-0.2 0.14,-0.35 0.33,-0.35 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path194"
                  d="m 431.46,231.47 7.99,0.26 0.01,0 c 0.25,0.01 0.44,0.22 0.43,0.46 -0.01,0.25 -0.22,0.44 -0.46,0.43 l 0,0 -8.01,-0.27 c -0.24,-0.01 -0.44,-0.21 -0.43,-0.46 0.01,-0.24 0.22,-0.43 0.47,-0.42 l 0,0 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path196"
                  d="m 447.42,231.68 c 1.32,-0.08 2.64,-0.16 3.97,-0.28 l 3.99,-0.34 c 0.26,-0.02 0.48,0.17 0.5,0.42 0.02,0.25 -0.17,0.48 -0.42,0.5 l -0.01,0 c -2.66,0.21 -5.3,0.46 -7.99,0.6 -0.25,0.01 -0.46,-0.18 -0.48,-0.43 -0.01,-0.25 0.18,-0.46 0.42,-0.48 l 0.02,0.01 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path198"
                  d="m 463.38,230.53 c 2.67,-0.13 5.37,-0.17 8.03,-0.06 l 0.01,0 c 0.27,0.01 0.48,0.24 0.47,0.51 -0.01,0.27 -0.23,0.47 -0.5,0.47 -1.34,-0.03 -2.66,-0.07 -3.98,-0.06 -1.33,0.01 -2.65,0.05 -3.98,0.11 -0.26,0.01 -0.49,-0.19 -0.5,-0.46 -0.02,-0.27 0.18,-0.5 0.45,-0.51 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path200"
                  d="m 479.23,230.11 c 2.44,-0.69 4.38,-2.61 5.7,-4.82 0.13,-0.21 0.41,-0.28 0.62,-0.15 0.21,0.13 0.28,0.41 0.15,0.62 l 0,0 c -0.71,1.17 -1.56,2.27 -2.6,3.19 -1.03,0.92 -2.27,1.65 -3.62,2.04 -0.24,0.07 -0.5,-0.07 -0.57,-0.31 -0.07,-0.24 0.07,-0.49 0.32,-0.57 l 0,0 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path202"
                  d="m 487.66,217.98 c 0.53,-2.57 0.75,-5.22 0.66,-7.84 -0.01,-0.24 0.18,-0.45 0.42,-0.46 0.24,-0.01 0.45,0.18 0.46,0.42 l 0,0.01 c 0.08,2.7 -0.13,5.4 -0.67,8.05 -0.05,0.24 -0.28,0.39 -0.52,0.34 -0.25,-0.04 -0.4,-0.27 -0.35,-0.52 l 0,0 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path204"
                  d="m 486.67,202.55 c -1.06,-2.36 -2.82,-4.39 -5.09,-5.59 -0.22,-0.11 -0.3,-0.38 -0.19,-0.6 0.11,-0.22 0.38,-0.3 0.6,-0.19 l 0.01,0 c 2.46,1.29 4.36,3.51 5.47,6 l 0,0.01 c 0.1,0.22 0,0.48 -0.22,0.58 -0.22,0.11 -0.48,0.01 -0.58,-0.21 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path206"
                  d="m 474.03,195.24 c -2.65,-0.23 -5.3,-0.36 -7.96,-0.46 -0.25,-0.01 -0.45,-0.22 -0.44,-0.47 0.01,-0.25 0.22,-0.45 0.47,-0.44 l 0,0 c 2.67,0.1 5.34,0.24 8.01,0.47 0.25,0.02 0.43,0.24 0.41,0.49 -0.02,0.25 -0.25,0.44 -0.49,0.41 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path208"
                  d="m 458.06,194.63 -7.99,0.02 -0.01,0 c -0.26,0 -0.47,-0.21 -0.47,-0.47 0,-0.26 0.21,-0.47 0.47,-0.47 l 0,0 8.01,-0.01 c 0.25,0 0.46,0.21 0.46,0.46 0,0.26 -0.2,0.47 -0.47,0.47 l 0,0 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path210"
                  d="m 442.08,194.79 -7.99,0.22 c -0.27,0.01 -0.49,-0.2 -0.5,-0.46 -0.01,-0.27 0.2,-0.49 0.46,-0.5 l 0,0 8,-0.21 c 0.26,-0.01 0.48,0.2 0.49,0.46 0.01,0.26 -0.2,0.49 -0.46,0.49 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path212"
                  d="m 426.09,195.29 -8,0.25 c -0.26,0.01 -0.48,-0.19 -0.49,-0.46 -0.01,-0.26 0.2,-0.48 0.46,-0.49 l 8,-0.28 c 0.27,-0.01 0.49,0.2 0.51,0.47 0,0.27 -0.21,0.5 -0.48,0.51 l 0,0 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path214"
                  d="m 410.63,196.99 c -2.1,1.45 -3.44,3.82 -4.29,6.27 -0.08,0.23 -0.33,0.36 -0.57,0.28 -0.24,-0.08 -0.36,-0.34 -0.28,-0.57 0.44,-1.29 1.01,-2.55 1.76,-3.71 0.75,-1.16 1.69,-2.22 2.86,-3.03 0.21,-0.14 0.49,-0.09 0.64,0.12 0.15,0.21 0.09,0.5 -0.12,0.64 l 0,0 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path216"
                  d="m 404.92,211 c -0.03,1.32 -0.11,2.64 -0.04,3.96 0.05,1.32 0.17,2.64 0.37,3.94 0.03,0.21 -0.11,0.41 -0.33,0.44 -0.21,0.03 -0.41,-0.11 -0.44,-0.32 -0.22,-1.33 -0.35,-2.68 -0.42,-4.03 -0.08,-1.35 -0.02,-2.7 0,-4.04 0,-0.24 0.2,-0.43 0.44,-0.43 0.24,0 0.43,0.2 0.43,0.44 l 0,0 -0.01,0.04 z"
                  className="st12"/>
              <path
                  style={{fill: highlightColor}}
                  id="path218"
                  d="m 407.66,226.35 c 0.66,1.12 1.48,2.15 2.5,2.94 1.01,0.8 2.21,1.34 3.48,1.55 0.16,0.03 0.27,0.18 0.25,0.34 -0.03,0.16 -0.18,0.27 -0.34,0.25 l 0,0 c -1.36,-0.23 -2.68,-0.79 -3.77,-1.64 -1.1,-0.84 -1.99,-1.93 -2.69,-3.1 -0.1,-0.16 -0.05,-0.37 0.12,-0.47 0.17,-0.1 0.35,-0.04 0.45,0.13 -0.01,-0.01 0,0 0,0 z"
                  className="st12"/>
            </g>
          </g>
        </g>
        <g
            id="g220"
            transform="translate(-245.37674,452.50904)">
          <path
              style={{fill: highlightColor}}
              id="path222"
              d="m 419.88,225.91 c 0.03,0.27 -0.09,0.39 -0.2,0.53 l -0.33,0.41 -0.62,0.84 c -0.19,0.29 -0.39,0.57 -0.6,0.85 l -0.58,0.87 c -0.75,1.17 -1.58,2.31 -2.07,3.62 -0.54,1.29 -0.99,2.6 -1.33,3.95 -0.15,0.68 -0.33,1.35 -0.59,2.01 -0.18,0.67 -0.56,1.34 -0.49,2.05 l 0.07,0.74 c 0.05,0.58 -0.37,1.09 -0.95,1.14 -0.58,0.05 -1.09,-0.37 -1.14,-0.95 -0.04,-0.47 0.23,-0.9 0.65,-1.07 0.19,-0.07 0.08,-0.18 0.09,-0.27 -0.01,-0.09 -0.02,-0.19 -0.04,-0.28 -0.04,-0.19 -0.08,-0.38 -0.11,-0.58 -0.04,-0.38 0.01,-0.76 0.07,-1.13 0.16,-0.73 0.39,-1.45 0.57,-2.17 0.41,-1.44 0.93,-2.84 1.56,-4.19 0.68,-1.32 1.25,-2.71 2.07,-3.96 0.42,-0.62 0.83,-1.24 1.32,-1.82 l 0.74,-0.85 0.38,-0.41 c 0.13,-0.14 0.24,-0.29 0.52,-0.29 0.52,0 0.94,0.39 1,0.9 l 0.01,0.06 z"
              className="st12"/>
        </g>
        <g
            id="g224"
            transform="translate(-245.37674,452.50904)">
          <path
              style={{fill: highlightColor}}
              id="path226"
              d="m 458.89,225.81 c -0.1,1.22 -0.81,2.2 -1.3,3.27 -0.3,0.51 -0.62,1 -0.94,1.5 l -1.07,1.41 c -1.5,1.83 -3.37,3.25 -5.19,4.67 -1.86,1.38 -3.86,2.55 -5.92,3.57 -0.52,0.24 -1.03,0.52 -1.56,0.73 -0.53,0.22 -1.08,0.39 -1.62,0.56 l -1.65,0.48 c -0.28,0.07 -0.55,0.15 -0.82,0.23 l -0.41,0.12 c -0.13,0.06 -0.3,0.02 -0.37,0.22 l -0.01,0.04 c -0.21,0.57 -0.85,0.85 -1.42,0.64 -0.57,-0.21 -0.85,-0.85 -0.64,-1.42 0.21,-0.57 0.85,-0.85 1.42,-0.64 0.06,0.02 0.13,0.06 0.18,0.09 0.19,0.12 0.28,-0.05 0.41,-0.09 0.12,-0.07 0.25,-0.14 0.37,-0.21 l 0.72,-0.46 c 0.48,-0.3 0.99,-0.52 1.5,-0.72 1.03,-0.41 2.07,-0.8 3.06,-1.29 1.99,-0.95 3.92,-2.03 5.72,-3.29 1.83,-1.22 3.36,-2.81 4.74,-4.47 l 0.99,-1.28 c 0.29,-0.45 0.59,-0.9 0.9,-1.34 l 0.8,-1.4 0.34,-0.73 c 0.12,-0.24 0.21,-0.49 0.51,-0.67 l 0.24,-0.14 c 0.33,-0.19 0.75,-0.08 0.94,0.25 0.06,0.1 0.09,0.23 0.08,0.37 z"
              className="st12"/>
        </g>
        <path
            style={{fill: highlightColorLight, stroke: highlightColor, strokeLinecap: "round", strokeMiterlimit: 10}}
            id="path228"
            d="m 165.37326,695.82904 c -0.33,-0.18 -0.67,-0.37 -0.85,-0.7 -0.15,-0.28 -0.19,-0.6 -0.2,-0.92 -0.02,-0.47 0.02,-0.95 0.24,-1.36 0.29,-0.54 0.88,-0.88 1.49,-0.97 0.61,-0.09 1.23,0.06 1.79,0.31 0.24,0.11 0.48,0.24 0.63,0.46 0.13,0.18 0.17,0.41 0.2,0.63 0.32,2.2 -1.3,3.67 -3.3,2.55 z"
            className="st22"/>
        <path
            style={{fill: highlightColorLight, stroke: highlightColor, strokeLinecap: "round", strokeMiterlimit: 10}}
            id="path230"
            d="m 193.30326,693.50904 c -0.3,-0.39 -1.04,-0.87 -1.45,-0.86 -0.67,0.01 -1.54,0.1 -2.12,0.53 -0.63,0.46 -0.73,1.66 -0.3,2.49 0.44,0.85 1.28,1.24 2.03,1.16 0.75,-0.08 1.44,-0.61 1.84,-1.4 0.29,-0.56 0.4,-1.41 0,-1.92 z"
            className="st22"/>
        <g
            transform="translate(17.24978,635.97785)"
            id="g116-0">
          <text
              style={{fontSize: "18px", fontFamily: "Roboto-LightItalic", fill: highlightColor}}
              id="text118-9"
              className="st12 st19 st20"
              transform="translate(68.5648,123.2395)">Insert a table here.
          </text>
        </g>
      </g>
      < g transform="translate(-49.992128,208.73141)" id="layer1-6"/>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 744.09448819 1052.3622047";
  }
}

export default withStyles(svgIconStyles)(DnDInsertTableValueChartIcon);
