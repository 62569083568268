/* ViewServices.ts.<extension>
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by lorenzo.auer, März 2020
 */
import {modelStore} from "../../core/stores/ModelStore";
import {deleteResource, fakeWriteResult, get, post, put} from "../../core/utils/RESTCallUtil";
import localViewStore from "../stores/LocalViewStore";
import {authenticationExecutorRead, baseurl, load} from "../actions/RESTCallActionCreatorsBase";
import {UUID, ViewBody, WriteResult} from "../../api/api";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {DeleteNavigationItemAction} from "../../core/actions/NavigationActions";
import {generateUUID} from "../../common/utils/IdGenerator";
import {RemoveViewAction} from "../actions/SharedViewActions";
import Log from "../../common/utils/Logger";
import {configurationStore} from "../../core/stores/ConfigurationStore";

const log = Log.logger("ViewManagerAsyncActionCreators");

export function saveViewWithBody(body: ViewBody, isNew: boolean = true): Promise<any> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/webviews`;

  if (configurationStore.canWriteToServer()) {
    if (isNew) {
      return post(JSON.stringify(body), url, {"content-type": "application/json"});
    } else {
      return put(JSON.stringify(body), `${url}/${body.uuid}`, {"content-type": "application/json"});
    }
  } else {
    return localViewStore.putWebView(body).then(r => {
      return {commandId: "fakeCommandId"};
    });
  }
}

export function updateParentFolder(uuid: string, parentId: string = null): Promise<WriteResult<null> | void> {
  if (!configurationStore.canWriteToServer()) {
    return Promise.resolve(fakeWriteResult);
  }
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/foldermembers/${uuid}/parent`;
  return put(JSON.stringify({parentId}), url, {"content-type": "application/json"});
}

export function updateMovablesPredecessor(movableId: string, predecessorId: string): Promise<WriteResult<null> | void> {
  if (!configurationStore.canWriteToServer()) {
    return Promise.resolve(fakeWriteResult);
  }
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/foldermembers/${movableId}/predecessor`;
  return put(JSON.stringify({predecessorId}), url, {"content-type": "application/json"});
}

export function deleteMovablesPredecessor(movableId: string): Promise<WriteResult<null> | void> {
  if (!configurationStore.canWriteToServer()) {
    return Promise.resolve(fakeWriteResult);
  }
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/foldermembers/${movableId}/predecessor`;
  return deleteResource(url);
}

export function saveFolder(name: string, uuid: string, parentId: string = null, isNew: boolean = false): Promise<void | WriteResult<any>> {
  if (!configurationStore.canWriteToServer()) {
    return Promise.resolve(fakeWriteResult);
  }
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/folders`;
  const payload = {name, uuid, parentId};
  if (isNew) {
    return post(JSON.stringify(payload), url, {"content-type": "application/json"});
  } else {
    return put(JSON.stringify(payload), `${url}/${uuid}`, {"content-type": "application/json"});
  }
}

export function deleteFolder(folderUuid: string): Promise<WriteResult<null> | void> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/folders/${folderUuid}`;

  return deleteResource(url).then(writeResult => {
    if (writeResult) {
      Dispatcher.dispatch(new DeleteNavigationItemAction(writeResult.commandId, folderUuid));
    }
    return writeResult;
  });
}

export function getWebViewHierarchy(): Promise<any> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/webview_folder_tree`;

  return get(url, authenticationExecutorRead(url, "webview", "WebViewHierarchy", true, undefined))
      .then((json: Object) => {
        // TODO: json is of type MovableJSON (see server api). Type ListItemHierarchy was always wrong at this point.
        return json;
      });
}

export function loadView<T>(uuid: string, groupId: UUID): Promise<T> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/webviews/${uuid}`;
  return load("webview", uuid, url, true, groupId);
}

export function deleteView(id: string): Promise<WriteResult<null> | void> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/webviews/${id}`;

  return deleteResource(url).then(writeResponse => {
    if (writeResponse) {
      const groupId = generateUUID();
      Dispatcher.dispatch(new RemoveViewAction(id, groupId));
      Dispatcher.dispatch(new DeleteNavigationItemAction(writeResponse.commandId, id, groupId));
    }
    return writeResponse;
  });
}
