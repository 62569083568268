export const normalizeName = (name: string): string => {
  const normalizedName: string = name.replace(/^$|[^0-9A-Za-zÄäÖöÜü_]/g, "_");
  return name.match(/^\d/g) ? "_" + normalizedName : normalizedName;
};

// checks if a given string is normalized or not
// returns true if the string is okay
// false otherwise
export const isNormalized = (name: string): boolean => {
  return name.match(/^\d/g) === null && name.match(/^$|[^0-9A-Za-zÄäÖöÜü_]/g) === null;
};