import {VisualAttributeId} from "../../core/utils/Core";
import {CSSProperties} from "react";
import {object, serializable} from "serializr";
import {jsonObjectAsString} from "../../common/utils/SerializrUtils";

/**
 * defines a conditional format to apply to attribute values in a diagram; the condition is restricted to attributes visible in diagram where the ConditionalFormat is defined.
 * The attribute which will be formatted is defined by the VisualAttributeDefinition, where the conditional format is located
 */
export class ConditionalFormat {
  /** visual attribute which is evaluated */
  @serializable(object(VisualAttributeId)) operand: VisualAttributeId;
  /** filter expression to apply to the given visual attribute for a match */
  @serializable filterExpression: string;
  /** styles to merge into the attribute/element styles which are to be formatted conditionally */
  @serializable(jsonObjectAsString()) styles: CSSProperties;

  constructor(operand: VisualAttributeId, filterExpression: string, styles: CSSProperties) {
    this.operand = operand;
    this.filterExpression = filterExpression;
    this.styles = styles;
  }
}
