import * as React from "react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {Button, createStyles, Theme, Tooltip, Typography, withStyles, WithStyles} from "@material-ui/core";
import {ViewId, VisualAttributeId} from "../../core/utils/Core";
import {ConditionalFormat} from "../models/ConditionalFormat";
import ColorPickerDialog from "../../common/components/ColorPickerDialog";
import {SetConditionalFormatsAction} from "../actions/SharedViewActions";
import {showDialog} from "../../common/utils/CommonDialogUtil";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {Add, Delete} from "@material-ui/icons";
import {StyleRules} from "@material-ui/core/styles";
import MetusDialog from "../../common/components/MetusDialog";
import autobind from "autobind-decorator";
import MetusTextField from "../../common/components/MetusTextField";
import {
  FilterTextCheckerResult,
  HierarchicalFilterData,
  processFilterText
} from "../../core/utils/filter/FilterTextProcessor";
import {ViewType} from "../../common/constants/Enums";
import {getFilterTooltipText} from "../utils/Util";
import * as _ from "lodash";
import {AttributeFormatType} from "../../api/api";
import {modelStore} from "../../core/stores/ModelStore";

const log = Log.logger("common");
const renderLog = Log.logger("common", Classifier.render);

interface LocalProps {
  open: boolean;
  /** id of view which should be updated with changed conditional format */
  viewId: ViewId;
  /** the visual attribute id the conditional format is attached to */
  attributeForFormat: VisualAttributeId;
  /** visual attributes available in combo box for condition*/
  attributesForCondition: VisualAttributeId[];
  /** initial conditional formats to display */
  initialFormats: ConditionalFormat[];

  isMatrix?: boolean;
  onDialogClose?: () => void;
}

interface LocalState {
  wrongOrIncompleteConditionalFormatIndices?: number[];
  editedConditionalFormats: ConditionalFormat[];
  /** conditional format index where color picker will put its result into, none if undefined */
  colorPickerIndex?: number;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const DropDownMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styles = (theme: Theme): StyleRules => createStyles({
  deleteButton: {
    padding: "0px",
    minWidth: "34px",
    backgroundColor: "inherit"
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "[column0]auto [column1]auto [column2]auto [column3]auto [column4]auto [column5]auto",
    gridColumnGap: "15px",
    gridAutoFlow: "row",
    width: "100%"
  },
  colorRectangle: {
    alignSelf: "center",
    width: 20,
    height: 20,
    border: "1px solid rgba(0, 0, 0, .2)",
    cursor: "pointer",
  },
  gridRow: {
    padding: 0,
    minWidth: 34,
    backgroundColor: "inherit",
    gridColumn: "column5",
  }
});

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class ConditionalFormatDialog extends React.Component<StyledLocalProps, LocalState> {
  private readonly attributeFormatType: AttributeFormatType;
  private isMatrix: boolean;

  constructor(props: StyledLocalProps) {
    super(props);
    const editedConditionalFormats = this.props.initialFormats;
    this.props.isMatrix ? this.isMatrix = false : this.isMatrix = false;

    // make sure there always is a row
    if (editedConditionalFormats.length === 0) {
      const style = this.isMatrix ? {background: "#4caf50"} : {fill: "#4caf50"};
      editedConditionalFormats.push(new ConditionalFormat(this.props.attributeForFormat, "", style));
    }

    this.attributeFormatType = modelStore.getAttributeFormatType(this.props.attributeForFormat.visualTableId.tableId, this.props.attributeForFormat.attributeName);

    this.state = {
      wrongOrIncompleteConditionalFormatIndices: [],
      editedConditionalFormats: editedConditionalFormats,
    };
  }

  getDialogContent(formId: string): JSX.Element {
    return (
        <React.Fragment>
          <form id={formId} onSubmit={this.handleSubmit}>
            <div className={this.props.classes.grid}>

              {this.state.editedConditionalFormats.map((conditionalFormat, index) => {

                return (<React.Fragment>
                  <span key={"C" + index} className={this.props.classes.colorRectangle}
                        style={{backgroundColor: conditionalFormat.styles.fill}}
                        onClick={(): void => this.openColorPicker(index)}/>
                  <Typography style={{fontSize: "20px", fontWeight: "normal"}}>&rarr;</Typography>
                  <Typography style={{fontSize: "20px", fontWeight: "normal"}}>If</Typography>
                  <Typography style={{
                    fontSize: "20px",
                    fontWeight: "normal"
                  }}>{this.props.attributesForCondition[0].attributeName}</Typography>

                  <Tooltip title={getFilterTooltipText(false).replace(/<BR\/>/g, '')} enterDelay={500}>
                    <MetusTextField
                        key={"t+index"}
                        error={this.state.wrongOrIncompleteConditionalFormatIndices.indexOf(index) !== -1}
                        autoFocus
                        data-testselector={"format" + index}
                        placeholder={"e.g. >7, Abc*"}
                        value={conditionalFormat.filterExpression}
                        onChange={(evt: any): void => this.setFilterExpression(index, evt)}
                    />
                  </Tooltip>
                  <Button key={"b" + index} className={this.props.classes.deleteButton}
                          data-testselector={"delete" + index}
                          onClick={(evt: any): void => this.deleteConditionalFormat(index, evt)}>
                    <Delete/>
                  </Button>
                </React.Fragment>);

              })}

              <Button
                  key={"createRow"}
                  className={this.props.classes.gridRow}
                  data-testselector="createRow"
                  onClick={this.addConditionalFormat}>
                <Add/>
              </Button>
            </div>
          </form>

          <ColorPickerDialog open={this.state.colorPickerIndex !== undefined}
                             onChangeComplete={(color: string): void => this.changeColor(this.state.colorPickerIndex, color)}/>
        </React.Fragment>
    );
  }

  render(): JSX.Element {
    renderLog.debug("Rendering ConditionalFormatDialog", this.state, this.props);
    const title = "Edit Conditional Format for '" + this.props.attributeForFormat.attributeName + "'";
    const errorMessage = this.state.wrongOrIncompleteConditionalFormatIndices.length > 0 ? "The condition(s) marked red are not valid!" : null;
    const formId = "form.ConditionalFormatDialog";

    return <MetusDialog
        data-testselector="ConditionalFormatDialog"
        title={title}
        open={this.props.open}
        onClose={this.handleClose}
        onPrimaryButtonPressed={this.handleSubmit}
        primaryButtonName={"OK"}
        errorMessage={errorMessage}>
      <p>Multiple Expressions will be evaluated first to last in the list. If one matches, following ones are not
        evaluated anymore.</p>
      {this.getDialogContent(formId)}
    </MetusDialog>;
  }

  @autobind
  private reset(): void {
    this.setState(prevState => {
      return {};
    });
  }

  @autobind
  private handleSubmit(): void {
    this.setState(state => {
      const validatedState = this.validate(state);
      if (validatedState.wrongOrIncompleteConditionalFormatIndices.length === 0) {
        /* Trim whitespaces if number format. */
        const conditionalFormats: ConditionalFormat[] = validatedState.editedConditionalFormats.map(conditionalFormat => {
          let filterExpression = conditionalFormat.filterExpression;
          if (this.attributeFormatType === "Double") {
            filterExpression = filterExpression.replace(/\s/, "");
          }
          return {...conditionalFormat, filterExpression};
        });

        Dispatcher.dispatch(new SetConditionalFormatsAction(this.props.viewId, this.props.attributeForFormat, conditionalFormats));
        if (this.props.onDialogClose) {
          this.props.onDialogClose();
        }

        showDialog(false);
      }
      return validatedState;
    });
  }

  @autobind
  private handleClose(): void {
    this.reset();
    showDialog(false);
  }

  @autobind
  private openColorPicker(index: number): void {
    this.setState(state => ({...state, colorPickerIndex: index}));
  }

  @autobind
  private changeColor(index: number, colorHexRGB: string): void {
    this.setState((state: LocalState) => {
      if (colorHexRGB !== undefined) {
        const style = this.isMatrix ? {background: colorHexRGB} : {fill: colorHexRGB};
        state.editedConditionalFormats[index].styles = style;
      }
      state.colorPickerIndex = undefined;
      return state;
    });
  }

  @autobind
  private setFilterExpression(index: number, event: any): void {
    const newValue = event.target.value;
    this.setState(state => {
      _.remove(state.wrongOrIncompleteConditionalFormatIndices, _index => _index === index);
      state.editedConditionalFormats[index].filterExpression = newValue;
      return state;
    });
  }

  @autobind
  private addConditionalFormat(): void {
    const style = this.isMatrix ? {background: "#f43336"} : {fill: "#f43336"};
    const newFormat = new ConditionalFormat(this.props.attributeForFormat, "", style);
    this.setState(state => ({
      editedConditionalFormats: [...state.editedConditionalFormats, newFormat],
      hasWrongOrIncompleteConditionalFormat: true
    }));
  }

  @autobind
  private deleteConditionalFormat(index: number, evt: any): void {
    this.setState(state => {
      state.editedConditionalFormats.splice(index, 1);
      return state;
    });
  }

  /** recalculates if all formats are valid after changes */
  @autobind
  private validate(state: LocalState): LocalState {
    state.wrongOrIncompleteConditionalFormatIndices = [];
    state.editedConditionalFormats.forEach((cf, index) => {
      console.log("ssss: ", cf, index)
      try {
        const retVal: FilterTextCheckerResult[] = [];
        const hierarchicalFilterData: HierarchicalFilterData =
            new HierarchicalFilterData(
                cf.operand.attributeName,
                cf.filterExpression,
                null,
                ViewType.ValueChart,
                cf.operand.visualTableId.tableId);
        console.log("hierarchicalFilterData: ", cf, hierarchicalFilterData)
        const filterTextCheckerResult: FilterTextCheckerResult = processFilterText(hierarchicalFilterData);
        if (cf.filterExpression.length === 0 || !filterTextCheckerResult.isValid) {
          state.wrongOrIncompleteConditionalFormatIndices.push(index);
        }
      } catch (ex) {
        state.wrongOrIncompleteConditionalFormatIndices.push(index);
      }
    });
    return state;
  }

}

const StyledConditionalFormatDialog = withStyles(styles)(ConditionalFormatDialog);
export default StyledConditionalFormatDialog;

export function showConditionalFormatDialog(show: boolean, viewId: ViewId, attributeForFormat: VisualAttributeId, attributesForCondition: VisualAttributeId[], initialFormats: ConditionalFormat[], isMatrix?: boolean, onDialogClose?: () => void): void {
  showDialog(show, <StyledConditionalFormatDialog open={show} viewId={viewId} attributeForFormat={attributeForFormat}
                                                  attributesForCondition={attributesForCondition}
                                                  initialFormats={initialFormats} isMatrix={isMatrix}
                                                  onDialogClose={onDialogClose
                                                  }/>)
}