import * as React from "react";
import {createStyles, SvgIcon, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles/withStyles";

const styles = (theme: Theme):StyleRules => createStyles({
  root: {
    fill: theme.palette.secondary[700],
    height: "16px",
    width: "16px",
  },
});

interface LocalProps {}

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;

class FilterIcon extends React.Component<StyledLocalProps, any> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    return <SvgIcon viewBox="6 6 12 12" className={this.props.classes.root}>
      <g id="filter" stroke="none" strokeWidth="1">
        <polygon points="6,7 11,12 11,18 13,16 13,12 18,7"/>
      </g>
    </SvgIcon>;
  }
}

export default withStyles(styles)(FilterIcon);
