/* ZoomOutIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Oktober 2019
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class ZoomOutIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <g id="zoomOut" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.5">
        <polygon id="Fill-1" fill="#000000" points="7 13 17 13 17 11 7 11"></polygon>
      </g>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(ZoomOutIcon);
