import StoreBase from "../../common/stores/StoreBase";
import Log from "../../common/utils/Logger";
import {
  AttributeDefinition,
  AttributeFormatType,
  AttributeValues,
  Connection,
  DefaultStyles,
  ExtTableId,
  HierarchyEntry, ID_ATT_NAME, NAME_ATT_NAME, ReferenceAttributeDefinition,
  Table,
  TableAttributeDefinitions
} from "../../api/api";
import {AttributeType, ElementId, ElementObject, TableId, VisualTableId} from "../utils/Core";
import * as _ from "lodash";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {
  addTreeItem,
  deleteTreeItem,
  ListItemHierarchy, moveTreeItemToFolder,
  renameTreeItem,
  transformHierarchyJsonToListItemHierarchy
} from "../models/ListItemHierarchy";
import {LoadActions} from "../../modelselection/actions/ModelAsyncActionCreators";
import {NavigationActions} from "../actions/NavigationActions";
import {action, observable} from "mobx";
import {TreeItemType} from "../../common/constants/Enums";
import {TwoLevelMapToSet} from "../../common/utils/TwoLevelMapToSet";
import {Validate} from "../../common/utils/Validate";
import {getTwoElementKey, TwoElementIdKey} from "../utils/TwoElementIdKey";
import {MatrixModel} from "../../matrix/models/MatrixModel";
import {
  AttributesToDelete,
  MoveElementsToTablePayload,
  ModelDirtyAction,
  SetWriteLockAction
} from "../actions/CoreActions";
import {CoreActions, LoadConnectionsPayload, SelectModelAction} from "../actions/CoreAsyncActions";
import {configurationStore} from "./ConfigurationStore";
import {ModelInfo} from "../models/ModelInfo";

const log = Log.logger("ModelStore", Classifier.action);

export class ModelStore extends StoreBase {
  private _modelInfo?: ModelInfo;
  private _tablesWithConnectionsLoaded: ExtTableId[];
  @observable private _connections: Map<TwoElementIdKey, Connection>;
  @observable private _connectedElementIdsByElementAndTableId: TwoLevelMapToSet<ElementId, TableId, ElementId>;
  private _defaultstyles: DefaultStyles;
  @observable private _primarySelection: Set<ElementId>;
  @observable private _secondarySelection: Set<ElementId>;
  @observable private _elementsByTableId: Map<TableId, Array<ElementObject>>;
  private _tableIdByElementId: Map<ElementId, TableId>;
  public _elementByElementId: Map<ElementId, ElementObject>;
  @observable private _tableHierarchy: ListItemHierarchy;
  @observable private _attributeDefinitionsByTableId: Map<ExtTableId, AttributeDefinition[]>;
  @observable private _tableNameByTableId: Map<TableId, string>;
  @observable private _modelDirty: boolean;

  constructor() {
    super();
    this.init();
  }

  get modelDirty():boolean {
    return this._modelDirty;
  }

  public get tableNameByTableId(): Map<TableId, string> {
    return this._tableNameByTableId;
  }

  public init(modelInfo?: ModelInfo): void {
    this._modelInfo = modelInfo || new ModelInfo(null, null);
    this._tablesWithConnectionsLoaded = [];
    this._connections = new Map();
    this._elementByElementId = new Map();
    this._elementsByTableId = new Map();
    this._connectedElementIdsByElementAndTableId = new TwoLevelMapToSet();
    this._tableIdByElementId = new Map();
    this._attributeDefinitionsByTableId = new Map();
    this._primarySelection = new Set();
    this._secondarySelection = new Set();
    this._tableNameByTableId = new Map();
    this._modelDirty = false;
  }

  public getTableName(id: TableId): string {
    return this._tableNameByTableId.get(id);
  }

    duplicateElements(originalElementIds: Array<ElementId>, newElementIds: Array<ElementId>): void {
        for (let i = 0; i < originalElementIds.length; i++) {
            this.duplicateElement(originalElementIds[i], newElementIds[i]);
        }
    }

    duplicateElement(originalElementId: ElementId, newElementId: ElementId): void {
        const originalElement: ElementObject = this.getElement(originalElementId);

        const newTableId: TableId = this.getTableForElement(originalElementId);
        const newName: string = "Copy of " + originalElement[NAME_ATT_NAME];

        this.newElement(newTableId, newElementId, newName);
        const newElement: ElementObject = this.getElement(newElementId);

        Object.keys(originalElement).forEach(attributeName => {
            if (ID_ATT_NAME !== attributeName.toLowerCase() && NAME_ATT_NAME !== attributeName.toLowerCase()) {
                newElement[attributeName] = originalElement[attributeName];
            }
        });
        this.setAttributeValues([newElement]);

        const newConnections: Connection[] = [];
        const connectedElementIds: ReadonlySet<ElementId> = this.getConnectedElements(originalElementId);
        connectedElementIds.forEach(connectedElementId => {
            const originalConnection: Connection = this.getConnection(originalElementId, connectedElementId);
            let newSourceElementId: ElementId;
            let newTargetElementId: ElementId;

            if(originalConnection.sourceElementId === originalElementId) {
                newSourceElementId = newElementId;
                newTargetElementId = originalConnection.targetElementId;
            } else {
                newSourceElementId = originalConnection.sourceElementId;
                newTargetElementId = newElementId;
            }

            let newConnection: Connection = {
                    sourceElementId: newSourceElementId,
                    targetElementId: newTargetElementId,
                    strength: originalConnection.strength,
                    comment: originalConnection.comment
                }
            newConnections.push(newConnection);
        });

        this.addConnections(newConnections);
    }

    newElement(tableId: TableId, elementId: ElementId, name: string): void {
        const elements: ElementObject[] = this._elementsByTableId.get(tableId);
        if (elements === undefined) {
            throw new Error("Table must be loaded for new elements to be created");
        }
        // add to maps
        const element = observable({id: elementId, name: name});
        elements.push(element);
        this._tableIdByElementId.set(elementId, tableId);
        // add name
        this._elementByElementId.set(elementId, element);
    }

  addElementsToTable(elements: ElementObject[], tableId: TableId): void {
    if (!this._elementsByTableId.has(tableId))
      this._elementsByTableId.set(tableId, []);

    elements.forEach(element => {
      this._tableIdByElementId.set(element.id, tableId);
      let _element: ElementObject = this._elementByElementId.get(element.id);
      if (!_element) {
        _element = observable(element);
        this._elementsByTableId.get(tableId).push(_element);
        this._elementByElementId.set(_element.id, _element);
      }
    });
  }

  setAttributeValues(elements: ElementObject[]): void {
    elements.forEach(element => {
      const _element = this._elementByElementId.get(element.id);
      Object.keys(element).forEach(attributeName => {
        if ("id" !== attributeName) {
          _element[attributeName] = element[attributeName];
        }
      });
    });
  }

  private setTables(tableHierarchy: ListItemHierarchy): void {
    if (tableHierarchy.children && tableHierarchy.children.size() > 0) {
      tableHierarchy.children.forEach(node => {
        this.setTables(node.value);
      });
    } else if (tableHierarchy.type === TreeItemType.Table) {
      this._tableNameByTableId.set(tableHierarchy.id, tableHierarchy.name);
    }
  }

  /**
   * get the connection object with the given source and target,
   * @return connection object or undefined if not found
   * @param sourceElementId
   * @param targetElementId
   * @param ignoreDirection if true (default), returns a connection from targetElementId to sourceElementId too, otherwise respects the direction
   */
  public getConnection(sourceElementId: ElementId, targetElementId: ElementId, ignoreDirection: boolean = true): Connection {
    const twoElementIdKey: TwoElementIdKey = getTwoElementKey(sourceElementId, targetElementId);
    let result = this._connections.get(twoElementIdKey);
    if (ignoreDirection && !result) {
      // lookup connections in other direction too
      const twoElementIdKeyReversed: TwoElementIdKey = getTwoElementKey(targetElementId, sourceElementId);
      result = this._connections.get(twoElementIdKeyReversed);
    }
    return result;
  }

  /**
   * adds connections or deletes them if already there, this is done for all given sourceIds sourceId => targetId,
   * for each connection the status is inverted (connected => unconnected and vice versa
   */
  public toggleConnections(sourceIds: ElementId[], targetElementId: ElementId): void {
    const connectionsToAdd: Connection[] = [];
    const connectionsToDelete: Connection[] = [];

    sourceIds.forEach(sourceElementId => {
      const connection: Connection = {sourceElementId, targetElementId, strength: 1};

      if (this.isConnected(sourceElementId, targetElementId)) {
        connectionsToDelete.push(connection);
      } else {
        connectionsToAdd.push(connection);
      }
    });

    this.addConnections(connectionsToAdd);
    this.deleteConnections(connectionsToDelete);
  }

  private deleteConnections(connections: Connection[]): void {
    connections.forEach((connection: Connection) => {
      this.updateConnectedElementsCacheDeletingConnection(connection.sourceElementId, connection.targetElementId);
      const twoElementIdKey: TwoElementIdKey = getTwoElementKey(connection.sourceElementId, connection.targetElementId);
      const twoElementIdReversedKey: TwoElementIdKey = getTwoElementKey(connection.targetElementId, connection.sourceElementId);
      this._connections.delete(twoElementIdKey);
      this._connections.delete(twoElementIdReversedKey);
    });
  }

  addConnections(connections: Connection[]): void {
    connections.forEach(connection => {
      if (!this.isConnected(connection.sourceElementId, connection.targetElementId)) {
        const twoElementIdKey: TwoElementIdKey = getTwoElementKey(connection.sourceElementId, connection.targetElementId);
        this._connections.set(twoElementIdKey, connection);
        this.updateConnectedElementsCacheAddingConnection(connection.sourceElementId, connection.targetElementId);
      }
    });
  }

  private updateConnection(connection: Connection): void {
    const twoElementIdKey: TwoElementIdKey = getTwoElementKey(connection.sourceElementId, connection.targetElementId);
    const twoElementIdReversedKey: TwoElementIdKey = getTwoElementKey(connection.targetElementId, connection.sourceElementId);

    if (this._connections.has(twoElementIdKey)) {
      this._connections.set(twoElementIdKey, connection);
    }
    if (this._connections.has(twoElementIdReversedKey)) {
      this._connections.set(twoElementIdReversedKey, {
        sourceElementId: connection.targetElementId,
        targetElementId: connection.sourceElementId, ...connection
      });
    }
  }

  public isConnected(elementId1: ElementId, elementId2: ElementId): boolean {
    /* The method updateConnectedElementsCache supports both directions.
     * So it's enough to check only one direction here.
    */
    return this.getConnectedElements(elementId1).has(elementId2);
  }

  /**
   * updates the connected elements by table cache with both directions when a connection is added
   * @param sourceElementId
   * @param targetElementId
   */
  private updateConnectedElementsCacheAddingConnection(sourceElementId: ElementId, targetElementId: ElementId): void {
    try {
      // find tables for both elements
      const sourceTableId = this.getTableForElement(sourceElementId);
      Validate.isDefined(sourceTableId, "Source Element not found in any table, elementId: " + sourceElementId);
      const targetTableId = this.getTableForElement(targetElementId);
      Validate.isDefined(targetTableId, "Target Element not found in any table, elementId: " + targetElementId);
      // direction source -> target
      this._connectedElementIdsByElementAndTableId.add(sourceElementId, targetTableId, targetElementId);
      // direction target -> source
      this._connectedElementIdsByElementAndTableId.add(targetElementId, sourceTableId, sourceElementId);
    } catch (ex) {
      log.warn("Invalid connection: " + ex.message);
    }
  }

  /**
   * updates the connected elements by table cache with both directions when a connection is deleted
   * @param sourceElementId
   * @param targetElementId
   */
  private updateConnectedElementsCacheDeletingConnection(sourceElementId: ElementId, targetElementId: ElementId): void {
    // find tables for both elements
    const sourceTableId = this.getTableForElement(sourceElementId);
    Validate.isDefined(sourceTableId, "Source Element must be in a table " + sourceElementId);
    const targetTableId = this.getTableForElement(targetElementId);
    Validate.isDefined(targetTableId, "Target Element must be in a table " + targetElementId);
    // direction source -> target
    this._connectedElementIdsByElementAndTableId.delete(sourceElementId, targetTableId, targetElementId);
    // direction target -> source
    this._connectedElementIdsByElementAndTableId.delete(targetElementId, sourceTableId, sourceElementId);
  }

  public deleteElements(elementIds: ElementId[]): void {
    /* delete connections containing elements */
    const connectionsContainingElements = Array.from(this._connections.values()).filter(con => elementIds.indexOf(con.sourceElementId) !== -1 || elementIds.indexOf(con.targetElementId) !== -1);
    this.deleteConnections(connectionsContainingElements);

    /* remove from selections, TODO: should this not only update the selections instead of clearing ? */
    this._primarySelection.clear();
    this._secondarySelection.clear();

    elementIds.forEach(elementId => {

      /* Delete element */
      const tableId = this._tableIdByElementId.get(elementId);
      const elements = this._elementsByTableId.get(tableId);
      _.remove(elements, element => element.id === elementId);
      this._elementsByTableId.set(tableId, elements);
      this._tableIdByElementId.delete(elementId);
      this._elementByElementId.delete(elementId);
    });
  }

  updateSelection(elementIds: ElementId[], keepSelection: boolean, toggleSelection: boolean): void {
    if (!keepSelection) {
      this._primarySelection.clear();
    }

    let callback: (elementId: ElementId) => void = (elementId: ElementId): any => this._primarySelection.add(elementId);
    if (keepSelection && toggleSelection) {
      callback = (elementId: ElementId): void => {
        if (this._primarySelection.has(elementId)) {
          this._primarySelection.delete(elementId);
        } else {
          this._primarySelection.add(elementId);
        }
      };
    }
    elementIds.forEach(callback);

    // TODO: do not clear but update only in order to reduce the number of necessary updates
    this._secondarySelection.clear();

    this._connections.forEach(connection => {
      if (this._primarySelection.has(connection.sourceElementId)) {
        this._secondarySelection.add(connection.targetElementId);
      } else if (this._primarySelection.has(connection.targetElementId)) {
        this._secondarySelection.add(connection.sourceElementId);
      }
    });
  }

  public deleteReferenceAttributesReferencingTables(tableIds: TableId[]): void {
    this.tableNameByTableId.forEach((name, id) => {
      const attributeNamesToDelete = this.getAttributeDefinitionsByTableId(id).filter(attDef => tableIds.includes((attDef as ReferenceAttributeDefinition).referencedTableId)).map(attDef => attDef.name);
      this.deleteAttributes(id, attributeNamesToDelete);
    });
  }

  private deleteTables(tableIds: TableId[]): void {

    tableIds.forEach(tableId => deleteTreeItem(this._tableHierarchy, tableId));

    let elementIdsToDelete: ElementId[] = [];
    for (const tableId of tableIds) {
      const elementIdsForTable: ElementId[] = this.getElementsForTable(tableId).map((element: ElementObject) => {
        return element.id;
      });

      elementIdsToDelete = elementIdsToDelete.concat(elementIdsForTable);
    }
    // elements, element values and connections are deleted with this step
    this.deleteElements(elementIdsToDelete);

    for (const tableId of tableIds) {
      if (this._tablesWithConnectionsLoaded.includes(tableId))
        this._tablesWithConnectionsLoaded.splice(this._tablesWithConnectionsLoaded.indexOf(tableId), 1);

      this._elementsByTableId.delete(tableId);

      this._attributeDefinitionsByTableId.delete(tableId);

      this._tableNameByTableId.delete(tableId);
    }

    for (const tableId of tableIds) {
      const attributeNames: string[] = [];

      for (const tableAttributeDefinitions of this.getTableAttributeDefinitions([tableId])) {
        for (const attribute of tableAttributeDefinitions.attributes)
          attributeNames.push(attribute.name);
      }

      this.deleteAttributes(tableId, attributeNames);
    }
    this.deleteReferenceAttributesReferencingTables(tableIds);
  }

  public deleteAttributes(tableId: TableId, attributeNames: string[]): void {
    const attributesForTable: AttributeDefinition[] = this._attributeDefinitionsByTableId.get(tableId);
    if (attributesForTable !== undefined) {
      for (let i: number = attributesForTable.length - 1; i >= 0; i--) {
        if (attributeNames.includes(attributesForTable[i].name)) {
          attributesForTable.splice(i, 1);
        }
      }
    }

    // the ElementObject in this._elementsByTableId should also be updated by this, because it is the same object
    const elementObjectsForTable: ElementObject[] = this._elementsByTableId.get(tableId);
    if (elementObjectsForTable !== undefined) {
      for (let i: number = elementObjectsForTable.length - 1; i >= 0; i--) {
        for (const attributeName of attributeNames) {
          if (elementObjectsForTable[i].hasOwnProperty(attributeName)) {
            delete elementObjectsForTable[i][attributeName];
            const a = "";
          }
        }
      }
    }
  }

  getConnectedElements(id: ElementId): ReadonlySet<ElementId> {
    return this._connectedElementIdsByElementAndTableId.getAll(id);
  }

  public getConnectedElementIdsToTable(id: ElementId, tableId: string): ElementId[] {
    const connectedElementSet: Set<ElementId> = this._connectedElementIdsByElementAndTableId.get(id, tableId);
    return connectedElementSet ? Array.from(connectedElementSet) : [];
  }

  public updateAttributeDefinitions(tableAttributeDefinitions: TableAttributeDefinitions[]): void {
    tableAttributeDefinitions.forEach(tableAttributeDefinition => {
      tableAttributeDefinition.attributes = this.sanitizeAttributeDefinition(tableAttributeDefinition.attributes);
      this._attributeDefinitionsByTableId.set(tableAttributeDefinition.tableId, tableAttributeDefinition.attributes);
    });
  }

  private sanitizeAttributeDefinition(attributeDefinitions: AttributeDefinition[]): AttributeDefinition[] {
    return attributeDefinitions.map(attributeDefinition => {
      return {...attributeDefinition, type: this.mapper(attributeDefinition.type)};
    });
  }

  private mapper = (type: string): AttributeType => {
    let retVal: AttributeType = <AttributeType>type;
    if (type === "Normal") {
      retVal = "String";
    } else if (type === "Reference") {
      retVal = "Derived"
    }
    return retVal;
  }

  public getTableAttributeDefinitions(tableIds: TableId[]): TableAttributeDefinitions[] {
    return tableIds.map(id => {
      const attributes = this._attributeDefinitionsByTableId.get(id) || [];
      return {tableId: id, attributes: attributes};
    });
  }

  /**
   * @return AttributeDefinitions for the given tableId, or empty list if tableId not found
   * @param tableId
   */
  public getAttributeDefinitionsByTableId(tableId: TableId): AttributeDefinition[] {
    return this._attributeDefinitionsByTableId.get(tableId) || [];
  }

  public getCommonAttributeNames(tableIds: TableId[]): string[] {
    if (tableIds.length == 0) {
      return [];
    }
    const attributeSets = tableIds.map(tableId => {
      const setResult = new Set<string>();
      this.getAttributeDefinitionsByTableId(tableId).forEach(attDef => setResult.add(attDef.name))
      return setResult;
    });
    const resultSet = attributeSets.reduce((prev, current) => new Set([...current].filter(c => prev.has(c))));
    return Array.from(resultSet);
  }

  get attributeDefinitions(): Map<ExtTableId, AttributeDefinition[]> {
    return this._attributeDefinitionsByTableId;
  }

  get connections(): Connection[] {
    return Array.from(this._connections.values());
  }

  get modelInfo(): ModelInfo | undefined {
    return this._modelInfo;
  }

  get defaultStyles(): DefaultStyles {
    return this._defaultstyles;
  }

  get tablesWithConnectionsLoaded(): ExtTableId[] {
    return this._tablesWithConnectionsLoaded;
  }

  get primarySelection(): Set<ElementId> {
    return this._primarySelection;
  }

  get secondarySelection(): Set<ElementId> {
    return this._secondarySelection;
  }

  get tableHierarchy(): ListItemHierarchy {
    return this._tableHierarchy;
  }

  get elementsByTableId(): Map<TableId, ElementObject[]> {
    return this._elementsByTableId;
  }

  public getCommonAttributeDefinitions(sourceTableId: TableId, targetTableId: TableId): AttributeDefinition[] {
    const sourceAttDefs: AttributeDefinition[] = modelStore.getAttributeDefinitionsByTableId(sourceTableId);
    const targetAttDefs: AttributeDefinition[] = modelStore.getAttributeDefinitionsByTableId(targetTableId);
    const commonAttDefs: AttributeDefinition[] = sourceAttDefs.filter((sourceAttDef) => {
      return targetAttDefs.find((targetAttDef) => {
        return targetAttDef.name === sourceAttDef.name && targetAttDef.type === sourceAttDef.type
      }) !== undefined
    });
    return commonAttDefs;
  }

  @action accept(actionParam: CoreActions | LoadActions | SelectModelAction | NavigationActions | SetWriteLockAction | ModelDirtyAction): void {
    log.debug("ModelStore accepting", actionParam);
    switch (actionParam.type) {
      case "selectelements":
        const {elementIds, extend, toggle} = actionParam.payload;
        log.debug("Got element selection", elementIds, extend);
        this.updateSelection(elementIds, extend, toggle);
        this.notify(actionParam.type);
        break;
      case "clearselection":
        log.debug("Got clearselection");
        this.clearSelection();
        this.notify(actionParam.type);
        break;
      case "updateAttributeValues": {
        log.debug("updateAttributeValues", actionParam.payload);
        const element: ElementObject = actionParam.payload;
        const tableId: TableId = actionParam.resourceId;
        this.addElementsToTable([element], tableId);
        this.setAttributeValues([element]);
        break;
      }
      case "newElement":
        log.debug("newElement", actionParam.payload);
        const {elementId, tableId, name} = actionParam.payload;
        this.newElement(tableId, elementId, name);
        this.notify(actionParam.type);
        break;
      case "moveElementsToTable":
        this.moveElementsToTable(actionParam.payload);
        this.notify(actionParam.type);
        break;
      case "deleteElements": {
        log.debug("deleteElements", actionParam.payload);
        const elementIds: ElementId[] = actionParam.payload;
        this.deleteElements(elementIds);
        this.notify(actionParam.type);
        break;
      }
      case "deleteTables": {
        log.debug("deleteTables", actionParam.payload);
        const tableIds: TableId[] = actionParam.payload;
        this.deleteTables(tableIds);
        this.notify(actionParam.type);
        break;
      }
      case "deleteAttributes":
        log.debug("deleteAttributes", actionParam.payload);
        const attrsToDelete: AttributesToDelete = actionParam.payload;
        attrsToDelete.tableIds.forEach((tableId, index) => {
          this.deleteAttributes(tableId, attrsToDelete.attributeNames[index]);
        });
        this.notify(actionParam.type);
        break;
      case "addTableToView":
        const payload = actionParam.payload;
        if (!this._elementsByTableId.has(payload.visualTableId.tableId))
          this._elementsByTableId.set(payload.visualTableId.tableId, []);
        this.notify(actionParam.type);
        break;
      case "createConnection": {
        const connection: Connection = actionParam.payload;
        this.addConnections([connection]);
        break;
      }
      case "updateConnection": {
        const connection: Connection = actionParam.payload;
        this.updateConnection(connection);
        break;
      }
      case "deleteConnection": {
        const connection: Connection = actionParam.payload;
        this.deleteConnections([connection]);
        break;
      }
      case "toggleConnections":
        const {sourceElementIds, targetElementId} = actionParam.payload;
        this.toggleConnections(sourceElementIds, targetElementId);
        this.notify(actionParam.type);
        break;

        // LOAD ACTIONS

      case "selectmodel":
        this.init(actionParam.payload as ModelInfo);
        this.notify(actionParam.type);
        break;
      case "loadMatrix":
        log.debug("matrix", actionParam.payload);
        const matrix: MatrixModel = actionParam.payload;
        const tableIds = [...matrix.columnHierarchy.tables, ...matrix.rowHierarchy.tables];
        tableIds.forEach((visualTableId: VisualTableId) => {
          if (!this._elementsByTableId.has(visualTableId.tableId))
            this._elementsByTableId.set(visualTableId.tableId, []);
        });
        this.notify(actionParam.type);
        break;
      case "defaultstyles":
        this._defaultstyles = actionParam.payload;
        // TODO: compatibility to old versions of server, remove if not needed anymore
        if (actionParam.payload.hasOwnProperty("node")) {
          this._defaultstyles.visualElement = (this._defaultstyles as any).node;
        }
        log.debug("Got list of default styles", this._defaultstyles);
        this.notify(actionParam.type);
        break;
      case "loadFoldersAndTables":
        log.debug("Got table_folder_tree");
        this._tableHierarchy = transformHierarchyJsonToListItemHierarchy(actionParam.payload as HierarchyEntry);
        this.setTables(this._tableHierarchy);
        this.notify(actionParam.type);
        break;
      case "attributeDefinitions":
        log.debug("Got tableattributes");
        this.updateAttributeDefinitions(actionParam.payload as TableAttributeDefinitions[]);
        this.notify(actionParam.type);
        break;
      case "loadAttributeValues":
        log.debug("loadAttributeValues", actionParam.payload);
        const tables: Table[] = actionParam.payload;
        tables.forEach((table: Table) => {
          const elements = attributeValues2ElementObjects(table.attributeValues);
          this.addElementsToTable(elements, table.tableId);
          this.setAttributeValues(elements);
        });
        break;
      case "loadConnections":
        const {connections, newTableIds}: LoadConnectionsPayload = actionParam.payload;
        log.debug("Got model connections", connections, newTableIds);
        if (Array.isArray(connections) && connections.length > 0) {
          this.addConnections(connections);
        }
        this._tablesWithConnectionsLoaded.push(...newTableIds);
        this.notify(actionParam.type);
        break;
      case "addTreeItem":
        addTreeItem(this._tableHierarchy, actionParam.resourceId, actionParam.payload);
        this.notify(actionParam.type);
        break;
      case "renameTreeItem":
        renameTreeItem(this._tableHierarchy, actionParam.resourceId, actionParam.payload);
        this.notify(actionParam.type);
        break;
      case "moveTreeItemToFolder":
        const sourceId = actionParam.resourceId;
        const targetId = actionParam.payload;
        log.debug(`ModelStore: moving item ${sourceId} to new parent ${targetId}`);
        moveTreeItemToFolder(this._tableHierarchy, sourceId, targetId);
        this.notify(actionParam.type);
        break;
      case "deleteTreeItem":
        log.debug(`ModelStore: deleting navigation item ${actionParam.resourceId}`);
        deleteTreeItem(this._tableHierarchy, actionParam.resourceId);
        this.notify(actionParam.type);
        break;
      case "modelDirty":
        log.debug("Model dirty");
        this._modelDirty = true;
        this.notify(actionParam.type);
        break;
      case "setWriteLock":
        const writeLock = actionParam.payload;
        log.debug("Set Write Lock", writeLock);
        const isSameChangeSet = writeLock.changeSetId === configurationStore.writeLock?.changeSetId;
        this._modelDirty = this._modelDirty || isSameChangeSet && writeLock.committed;
        this.notify(actionParam.type);
        break;
      case "duplicateElements":
        this.duplicateElements(actionParam.payload.originalElementIds, actionParam.payload.newElementIds);
        this.notify(actionParam.type);
        break;
    }
    }

  //noinspection JSUnusedGlobalSymbols
  /**
   * @param tableId
   * @param tableId
   * @param attributeName
   * @returns AttributeDefinition definition this attribute
   */
  public getAttributeDefinition(tableId: TableId, attributeName: string): AttributeDefinition {
    if (!this._attributeDefinitionsByTableId.has(tableId))
      return undefined;

    for (const attributeDefinition of this._attributeDefinitionsByTableId.get(tableId)) {
      if (attributeDefinition.name === attributeName) {
        return attributeDefinition;
      }
    }

    return undefined;
  }

  public tableHasAttributeDefinition(tableId: string, attributeName: string): boolean {
    if (!this._attributeDefinitionsByTableId.has(tableId))
      return undefined;

    for (const tableAttribute of this._attributeDefinitionsByTableId.get(tableId))
      if (tableAttribute.name === attributeName || "name" === attributeName)
        return true;

    return false;
  }

  public getTableForElement(elementId: ElementId): TableId {
    return this._tableIdByElementId.get((elementId));
  }

  public getAttributeFormatType(tableId: TableId, attributeName: string): AttributeFormatType {
    if (attributeName.toLowerCase() === "name")
      return "String";

    const attributeDefinition: AttributeDefinition = this.getAttributeDefinition(tableId, attributeName);
    if (attributeDefinition !== undefined)
      return attributeDefinition.formatType;

    return undefined;
  }

  public isConnectedToPrimarySelection(id: ElementId): boolean {
    let result = false;
    for (const selectedId of modelStore.primarySelection.values()) {
      if (modelStore.isConnected(id, selectedId)) {
        result = true;
        break;
      }
    }
    return result;
  }

  public getElementsById(ids: ElementId[]): ElementObject[] {
    return ids.map(id => this._elementByElementId.get(id));
  }

  public getElement(id: ElementId): ElementObject {
    return this._elementByElementId.get(id);
  }

  public getElementsForTable(tableId: TableId): Array<ElementObject> {
    if (this._elementsByTableId.has(tableId))
      return this._elementsByTableId.get(tableId);

    return [];
  }

  private moveElementsToTable(elementsToMove: MoveElementsToTablePayload): void {
    const {previousToNewElementIdsMap, sourceTableId, targetTableId, isCopy} = elementsToMove;
    const elementObjects: ElementObject[] = Array.from(previousToNewElementIdsMap.keys()).map((id) => modelStore.getElement(id));
    const elementsOfTargetTable: ElementObject[] = this._elementsByTableId.get(targetTableId);
    const sourceAttDefs: AttributeDefinition[] = modelStore.getAttributeDefinitionsByTableId(sourceTableId);
    const targetAttDefNames: string[] = modelStore.getAttributeDefinitionsByTableId(targetTableId).map(attDef => attDef.name);
    const newElementObjectsWithMovableAttValues: ElementObject[] = elementObjects.map((elementObject) => {
      const newElementObject: ElementObject = {id: previousToNewElementIdsMap.get(elementObject.id)};
      newElementObject.name = elementObject["name"];
      for (const attDef of sourceAttDefs) {
        if (targetAttDefNames.includes(attDef.name) && !elementsToMove.lostAttributeNames.includes(attDef.name)) {
          newElementObject[attDef.name] = elementObject[attDef.name];
        }
      }
      const connectedElementIds = [...this.getConnectedElements(elementObject.id)];
      const connections = connectedElementIds.map((connectedElementId) => {
        return this.getConnection(elementObject.id, connectedElementId);
      });
      if (!isCopy) {
        this.deleteConnections(connections);
        this.deleteElements([elementObject.id]);
      }
      const newElementObservable = observable(newElementObject);
      elementsOfTargetTable.push(newElementObservable);
      this._tableIdByElementId.set(newElementObservable.id, targetTableId);
      this._elementByElementId.set(newElementObservable.id, newElementObservable);
      for (const connection of connections) {
        if (connection.sourceElementId == elementObject.id) {
          connection.sourceElementId = newElementObservable.id;
        } else {
          connection.targetElementId = newElementObservable.id;
        }
      }
      this.addConnections(connections)
      return newElementObservable;
    });
  }

  reset(keepCurrentModel?: boolean): void {
    if (keepCurrentModel) {
      this.init(this.modelInfo);
    } else {
      this.init();
    }
  }

  /** clear selection
   *
   * @returns {boolean} true if something has changed
   */
  private clearSelection(): boolean {
    let result: boolean = false;
    if (this._primarySelection.size !== 0) {
      this._primarySelection.clear();
      result = true;
    }
    if (this._secondarySelection.size !== 0) {
      this._secondarySelection.clear();
      result = true;
    }
    return result;
  }

  public getAttributeValuesForTable(tableId: string, attributeNames: string[]): [AttributeValues, string[]] {
    const existingElements: ElementObject[] = [];
    const missingAttributeNames: string[] = [];

    const elements = this._elementsByTableId.get(tableId);
    if (Array.isArray(elements) && elements.length > 0) {
      existingElements.push(...this._elementsByTableId.get(tableId));
      missingAttributeNames.push(...this.calculateMissingAttributeNames(this._elementsByTableId.get(tableId)[0], attributeNames));
    }

    return [elementObjects2AttributeValues(existingElements), missingAttributeNames];
  }

  private calculateMissingAttributeNames(element: ElementObject, attributeNames: string[]): string[] {
    const existingAttributeNames: string[] = Object.keys(element);
    const missingAttributeNames: string[] = [];

    attributeNames.forEach(attributeName => {
      if (!existingAttributeNames.includes(attributeName)) {
        missingAttributeNames.push(attributeName);
      }
    });

    return missingAttributeNames;
  }
}

// singleton
// export default new ModelStore();

export function attributeValues2ElementObjects(attributeValues: AttributeValues): ElementObject[] {
  const retVal: ElementObject[] = [];

  attributeValues.generatedId.forEach(id => {
    retVal.push({id});
  });

  Object.keys(attributeValues).forEach((attributeName) => {
    if ("generatedId" !== attributeName) {
      attributeValues.generatedId.forEach((id, index) => {
        const value = attributeValues[attributeName][index];
        if (value) {
          retVal[index][attributeName] = value;
        } else {
          // no value available eg when power viewer and new element was created --> use null
          retVal[index][attributeName] = null;
        }
      });
    }
  });

  return retVal;
}

export function elementObjects2AttributeValues(elementObjects: ElementObject[]): AttributeValues {
  const retVal: AttributeValues = {generatedId: []};

  elementObjects.forEach((element: ElementObject, index: number) => {
    Object.keys(element).forEach(attributeName => {
      if ("id" === attributeName) {
        retVal.generatedId[index] = element.id;
      } else {
        if (retVal[attributeName] === undefined) {
          retVal[attributeName] = [];
        }
        retVal[attributeName][index] = element[attributeName];
      }
    });
  });

  return retVal;
}

const modelStore = new ModelStore();
export {modelStore};
