/* KpiIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, März 2019
 */
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";
import {withStyles} from "@material-ui/core";
import * as React from "react";

class KpiIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <style type="text/css">{`
      .st0kpi{display:none;fill:#F39200;}
      .st1kpi{fill:#FFFFFF;}
      .st2kpi{fill:#FFFFFF;fill-opacity:0.5;}
     `}</style>
      <rect id="Hilfe_für_Abmessung" x="-85.75" y="26.84"
            transform="matrix(-0.633 -0.7741 0.7741 -0.633 -160.0581 -20.786)" className="st0kpi" width="1.58"
            height="1.4"/>
      <rect id="Hilfe_für_Abmessung_1_" x="5.49" y="26.29"
            transform="matrix(-0.633 -0.7741 0.7741 -0.633 -10.6402 48.9449)" className="st0kpi" width="1.58"
            height="1.4"/>
      <g>
        <path className="st1kpi"
              d="M16.42,52.89H15.4c0-11.33,9.48-20.55,21.12-20.55v1.01C25.44,33.34,16.42,42.11,16.42,52.89z"/>
      </g>
      <g>
        <g>
          <polygon className="st1kpi" points="36.54,48.9 52.73,30.85 39.95,51.61 39.26,52.99 		"/>
        </g>
        <g>
          <polygon className="st1kpi" points="36.54,48.9 52.73,30.85 32.47,46.27 33.27,46.22 		"/>
        </g>
      </g>
      <g>
        <g>
          <path className="st1kpi" d="M34.09,56.39c-0.24,0-0.49-0.02-0.74-0.06c-2.65-0.41-4.47-2.89-4.06-5.53c0.41-2.65,2.89-4.47,5.53-4.06
			c2.65,0.41,4.47,2.89,4.06,5.53l0,0C38.52,54.67,36.44,56.39,34.09,56.39z M34.08,47.44c-1.98,0-3.73,1.45-4.04,3.47
			c-0.34,2.23,1.19,4.32,3.42,4.66c2.23,0.34,4.32-1.19,4.66-3.42l0,0c0.34-2.23-1.19-4.32-3.42-4.66
			C34.5,47.46,34.29,47.44,34.08,47.44z"/>
        </g>
        <g>
          <path className="st1kpi" d="M34.09,57.1c-0.28,0-0.56-0.02-0.85-0.07c-1.47-0.22-2.76-1.01-3.64-2.21c-0.88-1.2-1.24-2.67-1.02-4.14
			c0.46-3.03,3.31-5.12,6.35-4.66c1.47,0.22,2.76,1.01,3.64,2.21c0.88,1.2,1.24,2.67,1.02,4.14C39.18,55.07,36.81,57.1,34.09,57.1z
			 M34.09,46.73c-2.33,0-4.38,1.7-4.75,4.08c-0.19,1.27,0.12,2.53,0.88,3.57s1.88,1.71,3.14,1.91c2.64,0.4,5.08-1.46,5.47-4.02
			c0.19-1.27-0.12-2.53-0.88-3.57c-0.76-1.04-1.88-1.71-3.14-1.91C34.57,46.75,34.33,46.73,34.09,46.73z M34.09,55.67
			c-0.21,0-0.42-0.02-0.63-0.05c-2.26-0.35-3.81-2.46-3.47-4.72c0.34-2.21,2.44-3.81,4.72-3.47c2.26,0.35,3.81,2.46,3.47,4.72
			C37.87,54.21,36.1,55.67,34.09,55.67z M34.09,48.16c-1.65,0-3.09,1.23-3.34,2.86c-0.28,1.84,0.99,3.57,2.83,3.85
			c1.84,0.28,3.57-0.99,3.85-2.83c0.28-1.84-0.99-3.57-2.83-3.85C34.43,48.17,34.25,48.16,34.09,48.16z"/>
        </g>
      </g>
      <path className="st2kpi" d="M40.4,23.52c-0.31,0-0.62,0.01-0.93,0.02l0.1,8.26c1.69,0.22,3.31,0.63,4.85,1.2l5.39-7.96
	C46.84,24.07,43.68,23.52,40.4,23.52z"/>
      <path className="st2kpi"
            d="M16.95,43.18c1.32-2.52,3.11-4.73,5.28-6.53l-2.65-4.66c-2.25,2.18-4.15,4.71-5.63,7.5L16.95,43.18z"/>
      <path className="st2kpi"
            d="M14.5,52.86c0.05-3.07,0.72-5.96,1.89-8.56l-3.02-3.66c-1.77,3.72-2.8,7.86-2.89,12.24L14.5,52.86z"/>
      <path className="st2kpi"
            d="M30.43,32.38l-2.53-6.1c-2.74,1.27-5.26,2.93-7.48,4.92l2.73,4.71C25.3,34.32,27.76,33.11,30.43,32.38z"/>
      <path className="st2kpi" d="M36.57,31.45c0.6,0,1.19,0.03,1.77,0.07l-0.08-7.92c-3.28,0.23-6.42,0.99-9.33,2.2l2.49,6.23
	C33.07,31.65,34.79,31.45,36.57,31.45z"/>
      <path className="st2kpi"
            d="M52.59,38.42c1.7,1.82,3.09,3.93,4.08,6.27l10.69-4.2c-1.67-3.46-3.99-6.56-6.8-9.13l-8.21,6.68L52.59,38.42z"
      />
      <path className="st2kpi"
            d="M58.42,52.89h11.89c-0.07-3.96-0.92-7.73-2.4-11.17L57.11,45.7C57.93,47.94,58.37,50.35,58.42,52.89z"/>
      <path className="st2kpi" d="M50.94,25.45l-5.33,7.97c0.66,0.29,1.29,0.63,1.91,0.98l6.18-4.63L50,36.04c0.5,0.39,0.99,0.79,1.46,1.22
	l8.22-6.67C57.1,28.41,54.15,26.66,50.94,25.45z"/>
      <path className="st1kpi"
            d="M49.57,36.73l-0.52,0.88c4.62,3.58,7.58,9.1,7.58,15.27h1.02C57.65,46.34,54.48,40.5,49.57,36.73z"/>
      <path className="st1kpi"
            d="M46.79,34.94c-3.04-1.65-6.54-2.61-10.26-2.61v1.01c3.38,0,6.56,0.83,9.36,2.27L46.79,34.94z"/>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(KpiIcon);
