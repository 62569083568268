/* FilterPropertiesComponent.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Juli 2018
 */
import * as React from "react";
import {ChangeEvent} from "react";
import {createStyles, FormControlLabel, Theme, withStyles, WithStyles} from "@material-ui/core";
import {BaseSectionProperties} from "../../../properties/model/PropertiesModel";
import {ShowConnectedToSelectionAction} from "../../actions/SharedViewActions";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import Log from "../../../common/utils/Logger";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {StyleRules} from "@material-ui/core/styles";
import PropertiesSectionLayout from "../../../properties/components/PropertiesSectionLayout";
import {MetusCheckbox} from "../../../common/components/MetusCheckboxComponent";
import {observer} from "mobx-react";

const renderLog = Log.logger("properties", Classifier.render);

const styles = (theme: Theme): StyleRules => createStyles({});

export interface FilterProperties extends BaseSectionProperties {
  showConnectedOnly: boolean;
}

type StyledLocalProps = FilterProperties & WithStyles<typeof styles>;

@observer
class FilterPropertiesComponent extends React.Component<StyledLocalProps> {
  constructor(props: StyledLocalProps) {
    super(props);
  }

  private filterConnectedElements = (event: ChangeEvent<any>, checked: boolean): void => {
    Dispatcher.dispatch(new ShowConnectedToSelectionAction(this.props.activeViewId, checked));
  }

  render(): JSX.Element {
    renderLog.debug("Rendering FilterPropertiesComponent");
    return <PropertiesSectionLayout>
      <FormControlLabel label="Show only elements connected to selection"
                        checked={this.props.showConnectedOnly}
                        onChange={this.filterConnectedElements}
                        control={<MetusCheckbox/>}/>
    </PropertiesSectionLayout>;
  }

}

export default withStyles(styles)(FilterPropertiesComponent);
