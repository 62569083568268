/* NewElementDialog.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2018
 */
import * as React from "react";
import {FormEvent} from "react";
import Log from "../../common/utils/Logger";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import MetusDialog from "../../common/components/MetusDialog";
import autobind from "autobind-decorator";
import {Classifier} from "../../common/utils/ClassifierLogger";
import MetusTextField from "../../common/components/MetusTextField";
import ChartIcon from "../../common/icons/navigation/ChartIcon";
import NewFolderIcon from "../../common/icons/navigation/NewFolderIcon";
import StructuredTableIcon from "../../common/icons/navigation/StructuredTableIcon";
import MatrixIcon from "../../common/icons/navigation/MatrixIcon";
import ChainMatrixIcon from "../../common/icons/navigation/ChainMatrixIcon";
import ValueChartIcon from "../../common/icons/navigation/ValueChartIcon";
import {createNewFolder, createNewView} from "../actions/ViewManagerAsyncActionCreators";
import {ViewType} from "../../common/constants/Enums";
import TableIcon from "../../common/icons/navigation/TableIcon";
import {metusStore} from "../stores/MetusStore";
import {modelStore} from "../../core/stores/ModelStore";
import classnames from "classnames";
import {normalizeName} from "../../core/utils/NameNormalizer";
import {hideDialog, showDialog} from "../../common/utils/CommonDialogUtil";
import {createNewTable} from "../../core/actions/CoreAsyncActionCreators";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

type ElementType =
    "None"
    | "ViewsFolder"
    | "TablesFolder"
    | "StructuredTable"
    | "ValueChart"
    | "Chart"
    | "Table"
    | "ChainMatrix"
    | "Matrix";


interface LocalProps {
  open: boolean;
  title: string;
  elementTypes: ElementType[];
  parentId?: string;
}


interface LocalState {
  elementType: ElementType;
  elementName: string;
  isError: boolean;
}

const styles = (theme: Theme): StyleRules => createStyles({
  blankLine: {
    height: "56px"
  },
  elements: {
    display: "flex",
    color: "rgba(0,0,0,0.8)",
    width: "400px",
    flexWrap: "wrap",
  },
  element: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    height: "120px",
    borderRadius: "4px",
  },
  selectedElement: {
    backgroundColor: theme.metus.selection.fill,
  },
  nameForm: {
    marginTop: "20px",
    width: "100%",
  },
  nameField: {
    width: "100%",
  },
  icon: {
    width: "48px",
    height: "48px",
  },
  text: {
    textAlign: "center",
    marginTop: "5px",
  },
  distance: {
    extend: "icon",
    marginRight: "32px",
  }
});


type StyledLocalProps = LocalProps & WithStyles<typeof styles>;


class NewElementDialog extends React.Component<StyledLocalProps, LocalState> {


  constructor(props: StyledLocalProps) {
    super(props);
    this.state = this.createInitialState();
  }

  createInitialState(): LocalState {
    return {
      elementName: "",
      isError: false,
      elementType: this.props.elementTypes.length === 1 ? this.props.elementTypes[0] : "None",
    };
  }

  createElement(elementType: ElementType): JSX.Element {
    const {name, iconType} = this.getElementTypeNameAndIcon(elementType);
    const callback = this.chooseElementType.bind(this, elementType);
    const classes = {root: this.props.classes.icon};
    return <div
        className={
          classnames(
              this.props.classes.element,
              {
                [this.props.classes.selectedElement]:
                this.state.elementType === elementType,
              },
          )
        }
        onClick={() => {
          if (this.state.elementType !== elementType) {
            callback();
          }
        }}
        data-testselector={"newelementtype-" + elementType}
    >
      {React.createElement(iconType, {classes})}
      <p className={this.props.classes.text}>{name}</p>
    </div>;
  }

  chooseElementType(elementType: ElementType): void {
    this.setState(prev => ({elementType}));
  }

  getElementTypeSelectionPageContent(): JSX.Element {
    return <div className={this.props.classes.elements} data-testselector="elementTypeSelection">
      {this.props.elementTypes.map(elementType => this.createElement(elementType))}
    </div>;
  }

  getElementTypePageContent(formId: any, name: string, iconType: any): JSX.Element {
    const classes = {root: this.props.classes.distance};
    return <div className={this.props.classes.elements}>
      <form
          id={formId}
          className={this.props.classes.nameForm}
          onSubmit={this.handleSubmit}
          data-testselector="elementTypeForm"
      >
        <MetusTextField
            id="name"
            className={this.props.classes.nameField}
            autoFocus={true}
            value={this.state.elementName}
            placeholder={"Name"}
            onChange={this.handleOnChange}
            error={this.state.isError}
        />
      </form>
    </div>;
  }


  renderDialogWithElementTypePage(): JSX.Element {
    const formId = "newElementDialog";
    const {name, iconType} = this.getElementTypeNameAndIcon(this.state.elementType);
    return <MetusDialog
        data-testselector={"NewElementDialog"}
        title={this.props.title}
        open={this.props.open}
        onClose={this.handleClose}
        onPrimaryButtonPressed={this.handleSubmit}
        primaryButtonName={"Submit"}
        errorMessage={this.state.isError ? "Please enter a name" : null}>
      {this.getElementTypeSelectionPageContent()}
      {this.getElementTypePageContent(formId, name, iconType)}
    </MetusDialog>;
  }

  renderDialogWithElementTypeSelectionPage(): JSX.Element {
    const formId = "newElementDialog";
    return <MetusDialog
        data-testselector="NewViewElementDialog"
        title={this.props.title}
        open={this.props.open}
        onClose={this.handleClose}>
      {this.getElementTypeSelectionPageContent()}
    </MetusDialog>;
  }

  render(): JSX.Element {
    return this.state.elementType === "None" ? this.renderDialogWithElementTypeSelectionPage() : this.renderDialogWithElementTypePage();
  }

  private getElementTypeNameAndIcon(elementType: ElementType): { name: string, iconType: any } {
    switch (elementType) {
      case "ViewsFolder":
      case "TablesFolder":
        return {name: "Folder", iconType: NewFolderIcon};
      case "ValueChart":
        return {name: "Value Chart", iconType: ValueChartIcon};
      case "StructuredTable":
        return {name: "Structured Table", iconType: StructuredTableIcon};
      case "Table":
        return {name: "Table", iconType: TableIcon};
      case "Chart":
        return {name: "Chart", iconType: ChartIcon};
      case "ChainMatrix":
        return {name: "ChainMatrix", iconType: ChainMatrixIcon};
      case "Matrix":
        return {name: "Matrix", iconType: MatrixIcon};
      default:
        throw new Error("Unknown element type: " + elementType);
    }
  }


  @autobind
  private handleOnChange(event: any): void {
    const elementName = event.target.value;
    this.setState(prevState => ({elementName, isError: false}));
    event.preventDefault();
  }

  @autobind
  private handleSubmit(evt?: FormEvent): void {
    // vM MO-1728 prevent reload of broser window
    if (evt) {
      evt.preventDefault();
    }
    log.debug("New Element Finished");
    if (this.state.elementName && this.state.elementName.length > 0) {
      const name = this.state.elementName;
      if (this.state.elementType === "TablesFolder") {
        createNewFolder(name, this.props.parentId ? this.props.parentId : modelStore.tableHierarchy.id);
      } else if (this.state.elementType === "ViewsFolder") {
        createNewFolder(name, this.props.parentId ? this.props.parentId : metusStore.cockpitAndViewHierarchy.id);
      } else if (this.state.elementType === "Table") {
        // normalize to be compatible with Metus Classic
        const normalizedName = normalizeName(name);
        createNewTable(name, this.props.parentId);
      } else {
        let viewType;
        switch (this.state.elementType) {
          case "StructuredTable":
            viewType = ViewType.StructuredTable;
            break;
          case "ValueChart":
            viewType = ViewType.ValueChart;
            break;
          case "Chart":
            viewType = ViewType.Chart;
            break;
          case "ChainMatrix":
            viewType = ViewType.ChainMatrix;
            break;
          case "Matrix":
            viewType = ViewType.Matrix;
            break;
          default:
            viewType = null;
        }
        if (viewType !== null) {
          createNewView(name, viewType, this.props.parentId);
        }
      }
      this.handleClose();
    } else {
      this.setState(prevState => {
        return {isError: true};
      });
    }
  }

  @autobind
  private handleClose(): void {
    this.resetState();
    hideDialog();
  }

  @autobind
  private resetState(): void {
    this.setState(prevState => this.createInitialState());
  }

}

const StyledNewElementDialog = withStyles(styles)(NewElementDialog);
export default StyledNewElementDialog;

/**
 * helpers to create dialogs using the unified dialog api, avoiding action creators to be dependent on React Components
 */

export function showNewTableDialog(display: boolean, parentId?: string): void {
  showDialog(display, <StyledNewElementDialog title="New Table" open={display} elementTypes={["TablesFolder", "Table"]}
                                              parentId={parentId}/>);
}

export function showNewElementDialog(display: boolean, parentId?: string): void {
  showDialog(display, <StyledNewElementDialog title="New View" open={display}
                                              elementTypes={["ViewsFolder", "Chart", "StructuredTable", "ValueChart", "Matrix", "ChainMatrix"]}
                                              parentId={parentId}/>);
}
