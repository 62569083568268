/* Direction.<extension>
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by MeegenM, Juni 2017
 */

import {Validate} from "./Validate";

export enum Direction {
  NORTH = 0,
  EAST = 1,
  SOUTH = 2,
  WEST = 3,
  NORTHEAST = 4,
  SOUTHEAST = 5,
  NORTHWEST = 6,
  SOUTHWEST = 7
}

class Delta {
  /* x factor for resize delta */
  xf: number;
  /* width factor for resize delta */
  wf: number;
  /* y factor for resize delta */
  yf: number;
  /* height factor for resize delta */
  hf: number;
  /** width factor for handle position x */
  hpx: number;
  /** height factor for handle position y */
  hpy: number;
}

export class DirectionHelper {
  private static DELTAS: Map<Direction, Delta> = new Map([
    [Direction.NORTH, {xf: 0, wf: 0, yf: 1, hf: -1, hpx: 0.5, hpy: 0, dx: 0, dy: 1}],
    [Direction.SOUTH, {xf: 0, wf: 0, yf: 0, hf: 1, hpx: 0.5, hpy: 1, dx: 0, dy: -1}],
    [Direction.EAST, {xf: 0, wf: 1, yf: 0, hf: 0, hpx: 1, hpy: 0.5, dx: -1, dy: 0}],
    [Direction.WEST, {xf: 1, wf: -1, yf: 0, hf: 0, hpx: 0, hpy: 0.5, dx: 1, dy: 0}],
    [Direction.NORTHEAST, {xf: 0, wf: 1, yf: 1, hf: -1, hpx: 1, hpy: 0, dx: -1, dy: 1}],
    [Direction.NORTHWEST, {xf: 1, wf: -1, yf: 1, hf: -1, hpx: 0, hpy: 0, dx: 1, dy: 1}],
    [Direction.SOUTHEAST, {xf: 0, wf: 1, yf: 0, hf: 1, hpx: 1, hpy: 1, dx: -1, dy: -1}],
    [Direction.SOUTHWEST, {xf: 1, wf: -1, yf: 0, hf: 1, hpx: 0, hpy: 1, dx: 1, dy: -1}]
  ]);

  static resize(d: Direction, dx: number, dy: number, x: number, y: number, width: number, height: number): { x: number, y: number, width: number, height: number } {
    const delta = DirectionHelper.DELTAS.get(d);
    Validate.isDefined(delta);
    return {x: x + delta.xf * dx, y: y + delta.yf * dy, width: width + delta.wf * dx, height: height + delta.hf * dy};
  }

  /**
   * calculate the center point for the handle in the given direction for the box defined by x,y,width,height
   */
  static getHandleCenterPosition(d: Direction, x: number, y: number, width: number, height: number): { x: number, y: number } {
    const delta = DirectionHelper.DELTAS.get(d);
    Validate.isDefined(delta);
    return {x: x + width * delta.hpx, y: y + height * delta.hpy};
  }

  /**
   * get the handle offset from handle center position if handle is not drawn centered on the side, but ensures that full handle is inside the box
   * @param d direction
   * @param handleRadius half the handle size, this is the amount the center point must be inset
   * @return delta in x and y direction to add to handle center point getting upperleft corner of handle
   */
  static getHandleOffsetInner(d: Direction, handleRadius: number): { x: number, y: number } {
    const delta = DirectionHelper.DELTAS.get(d);
    Validate.isDefined(delta);
    return {x: -handleRadius - (handleRadius * delta.wf), y: -handleRadius - handleRadius * delta.hf};
  }
}

// EAST  handles.push(<DndHandle key={dir} dir={dir} x={vm.x + vm.width} y={vm.y + vm.height / 2} hs={hs} callback={this.props.east}/>);
// WEST  handles.push(<DndHandle key={dir} dir={dir} x={vm.x} y={vm.y + vm.height / 2 } hs={hs} callback={this.props.west}/>);
// NORTH handles.push(<DndHandle key={dir} dir={dir} x={vm.x + vm.width / 2 } y={vm.y} hs={hs} callback={this.props.north}/>);
// SOUTH handles.push(<DndHandle key={dir} dir={dir} x={vm.x + vm.width / 2 } y={vm.y + vm.height } hs={hs} callback={this.props.south}/>);
