import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class LinkAttributeIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <path d="M11.811,16.6427 C12.04,16.6747 12.269,16.7087 12.5,16.7087 C12.744,16.7087 12.987,16.6747 13.229,16.6387 L10.829,19.0387 C10.049,19.8187 9.024,20.2087 8,20.2087 C6.976,20.2087 5.952,19.8187 5.172,19.0387 C4.416,18.2837 4,17.2787 4,16.2107 C4,15.1427 4.416,14.1377 5.172,13.3827 L8.884,9.6697 C10.444,8.1107 12.981,8.1107 14.541,9.6697 C15.059,10.1877 15.388,10.8167 15.562,11.4777 L14.696,12.3437 C14.66,11.6277 14.379,10.9227 13.834,10.3767 C12.664,9.2077 10.761,9.2077 9.591,10.3767 L5.879,14.0897 C5.312,14.6567 5,15.4097 5,16.2107 C5,17.0117 5.312,17.7657 5.879,18.3317 C7.049,19.5017 8.952,19.5017 10.122,18.3317 L11.811,16.6427 Z M19.041,5.17 C20.601,6.73 20.601,9.267 19.041,10.827 L15.329,14.539 C14.549,15.319 13.524,15.709 12.5,15.709 C11.476,15.709 10.452,15.319 9.672,14.539 C9.165,14.032 8.827,13.408 8.648,12.734 L9.515,11.867 C9.554,12.608 9.85,13.303 10.379,13.832 C11.549,15.002 13.452,15.002 14.622,13.832 L18.334,10.12 C19.503,8.95 19.503,7.047 18.334,5.877 C17.164,4.708 15.261,4.708 14.091,5.877 L12.401,7.567 C11.93,7.502 11.454,7.502 10.983,7.571 L13.384,5.17 C14.944,3.61 17.481,3.61 19.041,5.17 Z"/> ;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(LinkAttributeIcon);