import {serializable} from "serializr";
import {observable} from "mobx";

// should better be an enum?
export type FilterRuleType = "contains" | "greaterThan";


export interface IFilterRule {
  type: FilterRuleType;
  expression: string;
}

export class FilterRule implements IFilterRule {

  @serializable @observable private _type: FilterRuleType;
  @serializable @observable private _expression: string;

  constructor(type:FilterRuleType, expression:string="") {
    this._type = type;
    this._expression = expression;
  }

  get expression():string {
    return this._expression;
  }

  set expression(expression:string) {
    this._expression = expression;
  }

  get type(): FilterRuleType {
    return this._type;
  }

  set type(value: FilterRuleType) {
    this._type = value;
  }


}