import {ActionBase, LoadAction, NotUndoableAction} from "../../common/actions/BaseAction";
import {Connection, ExtTableId, UUID} from "../../api/api";
import {ElementId, ViewId, VisualAttributeId, VisualTableId} from "../utils/Core";
import {
  AddAttributeToViewPayload,
  AddTableToViewPayload,
  Coords
} from "../../commonviews/actions/SharedViewAsyncActions";
import {
  CoreSyncActions,
  DuplicateElementsAction,
  NewElementAction,
  ServerWritingAction,
  UpdateAttributeValuesAction
} from "./CoreActions";
import {Target} from "../../common/constants/Enums";
import {ModelInfo} from "../models/ModelInfo";

export class CreateConnectionAction extends ActionBase<any> implements ServerWritingAction {
  type: "createConnection" = "createConnection";

  constructor(public commandId: string, payload: Connection, groupId?: UUID) {
    super(payload, null, groupId);
  }
}

export class UpdateConnectionAction extends ActionBase<any> implements ServerWritingAction {
  type: "updateConnection" = "updateConnection";

  constructor(public commandId: string, payload: Connection, groupId?: UUID) {
    super(payload, null, groupId);
  }
}

export class DeleteConnectionAction extends ActionBase<any> implements ServerWritingAction {
  type: "deleteConnection" = "deleteConnection";

  constructor(public commandId: string, payload: DeleteConnectionActionPayload, groupId?: UUID) {
    super(payload, null, groupId);
  }
}

export interface DeleteConnectionActionPayload {
  sourceElementId: ElementId;
  targetElementId: ElementId;
}

export interface ToggleConnectionActionPayload {
  sourceElementIds: ElementId[];
  targetElementId: ElementId;
}

/**
 * creates or deletes one or more new connections from the given source to the given target; if one already there it is removed, otherwise created
 */
export class ToggleConnectionAction extends ActionBase<any> implements ServerWritingAction {
  type: "toggleConnections" = "toggleConnections";

  constructor(public commandId: string, sourceElementIds: ElementId[], targetElementId: ElementId, groupId?: UUID) {
    super({sourceElementIds, targetElementId}, null, groupId);
  }
}

export class LoadConnectionsPayload {
  public connections: Connection[] = [];

  constructor(public newTableIds: ExtTableId[], connectionsEndpoints: [ElementId, ElementId][]) {
    connectionsEndpoints.forEach(connectionEndpoint => {
      this.connections.push({
        sourceElementId: connectionEndpoint[0],
        targetElementId: connectionEndpoint[1],
        strength: 1
      });
    });
  }
}

export class SelectModelAction extends NotUndoableAction<ModelInfo> {
  type: "selectmodel" = "selectmodel";

  constructor(modelInfo: ModelInfo) {
    super(modelInfo);
  }
}

export type CoreAsyncActions =
    NewElementAction
    | CreateConnectionAction
    | UpdateConnectionAction
    | DeleteConnectionAction
    | ToggleConnectionAction
    | AddAttributeToViewAction
    | AddTableToViewAction
    | UpdateAttributeValuesAction
    | DuplicateElementsAction;
export type CoreActions = CoreSyncActions | CoreAsyncActions;

export class LoadConnectionsAction extends LoadAction<LoadConnectionsPayload> {
  type: "loadConnections" = "loadConnections";
}

/**
 * adds an attribute definition to a view
 */
export class AddAttributeToViewAction extends ActionBase<AddAttributeToViewPayload> {
  type: "addAttributeToView" = "addAttributeToView";

  constructor(viewId: ViewId, visualAttributeId: VisualAttributeId, requestedCoords: Coords, initial: boolean, addToRight: boolean = false, isColumn: boolean = false, groupId: UUID = undefined) {
    super({visualAttributeId, requestedCoords, initial, addToRight, isColumn}, viewId, groupId);
  }
}

/**
 * adds a Table to a View
 */
export class AddTableToViewAction extends ActionBase<AddTableToViewPayload> {
  type: "addTableToView" = "addTableToView";

  constructor(viewId: ViewId, visualTableId: VisualTableId, insertPosition: Coords | number, groupId: UUID = undefined, target: Target = Target.ROW) {
    super({visualTableId, insertPosition, target}, viewId, groupId);
  }
}
