import {VisualAttributeId} from "../../../core/utils/Core";
import {VisualHeader} from "../../../commonviews/models/VisualHeader";
import Log from "../../../common/utils/Logger";
import {AttributeDefinition} from "../../../api/api";
import {ConditionalFormat} from "../../../commonviews/models/ConditionalFormat";
import {list, object, serializable} from "serializr";

const log = Log.logger("model");

export class VisualAttributeDefinition {
  @serializable(object(VisualAttributeId)) id: VisualAttributeId;
  @serializable(object(VisualHeader)) header: VisualHeader;
  /** conditional formats apply in the order they appear, the first matching one wins */
  @serializable(list(object(ConditionalFormat))) conditionalFormats: ConditionalFormat[];
  attributeDefinition: AttributeDefinition;

  constructor(id: VisualAttributeId, attributeDefinition: AttributeDefinition, name: string, displayName: string = "", x: number = 0, y: number = 0, width: number = 0, height: number = 0) {
    this.header = new VisualHeader(name, x, y, width, height, "attributeHeader");
    this.id = id;
    this.attributeDefinition = attributeDefinition;
    this.conditionalFormats = [];
  }
}
