import {IFilter} from "../../core/utils/filter/Filter";
import {ElementId} from "../../core/utils/Core";

export class CombinedFilter implements IFilter {
  constructor(private _filters: IFilter[]) {
  }

  matches(nodeIds: ElementId[], ignoreNoneMatchingFilters: boolean): ElementId[] {
    let result = nodeIds;
    this._filters.forEach(filter => result = filter.matches(result, ignoreNoneMatchingFilters));
    return result;
  }

}