/* ViewerContext.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by MeegenM, November 2017
 */
import {ElementId, TableId, ViewId, VisualElementId, VisualElementIdString, VisualTableId} from "../../core/utils/Core";
import identityConverter, {CoordinateConverter} from "../../common/utils/CoordinateUtil";
import {observable} from "mobx";
import {MosaicPath} from "react-mosaic-component";
import {Identifier} from "dnd-core";
import {ViewContext} from "../../commonviews/contexts/ViewContext";
import {ViewType} from "../../common/constants/Enums";
import {Validate} from "../../common/utils/Validate";

export type EditorDragHandlerResult = {
  viewId: ViewId,
  windowPath: MosaicPath,
  windowIndex: number,
  itemType: Identifier,
  selectedVisuals: Set<VisualElementIdString>
  selectedSourceTableIds?: Set<TableId>
};
export type EditorDragHandlerType = (itemType: Identifier, itemId: VisualElementId) => EditorDragHandlerResult;

export class ViewerContext {
  /** begindrag editor handler which provides currently selected elements */
  onBeginDrag: EditorDragHandlerType;

  /**
   *  called to reset direct edit mode
   */
  onDirectEditEnd: () => void;

  /** current view id */
  @observable viewId: ViewId;

  @observable viewType: ViewType;

  /** window index where current view is hosted */
  @observable windowIndex: number;
  /** window path where current view is hosted */
  @observable windowPath: MosaicPath;
  /** zoom scale */
  @observable scale: number;
  /** true if animation should be done on state change */
  @observable animate: boolean;
  /** element which was just created and should be in editable state */
  @observable directEditElementId: ElementId;
  /** visual table where element should be in edit state */
  @observable directEditVisualTableId: VisualTableId;
  /**
   * coordinate converter
   */
  converter: CoordinateConverter;

  constructor(beginDragHandler: EditorDragHandlerType,
              directEditEndHandler: () => void,
              viewId: ViewId,
              viewType: ViewType,
              scale: number,
              animate: boolean,
              directEditElementId: ElementId,
              directEditVisualTableId: VisualTableId,
              windowIndex: number,
              windowPath: MosaicPath,
              converter?: CoordinateConverter) {

    this.onBeginDrag = beginDragHandler;
    this.onDirectEditEnd = directEditEndHandler;
    this.viewId = viewId;
    this.scale = scale;
    this.animate = animate;
    this.directEditElementId = directEditElementId;
    this.directEditVisualTableId = directEditVisualTableId;
    this.windowIndex = windowIndex;
    this.windowPath = windowPath;
    this.converter = converter ? converter : identityConverter;
    Validate.isDefined(windowIndex);
    Validate.isDefined(windowPath);
  }

  getViewContext(): ViewContext {
    return new ViewContext(this.viewId, this.viewType, this.windowIndex);
  }

  copy(): ViewerContext {
    return new ViewerContext(this.onBeginDrag, this.onDirectEditEnd, this.viewId, this.viewType, this.scale, this.animate, this.directEditElementId, this.directEditVisualTableId, this.windowIndex, this.windowPath, this.converter);
  }
}