/* NewCockpitDialog.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2018
 */
import * as React from "react";
import Log from "../../common/utils/Logger";
import {Button, createStyles, Dialog, IconButton, Theme, Typography, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import {createNewCockpit} from "../actions/ViewManagerAsyncActionCreators";
import autobind from "autobind-decorator";
import {Classifier} from "../../common/utils/ClassifierLogger";
import MetusTextField from "../../common/components/MetusTextField";
import VariantsIcon from "../../common/icons/menu/cockpittypes/VariantsIcon";
import ChartIcon from "../../common/icons/menu/cockpittypes/ChartIcon";
import IdeationIcon from "../../common/icons/menu/cockpittypes/IdeationIcon";
import ModularityIcon from "../../common/icons/menu/cockpittypes/ModularityIcon";
import {CockpitColors, CockpitIconType} from "../../common/constants/Enums";
import {Cancel, Close} from "@material-ui/icons";
import RequirementsIcon from "../../common/icons/menu/cockpittypes/RequirementsIcon";
import ProductstructureIcon from "../../common/icons/menu/cockpittypes/ProductstructureIcon";
import MarketsIcon from "../../common/icons/menu/cockpittypes/MarketsIcon";
import InterfacesIcon from "../../common/icons/menu/cockpittypes/InterfacesIcon";
import CostIcon from "../../common/icons/menu/cockpittypes/CostIcon";
import ConceptsIcon from "../../common/icons/menu/cockpittypes/ConceptsIcon";
import ProductionIcon from "../../common/icons/menu/cockpittypes/ProductionIcon";
import ProductkitIcon from "../../common/icons/menu/cockpittypes/ProductkitIcon";
import ProcessesIcon from "../../common/icons/menu/cockpittypes/ProcessesIcon";
import DataIcon from "../../common/icons/menu/cockpittypes/DataIcon";
import PortfolioIcon from "../../common/icons/menu/cockpittypes/PortfolioIcon";
import KpiIcon from "../../common/icons/menu/cockpittypes/KpiIcon";
import {CirclePicker, ColorResult} from "react-color";
import {hideDialog, showDialog} from "../../common/utils/CommonDialogUtil";

const log = Log.logger("workbench");
const renderLog = Log.logger("workbench", Classifier.render);

const colors = Object.keys(CockpitColors).map(key => CockpitColors[key]);

const cockpitIconTypes: CockpitIconType[] = [
  CockpitIconType.Variants,
  CockpitIconType.Chart,
  CockpitIconType.Modularity,
  CockpitIconType.Ideation,
  CockpitIconType.Requirements,
  CockpitIconType.Productstructure,
  CockpitIconType.Markets,
  CockpitIconType.Concepts,
  CockpitIconType.Interfaces,
  CockpitIconType.Cost,
  CockpitIconType.Production,
  CockpitIconType.Productkit,
  CockpitIconType.Processes,
  CockpitIconType.Data,
  CockpitIconType.Portfolio,
  CockpitIconType.Kpi,

];

const cockpitIcons: JSX.Element[] = [
  <VariantsIcon/>,
  <ChartIcon/>,
  <ModularityIcon/>,
  <IdeationIcon/>,
  <RequirementsIcon/>,
  <ProductstructureIcon/>,
  <MarketsIcon/>,
  <ConceptsIcon/>,
  <InterfacesIcon/>,
  <CostIcon/>,
  <ProductionIcon/>,
  <ProductkitIcon/>,
  <ProcessesIcon/>,
  <DataIcon/>,
  <PortfolioIcon/>,
  <KpiIcon/>,
];

export function getCockpitIcon(iconType: CockpitIconType): JSX.Element {
  return cockpitIcons[iconType ? iconType : 0];
}

interface LocalProps {
  open: boolean;
}

interface LocalState {
  cockpitName: string;
  selectedColor: string;
  selectedIconType: CockpitIconType;
  isError: boolean;
}

const drawerWidth = 82;

const styles = (theme: Theme): StyleRules => createStyles({
  "@global ::-webkit-scrollbar": {
    width: "8px",
    height: "8px",
  },
  "@global ::-webkit-scrollbar-thumb": {
    background: CockpitColors.DarkGrayishBlue,
  },
  "@global ::-webkit-scrollbar-track": {
    background: theme.palette.secondary[600],
  },
  dialogPaper: {
    backgroundColor: theme.metus.dialog.fill,
    color: theme.metus.dialog.secondaryText,
  },
  dialogContent: {
    padding: "20px 35px",
  },
  typographyTitle: {
    fontSize: "20px",
    color: "#FFFFFF",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "20px 35px",
  },
  buttonContained: {
    width: "auto",
    minWidth: "140px",
    backgroundColor: theme.metus.dialog.buttonFill,
    height: "auto",
    marginLeft: "auto", // align to the right if there is only one button
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    color: theme.metus.dialog.primaryText,
    padding: "20px 35px",
  },
  closeIconButton: {
    padding: 0,
    color: "#FFFFFF",
  },
  closeIcon: {
    height: "24px",
    width: "24px",
  },
  blankLine: {
    height: "20px"
  },
  button: {
    margin: theme.spacing(1),
  },
  iconButton: {
    border: "1px solid rgba(0, 0, 0, .2)",
  },
  drawer: {
    width: drawerWidth,
    flex: "0 0 auto",
  },
  drawerPaper: {
    left: "calc(50vw + 300px)",
    bottom: "calc(50vh - 266px)",
    width: "172px",
    height: "532px",
    display: "flex",
    flex: "1 0 auto",
    flexDirection: "column",
    zIndex: 1200,
    position: "fixed",
    overflowY: "auto",
    borderRadius: "4px",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    borderRadius: "4px",
    marginTop: 32,
    paddingTop: 8,
    paddingBottom: 8,
  },
  cancelIconContainer: {
    alignSelf: "center",
    paddingLeft: "16px",
  },
  cancelIcon: {
    height: "40px",
    width: "40px",
  },
  errorTextContainer: {
    alignSelf: "center",
    paddingLeft: "20px",
  },
  typographyErrorText: {
    fontSize: "16px",
    fontWeight: 400,
    color: "inherit",
  },
});

type StyledLocalProps = LocalProps & WithStyles<typeof styles>;


class NewCockpitDialog extends React.Component<StyledLocalProps, LocalState> {
  private initialState: LocalState = {
    cockpitName: "",
    selectedColor: "#F38E40",
    selectedIconType: CockpitIconType.Modularity,
    isError: false
  };

  constructor(props: StyledLocalProps) {
    super(props);
    this.state = this.initialState;
  }

  render(): JSX.Element {
    const {classes} = this.props;
    const {selectedIconType, selectedColor} = this.state;
    const selectedIcon = getCockpitIcon(selectedIconType);
    const color = selectedColor ? selectedColor : "#FFFFFF";
    let iconButtons = cockpitIconTypes.map((iconType, index) => {
      return this.buildCockpitIconButton(iconType, index);
    });

    return <Dialog
        data-testselector={"NewCockpitDialog"}
        classes={{paper: classes.dialogPaper}}
        disableBackdropClick={true}
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby={"alert-dialog-title"}
        aria-describedby={"alert-dialog-description"}
    >

      <main>
        {/* Close button */}
        <div className={classes.header} style={{backgroundColor: `${color}`}}>
          <Typography className={classes.typographyTitle}>{"New Cockpit"}</Typography>
          <IconButton onClick={this.handleClose} data-testselector="closeDialogButton"
                      classes={{root: classes.closeIconButton}}>
            <Close classes={{root: classes.closeIcon}}/>
          </IconButton>
        </div>

        {/* Preview selected icon */}
        <div style={{backgroundColor: `${selectedColor}`, textAlign: "center"}}>
          <selectedIcon.type {...selectedIcon.props} style={{fontSize: 160}}/>
        </div>

        {/* Content */}
        <div className={classes.dialogContent}>
          {this.getDialogContent(selectedColor)}
        </div>

        {/* Actions */}
        <div className={classes.buttons}>
          <Button
              data-testselector={"SubmitButton"}
              classes={{contained: classes.buttonContained}}
              variant={"contained"}
              type="button"
              onClick={this.handleSubmit}>{"Submit"}
          </Button>
        </div>
      </main>

      {/* Icon selection drawer */}
      <div className={classes.drawer}>
        <div className={classes.drawerPaper}>
          <div style={{backgroundColor: `${this.state.selectedColor}`}}>
            {iconButtons}
          </div>
        </div>
      </div>

      {/* Error footer */}
      {this.state.isError &&
      <div
          className={this.props.classes.footer}>
        <div className={this.props.classes.cancelIconContainer}>
          <Cancel classes={{root: this.props.classes.cancelIcon}}/>
        </div>
        <div className={this.props.classes.errorTextContainer}>
          <Typography className={this.props.classes.typographyErrorText}>{"Please enter a name"}
          </Typography>
        </div>
      </div>}
    </Dialog>;
  }

  private getDialogContent(selectedColor: string): JSX.Element {
    return <React.Fragment>
      <MetusTextField id="name"
                      autoFocus={true}
                      value={this.state.cockpitName}
                      placeholder="Name"
                      onChange={this.handleOnChange}
                      error={this.state.isError}
                      fullWidth={true}
                      onKeyPress={evt => {
                        if (evt.key === 'Enter') {
                          this.handleSubmit()
                        }
                      }}
      />

      <div className={this.props.classes.blankLine}/>

      <div style={{textAlign: "center"}}>
        <CirclePicker color={selectedColor}
                      colors={colors}
                      onChange={this.handleOnColorClick} circleSize={56}
                      width={"504px"}/>
      </div>
    </React.Fragment>;
  }

  private buildCockpitIconButton(iconType: CockpitIconType, index: number): JSX.Element {
    const {classes} = this.props;
    const cockpitIcon = getCockpitIcon(iconType);

    return <IconButton className={classes.iconButton} onClick={this.handleOnIconClick.bind(this, iconType)} key={index}>
      <cockpitIcon.type style={{fontSize: 80}}/>
    </IconButton>;
  }

  private handleOnIconClick(iconType: CockpitIconType): void {
    this.setState(prevState => ({selectedIconType: iconType}));
  }

  @autobind
  private handleOnColorClick(colorResult: ColorResult): void {
    this.setState(prevState => ({selectedColor: colorResult.hex}));
  }

  @autobind
  private handleOnChange(event: any): void {
    const cockpitName = event.target.value;
    this.setState(prevState => ({cockpitName, isError: false}));
  }

  @autobind
  private handleSubmit(): void {
    if (this.state.cockpitName && this.state.cockpitName.length > 0) {
      this.resetState();
      createNewCockpit({
        name: this.state.cockpitName,
        iconColor: this.state.selectedColor,
        iconType: this.state.selectedIconType
      });
      hideDialog();
    } else {
      this.setState(prevState => {
        return {isError: true};
      });
    }
  }

  @autobind
  private handleClose(): void {
    this.resetState();
    hideDialog();
  }

  private resetState(): void {
    this.setState(prevState => this.initialState);
  }

}

const StyledNewCockpitDialog = withStyles(styles)(NewCockpitDialog);
export default StyledNewCockpitDialog;

export function showNewCockpitDialog(display: boolean): void {
  showDialog(display, <StyledNewCockpitDialog open={display}/>);
}