/* IdeationIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Dezember 2018
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";

class IdeationIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <g id="co-/-ideation" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M61,25.222 C61,21.362 57.859,18.222 54,18.222 C50.141,18.222 47,21.362 47,25.222 C47,27.092 47.715,28.836 49.014,30.135 C51.135,32.256 52,34.245 52,37 L56,37 C56,34.245 56.865,32.256 58.986,30.135 C60.285,28.836 61,27.092 61,25.222 Z M63,25.222 C63,27.626 62.077,29.873 60.4,31.549 C58.65,33.299 58,34.776 58,37 L58,42 L54,44 L50,42 L50,37 C50,34.776 49.35,33.299 47.6,31.549 C45.923,29.873 45,27.626 45,25.222 C45,20.26 49.037,16.222 54,16.222 C58.963,16.222 63,20.26 63,25.222 Z M42,25 C42,24.662 42.023,24.33 42.051,24 L37,24 L37,26 L42.051,26 C42.023,25.669 42,25.337 42,25 Z M63.852,31.842 L68.223,34.366 L69.223,32.634 L64.847,30.107 C64.561,30.714 64.231,31.296 63.852,31.842 Z M38.777,32.634 L39.777,34.366 L44.148,31.842 C43.769,31.296 43.439,30.714 43.153,30.107 L38.777,32.634 Z M65.949,24 C65.977,24.33 66,24.662 66,25 C66,25.337 65.977,25.669 65.949,26 L71,26 L71,24 L65.949,24 Z M38.777,17.366 L43.153,19.892 C43.439,19.285 43.769,18.703 44.148,18.158 L39.777,15.634 L38.777,17.366 Z M69.223,17.366 L68.223,15.634 L63.852,18.158 C64.231,18.703 64.561,19.285 64.847,19.892 L69.223,17.366 Z M55,13.05 L55,8 L53,8 L53,13.05 C53.33,13.023 53.662,13 54,13 C54.338,13 54.67,13.023 55,13.05 Z M59.107,14.153 C59.715,14.439 60.296,14.768 60.842,15.149 L63.366,10.777 L61.634,9.777 L59.107,14.153 Z M48.893,14.153 L46.366,9.777 L44.634,10.777 L47.157,15.149 C47.704,14.768 48.285,14.439 48.893,14.153 Z" id="Fill-1" fill="#FFFFFF"></path>
      <path
          d="M44,50.806 L44,68 L12,68 L12,50.806 C21.762,45.158 34.238,45.158 44,50.806 Z M35.936,33.935 C35.936,38.318 32.383,41.871 28,41.871 C23.617,41.871 20.064,38.318 20.064,33.935 C20.064,29.553 23.617,26 28,26 C32.383,26 35.936,29.553 35.936,33.935 Z"
          id="Combined-Shape" fillOpacity="0.5" fill="#FFFFFF"></path>
    </g>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(IdeationIcon);
