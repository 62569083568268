import * as React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {Dispatcher} from "../utils/Dispatcher";
import {ShowDialogAction} from "../actions/InteractionStateActions";

interface LocalProps {
  open: boolean;
  message: string;
  suppressOkButton?: boolean;
}

type StyledLocalProps = LocalProps;

export class MessageDialog extends React.Component<StyledLocalProps, any> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  handleClose = (): void => {
    Dispatcher.dispatch(new ShowDialogAction(false));
  }

  render(): JSX.Element {
    return (
        <Dialog
            open={this.props.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Information"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.message.split("\n").map(line => <p>{line}</p>)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {!this.props.suppressOkButton ? <Button onClick={this.handleClose} color="primary">Ok</Button>: null}
          </DialogActions>
        </Dialog>
    );
  }
}

