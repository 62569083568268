import {DropTargetMonitor, DropTargetSpec} from "react-dnd";
import DragTypes from "../../common/constants/DragTypes";
import DragType from "../../common/constants/DragTypes";
import {NodeProps} from "../../workbench/components/TreeListItemComponent";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {DndTargetFeedbackAction} from "../../common/actions/InteractionStateActions";
import {Cursor} from "../../common/constants/Enums";
import {JoinTableSectionHeaderComponent, JoinTableSectionHeaderComponentProps} from "./JoinTableSectionHeaderComponent";
import {AttributeId, TableId} from "../../core/utils/Core";
import {NAME_ATT_NAME} from "../../api/api";
import {dragTypeAdapterManager} from "../../commonviews/utils/DragTypeAdapterManager";
import {TableDragInfo} from "../../common/utils/CoreDropTypes";
import {changeCellConfiguration} from "../actions/MatrixAsyncActionCreators";

const dndLog = Log.logger("JoinTableSectionHeaderComponent", Classifier.dnd);
export const joinTableSectionHeaderComponentDropSpec: DropTargetSpec<JoinTableSectionHeaderComponentProps> = {
  hover(props: JoinTableSectionHeaderComponentProps, monitor: DropTargetMonitor, component: any): void {
    dndLog.debug("Hover", monitor.getItemType(), monitor.getItem());
    switch (monitor.getItemType()) {
      case DragTypes.CORE_TABLE:
      case DragTypes.VISUAL_TABLE_COLUMN_MOVE:
        const tableItem: NodeProps = monitor.getItem() as NodeProps;
        dndLog.debug("Table hovering, setting table target feedback");
        Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.COPY, "set Join Table"));
        break;
      case DragTypes.CORE_ATTRIBUTE_DEFINITION:
        dndLog.debug("Table hovering, setting table target feedback");
        Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.COPY, "set Join Table and Attribute"));
        break;
      default:
        dndLog.error("Hovering with unknown type, please handle type or remove it from DropTarget types. If type is adaptable, please adapt it");
        break;
    }
  },

  canDrop(props: JoinTableSectionHeaderComponentProps, monitor: DropTargetMonitor): boolean {
    return true;
  },

  drop(props: JoinTableSectionHeaderComponentProps, monitor: DropTargetMonitor, component: any): void {
    dndLog.debug("Dropped " + monitor.getItemType().toString());
    if (monitor.canDrop()) {
      let result = undefined;
      switch (monitor.getItemType()) {
        case DragTypes.VISUAL_TABLE_COLUMN_MOVE:
          // convert to CORE_TABLE
          const item: TableDragInfo = dragTypeAdapterManager.adapt(monitor.getItem(), DragType.CORE_TABLE) as TableDragInfo;
          if (item) {
            changeCellConfiguration(props.viewContext.viewId, item.tableId, NAME_ATT_NAME, false);
          }
          break;
        case DragTypes.CORE_TABLE:
          dndLog.debug(`Setting join table for matrix ${props.viewContext.viewId}`);
          const tableItem: NodeProps = monitor.getItem() as NodeProps;
          const id = tableItem.id as TableId;
          changeCellConfiguration(props.viewContext.viewId, id, NAME_ATT_NAME, false);
          break;
        case DragTypes.CORE_ATTRIBUTE_DEFINITION:
          dndLog.debug(`Setting join table attribute for matrix ${props.viewContext.viewId}`);
          const tableItem2: NodeProps = monitor.getItem() as NodeProps;
          const attributeId: AttributeId = AttributeId.fromKey(tableItem2.id);
          changeCellConfiguration(props.viewContext.viewId, attributeId.tableId, attributeId.attributeName, false);
          break;
        default:
          dndLog.error("Drop with unknown type, please handle type or remove it from DropTarget types. If type is adaptable, please adapt it");
          break;
      }
      dndLog.debug("JoinTableSectionHeaderComponent.drop returning " + result, result);
      return result;
    }
  }
};
