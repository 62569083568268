import "../../common/css/metusapp.css";
import * as React from "react";
import Log from "../../common/utils/Logger";
import MetusDragLayer from "./MetusDragLayer";
import {interactionStore} from "../../common/stores/InteractionStore";
import {Classifier} from "../../common/utils/ClassifierLogger";
import ReactTooltip from "react-tooltip";
import {observer} from "mobx-react";
import theme from "../../common/theme/Theme";

const log = Log.logger("common");
const renderLog = Log.logger("common", Classifier.render);
const ReactUntypedTooltip = ReactTooltip as any;

interface LocalProps {
}

@observer
export default class InteractionContainer extends React.Component<LocalProps> {

  constructor(props: LocalProps) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering InteractionContainer",);
    const dialogAndLoadingState = {
      isLoading: interactionStore.isLoading,
      dialogs: interactionStore.dialogs.map(dAndM => {
        return dAndM.dialog;
      }),
    };
    const dropState = {
      dropTargetFeedback: interactionStore.dropTargetFeedback,
      dragSourceFeedback: interactionStore.dragSourceFeedback,
      dropTargetCursor: interactionStore.dropTargetCursor,
      dropTargetFeedbackLocation: interactionStore.dropTargetFeedbackLocation
    };
    return <div data-testselector={"Loading" + dialogAndLoadingState.isLoading}>
      {dialogAndLoadingState.dialogs}
      <ReactUntypedTooltip delayShow={800} border={true} clickable={true} multiline={true} html={true} type="info" arrowColor={theme.metus.properties.active.highlight}
                           effect="solid" place="bottom" className="metusTooltip" bodyMode={true}
                           dataEvent
                           style={{zIndex: '1000'}}/>
      <MetusDragLayer dragSourceFeedback={dropState.dragSourceFeedback}
                      dropTargetFeedback={dropState.dropTargetFeedback}
                      dropTargetFeedbackLocation={dropState.dropTargetFeedbackLocation}
                      dropTargetCursor={dropState.dropTargetCursor}/>
    </div>;
  }
}

