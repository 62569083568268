/* CoreDataServices.ts.<extension>
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by lorenzo.auer, Februar 2020
 */
import {Attribute, AttributeType, ElementId, TableId, ViewId} from "../utils/Core";
import {Validate} from "../../common/utils/Validate";
import {isNormalized} from "../utils/NameNormalizer";
import {generateUUID} from "../../common/utils/IdGenerator";
import {baseurl, load, loadAndDispatchAction} from "../../commonviews/actions/RESTCallActionCreatorsBase";
import {modelStore} from "../stores/ModelStore";
import {deleteResource, ExplicitStatusCodes, fakeWriteResult, post, put} from "../utils/RESTCallUtil";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {
  DeleteAttributesAction,
  DeleteTablesAction,
  DuplicateElementsAction,
  MoveElementsToTableAction, MoveElementsToTablePayload,
  NewAttributeAction,
  NewElementAction,
  SelectElementsAction,
  SetWriteLockAction
} from "../actions/CoreActions";
import {
  AttributeFormatType,
  AttributeValues,
  CommitResult,
  CommitStrategy,
  Connection,
  CreateConnectionBody, ElementIdsMap,
  ExtTableId,
  FolderEntry,
  HierarchyEntry,
  TableAttributeDefinitions,
  TableEntry,
  ToggleConnectionsBody,
  UpdateConnectionBody,
  UUID,
  Workspace,
  WriteLock,
  WriteResult
} from "../../api/api";
import {stringify} from "query-string";
import {
  CreateConnectionAction,
  DeleteConnectionAction,
  ToggleConnectionAction,
  ToggleConnectionActionPayload,
  UpdateConnectionAction
} from "../actions/CoreAsyncActions";
import {getNameForListItemType, TreeItemType, ModelLocationType} from "../../common/constants/Enums";
import {configurationStore} from "../stores/ConfigurationStore";
import _ from "lodash";

export const UPPER_LIMIT_OF_QUERY_PARAMS = 100;

/**
 * create a new element, request originating from the given view in the given table
 * @param tableId table to create element in
 * @param x x coord in view coordinates, optional
 * @param y y-coord in view coordinates, optional
 * @param uuid optional id of element to create, if not specified a new one will be generated
 * @return uuid of the element generated, this is immediately returned, usually before server is contacted and model is changed
 */
export function newElement(tableId: TableId, x?: number, y?: number, uuid?: ElementId): Promise<ElementId> {
  const elementId: ElementId = uuid || generateUUID();
  // create element on server
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/elements`;
  // ATTENTION: MUST MATCH ElementCreatorData Rest API Java Class
  const elementCreatorData = {uuid: elementId, tableId, name: ""};
  return post(JSON.stringify(elementCreatorData), url, {"content-type": "application/json"})
      .then(writeResult => {
        if (writeResult) {
          Dispatcher.dispatch(new NewElementAction(writeResult.commandId, elementId, "", tableId, x, y));
          return elementId;
        }
      });
}

export function getLostAttributes(sourceTableId: TableId, targetTableId: TableId): Promise<void | string[]> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/lostAttributes/${sourceTableId}/${targetTableId}`;
  return load("loadLostAttributes", undefined, url, false, undefined, {"content-type": "application/json"})
      .then((response: Object) => {
        return response as string[] || [];
      });
}


export function duplicateElements(originalElementIds: Array<ElementId>, viewId: ViewId, y: number = null): Promise<WriteResult<null> | void> {
  const newElementIds: Array<ElementId> = [];

  for (let i = 0; i < originalElementIds.length; i++) {
    newElementIds.push(generateUUID());
  }

  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/elements/duplicate`;
  const elementsDuplicatorData = {originalElementIds: originalElementIds, newElementIds: newElementIds};

  return post(JSON.stringify(elementsDuplicatorData), url, {"content-type": "application/json"})
      .then((writeResult: WriteResult<null>) => {
        let commandId = null;
        if(writeResult !== undefined && writeResult !== null) {
          commandId = writeResult.commandId;
        }
        Dispatcher.dispatch(new DuplicateElementsAction(commandId, originalElementIds, newElementIds, viewId));
      });
}

export function moveElementsToTable(elementIdsToMove: ElementId[], sourceTableId: TableId, targetTableId: TableId, isCopy: boolean, lostAttributeNames: string[], y: number): Promise<void> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/elements/move`;
  const moveData = {elementIdsToMove, sourceTableId, targetTableId, isCopy};

  return put(JSON.stringify(moveData), url, {"content-type": "application/json"})
      .then((writeResult: WriteResult<ElementIdsMap>) => {
        if (writeResult) {
          const groupId = generateUUID();
          // update model store
          const fakeResponseMap: Map<ElementId, ElementId> = new Map();
          elementIdsToMove.forEach((elementId) => {
            fakeResponseMap.set(elementId, elementId)
          });
          const previousToNewElementIdsMap = writeResult.json ? (new Map(Object.entries(writeResult.json))) : fakeResponseMap;
          const moveElementsToTablePayload: MoveElementsToTablePayload = {
            previousToNewElementIdsMap,
            sourceTableId,
            targetTableId,
            isCopy,
            lostAttributeNames,
            y
          };
          Dispatcher.dispatch(new MoveElementsToTableAction(writeResult.commandId, moveElementsToTablePayload, groupId));
        }
      });
}

export function saveTable(name: string, uuid: string, isNew: boolean = false, parentId?: string): Promise<void | WriteResult<any>> {
  if (!configurationStore.canWriteToServer()) {
    return Promise.resolve(fakeWriteResult);
  }
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/tables`;
  const payload = {name, uuid, parentId};
  if (isNew) {
    return post(JSON.stringify(payload), url, {"content-type": "application/json"});
  } else {
    return put(JSON.stringify(payload), `${url}/${uuid}`, {"content-type": "application/json"});
  }
}

export interface AttributeData {
  tableId: string;
  attributeName: string;
  type: AttributeType;
  formatType: string;
  formatUsingGlobalSeparators: string;
  targetTableId: string;
  targetAttributeName: string;
  formula: string;
}

export async function loadTableFolderTree(groupId: UUID): Promise<any> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/table_folder_tree`;

  const json = await loadAndDispatchAction("loadFoldersAndTables", "", url, true, groupId);

  const tableIds = filterTableIdsFromTree(json);

  const chunksOfUuids: UUID[][] = _.chunk(tableIds, UPPER_LIMIT_OF_QUERY_PARAMS);
  return Promise.all(chunksOfUuids.map(uuids => loadAttributeDefinitionsForTables(uuids, groupId)));
}

export function loadAttributeDefinitionsForTables(tableIds: TableId[], groupId: UUID): Promise<TableAttributeDefinitions[]> {
  let result;
  if (tableIds.length === 0) {
    result = Promise.resolve();
  } else {
    /* filterDefinition on existing attributes not needed in this step, because we bulk load instead of lazy */
    const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/attributes?tables=${tableIds.join(",")}`;
    result = loadAndDispatchAction("attributeDefinitions", undefined, url, true, groupId);
  }
  return result;
}

function filterTableIdsFromTree(json: HierarchyEntry, currentList: string[] = []): UUID[] {
  if (json.hasOwnProperty("children")) {
    for (const childObject of (json as FolderEntry).children) {
      filterTableIdsFromTree(childObject, currentList);
    }
  } else if (json.type === getNameForListItemType(TreeItemType.Table)) {
    currentList.push((json as TableEntry).uuid);
  }
  return currentList;
}

function generateAttributeData(tableId: string, attributeName: string, type: AttributeType, formatType: AttributeFormatType,
                               formatUsingGlobalSeparators: string, targetTableId: string, targetAttributeName: string, formula: string): AttributeData {

  let attributeFormatType: string = formatType;
  if (attributeFormatType === "Double" && modelStore.modelInfo.location !== ModelLocationType.liveserver)
    attributeFormatType = "Number";
  else if (attributeFormatType === "String")
    attributeFormatType = "None";

  return {
    tableId: tableId,
    attributeName,
    type,
    formatType: attributeFormatType,
    formatUsingGlobalSeparators: formatUsingGlobalSeparators,
    targetAttributeName: targetAttributeName,
    targetTableId: targetTableId,
    formula: formula
  };

}

export function newAttribute(tableId: string, attributeName: string, type: AttributeType, formatType: AttributeFormatType,
                             formatUsingGlobalSeparators: string, targetTableId: string, targetAttributeName: string, formula: string): Promise<string> {

  Validate.isTrue(isNormalized(attributeName), "Attribute name isn't normalized: " + attributeName);
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/attributes`;

  const elementCreatorData: AttributeData = generateAttributeData(tableId, attributeName, type, formatType,
      formatUsingGlobalSeparators, targetTableId, targetAttributeName, formula);

  return post(JSON.stringify(elementCreatorData), url, {"content-type": "application/json"})
      .then((writeResult) => {
        if (writeResult) {
          const groupId = generateUUID();
          Dispatcher.dispatch(new NewAttributeAction(writeResult.commandId, attributeName, groupId));
          // do it the hard way by loading the table folder tree anew
          return loadTableFolderTree(groupId);
        }
      });
}

export function editAttribute(tableId: string, previousName: string, newName: string, type: AttributeType, formatType: AttributeFormatType,
                              formatUsingGlobalSeparators: string, targetTableId: string, targetAttributeName: string, formula: string) {
  Validate.isTrue(isNormalized(newName), "Attribute name isn't normalized: " + newName);
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/attributes/${previousName}`;

  const editAttributeData: AttributeData = generateAttributeData(tableId, newName, type, formatType,
      formatUsingGlobalSeparators, targetTableId, targetAttributeName, formula);

  return put(JSON.stringify(editAttributeData), url, {"content-type": "application/json"})
      .then((writeResult) => {
        if (writeResult) {
          const groupId = generateUUID();
          // do it the hard way by loading the table folder tree anew
          return loadTableFolderTree(groupId);
        }
      });
}

/**
 * create a new connection with given strength and optional comment, error if a connection already exists
 * @param sourceElementId from
 * @param targetElementId to
 * @param strength strength, default is 1.0
 * @param comment optional comment
 */
export function createConnection(sourceElementId: ElementId, targetElementId: ElementId, strength: number = 1.0, comment?: string): Promise<void> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/connections`;
  const requestBody: CreateConnectionBody = {
    uuid: generateUUID(),
    sourceElementId,
    targetElementId,
    strength,
    comment
  };

  return post(JSON.stringify(requestBody), url, {"content-type": "application/json"}, false /*forceWrite*/)
      .then(writeResult => {
        if (writeResult) {
          const groupId = generateUUID();
          // update model store
          const connection: Connection = {
            sourceElementId: requestBody.sourceElementId,
            targetElementId: requestBody.targetElementId,
            strength: requestBody.strength,
            comment: requestBody.comment
          };
          Dispatcher.dispatch(new CreateConnectionAction(writeResult.commandId, connection, groupId));
        }
      });
}

/**
 * changes the attributes strength, comment for the given connection; if it does not exist, throws an error
 * @param sourceElementId from
 * @param targetElementId to
 * @param strength strength
 * @param comment comment for this connection
 */
export function updateConnection(sourceElementId: ElementId, targetElementId: ElementId, strength: number, comment?: string): Promise<void> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/connections/${sourceElementId}/${targetElementId}`;
  const updateData: UpdateConnectionBody = {strength, comment};

  return put(JSON.stringify(updateData), url, {"content-type": "application/json"})
      .then(writeResult => {
        if (writeResult) {
          const groupId = generateUUID();
          // update model store
          const connection: Connection = {sourceElementId, targetElementId, strength, comment};
          Dispatcher.dispatch(new UpdateConnectionAction(writeResult.commandId, connection, groupId));
        }
      });
}

/**
 * deletes a connection, error if not existing
 * @param sourceElementId from
 * @param targetElementId to
 */
export function deleteConnection(sourceElementId: ElementId, targetElementId: ElementId): Promise<void> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/connections/${sourceElementId}/${targetElementId}`;

  return deleteResource(url)
      .then((writeResult: WriteResult<null>) => {
        const groupId = generateUUID();
        Dispatcher.dispatch(new DeleteConnectionAction(writeResult.commandId, {
          sourceElementId,
          targetElementId
        }, groupId));
      });
}

/**
 * toggle connection existence for drag/drop operations; existing connections will be deleted, non-existing created
 * @param sourceElementIds from
 * @param targetElementId to
 */
export function toggleConnections(sourceElementIds: ElementId[], targetElementId: ElementId): Promise<ToggleConnectionActionPayload> {
  /* NOTE: This URI is poor designed. It would be better to use createConnection or deleteConnections respectively. But the decision logic is currently implemented on server side. */
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/connections/toggle`;
  const toggleConnectionsData: ToggleConnectionsBody = {
    uuid: generateUUID(),
    sourceUuids: sourceElementIds,
    targetUuid: targetElementId,
  };
  const explicitStatusCodes: ExplicitStatusCodes = {allowedStatusCodes: [200, 201]};

  return post(JSON.stringify(toggleConnectionsData), url, {"content-type": "application/json"}, false /*forceWrite*/, explicitStatusCodes)
      .then(writeResult => {
        if (writeResult) {
          const groupId = generateUUID();
          // update model store
          Dispatcher.dispatch(new ToggleConnectionAction(writeResult.commandId, sourceElementIds, targetElementId, groupId));
          // update blue/grey elements
          Dispatcher.dispatch(new SelectElementsAction(Array.from(modelStore.primarySelection), false, false, groupId));
          return {sourceElementIds, targetElementId};
        }
      });
}

export function deleteElements(ids: ElementId[]): Promise<WriteResult<null> | void> {
  const queryString = stringify({ids});
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/elements?${queryString}`;
  return deleteResource(url);
}

export function deleteAttribute(tableId: TableId, attributeName: string): Promise<any> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/tables/${tableId}/attributes/${attributeName}`;
  return deleteResource(url)
      .then(writeResponse => {
        if (writeResponse) {
          const groupId = generateUUID();
          const attributeNames: string[][] = [[attributeName]];
          const tableIds = [tableId];
          // Delete attribute from ModelStore, other stores must sync their information
          Dispatcher.dispatch(new DeleteAttributesAction(writeResponse.commandId, {tableIds, attributeNames}, groupId));
        }
        return Promise.all([]);
      });
}

export function deleteTable(tableId: TableId): Promise<TableId> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/tables/${tableId}?${stringify([tableId])}`;
  return deleteResource(url)
      .then(writeResponse => {
        if (writeResponse) {
          const groupId = generateUUID();
          // Delete tables from ModelStore, other stores will sync their information
          Dispatcher.dispatch(new DeleteTablesAction(writeResponse.commandId, [tableId], groupId));
          return tableId;
        }
      });
}

export function updateAttributeValues(attributes: Attribute[], elementId: string, tableId: TableId): Promise<void | WriteResult<any>> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/elements/${elementId}`;
  return put(JSON.stringify(attributes), url, {"content-type": "application/json"});
}

export function loadAttributeValuesForTable(tableId: TableId, attributeNames: string[], groupId: UUID): Promise<AttributeValues> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/tables/${tableId}/attributes?select=${attributeNames.join(",")}`;
  return load("loadAttributeValues", tableId, url, true, groupId);
}

export function loadConnections(newTableIds: ExtTableId[], existingTableIds: ExtTableId[], groupId: UUID): Promise<Connection[]> {
  let url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/connections?new=${newTableIds.join(",")}`;
  if (existingTableIds.length > 0) {
    url += `&existing=${existingTableIds.join(",")}`;
  }
  return load("loadConnections", null, url, true, groupId);
}

export function createOrUpdateWriteLock(force: boolean, changeSetId:string): Promise<void | WriteResult<WriteLock>> {
  const changeSetParam = changeSetId ? `?changeSetId=${changeSetId}` : "";
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/writelock${changeSetParam}`;
  // force write since the write lock must be acquired while the client does not hold a write lock
  const method = force ? put : post;
  return method("", url, {}, true /*forcewrite*/);
}

export function deleteWriteLock(commitStrategy:CommitStrategy): Promise<void | WriteResult<CommitResult>> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/writelock?commitStrategy=${CommitStrategy[commitStrategy]}`;
  return deleteResource(url, true /*forcewrite*/);
}

export function loadWriteLock(groupId: string): Promise<WriteLock> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/writelock`;
  return load("writeLock", null, url, true /*undoable*/, groupId)
      .then((result: WriteLock) => {
        Dispatcher.dispatch(new SetWriteLockAction(result));
        return result;
      });
}

export function loadWorkspaces(): Promise<Workspace[]> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/workspaces`;
  return load("workspace", null, url, true /*undoable*/);
}

export function createWorkspace(name:string): Promise<WriteResult<string>> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/workspaces`;
  return post(JSON.stringify({name}), url, {"content-type": "application/json"}, true) as any;
}
