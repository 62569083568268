// lib imports
import * as React from "react";
import {CSSProperties, RefObject} from "react";
import {ConnectionComponent} from "./ConnectionComponent";
import Log from "../../../common/utils/Logger";
import {VisualConnection} from "../../models/chart/VisualConnection";
import {observer} from "mobx-react";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {VirtualVisibility} from "../../../common/utils/VirtualRenderUtil";

const log = Log.logger("diagram");
const renderLog = Log.logger("diagram", Classifier.render);

interface LocalProps {
  visualConnections: VisualConnection[];
  animationCount?: number;
}

interface LocalState {
  renderedAnimationCount: number;
  isAnimating: boolean;
}

@observer
export class ConnectionsComponent extends React.Component<LocalProps, any> {
  private containerRef: RefObject<SVGPathElement> = React.createRef();
  private timeoutHandle: any = undefined;

  constructor(props: LocalProps) {
    super(props);
    this.state = {
      renderedAnimationCount: 0,
      isAnimating: false
    };
  }

  /**
   * trigger animation if renderedAnimationCount increased
   * @param props props
   * @param state state
   */
  static getDerivedStateFromProps(props: LocalProps, state: LocalState): LocalState {
    if (props.animationCount > state.renderedAnimationCount) {
      return {
        isAnimating: true,
        renderedAnimationCount: props.animationCount
      };
    }
    return null;
  }

  render(): JSX.Element {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
      this.timeoutHandle = undefined;
    }
    renderLog.debug(`Rendering ConnectionsComponent with ${this.props.visualConnections.length} edges`);
    // css transition for transform does not work since it is no move, transition for x1,y1,x2,y2 does not work
    // thus do only animate fade-in/fade-out
    const style: CSSProperties = {stroke: "black", "strokeWidth": 1, fill: "none"};
    if (this.state.isAnimating) {
      style.animation = "fade-out-in 4s linear";
    }

    // currently no virtualization done, everything is just a line
    const componentMapper = (visualConnection: VisualConnection, visibility: VirtualVisibility): JSX.Element =>
        <ConnectionComponent key={visualConnection.id}
                             visualConnection={visualConnection}
                             animationCount={this.props.animationCount} hidden={false}/>;

    const unFilteredConnections = this.props.visualConnections.filter(value => value.visible);
    const mappedConnections = unFilteredConnections.map(c => componentMapper(c,VirtualVisibility.FULL));
    // connections are not virtualized to get a better fast preview
    // const mappedConnections = mapVirtualized(this.props.visibleSVGRect, unFilteredConnections, componentMapper, undefined,"ConnectionsComponent");
    return <g data-testselector="connections" style={style} ref={this.containerRef}>
      {mappedConnections}
    </g>;
  }

  componentDidUpdate(prevProps: Readonly<LocalProps>, prevState: Readonly<any>, snapshot?: any): void {
    if (this.state.isAnimating) {
      this.timeoutHandle = setTimeout(() => {
        this.setState({isAnimating: false});
      }, 4000);
    }
  }
}

