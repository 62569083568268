// lib imports
import * as React from "react";
import {CSSProperties} from "react";
import Log from "../../../common/utils/Logger";
import {ElementId, VisualTableId} from "../../../core/utils/Core";
import {ViewerContext} from "../../utils/ViewerContext";
import DragTypes, {default as DragType} from "../../../common/constants/DragTypes";
import {
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
  DragSourceSpec,
  DropTarget,
  DropTargetConnector,
  DropTargetMonitor,
  DropTargetSpec
} from "react-dnd";
import * as _ from "lodash";
import {updateAttributeValuesForElementAndTable} from "../../../core/actions/CoreAsyncActionCreators";
import {FeedbackHelper} from "../../../common/utils/DragDropHelper";
import {DndTargetFeedbackAction} from "../../../common/actions/InteractionStateActions";
import {Cursor} from "../../../common/constants/Enums";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {VisualElementTargetDropInfo} from "../../../commonviews/constants/DiagramDragDropTypes";
import {dragTypeAdapterManager} from "../../../commonviews/utils/DragTypeAdapterManager";
import {ElementSourceDragInfo} from "../../../common/utils/CoreDropTypes";
import {SourceDragInfo} from "../../../common/utils/DragTypeAdapter";
import {ContextMenuTrigger} from "react-contextmenu";
import {getLevelDefaultTextColor, highlight} from "../../../core/utils/LevelColorUtil";
import {AttributeValueComponent} from "./AttributeValueComponent";
import {NAME_ATT_NAME} from "../../../api/api";
import {VisualValueChartElement} from "../../models/valuechart/VisualValueChartElement";
import {DiagramVisualConstants} from "../../../commonviews/constants/DiagramVisualConstants";
import {observer} from "mobx-react";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {VisualAttributeDefinition} from "../../models/common/VisualAttributeDefinition";
import {VisualBaseAttributeValue} from "../../models/common/VisualBaseAttributeValue";
import {VisualBaseElement} from "../../models/common/CommonDiagramTypes";
import {Color} from "csstype";
import autobind from "autobind-decorator";
import {Rect} from "../../../common/utils/Geometry";
import {ComponentMapper, mapVirtualized, VirtualVisibility} from "../../../common/utils/VirtualRenderUtil";
import {LightweightNestedValueChartElement} from "../valuechart/LightweightNestedValueChartElement";
import shallowEqual from "shallowequal";
import identity from "lodash/fp/identity";
import {toggleConnections} from "../../../core/services/CoreDataServices";

// HACK TO avoid typescript error since typescript does not know about webpack 'resource module loader' for requiring images
declare function require(x: string): string;

const log = Log.logger("diagram");
const dndLog = Log.logger("NestedElementComponent", Classifier.dnd);
const renderLog = Log.logger("diagram", Classifier.render);

export interface NestedElementComponentProps {
  viewerContext: ViewerContext;
  viewModel: VisualBaseElement;
  visualAttributeDefinitions: Map<string, VisualAttributeDefinition>;
  visualTableId: VisualTableId;
  visibleSVGRect: Rect;
  /**
   * if true, no text is displayed; false if not specified
   */
  renderWithoutDetails?: boolean;
  /**
   * true if attribute values should be auto-wrapped according to size of box; if false, they will be cut off at the box width, maybe cutting off in the middle of a letter
   */
  wrapLinesInAttributes: boolean;
  hidden: boolean;

  /** number of levels in this value chart, needed to determine colors */
  levelCount: number;
  connectDropTarget?: ConnectDropTarget;
  connectDragSource?: ConnectDragSource;
  animationCount?: number;
}

interface LocalState {
  isMouseHovering: boolean;
}

// ContextMenuTrigger props are passed to collect function
/*
function collect(props: any): { id: VisualNodeIdString } {
  return {id: props["data-id"]};
}
*/
function collect(props: any): { id: ElementId } {
  return {id: props["data-id"]};
}

@observer
export class NestedElementComponentNoDnd extends React.Component<NestedElementComponentProps, LocalState> {
  private renderedAnimationCount: number = 0;

  constructor(props: NestedElementComponentProps) {
    super(props);
    this.state = {isMouseHovering: false};
  }

  @autobind onMouseOver(): void {
    this.setState({isMouseHovering: true});
  }

  @autobind onMouseOut(): void {
    this.setState({isMouseHovering: false});
  }

  render(): JSX.Element {
    const viewModel = this.props.viewModel;
    renderLog.debug("Rendering NestedElementComponent " + viewModel.title, viewModel);
    const {width, height} = viewModel;
    const gstyle: CSSProperties = {};
    if (this.props.animationCount && this.props.animationCount > this.renderedAnimationCount) {
      this.renderedAnimationCount = this.props.animationCount;
      gstyle.transition = "transform 2s";
    }

    let rectStyles: CSSProperties = viewModel.rectStyles;
    if (this.state.isMouseHovering) {
      rectStyles = highlight(rectStyles);
    }

    let contentBox = undefined;
    let x, y, xOffset;
    let contextMenuId;
    let textColor: Color = undefined;
    if (viewModel instanceof VisualValueChartElement) {
      textColor = getLevelDefaultTextColor(viewModel.level.index, viewModel.level.levelCount);
      x = viewModel.relativeX;
      y = viewModel.relativeY;
      contextMenuId = "cm_dg_NestedElementComponentValueChart";
      xOffset = -(DiagramVisualConstants.VC_LEVEL_INSET + DiagramVisualConstants.VC_GAP) * viewModel.level.index;

      const visibleValueChartElements = viewModel.children.filter(c => c.visible);
      const componentMapper: ComponentMapper<VisualValueChartElement> = (vcElement: VisualValueChartElement, visibility: VirtualVisibility, levelCount: number, isThumbNail: boolean): JSX.Element =>
          visibility === VirtualVisibility.INVISIBLE ?
              <LightweightNestedValueChartElement key={vcElement.id.toKey()} viewModel={vcElement}/> :
              <NestedElementComponent
              key={vcElement.id.toKey()} viewModel={vcElement} levelCount={this.props.levelCount}
              viewerContext={this.props.viewerContext} visualTableId={vcElement.level.id}
              visualAttributeDefinitions={vcElement.level.visualAttributeDefinitions} hidden={false}
              visibleSVGRect={this.props.visibleSVGRect} renderWithoutDetails={isThumbNail} wrapLinesInAttributes={this.props.wrapLinesInAttributes}/>;
      const virtualMappedElements = mapVirtualized(this.props.visibleSVGRect, this.props.levelCount, visibleValueChartElements, componentMapper, this.props.viewerContext.scale, "Nested Element " + this.props.viewModel.title);

      const contentBoxTransform = `translate(${viewModel.level.contentDx},${DiagramVisualConstants.VC_GAP + DiagramVisualConstants.DEFAULT_ELEMENT_HEIGHT + viewModel.level.contentDy})`;
      contentBox = <g transform={contentBoxTransform}>
        {virtualMappedElements}
      </g>;
    } else {
      contextMenuId = "cm_dg_NestedElementComponent";
      x = viewModel.x;
      y = viewModel.y;
      xOffset = -x;
    }
    const elementTransform = `translate(${x},${y})`;
    const edit: boolean = viewModel.id.elementId === this.props.viewerContext.directEditElementId && shallowEqual(this.props.visualTableId, this.props.viewerContext.directEditVisualTableId);
    if (this.props.viewerContext.directEditElementId) {
      log.debug("edit mode " + edit + " for " + viewModel.title + ", " + viewModel.id.toKey());
    }
    let attributeValues = undefined;
    if (!this.props.renderWithoutDetails) {
      attributeValues = Array.from(viewModel.attributeValues.values()).map(
          (viewAttributeValue: VisualBaseAttributeValue) => {
            const attName: string = viewAttributeValue.attributeDefinition.id.attributeName;
            const visualAttributeDefinition: VisualAttributeDefinition = this.props.visualAttributeDefinitions.get(attName);
            const attributes = Array.from(this.props.visualAttributeDefinitions.values()).sort((a, b) => a.header.x - b.header.x);
            // last attribute has no separator
            const drawSeparator = attributes.length > 0 && visualAttributeDefinition !== attributes[attributes.length - 1];
            // vM for performance reasons no line breaks in virtualized diagrams, thus wrapLines = false
            return <AttributeValueComponent key={attName}
                                            drawSeparator={drawSeparator}
                                            xOffset={xOffset}
                                            attributeName={attName}
                                            windowIndex={this.props.viewerContext.windowIndex}
                                            viewId={this.props.viewerContext.viewId}
                                            tableId={this.props.visualTableId.tableId}
                                            attributeValue={viewAttributeValue}
                                            attributeDefinition={visualAttributeDefinition.attributeDefinition}
                                            updateValue={this.updateAttributeValue}
                                            cancelEditing={this.cancelEditing}
                                            animate={this.props.viewerContext.animate}
                                            edit={edit && attName === NAME_ATT_NAME}
                                            textColor={textColor}
                                            wrapLines={this.props.wrapLinesInAttributes}
                                            scale={this.props.viewerContext.scale}
            />;
          });
    }
    const connectDragSource = this.props.connectDragSource || identity;
    const connectDropTarget = this.props.connectDropTarget || identity;

    return connectDragSource(connectDropTarget(
        <g transform={elementTransform}
           data-testselector={"box-" + viewModel.title + "-" + viewModel.id.elementId}
           data-nodeid={viewModel.id.toKey()}
           onMouseOver={this.onMouseOver}
           onMouseOut={this.onMouseOut} style={gstyle} visibility={this.props.hidden ? "hidden" : "visible"}>
          <ContextMenuTrigger id={contextMenuId + this.props.viewerContext.windowIndex}
                              data-id={this.props.viewModel.id.toKey()}
                              renderTag="g"
                              collect={collect}
                              holdToDisplay={-1}
                              attributes={{"data-testselector": "contextmenutrigger"} as any}>
            <rect width={width} height={height} style={rectStyles} rx={DiagramVisualConstants.TABLE_HEADER_ROUNDED_ARC}
                  ry={DiagramVisualConstants.TABLE_HEADER_ROUNDED_ARC}/>
            {attributeValues}
          </ContextMenuTrigger>
          {contentBox}
        </g>
    ));
  }

  @autobind
  private updateAttributeValue(name: string, value: string): void {
    this.props.viewerContext.onDirectEditEnd();
    const attribute = this.props.visualAttributeDefinitions.get(name);
    if (attribute && attribute.attributeDefinition.editable) {
      updateAttributeValuesForElementAndTable([{
        name,
        value
      }], this.props.viewModel.id.elementId, this.props.visualTableId.tableId);
    } else {
      alert("Attribute editing not allowed");
    }
  }

  @autobind
  private cancelEditing(name: string): void {
    const attribute = this.props.visualAttributeDefinitions.get(name);
    if (attribute && attribute.attributeDefinition.editable) {
      this.props.viewerContext.onDirectEditEnd();
    }
  }

}

function targetCollect(connect: DropTargetConnector, monitor: DropTargetMonitor): Object {
  return {
    connectDropTarget: connect.dropTarget()
  };
}

//noinspection JSUnusedLocalSymbols,JSUnusedLocalSymbols,JSUnusedLocalSymbols
const dropTargetSpec: DropTargetSpec<NestedElementComponentProps> = {
  hover(props: NestedElementComponentProps, monitor: DropTargetMonitor, component: React.Component<NestedElementComponentProps, LocalState>): void {
    const shallow = monitor.isOver({shallow: true});
    dndLog.debug("Hover over id,name, shallow, isOverCurrent", props.viewModel.id, props.viewModel.title, shallow);
    if (shallow) {
      const box = FeedbackHelper.createTargetFeedbackBox(component, monitor);
      const image = <img src={require("../../../common/images/connectElements.svg")} width="200"
                         style={{transform: "translate(-50%,-50%)", position: "absolute"}} alt="connect"/>;
      dndLog.debug("Setting target feedback", props.viewModel.id);
      Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.ALIAS, <div
          style={{position: "absolute"}}>{[box, image]} </div>));
    }
  },

  drop(props: NestedElementComponentProps, monitor: DropTargetMonitor, component: NestedElementComponentNoDnd): VisualElementTargetDropInfo {
    let result = undefined;
    dndLog.debug("Drop occured");
    const item: ElementSourceDragInfo = dragTypeAdapterManager.adapt(monitor.getItem() as SourceDragInfo, DragType.CORE_ELEMENTS) as ElementSourceDragInfo;
    if (!monitor.didDrop()) {
      if (item) {
        dndLog.debug("Drop CORE_ELEMENTS, newOrDeleteConnections");
        const selectedElementIds = item.selectedElements.add(item.sourceElementId);
        const targetElementId = props.viewModel.id.elementId;
        toggleConnections(Array.from(selectedElementIds), targetElementId);
        result = {targetElementId};
      } else {
        dndLog.error("Drop with unknown type, please handle type or remove it from DropTarget types. If type is adaptable, please adapt it");
      }
    } else {
      dndLog.debug("ignoring drop, was handled by nested element before");
    }
    return result;
  }
};

function sourceCollect(connect: DragSourceConnector, monitor: DragSourceMonitor): Object {
  return {
    connectDragSource: connect.dragSource()
  };
}

//noinspection JSUnusedLocalSymbols,JSUnusedLocalSymbols,JSUnusedLocalSymbols
const dragSourceSpec: DragSourceSpec<NestedElementComponentProps, any> = {
  beginDrag(props: NestedElementComponentProps, dragSourceMonitor: DragSourceMonitor, component: React.Component<NestedElementComponentProps>): any {
    dndLog.debug("beginDrag");
    return {
      ...props.viewerContext.onBeginDrag(DragTypes.VISUAL_ELEMENTS, props.viewModel.id),
      sourceElementId: props.viewModel.id.elementId,
      type: DragTypes.VISUAL_ELEMENTS
    };
  }
};

// HOCs must be composed using flow first
export const NestedElementComponent = _.flow(
    DragSource<NestedElementComponentProps>(DragTypes.VISUAL_ELEMENTS, dragSourceSpec, sourceCollect),
    DropTarget<NestedElementComponentProps>(dragTypeAdapterManager.getCompatibleTypes(DragTypes.CORE_ELEMENTS, DragTypes.VISUAL_ELEMENTS), dropTargetSpec, targetCollect)
)(NestedElementComponentNoDnd);
