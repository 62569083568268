import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../menu/MetusSvgIcon";


class StringAttributeIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <g>
      <path
          d="M14,6.0003 L14,7.0003 L10.339,7.0003 L8.134,18.0003 L7.134,18.0003 L9.339,7.0003 L5,7.0003 L5,6.0003 L14,6.0003 Z M12,16 C12.553,16 13,16.448 13,17 C13,17.552 12.553,18 12,18 C11.447,18 11,17.552 11,17 C11,16.448 11.447,16 12,16 Z M16,16 C16.553,16 17,16.448 17,17 C17,17.552 16.553,18 16,18 C15.447,18 15,17.552 15,17 C15,16.448 15.447,16 16,16 Z M20,16 C20.553,16 21,16.448 21,17 C21,17.552 20.553,18 20,18 C19.447,18 19,17.552 19,17 C19,16.448 19.447,16 20,16 Z"
          >
      </path>
    </g>
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(StringAttributeIcon);
