/**
 * Created by MeegenM on 09.03.2017.
 */
import * as React from "react";
import * as ReactDnD from "react-dnd";
import {DropTarget} from "react-dnd";
import MetusLoginContainer from "../../modelselection/components/MetusLoginContainer";
import DragTypes from "../../common/constants/DragTypes";
import {Cursor} from "../../common/constants/Enums";
import Log from "../../common/utils/Logger";
import HeaderBarContainer from "./HeaderBarContainer";
import {Classifier} from "../../common/utils/ClassifierLogger";
import InteractionContainer from "./InteractionContainer";
import {DndTargetFeedbackAction} from "../../common/actions/InteractionStateActions";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {observer} from "mobx-react";
import {createStyles, Theme, withStyles, WithStyles, WithTheme} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles/withStyles";
import autobind from "autobind-decorator";
import ResizerComponent from "../../common/components/ResizerComponent";
import {PropertiesModel} from "../../properties/model/PropertiesModel";
import {IMetusPropertiesProviderContext} from "../../commonviews/contexts/IMetusPropertiesProviderContext";
import ToolboxComponent from "../../properties/components/ToolboxComponent";

const renderLog = Log.logger("workbench", Classifier.render);
const dndlog = Log.logger("App", Classifier.dnd);
const log = Log.logger("workbench");
const resizeDelayMS = 100;


const styles = (theme: Theme): StyleRules => {
  return createStyles({
    root: { // contains AppBar and Main
      display: "flex",
      flexDirection: "column",
      height: "100%",
      "@global ::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
      },
      "@global ::-webkit-scrollbar-thumb": {
        background: theme.metus.navigation.editorScrollBar,
        borderRadius: "10px",
      },
      "@global ::-webkit-scrollbar-track": {
        background: theme.metus.navigation.editorScrollBarBackground,
        borderRadius: "10px",
      },
    },
    main: { // all the stuff below the header
      display: "flex",
      minWidth: "720px",
      /** vM: strangely this is needed to make flexbox layout shrink if content grows larger than window, see https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/*/
      minHeight: "0px",
      flex: "1 1 auto",
    },
    sidebar: {
      flexGrow: 0,
      flexShrink: 0,
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden",
      overflowY: "hidden",
      transition: theme.transitions.create("flex-basis", {
        easing: theme.transitions.easing.sharp,
        duration: resizeDelayMS,
      }),
    },
    flexHeader: {
      flex: "0 0 64px"
    },
    flexMain: {
      flex: "1 1 auto"
    },
    rightResizer: {
      backgroundColor: theme.metus.properties.iconDivider,
    },
    leftResizer: {
      backgroundColor: theme.metus.navigation.iconDivider,
    }
  });
};

interface LocalProps {
  connectDropTarget?: ReactDnD.ConnectDropTarget;
  leftSidebar?: React.ReactElement<any>;
  showRightSidebar: boolean;
  showLeftSidebar: boolean;
  propertiesModel: PropertiesModel<IMetusPropertiesProviderContext>;
}

interface AppState {
  isLeftSidebarClosed?: boolean;
  leftSidebarOpenedWidth?: number;
  isRightSidebarClosed?: boolean;
  rightSidebarOpenedWidth?: number;
}

type StyledLocalProps = LocalProps & WithStyles<typeof styles> & WithTheme;

const NAV_BAR_MIN_WIDTH = 306;
const NAV_BAR_MAX_WIDTH = 600;
const TOOL_BOX_MIN_WIDTH = 300;
const TOOL_BOX_MAX_WIDTH = 600;

@observer
export class MainLayoutComponentNoDnd extends React.Component<StyledLocalProps, AppState> {
  constructor(props: StyledLocalProps) {
    super(props);
    this.state = {
      isLeftSidebarClosed: false,
      isRightSidebarClosed: true,
      leftSidebarOpenedWidth: NAV_BAR_MIN_WIDTH,
      rightSidebarOpenedWidth: TOOL_BOX_MIN_WIDTH,
    };
  }

  @autobind
  resizeLeftSidebar(delta: number): void {
    log.debug("Resizing left sidebar", delta);
    this.setState((prev: AppState): AppState => {
      return {
        leftSidebarOpenedWidth: Math.min(NAV_BAR_MAX_WIDTH, Math.max(NAV_BAR_MIN_WIDTH, prev.leftSidebarOpenedWidth + delta))
      };
    });
  }

  @autobind
  resizeRightSidebar(delta: number): void {
    log.debug("Resizing right sidebar", delta);
    this.setState((prev: AppState): AppState => {
      return {
        rightSidebarOpenedWidth: Math.min(TOOL_BOX_MAX_WIDTH, Math.max(TOOL_BOX_MIN_WIDTH, prev.rightSidebarOpenedWidth - delta))
      };
    });
  }


  render(): JSX.Element {
    renderLog.debug("Rendering MainLayoutComponent", this.state);
    const navigationBarWidth = this.state.isLeftSidebarClosed ? this.props.theme.metus.navigation.width : this.state.leftSidebarOpenedWidth;
    const tabBarWidth = this.state.isRightSidebarClosed ? this.props.theme.metus.properties.width : this.state.rightSidebarOpenedWidth;
    const contentArea: any = this.props.children;

    return this.props.connectDropTarget(
        <div className={this.props.classes.root}>
          <MetusLoginContainer/>
          <InteractionContainer/>
          <HeaderBarContainer classes={{root: this.props.classes.flexHeader}}/>
          <div className={this.props.classes.main}>
            {!this.props.showLeftSidebar ? null : [<div key="navigationSidebar" style={{flexBasis: navigationBarWidth}}
                                                        className={this.props.classes.sidebar}>
              {React.cloneElement(this.props.leftSidebar, {
                onClosedChanged: this.onLeftSidebarClosedChanged,
                closed: this.state.isLeftSidebarClosed
              })}
            </div>, this.state.isLeftSidebarClosed ? null : <ResizerComponent
                key="leftBarResizer"
                onResize={this.resizeLeftSidebar}
                throttleMS={resizeDelayMS}
                xPosition={navigationBarWidth}
                positionAnchor={"left"}/>]
            }
            <div key="workbench"
                 style={{flex: "1 1 auto", overflow: "hidden", display: "flex", flexDirection: "column"}}>
              {
                this.props.children
              }
            </div>
            {
              !this.props.showRightSidebar ? null :
                  [this.state.isRightSidebarClosed ? null :
                      <ResizerComponent xPosition={tabBarWidth} positionAnchor={"right"} key="rightBarResizer"
                                        onResize={this.resizeRightSidebar} throttleMS={resizeDelayMS}
                                        classes={{root: this.props.classes.rightResizer}}/>,
                    <div key="rightBar" style={{flexBasis: tabBarWidth}} className={this.props.classes.sidebar}>
                      <ToolboxComponent closed={this.state.isRightSidebarClosed}
                                        onClosedChanged={this.onRightSidebarClosedChanged}
                                        propertiesModel={this.props.propertiesModel}/>
                    </div>]
            }
          </div>
        </div>
    );

  }

  @autobind
  private onLeftSidebarClosedChanged(isSidebarClosed: boolean): void {
    log.debug("Navigation bar is closed", isSidebarClosed);
    this.setState((state) => ({isLeftSidebarClosed: isSidebarClosed}));
  }

  @autobind
  private onRightSidebarClosedChanged(isRightSidebarClosed: boolean): void {
    log.debug("Properties bar is closed", isRightSidebarClosed);
    this.setState((state) => ({isRightSidebarClosed}));
  }

}

const mainTarget: ReactDnD.DropTargetSpec<StyledLocalProps> = {
  canDrop(props: StyledLocalProps, monitor: ReactDnD.DropTargetMonitor): boolean {
    dndlog.debug("App: canDrop returns false");
    return false;
  },

  hover(props: StyledLocalProps, monitor: ReactDnD.DropTargetMonitor, component: React.Component<StyledLocalProps, any>): void {
    if (monitor.isOver({shallow: false})) {
      dndlog.debug("App: hovering, resetting target feedback, cursor to no-drop");
      // reset target feedback
      // Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.NO_DROP);
      Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.NO_DROP, "Item can not be dropped here."));
    }
  }
};

function collect(connect: ReactDnD.DropTargetConnector, monitor: ReactDnD.DropTargetMonitor): Object {
  return {
    connectDropTarget: connect.dropTarget()
  };
}

const StyledApp: any = withStyles(styles)(MainLayoutComponentNoDnd);

// App is drop target for all possible types to reset drop target feedback when outside any component interested in it
export default withStyles(styles, {withTheme: true})(DropTarget(Object.keys(DragTypes).map(k => DragTypes[k]), mainTarget, collect)(MainLayoutComponentNoDnd));
