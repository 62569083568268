import {INoRowsOverlayParams} from "ag-grid-community/dist/lib/rendering/overlays/noRowsOverlayComponent";
import React from "react";
import {matrixStore} from "../stores/MatrixStore";
import {newElement} from "../../core/services/CoreDataServices";
import {ViewId} from "../../core/utils/Core";
import {GridApi} from "ag-grid-community";
import autobind from "autobind-decorator";
import {MatrixModel} from "../models/MatrixModel";
import {observer} from "mobx-react";

interface LocalProps extends INoRowsOverlayParams {
  api: GridApi;
  viewId: ViewId;
  viewModel: MatrixModel
}

@observer
export class CustomNoRowsOverlayComponent extends React.Component<LocalProps> {
  public render(): JSX.Element {
    const api = this.props.api;
    const y = this.props.viewModel.fullHeaderHeightIfEmpty + 5;

    // use filler to enable click on whole area
    return <svg style={{position: "relative", top: y || 0}}>
      <g style={{fillOpacity: 0.5, stroke: "none", strokeWidth: 1, fillRule: "evenodd"}}>
        <rect x="0" y="0" width="24" height="24" className="clickableAreaFiller"
              style={{pointerEvents: "all", cursor: "pointer"}} onClick={this.addFirstElementToMatrix}/>
        <polygon points="11 4 11 11 4 11 4 13 11 13 11 20 13 20 13 13 20 13 20 11 13 11 13 4" fill="#000"/>
      </g>
    </svg>;
  }

  /*
  <svg><g xmlns="http://www.w3.org/2000/svg" style="pointer-events: none;" fill-opacity="0.5" stroke="none" strokeWidth="1" fillRule="evenodd">\n' +
  '      <polygon style="pointer-events: visibleFill;" onClick="window.addFirstElementToMatrix(\''+this.props.viewId+'\')" points="11 4 11 11 4 11 4 13 11 13 11 20 13 20 13 13 20 13 20 11 13 11 13 4" fill="#000"/>\n' +
  '    </g></svg>
   */
  @autobind
  private addFirstElementToMatrix(): void {
    const matrixById = matrixStore.getMatrixById(this.props.viewId);
    if (matrixById) {
      if (matrixById.tables.length > 0) {
        const visualTableId = matrixById.tables[0];
        newElement(visualTableId.tableId);
      }
    }
  }


}

