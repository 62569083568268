import {observer} from "mobx-react";
import * as React from "react";
import {ICellRendererParams} from "ag-grid-community";
import Log from "../../../common/utils/Logger";
import {convertToOpacity, extractCellValue} from "../../models/common/MatrixCellSharedFunctions";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import {calcFontSizeFromScale, cellContainer} from "./common";

const log = Log.logger("MatrixComponent");

type StyledLocalProps = ICellRendererParams & WithStyles<typeof styles>;

const styles = (theme:Theme):StyleRules => createStyles({
  circle: {
    borderRadius: "50%",
    backgroundColor: theme.palette.primary["A100"],
    display: "block",
  },
  cellContainer
})

/**
 * matrix cell renderer for matrix columns.
 * renders image attributes and normal attributes; the value is passed as raw internal value,
 * formatting is done using ag-grid cell formatter; this enables ag-grid to calculate with numbers.
 */
@observer
class MatrixCellRendererComponent extends React.Component<StyledLocalProps> {

  MAX_BUBBLE_DIAMETER = 16;

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    const cellValue = this.props.getValue();
    let result = null;
    if (typeof(cellValue) === "number") {
      const diameter = Math.max(1, convertToOpacity(cellValue) * this.MAX_BUBBLE_DIAMETER);
      result = <span className={this.props.classes.circle} style={{width:diameter, height: diameter, fontSize: calcFontSizeFromScale(this.props.context)}}></span>;
    }
    return result;
  }

}

export default withStyles(styles)(MatrixCellRendererComponent);
