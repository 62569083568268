import {CSSProperties} from "@material-ui/styles";
import {ICellRendererParams} from "ag-grid-community";
import {matrixStore} from "../../stores/MatrixStore";
import {MatrixContext} from "../MatrixContext";
import {MatrixVisualConstants} from "../../models/common/MatrixVisualConstants";
import {createStyles, Theme, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";

export const wordWrapCSS: CSSProperties = {
  whiteSpace: "normal",
  wordBreak: "break-word",
}

export const cellContainer: CSSProperties = {
  display: "flex",
  alignItems: "center",
}

export const isWordWrap = (props:ICellRendererParams):boolean => {
  const viewId = props.context.viewId;
  const matrix = matrixStore.getMatrixById(viewId);
  const columnId = props.column.getColId();
  return matrix.getWordWrapOnColumn(columnId);
}

// font size must be set on cell content for word wrapping
// otherwise the row would be a better place to set the font size in order to be more DRYish
export const calcFontSizeFromScale = (context: MatrixContext): string => {
  return (MatrixVisualConstants.BASE_ROW_FONT_SIZE * context.getScale()) + "px";
}

export const styles = (theme: Theme): StyleRules => createStyles({
  cellContainer
});

export type StyledMatrixCellRendererProps = ICellRendererParams & WithStyles<typeof styles>;


