/* RightSubtreeIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Januar 2019
 */
import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "../../common/icons/menu/MetusSvgIcon";

class RightSubtreeIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <g id="order_R" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.5">
      <path
          d="M10.4229,10.585 C10.2369,10.303 10.0049,10.059 9.7479,9.841 L18.1639,3.6 L18.7589,4.402 L10.4229,10.585 Z M20.0002,12.001 L20.0002,13.001 L10.9492,13.001 L10.9492,12.001 L20,12.001 L20.0002,12.001 Z M9.748,15.1612 C10.005,14.9432 10.237,14.6992 10.423,14.4172 L18.759,20.5992 L18.164,21.4022 L9.748,15.1612 Z M7.5,14.001 C8.329,14.001 9,13.33 9,12.501 C9,11.672 8.329,11.001 7.5,11.001 C6.671,11.001 6,11.672 6,12.501 C6,13.33 6.671,14.001 7.5,14.001 Z M7.5,10.001 C8.878,10.001 10,11.123 10,12.501 C10,13.879 8.878,15.001 7.5,15.001 C6.122,15.001 5,13.879 5,12.501 C5,11.123 6.122,10.001 7.5,10.001 Z"
          id="Combined-Shape-Copy" fill="#000000"
          transform="translate(12.500100, 12.501100) scale(-1, 1) translate(-12.500100, -12.501100) "></path>
    </g>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(RightSubtreeIcon);
