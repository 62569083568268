/* CostIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, März 2019
 */
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";
import {withStyles} from "@material-ui/core";
import * as React from "react";

class CostIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <style type="text/css">{`
      .st0cost{fill:#FFFFFF;}
      .st1cost{fill:#FFFFFF;fill-opacity:0.5;}
      .st2cost{display:none;fill:none;stroke:#E62D3A;stroke-width:2;stroke-miterlimit:10;}
     `}</style>
      <g>
        <g>
          <rect x="6.2" y="64.61" className="st0cost" width="67.6" height="1.23"/>
        </g>
        <path className="st1cost" d="M24.51,63.37H8.46c-1.25,0-2.27-1.02-2.27-2.27V16.42c0-1.25,1.02-2.27,2.27-2.27h16.04
		c1.25,0,2.27,1.02,2.27,2.27V61.1C26.77,62.35,25.76,63.37,24.51,63.37z"/>
        <path className="st1cost" d="M51.94,29.74h-13.1c-1.25,0-2.27-1.02-2.27-2.27v-8.58c0-1.25,1.02-2.27,2.27-2.27h13.1
		c1.25,0,2.27,1.02,2.27,2.27v8.58C54.21,28.72,53.19,29.74,51.94,29.74z"/>
        <g>
          <rect x="28.51" y="14.15" className="st0cost" width="25.7" height="1.23"/>
        </g>
        <path className="st1cost" d="M61.74,46.56h-13.1c-1.25,0-2.27-1.02-2.27-2.27v-8.58c0-1.25,1.02-2.27,2.27-2.27h13.1
		c1.25,0,2.27,1.02,2.27,2.27v8.58C64,45.54,62.99,46.56,61.74,46.56z"/>
        <g>
          <rect x="36.57" y="30.97" className="st0cost" width="27.43" height="1.23"/>
        </g>
        <path className="st1cost" d="M71.53,63.37h-13.1c-1.25,0-2.27-1.02-2.27-2.27v-8.58c0-1.25,1.02-2.27,2.27-2.27h13.1
		c1.25,0,2.27,1.02,2.27,2.27v8.58C73.8,62.36,72.78,63.37,71.53,63.37z"/>
        <g>
          <rect x="46.36" y="47.79" className="st0cost" width="27.43" height="1.23"/>
        </g>
      </g>
      <path className="st2cost" d="M43.86,26.13h-9.03c-0.67,0-1.21-0.54-1.21-1.21v-5.53c0-0.67,0.54-1.21,1.21-1.21h9.03
	c0.67,0,1.21,0.54,1.21,1.21v5.53C45.07,25.59,44.53,26.13,43.86,26.13z"/>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(CostIcon);
