import {MosaicContext, MosaicPath} from "react-mosaic-component";
import {observer} from "mobx-react";
import * as React from "react";
import ZoomControl from "./ToolbarControls/ZoomControl";
import {ToggleFullscreenControl} from "./ToolbarControls/ToggleFullscreenControl";
import {CollapseHeaderControl} from "./ToolbarControls/CollapseHeaderControl";
import {CloseEditorControl} from "./ToolbarControls/CloseEditorControl";
import autobind from "autobind-decorator";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {CloseEditorAction, DeactivateViewAction, ToggleFullScreenAction} from "../actions/ViewManagerActions";
import {saveView} from "../actions/ViewManagerAsyncActionCreators";
import {ViewInfo} from "../../commonviews/models/ViewInfo";
import {ZoomViewAction} from "../../commonviews/actions/SharedViewActions";

interface ViewToolbarProps {
  viewInfo: ViewInfo;
  windowPath: MosaicPath;
  windowIndex: number;
  numberOfOpenWindows: number;
  isFullScreen: boolean;
}

@observer
export class ViewToolbar extends React.Component<ViewToolbarProps> {
  constructor(props: ViewToolbarProps) {
    super(props);
  }

  render(): React.ReactNode {
    let retVal: React.ReactNode;
    const {viewInfo, windowIndex, numberOfOpenWindows} = this.props;
    return <MosaicContext.Consumer>
      {
        context => {
          if (viewInfo) {
            return <>
              <ZoomControl onZoom={this.zoomView} windowIndex={windowIndex}/>
              <ToggleFullscreenControl onClick={this.toggleFullscreen} isFullScreen={this.props.isFullScreen}/>
              <CollapseHeaderControl viewInfo={viewInfo} windowIndex={windowIndex}/>
              <CloseEditorControl onClick={this.closeEditor}/>
            </>;
          } else if (numberOfOpenWindows > 1) {
            return <CloseEditorControl onClick={this.closeEditor}/>;
          } else {
            return null;
          }
        }
      }
    </MosaicContext.Consumer>;
  }

  @autobind
  private zoomView(scale: number): void {
    Dispatcher.dispatch(new ZoomViewAction(this.props.windowIndex, {scale}));
  }

  @autobind
  private toggleFullscreen(): void {
    Dispatcher.dispatch(new ToggleFullScreenAction(this.props.windowIndex, !this.props.isFullScreen));
  }

  @autobind
  private closeEditor(): void {
    const viewInfo = this.props.viewInfo;
    if (viewInfo) {
      saveView(viewInfo.type, viewInfo.id, viewInfo.name, viewInfo.viewVersion, false);
      Dispatcher.dispatch(new DeactivateViewAction(viewInfo.id));
    }
    Dispatcher.dispatch(new CloseEditorAction(this.props.windowIndex));
  }
}
