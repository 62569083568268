/*
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Metus Team
 */
import * as React from "react";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import {observer} from "mobx-react";
import {PropertiesModel, Tab} from "../model/PropertiesModel";
import {DynamicPropertiesSectionComponent} from "./DynamicPropertiesSectionComponent";

const renderLog = Log.logger("properties", Classifier.render);

const styles = (theme: Theme): StyleRules => createStyles({
  root: {
    // margin: theme.spacing.unit + "px",
  },
});

interface LocalProps<C> {
  tab: Tab<C>;
  activeSectionId: string;
  sectionChangeHandler: (id: string) => void;
  propertiesModel: PropertiesModel<C>;
}

type StyledLocalProps<C> = LocalProps<C> & WithStyles<typeof styles>;

interface LocalState {
}

/**
 * renders the list of sections
 */
@observer
class PropertiesTabComponent<C> extends React.Component<StyledLocalProps<C>, LocalState> {

  constructor(props: StyledLocalProps<C>) {
    super(props);
  }

  render(): JSX.Element {
    renderLog.debug("Rendering PropertiesTabComponent", this.props);
    const sections = this.props.tab.sections.filter(s => s.visible).map(
        (section) => {
          const sectionDefinition = this.props.propertiesModel.getSectionDefinition(section.sectionDefinitionId);
          return <DynamicPropertiesSectionComponent key={sectionDefinition.id}
                                                    section={section}
                                                    sectionDefinition={sectionDefinition}
                                                    sectionChangeHandler={this.props.sectionChangeHandler}
                                                    activeSectionId={this.props.activeSectionId}
          />;
        });
    return <>{sections}</>;
  }

}

export default withStyles(styles)(PropertiesTabComponent);
