import DragType from "../constants/DragTypes";

export interface SourceDragInfo {
  type: DragType;
}

export interface DragTypeAdapterManager {
  /**
   * will return all drag types which can be converted to one of the given types,
   * including the given types themselves.
   *
   * Usage:
   * DropTarget(DragTypesAdapterFactory.getCompatibleTypes(DragType.ELEMENTS),...)
   * will return ELEMENTS, VISUAL_ELEMENTS
   * @param targetTypes one or more identifier
   * @returns list of all compatible identifiers, no duplicates
   */
  getCompatibleTypes(...targetTypes: DragType[]): DragType[];

  /** register the given adapter to convert from fromType to toType */
  register(adapter: DragTypeAdapter, fromType: DragType, toType: DragType): void;

  /** adapt object to the given type */
  adapt(object: SourceDragInfo, toType: DragType): SourceDragInfo;
}

export class DragTypeAdapter {
  readonly from: DragType;
  readonly to: DragType;
  readonly adapt: (t: Object) => Object;

  constructor(from: DragType, to: DragType, adapt: (t: SourceDragInfo) => SourceDragInfo) {
    this.from = from;
    this.to = to;
    this.adapt = adapt;
  }
}
