import {UUID} from "../../api/api";
import {generateUUID} from "../utils/IdGenerator";

export class ActionBase<P> {
  public id?:UUID;
  public resourceId?: string;
  public readonly payload: P;
  public readonly recordable?: boolean = true;
  public readonly _undoable?: boolean = true;
  public groupId?: UUID;
  public readonly type: string;
  public commandId?: UUID;

  // if false it is undone but does not count as a complete step so that the next command on the undo stack also gets undone
  // until the first complete step was undone
  public readonly isCompleteUndoStep?:boolean = true;

  constructor(payload: P, resourceId?: string, groupId?: UUID, recordable: boolean = true, undoable: boolean = true, isCompleteUndoStep:boolean = true) {
    this.payload = payload;
    this.resourceId = resourceId;
    this.groupId = groupId;
    this.recordable = recordable;
    this._undoable = undoable;
    this.isCompleteUndoStep = isCompleteUndoStep;
    this.id = generateUUID();
  }

  public get undoable(): boolean {
    return this._undoable && this.commandId !== null;
  }
}

/**
 * base class for actions not participating in undo/redo recording and replay,
 * all actions reflecting intermediate state to the user should derive from this
 */
export class NotRecordedAction<P> extends ActionBase<P> {
  constructor(payload: P, resourceId?: string) {
    super(payload, resourceId, undefined, false, false);
  }
}

/**
 * base class for actions which are recorded for replay but cannot be undone by the user, e.g. loading the model
 */
export class NotUndoableAction<P> extends ActionBase<P> {
  constructor(payload: P, resourceId?: string, groupId?: string) {
    super(payload, resourceId, groupId, true, false);
  }
}


/**
 * convenience class for very simple non-recordable actions without parameters
 */
export class NotRecordedNoParamsAction extends NotRecordedAction<undefined> {
  constructor(groupId?: UUID) {
    super(undefined);
  }
}

export class LoadAction<P> extends ActionBase<P>{
  constructor(payload: P, resourceId?: string, groupId?: string, recordable: boolean = true, undoable: boolean = true) {
    super(payload, resourceId, groupId, recordable, undoable);
  }
}

/**
 * generic class for loading some data
 */

export type Action = ActionBase<any>;
export default Action;
export type HttpManipulationVerb = "POST" | "PUT" | "DELETE";
type HttpVerb = "GET" | HttpManipulationVerb;

/** selection and initial load of a metus model */
export type ModelLoadActionType =
    "defaultstyles" /* load model wide default styles for table and attribute headers, nodes and attributes */
    | "loadFoldersAndTables" /* load full tree of folders and tables for a model */
    | "attributeDefinitions" /* load attribute meta informations */
    | "loadUserName" /** load user name from server for automatic cookie login */
    | "modellist" /* load model list from server */
    | "modelversion"
    | "webviewHierarchy"
    | "loadResetModel" /** hacked get request to flush the model cache on the server */
    | "modelMeta"
    | "loadServerConfiguration"
    | "writeLock"
    | "workspace"
    | "loadLostAttributes" /*get attributes that cannot be copied from one table to another because of type incompatability*/
    ;
/** load views and core model data shown in views */
export type ViewLoadActionType = "loadAttributeValues" /* load attribute values */
    | "loadConnections" /* load connections needec by a chart */
    | "loadTable" /** open a table as tree grid */
    | "treeGrid" /* load tree grid */
    | "webview" /* load a specific web view */
    | "loadMatrix"
    ;
/**
 * TODO: LoadAction is currently not strongly typed, thus for now use these constants;
 * BETTER WAY: pass ActionFactory to load() method, e.g.
 *  export function loadChart(uuid: string): Promise<DiagramData> {
 *   const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}/charts/${uuid}`;
 *   return load((resourceId, payload) => (new DiagramLoadAction(resourceId, payload), uuid, url);
 *  }
 *
 */
export type LoadActionType = ModelLoadActionType | ViewLoadActionType;

export class ServerRequestInfo {
  /**
   * resolves the promise of the original request
   */
  public resolve: (result: any) => void;

  /**
   * rejects the promise of the original request
   */
  public reject: (reason?: any) => void;

  constructor(
      url: string,
      method: HttpVerb,
      undoable: boolean = true,
      groupId: string = undefined,
      loadType?: LoadActionType,
      resourceId?: string,
  ) {

    this.loadType = loadType;
    this.resourceId = resourceId;
    this.url = url;
    this.undoable = undoable;
    this.groupId = groupId;
    this.method = method;
  }

  public setPromiseCallbacks<T>(resolve: (T) => void, reject: (reason?: any) => void): void {
    this.resolve = resolve;
    this.reject = reject;
  }

  /** unique load type identifier */
  public loadType: LoadActionType;
  /**
   * resource id to fetch
   */
  public resourceId: string;
  /**
   * url for request
   */
  public url: string;
  /**
   * can it be undone ?
   */
  public undoable: boolean;
  /**
   * set if part of an undo group
   */
  public groupId?: string;

  /** http method this request uses, currently the ones listed below are sufficient, extend if needed */
  public method: HttpVerb;
}
