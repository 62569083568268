import {UUID} from "../../api/api";

import uuidCreator from "uuid-base62";

// uid generator generates 36 character URL conforming to rfc 4122, e.g.
// 550e8400-e29b-11d4-a716-446655440000
// base-32 encoding cuts it down to 22 characters, e.g.
// 7mTHIEBesZPDpSLa2n0Em2
// Since base-62 is URL-save, it is perfectly usable as an id in REST-Requests
export function generateUUID(): UUID {
  return uuidCreator.v1(); // v1 is time based, v4 random based
}

export function getRandomInt(): number {
  let retVal: number;
  const numbers = new Uint32Array(1);
  numbers[0] = 1;
  window.crypto?.getRandomValues(numbers);
  retVal = numbers[0];
  if (retVal === 0) {
    retVal = getRandomInt();
  }
  return retVal;
}
