/* ValueChartPropertiesComponent.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, Juni 2018
 */

import Log from "../../../common/utils/Logger";
import * as React from "react";
import {ChangeEvent} from "react";
import {
  createStyles,
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core";
import {VisualTableId} from "../../../core/utils/Core";
import {ChangeColumnCountValueChartLevelAction} from "../../actions/DiagramActions";
import {BaseSectionProperties} from "../../../properties/model/PropertiesModel";
import {Dispatcher} from "../../../common/utils/Dispatcher";
import {Classifier} from "../../../common/utils/ClassifierLogger";
import {StyleRules} from "@material-ui/core/styles";
import autobind from "autobind-decorator";
import {observer} from "mobx-react";

const log = Log.logger("ValueChartConfigurationComponent");
const renderLog = Log.logger("properties", Classifier.render);


const styles = (theme: Theme): StyleRules => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formLabel: {
    paddingBottom: 5,
  },
  formControlLabel: {
    paddingTop: 10,
  },
});


export interface ValueChartProperties extends BaseSectionProperties {
  multiline: boolean;
  visualTables?: { visualTableId: VisualTableId, tableName: string, columnCount: number }[];
}

type StyledLocalProps = ValueChartProperties & WithStyles<typeof styles>;

interface LocalState {
}


@observer
class ValueChartPropertiesComponent extends React.Component<StyledLocalProps, LocalState> {

  constructor(props: StyledLocalProps) {
    super(props);
  }

  @autobind
  private toggleMultiline(event: ChangeEvent<any>, multiline: boolean): void {
    alert("Not yet implememted!");
  }

  @autobind
  private changeColumnCount(event: ChangeEvent<any>): void {

    const visualTableId: VisualTableId = VisualTableId.fromKey(event.target.name);
    const columnCount: number = event.target.value;
    Dispatcher.dispatch(new ChangeColumnCountValueChartLevelAction(this.props.activeViewId, visualTableId, columnCount));
  }

  render(): JSX.Element {
    renderLog.debug("Rendering ValueChartPropertiesComponent");
    return (this.props.visualTables && this.props.visualTables.length > 1) &&
        <FormControl className={this.props.classes.root}>
          <FormLabel className={this.props.classes.formLabel}>Number of columns</FormLabel>
          {
            this.props.visualTables.map((table, index) => {
              if (index > 0) {
                return (
                    <FormControl className={this.props.classes.formControl}>
                      <InputLabel>{table.tableName}</InputLabel>
                      <Select
                          value={table.columnCount}
                          onChange={this.changeColumnCount}
                          input={<Input name={table.visualTableId.toKey()}/>}
                      >
                        {getMenuItems()}
                      </Select>
                    </FormControl>);
              }
            })
          }
        </FormControl>;
  }

}

function getMenuItems(): JSX.Element[] {
  return [1, 2, 3, 4, 5].map(n => <MenuItem key={n} value={n}>{n}</MenuItem>);
}

export default withStyles(styles)(ValueChartPropertiesComponent);
