import {ContainsCaseInsensitiveStringStaticFilterMatcher} from "../../core/utils/filter/static/ContainsCaseInsensitiveStringStaticFilterMatcher";
import {HierarchyFilterResult, revealParent} from "../../matrix/models/HierarchyElementGenerators";

export interface FilterableTreeListItem {
  name:string;
  level:number;
}

function* markListItemsFiltered(listItems: FilterableTreeListItem[], filterExpression:string): IterableIterator<HierarchyFilterResult<FilterableTreeListItem>> {
  const matcher = new ContainsCaseInsensitiveStringStaticFilterMatcher(filterExpression);
  for (let i = 0; i < listItems.length; i++) {
    const element = listItems[i];
    const isFilterMatch = matcher.valueSatisfiesFilterCondition(element.name);
    const markedResult = { isFilterMatch, element};
    yield markedResult;
  }
}

export function filterListItemsAndRevealParents<T extends FilterableTreeListItem>(listItems: T[], filterExpression:string):T[] {
  const treeListItemGenerator = revealParent(markListItemsFiltered(listItems, filterExpression))
  const result =  Array.from(treeListItemGenerator) as T[];
  return result;
}

