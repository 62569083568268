import {IStaticFilterMatcher} from "./IStaticFilterMatcher";

export class GreaterThanStaticFilterMatcher implements IStaticFilterMatcher {
  private _operand: string;

  constructor(operand: string) {
    this._operand = operand;
  }

  valueSatisfiesFilterCondition(value: any): boolean {
    let result = false;
    if (value !== null && value !== undefined && this._operand !== null && this._operand !== undefined) {
      result = value > this._operand;
    }
    return result;
  }

}
