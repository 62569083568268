/* ModelActionCreators.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, August 2017
 */
import Log from "../../common/utils/Logger";
import {ResetModelLocationAction, SelectModelLocationAction} from "./ModelSelectionActions";
import {baseurl, load, loadAndDispatchAction,} from "../../commonviews/actions/RESTCallActionCreatorsBase";
import {
  CommitResult,
  CommitStrategy,
  DefaultStyles,
  HierarchyEntry,
  LockedType,
  ModelMeta,
  MultiuserMode,
  ResultMessage,
  Table,
  TableAttributeDefinitions,
  TreeGridData,
  UUID,
  WriteLock,
  WriteResult
} from "../../api/api";
import {deleteResource, ExplicitStatusCodes, get, post} from "../../core/utils/RESTCallUtil";
import {LoadAction, ServerRequestInfo} from "../../common/actions/BaseAction";
import {modelStore} from "../../core/stores/ModelStore";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {
  AuthenticatedAction,
  AuthenticationInProgressAction,
  HideLoadingAction
} from "../../common/actions/InteractionStateActions";
import {showMessageDialog} from "../../common/utils/CommonDialogUtil";
import base64 from "base-64";
import {loadDefaultStyles} from "../../diagram/actions/DiagramAsyncActionCreators";
import {loadWebViewHierarchy, saveAllOpenViews} from "../../workbench/actions/ViewManagerAsyncActionCreators";
import {generateUUID} from "../../common/utils/IdGenerator";
import {
  createOrUpdateWriteLock,
  deleteWriteLock,
  loadTableFolderTree,
  loadWriteLock
} from "../../core/services/CoreDataServices";
import {SetWriteLockAction} from "../../core/actions/CoreActions";
import {LoadConnectionsAction, SelectModelAction} from "../../core/actions/CoreAsyncActions";
import {LoadMatrixAction} from "../../matrix/actions/MatrixAsyncActions";
import {configurationStore, ServerConfiguration} from "../../core/stores/ConfigurationStore";
import {ModelInfo} from "../../core/models/ModelInfo";
import {ModelLocationType} from "../../common/constants/Enums";

const log = Log.logger("ModelActionCreators");

/**
 * a server was selected, so load server configuration information and model list
 * @param modelLocation
 */
export async function loadInitialServerData(modelLocation: ModelLocationType): Promise<void> {
  Dispatcher.dispatch(new SelectModelLocationAction(modelLocation));
  if (modelLocation != null) {
    await loadServerConfiguration(modelLocation);
    loadModelList(modelLocation);
  }
}

export function resetModelLocation(): void {
  Dispatcher.dispatch(new ResetModelLocationAction());
}

/**
 * when a project/model is selected, full metadata and navbar data is loaded here.
 * @param modelInfo
 */
export function loadInitialModelData(modelInfo: ModelInfo): Promise<any> {
  const groupId = generateUUID();

  return getLatestVersion(modelInfo)
      .then(version => {
        modelInfo.version = version;
        Dispatcher.dispatch(new SelectModelAction(modelInfo));
        return modelInfo;
      })
      .then((modelInfo: ModelInfo) => {
        return loadDefaultStyles(groupId);
      })
      .then(() => {
        const promises: Promise<any>[] = [];
        promises.push(loadTableFolderTree(groupId));
        promises.push(loadWebViewHierarchy(groupId));
        const loadModelMetaPromise = loadModelMeta(groupId);
        if (modelInfo.location === ModelLocationType.liveserver) {
          // model meta information needed before write lock can be set
          promises.push(loadModelMetaPromise.then(() => loadWriteLock(groupId)));
        } else {
          promises.push(loadModelMetaPromise);
        }
        return Promise.all(promises);
      }).catch((error) => {
        throw new Error(error);
      });
}


/** load user name from server if authenticated via cookie */
export function loadUserName(modelLocation: ModelLocationType): Promise<any> {
  // only in server case, otherwise ignore
  if (modelLocation === ModelLocationType.server) {
    const url = `${baseurl}/${ModelLocationType[modelLocation]}/session`;
    const loadType = "loadUserName";
    return loadAndDispatchAction(loadType, null, url);
  }
  return Promise.resolve();
}

export async function loadModelList(modelLocation: ModelLocationType): Promise<any> {
  const url = `${baseurl}/${ModelLocationType[modelLocation]}/models`;

  try {
    const result = await loadAndDispatchAction("modellist", null, url, true, undefined, {}, [404, 500]);
    // a more robust check that modellist loading finished with authenticate would be nice
    if (result) {
      return loadUserName(modelLocation);
    }
  } catch (e) {
    showMessageDialog(true, "The project list could not be loaded.\nPlease check if your system is configured properly.")
    Dispatcher.dispatch(new HideLoadingAction());
  }

}


export async function createModel(modelName: string, multiuserMode: MultiuserMode, version: string = "0"): Promise<void> {
  const url = `${baseurl}/${ModelLocationType[ModelLocationType.liveserver]}/models`;

  try {
    const explicitStatusCodes: ExplicitStatusCodes = {knownErrorCodes: [409, 500]};
    const requestBody = {modelName, multiuserMode};
    const result: void | WriteResult<any> = await post(JSON.stringify(requestBody), url, {"content-type": "application/json"}, true, explicitStatusCodes);

    Dispatcher.dispatch({
      type: "modellist",
      undoable: false,
      payload: (<WriteResult<string[]>>result).json
    });
  } catch (e) {
    throw e;
  }

}

export async function deleteModel(modelName: string, version: string = "0"): Promise<void> {
  const url = `${baseurl}/${ModelLocationType[ModelLocationType.liveserver]}/models/${modelName}/${version}`;
  const result: void | WriteResult<any> = await deleteResource(url, true);

  Dispatcher.dispatch({
    type: "modellist",
    undoable: false,
    payload: (<WriteResult<string[]>>result).json
  });
}

export function getLatestVersion(modelInfo: ModelInfo): Promise<any> {
  return modelInfo.location === ModelLocationType.server
      ? load("modelversion", modelInfo.name, `${baseurl}/server/models/${modelInfo.name}/latestversion`, false)
      : Promise.resolve(0);
}

/**
 * loads information regarding the selected server to behave differently for Scalable and Classic Server
 * @param modelLocation
 */
export function loadServerConfiguration(modelLocation: ModelLocationType): Promise<ServerConfiguration> {
  const url = `${baseurl}/${ModelLocationType[modelLocation]}/configuration`;
  return load("loadServerConfiguration", null, url).then((result: ServerConfiguration) => {
    log.debug("Loaded ServerConfiguration", result);
    Dispatcher.dispatch(new LoadServerConfigurationAction(result, null, null));
    return result;
  });
}

export function loadModelMeta(groupId: UUID): Promise<ModelMeta> {
  const url = `${baseurl}/${modelStore.modelInfo.locationString}/models/${modelStore.modelInfo.name}/${modelStore.modelInfo.version}`;
  return load("modelMeta", null, url).then((result: ModelMeta) => {
    log.debug("Loaded Model Meta", result);
    if (result !== undefined) {
      Dispatcher.dispatch({
        type: "loadModelMeta",
        resourceId: null,
        groupId: groupId,
        payload: result,
        undoable: true
      });
    }
    return result;
  });
}

export function authenticate(serverRequestInfo: ServerRequestInfo, username: string, password: string, body?: string): Promise<any> {
  const credentials = `Metus ${base64.encode(username + ":" + password)}`;
  const headers = {"Metus-Authorization": credentials};
  Dispatcher.dispatch(new AuthenticationInProgressAction(true));
  const action = loadAndDispatchAction(serverRequestInfo.loadType, serverRequestInfo.resourceId, serverRequestInfo.url, serverRequestInfo.undoable, serverRequestInfo.groupId, headers);
  return action
      .then((args) => {
        Dispatcher.dispatch(new AuthenticationInProgressAction(false));
        Dispatcher.dispatch(new AuthenticatedAction(username));
        serverRequestInfo.resolve(args);
        return args;
      });
}

export function logout(modelLocation: ModelLocationType): Promise<void | WriteResult<null>> {
  // logout session at server
  const url = `${baseurl}/${ModelLocationType[modelLocation]}/logout`;
  return get(url, () => Promise.resolve()).then(() => {
    resetModelLocation();
  });
}

// reset the model cache in the server and refresh model and access rights
export function resetModelCache(modelInfo: ModelInfo): Promise<any> {
  return getLatestVersion(modelInfo)
      .then(version => {
        modelInfo.version = version;
        const url = `${baseurl}/${modelInfo.locationString}/models/${modelInfo.name}/${modelInfo.version}/reset`;
        return load("loadResetModel", undefined, url, false).then(
            (json: ResultMessage) => {
              showMessageDialog(true, json.messages.join("\n"));
            }
        );
      });
}

export class LoadDefaultStylesAction extends LoadAction<DefaultStyles> {
  type: "defaultstyles" = "defaultstyles";
}

export class LoadFoldersAndTablesAction extends LoadAction<HierarchyEntry> {
  type: "loadFoldersAndTables" = "loadFoldersAndTables";
}

export class LoadWebViewHierarchyAction extends LoadAction<HierarchyEntry> {
  type: "webviewHierarchy" = "webviewHierarchy";
}

export class LoadAttributeDefinitionsAction extends LoadAction<TableAttributeDefinitions[]> {
  type: "attributeDefinitions" = "attributeDefinitions";
}

export class LoadAttributeValuesAction extends LoadAction<Table[]> {
  type: "loadAttributeValues" = "loadAttributeValues";
}

export class LoadUserNameAction extends LoadAction<string> {
  type: "loadUserName" = "loadUserName";
}

export class LoadTableAction extends LoadAction<TreeGridData> {
  type: "loadTable" = "loadTable";
}

export class LoadResetModelAction extends LoadAction<string> {
  type: "loadResetModel" = "loadResetModel";
}

export class LoadModelMetaAction extends LoadAction<ModelMeta> {
  type: "loadModelMeta" = "loadModelMeta";
}

export class LoadServerConfigurationAction extends LoadAction<ServerConfiguration> {
  constructor(payload: ServerConfiguration, resourceId?: string, groupId?: string) {
    super(payload, resourceId, groupId, false, false);
  }

  type: "loadServerConfiguration" = "loadServerConfiguration";
}

export type LoadActions =
    LoadDefaultStylesAction
    | LoadFoldersAndTablesAction
    | LoadAttributeDefinitionsAction
    | LoadAttributeValuesAction
    | LoadConnectionsAction
    | LoadUserNameAction
    | LoadTableAction
    | LoadResetModelAction
    | LoadModelMetaAction
    | LoadServerConfigurationAction
    | LoadMatrixAction
    | LoadWebViewHierarchyAction
    ;

export function acquireWriteLock(overwriteExistingLock: boolean = false, workspace: string = undefined): any {
  createOrUpdateWriteLock(overwriteExistingLock, workspace)
      .then((writeResult: WriteResult<WriteLock>) => {
        if (writeResult) {
          Dispatcher.dispatch(new SetWriteLockAction(writeResult.json));
          const modelInfo = modelStore.modelInfo;
          loadInitialModelData(modelInfo);
        }
      });
}

/**
 * releases write lock after editing, saves all open views before releasing
 */
export async function unlock(commitStrategy = CommitStrategy.Discard): Promise<CommitResult> {
  let commitResult: CommitResult;

  await saveAllOpenViews();

  const writeResult = await deleteWriteLock(commitStrategy);
  if (writeResult) {
    commitResult = writeResult.json;
    if (commitResult?.type === "ConflictDescription") {
      log.debug("Delete Write Lock returned conflicts", commitResult);
    } else {
      log.debug("Delete Write Lock returned result", commitResult);
      Dispatcher.dispatch(new SetWriteLockAction({locked: LockedType.None}));
      if (configurationStore.modelMeta.multiuserMode === "merge") {
        const modelInfo = modelStore.modelInfo;
        await loadInitialModelData(modelInfo);
      }
    }
  }

  return commitResult;
}
