import {RotationDegree} from "../../../api/api";

export class MatrixVisualConstants {
  /**
   * default matrix column rotated header height
   */
  public static readonly MATRIX_DEFAULT_ROTATED_HEADER_HEIGHT: number = 150;

  /**
   * default matrix column rotated header width
   */
  public static readonly MATRIX_DEFAULT_ROTATED_HEADER_WIDTH: number = 50;

  /**
   * default matrix column header rotation
   */
  public static readonly MATRIX_DEFAULT_HEADER_ROTATION: RotationDegree = 90;

  public static readonly BASE_ROW_FONT_SIZE: number = 12;

  public static readonly BASE_GROUPHEADER_HEIGHT: number = 40;

  public static readonly BASE_HEADER_WIDTH: number = 50;

  public static readonly BASE_NAME_ATT_HEADER_WIDTH: number = 150;

  public static readonly BASE_ROW_HEIGHT: number = 25;

  public static readonly FLOATING_FILTER_HEIGHT: number = 25;

}

export type MatrixColumnHeaderClass =
    "matrixColumnHeader"
    | "matrixColumnHeaderRotated"
    | "matrixColumnHeaderColorIndex0"
    | "matrixColumnHeaderColorIndex1"
    | "matrixColumnHeaderColorIndex2"
    | "matrixColumnHeaderColorIndex3"
    | "matrixColumnHeaderColorIndex4"
    | "matrixColumnHeaderColorIndex5"
    | "columnGroupShowPlaceholder"
    | "matrixRowGroupHeader";