import {action, autorun, IReactionDisposer, IReactionPublic, observable} from "mobx";
import autobind from "autobind-decorator";
import Log from "../../common/utils/Logger";
import {ElementPathKey, elementPathKeyLength, elementPathToKey} from "./MatrixCoreDefinitions";
import {filterElements, HierarchyElement, hierarchyElements} from "./HierarchyElementGenerators";
import {MatrixModel} from "./MatrixModel";

const log = Log.logger("MatrixRowElements");

export interface IMatrixRowElements {
  ids: Set<ElementPathKey>;
}

/**
 * From matrix.rowHierarchy.tables create the list of filtered rows
 * automatically update the list if
 * <ul>
 *   <li>filter changes</li>
 *   <li>elements are added or removed on first level</li>
 *   <li>connections from elements of rowHierarchy.tables are added or removed</li>
 * </ul>
 */
export class MatrixRowElements implements IMatrixRowElements {
  // observable Sets are missing in our version of mobx (comes with 5.9.0)
  // should be Set<ElementPath>, but Sets only support primitive types, thus the map uses JSON.stringify of ElementPath as map index for the object
  @observable private _ids: Set<ElementPathKey> = new Set<ElementPathKey>();
  private _idsToExist: Map<ElementPathKey, boolean> = new Map<ElementPathKey, boolean>();
  private _autorunDisposer: IReactionDisposer;

  /**
   *
   * @param _matrix, matrix model
   */
  constructor(private _matrix: MatrixModel) {
    // This is more or less an intelligent implementation of a computed property,
    // which recomputes the rows set with minimal changes to the observable _ids set,
    // Could it be replaced by a computed property in MatrixModel which does the same efficient update ?
    this._autorunDisposer = autorun(this.createRows);
  }

  get ids(): Set<ElementPathKey> {
    return this._ids;
  }

  @autobind
  private createRows(r: IReactionPublic): void {
    log.debug("createRows triggered" + Date.now());
    const updateObservedIds = action((added: ElementPathKey[], removed: ElementPathKey[]): void => {
      log.debug("removing rows in observable set", removed);
      // MO-2006 make sure to delete children before their parents, otherwise ag-grid throws exceptions
      removed.sort((s: string, t: string) => elementPathKeyLength(t) - elementPathKeyLength(s)).forEach(pathKey => {
        this._idsToExist.delete(pathKey);
        this._ids.delete(pathKey);
      });
      log.debug("adding new rows to observable set", added);
      added.forEach(pathKey => {
        this._idsToExist.set(pathKey, true);
        this._ids.add(pathKey);
      });
      log.debug("Update completed");
    });
    log.debug("MatrixRowElements: recalculate rows");
    const toBeRemoved = new Map<ElementPathKey, boolean>(this._idsToExist);
    const toBeAdded: ElementPathKey[] = [];
    const rowTables = this._matrix.rowHierarchy.tables;
    if (rowTables.length > 0) {
      const rootTable = rowTables[0];
      const rowElementIterator: IterableIterator<HierarchyElement> = hierarchyElements(this._matrix.rowHierarchy);

      // Just filter if filter is set
      let filteredRowElementIterator;
      if (this._matrix.filterIsShown || this._matrix.rowHierarchy.showConnectedOnly) {
        filteredRowElementIterator = filterElements(this._matrix.rowHierarchy, rowElementIterator);
      } else {
        filteredRowElementIterator = rowElementIterator;
      }

      for (const hierarchyElement of filteredRowElementIterator) {
        const pathKey = elementPathToKey(hierarchyElement.path);
        const isNew = !this._idsToExist.get(pathKey);
        if (isNew) {
          toBeAdded.push(pathKey);
        } else {
          toBeRemoved.delete(pathKey);
        }
      }
    }

    // do updates in an action otherwise mobx complains
    updateObservedIds(toBeAdded, Array.from(toBeRemoved.keys()));
  }

  public dispose(): void {
    this._autorunDisposer();
  }
}