/* ViewVersions.ts
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Klaus.Freiberger, April 2022
 */

import {ViewType} from "./Enums";

export const CHART_VERSION: number = 1;
export const VALUE_CHART_VERSION: number = 1;
export const STRUCTURED_TABLE_VERSION: number = 1;
export const MATRIX_VERSION: number = 1;
export const CHAIN_MATRIX_VERSION: number = 1;
export const TABLE_VERSION: number = 0; // set to 0 because they are not completely implemented
export const WEB_VIEW_HIERARCHY_VERSION: number = 0;
export const COCKPIT_VERSION: number = 0;
export const FOLDER_VERSION: number = 0; // Necessary for ListItemHierarchy
export const ATTRIBUTE_VERSION: number = 0; // Maybe necessary for ListItemHierarchy

export function getVersionOfViewType(viewType: ViewType): number {
  switch (viewType) {
    case ViewType.Chart:
      return CHART_VERSION;
    case ViewType.ValueChart:
      return VALUE_CHART_VERSION;
    case ViewType.StructuredTable:
      return STRUCTURED_TABLE_VERSION;
    case ViewType.Matrix:
      return MATRIX_VERSION;
    case ViewType.ChainMatrix:
      return CHAIN_MATRIX_VERSION;
    case ViewType.Table:
      return TABLE_VERSION;
    case ViewType.WebViewHierarchy:
      return WEB_VIEW_HIERARCHY_VERSION;
    case ViewType.Cockpit:
      return COCKPIT_VERSION;
    default:
      return -1;
  }
}
