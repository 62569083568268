import {DropTargetMonitor, DropTargetSpec, XYCoord} from "react-dnd";
import DragTypes from "../../common/constants/DragTypes";
import {NodeProps} from "../../workbench/components/TreeListItemComponent";
import Log from "../../common/utils/Logger";
import {Classifier} from "../../common/utils/ClassifierLogger";
import {Dispatcher} from "../../common/utils/Dispatcher";
import {DndTargetFeedbackAction} from "../../common/actions/InteractionStateActions";
import {Cursor, PersistencyState, Target, ViewType} from "../../common/constants/Enums";
import {TableHierarchyHeaderComponentProps} from "./TableHierarchyHeaderComponentNoDnd";
import {FeedbackHelper} from "../../common/utils/DragDropHelper";
import * as React from "react";
import {VisualTableId} from "../../core/utils/Core";
import {VisualTableDragInfo} from "../../common/utils/CoreDropTypes";
import {ViewInfo} from "../models/ViewInfo";
import {AddTableToViewAction} from "../../core/actions/CoreAsyncActions";
import {getVersionOfViewType} from "../../common/constants/ViewVersions";

const dndLog = Log.logger("TableHierarchyHeaderComponent", Classifier.dnd);
export const tableHierarchyHeaderComponentDropSpec: DropTargetSpec<TableHierarchyHeaderComponentProps> = {
  hover(props: TableHierarchyHeaderComponentProps, monitor: DropTargetMonitor, component: any): void {
    dndLog.debug("Hover", monitor.getItemType(), monitor.getItem());
    const mousePosition: XYCoord = monitor.getClientOffset() ? monitor.getClientOffset() : {x: 100, y: 100};
    if (props.viewContext.viewType !== ViewType.Table) {
      switch (monitor.getItemType()) {
        case DragTypes.CORE_TABLE:
          const tableItem: NodeProps = monitor.getItem() as NodeProps;
          dndLog.debug("Table hovering, setting table target feedback");
          Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.COPY, "add table to " + (props.isColumnHierarchy ? "columns" : "rows")));
          break;
        case DragTypes.VISUAL_TABLE_COLUMN_MOVE:
          const shallow = monitor.isOver({shallow: true});
          dndLog.debug("Hover over empty hierarchy", shallow);
          if (shallow) {
            const box = FeedbackHelper.createTargetFeedbackBox(component, monitor);
            dndLog.debug("Setting target feedback");
            Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.ALIAS, <div
                style={{position: "absolute"}}>{box} </div>));
          }
          break;
        default:
          dndLog.error("Hovering with unknown type, please handle type or remove it from DropTarget types. If type is adaptable, please adapt it");
          break;
      }
    } else {
      Dispatcher.dispatch(new DndTargetFeedbackAction(Cursor.NO_DROP, "cannot alter the table configuration of a table view"));
    }
  },

  canDrop(props: TableHierarchyHeaderComponentProps, monitor: DropTargetMonitor): boolean {
    dndLog.debug("Can Drop", monitor.getItemType(), monitor.getItem());
    const mousePosition: XYCoord = monitor.getClientOffset() ? monitor.getClientOffset() : {x: 100, y: 100};
    let result: boolean = false;
    if (props.viewContext.viewType !== ViewType.Table) {
      switch (monitor.getItemType()) {
        case DragTypes.CORE_TABLE:
          result = true;
          break;
        case DragTypes.VISUAL_TABLE_COLUMN_MOVE:
          result = true;
          break;
        default:
          result = false;
          break;
      }
    } else {
      result = false;
    }
    return result;
  },

  drop(props: TableHierarchyHeaderComponentProps, monitor: DropTargetMonitor, component: any): Object {
    dndLog.debug("Dropped " + monitor.getItemType().toString());
    const shallow = monitor.isOver({shallow: true});
    dndLog.debug("TableHierarchyHeaderComponent drop " + shallow);
    if (monitor.canDrop()) {
      let result = undefined;
      const viewInfo = new ViewInfo(props.viewContext.viewType, props.viewContext.viewId, "dummy-name", PersistencyState.Loaded, getVersionOfViewType(props.viewContext.viewType));
      const hasDroppedOnChild = monitor.didDrop();
      switch (monitor.getItemType()) {
        case DragTypes.CORE_TABLE:
          if (!hasDroppedOnChild) {
            dndLog.debug(`Adding table to matrix ${viewInfo.id} + ${props.isColumnHierarchy ? "columns" : "rows"}`);
            result = {
              viewInfo,
              targetPosition: undefined,
              isColumn: props.isColumnHierarchy
            };
          }
          break;
        case DragTypes.VISUAL_TABLE_COLUMN_MOVE:
          if (!hasDroppedOnChild) {
            const item: VisualTableDragInfo = monitor.getItem();
            if (item) {
              dndLog.debug("Drop VISUAL_TABLE_COLUMN " + JSON.stringify(item));
              const newVisualTableId = new VisualTableId(item.tableId);
              Dispatcher.dispatch(new AddTableToViewAction(props.viewContext.viewId, newVisualTableId, undefined, undefined, props.isColumnHierarchy ? Target.COLUMN : Target.ROW));
            } else {
              dndLog.error("Drop with unknown type, please handle type or remove it from DropTarget types. If type is adaptable, please adapt it");
            }
          }
          break;
        default:
          dndLog.error("Drop with unknown type, please handle type or remove it from DropTarget types. If type is adaptable, please adapt it");
          break;
      }
      dndLog.debug("DiagramComponent.drop returning " + result, result);
      return result;
    }
  }
};
