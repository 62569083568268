import {observer} from "mobx-react";
import * as React from "react";
import {GridApi, ICellRendererParams} from "ag-grid-community";
import Log from "../../../common/utils/Logger";
import {ElementId} from "../../../core/utils/Core";
import {createStyles, Theme, WithStyles, withStyles} from "@material-ui/core";
import {StyleRules} from "@material-ui/core/styles";
import {AttributeDefinition} from "../../../api/api";
import {getImageUri} from "../../../core/utils/ImageUtil";
import {calcFontSizeFromScale, cellContainer, isWordWrap, wordWrapCSS} from "./common";

const classNames = require("classnames");

const log = Log.logger("MatrixComponent");

interface AttributeCellRendererComponentProps extends ICellRendererParams {
}

const styles = (theme: Theme): StyleRules => createStyles({
  cell: {
    minWidth: "10px",
    minHeight: "10px",
    display: "inline-block",
  },
  cellContainer,
  wordWrapCSS
});

type StyledLocalProps = AttributeCellRendererComponentProps & WithStyles<typeof styles>;
/**
 * inner renderer for attributes in a structured table
 * @author Lorenzo Russo da Costa Auer
 */
@observer
class AttributeCellRendererComponent extends React.Component<StyledLocalProps> {
  private readonly gridApi: GridApi;

  constructor(props: StyledLocalProps) {
    super(props);
  }

  render(): JSX.Element {
    const rowElementId = this.getRowElementId();
    const colElementId = this.getColElementId();
    const {displayValue, displayElement} = this.renderElementForDisplay();
    const className = classNames(this.props.classes.cell, {[this.props.classes.wordWrapCSS]: isWordWrap(this.props)});
    return <span className={className}
                 data-testselector={"ElementName/" + displayValue + "/"}
                 data-tip={displayValue}
                 style={{fontSize: calcFontSizeFromScale(this.props.context)}}>
            {displayElement}
          </span>;
  }

  private renderElementForDisplay(): { displayValue: string, displayElement: JSX.Element } {
    const rawValue = this.props.value;
    const displayValue: string = this.props.valueFormatted;
    const imageElement = this.calculateImageElement(rawValue);
    let displayElement: JSX.Element = imageElement ? imageElement : <span>{displayValue}</span>;
    return {displayValue, displayElement};
  }

  private calculateImageElement(value: string): JSX.Element {
    let retVal: JSX.Element;
    const attributeDefinition = this.getAttributeDefinition();
    // NOTE: attributeDefinition can be undefined if we are in a NOT leaf row and the attribute is not defined
    // in this superior table
    if (attributeDefinition && "Image" === attributeDefinition.type && (value && value.length > 0)) {
      retVal = <img src={getImageUri(value)} width={this.props.column.getActualWidth() - 30} alt="image"/>;
    }
    return retVal;
  }

  private getColElementId(): ElementId {
    return this.props.colDef.field;
  }

  private getRowElementId(): ElementId {
    return this.props.data.elementId;
  }

  private getAttributeDefinition(): AttributeDefinition {
    return this.props.context.getAttributeDefinitionByNodeLevel(this.props.node.level, this.props.colDef.field);
  }

}

export default withStyles(styles)(AttributeCellRendererComponent);

