import {AttributeDefinitionImpl, ElementId, TableId} from "../Core";
import {
  FilterMatcher,
  IFilterMatcher,
  nonePseudoMatcher,
  NumberFilterMatcher,
  StringFilterMatcher
} from "./FilterMatcher";
import {AttributeDefinition} from "../../../api/api";
import {modelStore} from "../../stores/ModelStore";
import {format} from "../NumberFormatter";
import {
  renderDisplayValue
} from "../../../common/utils/FormatUtil";

/**
 * Created by P.Bernhard on 11.10.2017.
 */


export interface IFilter {
  matches(nodeIds: ElementId[], ignoreNoneMatchigFilters?: boolean): ElementId[];
}

export interface IAttributeFilter extends IFilter {
  matches(nodeIds: ElementId[], ignoreNoneMatchingFilters?: boolean): ElementId[];
}

export abstract class AttributeFilter<T> implements IAttributeFilter {
  protected _attributeName: string;
  protected _table: TableId;
  protected _matcher: FilterMatcher<T>;
  protected _filterText: string;

  protected constructor(attributeName: string, table: TableId, filterText: string, matcher: FilterMatcher<T>) {
    this._attributeName = attributeName;
    this._table = table;
    this._matcher = matcher;
    this._filterText = filterText;
  }

  public abstract matches(nodeIds: ElementId[], ignoreNoneMatchingFilters?: boolean): ElementId[];

  protected getAttributeValue(nodeId: ElementId): string {
    const attributeValue: string = modelStore.getElement(nodeId)[this._attributeName];
    return attributeValue === null ? "" : attributeValue;
  }
}

class NoneMatchingFilter implements IAttributeFilter {

  public matches(nodeIds: ElementId[], ignoreNoneMatchingFilters?: boolean): ElementId[] {
    if (ignoreNoneMatchingFilters) {
      return nodeIds;
    }
    return [];
  }
}


export class AttributeStringFilter extends AttributeFilter<string> {

  constructor(attributeName: string, table: TableId, filterText: string, matcher: StringFilterMatcher) {
    super(attributeName, table, filterText, matcher);
  }

  public matches(nodeIds: ElementId[]): ElementId[] {
    if (this._matcher === null || this._matcher === undefined)
      return nodeIds;

    const filteredNodeIds: ElementId[] = [];

    for (const nodeId of nodeIds) {
      if (this._matcher.valueSatisfiesFilterCondition(this.getAttributeValue(nodeId), this._filterText, this._table, this._attributeName)) {
        filteredNodeIds.push(nodeId);
      }
    }

    return filteredNodeIds;
  }

}


export class AttributeNumberFilter extends AttributeFilter<number> {

  constructor(attributeName: string, table: TableId, filterText: string, matcher: NumberFilterMatcher) {
    super(attributeName, table, filterText, matcher);
  }

  public matches(nodeIds: ElementId[]): ElementId[] {
    if (this._matcher === null || this._matcher === undefined)
      return nodeIds;

    const filteredNodeIds: ElementId[] = [];

    for (const nodeId of nodeIds) {
      const attributeDefinition: AttributeDefinition = modelStore.getAttributeDefinition(this._table, this._attributeName);
      const viewAttributeDefinition: AttributeDefinition = new AttributeDefinitionImpl(attributeDefinition.type, attributeDefinition.formatType, attributeDefinition.pattern, attributeDefinition.editable, attributeDefinition.editPattern);
      const value: number = formatInternalNumber(this.getAttributeValue(nodeId), viewAttributeDefinition);

      if (this._matcher.valueSatisfiesFilterCondition(value, this._filterText, this._table, this._attributeName)) {
        filteredNodeIds.push(nodeId);
      }
    }

    return filteredNodeIds;
  }

}

function formatInternalNumber(rawValue: string, definition: AttributeDefinition): number {
  if (rawValue !== null && rawValue !== undefined && definition.formatType === "Double") {
    const percentValue: number = percentResult(rawValue, definition);
    if(percentValue) {
      return percentValue;
    }

    return Number.parseFloat(format(definition.pattern, rawValue, true));
  }

  return undefined;
}

function percentResult(rawValue: string, definition: AttributeDefinition): number {
  if(!definition.pattern)
    return undefined;

  let displayValue: string = renderDisplayValue(rawValue, definition);
  if(displayValue.endsWith("%")) {
    const shiftedResult: string = (Number.parseFloat(rawValue) * 100) + "";
    const shiftedResultNum: number = Number.parseFloat(format(definition.pattern, shiftedResult, true));
    // If you want to filter real percent numbers (e.g. >54 instead of >5,4) return shiftedResultNum;
    const backShiftedResult: number = shiftedResultNum / 100;

    return backShiftedResult;
  }

  return undefined;
}

export class AttributeFilterData {
  attributeName: string;
  filterText: string;
  matcher: IFilterMatcher;
}

export function createAttributeFilterForMatcher(attributeName: string, tableId: TableId, filterExpression: string, matcher: IFilterMatcher): IFilter {
  if (matcher instanceof NumberFilterMatcher) {
    return new AttributeNumberFilter(attributeName, tableId, filterExpression, matcher);
  } else if (matcher instanceof StringFilterMatcher) {
    return new AttributeStringFilter(attributeName, tableId, filterExpression, matcher);
  } else if (matcher === nonePseudoMatcher || matcher === null) {
    return new NoneMatchingFilter();
  }
  return undefined;
}

export class ElementFilter implements IFilter {
  private readonly _filters: IAttributeFilter[];

  constructor(attributesFilterData: AttributeFilterData[], table: TableId) {
    this._filters = [];

    for (const attributeFilterData of attributesFilterData) {
      const filter = createAttributeFilterForMatcher(attributeFilterData.attributeName, table, attributeFilterData.filterText, attributeFilterData.matcher);
      if (filter) {
        this._filters.push(filter);
      }
    }
  }

  public matches(nodeIds: ElementId[]): ElementId[] {
    if (this._filters.length === 0)
      return nodeIds;

    const remainingNodeIdsForAttributes: Array<Array<ElementId>> = [];
    const remainingNodeIds: ElementId[] = [];

    for (const filter of this._filters) {
      remainingNodeIdsForAttributes.push(filter.matches(nodeIds));
    }

    const remainingNodeIdsFirstAttribute: ElementId[] = remainingNodeIdsForAttributes.shift();

    for (const remainingNodeIdFirstAttribute of remainingNodeIdsFirstAttribute) {
      let idIsRemainingForAllFilters: boolean = true;

      for (const remainingNodeIdsForAttribute of remainingNodeIdsForAttributes) {
        if (remainingNodeIdsForAttribute.indexOf(remainingNodeIdFirstAttribute) < 0) {
          idIsRemainingForAllFilters = false;
          break;
        }
      }

      if (idIsRemainingForAllFilters) {
        remainingNodeIds.push(remainingNodeIdFirstAttribute);
      }
    }

    return remainingNodeIds;
  }

  private getAttributeValue(nodeId: ElementId, attributeName: string): string {
    const attributeValue: string = modelStore.getElement(nodeId)[attributeName];
    return attributeValue === null ? "" : attributeValue;
  }
}
