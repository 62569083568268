/* RequirementsIcon.tsx
 * Copyright (C) METUS GmbH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by georg.bogner, März 2019
 */
import {MetusSvgIcon, svgIconStyles} from "../MetusSvgIcon";
import {withStyles} from "@material-ui/core";
import * as React from "react";

class RequirementsIcon extends MetusSvgIcon {
  svg(): JSX.Element {
    return <React.Fragment>
      <style type="text/css">{`
      .st0requ{fill:#FFFFFF;}
      .st1requ{fill:#FFFFFF;fill-opacity:0.5;}
     `}</style>
      <g>
        <g>
          <g>
            <g>
              <path className="st0requ" d="M28.01,37.42h-5.13c-1.43,0-2.59-1.16-2.59-2.59V29.7c0-1.43,1.16-2.59,2.59-2.59h5.13
					c1.43,0,2.59,1.16,2.59,2.59v5.13C30.6,36.26,29.44,37.42,28.01,37.42z M22.88,27.93c-0.98,0-1.77,0.79-1.77,1.77v5.13
					c0,0.98,0.79,1.77,1.77,1.77h5.13c0.98,0,1.77-0.79,1.77-1.77V29.7c0-0.98-0.79-1.77-1.77-1.77H22.88z"/>
              <path className="st0requ" d="M28.01,37.79h-5.13c-1.63,0-2.96-1.33-2.96-2.96V29.7c0-1.63,1.33-2.96,2.96-2.96h5.13
					c1.63,0,2.96,1.33,2.96,2.96v5.13C30.97,36.46,29.64,37.79,28.01,37.79z M22.88,27.47c-1.23,0-2.23,1-2.23,2.23v5.13
					c0,1.23,1,2.23,2.23,2.23h5.13c1.23,0,2.23-1,2.23-2.23V29.7c0-1.23-1-2.23-2.23-2.23H22.88z M28.01,36.97h-5.13
					c-1.18,0-2.14-0.96-2.14-2.14V29.7c0-1.18,0.96-2.14,2.14-2.14h5.13c1.18,0,2.14,0.96,2.14,2.14v5.13
					C30.14,36.01,29.18,36.97,28.01,36.97z M22.88,28.3c-0.77,0-1.4,0.63-1.4,1.4v5.13c0,0.77,0.63,1.4,1.4,1.4h5.13
					c0.77,0,1.4-0.63,1.4-1.4V29.7c0-0.77-0.63-1.4-1.4-1.4H22.88z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path className="st0requ" d="M28.01,54.4h-5.13c-1.43,0-2.59-1.16-2.59-2.59v-5.13c0-1.43,1.16-2.59,2.59-2.59h5.13
					c1.43,0,2.59,1.16,2.59,2.59v5.13C30.6,53.24,29.44,54.4,28.01,54.4z M22.88,44.91c-0.98,0-1.77,0.79-1.77,1.77v5.13
					c0,0.98,0.79,1.77,1.77,1.77h5.13c0.98,0,1.77-0.79,1.77-1.77v-5.13c0-0.98-0.79-1.77-1.77-1.77H22.88z"/>
              <path className="st0requ" d="M28.01,54.77h-5.13c-1.63,0-2.96-1.33-2.96-2.96v-5.13c0-1.63,1.33-2.96,2.96-2.96h5.13
					c1.63,0,2.96,1.33,2.96,2.96v5.13C30.96,53.44,29.64,54.77,28.01,54.77z M22.88,44.45c-1.23,0-2.23,1-2.23,2.23v5.13
					c0,1.23,1,2.23,2.23,2.23h5.13c1.23,0,2.23-1,2.23-2.23v-5.13c0-1.23-1-2.23-2.23-2.23H22.88z M28.01,53.94h-5.13
					c-1.18,0-2.14-0.96-2.14-2.14v-5.13c0-1.18,0.96-2.14,2.14-2.14h5.13c1.18,0,2.14,0.96,2.14,2.14v5.13
					C30.14,52.98,29.18,53.94,28.01,53.94z M22.88,45.28c-0.77,0-1.4,0.63-1.4,1.4v5.13c0,0.77,0.63,1.4,1.4,1.4h5.13
					c0.77,0,1.4-0.63,1.4-1.4v-5.13c0-0.77-0.63-1.4-1.4-1.4H22.88z"/>
            </g>
          </g>
        </g>
        <path className="st1requ" d="M58.68,34.94H35.02c-0.77,0-1.4-0.63-1.4-1.4V31c0-0.77,0.63-1.4,1.4-1.4h23.66c0.77,0,1.4,0.63,1.4,1.4v2.54
		C60.08,34.31,59.45,34.94,58.68,34.94z"/>
        <path className="st1requ" d="M58.68,51.91H35.02c-0.77,0-1.4-0.63-1.4-1.4v-2.54c0-0.77,0.63-1.4,1.4-1.4h23.66c0.77,0,1.4,0.63,1.4,1.4
		v2.54C60.08,51.29,59.45,51.91,58.68,51.91z"/>
        <path className="st0requ" d="M49.16,52.71"/>
        <g>
          <g>
            <path className="st0requ" d="M24.15,34.94c-0.24,0-0.48-0.1-0.65-0.27l-4.33-4.23c-0.37-0.36-0.38-0.95-0.02-1.32
				c0.36-0.37,0.95-0.38,1.32-0.02l3.66,3.57l8.39-8.69c0.36-0.37,0.95-0.38,1.32-0.02c0.37,0.36,0.38,0.95,0.02,1.32l-9.05,9.37
				c-0.17,0.18-0.41,0.28-0.66,0.29C24.16,34.94,24.16,34.94,24.15,34.94z"/>
          </g>
        </g>
        <g>
          <path className="st0requ" d="M64.34,32.22l0,30.03c0,1.15-0.94,2.08-2.08,2.08H17.74c-1.15,0-2.08-0.93-2.08-2.08V17.74
			c0-1.15,0.94-2.08,2.08-2.08h44.51c1.15,0,2.08,0.93,2.08,2.08L64.34,32.22L64.34,32.22l0.73,0V17.74c0-1.55-1.26-2.81-2.81-2.81
			H17.74c-1.55,0-2.81,1.26-2.81,2.81v44.51c0,1.55,1.26,2.81,2.81,2.81h44.51c1.55,0,2.81-1.26,2.81-2.81V32.22l0,7.76L64.34,32.22
			z"/>
          <path className="st0requ" d="M62.25,66H17.74C15.68,66,14,64.32,14,62.25V17.74c0-2.06,1.68-3.74,3.74-3.74h44.51
			c2.06,0,3.74,1.68,3.74,3.74v44.51C66,64.32,64.32,66,62.25,66z M17.74,16.59c-0.63,0-1.15,0.52-1.15,1.15v44.51
			c0,0.63,0.52,1.15,1.15,1.15h44.51c0.63,0,1.15-0.52,1.15-1.15l0-30.03l0-14.48c0-0.63-0.52-1.15-1.15-1.15H17.74z"/>
        </g>
      </g>
    </React.Fragment>;
  }

  viewBox(): string {
    return "0 0 80 80";
  }
}

export default withStyles(svgIconStyles)(RequirementsIcon);
