import * as React from "react";
import {withStyles} from "@material-ui/core";
import {MetusSvgIcon, svgIconStyles} from "./MetusSvgIcon";


export class AddIcon extends MetusSvgIcon {

  svg(): JSX.Element {
    return <g xmlns="http://www.w3.org/2000/svg" id="icon/add/s" stroke="none" strokeWidth="1" fillRule="evenodd">
      <polygon points="11 4 11 11 4 11 4 13 11 13 11 20 13 20 13 13 20 13 20 11 13 11 13 4"/>
    </g>;
  }

  viewBox(): string {
    return "0 0 24 24";
  }
}

export default withStyles(svgIconStyles)(AddIcon);

